import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Accordion from "@mui/material/Accordion";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ButtonComponent from "../../components/ButtonComponent";
import NoInfoAvailable from "../../components/NoInfoAvailable";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CommonDeleteDialog from "../../components/DialogsBox/CommonDeleteDialog";
import { ReactComponent as EditIcon } from "../../../assets/Images/Icons/EditIcon.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/Images/Icons/DeleteIcon.svg";
import {
    deleteFaqsId,
    setSelectedQuestion,
    getFaqsById,
} from "../../store/Action/FaqsAction";
import { ROUTES } from "./../../router/RouteList";

function Faqs(props) {
    const {
        deleteFaqsId,
        setSelectedQuestion,
        faqsCategory,
        filteredData,
        getFaqsById,
        userLoggedIn
    } = props;
    const [state, setState] = useState({
        delete: false,
        deletedData: null,
        data: null,
    });
    const { deletedData, data } = state;
    const closeDelete = () => {
        setState({ delete: false });
    };
    const handleDeleteAction = async (data) => {
        await deleteFaqsId(data);
        closeDelete(data);
    };
    const handleEdit = async (faqs) => {
        navigate(`${ROUTES.EDIT_FAQS}?${faqs?.id}`);
        await getFaqsById(faqs?.id);
    };
    const handleDelete = (faqs) => {
        setState({
            delete: true,
            deletedData: faqs?.id,
            data: faqs,
        });
    };
    const navigate = useNavigate();
    const handelAddFaqs = async () => {
        navigate(ROUTES.ADD_FAQS);
    };
    function renderCategoryName() {
        let data = faqsCategory?.find(
            (obj) => obj?.id === filteredData?.[0]?.category_id
        );
        return data?.name;
    }

    return (
        <Fragment>
            <CommonDeleteDialog
                id="faqsQuestionDialog"
                open={state?.delete}
                title={userLoggedIn?.language_data?.faqs?.delete_faq_text || "Delete Question"}
                cancelButtonAction={closeDelete}
                cancelButtonTitle="No"
                submitButtonTitle={"yes"}
                content={`Are you sure, you want to (${data?.question}) delete
                    from the FAQ?`}
                submitButtonAction={handleDeleteAction}
                submitButtonParameter={deletedData}
            />
            <Grid container mt={4}>
                <Grid item md={10} display={"flex"} justifyContent={"center"}>
                    {filteredData?.length !== 0 && (
                        <Typography className="faqsHeading">
                            {renderCategoryName()}{" "}
                        </Typography>
                    )}
                </Grid>
                <Grid item md={2} display={"flex"} justifyContent={"end"}>
                    {faqsCategory?.length !== 0 && (
                        <ButtonComponent
                            id="faqsQestionAddQuestion"
                            onClick={handelAddFaqs}
                            title={userLoggedIn?.language_data?.faqs?.add_question_btn || "Add Question"}
                            startIcon={<AddCircleOutlineIcon />}
                        />
                    )}
                </Grid>
            </Grid>
            <Grid container mt={3} gap={3} pb={4}>
                {filteredData?.length ? (
                    filteredData.map((faqs) => (
                        <Grid item md={12} xs={12}>
                            <Accordion className="questionBox1">
                                <AccordionSummary
                                    expandIcon={
                                        <ExpandMoreIcon className="questionIcon" />
                                    }
                                    aria-controls="panel6a-content"
                                    id="panel6a-header"
                                >
                                    <Grid container>
                                        <Grid
                                            item
                                            md={8}
                                            display={"flex"}
                                            justifyContent={"flex-start"}
                                        >
                                            <Typography className="questionIcon">
                                                {faqs?.question}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            md={4}
                                            display={"flex"}
                                            justifyContent={"flex-end"}
                                        >
                                            <IconButton
                                                id="faqsQestionEdit"
                                                onClick={() => handleEdit(faqs)}
                                            >
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton
                                                id="faqsQestionDelete"
                                                onClick={() =>
                                                    handleDelete(faqs)
                                                }
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </AccordionSummary>
                                <AccordionDetails style={{margin: '10px'}}>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: faqs?.answer,
                                        }}
                                    />
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    ))
                ) : (
                    <NoInfoAvailable
                        className="noCustomerInfo"
                        noInfoTypo="noInfoTypeQuestion"
                    />
                )}
            </Grid>
        </Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,
        faqsList: state.faqs?.faqsList,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        { deleteFaqsId, setSelectedQuestion, getFaqsById },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Faqs);
