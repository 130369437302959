import React, { Fragment, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Typography from "@mui/material/Typography";
import Navbar from "../../common/Header/Navbar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import ButtonComponent from "../../components/ButtonComponent";
import Rectangle from "../../../assets/Images/Avatar/Rectangle.png";
import "../../../assets/styles/Container/Profile.scss";
import { ROUTES } from "../../router/RouteList";
import LoadingComponent from "../../components/Loading/LoadingComponent";
import { getLoggedInUser } from "../../store/Action/LoginAction";
import {Translate } from 'react-auto-translate';
function MyProfile(props) {
    const { userLoggedIn, commonDropdown, getLoggedInUser } = props;
    const navigate = useNavigate();
    function handleClick() {
        navigate(ROUTES.EDIT_PROFILE);
    }

    function handleCancel() {
        navigate(ROUTES.HOME);
    }

    function getRole(id) {
        let data = commonDropdown?.roles?.find((obj) => obj.id === id);
        return data;
    }

    useEffect(() => {
        getLoggedInUser();
    }, []);
    return (
        <Fragment>
        <Navbar />
        {!userLoggedIn ? (
            <LoadingComponent sx={{ height: "600px" }} />
        ) : (
            <Box sx={{ px: { md: 7, xs: 1 } }}>
                <Grid container>
                    <Grid item lg={12} mt={12}>
                        <Typography className="profileEditHeading">
                            <Translate>My Profile</Translate>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid
                    container
                    className="gridFirst"
                    sx={{ display: "flex" }}
                >
                    <Grid
                        item
                        xs={12}
                        lg={2}
                        md={12}
                        sm={12}
                        className="imgDiv"
                    >
                        <img
                            src={
                                userLoggedIn?.user?.photograph || Rectangle
                            }
                            alt="Rectangle"
                            className="imgRectangle"
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        lg={10}
                        md={12}
                        sm={12}
                        className="secondBox"
                    >
                        <Grid container spacing={3}>
                            <Grid
                                item
                                xs={12}
                                lg={6}
                                md={6}
                                sm={12}
                                display={"flex"}
                                gap={1}
                            >
                                <Typography className="firstName">
                                    <Translate>
                                        {userLoggedIn?.language_data?.customer?.first_name ||
                                        "First Name"}
                                    </Translate>
                                    :
                                </Typography>
                                <Typography className="name">
                                    {userLoggedIn?.user?.first_name}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                lg={6}
                                md={6}
                                sm={12}
                                display={"flex"}
                                gap={1}
                            >
                                <Typography className="firstName">
                                    <Translate>
                                        {userLoggedIn?.language_data?.customer?.middle_name ||
                                        "Middle Name"}
                                    </Translate>
                                    :
                                </Typography>
                                <Typography className="colorDate">
                                    {userLoggedIn?.user?.middle_name === null
                                        ? "---"
                                        : userLoggedIn?.user?.middle_name}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                lg={6}
                                md={6}
                                sm={12}
                                display={"flex"}
                                gap={1}
                            >
                                <Typography className="firstName">
                                    <Translate>
                                        {userLoggedIn?.language_data?.customer?.last_name ||
                                        "Last Name"}
                                    </Translate>
                                    :
                                </Typography>
                                <Typography className="name">
                                    {userLoggedIn?.user?.last_name}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                lg={6}
                                display={"flex"}
                                gap={1}
                                md={6}
                                sm={12}
                            >
                                <Typography className="firstName">
                                    <Translate>User Title:</Translate>
                                </Typography>
                                <Typography className="colorDate">
                                    {userLoggedIn?.user?.user_title || "---"}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                lg={6}
                                display={"flex"}
                                gap={1}
                                md={6}
                                sm={12}
                            >
                                <Typography className="firstName">
                                    <Translate>Email:</Translate>
                                </Typography>
                                <Typography className="colorDate">
                                    {userLoggedIn?.user?.email}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                lg={6}
                                display={"flex"}
                                gap={1}
                                md={6}
                                sm={12}
                            >
                                <Typography className="firstName">
                                    <Translate>
                                        {userLoggedIn?.language_data?.comman?.status || "Status"}
                                    </Translate>
                                    :
                                </Typography>
                                <Typography className="statusStyle">
                                    <Translate>{userLoggedIn?.user?.status}</Translate>
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                lg={6}
                                display={"flex"}
                                gap={1}
                                md={6}
                                sm={12}
                            >
                                <Typography className="firstName">
                                    <Translate>Role:</Translate>
                                </Typography>
                                <Typography className="colorDate">
                                    {getRole(userLoggedIn?.user?.role_id)?.label}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                lg={6}
                                display={"flex"}
                                gap={1}
                                md={6}
                                sm={12}
                            >
                                <Typography className="firstName">
                                    <Translate>Created On:</Translate>
                                </Typography>
                                <Typography className="colorDate">
                                    {userLoggedIn?.user?.created_at}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                lg={6}
                                display={"flex"}
                                gap={1}
                                md={6}
                                sm={12}
                            >
                                <Typography className="firstName">
                                    <Translate>Last Update :</Translate>
                                </Typography>
                                <Typography className="colorDate">
                                    {userLoggedIn?.user?.updated_at}
                                </Typography>
                            </Grid>
                            {userLoggedIn.user.role_id != 1 && (
                                <Grid
                                    item
                                    xs={12}
                                    lg={6}
                                    display={"flex"}
                                    gap={1}
                                    md={6}
                                    sm={12}
                                >
                                    <Typography className="firstName">
                                        <Translate>Language :</Translate>
                                    </Typography>
                                    <Typography className="colorDate">
                                        {commonDropdown.languages
                                            .filter(
                                                (data) =>
                                                    data.id ==
                                                    userLoggedIn?.user
                                                        ?.language_id
                                            )
                                            .map((data, index) => data.label)}
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sx={{
                            justifyContent: "end",
                            display: "flex",
                            paddingBottom: "35px",
                            paddingRight: "32px",
                            paddingTop: "32px",
                        }}
                    >
                        <div style={{ paddingRight: "10px" }}>
                            <ButtonComponent
                                id="profileCancelButton"
                                title={<Translate>Cancel</Translate>}
                                variant={"outlined"}
                                onClick={() => handleCancel()}
                            />
                        </div>
                        <div>
                            <ButtonComponent
                                id="profileUpdateButton"
                                title={<Translate>Update Profile</Translate>}
                                variant={"contained"}
                                onClick={() => handleClick()}
                            />
                        </div>
                    </Grid>
                </Grid>
            </Box>
        )}
    </Fragment>
    
    );
}

const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,
        commonDropdown: state.auth?.commonDropdown,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ getLoggedInUser }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(MyProfile);
