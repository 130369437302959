import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ButtonComponent from "../../components/ButtonComponent";
import DialogComponent from "../../components/DialogsBox/DialogComponent";
import Divider from "@mui/material/Divider";
import { connect } from "react-redux";

function InvoiceDialog(props) {
    const { open, packageList, handleClose, userLoggedIn } = props;

    return (
        <DialogComponent
            open={open}
            handleClose={handleClose}
            title={"Invoice"}
            className="subscriptionDialog"
            content={
                <Box sx={{ px: { md: 2, xs: 1 } }}>
                    <Divider className="billDivider" />
                    <Grid container my={2}>
                        <Grid item xs={4}>
                            <Typography className="billingTitles">
                                Billed to:
                            </Typography>
                            <Typography className="invoiceTypo">
                                John Smith
                            </Typography>
                            <Typography className="invoiceTypo">
                                john@example.com
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography className="billingTitles">
                                {userLoggedIn?.language_data?.formstudio?.address || "Address"}:
                            </Typography>
                            <Typography className="invoiceTypo">
                                {"G-124, Oswald Road, Los Angeles,"}
                            </Typography>
                            <Typography className="invoiceTypo">
                                California - 90001
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Grid
                                container
                                display={"flex"}
                                justifyContent={"flex-end"}
                            >
                                <Grid
                                    item
                                    xs={12}
                                    display={"flex"}
                                    justifyContent={"flex-end"}
                                >
                                    <Typography className="billingTitles">
                                        Invoice #1204
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    display={"flex"}
                                    justifyContent={"flex-end"}
                                >
                                    <Typography className="invoiceTypo">
                                        Issued Date: 31/03/2023
                                    </Typography>
                                </Grid>
                                <Typography className="invoiceTypo">
                                    Issued Time: 14:03
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Divider className="billDivider" />
                    <Grid container my={2}>
                        <Grid item xs={4}>
                            <Typography className="billingTitles">
                                Package
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography className="billingTitles">
                                Package Details
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={4}
                            display={"flex"}
                            justifyContent={"flex-end"}
                        >
                            <Typography className="billingTitles">
                                Price
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider className="billDivider" />
                    <Grid container my={2}>
                        <Grid item xs={4}>
                            <Typography className="invoiceTypo">
                                CUSTOM Package(Monthly)
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography className="invoiceTypo">
                                Next Billing Date: 01/05/2023
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={4}
                            display={"flex"}
                            justifyContent={"flex-end"}
                        >
                            <Typography className="billingTitles">
                                $350
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider className="billDivider" />
                    <Grid container my={2}>
                        <Grid
                            item
                            xs={12}
                            justifyContent={"flex-end"}
                            display={"flex"}
                        >
                            <Typography className="invoiceTypo">
                                {"Total Amount: "}
                            </Typography>
                            <Typography className="billingTitles">
                                {` $350`}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className="billingTitles">
                                Notes:
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className="invoiceTypo">
                                Notes
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            }
            action={
                <Grid
                    container
                    display={"flex"}
                    justifyContent={"flex-end"}
                    mr={3}
                    gap={2}
                >
                    <ButtonComponent
                        title={"Cancel"}
                        onClick={() => handleClose()}
                    />
                </Grid>
            }
        />
    );
}

const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,
    };
};

export default connect(mapStateToProps, null) (InvoiceDialog);
