import React, { Fragment, useEffect, useState } from "react";
import { Grid, Typography, Chip } from "@mui/material";
import RadioButtonGroup from "../../../../../components/RadioButtonGroup/RadioButtonGroup";
import {
    defaultComplianceSettingOptions,
    yesNoOptions,
    defaultIDComplianceSettingOptions,
} from "../../../../../utils/CommonData";
import { bindActionCreators } from "redux";
import AgreementCommon from "./AgreementCommon";
import {
    getDefaultComplianceSettings,
    getUserDefaultComplianceSetting,
    createUserDefaultComliance,
    editUserDefaultComliance,
} from "../../../../../store/Action/DefaultComplianceSettingAction";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import ButtonComponent from "../../../../../components/ButtonComponent";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../../../router/RouteList";
import { getQuestionListByOptions } from "../../../../../store/Action/MqsAction";
import TextFieldComponent from "../../../../../components/TextField/TextFieldComponent";
import CheckboxComponents from "../../../../../components/Checkbox/CheckboxComponent";
import { FormControlLabel, Checkbox, MenuItem, ListItemText } from "@mui/material";
import MultiSelectDropDown from "../../../../../components/DropDown/MultiSelectDropDown";
import { Box } from "@mui/system";
import { setExtraSettings } from "../../../../../store/Action/DefaultComplianceSettingAction";
import { getExtraSettings } from "../../../../../store/Action/DefaultComplianceSettingAction";
const DefaultComplianceSettings = (props) => {
    const {
        conductorType,
        guid,
        getDefaultComplianceSettings,
        getSelfConfigrationList,
        getUserDefaultComplianceSetting,
        createUserDefaultComliance,
        defaultComplianceSelectData,
        editUserDefaultComliance,
        state,
        setState,
        getQuestionListByOptions,
        usersList,
        setExtraSettings,
        getExtraSettings,
        ExtraSettings
    } = props;
    const [questions, setQuestion] = useState({
        allSelect: false,
        allSelectCode: false,
        selectJuridicationAndCircuits: false,
        selectAllReccurence: false,
        selectAternativeContentResponses: false,
        isLoading: false,
        selectCircuits: false,
        showRemediation: true,
        isCategoryReadOnly: false,
        isViewMode: false,
        openMqsResponse: false,
        isKoWarning: false,
        collar_color: [],
        recurrence_probability_ata: '',
        recurrence_probability_cbha: '',
        recurrence_probability_rcp: '',
        weightage: '',
        selectAllEmployee: '',
        days: '',
        isedit: false,
        additionOption: {
            guid: guid,
            onBoarding: {
                id: 1,
                isAll: ExtraSettings?.onBoarding?.isAll || false,
                employee: ExtraSettings?.onBoarding?.employees || [],
                checked:  ExtraSettings?.onBoarding  ||  false,
            },
            offBoarding: {
                id: 3,
                isAll: ExtraSettings?.offBoarding?.isAll || false,
                employee: ExtraSettings?.offBoarding?.employees || [],
                checked: ExtraSettings?.offBoarding ||  false,
            },
            reTesting: {
                id: 2,
                isAll: ExtraSettings?.reTesting?.isAll || false,
                employee: ExtraSettings?.reTesting?.employees || [],
                days: ExtraSettings?.reTesting?.days || '',
                checked: ExtraSettings?.reTesting || false,
            },
        },
        recurrenceProbability: [
            {
                isChecked: false,
                collar: 5,
                label: "Blue",
                weightage: 1,
                rcp_probability: 1,
                ata_probability: 1,
                cbha_probability: 1,
            },
            {
                isChecked: false,
                collar: 2,
                label: "White",
                weightage: 1,
                rcp_probability: 1,
                ata_probability: 1,
                cbha_probability: 1,
            },
            {
                isChecked: false,
                collar: 3,
                label: "All Other Collars",
                weightage: 1,
                rcp_probability: 1,
                ata_probability: 1,
                cbha_probability: 1,
            },
        ],
        expanded: {
            expand: true,
        },
        formData: {
            pre_question: 0,
            trigger_code: [],
            jurisdiction: [],
            circuits: [],
            category_id: null,
            unique_ques_family_code: null,
            weightage: '',
            question: null,
            explanation: null,
            remediation_info: null,
            second_level_remediation_info: null,
            key_compliance_lesson: null,
            available_response: null,
            responses_for_remediation: null,
            ques_in_onboarding: null,
            ques_in_ongoing: null,
            ques_in_offboarding: null,
            ques_apply_on_hp_sr: null,
            us_specific_ques: null,
            dependent_ques: null,
            contingent_ques: null,
            responses_for_activate_dq: null,
            override_ques: null,
            responses_for_override_ques: null,
            overrided_questions: null,
            ques_apply_to_cb: null,
            recurrence_probability_ata: null,
            recurrence_probability_cbha: null,
            recurrence_probability_rcp: null,
            all_tc_apply: null,
            for_contractor: null,
            dq_ranking: null,
            contingent_ques_master: null,
            examples: null,
            collar: [],
            best_response: null,
            score: null,
            ko_question: null,
            ko_ques_content: null,
            responses_trigger_ko_status: null,
            apply_reservation_right_content: null,
            responses_trigger_reservation_right_content: null,
            reservation_right_content: null,
            responses_of_rrc: null,
            apply_alternative_response_content: null,
            alternative_response_content: null,
            alternative_content_responses: [],
            repression_question_responses: null,
            test_type_id: null,
            for_sub_contractor: null,
            for_sub_contacting_business: null,
            for_manager: null,
            for_service_recipient: null,
            for_hiring_party: null,
            for_indirect_contractor: null,
        },
        questionIndex: 0,
    });

    console.log(questions.additionOption, 'extrasetttings')


    useEffect(() => {
        getExtraSettings(guid)
    }, [])



    const settings = useSelector(
        (state) => state.defaultComplianceSettings.settings
    );
    const { defaultOption, defautIDOption, takeComplianceQues } =
        state.defaultComplianceSettingDetails;
    const navigate = useNavigate();
    useEffect(() => {
        getDefaultComplianceSettings();
        if (defaultComplianceSelectData) {
            setState({
                defaultComplianceSettingDetails: {
                    defaultOption: defaultComplianceSelectData?.option,
                    defautIDOption:
                        defaultComplianceSelectData?.default_compliance_id,
                    takeComplianceQues:
                        defaultComplianceSelectData?.take_compliance_ques,
                },
            });
        }
    }, [
        getUserDefaultComplianceSetting,
        defaultComplianceSelectData?.defaultOption,
        defaultComplianceSelectData?.defautIDOption,
        defaultComplianceSelectData?.takeComplianceQues,
    ]);

    const handlerSave = async () => {
       
         setState((prevState) => ({ ...prevState, isLoading: true }));
         await setExtraSettings(questions?.additionOption);
        await getExtraSettings(guid)
         setState((prevState) => ({ ...prevState, isLoading: false }));
       
    };

    const handlerEdit = async () => {
        setState((prevState) => ({ ...prevState, isLoading: true }));
        let data = {
            id: defaultComplianceSelectData?.id,
            guid: guid,
            option: defaultOption,
            take_compliance_ques:
                defaultOption === 4 ? false : takeComplianceQues,
            default_compliance_id: defautIDOption,
        };
        await editUserDefaultComliance(data);
        setState((prevState) => ({ ...prevState, isLoading: false }));
    };
    const handlerSetupQuestion = async () => {
        setState((prevState) => ({ ...prevState, isLoading: true }));
        const res = await getQuestionListByOptions(defautIDOption);
        setState((prevState) => ({ ...prevState, isLoading: false }));
        if (res?.payload?.length > 0) {
            navigate(ROUTES.ADD_QUESTION + "/" + res.payload[0], {
                state: { optionID: defautIDOption, questionID: res.payload[0] },
            });
        } else {
            setState((prevState) => ({
                ...prevState,
                isOpenNoInfoDialog: true,
            }));
        }
    };
    const editButton = () => {
        return <ButtonComponent title="Update" onClick={handlerEdit} />;
    };
    const saveButton = () => {
        return <ButtonComponent title="Save" onClick={handlerSave} />;
    };



    const handleOnBoardEmployeeChange = (event) => {
        const {
            target: { value },
        } = event;

        setQuestion((previous) => {
            return {
                ...previous,
                selectJuridicationAndCircuits: usersList?.length === value?.length ? true : false,
                additionOption: {
                    ...previous.additionOption,
                    onBoarding: {
                        ...previous.additionOption.onBoarding,
                        employee: questions?.additionOption?.onBoarding?.checked ? value : [],
                        isAll: false
                    },
                },
            };
        });
    }

    const handleOffBoardEmployeeChange = (event) => {
        const {
            target: { value },
        } = event;

        setQuestion((previous) => {
            return {
                ...previous,
                selectJuridicationAndCircuits: usersList?.length === value?.length ? true : false,
                additionOption: {
                    ...previous.additionOption,
                    offBoarding: {
                        ...previous.additionOption.offBoarding,
                        employee: questions?.additionOption?.offBoarding?.checked ? value : [],
                        isAll: false
                    },
                },
            };
        });

    }

    const handlereTestingEmployeeChange = (event) => {
        const {
            target: { value },
        } = event;
        setQuestion((previous) => {
            return {
                ...previous,
                selectJuridicationAndCircuits: usersList?.length === value?.length ? true : false,
                additionOption: {
                    ...previous.additionOption,
                    reTesting: {
                        ...previous.additionOption.reTesting,
                        employee: questions?.additionOption?.reTesting?.checked ? value : [],
                        isAll: false
                    },
                },
            };
        });
    }

    const handleRenderValue = (selected, values) => {
        return (
            <Box
                sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 0.5,
                    height: 40,
                    overflow: "auto",
                    padding: "4px",
                }}
            >
                {selected?.map((selected_value) => {
                    const value = values?.find(
                        (item) => item?.id === selected_value
                    );
                    return <Chip key={selected_value} label={value?.first_name + " " + value?.last_name} />;
                })}
            </Box>
        );
    };

    console.log(questions)
    return (
        <Fragment>
            <Grid container spacing={0}>
                <Grid item lg={12} md={12}>
                    <div style={{ display: "flex", flexDirection: 'column' }}>
                        <div style={{ display: "flex" }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={questions?.additionOption?.onBoarding?.checked}
                                        onChange={(event) => {
                                            setQuestion((prevState) => ({
                                                ...prevState,
                                                additionOption: {
                                                    ...prevState.additionOption,
                                                    onBoarding: {
                                                        ...prevState.additionOption?.onBoarding,
                                                        checked: event.target.checked,
                                                        id: 1,
                                                    }
                                                }
                                            }));
                                        }}
                                    />
                                }
                                label={
                                    <span
                                        style={{
                                            fontSize: "17px",
                                            fontWeight: 600,
                                        }}
                                    >
                                        {'OnBoarding'}
                                    </span>
                                }
                                labelPlacement="Select"
                            />

                            <Grid item lg={3} md={12}>
                                {
                                    questions?.additionOption?.onBoarding?.checked &&
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={questions?.additionOption?.onBoarding?.isAll}
                                                onChange={(event) => {
                                                    const isChecked = event.target.checked;
                                                    setQuestion((prevState) => ({
                                                        ...prevState,
                                                        additionOption: {
                                                            ...prevState.additionOption,
                                                            onBoarding: {
                                                                ...prevState.additionOption?.onBoarding,
                                                                isAll: isChecked && questions?.additionOption?.onBoarding?.checked ? true : false
                                                            }
                                                        }
                                                    }));

                                                    setQuestion((previous) => {
                                                        return {
                                                            ...previous, // Copy the previous state
                                                            additionOption: {
                                                                ...previous.additionOption, // Copy the previous 'additionOption'
                                                                onBoarding: {
                                                                    ...previous.additionOption.onBoarding, // Copy the previous 'onBoarding'
                                                                    employee: [] // Update the 'isAll' property
                                                                }
                                                            }
                                                        };
                                                    });
                                                }}
                                            />
                                        }
                                        label={
                                            <span
                                                style={{
                                                    fontSize: "17px",
                                                    // fontWeight: 600,
                                                }}
                                            >
                                                {'Initiate to all Contractor'}
                                            </span>
                                        }
                                        labelPlacement="Select"
                                    />
                                }

                            </Grid>
                        </div>


                        {
                            questions?.additionOption?.onBoarding?.checked &&
                            <Grid container mt={3} className="flex" spacing={2}>
                                {
                                    questions?.additionOption?.onBoarding?.isAll ? null :
                                        <Grid item lg={3} md={12} >
                                            <MultiSelectDropDown
                                                value={
                                                    Array.isArray(questions?.additionOption?.onBoarding?.employee) ? 
                                                    questions?.additionOption?.onBoarding?.employee : []
                                                }
                                                title={'Select Contractor'}
                                                onChange={
                                                    handleOnBoardEmployeeChange
                                                }
                                                readOnly={
                                                    questions?.isViewMode
                                                }
                                                name="selectEmployee"

                                                renderValue={(
                                                    selected
                                                ) => {
                                                    return handleRenderValue(
                                                        selected,
                                                        usersList
                                                    );
                                                }}
                                            >

                                                {usersList?.map(
                                                    (code) => (
                                                        <MenuItem
                                                            key={
                                                                code?.id
                                                            }
                                                            value={
                                                                code?.id
                                                            }
                                                        >
                                                            <Checkbox
                                                                checked={
                                                                    questions?.additionOption?.onBoarding?.employee?.indexOf(
                                                                        code?.id
                                                                    ) >
                                                                    -1
                                                                }
                                                            />
                                                            <ListItemText
                                                                primary={
                                                                    `${code?.first_name}${" "}${code?.last_name}`
                                                                }
                                                            />
                                                        </MenuItem>
                                                    )
                                                )}

                                            </MultiSelectDropDown>
                                        </Grid>
                                }


                            </Grid>
                        }

                        <div style={{ display: 'flex' }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={questions?.additionOption?.reTesting?.checked}
                                        onChange={(event) => {
                                            const isChecked = event.target.checked;
                                            setQuestion((prevState) => ({
                                                ...prevState,
                                                additionOption: {
                                                    ...prevState.additionOption,
                                                    reTesting: {
                                                        ...prevState?.additionOption?.reTesting,
                                                        checked:  event.target.checked,
                                                        id: 2,
                                                    }
                                                }
                                            }));
                                        }}
                                    />
                                }
                                label={
                                    <span
                                        style={{
                                            fontSize: "17px",
                                            fontWeight: 600,
                                        }}
                                    >
                                        {'Retesting'}
                                    </span>
                                }
                                labelPlacement="Select"
                            />

                            <Grid item lg={3} md={12}>
                                {
                                    questions?.additionOption?.reTesting?.checked &&
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={questions?.additionOption?.reTesting?.isAll}
                                                onChange={(event) => {
                                                    const isChecked = event.target.checked;
                                                    setQuestion((prevState) => ({
                                                        ...prevState,
                                                        additionOption: {
                                                            ...prevState.additionOption,
                                                            reTesting: {
                                                                ...prevState.additionOption.reTesting,
                                                                isAll: isChecked && questions?.additionOption?.reTesting?.checked ? true : false
                                                            }
                                                        }
                                                    }));

                                                    setQuestion((previous) => {
                                                        return {
                                                            ...previous, // Copy the previous state
                                                            additionOption: {
                                                                ...previous.additionOption, // Copy the previous 'additionOption'
                                                                reTesting: {
                                                                    ...previous.additionOption.reTesting, // Copy the previous 'onBoarding'
                                                                    employee: [] // Update the 'isAll' property
                                                                }
                                                            }
                                                        };
                                                    });
                                                }}
                                            />
                                        }
                                        label={
                                            <span
                                                style={{
                                                    fontSize: "17px",
                                                    // fontWeight: 600,
                                                }}
                                            >
                                                {'Initiate to all Contractor'}
                                            </span>
                                        }
                                        labelPlacement="Select"
                                    />
                                }

                            </Grid>

                        </div>

                        {
                            questions?.additionOption?.reTesting?.checked &&
                            <Grid container mt={3} className="flex" spacing={2}>

                                {
                                    questions?.additionOption?.reTesting?.id == 2 &&
                                    <Grid item lg={3} md={12}>
                                        <TextFieldComponent
                                            label={'Reset Frequency (Days)'}
                                            value={questions?.additionOption?.reTesting?.days}
                                            onChange={(e) => {
                                                setQuestion((prevState) => ({
                                                    ...prevState,
                                                    additionOption: {
                                                        ...prevState.additionOption,
                                                        reTesting: {
                                                            ...prevState.additionOption.reTesting,
                                                            days: e.target.value
                                                        }
                                                    }
                                                   
                                                }));
                                            }}
                                        />
                                    </Grid>
                                }
                                {
                                    questions?.additionOption?.reTesting?.isAll ? null :
                                        <Grid item lg={3} md={12} >
                                            <MultiSelectDropDown
                                                value={
                                                    Array.isArray(
                                                        questions
                                                            ?.additionOption
                                                            ?.reTesting?.employee
                                                    )
                                                        ? questions
                                                            ?.additionOption
                                                            ?.reTesting?.employee
                                                        : []
                                                }
                                                title={'Select Contractor'}
                                                onChange={
                                                    handlereTestingEmployeeChange
                                                }
                                                readOnly={
                                                    questions?.isViewMode
                                                }
                                                name="selectEmployee"

                                                renderValue={(
                                                    selected
                                                ) => {
                                                    return handleRenderValue(
                                                        selected,
                                                     usersList 
                                                    );
                                                }}
                                            >

                                                {usersList?.map(
                                                    (code) => (
                                                        <MenuItem
                                                            key={
                                                                code?.id
                                                            }
                                                            value={
                                                                code?.id
                                                            }
                                                        >
                                                            <Checkbox
                                                                checked={
                                                                    questions?.additionOption?.reTesting?.employee?.indexOf(
                                                                        code?.id
                                                                    ) >
                                                                    -1
                                                                }
                                                            />
                                                            <ListItemText
                                                                primary={
                                                                    `${code?.first_name}${" "}${code?.last_name}`
                                                                }
                                                            />
                                                        </MenuItem>
                                                    )
                                                )}

                                            </MultiSelectDropDown>
                                        </Grid>
                                }


                            </Grid>
                        }


                        <div style={{ display: "flex" }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={questions?.additionOption?.offBoarding?.checked}
                                        onChange={(event) => {
                                            setQuestion((prevState) => ({
                                                ...prevState,
                                                additionOption: {
                                                    ...prevState.additionOption,
                                                    offBoarding: {
                                                        ...prevState.additionOption.offBoarding,
                                                        checked: event.target.checked,
                                                        id: 3,
                                                    }
                                                }
                                            }));

                                            setQuestion((previous) => {
                                                return {
                                                    ...previous, // Copy the previous state
                                                    additionOption: {
                                                        ...previous.additionOption, // Copy the previous 'additionOption'
                                                        offBoarding: {
                                                            ...previous.additionOption.offBoarding, // Copy the previous 'onBoarding'
                                                            employee: [] // Update the 'isAll' property
                                                        }
                                                    }
                                                };
                                            });
                                        }}
                                    />
                                }
                                label={
                                    <span
                                        style={{
                                            fontSize: "17px",
                                            fontWeight: 600,
                                        }}
                                    >
                                        {'OffBoarding'}
                                    </span>
                                }
                                labelPlacement="Select"
                            />


                            <Grid item lg={3} md={12}>
                                {
                                    questions?.additionOption?.offBoarding?.checked &&
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={questions?.additionOption?.offBoarding?.isAll}
                                                onChange={(event) => {
                                                    const isChecked = event.target.checked;
                                                    setQuestion((prevState) => ({
                                                        ...prevState,
                                                        additionOption: {
                                                            ...prevState.additionOption,
                                                            offBoarding: {
                                                                ...prevState.additionOption.offBoarding,
                                                                isAll: isChecked && questions?.additionOption?.offBoarding?.checked ? true : false
                                                            }
                                                        }
                                                    }));

                                                    setQuestion((previous) => {
                                                        return {
                                                            ...previous, // Copy the previous state
                                                            additionOption: {
                                                                ...previous.additionOption, // Copy the previous 'additionOption'
                                                                offBoarding: {
                                                                    ...previous.additionOption.offBoarding, // Copy the previous 'onBoarding'
                                                                    employee: [] // Update the 'isAll' property
                                                                }
                                                            }
                                                        };
                                                    });
                                                }}
                                            />
                                        }
                                        label={
                                            <span
                                                style={{
                                                    fontSize: "17px",
                                                    // fontWeight: 600,
                                                }}
                                            >
                                                {'Initiate to all Contractor'}
                                            </span>
                                        }
                                        labelPlacement="Select"
                                    />
                                }

                            </Grid>
                        </div>
                    </div>


                    {
                        questions?.additionOption?.offBoarding?.checked &&
                        <Grid container mt={3} className="flex" spacing={2}>
                            {
                                questions?.additionOption?.offBoarding?.isAll ? null :
                                    <Grid item lg={3} md={12} >
                                        <MultiSelectDropDown
                                            value={
                                                Array.isArray(
                                                    questions
                                                        ?.additionOption.offBoarding?.employee
                                                )
                                                    ? questions
                                                        ?.additionOption
                                                        ?.offBoarding?.employee
                                                    : []
                                            }
                                            title={'Select Contractor'}
                                            onChange={
                                                handleOffBoardEmployeeChange
                                            }
                                            readOnly={
                                                questions?.isViewMode
                                            }
                                            name="selectEmployee"

                                            renderValue={(
                                                selected
                                            ) => {
                                                return handleRenderValue(
                                                    selected,
                                                    usersList
                                                );
                                            }}
                                        >

                                            {usersList?.map(
                                                (code) => (
                                                    <MenuItem
                                                        key={
                                                            code?.id
                                                        }
                                                        value={
                                                            code?.id
                                                        }
                                                    >
                                                        <Checkbox
                                                            checked={
                                                                questions?.additionOption?.offBoarding?.employee?.indexOf(
                                                                    code?.id
                                                                ) >
                                                                -1
                                                            }
                                                        />
                                                        <ListItemText
                                                            primary={
                                                                `${code?.first_name}${" "}${code?.last_name}`
                                                            }
                                                        />
                                                    </MenuItem>
                                                )
                                            )}

                                        </MultiSelectDropDown>
                                    </Grid>
                            }


                        </Grid>
                    }









                    {/* <RadioButtonGroup
                        row={false}
                        title="Select Option"
                        options={defaultComplianceSettingOptions}
                        defaultValue={state?.testModeSetting?.defaultOption}
                        value={state?.testModeSetting?.defaultOption}
                        onChange={(event) => {
                            setState((prevState) => ({
                                ...prevState,
                                testModeSetting: {
                                    ...state.testModeSetting,
                                    defaultOption: parseInt(event.target.value),
                                },
                            }))
                        }

                        }
                    /> */}

                </Grid>
            </Grid>




            {/* {conductorType === "idc" && (
                <Grid container mt={3}>
                    <Grid item lg={12} md={12}>
                        <RadioButtonGroup
                            row={false}
                            title="Indirect Conductor must choose anyone of the 3 options:"
                            options={defaultIDComplianceSettingOptions}
                            defaultValue={
                                defaultIDComplianceSettingOptions[0].value
                            }
                            onChange={(event) =>
                                setState((prevState) => ({
                                    ...prevState,
                                    defaultComplianceSettingDetails: {
                                        ...state.defaultComplianceSettingDetails,
                                        defautIDOption: parseInt(
                                            event.target.value
                                        ),
                                    },
                                }))
                            }
                        />
                    </Grid>
                </Grid>
            )} */}
            <Grid container mt={3}>
                <Grid item xs={12}>
                    {takeComplianceQues && (
                        <AgreementCommon
                            showTitle={false}
                            isDefaultSetting={true}
                            options={settings}
                            state={state}
                            setStateDefaultSetting={setState}
                            defaultSettingOptionId={defautIDOption}
                            saveButton={saveButton}
                            editButton={editButton}
                            handlerSetupQuestion={handlerSetupQuestion}
                        />
                    )}
                </Grid>

                <Grid item justifyContent={"end"} display={"flex"} xs={12}>
                    {!takeComplianceQues ||
                        defautIDOption == 5 ||
                        defaultOption === 4
                        ? defaultComplianceSelectData
                            ? editButton()
                            : saveButton()
                        : null}
                </Grid>
            </Grid>
        </Fragment>
    );
};
const mapStateToProps = (state) => {
    return {
        defaultComplianceSelectData:
            state.defaultComplianceSettings.defaultComplianceSelectData,
        usersList: state.user.usersList,
        ExtraSettings: state.defaultComplianceSettings.extraSetting
    };
};
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getDefaultComplianceSettings,
            getUserDefaultComplianceSetting,
            createUserDefaultComliance,
            editUserDefaultComliance,
            getQuestionListByOptions,
            setExtraSettings,
            getExtraSettings,
        },
        dispatch
    );
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DefaultComplianceSettings);
