import React, { useState } from "react";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ColorPickerComponent from "../../components/ColorPicker";
import ColorPicker from "../../../assets/Images/Logo/ColorPicker.png";
import { colorScheme } from "./FormStudioUtils";
import { connect } from "react-redux";

const DesignDrawer = (props) => {
    const { open, formStates, pageColor, formColor, fontColor, color, userLoggedIn } = props;
    const [state, setState] = useState({
        pickerOpen: false,
        type: "",
        color_scheme: "#22194D",
        defaultColor: "",
        selectedColor: null,
    });
    const { pickerOpen, type, defaultColor, selectedColor } = state;

    const handleClose = () => {
        setState((prevState) => ({
            ...prevState,
            pickerOpen: false,
            type: type,
        }));
    };
    const handleDrawerClose = () => {
        formStates((prevState) => ({ ...prevState, designDrawer: false }));
    };
    const handleOpen = (type, hexColor) => {
        setState((prevState) => ({
            ...prevState,
            pickerOpen: true,
            type: type,
            defaultColor: hexColor,
        }));
    };
    const handleColorChange = (event, type) => {
        setState((prevState) => ({
            ...prevState,
            selectedColor: event?.hex,
            type: type,
            defaultColor: event?.hex,
        }));
    };
    const updateFormColor = async () => {
        formStates((prevState) => ({
            ...prevState,
            [type]: selectedColor,
        }));
        setState((prevState) => ({
            ...prevState,
            pickerOpen: false,
        }));
    };
    const handlerColorTheme = (boader, background, fontColor) => {
        formStates((prevState) => ({
            ...prevState,
            pageColor: boader,
            formColor: background,
            fontColor: fontColor,
        }));
    };

    return (
        <>
            <ColorPickerComponent
                open={pickerOpen}
                handleClose={handleClose}
                handleColorChange={handleColorChange}
                defaultColor={defaultColor}
                type={type}
                updateFormColor={updateFormColor}
                id="designDrawerColorPicker"
            />
            <Box sx={{ display: "flex" }}>
                <Drawer
                    className="drawer"
                    variant="persistent"
                    anchor="right"
                    open={open}
                >
                    <Grid
                        container
                        display={"flex"}
                        justifyContent={"space-between"}
                        paddingLeft={"15px"}
                        alignContent={"center"}
                        alignItems={"center"}
                        className="drawerTitleBox"
                    >
                        <Typography className="drawerHeadText">
                            {userLoggedIn?.language_data?.formstudio?.font_design || "Font & Design"}
                        </Typography>
                        <IconButton
                            onClick={handleDrawerClose}
                            id="designDrawerCloseIcon"
                        >
                            <CloseIcon sx={{ color: "#76520E" }} />
                        </IconButton>
                    </Grid>
                    <Divider />
                    <Box className="listBox">
                        <Box>
                            <Typography paddingY={2} className="drawerHeadText">
                                {userLoggedIn?.language_data?.formstudio?.colors_txt || "COLORS"}
                            </Typography>
                            <Divider className={"dividerStyle"} />
                            <Box paddingY={2}>
                                <Typography className="drawerHeadText">
                                    {userLoggedIn?.language_data?.formstudio?.color_scheme || "Color Scheme"}
                                </Typography>
                                <Box>
                                    <Grid container>
                                        <Grid item className="colorSchemeItems">
                                            {colorScheme.map((obj, index) => (
                                                <Paper
                                                    id="designDrawerColorScheme"
                                                    key={index}
                                                    className="colorSchemePicker"
                                                    sx={{
                                                        border: `2px solid ${obj.boaderColor}`,
                                                        background: `${obj.backgroundColor}`,
                                                        color: `${obj.color}`,
                                                    }}
                                                    onClick={() => {
                                                        handlerColorTheme(
                                                            `${obj.boaderColor}`,
                                                            `${obj.backgroundColor}`,
                                                            `${obj.color}`
                                                        );
                                                    }}
                                                >
                                                    A
                                                </Paper>
                                            ))}
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                            <Divider />
                            <Box paddingY={2}>
                                <Typography className="drawerHeadText">
                                    {userLoggedIn?.language_data?.formstudio?.page_color || "Page Color"}
                                </Typography>
                                <Box className="colorPickerBox">
                                    <Paper className="colorCodePaper">
                                        <Typography
                                            sx={{ textTransform: "uppercase" }}
                                        >
                                            {pageColor}
                                        </Typography>
                                        <Paper
                                            className="colorCodePicker"
                                            sx={{
                                                border: "1px solid white",
                                                background: pageColor,
                                            }}
                                        />
                                    </Paper>
                                    <img
                                        src={ColorPicker}
                                        className="colorImage"
                                        alt="Color picker"
                                        onClick={() =>
                                            handleOpen("pageColor", pageColor)
                                        }
                                        id="designDrawerPageColor"
                                    />
                                </Box>
                            </Box>
                            <Divider />
                            <Box paddingY={2}>
                                <Typography className="drawerHeadText">
                                    {userLoggedIn?.language_data?.formstudio?.form_collection || "Form Color"}
                                </Typography>
                                <Box className="colorPickerBox">
                                    <Paper className="colorCodePaper">
                                        <Typography
                                            sx={{ textTransform: "uppercase" }}
                                        >
                                            {formColor ===
                                            "var(--default-tertiary-color)"
                                                ? color.tertiaryCode
                                                : formColor}
                                        </Typography>
                                        <Paper
                                            className="colorCodePicker"
                                            sx={{
                                                border: "1px solid white",
                                                backgroundColor: `${formColor}`,
                                            }}
                                        />
                                    </Paper>
                                    <img
                                        id="designDrawerFormColor"
                                        src={ColorPicker}
                                        className="colorImage"
                                        alt="Color picker"
                                        onClick={() =>
                                            handleOpen("formColor", formColor)
                                        }
                                    />
                                </Box>
                            </Box>
                            <Divider />
                            <Box paddingY={2}>
                                <Typography className="drawerHeadText">
                                    {userLoggedIn?.language_data?.formstudio?.font_color || "Font Color"}
                                </Typography>
                                <Box className="colorPickerBox">
                                    <Paper className="colorCodePaper">
                                        <Typography
                                            sx={{ textTransform: "uppercase" }}
                                        >
                                            {fontColor}
                                        </Typography>
                                        <Paper
                                            className="colorCodePicker"
                                            sx={{
                                                border: "1px solid white",
                                                background: fontColor,
                                            }}
                                        />
                                    </Paper>
                                    <img
                                        src={ColorPicker}
                                        className="colorImage"
                                        alt="Color picker"
                                        onClick={() =>
                                            handleOpen("fontColor", fontColor)
                                        }
                                        id="designDrawerFontColor"
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Drawer>
            </Box>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,
    };
};


export default connect(mapStateToProps, null) (DesignDrawer);
