import { getApiCall } from "../../utils/Action";
import { API_CONSTANTS } from "../../config/ApiConstants";

export const GET_DCT_DATA = "GET_DCT_DATA";

const GetDctAssesmentAction = (id)=>{
    return async(dispatch)=>{
        try{
            const response = await getApiCall(API_CONSTANTS.GET_FORM_STUDIO_DATA + `/${id}`);
            if(response?.status === 200){
                console.timeLog(response)
                return dispatch({
                    type: GET_DCT_DATA,
                    payload: response?.data
                });
            }else{
                // toast.error(response?.data?.message);
            }
        }catch(error){
            throw error;
        }
    }
}
export default GetDctAssesmentAction;


