import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Slide from "@mui/material/Slide";
import { getDocumentDatabaseData } from "../../../../../store/Action/DocumentDatabaseAction";
import DocsTable from "./DocsTable";
import ButtonComponent from "../../../../../components/ButtonComponent";
import DialogComponent from "../../../../../components/DialogsBox/DialogComponent";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
function DocsTemplates(props) {
    const {
        ImportContractsAction,
        getDocumentDatabaseData,
        initilizeStates,
        onClose,
        selectedValue,
        setState,
        open,
        documentDatabase,
        customer,
    } = props;
    const location = useLocation();
    const urlData = location.pathname?.split("/");
    const id = urlData[3];
    const guid = urlData[2];
    const [selectedDocumentDatabaseData, setselectedDocumentDatabaseData] =
        useState({
            isCheckedAll: false,
            isChecked: [],
        });
    const { isCheckedAll, isChecked } = selectedDocumentDatabaseData;
    useEffect(() => {
        getDocumentDatabaseData();
    }, [selectedDocumentDatabaseData.isChecked, getDocumentDatabaseData]);

    const handleClose = () => {
        setState((prevState) => ({ ...prevState, openImport: false }));
        onClose(selectedValue);
    };

    async function onClickOnImport() {
        setState((prevState) => ({ ...prevState, openImport: false }));
        onClose(selectedValue);
        await ImportContractsAction({
            guid: customer.guid,
            is_active: true,
            contractor_id: Number(id),
            document_ids: isChecked,
            contract_ids: isChecked,
        });
        setState((prevState) => ({ ...prevState, loading: true }));
        initilizeStates(guid, Number(id));
        setselectedDocumentDatabaseData((prevState) => ({
            ...prevState,
            isChecked: [],
        }));
        setState((prevState) => ({ ...prevState, loading: false }));
    }
    return (
        <DialogComponent
            className="dialogBoxMain"
            open={open}
            direction="up"
            keepMounted
            handleClose={handleClose}
            TransitionComponent={Transition}
            title={"Contract Template"}
            content={
                <DocsTable
                    tableData={documentDatabase}
                    documentDatabase={documentDatabase}
                    selectedDocumentDatabaseData={selectedDocumentDatabaseData}
                    setselectedDocumentDatabaseData={
                        setselectedDocumentDatabaseData
                    }
                    isChecked={isChecked}
                    isCheckedAll={isCheckedAll}
                />
            }
            sxaction={{ px: 3 }}
            action={
                selectedDocumentDatabaseData.isChecked.length !== 0 ? (
                    // <Button
                    //     autoFocus
                    //     className="button_contained"
                    //     onClick={onClickOnImport}
                    // >
                    //     Import
                    // </Button>
                    <ButtonComponent
                        title={"Import"}
                        autoFocus
                        className="button_contained"
                        onClick={onClickOnImport}
                    />
                ) : (
                    <ButtonComponent
                        title={"Cancel"}
                        autoFocus
                        className="button_contained"
                        onClick={onClickOnImport}
                    />
                    // <Button
                    //     autoFocus
                    //     className="button_contained"
                    //     onClick={handleClose}
                    // >
                    //     Cancel
                    // </Button>
                )
            }
        />
    );
}
const mapStateToProps = (state) => {
    return {
        saveTemplateId: state?.saveTemplateId,
        emailTemplateList: state?.contractor?.emailTemplateList,
        user: state?.user?.selectedUser?.id,
        customer: state?.customer?.selectedCustomer,
        documentDatabase: state?.docDatabase?.documentDatabase,
    };
};
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getDocumentDatabaseData,
        },
        dispatch
    );
};
export default connect(mapStateToProps, mapDispatchToProps)(DocsTemplates);
