import React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ShareFormBuilderDialog from "./ShareFormBuilderDialog";
import { Translate } from 'react-auto-translate';
import ButtonComponent from "../../components/ButtonComponent";
import { getFormById, makeDefault } from "../../store/Action/FormStudioAction";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
const ViewHeader = (props) => {
    const {
        item,
        handlerEditForm,
        handlerRemoveForm,
        setViewFormState,
        domain,
        makeDefault,
        formDetail,
        getFormById
    } = props;

    const [state, setState] = useState({
        anchorEl: null,
        openShareDialog: { open: false, data: null },
        copied: false,
        formShareLink: null,
    });

    useEffect(() => {
        setState((prevState) => ({
            ...prevState,
            formShareLink: `https://${domain ? `${domain}.` : ""
                }retengine.co/form-submission/${item?.id}`,
        }));
    }, [item?.id]);

    const { openShareDialog } = state;

    const handleClick = (event) => {
        setState((prevState) => ({
            ...prevState,
            anchorEl: event.currentTarget,
        }));
    };

    const handleClose = () => {
        setState((prevState) => ({
            ...prevState,
            anchorEl: null,
        }));
    };

    const handlerOpenShareDialog = (item) => {
        setState((prevState) => ({
            ...prevState,
            openShareDialog: { open: true, data: item },
        }));
    };

    const handlerOpenDownloadDialog = (item) => {
        setViewFormState((prevState) => ({
            ...prevState,
            openDownloadDialog: { open: true, data: item },
        }));
    };

    const sendDefaultId = async(item) => {
      const res = await makeDefault(item);
      await getFormById(item) 
      console.log(res)
    }
    

    return (
        <>
            <ShareFormBuilderDialog
                open={openShareDialog?.open}
                setState={setState}
                state={state}
            />
            <Box display={"flex"} sx={{ marginY: "25px" }}>
                <Grid
                    container
                    sx={{
                        justifyContent: "space-between",
                        alignContent: "center",
                        alignItems: "flex-start",
                    }}
                >
                    <Grid
                        item
                        xs={12}
                        md={8}
                        gap={"20px"}
                        display={"flex"}
                        alignItems={"flex-start"}
                    >
                        <Typography className="formName">
                            <Translate>{item?.name}</Translate>
                        </Typography>
                        <IconButton
                            id="viewHeaderFormName"
                            onClick={handleClick}
                        >
                            <MoreVertIcon />
                        </IconButton>
                        <Menu
                            id="viewHeaderMenus"
                            anchorEl={state.anchorEl}
                            open={state.anchorEl}
                            onClose={handleClose}
                            MenuListProps={{
                                "aria-labelledby": "basic-button",
                            }}
                        >
                            <MenuItem
                                id="viewHeaderEdit"
                                onClick={handlerEditForm}
                            >
                                <Translate>Edit Form</Translate>
                            </MenuItem>
                            <MenuItem
                                id="viewHeaderRomove"
                                onClick={() =>
                                    handlerRemoveForm(item?.id, item?.name)
                                }
                            >
                                <Translate>Delete Form</Translate>
                            </MenuItem>
                            <MenuItem
                                id="viewHeaderShare"
                                onClick={() => handlerOpenShareDialog(item)}
                            >
                                <Translate>Share</Translate>
                            </MenuItem>
                            <MenuItem
                                id="viewHeaderExport"
                                onClick={() => handlerOpenDownloadDialog(item)}
                            >
                                <Translate>Export</Translate>
                            </MenuItem>
                        </Menu>
                        <ButtonComponent
                            id="formStudioSaveButton"
                            variant={formDetail?.default == 1 ? 'outlined' : "contained"}
                            title={formDetail?.default == 1 ? 'Default' : 'Make Default'}
                            disabled= {formDetail?.default == 1 ? true : false}
                            onClick={() => sendDefaultId(item.id)}
                        />
                    </Grid>
                    <Grid item display={"flex"} gap={"40px"}>
                        <Box display={"flex"} gap={2}>
                            <Typography className="formLabel">
                                <Translate>Created by:</Translate>
                            </Typography>
                            <Typography className="formText">
                                {item?.user?.display_name}
                                &nbsp; &nbsp; &nbsp;
                                {item?.created_at}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>

    );
};

const mapStateToProps = (state) => {
    return {
       
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        { makeDefault, getFormById },
        dispatch
    );
};
export default connect(null, mapDispatchToProps) (ViewHeader);
