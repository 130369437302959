import React, { Fragment, useEffect, useState } from "react";
import Table from "../../../../../components/Table/TableComponent";
import { Grid } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Link from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as DeleteIcon } from "../../../../../../assets/Images/Icons/DeleteIcon.svg";
import Button from "../../../../../components/ButtonComponent";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../../../router/RouteList";
import { Translate } from "react-auto-translate/lib/commonjs";

const ModulesPermissions = (props) => {
    const handleArchieve = () => {};
    const navigate = useNavigate();
    const userList = useSelector((state) => state.user.usersList);
    const [modulePermissionState, setModulePermission] = useState({
        headerList: [
            {
              name: (
                <Translate>
                  Username
                </Translate>
              ),
              headerClass: "tableHeading",
              accessibleKey: "display_name",
              rowClass: "typo4",
              headerAlign: "center",
              rowAlign: "center",
            },
            {
              name: (
                <Translate>
                  Role
                </Translate>
              ),
              headerClass: "tableHeading",
              accessibleKey: "role",
              rowClass: "customerName",
              headerAlign: "center",
              rowAlign: "center",
            },
          ],
        extraHeaderRight: [],
        tableList: [],
        extraTableList: [],
        isMount: false,
        showAssign: false,
    });
    const setting = (
        <Link fontSize={16} sx={{ cursor: "pointer" }}>
            Setting
        </Link>
    );
    const handlerSetting = (user, service) => {
        return (
            <Link
                fontSize={16}
                sx={{ cursor: "pointer" }}
                onClick={() => {
                    navigate(ROUTES.SETTINGS + "/" + service + "/" + user.id, {
                        state: { userData: user, service: service },
                    });
                }}
            >
                Setting
            </Link>
        );
    };
    useEffect(() => {
        if (userList !== null && userList?.length) {
            const complianceServies =
                userList[0]?.compliance_service?.split(",");
            if (complianceServies !== undefined) {
                const headers = [
                    ...complianceServies.map((services) => {
                        return {
                            name: services,
                            headerClass: "tableHeading",
                            accessibleKey: services.toLowerCase(),
                            rowClass: "typo4",
                            headerAlign: "center",
                            rowAlign: "center",
                        };
                    }),
                ];
                const tableList = [
                    ...userList.map((user) => {
                        let listObj = {
                            display_name: user.display_name,
                            role: user.role,
                        };
                        complianceServies.forEach((service) => {
                            listObj[service.toLowerCase()] = handlerSetting(
                                user,
                                service
                            );
                        });
                        return listObj;
                    }),
                ];
                if (!modulePermissionState.isMount) {
                    setModulePermission({
                        ...modulePermissionState,
                        isMount: true,
                        headerList: [
                            ...modulePermissionState.headerList,
                        ].concat(headers),
                        tableList: [...tableList],
                    });
                }
            }
        }
    }, [userList]);
    const actionList = [
        {
            id: 1,
            iconClass: "iconButton",
            type: "icon",
            name: <DeleteIcon />,
            handleButton: handleArchieve,
            imgClass: "",
        },
    ];

    return (
        <Fragment>
            <Grid container>
                <Grid lg={12} md={12} item>
                    <Table
                        headerList={modulePermissionState.headerList}
                        tableList={modulePermissionState.tableList}
                        extraHeaderRight={
                            <TableCell align="center" className="tableHeading">
                               <Translate>Action</Translate> 
                            </TableCell>
                        }
                        actions={actionList}
                        sx={{
                            boxShadow:
                                "0px 0px 10px 4px rgba(59, 59, 59, 0.1) !important",
                            borderRadius: "10px !important",
                        }}
                    >
                        {modulePermissionState.tableList.length > 0
                            ? modulePermissionState.tableList.map(
                                  (item, index) => {
                                      const objKeys = Object.keys(item);
                                      return (
                                          <TableRow
                                              key={index}
                                              className={
                                                  index % 2 !== 0
                                                      ? "tertiaryBackground"
                                                      : ""
                                              }
                                          >
                                              {objKeys.map((cell, index) => {
                                                  return (
                                                      <TableCell
                                                          className="typo4"
                                                          align="center"
                                                          key={index}
                                                      >
                                                          {item[cell]}
                                                      </TableCell>
                                                  );
                                              })}
                                              {actionList.map((item, index) => {
                                                  return (
                                                      <TableCell
                                                          className="typo4"
                                                          align="center"
                                                          key={index}
                                                      >
                                                          <IconButton>
                                                              <Translate>{item?.name}</Translate>
                                                          </IconButton>
                                                      </TableCell>
                                                  );
                                              })}
                                          </TableRow>
                                      );
                                  }
                              )
                            : ""}
                    </Table>
                </Grid>
                {modulePermissionState.showAssign && (
                    <Grid item lg={12} md={12} textAlign="right" paddingTop={2}>
                        <Button
                            title="Assign User"
                            sx={{
                                paddingInline: "15px !important",
                                fontSize: "20px !important",
                            }}
                        />
                    </Grid>
                )}
            </Grid>
        </Fragment>
    );
};

export default ModulesPermissions;
