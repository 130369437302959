import React, { Fragment, useEffect, useState } from "react";
import TitleDescription from "./TitleDescription";
import DropDown from "../../../../../components/DropDown/DropDownComponent";
import { Grid } from "@mui/material";
import { Typography } from "@mui/material";
import { Icon } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "../../../../../components/ToolTip/TooltipComponent";
import Button from "../../../../../components/ButtonComponent";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../../../router/RouteList";

const AgreementCommon = (props) => {
    const {
        title,
        showTitle,
        showTitleToolTip,
        toolTipTitle,
        options,
        isTestSettings,
        testLawSettings,
        stateList,
        agreementSettings,
        isDefaultSetting,
        type,
        setStateDefaultSetting,
        defaultSettingOptionId,
        defaultComplianceSelectData,
        editButton,
        saveButton,
        state,
        handlerSetupQuestion,
        setStateTestLawSetting,
        userLoggedIn,
    } = props;

    const navigate = useNavigate();
    const [approchData, setApprochData] = useState({
        id: "",
        type: "",
        option: "",
        rules: [],
        summary: "",
        content: "",
        admin_burden: "",
        risk: "",
        recommended_option: "",
        show_states: false,
        selectdObject: [],
    });
    const { id } = approchData;
    const emailIds = [3, 4, 8, 9];
    const [showEmail, setShowEmail] = useState(false);
    const [rules, setRules] = useState("");
    const [stateMenu, setStateMenu] = useState("");
    useEffect(() => {
        if (isTestSettings && id === "" && testLawSettings) {
            setApprochData({ ...approchData, ...testLawSettings });
        }
        if (
            isDefaultSetting &&
            approchOptions?.length &&
            agreementSettings?.length &&
            id === "" &&
            defaultSettingOptionId
        ) {
            const approchFilterData = approchOptions.find(
                (item) => item.id === defaultSettingOptionId
            );
            setApprochData({ ...approchData, ...approchFilterData });
        }
    }, [testLawSettings, defaultSettingOptionId]);

    const approchOptions = isDefaultSetting
        ? options
        : agreementSettings.filter((item) => {
              return item.type === type;
          });
    const applyRules = isDefaultSetting
        ? []
        : approchData.rules.map((rule) => (
              <MenuItem key={rule} value={rule}>
                  {rule}
              </MenuItem>
          ));
    const approchChangeHandler = (e) => {
        const approchFilterData = approchOptions.find(
            (item) => item.id === e.target.value
        );
        const emailId = emailIds.find((id) => id === approchFilterData.id);
        if (emailId !== undefined) {
            setShowEmail(true);
        } else {
            setShowEmail(false);
        }
        setApprochData({ ...approchData, ...approchFilterData });
        if (isDefaultSetting)
            setStateDefaultSetting({
                ...state,
                defaultComplianceSettingDetails: {
                    ...state.defaultComplianceSettingDetails,
                    defautIDOption: parseInt(e.target.value),
                },
            });
    };
    const rulesChangeHandler = (event, applyType, id) => {
        const copyData = state?.testLawDetails?.settings;
        let filterIndex;
        if (copyData !== undefined) {
            filterIndex = copyData.findIndex((obj) => obj?.master_id === id);
        }
        let updateDetails;
        if (filterIndex != -1) {
            if (applyType == "rule") {
                setRules(event.target.value);
                if (copyData !== undefined) {
                    updateDetails = {
                        ...copyData[filterIndex],
                        rule: event.target.value,
                    };
                }
            }
            if (applyType == "state") {
                setStateMenu(event.target.value);
                if (copyData !== undefined) {
                    updateDetails = {
                        ...copyData[filterIndex],
                        state: event.target.value,
                    };
                }
            }
            if (copyData !== undefined) {
                const updateArray = [
                    ...copyData.slice(0, filterIndex),
                    updateDetails,
                    ...copyData.slice(filterIndex + 1),
                ];
                setStateTestLawSetting((prevState) => ({
                    ...prevState,
                    testLawDetails: {
                        ...state?.testLawDetails,
                        settings: updateArray,
                    },
                }));
            }
        }
    };
    const fetchRuleValue = (id) => {
        const newData =
            state?.testLawDetails &&
            state?.testLawDetails?.settings?.find(
                (obj) => obj?.master_id === id
            );
        return newData?.rule || rules;
    };
    const fetchStateValue = (id) => {
        const newData = state?.testLawDetails?.settings?.find(
            (obj) => obj?.master_id === id
        );
        return newData?.state || stateMenu;
    };

    return (
        <Fragment>
            <Grid container spacing={1}>
                {showTitle && (
                    <Grid item lg={12} md={12}>
                        <Typography
                            className="font_16_600"
                            lineHeight={3}
                            variant="span"
                        >
                            {title}
                        </Typography>
                        {showTitleToolTip && (
                            <Tooltip
                                arrow={true}
                                title={toolTipTitle}
                                placement="right"
                                sx={{
                                    left: 20,
                                }}
                            >
                                <Icon className="infoIcon">
                                    <InfoIcon />
                                </Icon>
                            </Tooltip>
                        )}
                    </Grid>
                )}
                <Grid item lg={5} md={5}>
                    <Grid container spacing={2} paddingRight={2}>
                        {!isTestSettings &&
                            state?.defaultComplianceSettingDetails
                                ?.defaultOption !== 4 && (
                                <Grid item lg={12} md={12}>
                                    <DropDown
                                        fullWidth={true}
                                        label={
                                            !isDefaultSetting
                                                ? "Approach"
                                                : "Select Option"
                                        }
                                        onChange={approchChangeHandler}
                                        defaultValue={defaultSettingOptionId}
                                        value={defaultSettingOptionId}
                                    >
                                        {approchOptions.map((item) => {
                                            return (
                                                <MenuItem
                                                    key={item.id}
                                                    value={item.id}
                                                >
                                                    {item.option}
                                                </MenuItem>
                                            );
                                        })}
                                    </DropDown>
                                </Grid>
                            )}
                        {!isDefaultSetting && approchData.id !== 0 && (
                            <Grid item lg={12} md={12}>
                                <DropDown
                                    fullWidth={true}
                                    label="Apply Rules"
                                    onChange={(e) =>
                                        rulesChangeHandler(
                                            e,
                                            "rule",
                                            approchData?.id
                                        )
                                    }
                                    value={fetchRuleValue(approchData?.id)}
                                >
                                    {applyRules}
                                </DropDown>
                            </Grid>
                        )}
                        <Grid item lg={12} md={12}>
                            {approchData.show_states &&
                                approchData.id !== 0 && (
                                    <DropDown
                                        fullWidth={true}
                                        label={
                                            userLoggedIn?.language_data
                                                ?.complianceworkflow?.state ||
                                            "State"
                                        }
                                        onChange={(e) =>
                                            rulesChangeHandler(
                                                e,
                                                "state",
                                                approchData?.id
                                            )
                                        }
                                        value={fetchStateValue(approchData?.id)}
                                    >
                                        {stateList.map((state) => {
                                            return (
                                                <MenuItem
                                                    value={state.id}
                                                    key={state.id}
                                                >
                                                    {state.label}
                                                </MenuItem>
                                            );
                                        })}
                                    </DropDown>
                                )}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item lg={7} md={7}>
                    <Grid container spacing={2}>
                        {state?.defaultComplianceSettingDetails
                            ?.defaultOption !== 4 &&
                            approchData.summary !== "" && (
                                <Grid item lg={12} md={12}>
                                    <TitleDescription
                                        title={
                                            userLoggedIn?.language_data
                                                ?.documentdatabase
                                                ?.add_success || "Summary"
                                        }
                                        description={approchData.summary}
                                    />
                                </Grid>
                            )}
                        {state?.defaultComplianceSettingDetails
                            ?.defaultOption !== 4 &&
                            approchData.content !== "" && (
                                <Grid item lg={12} md={12}>
                                    <TitleDescription
                                        title="Description"
                                        description={approchData.content}
                                    />
                                </Grid>
                            )}
                        {!isDefaultSetting ?? (
                            <Grid item lg={12} md={12}>
                                <Grid
                                    container
                                    spacing={4}
                                    sx={{ marginTop: "-24px" }}
                                >
                                    <Grid item lg={4} md={4}>
                                        {approchData.admin_burden !== "" && (
                                            <TitleDescription
                                                title="Administrative Burden"
                                                description={
                                                    approchData.admin_burden
                                                }
                                            />
                                        )}
                                    </Grid>
                                    <Grid item lg={3} md={3}>
                                        {approchData.risk !== "" && (
                                            <TitleDescription
                                                title="Risk Management"
                                                description={approchData.risk}
                                            />
                                        )}
                                    </Grid>
                                    <Grid item lg={5} md={5}>
                                        {approchData.recommended_option !==
                                            "" && (
                                            <TitleDescription
                                                title="Highest Recommended Option"
                                                description={
                                                    approchData.recommended_option
                                                }
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
            <Grid spacing={3} container marginTop={2}>
                {showEmail && !isDefaultSetting && (
                    <Grid item lg={12} md={12} textAlign="right" mr={2}>
                        <Button
                            title="Send Email"
                            sx={{
                                fontSize: "20px !important",
                                paddingInline: "15px !important",
                            }}
                            onClick={() => navigate(ROUTES.WORKFLOW_EMAIL)}
                        />
                        <Tooltip
                            arrow={true}
                            title="Content of Email sent to Contractor (if applicable)"
                            placement="top"
                            sx={{
                                left: 10,
                                top: 5,
                            }}
                        >
                            <Icon className="infoIcon">
                                <InfoIcon />
                            </Icon>
                        </Tooltip>
                    </Grid>
                )}
                {isDefaultSetting &&
                    approchData.id !== 0 &&
                    defaultSettingOptionId !== 5 &&
                    state?.defaultComplianceSettingDetails?.defaultOption !==
                        4 && (
                        <Grid
                            container
                            display={"flex"}
                            justifyContent={"flex-end"}
                            gap={2}
                        >
                            <Button
                                title="Setup Questions"
                                onClick={handlerSetupQuestion}
                            />
                            {defaultComplianceSelectData
                                ? editButton()
                                : saveButton()}
                        </Grid>
                    )}
            </Grid>
        </Fragment>
    );
};

AgreementCommon.defaultProps = {
    showTitle: true,
    showTitleToolTip: true,
    isJurisdiction: false,
    isTestSettings: false,
    isDefaultSetting: false,
};

const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,
        stateList: state.states.list,
        agreementSettings: state.agreementSettings.settings,
        defaultComplianceSelectData:
            state.defaultComplianceSettings.defaultComplianceSelectData,
        testLawSelecteDetails: state.testLawSettings.modules,
    };
};

export default connect(mapStateToProps, null)(AgreementCommon);
