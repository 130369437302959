import * as Actions from '../Action/DocumentsAction'
import {b64DecodeUnicode} from '../../components/Base64'

const initialState = {
    documentsList:[],
	documentFileList:null,
	documentFile:null,
	fileContent:null,
};

const DocumentsReducer = (state = initialState, action) => {
	switch (action?.type) {
		case Actions.GET_DOCUMENTS_ACTION: {
			return {
				...state,
				documentsList:action.payload
			};
		}
		case Actions.ADD_DOCUMENT_ACTION: {
			return {
				...state,
				documentsList:action.payload
			};
		}
		case Actions.UPDATE_DOCUMENTS_ACTION: {
			return {
				...state,
				documentsList:action.payload
			};
		}	
		case Actions.DOCUMENTS_ACTION: {
			return {
				...state,
				documentsList:action.payload
			};	
		}
		case Actions.UPLOAD_DOCUMENT_ACTION: {
			let fileContent=null;
			if(action?.payload){
				fileContent=b64DecodeUnicode(action?.payload?.html);
			}
			return {
				...state,
				documentFile:action.payload,
				fileContent:fileContent,
			};	
		}
		case Actions.GET_FILES_LIST_ACTION: {
			return {
				...state,
				documentFileList:action.payload,
			};	
		}

		default: {
			return state;
		}
	}
};

export default DocumentsReducer; 