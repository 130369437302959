import * as React from "react";
import Box from "@mui/material/Box";
import RenderElements from "./RenderElements";
import NoInfoAvailable from "../../components/NoInfoAvailable";
import ButtonComponent from "../../components/ButtonComponent";
import DialogComponent from "../../components/DialogsBox/DialogComponent";

const FormTemplatePreview = (props) => {
    const { open, setState, state } = props;
    const { formName } = state;
    const handleClose = () => {
        setState((prevState) => ({
            ...prevState,
            htmlData: null,
            formTemplateDialog: false,
        }));
    };

    return (
        <DialogComponent
            open={open}
            maxWidth={"md"}
            sx={{ "& .MuiPaper-root": { width: "800px" } }}
            title={formName || "Form Preview"}
            sxContent={{
                background: state.pageColor,
                paddingX: "60px",
                paddingBottom: "40px",
                paddingTop: "40px",
            }}
            content={
                state?.formElements?.length != 0 ? (
                    <RenderElements
                        formElements={state?.formElements}
                        state={state}
                        isRead={true}
                    />
                ) : (
                    <Box className="centerPositionBox" sx={{ height: "300px" }}>
                        <NoInfoAvailable />
                    </Box>
                )
            }
            action={
                <ButtonComponent
                    title={"Close"}
                    id="formTemPreviewClose"
                    onClick={handleClose}
                />
            }
            handleClose={handleClose}
        />
    );
};

export default FormTemplatePreview;
