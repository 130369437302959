import React, { useEffect, useState, useRef } from "react";
import Box from "@mui/system/Box";
import Grid from "@mui/material/Grid";
import TooltipComponent from "../../../../../components/ToolTip/TooltipComponent";
import toast from "react-hot-toast";
import TextFieldComponent from "../../../../../components/TextField/TextFieldComponent";
import Typography from "@mui/material/Typography";
import DialogContentText from "@mui/material/DialogContentText";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { sendContractorEmail } from "../../../../../store/Action/ContractorEmailAction";
import validator from "validator";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ButtonComponent from "../../../../../components/ButtonComponent";
import SunEditorComponent from "../../../../../components/SunEditorComponent";
import DialogComponent from "../../../../../components/DialogsBox/DialogComponent";

const ResendEmailModel = (props) => {
    const {
        open,
        resendId,
        commonData,
        sendContractorEmail,
        emailTemplateHistoryDetail,
        setState,
        userLoggedIn,
        
    } = props;
    const [form, setForm] = useState({
        saveTemplateId: "",
        from_address: "",
        to_address: "",
        cc_address: "",
        bcc_address: "",
        email_subject: "",
        template_content: "",
        template_name: "",
        contractor_id: commonData.contractor_id,
        client_id: commonData.client_id,
        contractor_email_id: null,
        editorContent: null,
        focused: false,
    });
    const {
        from_address,
        to_address,
        cc_address,
        bcc_address,
        email_subject,
        template_content,
        template_name,
        editorContent,
        focused,
        contractor_id,
        id,
    } = form;
    const emailTemplateHistoryData = emailTemplateHistoryDetail?.find(
        (obj) => obj.id === resendId
    );
    useEffect(() => {
        if (resendId) {
            setForm((prevState) => ({
                ...prevState,
                template_name: emailTemplateHistoryData?.template_name,
                email_subject: emailTemplateHistoryData?.email_subject,
                editorContent: emailTemplateHistoryData?.message_body,
                id: emailTemplateHistoryData?.contractor_email_id,
                from_address: emailTemplateHistoryData?.from_address,
                to_address: emailTemplateHistoryData?.to_address,
                cc_address: emailTemplateHistoryData?.cc_address,
                bcc_address: emailTemplateHistoryData?.bcc_address,
            }));
        }
    }, [resendId]);
    const editor = useRef();
    const getSunEditorInstance = (sunEditor) => {
        editor.current = sunEditor;
    };
    const handleClickEditor = (e) => {};
    const handleChange = (content) => {
        setForm((prevState) => ({
            ...prevState,
            template_content: content,
            editorContent: content,
        }));
    };
    const handleClose = () => {
        setState((prevState) => ({ ...prevState, openResend: false }));
    };
    const handlerChages = (e) => {
        setForm((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const tosterLoder = toast.loading("Please Wait...");
        const res = await sendContractorEmail(form);
        if (res?.payload?.status === 200) {
            toast.dismiss(tosterLoder);
            setState((prevState) => ({
                ...prevState,
                openSuccess: true,
                content: res?.payload?.message,
            }));
            setForm((prevState) => ({
                ...prevState,
                template_name: "",
                email_subject: "",
                template_content: "",
                editorContent: "",
                from_address: "",
                to_address: "",
                cc_address: "",
                bcc_address: "",
            }));
        } else {
            toast.dismiss(tosterLoder);
            // toast.error("Something Went Wrong");
        }
        setState((prevState) => ({ ...prevState, openResend: false }));
    };
    const checkValidation = (data) => {
        if (!data) return false;
        else return validator.isEmail(data);
    };
    const ErrorTooltip = (props) => {
        const { data } = props;
        return (
            <>
                {checkValidation(data) ? (
                    ""
                ) : (
                    <TooltipComponent
                        className=""
                        title={<Typography>Incorrect Email</Typography>}
                        placement="right"
                        arrow
                    >
                        <InfoOutlinedIcon sx={{ color: "#E05D5D" }} />
                    </TooltipComponent>
                )}
            </>
        );
    };
    return (
        <div>
            <DialogComponent
                open={open}
                handleClose={handleClose}
                ariaLabelledby="alert-dialog-title"
                ariaDescribedby="alert-dialog-description"
                sx={{
                    "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "800px", // Set your width here
                    },
                }}
                title={template_name}
                content={
                    <>
                        <DialogContentText id="alert-dialog-description">
                            <Box
                                paddingX={3}
                                sx={{
                                    py: 2,
                                    gap: "5px",
                                }}
                                className="emailHeadSection"
                            >
                                <Grid container display={"flex"} spacing={1}>
                                    <Grid
                                        item
                                        xs={12}
                                        display={"flex"}
                                        gap={3}
                                        alignItems={"center"}
                                    >
                                        <Grid item xs={1}>
                                            <Typography className="emailTextFieldLabel">
                                                From:
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={10}
                                            md={8}
                                            xl={8}
                                            display={"flex"}
                                            gap={1}
                                            alignItems={"center"}
                                        >
                                            <TextFieldComponent
                                                id="resendEmailFromAddress"
                                                name="from_address"
                                                value={from_address}
                                                onChange={handlerChages}
                                                fullWidth
                                                size="small"
                                                className="emailSectionTextField"
                                                placeholder="contractor@example.com"
                                                variant="outlined"
                                                sx={{
                                                    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
                                                        {
                                                            border: "1.5px solid #E05D5D",
                                                        },
                                                }}
                                                error={
                                                    from_address
                                                        ? !checkValidation(
                                                              from_address
                                                          )
                                                        : false
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={1} md={1} xl={1}>
                                            {from_address ? (
                                                <ErrorTooltip
                                                    data={from_address}
                                                />
                                            ) : null}
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        display={"flex"}
                                        gap={3}
                                        alignItems={"center"}
                                    >
                                        <Grid item xs={1}>
                                            <Typography className="emailTextFieldLabel">
                                                {userLoggedIn?.language_data?.emailtemplates?.to || "To"}:
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={11}
                                            md={8}
                                            xl={8}
                                            display={"flex"}
                                            gap={1}
                                            alignItems={"center"}
                                        >
                                            <TextFieldComponent
                                                fullWidth
                                                className="emailSectionTextField"
                                                id="resendEmailToAddress"
                                                name="to_address"
                                                value={to_address}
                                                onChange={handlerChages}
                                                size="small"
                                                placeholder="john@example.com"
                                                variant="outlined"
                                                sx={{
                                                    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
                                                        {
                                                            border: "1.5px solid #E05D5D",
                                                        },
                                                }}
                                                error={
                                                    to_address
                                                        ? !checkValidation(
                                                              to_address
                                                          )
                                                        : false
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={1} md={1} xl={1}>
                                            {to_address ? (
                                                <ErrorTooltip
                                                    data={to_address}
                                                />
                                            ) : null}
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        display={"flex"}
                                        gap={3}
                                        alignItems={"center"}
                                    >
                                        <Grid item xs={1}>
                                            <Typography className="emailTextFieldLabel">
                                                Cc:
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={11}
                                            md={8}
                                            xl={8}
                                            display={"flex"}
                                            gap={1}
                                            alignItems={"center"}
                                        >
                                            <TextFieldComponent
                                                fullWidth
                                                className={` ${
                                                    !checkValidation(cc_address)
                                                        ? "emailSectionTextField"
                                                        : "emailError"
                                                }`}
                                                id="resendEmailCcAddress"
                                                name="cc_address"
                                                value={cc_address}
                                                onChange={handlerChages}
                                                size="small"
                                                placeholder="john@example.com"
                                                variant="outlined"
                                                error={
                                                    cc_address
                                                        ? !checkValidation(
                                                              cc_address
                                                          )
                                                        : false
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={1} md={1} xl={1}>
                                            {cc_address ? (
                                                <ErrorTooltip
                                                    data={cc_address}
                                                />
                                            ) : null}
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        display={"flex"}
                                        gap={3}
                                        alignItems={"center"}
                                    >
                                        <Grid item xs={1}>
                                            <Typography className="emailTextFieldLabel">
                                                Bcc:
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={11}
                                            md={8}
                                            xl={8}
                                            display={"flex"}
                                            gap={1}
                                            alignItems={"center"}
                                        >
                                            <TextFieldComponent
                                                fullWidth
                                                className="emailSectionTextField"
                                                id="resendEmailBccAddress"
                                                name="bcc_address"
                                                value={bcc_address}
                                                onChange={handlerChages}
                                                size="small"
                                                placeholder="john@example.com"
                                                variant="outlined"
                                                sx={{
                                                    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
                                                        {
                                                            border: "1.5px solid #E05D5D",
                                                        },
                                                }}
                                                error={
                                                    bcc_address
                                                        ? !checkValidation(
                                                              bcc_address
                                                          )
                                                        : false
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={1} md={1} xl={1}>
                                            {bcc_address ? (
                                                <ErrorTooltip
                                                    data={bcc_address}
                                                />
                                            ) : null}
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        display={"flex"}
                                        gap={3}
                                        alignItems={"center"}
                                    >
                                        <Grid item xs={1}>
                                            <Typography className="emailTextFieldLabel">
                                            {userLoggedIn?.language_data?.emailtemplates?.subject || "Subject"}:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={11} md={8} xl={8}>
                                            <TextFieldComponent
                                                fullWidth
                                                className="emailSectionTextField"
                                                id="resendEmailSubject"
                                                size="small"
                                                placeholder="Invitation mail"
                                                variant="outlined"
                                                onChange={handlerChages}
                                                name="email_subject"
                                                value={email_subject}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box className="emailSectionEditor">
                                <SunEditorComponent
                                    buttonList={true}
                                    setDefaultStyle="font-size: 14px;"
                                    width="100%"
                                    height="220px"
                                    getSunEditorInstance={getSunEditorInstance}
                                    onChange={handleChange}
                                    defaultValue={editorContent}
                                    setContents={editorContent}
                                    onClick={handleClickEditor}
                                />
                            </Box>
                        </DialogContentText>
                    </>
                }
                sxaction={{ paddingX: 4, paddingBottom: 2 }}
                action={
                    <ButtonComponent
                        className="button_contained"
                        disabled={
                            !(
                                checkValidation(to_address) &&
                                checkValidation(from_address) &&
                                (cc_address
                                    ? checkValidation(cc_address)
                                    : true) &&
                                (bcc_address
                                    ? checkValidation(bcc_address)
                                    : true)
                            )
                        }
                        onClick={handleSubmit}
                        title={"Resend"}
                    />
                }
            />
        </div>
    );
};
const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,
        emailTemplateHistoryDetail: state.contractor.emailHistoryList,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ sendContractorEmail }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ResendEmailModel);
