import React from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { connect } from "react-redux";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { ReactComponent as DeleteIcon } from "../../../../../../assets/Images/Icons/DeleteIcon.svg";
import ButtonComponent from "../../../../../components/ButtonComponent";
import CheckboxComponents from "../../../../../components/Checkbox/CheckboxComponent";

const LeftEmailDeleteSection = (props) => {
    const { templateList, commonData, setState, state } = props;
    const { isDeleteCheckAll, isDeleteCheck } = state;
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("sm"));

    const openEmailView = (id) => {
        setState((prevState) => ({ ...prevState, emailId: id }));
    };
    // for delete select checkbox ---
    const handleSelectAll = (e) => {
        setState((prevState) => ({
            ...prevState,
            isDeleteCheckAll: !isDeleteCheckAll,
            deleteId: null,
            isDeleteCheck:
                templateList?.length && templateList.map((li) => li.id),
        }));
        if (isDeleteCheckAll) {
            setState((prevState) => ({ ...prevState, isDeleteCheck: [] }));
        }
    };
    const handleClick = (e) => {
        const { value, checked } = e.target;
        if (!checked) {
            //remove
            setState((prevState) => ({
                ...prevState,
                isDeleteCheck: isDeleteCheck.filter(
                    (item) => item !== Number(value)
                ),
            }));
        } else {
            //add
            setState((prevState) => ({
                ...prevState,
                isDeleteCheck: [...isDeleteCheck, Number(value)],
            }));
        }
    };
    const deleteDataByDeleteIcon = (id, template_name) => {
        setState((prevState) => ({ ...prevState, deleteId: id }));
        romoveTemplate(template_name);
    };
    const romoveTemplate = (templateName) => {
        setState((prevState) => ({
            ...prevState,
            openDelete: true,
            deletedData: {
                id: isDeleteCheck,
                templateName: templateName,
            },
        }));
    };
    return (
        <>
            <Box className="emailTempBox" height={matches ? "670px" : "auto"}>
                <Box
                    sx={{
                        paddingLeft: "40px",
                        paddingRight: "15px",
                        paddingY: "10px",
                    }}
                    display="flex"
                    justifyContent={"space-between"}
                >
                    {templateList?.length > 0 ? (
                        <FormControlLabel
                            value="end"
                            control={
                                <CheckboxComponents
                                    name="selectDeleteAll"
                                    onChange={handleSelectAll}
                                    checked={
                                        templateList?.length > 0
                                            ? isDeleteCheckAll
                                            : false
                                    }
                                />
                            }
                            label={
                                <Typography className="checkboxLable">
                                    Select
                                </Typography>
                            }
                            labelPlacement="Select"
                        />
                    ) : null}
                    <ButtonComponent
                        title={"Delete"}
                        sx={{
                            color: "#E05D5D",
                            textTransform: "capitalize",
                            padding: "0",
                            fontWeight: 600,
                            fontSize: "16px",
                            lineHeight: "20px",
                            textDecorationLine: "underline",
                            display:
                                templateList?.length > 0
                                    ? isDeleteCheck.length !== 0
                                        ? "block"
                                        : "none"
                                    : "none",
                        }}
                        onClick={() => {
                            romoveTemplate("All Selected ");
                        }}
                    />
                </Box>
                <Divider />
                <Grid
                    container
                    sx={{
                        paddingLeft: "40px",
                        paddingRight: "15px",
                        paddingY: "5px",
                    }}
                >
                    {templateList?.length
                        ? templateList.map((data, index) => (
                              <Grid
                                  key={index}
                                  item
                                  xs={12}
                                  display="flex"
                                  justifyContent={"space-between"}
                              >
                                  <Box display={"flex"} alignItems={"center"}>
                                      <FormControlLabel
                                          value={data?.id}
                                          control={
                                              <CheckboxComponents
                                                  checked={isDeleteCheck.includes(
                                                      data?.id
                                                  )}
                                                  onChange={handleClick}
                                                  value={data?.id}
                                              />
                                          }
                                          labelPlacement="Select"
                                          style={{ color: "#3B3B3B" }}
                                      />
                                      <Typography
                                          className="checkboxLable"
                                          onClick={() =>
                                              openEmailView(data?.id)
                                          }
                                          sx={{
                                              textTransform: "capitalize",
                                              p: 0,
                                              textAlign: "left",
                                              justifyContent: "left",
                                              width: "150px",
                                          }}
                                      >
                                          {data?.template_name}
                                      </Typography>
                                  </Box>
                                  <Box display={"flex"} alignItems={"center"}>
                                      <IconButton
                                          onClick={() =>
                                              openEmailView(data?.id)
                                          }
                                          aria-label="delete"
                                      >
                                          <EditOutlinedIcon className="icon" />
                                      </IconButton>
                                      <IconButton
                                          onClick={() =>
                                              deleteDataByDeleteIcon(
                                                  data?.id,
                                                  data?.template_name
                                              )
                                          }
                                          aria-label="delete"
                                      >
                                          <DeleteIcon className="icon" />
                                      </IconButton>
                                  </Box>
                              </Grid>
                          ))
                        : null}
                </Grid>
            </Box>
        </>
    );
};
const mapStateToProps = (state) => {
    return {
        templateList: state.contractor.emailTemplateList,
    };
};
export default connect(mapStateToProps)(LeftEmailDeleteSection);
