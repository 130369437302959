import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Dialog from "@mui/material/Dialog";

export const temp_text = {
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "20px",
    color: "#3B3B3BB2",
    paddingLeft: "0px",
    textTransform: "none",
    "&:hover": {
        backgroundColor: "#FFFFFFCC",
    },
};
export const heading1_text = {
    color: "#3B3B3B !important",
    fontFamily: "Source Sans Pro",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "20px",
};
export const heading2_text = {
    color: "#3B3B3B !important",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "20px",
};

export const workflow_heading1_text = {
    color: "#3B3B3B !important",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "22px",
    marginTop: "20px",
};
export const workflow_heading2_text = {
    color: "#3B3B3B !important",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "16px",
    marginTop: "15px",
};
export const style = {
    boxShadow: 3,
    width: "100%",
    height: "5rem",
    bgcolor: (theme) => (theme.palette.mode === "dark" ? "#101010" : "#fff"),
    color: (theme) => (theme.palette.mode === "dark" ? "grey.300" : "grey.800"),
    p: 1,
    pl: 2,
    borderRadius: 1,
};
export const textField_style = {
    fontFamily: "Source Sans Pro",
    fontSize: 18,
    fontWeight: "600",
    minWidth: "500px",
};

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        fontWeight: 600,
        fontSize: "18px",
        backgroundColor: "#F2F2F2",
        color: "#76520E",
        border: 1,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
    },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
        backgroundColor: "#F2F2F2",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 1,
    },
}));

export const DialogStyles = styled(Dialog)(({ theme }) => ({
    "& .css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
        borderRadius: "25px !important",
    },
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));
