import React, { useEffect, useState } from "react";
import Navbar from "../../../../../common/Header/Navbar";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import "../../../../../../assets/styles/Container/PermissionSetting.scss";
import ButtonComponent from "../../../../../components/ButtonComponent";
import RolesPermissionTable from "../../../../ManageRoles/RolesPermissionTable";
import { useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    getRolePermissions,
    updateRolePermission,
} from "../../../../../store/Action/RolesModuleAction";
import LoadingComponent from "../../../../../components/Loading/LoadingComponent";

const PermissionSetting = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const {
        getRolePermissions,
        moduleList,
        updateRolePermission,
        userLoggedIn,
    } = props;
    const [state, setstate] = useState({
        userData: location?.state?.userData || null,
        service: location?.state?.service || "",
        modulesData: moduleList || null,
        isLoading: false,
    });
    const { userData, service, modulesData, isLoading } = state;
    useEffect(() => {
        getRolePermissions(location?.state?.userData?.role_id);
    }, [getRolePermissions]);

    const handleUpdate = async () => {
        setstate((prevState) => ({ ...prevState, isLoading: true }));
        let data = {
            role_id: location?.state?.userData?.role_id || "",
            modules: modulesData.map((obj) => ({
                module_id: obj.id,
                permissions: {
                    read: obj?.permissions?.read || false,
                    create: obj?.permissions?.create || false,
                    edit: obj?.permissions?.edit || false,
                    delete: obj?.permissions?.delete || false,
                },
            })),
        };
        const res = await updateRolePermission(data);
        if (res) {
            setstate((prevState) => ({ ...prevState, isLoading: false }));
        }
    };

    function handleCancel() {
        navigate(-1);
    }
    return (
        <>
            <Navbar />
            {isLoading ? (
                <LoadingComponent sx={{ height: "600px" }} />
            ) : (
                <Box sx={{ px: { md: 7, xs: 1 }, pb: 7 }}>
                    <Typography className="pcpText">
                        {service} Permission Setting
                    </Typography>
                    <Grid container paddingY={2}>
                        <Grid item xs={12} md={6} lg={6} xl={6}>
                            <Grid item paddingTop={1} className="userItemSec">
                                <Grid item xs={4}>
                                    <Typography className="userDetailLabel">
                                        {userLoggedIn?.language_data?.customer
                                            ?.first_name || "First Name"}
                                        :
                                    </Typography>
                                </Grid>
                                <Typography className="userDetailsTypo">
                                    {userData?.first_name}
                                </Typography>
                            </Grid>
                            <Grid item paddingTop={1} className="userItemSec">
                                <Grid item xs={4}>
                                    <Typography className="userDetailLabel">
                                        Username:
                                    </Typography>
                                </Grid>
                                <Typography className="userDetailsTypo">
                                    {userData?.display_name}
                                </Typography>
                            </Grid>
                            <Grid item paddingTop={1} className="userItemSec">
                                <Grid item xs={4}>
                                    <Typography className="userDetailLabel">
                                    {userLoggedIn?.language_data?.comman?.created_by || "Created By"}:
                                    </Typography>
                                </Grid>
                                <Typography className="userDetailsTypo">
                                    {userData?.user_created?.display_name}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6} xl={6}>
                            <Grid item paddingTop={1} className="userItemSec">
                                <Grid item xs={4}>
                                    <Typography className="userDetailLabel">
                                        {userLoggedIn?.language_data?.customer
                                            ?.last_name || "Last Name"}
                                        :
                                    </Typography>
                                </Grid>
                                <Typography className="userDetailsTypo">
                                    {userData?.last_name}
                                </Typography>
                            </Grid>
                            <Grid item paddingTop={1} className="userItemSec">
                                <Grid item xs={4}>
                                    <Typography className="userDetailLabel">
                                        Role:
                                    </Typography>
                                </Grid>
                                <Typography className="userDetailsTypo">
                                    {userData?.role}
                                </Typography>
                            </Grid>
                            <Grid item paddingTop={1} className="userItemSec">
                                <Grid item xs={4}>
                                    <Typography className="userDetailLabel">
                                        Last Modified By:
                                    </Typography>
                                </Grid>
                                <Typography className="userDetailsTypo">
                                    {userData?.user_modified?.display_name}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <RolesPermissionTable
                        modulesData={moduleList}
                        setState={setstate}
                    />
                    <Box
                        display={"flex"}
                        gap={2}
                        sx={{ justifyContent: "flex-end", my: 2 }}
                    >
                        <ButtonComponent
                            title={"Cancel"}
                            onClick={() => handleCancel()}
                            variant={"outlined"}
                        />
                        <ButtonComponent
                            title={"Update"}
                            onClick={handleUpdate}
                        />
                    </Box>
                </Box>
            )}
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        moduleList: state?.roles?.moduleList,
        userLoggedIn: state.auth?.userLoggedIn,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getRolePermissions,
            updateRolePermission,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(PermissionSetting);
