// export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const BASE_URL = "https://elevance.coreworklab.com/backend/index.php/api/";
// export const BASE_URL = "http://127.0.0.1:8000/api/";
export const API_CONSTANTS = {
    SIGN_UP: "/sign-up",
    // manage roles
    MANAGE_ROLES: `roles`,
    GET_MODULES: `module`,
    CREATE_ROLE: `roles`,
    DELETE_ROLE: `roles`,
    CREATE_ROLE_PERMISSION: `role-permission`,
    UPDATE_ROLE_PERMISSIONS: `role-permission/bulk`, // email templates
    LIST_EMAIL_TEMPLATES: `email/list-template`,
    SEND_EMAIL_TEMPLATES: `email/send-email`,
    LIST_EMAIL_HISTORY: `email/get-emailhistory-log`,
    INSERT_EMAIL_TEMPLATES: `email/store-template`,
    GET_EMAIL_TEMPLATE_DETAILS: `email/get-template-details`,
    UPDATE_TEMPLATE_DETAILS: `email/update-template`,
    DELETE_EMAIL_TEMPLATE: `email/remove-template`,
    GET_EMAIL_TEMPLATES: `email/list-template`,
    GET_EMAIL_STATUS_LIST: `email/get-status-list`,
    GET_CLIENT_LIST: `email/get-users-list`,
    GET_ROLE_PERMISSIONS: `/roles/permissions`,
    //FAQs
    STORE_CATEGORY: `faq-category`,
    FAQS: `faq`,
    //Signed Document
    GET_SIGNED_DOCUMENT_CATEGORY: `signed-document/get-categories`,
    GET_SIGNED_DOCUMENT_DETAIL: `signed-document/get-list`,
    STORE_SIGNED_DOCUMENT_DATA: `signed-document/store`,
    UPDATE_SIGNED_DOCUMENT_DATA: `signed-document/update`,
    GET_SIGNED_DOCUMENT: `signed-document/detail`,
    //Document Database
    GET_DOCUMENT_DATABASE_CATEGORY: `document-database/get-categories`,
    GET_DOCUMENT_DATABASE_DETAIL: `document-database/get-list`,
    STORE_DOCUMENT_DATABASE_DETAILS: `document-database/store`,
    UPDATE_DOCUMENT_DATABASE_DETAILS: `document-database/update`,
    DELETE_DOCUMENT_DATABASE_DETAILS: `document-database/delete`,
    GET_DOCUMENT_DATABASE: `document-database/detail`,
    //Contractor Email Api
    SAVE_CONTRACTOR_EMAIL_TEMPLATE: `save-contractor-email-template`,
    CONTRACTOR_EMAIL_LISTS: `list-contractor-email-templates`,
    GET_CONTRACTOR_TEMPLATE_DETAILS: `get-contractor-template-details`,
    UPDATE_CONTRACTOR_EMAIL_TEMPLATE: `update-contractor-email-template`,
    REMOVE_CONTRACTOR_EMAIL_TEMPLATE: `remove-email-templates`,
    GET_CONTRACTOR_EMAIL_HISTORY_LIST: `list-contractor-email-history`,
    GET_CONTRACTOR_EMAIL_HISTORY_DETAIL: `detail-contractor-email-history`,
    SEND_CONTRACTOR_EMAIL: `send-contractor-email`,
    IMPORT_EMAIL_TEMPLATES: `import-email-templates`,
    //Data Capture Tool
    CREATE_FORM: `form-studio`,
    FORM_SUBMISSION: `form-studio-submission`,
    EXPORT_SUBMISSION: "export-form-submission",
    GET_CUSTOMER_INFORMATION: `users`,
    EVENTS_TRACKING: `events`,
    GET_TASKS: `compliance-tasks`,
    DELETE_TASKS: `compliance-tasks/`,
    UPDATE_TASKS: `compliance-tasks/`,
    COMMENTS: `comments`,
    COMMENTS_COUNT: `comments`,
    CREATE_USER: `/users`,
    NOTIFICATION_LIST: `notification/list-with-count`,
    NOTIFICATION_DELETE: `notification/delete`,
    NOTIFICATION_SINGLE_READ: `notification/read`,
    NOTIFICATION_READ_ALL: `notification/read-all`,
    GLOSSARIES: `glossary`,
    STATES_LIST: `common/get-states`,
    AGREEMENT_SETTINGS: "agreement-settings",
    GET_ASSESSMENT_SUMMARY: `assessment-summary`,
    GET_START_TEST: `start-test`,
    DOCUMENTS_COLLECTION: "document-collection",
    CONTRACTS_COLLECTION: "contract-collection",
    CONTRACTS_DATABASE: "contract",
    TEST_SCOPE_SETTINGS: "test-scope-settings",
    ASSIGN_CONTRACTORS: "assign-contractors",
    CLIENTS: "/clients",
    //Mqs Question
    STORE_QUESTION: `mqs`,
    STORE_CATEGORY_MQS: `mqs-categories`,
    SUBMIT_TEST: `test-submit`,
    UPDATE_CONFILCT: `update-conflict-responses`,
    GET_CONFLICT_RESPONSES: `get-conflict-responses`,
    GET_QUESTION_RESPONSES: `get-view-responses`,
    GET_DEINATION_FROM_GLOSSARIES: `get-term-defination`,
    GET_CONTIGENT_QUESTION: `get-contigent-question`,
    CHECK_ALTERNATIVE_RESPONSE: `check-alternative-response`,
    CHECK_CONTINGENT_QUESTIONS: `check-dependent-question`,
    //scoring
    AUDIT_DEFENCE_REPORT: `audit-defence-report`,
    UPDATE_CIRCUIT_DATA: `scoring-configurations/political/federal`,
    UPDATE_State_DATA: `scoring-configurations/political/state`,
    DEFAULT_COMPLIANCE_SETTINGS: "default-compliance-settings",
    UPDATE_TASKS_STATUS: `compliance-tasks-status/update`,
    ASSESSMENT_PARTIES: "assessment-parties",
    ASSIGN_TASK: "compliance-tasks-status/update",
    GET_RCP_TASKS: "compliance-tasks/",
    WHITE_LABELLING: "white-labelling",
    ASSESSMENT_SETTING: "assessment-setting",
    CUSTOMER_HIERARCHY: "hierarchy",
    UPDATE_PROFILE: "user/profile",
    UPDATE_ASSESSMENT_PARTIES: "update-assessment-party",
    MQS_DEFINATIONS: "mqs-definations",
    MQS_QUESTION: "questions",
    DOCUMENT: "document",
    IMPORT_CONTRACTS: "signed-document/import",
    IMPORT_MQS_QUESTION: "mqs-upload",
    DELETE_SELFCONFIG_TEST: `self-configuration-setting`,
    GET_TEST_OVERVIEW_RESULT: `get-test-result`,
    //test-law-setting
    TEST_LAW_SETTINGS: "test-law-settings",
    //Essentials Test
    LITE_TEST: "lite-test",
    // Default Compilance Setting
    CREATE_DEFAULT_COMPLIANCE_SETTINGS: "user-default-compliance-settings",
    USER_DEFAULT_COMPLIANCE_SETTINGS:
        "user-default-compliance-settings-details",
    VIEW_QUESTIONS_BALANCING_CATEGORY: "get-default-questions",
    SETUP_QUESTIONS: "setup-questions",
    //self CONFIGURATION Test
    SELF_CONFIGURATION_SETTING: "self-configuration-setting",
    SELF_CONFIGURATION_SETTING_LIST: "self-configuration-setting-list",
    //saveDynamicTest
    DYANAMIC_TEST_API: "standard-test-setting",
    DYANAMIC_TEST_DETAILS_LIST: "standard-test-setting-list",
    GET_FORM_DETAILS: "get-form-details",
    FORM_STUDIO_SUBMISSION: "form-studio-submission",
    GET_FORM_STUDIO_DATA: "form-studio-submission",
    //compliance Workflow
    GET_WORKERS_LIST: "get-workers",
    SAVE_EXTRA_SETTINGS: "store-extra-lite-test-setting",
    MAKE_DEFAULT: "form-studio/set-default",
    VIEW_EXTRA_SETTINGS: "view-extra-lite-test-setting",
    GET_FORM_STUDIO_DATA: "form-studio-submission/1",
    GET_ADD_TRAINING_LIB: "training-lib-module-add",
    GET_LIST_TRAINING_LIB: "training-lib-module-list",
    GET_DELETE_TRAINING_LIB: "training-lib-module-delete",
    GET_EDIT_TRAINING_LIB: "training-lib-module-edit",
};
export const NOTIFICATION_CHANNEL_CONSTANTS = {
    USER_NOTIFICATION: `users.notification`,
};
