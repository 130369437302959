import { NOTIFICATION_CONFIG } from "../Action/NotificationConfigAction";

const configState = {};

const dialog = (state = configState, action) => {
    switch (action?.type) {
        case NOTIFICATION_CONFIG: {
            return {
                ...action.payload,
            };
        }
        default: {
            return state;
        }
    }
};

export default dialog;
