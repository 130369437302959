import axios from "axios";
import { toast } from "react-hot-toast";
import { BASE_URL } from "../config/ApiConstants";

function getHeaders() {
    const auth_token = window.localStorage.getItem("token");
    const languageId = window.localStorage.getItem('language')
    window.localStorage.setItem("languageCode", `${
        languageId == 38 ? "en" : (
          languageId == 48 ? "fr" : (
            languageId == 40 ? "es" : "en"
          )
        )
      }`)
    return {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth_token}`,
        "Accept-Language": `${
            languageId == 38 ? "en" : (
              languageId == 48 ? "fr" : (
                languageId == 40 ? "es" : "en"
              )
            )
          }`
 
    };
}
function getToken() {
    return window.localStorage.getItem("token");
}

export const getApiCall = async (url, params = null) => {
    try {
        const token = getToken();
        axios.defaults.baseURL = BASE_URL;
        if (navigator.onLine) {
            if (token) {
                return await axios.get(url, {
                    params: params,
                    headers: getHeaders(),
                });
            } else {
                // toast.error("Kindly re-login as your session is expired.");
            }
        } else {
            toast.error("You are offline kindly try after some time.");
        }
    } catch (error) {
        if (error?.response?.data?.status === 401) {
            localStorage.clear();
            window.location.reload(true);
            toast.error("Kindly re-login as your session is expired.");
        } else {
            // toast.error(error?.response?.data?.message);
        }
        return error?.response;
    }
};

export const postApiCall = async (endPointURL, data, params = null) => {
    try {
        axios.defaults.baseURL = BASE_URL;
        if (navigator.onLine) {
            return await axios.post(endPointURL, data, {
                params: params,
                headers: getHeaders(),
            });
        } else {
            toast.error("You are offline kindly try after some time.");
        }
    } catch (error) {
        if (error?.response?.data?.status === 401) {
            localStorage.clear();
            window.location.reload(true);
            toast.error("Kindly re-login as your session is expired.");
        } else {
            toast.error(error?.response?.data?.message);
        }
        return error?.response;
    }
};

export const deleteApiCall = async (
    endPointURL,
    params = null,
    data = null
) => {
    try {
        axios.defaults.baseURL = BASE_URL;
        return await axios.delete(endPointURL, {
            params: params,
            headers: getHeaders(),
            data: data,
        });
    } catch (error) {
        if (error?.response?.data?.status === 401) {
            localStorage.clear();
            window.location.reload(true);
            toast.error("Kindly re-login as your session is expired.");
        } else {
            // toast.error(error?.response?.data?.message);
        }
        return error?.response;
    }
};

export const putApiCall = async (endPointURL, data) => {
    try {
        axios.defaults.baseURL = BASE_URL;
        return await axios.put(endPointURL, data, {
            headers: getHeaders(),
        });
    } catch (error) {
        if (error?.response?.data?.status === 401) {
            localStorage.clear();
            window.location.reload(true);
            toast.error("Kindly re-login as your session is expired.");
        } else {
            toast.error(error?.response?.data?.message);
        }
        return error?.response;
    }
};
