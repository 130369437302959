import React, { Fragment } from "react";
import Grid from "@mui/material/Grid";
import ButtonComponent from "../../components/ButtonComponent";
import TextFieldComponent from "../../components/TextField/TextFieldComponent";
import DialogComponent from "../../components/DialogsBox/DialogComponent";
import { connect } from "react-redux";
function AddCategoryDialogMqs(props) {
    
    const { openDialog, setState, state, handleClose, handleCategoryAdd, userLoggedIn } =
        props;

    return (
        <Fragment>
             <DialogComponent
                className="dialogMqs"
                open={openDialog}
                onClose={handleClose}
                title={userLoggedIn?.language_data?.faqs?.add_category_btn || `Add Category`}
                content={<Grid container>
                        <Grid
                            item
                            md={12}
                            xs={12}
                            ml={3}
                            mt={1}
                            className="questionGrid"
                        >
                            <TextFieldComponent
                                label={userLoggedIn?.language_data?.faqs?.category_name || "Category Name"}
                                onChange={(event) =>
                                    setState((prevState) => ({
                                        ...prevState,
                                        category: event.target.value,
                                    }))
                                }
                                id="addCategoryName"
                                value={state?.category}
                            />
                        </Grid>
                    </Grid>}
                action={
                    <ButtonComponent
                    title={userLoggedIn?.language_data?.comman?.add_btn || "Add"}
                    variant={"contained"}
                    className="mqsButton"
                    onClick={() => handleCategoryAdd()}
                />
                }
            />
        </Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,
    };
};

export default connect(mapStateToProps, null) (AddCategoryDialogMqs);
