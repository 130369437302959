import { API_CONSTANTS } from "../../config/ApiConstants";
import { getApiCall, postApiCall ,deleteApiCall,putApiCall} from "../../utils/Action"

export const GET_DOCUMENTS_ACTION = "GET_DOCUMENTS_ACTION";
export const ADD_DOCUMENT_ACTION = "ADD_DOCUMENT_ACTION";
export const DOCUMENTS_ACTION = "DOCUMENTS_ACTION";
export const UPDATE_DOCUMENTS_ACTION = "UPDATE_DOCUMENTS_ACTION";
export const UPLOAD_DOCUMENT_ACTION= "UPLOAD_DOCUMENT_ACTION";
export const GET_FILES_LIST_ACTION= "GET_FILES_LIST_ACTION";

export const getDocumentsAction = (guid,id) => {
    return async (dispatch) => {
        try {
            const response = await getApiCall(API_CONSTANTS.DOCUMENTS_COLLECTION, { 
                guid:guid ,
                contractor_id:id
            });
            if (response?.status === 200) {
                return dispatch({
                    type: GET_DOCUMENTS_ACTION,
                    payload: response.data.result,
                });
            } 
        } catch (error) {
            throw error;
        }
    };
};

export const addDocumentAction=(data)=>{
    return async (dispatch)=>{
        try {
            const response = await postApiCall(API_CONSTANTS.DOCUMENTS_COLLECTION,data,{guid:data.guid})
            if (response?.status === 201) {
                return dispatch({
                    type: ADD_DOCUMENT_ACTION,
                    payload: []
                });
            }
        } catch (error) {
            throw error
        }
    }
}

export const updateDocumentsAction=(guid,c_id,data)=>{
    return async (dispatch)=>{
        try {
            const response = await putApiCall(API_CONSTANTS.DOCUMENTS_COLLECTION + `/${guid}`,{collection:data})      
            if (response?.status === 200) {
                return dispatch({
                    type: UPDATE_DOCUMENTS_ACTION,
                    payload: []
                });
            }
        } catch (error) {
            throw error;
        }
    }
}

export const deleteDocumentAction=(guid,id)=>{
    return async (dispatch)=>{
        try {
            const response = await deleteApiCall(API_CONSTANTS.DOCUMENTS_COLLECTION + `/${guid}`,{id:id})       
            if (response?.status === 200) {
                return dispatch({
                    type: DOCUMENTS_ACTION,
                    payload: []
                });
            }
        } catch (error) {
            throw error;
        }
    }
}

export const getDocsRepositoryListAction = (guid,id) => {
    return async (dispatch) => {
        try {
            const response = await getApiCall(API_CONSTANTS.DOCUMENTS_COLLECTION, { 
                guid:guid ,
                contractor_id:id
            });
            if (response.status === 200) {
                return dispatch({
                    type: GET_DOCUMENTS_ACTION,
                    payload: response.data.result,
                });
            } 
        } catch (error) {
            throw error;
        }
    };
};

export const uploadDocFileAction = (data) => {
    return async (dispatch) => {
        try {
             const response = await postApiCall("/contract", data);
            if (response.status === 201) {
                return dispatch({
                    type:  UPLOAD_DOCUMENT_ACTION,
                    payload: response.data.result,
                });
            } 
        } catch (error) {
            throw error;
        }
    };
};

export const getDocFilesAction = () => {
    return async (dispatch) => {
        try {
             const response = await getApiCall("/contract/1");
            if (response.status === 201) {
                return dispatch({
                    type:  GET_FILES_LIST_ACTION,
                    payload: response.data.result,
                });
            } 
        } catch (error) {
            throw error;
        }
    };
};