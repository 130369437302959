import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Box from "@mui/material/Box";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import FormControlLabel from "@mui/material/FormControlLabel";
import TableComponent from "../../../../../components/Table/TableComponent";
import Checkbox from "../../../../../components/Checkbox/CheckboxComponent";
import { getDocumentAprrovedList } from "../../../../../store/Action/DocumentDatabaseAction";
import LoadingComponent from "../../../../../components/Loading/LoadingComponent";

function DocsTable(props) {
    const {
        getDocumentAprrovedList,
        documentDatabase,
        setselectedDocumentDatabaseData,
        isCheckedAll,
        isChecked,
        userLoggedIn
    } = props;

    const [state, setState] = useState({
        openDeleteDialog: false,
        deleteData: null,
        openAddDialog: false,
        openSuccesfullModel: false,
        modelContent: "",
        id: null,
        address: { state: "" },
        isLoading: false,
        filteredData: null,
    });

    const handleSelectAll = (e) => {
        setselectedDocumentDatabaseData((prevState) => ({
            ...prevState,
            isCheckedAll: !isCheckedAll,
            isChecked:
                documentDatabase?.length && documentDatabase.map((li) => li.id),
        }));
        if (isCheckedAll) {
            setselectedDocumentDatabaseData((prevState) => ({
                ...prevState,
                isChecked: [],
            }));
        }
    };

    const { isLoading, filteredData } = state;

    useEffect(() => {
        async function callApi() {
            setState((prevState) => ({ ...prevState, isLoading: true }));
            await getDocumentAprrovedList(null, null, false);
            setState((prevState) => ({ ...prevState, isLoading: false }));
        }
        if (documentDatabase === null) callApi();
    }, [getDocumentAprrovedList, documentDatabase]);

    const handleClickOnCheckbox = (e) => {
        const { value, checked } = e.target;
        if (!checked) {
            //remove
            setselectedDocumentDatabaseData((prevState) => ({
                ...prevState,
                isChecked: isChecked.filter((item) => item !== Number(value)),
            }));
        } else {
            //add
            let row = documentDatabase.filter((item) => item.id === value);
            setselectedDocumentDatabaseData((prevState) => ({
                ...prevState,
                isChecked: [...isChecked, Number(value)],
            }));
        }
    };

    const renderClassStatus = (obj, row) => {
        if (obj?.rowClass) return obj?.rowClass;
        else if (row?.status_name === "Approved") return "cellApproved";
        else if (
            row?.status_name?.replace(/\s/g, "") === "PendingforApproval" ||
            row?.status_name === "Others"
        )
            return "cellPending";
        else if (
            row?.status_name?.replace(/\s/g, "") === "OnHold" ||
            row?.status_name === "Rejected" ||
            row?.status_name === "Reassigned"
        )
            return "cellOnHold";
    };
    const headerList = [
        {
            id: 1,
            name: userLoggedIn?.language_data?.documentdatabase?.document_name || "Document Name",
            headerClass: "tableHeading",
            accessibleKey: "document_name",
            rowClass: "nameFile",
            headerAlign: "center",
            rowAlign: "center",
        },
        {
            id: 2,
            name: userLoggedIn?.language_data?.documentdatabase?.document_category || "Category",
            accessibleKey: "category",
            headerClass: "tableHeading",
            rowClass: "tableBodyRow",
            headerAlign: "center",
            rowAlign: "center",
        },

        {
            id: 4,
            name: userLoggedIn?.language_data?.comman?.status || "Status",
            headerClass: "tableHeading",
            accessibleKey: "status_name",
            headerAlign: "center",
            rowAlign: "center",
        },
        {
            id: 5,
            name: userLoggedIn?.language_data?.comman?.approved_by || "Approved By",
            headerClass: "tableHeading",
            accessibleKey: "approved_by",
            headerAlign: "center",
            rowAlign: "center",
        },
    ];

    //
    return (
        <>
            {!documentDatabase || isLoading ? (
                <LoadingComponent />
            ) : (
                <Box sx={{ px: { md: 7, xs: 1, mt: "40px" } }}>
                    {documentDatabase?.length !== 0 && (
                        <TableComponent
                            headerList={headerList}
                            extraHeaderLeft={
                                <TableCell
                                    align="left"
                                    className="tableHeading"
                                >
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={handleSelectAll}
                                            />
                                        }
                                        labelPlacement="Select"
                                    ></FormControlLabel>
                                </TableCell>
                            }
                        >
                            {documentDatabase?.length !== 0 &&
                                documentDatabase
                                    ?.sort((a, b) =>
                                        a?.document_name?.toLowerCase() >
                                        b?.document_name?.toLowerCase()
                                            ? 1
                                            : b?.document_name?.toLowerCase() >
                                              a?.document_name?.toLowerCase()
                                            ? -1
                                            : 0
                                    )
                                    .map((row, index) => (
                                        <TableRow
                                            key={row?.name}
                                            className={
                                                index % 2 !== 0
                                                    ? "tertiaryBackground"
                                                    : ""
                                            }
                                        >
                                            <TableCell
                                                align="center"
                                                className="typo4"
                                            >
                                                <FormControlLabel
                                                    value={row?.id}
                                                    control={
                                                        <Checkbox
                                                            checked={isChecked?.includes(
                                                                row?.id
                                                            )}
                                                            onChange={
                                                                handleClickOnCheckbox
                                                            }
                                                            value={row?.id}
                                                        />
                                                    }
                                                    labelPlacement="Select"
                                                ></FormControlLabel>
                                            </TableCell>
                                            {headerList?.map((obj) => (
                                                <TableCell
                                                    component="th"
                                                    key={obj?.name}
                                                    align={obj?.rowAlign}
                                                    className={renderClassStatus(
                                                        obj,
                                                        row
                                                    )}
                                                >
                                                    {obj?.id === 5 &&
                                                    !row?.approved_by
                                                        ? "Not Assigned"
                                                        : row[
                                                              obj?.accessibleKey
                                                          ]}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                        </TableComponent>
                    )}
                </Box>
            )}
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        documentDatabase: state?.docDatabase?.selectedDocumentDatabaseData,
        userLoggedIn: state?.auth?.userLoggedIn,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getDocumentAprrovedList,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(DocsTable);
