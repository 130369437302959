import { BASE_URL } from "../../config/ApiConstants";

export const NOTIFICATION_CONFIG = "NOTIFICATION_CONFIG";

export const getNotificationConfig = (config, token) => {
    config.auth.headers = {
        Authorization: "Bearer " + token
    };
    config.authEndpoint = BASE_URL + "broadcasting/auth";
    config.wsHost = config.wsHost.replace(/^http:\/\//, '');
    config.encrypted = false;
    config.enabledTransports = ['ws', 'wss']
    localStorage.setItem("notificationConfig", JSON.stringify(config));
    return {
        type: NOTIFICATION_CONFIG,
        payload: config,
    };
};
