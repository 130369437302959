import * as Actions from "../Action/TestLawSettingAction";

const initialState = {
    modules: null,
};

const testLawSettings = (state = initialState, action) => {
    switch (action?.type) {
        case Actions.GET_TEST_LAW_DETAILS: {
            return {
                ...state,
                modules: action.payload,
            };
        }
        // case Actions.SET_TEST_LAW_DETAILS: {
        //     return {
        //         ...state,
        //         modules: action.payload,
        //     };
        // }
        default: {
            return state;
        }
    }
};

export default testLawSettings;
