import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import ButtonComponent from "../../components/ButtonComponent";
import DialogComponent from "../../components/DialogsBox/DialogComponent";
import SuccessfullModel from "../../components/DialogsBox/SuccessfullModel";
import RadioButtonGroup from "../../components/RadioButtonGroup/RadioButtonGroup";
import {
    assignContractorsAction,
} from "../../store/Action/ComplianceWorkflowAction";
import { ROUTES } from "../../router/RouteList";
import { assignOptions } from "../../utils/CommonData";
import "../../../assets/styles/Container/AssignUser.scss";
import AutoCompleteDropDown from "../../components/DropDown/AutoCompleteDropDown";
function AssignUserDialog(props) {
    const {
        complianceWorkflow,
        assignContractorsAction,
        customer,
        currentTab,
        setisDelete,
        userLoggedIn
    } = props;
    const navigate = useNavigate();
    const [state, setState] = useState({
        open: false,
        assignUser: "",
        reject: false,
        warnningOpen: false,
        warnningContent: "Please Select Contractor!",
        assignContractor: {
            client_id: "",
            assign_user_id: "",
            status: "",
            previous_status: "",
            contractorids: null,
        },
    });
    const {
        open,
        assignUser,
        value,
        assignContractor,
        warnningOpen,
        warnningContent,
    } = state;
    const { selectedContractors, conductorList } = complianceWorkflow;
    const handleClickToOpen = () => {
        if (selectedContractors.length > 0) {
            setState((prevState) => ({ ...prevState, open: true }));
        } else {
            setState((prevState) => ({ ...prevState, warnningOpen: true }));
        }
        setisDelete(true)
    };
    const handleToClose = () => {
        setState((prevState) => ({ ...prevState, open: false }));
    };
    const handleChange = (event) => {
        if (event.target.value !== "assignToMe") {
            setState((prevState) => ({
                ...prevState,
                assignUser: event.target.value,
                assignContractor: {
                    client_id: customer?.selectedCustomer?.id,
                    assign_user_id: conductorList[0]?.id,
                    status: currentTab === "U" ? "I" : "C",
                    previous_status: currentTab,
                    contractorids: selectedContractors,
                },
            }));
        } else {
            setState((prevState) => ({
                ...prevState,
                assignUser: event.target.value,
                assignContractor: {
                    assign_user_id: "",
                    client_id: customer.selectedCustomer.id,
                    contractorids: complianceWorkflow.selectedContractors,
                    previous_status: currentTab,
                    status: "I",
                },
            }));
        }
    };
    const handleSubmit = async () => {
        const demoArray = assignContractor?.contractorids.map(
            (item) => item.id
        );
        if (assignUser === "assignToMe") {
            await assignContractorsAction({
                ...assignContractor,
                contractorids: demoArray,
            });
            setisDelete(true)
            navigate(
                `${ROUTES.CUSTOMERS_INFORMATION}/${customer?.selectedCustomer?.guid}/${assignContractor?.contractorids[0]?.id}`,
                { complianceWorkflow }
            );
        } else {
            await assignContractorsAction({
                ...assignContractor,
                contractorids: demoArray,
            });
            setisDelete(true)
            setState({ open: false });
        }
    };

    return (
        <>
            <SuccessfullModel
                open={warnningOpen}
                handleClose={() => setState({ ...state, warnningOpen: false })}
                content={warnningContent}
            />
            <DialogComponent
                className="assignDialog"
                open={open}
                handleClose={handleToClose}
                title={`Contractor : ${
                    selectedContractors.length === 1
                        ? selectedContractors[0]?.display_name
                        :selectedContractors.length>1 ?"Multiple Selected Contractor": "---"
                }`}
                content={
                    <>
                        <Typography className="font_20_600">
                            Assign User
                        </Typography>
                        <Grid container px={4} justifyContent={"center"}>
                            <RadioButtonGroup
                                options={assignOptions}
                                row={true}
                                defaultValue={true}
                                name="assignUser"
                                onChange={handleChange}
                                value={assignUser}
                            />
                        </Grid>
                        <Grid container mx={8} mt={1.5}>
                            {assignUser === "assignToUser" && (
                                <AutoCompleteDropDown
                                    className="assignAutoComplete"
                                    options={complianceWorkflow.pcpmanagers}
                                    onChange={(_, newInputValue) => {
                                        newInputValue &&
                                            setState((prevState) => ({
                                                ...prevState,
                                                assignContractor: {
                                                    ...prevState.assignContractor,
                                                    assign_user_id:
                                                        newInputValue.value,
                                                },
                                            }));
                                    }}
                                    value={value}
                                    sx={{ width: 400 }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder="Search assignee"
                                        />
                                    )}
                                />
                            )}
                        </Grid>
                    </>
                }
                action={
                    <Grid
                        container
                        display={"flex"}
                        justifyContent={"flex-end"}
                        mr={2}
                        mb={2}
                    >
                        <ButtonComponent
                            onClick={handleSubmit}
                            title={userLoggedIn?.language_data?.complianceworkflow
                                ?.assign ||  "Assign"}
                        />
                    </Grid>
                }
            />
            <ButtonComponent onClick={handleClickToOpen} title={userLoggedIn?.language_data?.complianceworkflow
                                ?.assign || "Assign"} />
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        customer: state.customer,
        user: state.user,
        complianceWorkflow: state.complianceWorkflow,
        selectedContractors: state.complianceWorkflow?.selectedContractors,
        userLoggedIn: state.auth?.userLoggedIn,

    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        { assignContractorsAction},
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(AssignUserDialog);
