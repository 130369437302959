import * as React from "react";
import Box from "@mui/material/Box";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import CheckboxComponents from "../../components/Checkbox/CheckboxComponent";
import TableComponent from "../../components/Table/TableComponent";
import { Translate } from "react-auto-translate";
const headCells = [
    // {
    //     numeric: false,
    //     headerClass:"tableHeadCell",
    //     disablePadding: false,
    //     label: "S.No.",
    //     align: "center",
    // },
    {
        numeric: true,
        headerClass: "tableHeadCell",
        disablePadding: false,
        label: "Module Name",
        align: "center",
    },
    {
        numeric: true,
        headerClass: "tableHeadCell",
        disablePadding: false,
        label: "Description",
        align: "left",
    },
    {
        numeric: false,
        headerClass: "tableHeadCell",
        disablePadding: false,
        label: "Create",
        align: "center",
    },
    {
        numeric: false,
        headerClass: "tableHeadCell",
        disablePadding: false,
        label: "Read Only",
        align: "center",
    },
    {
        numeric: false,
        headerClass: "tableHeadCell",
        disablePadding: false,
        label: "Edit",
        align: "center",
    },
    {
        numeric: false,
        headerClass: "tableHeadCell",
        disablePadding: false,
        label: "Delete",
        align: "center",
    },
    {
        numeric: false,
        headerClass: "tableHeadCell",
        disablePadding: false,
        label: "Select All",
        align: "center",
    },
];
function RolesPermissionTable(props) {
    const { modulesData, setState, disabled } = props;
    const handlerCheck = (e, id, name) => {
        let newData = modulesData;
        let index = modulesData.findIndex((obj) => obj.id === parseInt(id));
        let data;
        if (index !== -1) {
            if (name === "selectAll") {
                data = {
                    ...newData[index],
                    permissions: {
                        [name]: e.target.checked,
                        create: e.target.checked,
                        read: e.target.checked,
                        edit: e.target.checked,
                        delete: e.target.checked,
                    },
                };
            } else {
                data = {
                    ...newData[index],
                    permissions: {
                        ...newData[index]?.permissions,
                        [name]: e.target.checked,
                    },
                };
            }
        }
        newData[index] = data;
        setState((prevState) => ({ ...prevState, modulesData: newData }));
    };

    return (
        <Box className="permissionTable">
            <TableComponent
                sx={{
                    [`& .${tableCellClasses.root}`]: {
                        borderBottom: "none",
                        padding: "14px",
                    },
                }}
                headSx={{ borderRadius: "10px" }}
                tableClass={"tableRoot"}
                extraHeaderLeft={headCells.map((headCell) => (
                    <TableCell
                        className="tableHeadCell"
                        key={headCell.id}
                        align={headCell.align}
                    >
                        <Translate>{headCell.label}</Translate>
                    </TableCell>
                ))}
            >
                {modulesData?.length
                    ? modulesData.map((row, index) => (
                          <TableRow
                              className={
                                  index % 2 === 0
                                      ? "tableBodyRow"
                                      : "tableBodyRowColor"
                              }
                              tabIndex={-1}
                              key={row?.id}
                          >
                              <TableCell style={{ textAlign: "center" }}>
                                  {index + 1}
                              </TableCell>
                              <TableCell>
                                  <Translate>{row?.name}</Translate>
                              </TableCell>
                              <TableCell
                                  style={{
                                      textAlign: "left",
                                      color: "#F26A21",
                                  }}
                              >
                                  <Translate>{row?.description}</Translate>
                              </TableCell>
                              <TableCell style={{ textAlign: "center" }}>
                                  <CheckboxComponents
                                      onChange={(event) =>
                                          handlerCheck(event, row?.id, "create")
                                      }
                                      checked={
                                          row?.permissions?.create || false
                                      }
                                      disabled={disabled}
                                  />
                              </TableCell>
                              <TableCell style={{ textAlign: "center" }}>
                                  <CheckboxComponents
                                      onChange={(event) =>
                                          handlerCheck(event, row?.id, "read")
                                      }
                                      checked={row?.permissions?.read || false}
                                      disabled={disabled}
                                  />
                              </TableCell>
                              <TableCell style={{ textAlign: "center" }}>
                                  <CheckboxComponents
                                      onChange={(event) =>
                                          handlerCheck(event, row?.id, "edit")
                                      }
                                      checked={row?.permissions?.edit || false}
                                      disabled={disabled}
                                  />
                              </TableCell>
                              <TableCell style={{ textAlign: "center" }}>
                                  <CheckboxComponents
                                      onChange={(event) =>
                                          handlerCheck(event, row?.id, "delete")
                                      }
                                      checked={
                                          row?.permissions?.delete || false
                                      }
                                      disabled={disabled}
                                  />
                              </TableCell>
                              <TableCell style={{ textAlign: "center" }}>
                                  <CheckboxComponents
                                      onChange={(event) =>
                                          handlerCheck(
                                              event,
                                              row?.id,
                                              "selectAll"
                                          )
                                      }
                                      checked={
                                          (row?.permissions?.read &&
                                              row?.permissions?.create &&
                                              row?.permissions?.delete &&
                                              row?.permissions?.edit) ||
                                          false
                                      }
                                      disabled={disabled}
                                  />
                              </TableCell>
                          </TableRow>
                      ))
                    : null}
            </TableComponent>
        </Box>
    );
}

export default RolesPermissionTable;
