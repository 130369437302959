import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import Box from "@mui/material/Box";
import { toast } from "react-hot-toast";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import TextFieldComponent from "../../components/TextField/TextFieldComponent";
import ButtonComponent from "../../components/ButtonComponent";
import Navbar from "../../common/Header/Navbar";
import { resetPassword } from "../../store/Action/LoginAction";
import { ROUTES } from "../../router/RouteList";

function ResetPassword(props) {
    const navigate = useNavigate();
    const { resetPassword } = props;
    const [resetToken] = useSearchParams();
    const token_value = resetToken.get("auth");
    const [input, setInput] = useState({
        password: "",
        confirmPassword: "",
    });
    const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const createParam = queryParams.get('create');

  console.log(createParam)

    const [error, setError] = useState({
        password: "",
        confirmPassword: "",
    });

    const [state, setState] = useState({
        showPassword: false,
        showConfirmPassword: false,
    });

    const { showPassword, showConfirmPassword } = state;

    const onInputChange = (e) => {
        const { name, value } = e.target;
        setInput((prev) => ({
            ...prev,
            [name]: value,
        }));
        validateInput(e);
    };
    function validateInput(e) {
        let { name, value } = e.target;
        setError((prev) => {
            const stateObj = { ...prev, [name]: "" };
            switch (name) {
                case "password": {
                    if (!value) {
                        stateObj[name] = "Please enter Password.";
                    } else if (
                        input.confirmPassword &&
                        value !== input.confirmPassword
                    ) {
                        stateObj["confirmPassword"] =
                            "Password and Confirm Password does not match.";
                    } else {
                        stateObj["confirmPassword"] = input.confirmPassword
                            ? ""
                            : error.confirmPassword;
                    }
                    break;
                }
                case "confirmPassword": {
                    if (!value) {
                        stateObj[name] = "Please enter Confirm Password.";
                    } else if (input.password && value !== input.password) {
                        stateObj[name] =
                            "Password and Confirm Password does not match.";
                    }
                    break;
                }
                default:
                    break;
            }

            return stateObj;
        });
    }

    async function handleSubmit() {
        const resp = await resetPassword(
            token_value,
            input.password,
            input.confirmPassword
        );
        if (resp) {
            toast.success(resp?.payload);
            navigate(ROUTES.HOME);
        }
    }

    let params = Object.fromEntries(
        new URLSearchParams(window.location.search).entries()
    );
    return (
        <div>
            <Navbar />
            <Grid container spacing={2} mt={0} mb={6}>
                <Grid
                    item
                    xs={12}
                    mt={14}
                    justifyContent={"center"}
                    display={"flex"}
                    alignItems={"center"}
                >
                    <Paper className="paperReset">
                        <Grid container spacing={2} gap={2}>
                            <Grid
                                item
                                xs={12}
                                justifyContent={"center"}
                                display={"flex"}
                            >
                                <Typography className="resetHeading">
                                    {
                                        createParam ? 'Create New ' : "Reset "
                                    }
                                     Password
                                </Typography>
                            </Grid>
                            <Typography className="textHeading">
                                Please enter your password and confirm password
                            </Typography>
                            <Grid item xs={12}>
                                <TextFieldComponent
                                    id="resetPassword"
                                    label={"Password"}
                                    value={input.password}
                                    name="password"
                                    onChange={onInputChange}
                                    onBlur={(e) => validateInput(e)}
                                    // type="password"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    type={showPassword ? "text" : "password"}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() =>
                                                        setState(
                                                            (prevState) => ({
                                                                ...prevState,
                                                                showPassword:
                                                                    !showPassword,
                                                            })
                                                        )
                                                    }
                                                    onMouseDown={(event) =>
                                                        event.preventDefault()
                                                    }
                                                    edge="start"
                                                    className="showButton"
                                                >
                                                    {!showPassword ? (
                                                        <VisibilityOff />
                                                    ) : (
                                                        <Visibility />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextFieldComponent
                                    id="resetConfirmPassword"
                                    label={"Confirm Password"}
                                    value={input.confirmPassword}
                                    // type="password"
                                    onChange={onInputChange}
                                    name="confirmPassword"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    type={
                                        showConfirmPassword
                                            ? "text"
                                            : "password"
                                    }
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() =>
                                                        setState(
                                                            (prevState) => ({
                                                                ...prevState,
                                                                showConfirmPassword:
                                                                    !showConfirmPassword,
                                                            })
                                                        )
                                                    }
                                                    onMouseDown={(event) =>
                                                        event.preventDefault()
                                                    }
                                                    edge="start"
                                                    className="showButton"
                                                >
                                                    {!showConfirmPassword ? (
                                                        <VisibilityOff />
                                                    ) : (
                                                        <Visibility />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    onBlur={(e) => validateInput(e)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Box sx={{ color: "red" }}>
                                    {error.confirmPassword && (
                                        <span className="err">
                                            {error.confirmPassword}
                                        </span>
                                    )}
                                </Box>
                                {/* {params["auth"] && ( */}
                                <Grid
                                    item
                                    xs={12}
                                    justifyContent={"center"}
                                    display={"flex"}
                                    mt={3}
                                >
                                    <ButtonComponent
                                        id="resetResetPasswordButton"
                                        title={createParam ? 'Create New Passowrd' : "Reset Password"}
                                        type="submit"
                                        variant="contained"
                                        onClick={handleSubmit}
                                    />
                                </Grid>
                                {/* )} */}
                                <Grid
                                    item
                                    xs={12}
                                    justifyContent={"center"}
                                    display={"flex"}
                                    mt={3}
                                >
                                    <Link
                                        to={ROUTES.LOGIN}
                                        className="backToLogin"
                                        id="resetBackButton"
                                    >
                                        Back to Login
                                    </Link>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        resetPassword: state.auth.rsestPassword,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ resetPassword }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
