import React from "react";
import Link from "@mui/material/Link";
import { getUserById } from "../store/Action/UserAction";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
const ExpandAllButton = (props) => {
    const { setState, expand, userLoggedIn } = props;
    const handleClick = () => {
        setState((prevState) => ({ ...prevState, expand: !expand }));
    };

    return (
        <Link onClick={handleClick} className="expandButton">
            {expand ? userLoggedIn?.language_data?.comman?.collapse_all || "Collapse All" : "Expand All"}
        </Link>
    );
};

const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getUserById
        },
        dispatch
    )
}
export default connect(mapStateToProps, mapDispatchToProps) (ExpandAllButton);
