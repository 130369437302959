import React, { useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Popover from "@mui/material/Popover";
import Papper from "@mui/material/Paper";
import FormDummyImage from "../../../assets/Images/formDummy.png";
import {
    getForms,
    getSubmissionsById,
} from "../../store/Action/FormStudioAction";
import { ROUTES } from "../../router/RouteList";

const FormCollectionPopper = (props) => {
    const navigate = useNavigate();
    const { open, anchorEl, setState, getForms, formList, getSubmissionsById } =
        props;

    useEffect(() => {
        getForms();
    }, [getForms]);

    const handleClose = () => {
        setState((prevState) => ({
            ...prevState,
            anchorEl: null,
            popperOpen: false,
        }));
    };

    const navViewForm = async (item) => {
        setState((prevState) => ({
            ...prevState,
            anchorEl: null,
            popperOpen: false,
            isLoading: true,
        }));
        navigate(ROUTES.FORM_BUILDER + "/view/" + item?.id, {
            state: { item: item },
        });
        await getSubmissionsById(item?.id);
        setState((prevState) => ({ ...prevState, isLoading: false }));
    };

    return (
        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        >
            <Papper
                sx={{
                    overflowY: "hidden",
                    height: "200px",
                    width: "630px",
                    boxShadow: "0px 0px 10px 2px rgba(214, 214, 214, 0.5)",
                    borderRadius: "0px 0px 5px 5px",
                }}
            >
                <Box className="boxContainer" gap={2}>
                    {formList?.length
                        ? formList.map((item, index) => (
                              <Box
                                  key={index}
                                  className="imgContainer"
                                  onClick={() => navViewForm(item)}
                              >
                                  <img
                                      className="dummyFormImg"
                                      src={FormDummyImage}
                                  />
                                  <Box
                                      sx={{
                                          width: "100px",
                                          whiteSpace: "nowrap",
                                      }}
                                  >
                                      <Typography
                                          sx={{
                                              textOverflow: "ellipsis",
                                              overflow: "hidden",
                                          }}
                                      >
                                          {item?.name}
                                      </Typography>
                                  </Box>
                              </Box>
                          ))
                        : null}
                </Box>
            </Papper>
        </Popover>
    );
};

const mapStateToProps = (state) => {
    return {
        formList: state.formStudio.formsList,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ getForms, getSubmissionsById }, dispatch);
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FormCollectionPopper);
