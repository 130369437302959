import React from "react";
import DialogContentText from "@mui/material/DialogContentText";
import ButtonComponent from "../ButtonComponent";
import DialogComponent from "./DialogComponent";
function CommonDeleteDialog(props) {
    const {
        open,
        setOpen,
        content,
        UploadFile,
        title,
        cancelButtonTitle,
        cancelButtonAction,
        uploadButtonTitle,
        uploadButtonAction,
        id,
    } = props;

    return (
        <div>
            <DialogComponent
                className="uploadDialog"
                open={open}
                id={id}
                handleClose={cancelButtonAction}
                sxaction={{ paddingX: 4, paddingBottom: 2 }}
                title={title}
                sxContent={{
                    textAlign: "left",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
                content={
                    <>
                        <DialogContentText
                            sx={{ color: "#3B3B3B", alignItems: "center" }}
                            className="font_18_600"
                        >
                            {UploadFile}
                            {content}
                        </DialogContentText>
                    </>
                }
                action={
                    <>
                        <ButtonComponent
                            title={cancelButtonTitle || "Cancel"}
                            className="button_outline"
                            onClick={() => cancelButtonAction()}
                            id={`${id}CommUploadCancelButton`}
                        />
                        <ButtonComponent
                            id={`${id}CommUploadUploadButton`}
                            title={uploadButtonTitle || "Upload"}
                            className="button_contained"
                            onClick={() => uploadButtonAction()}
                        />
                    </>
                }
            />
        </div>
    );
}

export default CommonDeleteDialog;
