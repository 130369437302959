import React, { useState } from "react";
import { Grid } from "@mui/material";
import { Box } from "@mui/material";
import { Typography } from "@mui/material";
import RadioButtonGroup from "../../../../../components/RadioButtonGroup/RadioButtonGroup";
import "../../../../../../assets/styles/Container/AgreementSettings.scss";
import AgreementCommon from "./AgreementCommon";
import AccordionWithCheckBox from "../../../../../components/AccordionWithCheckBox/AccordionWithCheckBox";
import TitleDescription from "./TitleDescription";
import { yesNoOptions } from "../../../../../utils/CommonData";
import { connect } from "react-redux";

const TestScopeSettings = (props) => {
    const { testScopeSettings, state, setState, userLoggedIn } = props;
    const [testScopeState, setTestScopeState] = useState({ option: 0 });
    const { option } = testScopeState;
    return (
        <>
            <Grid container spacing={0}>
                <Grid item lg={4} md={4}>
                    <RadioButtonGroup
                        row={true}
                        title="Do you want to apply all applicable test laws?"
                        options={yesNoOptions}
                        defaultValue={yesNoOptions[0].value}
                        onChange={(e) =>
                            setTestScopeState((prevState) => ({
                                ...prevState,
                                option: e.target.value === "no" ? 1 : 0,
                            }))
                        }
                    />
                </Grid>
            </Grid>
            <Box>
                <Grid container spacing={1} className="mt-5 mb-25">
                    <Grid item>
                        <Typography className="font_18_600 light-sea-green">
                            Scope of Testing:
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography className="font_18_600" lineHeight={1.5}>
                            {testScopeSettings[option]?.option}
                        </Typography>
                    </Grid>
                </Grid>
                <Box className="mt-15">
                    <AgreementCommon
                        showTitle={false}
                        isTestSettings={true}
                        testLawSettings={testScopeSettings[option]}
                    />
                </Box>
                {option === 1 && (
                    <Grid container spacing={0} className="mt-10">
                        {testScopeSettings[1]?.custom_scopes.map((testLaw) => {
                            return (
                                <Grid
                                    item={true}
                                    lg={12}
                                    md={12}
                                    className="my-10"
                                    key={testLaw.id}
                                >
                                    <AccordionWithCheckBox
                                        title={testLaw.option}
                                    >
                                        <Box>
                                            <TitleDescription
                                                title={userLoggedIn?.language_data?.documentdatabase?.add_success || "Summary"}
                                                description={testLaw?.summary}
                                            />
                                        </Box>
                                        <Box className="mt-15">
                                            <TitleDescription
                                                title="Description"
                                                description={testLaw.content}
                                            />
                                        </Box>
                                    </AccordionWithCheckBox>
                                </Grid>
                            );
                        })}
                    </Grid>
                )}
            </Box>
        </>
    );
};
const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,
        testScopeSettings: state.testScopeSettings.settings,
    };
};


export default connect(mapStateToProps, null)(TestScopeSettings);
