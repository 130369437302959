import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import AccordionSummary from "@mui/material/AccordionSummary";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { GrEdit } from "react-icons/gr";
import { ReactComponent as DeleteIcon } from "../../../assets/Images/Icons/DeleteIcon.svg";
import NoInfoAvailable from "../../components/NoInfoAvailable";
import Navbar from "../../common/Header/Navbar";
import PageHeader from "../../common/PageHeader";
import {
    getGlossaryList,
    addGlossary,
    deleteGlossary,
    updateGlossary,
} from "../../store/Action/GlossaryAction";
import { alphabetsList } from "./GlossaryUtils";
import CommonDeleteDialog from "./../../components/DialogsBox/CommonDeleteDialog";
import AddDefinitionDialog from "./AddDefinitionDialog";
import ContextProvider from "../../components/Context/ContextProvider";
import "../../../assets/styles/Container/Glossary.scss";
import LoadingComponent from "../../components/Loading/LoadingComponent";
import ExpandAllButton from "../../components/ExpandAllButton";
import AccordionCustom from "../../components/AccordionCustom";
import {Translate } from 'react-auto-translate';
const Glossary = (props) => {
    const {
        getGlossaryList,
        glossaryList,
        addGlossary,
        deleteGlossary,
        updateGlossary,
    } = props;
    const [search, setSearch] = useState("");
    const [state, setState] = useState({
        expand: false,
        deleteData: null,
        openDeleteDialog: false,
        openAddDialog: false,
        alphabet: "",
        term: "",
        definition: "",
        type: "add",
        currentAlphabet: "A",
        filteredData: null,
        isLoading: false,
        expandSingle: null,
    });
    const {
        expand,
        deleteData,
        openDeleteDialog,
        alphabet,
        term,
        definition,
        openAddDialog,
        type,
        updatedData,
        currentAlphabet,
        filteredData,
        isLoading,
        expandSingle,
    } = state;

    const handleClickOpen = () => {
        setState((prevState) => ({
            ...prevState,
            openAddDialog: true,
            alphabet: "",
            term: "",
            flag: false,
            definition: "",
        }));
    };

    const handleExpansion = (id) => {
        setState((prevState) => ({
            ...prevState,
            expandSingle: id === expandSingle ? null : id,
        }));
    };

    const getGlossaryData = async (currentAlphabet) => {
        setState((prevState) => ({
            ...prevState,
            currentAlphabet: currentAlphabet,
            isLoading: true,
        }));
        await getGlossaryList(currentAlphabet, search);
        setState((prevState) => ({ ...prevState, isLoading: false }));
    };

    useEffect(() => {
        async function callApi() {
            setState((prevState) => ({ ...prevState, isLoading: true }));
            await getGlossaryList(currentAlphabet, search);
            setState((prevState) => ({ ...prevState, isLoading: false }));
        }
        if (glossaryList === null) callApi();
        if (glossaryList) {
            setState((prevState) => ({
                ...prevState,
                filteredData: glossaryList,
            }));
        }
    }, [getGlossaryList, currentAlphabet, glossaryList]);

    const handleDelete = (e, data) => {
        e.stopPropagation();
        setState((prevState) => ({
            ...prevState,
            deleteData: data,
            openDeleteDialog: true,
        }));
    };

    const closeDelete = () => {
        setState((prevState) => ({
            ...prevState,
            deleteData: null,
            openDeleteDialog: false,
        }));
    };

    const handleDeleteAction = async () => {
        setState((prevState) => ({ ...prevState, isLoading: true }));
        await deleteGlossary(deleteData);
        closeDelete();
        setState((prevState) => ({ ...prevState, isLoading: false }));
    };

    const handleCloseDialog = () => {
        setState((prevState) => ({
            ...prevState,
            openAddDialog: false,
            type: "add",
        }));
    };

    async function handleSubmit() {
        let res;
        if (type === "add") {
            res = await addGlossary({
                currentAlphabet: currentAlphabet,
                alphabet: alphabet,
                term: term,
                definition: definition,
            });
        } else {
            setState((prevState) => ({ ...prevState, isLoading: true }));
            res = await updateGlossary({
                ...updatedData,
                alphabet: alphabet,
                term: term,
                definition: definition,
            });
        }
        setState((prevState) => ({ ...prevState, isLoading: false }));
        if (res) handleCloseDialog();
    }

    const handleUpdate = (e, data) => {
        e.stopPropagation();
        setState((prevState) => ({
            ...prevState,
            openAddDialog: true,
            type: "edit",
            alphabet: data.alphabet,
            term: data.term,
            definition: data.definition,
            id: data.id,
            flag: true,
            updatedData: data,
        }));
    };

    useEffect(() => {
        if (glossaryList === null) getGlossaryList(currentAlphabet, search);
        if (glossaryList) {
            setState((prevState) => ({
                ...prevState,
                filteredData: glossaryList,
                isLoading: false,
            }));
        }
    }, [search]);
    
    // const handleSearch = (data) => {
    //     let newList = filteredData;
    //     if (data !== "") {
    //         newList = glossaryList.filter((obj) =>
    //             obj.term?.toLowerCase()?.includes(data.toLowerCase())
    //         );
    //         setState((prevState) => ({
    //             ...prevState,
    //             filteredData: glossaryList,
    //         }));
    //     } else {
    //         setState((prevState) => ({
    //             ...prevState,
    //             filteredData: glossaryList,
    //         }));
    //     }
    // };

    const handleSearch = (data) => {
        getGlossaryList(currentAlphabet, search);
        if (data !== "") {
            setState((prevState) => ({
                ...prevState,
                filteredData: glossaryList,
            }));
        } else {
            setState((prevState) => ({
                ...prevState,
                filteredData: glossaryList,
            }));
        }
    };

    useEffect(() => {
        handleSearch(search)
    }, [search])


    const getAccordionSummary = (data) => (
        <AccordionSummary
            onClick={() => handleExpansion(data.id)}
            expandIcon={<ExpandMoreIcon />}
            id="glossaryExpandAccordion"
        >
            <Grid container display={"flex"} justifyContent="space-between">
                <Grid item className="glossarySummary">
                    <Typography className="glossaryTerm">
                        {data.term}
                    </Typography>
                </Grid>
                <Grid item>
                    <IconButton id="glossaryUpdateButton">
                        <GrEdit
                            style={{ fontSize: "17px" }}
                            onClick={(e) => handleUpdate(e, data)}
                        />
                    </IconButton>
                    <IconButton id="glossaryDeleteButton">
                        <DeleteIcon
                            style={{ fontSize: "17px" }}
                            onClick={(e) => handleDelete(e, data)}
                        />
                    </IconButton>
                </Grid>
            </Grid>
        </AccordionSummary>
    );

    return (
        <div>
        <Navbar />
        {isLoading ? (
            <LoadingComponent />
        ) : (
            <Box sx={{ px: { md: 7, xs: 1 } }}>
                <Box pt={1}>
                    <PageHeader
                        title={<Translate>Glossary</Translate>}
                        showSearchBar={true}
                        buttonTitle={<Translate>New Definition</Translate>}
                        handleAdd={handleClickOpen}
                        handleSearch={handleSearch}
                        setSearch={setSearch}
                    />
                    <ExpandAllButton expand={expand} setState={setState} />
                    <Grid container className="glossaryAlphabet">
                        {alphabetsList &&
                            alphabetsList.map((item, index) => {
                                return (
                                    <Grid item xs={0.4}>
                                        <IconButton
                                            id={`glossaryAlphabets ${index}`}
                                            className={`glossaryIconButton ${
                                                item === currentAlphabet
                                                    ? "secondaryColor"
                                                    : ""
                                            }`}
                                            key={index}
                                            onClick={() =>
                                                getGlossaryData(item)
                                            }
                                        >
                                            {item}
                                        </IconButton>
                                    </Grid>
                                );
                            })}
                    </Grid>
                    <Grid container pt={2} gap={2} pb={2}>
                        <ContextProvider.Provider value={expand}>
                            {filteredData?.length ? (
                                filteredData
                                    ?.sort((a, b) =>
                                        a.term.toLowerCase() >
                                        b.term.toLowerCase()
                                            ? 1
                                            : b.term.toLowerCase() >
                                              a.term.toLowerCase()
                                            ? -1
                                            : 0
                                    )
                                    .map((data, index) => (
                                        <AccordionCustom
                                            key={data.id}
                                            accordionSummary={getAccordionSummary(
                                                data
                                            )}
                                            expand={
                                                expandSingle !== null
                                                    ? data.id === expandSingle
                                                    : false
                                            }
                                            id={`glossaryAccordionSummary ${index}`}
                                        >
                                            <AccordionDetails>
                                                <div
                                                    style={{
                                                        fontSize:
                                                            "16px !important",
                                                    }}
                                                    dangerouslySetInnerHTML={{
                                                        __html: data?.definition,
                                                    }}
                                                />
                                            </AccordionDetails>
                                        </AccordionCustom>
                                    ))
                            ) : (
                                <NoInfoAvailable
                                    className="noCustomerInfo"
                                    noInfoTypo="noInfoTypoCustomer"
                                />
                            )}
                        </ContextProvider.Provider>
                    </Grid>
                </Box>
            </Box>
        )}
        <CommonDeleteDialog
            open={openDeleteDialog}
            title={<Translate>Delete Definition</Translate>}
            cancelButtonAction={closeDelete}
            cancelButtonTitle={<Translate>Cancel</Translate>}
            submitButtonTitle={<Translate>Delete</Translate>}
            content={
                <Translate>
                    Are you sure, you want to remove the "{deleteData?.term}" from
                    the definition?
                </Translate>
            }
            submitButtonAction={handleDeleteAction}
            submitButtonParameter={deleteData?.id}
        />
        <AddDefinitionDialog
            open={openAddDialog}
            handleClose={handleCloseDialog}
            handleSubmit={handleSubmit}
            state={state}
            setState={setState}
            termList={glossaryList}
            type={type}
        />
    </div>
    
    );
};

const mapStateToProps = (state) => {
    return {
        glossaryList: state.glossary.glossaryList,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        { getGlossaryList, addGlossary, deleteGlossary, updateGlossary },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Glossary);
