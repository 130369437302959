import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CheckboxComponents from "../../../components/Checkbox/CheckboxComponent";
import IconButton from "@mui/material/IconButton";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextFieldComponent from "../../../components/TextField/TextFieldComponent";
import ButtonComponent from "../../../components/ButtonComponent";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";

const Checkbox = (props) => {
    const {
        state,
        setState,
        data,
        children,
        handleChange,
        setMenuItem,
        handlerFieldChange,
        isRead,
        isEdit,
    } = props;

    const [checkBoxState, setCheckBoxState] = useState({
        editQueLabel: false,
        editOptLabel: false,
        checkOptionEvent: null,
    });

    const { editQueLabel, editOptLabel, checkOptionEvent } = checkBoxState;

    const handlerIsEdit = () => {
        if (isEdit) {
            setState((prevState) => ({ ...prevState, editLabel: true }));
        }
    };
    const onBlur = () => {
        setState((prevState) => ({ ...prevState, editLabel: false }));
    };

    const handlerChangeOption = (e) => {
        setCheckBoxState((prevState) => ({
            ...prevState,
            checkOptionEvent: e,
        }));
    };

    const onChangeCheckboxOpt = () => {
        setCheckBoxState((prevState) => ({
            ...prevState,
            checkboxOpt: [
                ...(state?.checkboxOpt ?? ""),
                checkOptionEvent.target.value,
            ],
            editOptLabel: false,
        }));
        setMenuItem(checkOptionEvent);
    };

    const handlerCloseOption = () => {
        setCheckBoxState((prevState) => ({
            ...prevState,
            editOptLabel: false,
        }));
    };

    const romoveOpt = (index) => {
        let copyData = data?.fieldDetails?.options;
        copyData.splice(index, 1);
        setState((prevState) => ({
            ...prevState,
            radioOptions: copyData,
        }));
    };

    const handleChecked = (option) => {
        let checkedOptValue = data?.formValues?.checkBoxOption?.find(
            (obj) => obj == option
        );
        return !!checkedOptValue;
    };

    const handleEditQueLabel = (data) => {
        setCheckBoxState((prevState) => ({ ...prevState, editQueLabel: data }));
    };

    return (
        <Grid container>
            <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "space-between" }}
            >
                <TextFieldComponent
                    sx={{
                        display:
                            state.selectedEle?.selected && state?.editLabel
                                ? "block"
                                : "none",
                        color: state.fontColor,
                        "& .MuiInputBase-input": { color: state.fontColor },
                    }}
                    onBlur={onBlur}
                    onChange={handleChange}
                    value={data?.label}
                    className="elementHeadingTextField"
                    autoFocus={true}
                    id="checkboxLabel"
                />
                <Typography
                    display={
                        state.selectedEle?.selected && state?.editLabel
                            ? "none"
                            : "block"
                    }
                    className="elementHeading"
                    variant="h5"
                    onClick={handlerIsEdit}
                    sx={{ color: state.fontColor }}
                >
                    {data.label}
                </Typography>
                {children}
            </Grid>
            <Grid item container spacing={2}>
                {isEdit ? (
                    <Grid item xs={12}>
                        <TextFieldComponent
                            placeholder={"Enter Question"}
                            id="checkboxQuestion"
                            sx={{
                                display:
                                    state?.selectedEle?.selected && editQueLabel
                                        ? "block"
                                        : "none",
                                color: state?.fontColor,
                            }}
                            className="elementHeadingTextField font_16_600"
                            onBlur={() => handleEditQueLabel(false)}
                            onChange={setMenuItem}
                            name={"checkBoxQuestion"}
                            value={data?.fieldDetails?.question}
                        />
                        <Typography
                            className="font_16_600"
                            sx={{
                                display: editQueLabel ? "none" : "block",
                                color: state?.fontColor,
                            }}
                            onClick={() => handleEditQueLabel(true)}
                        >
                            {data?.fieldDetails?.question ||
                                " Type Question/Test"}
                        </Typography>
                        <FormGroup>
                            {data?.fieldDetails?.options?.map(
                                (element, index) => {
                                    return (
                                        <FormControlLabel
                                            key={index}
                                            value={`${element}`}
                                            disabled={isRead}
                                            sx={{ color: state?.fontColor }}
                                            control={
                                                <CheckboxComponents
                                                    id="FormStudioCheckbox"
                                                    checked={handleChecked(
                                                        `${element}`
                                                    )}
                                                    sx={{
                                                        color: state?.fontColor,
                                                        "&, &.MuiCheckbox-root":
                                                            {
                                                                color: state?.fontColor,
                                                            },
                                                    }}
                                                />
                                            }
                                            label={
                                                <Grid
                                                    container
                                                    className="justifyBetweenClass"
                                                    gap={4}
                                                    sx={{
                                                        width: "150px",
                                                        color: state?.fontColor,
                                                    }}
                                                >
                                                    <Grid item>{element}</Grid>
                                                    <Grid
                                                        item
                                                        sx={{
                                                            color: state?.fontColor,
                                                        }}
                                                    >
                                                        <IconButton
                                                            id="FormStudioCheckboxRomove"
                                                            onClick={() =>
                                                                romoveOpt(index)
                                                            }
                                                            sx={{
                                                                color: state?.fontColor,
                                                            }}
                                                        >
                                                            <HighlightOffIcon />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                            }
                                            className="radio_label"
                                        />
                                    );
                                }
                            )}
                            <Box
                                sx={{
                                    mt: 1,
                                }}
                            >
                                {editOptLabel ? (
                                    <Box className="addOptionBox">
                                        <TextFieldComponent
                                            onChange={handlerChangeOption}
                                            className="textFieldFromBuilder"
                                            placeholder="Enter Option"
                                            name={"optionLabel"}
                                            id="checkboxOption"
                                        />
                                        <Box className="addCancelButtonBox">
                                            <IconButton
                                                id="FormStudioCheckboxAdd"
                                                onClick={onChangeCheckboxOpt}
                                            >
                                                <CheckIcon />
                                            </IconButton>
                                            <IconButton
                                                id="FormStudioCheckboxClear"
                                                onClick={handlerCloseOption}
                                            >
                                                <ClearIcon />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                ) : (
                                    <ButtonComponent
                                        id="FormStudioCheckboxAddButton"
                                        variant="text"
                                        title={"Add More Option"}
                                        sx={{ color: state?.fontColor }}
                                        onClick={() => {
                                            setCheckBoxState((prevState) => ({
                                                ...prevState,
                                                editOptLabel: true,
                                            }));
                                        }}
                                    />
                                )}
                            </Box>
                        </FormGroup>
                    </Grid>
                ) : (
                    <Grid item xs={12}>
                        <FormControl>
                            <Typography
                                className="font_16_600"
                                sx={{ color: state?.fontColor }}
                            >
                                {data?.fieldDetails?.question ||
                                    " Type Question/Test"}
                            </Typography>
                            <FormGroup
                                row={true}
                                disabled={isRead}
                                required={true}
                            >
                                {data?.fieldDetails?.options?.map(
                                    (element, index) => {
                                        return (
                                            <FormControlLabel
                                                onChange={(event) =>
                                                    handlerFieldChange(
                                                        event,
                                                        data?.id,
                                                        "checkBoxOption"
                                                    )
                                                }
                                                name={"checkBoxOption"}
                                                key={index}
                                                value={element}
                                                control={
                                                    <CheckboxComponents
                                                        id="FormStudioCheckboxOptionsCheck"
                                                        sx={{
                                                            color: state?.fontColor,
                                                            "&, &.MuiCheckbox-root":
                                                                {
                                                                    color: state?.fontColor,
                                                                },
                                                        }}
                                                        checked={handleChecked(
                                                            `${element}`
                                                        )}
                                                    />
                                                }
                                                sx={{ color: state?.fontColor }}
                                                label={element}
                                                className="radio_label"
                                            />
                                        );
                                    }
                                )}
                            </FormGroup>
                        </FormControl>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};

export default Checkbox;
