import { API_CONSTANTS } from "../../config/ApiConstants";
import { getApiCall, postApiCall, putApiCall } from "../../utils/Action";
import { ROUTES } from "../../router/RouteList";
import { toast } from "react-hot-toast";

export const GET_WHITE_LABEL_INFO = "GET_WHITE_LABEL_INFO";
export const GET_WHITE_LABEL_BY_ID = "GET_WHITE_LABEL_BY_ID";

export const getWhiteLabelInfo = (url, navigate) => {
    return async (dispatch) => {
        try {
            const response = await postApiCall(
                API_CONSTANTS.WHITE_LABELLING + "/info",
                { uri: url }
            );

            if (response?.status === 400 || response?.status === 422) {
                localStorage.clear();
                window.history.pushState([], [], window.location.href);
                dispatch({
                    type: GET_WHITE_LABEL_INFO,
                    payload: { client_id: "" },
                });
                // navigate(ROUTES.PAGE_NOT_FOUND);
            } else {
                return dispatch({
                    type: GET_WHITE_LABEL_INFO,
                    payload: response?.data?.result,
                });
            }
        } catch (error) {
            dispatch({
                type: GET_WHITE_LABEL_INFO,
                payload: { client_id: "" },
            });

            throw error;
        }
    };
};

export const updateWhiteLabelling = (data) => {
    return async (dispatch) => {
        try {
            const response = await putApiCall(
                API_CONSTANTS.WHITE_LABELLING + "/" + data.client_id,
                data
            );
            if (response?.status === 200) {
                dispatch({
                    type: GET_WHITE_LABEL_BY_ID,
                    payload: response?.data?.result,
                });
                toast.success(response?.data?.message);
            }
        } catch (error) {
            throw error;
        }
    };
};

export const getWhiteLabelById = (id) => {
    return async (dispatch) => {
        try {
            const response = await getApiCall(
                API_CONSTANTS.WHITE_LABELLING + `/${id}`
            );
            if (response?.status === 200) {
                return dispatch({
                    type: GET_WHITE_LABEL_BY_ID,
                    payload: response?.data?.result,
                });
            }
        } catch (error) {
            throw error;
        }
    };
};

export const setWhiteLabel = (data) => {
    return async (dispatch) => {
        dispatch({ type: GET_WHITE_LABEL_BY_ID, payload: data });
    };
};
