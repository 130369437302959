// import parsePhoneNumberFromString, { AsYouType } from "libphonenumber-js";
// import parsePhoneNumber from "libphonenumber-js";
import { toast } from "react-hot-toast";

export const getAccessToken = () => {
    return sessionStorage.getItem("accessToken");
};

export const addEllipsis = (str, limit) => {
    return str.length > limit ? str.substring(0, limit) + "..." : str;
};

export const getType = (value, body) => {
    if (value.params) {
        return { params: body };
    } else if (value.query) {
        if (typeof body === "object") {
            return { query: body._id };
        } else {
            return { query: body };
        }
    }
    return {};
};

export const permissionDenied = () => {
    toast.error(
        "You don't have the permission to access this. Kindly refer to your administrator for permissions."
    );
};

export const getFileValidation = (validation) => {
    let list;
    if (validation.includes("image")) {
        list = { "image/*": [".png", ".jpeg", ".jpg"] };
    }
    if (validation.includes("video")) {
        list = { ...list, "video/*": [".mp4", ".mov", ".wvm"] };
    }
    if (validation.includes("application")) {
        list = {
            ...list,
            "application/*": [
                "vnd.ms-excel",
                ".docx",
                ".pdf",
                ".doc",
                ".msword",
            ],
        };
    }
    if (validation.includes("audio")) {
        list = {
            ...list,
            "audio/*": [".mp3", ".docx"],
        };
    }
    return list;
};
