import React, { useEffect, useState } from "react";
import { Grid, Icon, MenuItem, Tooltip, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { TestOptions, yesNoOptions } from "../../../../../../utils/CommonData";
import GroupingCategoryWeight from "./GroupingCategoryWeight";
import HybridCategoryWeight from "./HybridCategoryWeight";
import BalancingCategory from "./BalancingCategory";
import DropDownComponent from "../../../../../../components/DropDown/DropDownComponent";
import RadioButtonGroup from "../../../../../../components/RadioButtonGroup/RadioButtonGroup";
import TextFieldComponent from "../../../../../../components/TextField/TextFieldComponent";
import CheckboxComponents from "../../../../../../components/Checkbox/CheckboxComponent";
import RoleSettings from "./RoleSettings";
import ButtonComponent from "../../../../../../components/ButtonComponent";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getUserById } from "../../../../../../store/Action/UserAction";
const TestCommanSection = (props) => {
    const {
        stateTest,
        setStateTest,
        setStateMainAgreement,
        stateMainAgreement,
        getSelfConfigration,
        addNumberOfValue,
        handleTableDataState,
        testCategory,
        handleCreateTest,
        handleEditTest,
        guid,
        userLoggedIn
    } = props;
    const [option, setOption] = useState(0);
    const [testTimer, setTestTimer] = useState(0);


    const testDuration = [
        {
            label: userLoggedIn?.language_data?.comman?.yes_btn || "Yes",
            value: 0,
        },
        {
            label:  "No",
            value: 1,
        },
    ];
    useEffect(() => {
        if (stateTest.category_setting_type === 1) {
            const testData = getSelfConfigration?.setting?.filter((item) => {
                return item.test_id === stateTest.test_id;
            });
            setStateTest((prevState) => ({
                ...prevState,
                category_setting_type: testData?.[0]?.category_setting_type,
                category_settings_list: testData?.[0]?.category_settings,
                category_settings: testData?.[0]?.category_settings,
            }));
        }
    }, [stateTest.category_setting_type]);

    const selectedCategoryType = (e) => {
        setStateTest((prevState) => ({
            ...prevState,
            testType: parseInt(e),
            category_setting_type: parseInt(e),
            category_settings: [],
        }));
    };

    const handleTestNameChange = (event) => {
        const { value, name } = event.target;
        const testData = getSelfConfigration?.setting?.filter((item) => {
            return item.test_id === value;
        });
        setStateTest((prevState) => ({
            ...prevState,
            testName: testData?.[0]?.test_name,
            test_id: testData?.[0]?.test_id,
            setestedTest: testData,
            applicable_laws: testData?.[0]?.applicable_laws,
            test_type: testData?.[0]?.test_type,
            jurisdiction: testData?.[0]?.jurisdiction,
            category_setting_type: testData?.[0]?.category_setting_type,
            category_settings_list: testData?.[0]?.category_settings,
            category_settings: testData?.[0]?.category_settings,
        }));
    };
    const handlerNoOfDays = (event) => {
        setStateTest((prevState) => ({
            ...prevState,
            recurring_test_time: event.target.value,
        }));
    };
    const handleNotification = (event) => {
        if (event.target.name === "email_notification") {
            setStateTest((prevState) => ({
                ...prevState,
                notification_setting: {
                    ...stateTest.notification_setting,
                    email_notification:
                        !stateTest.notification_setting.email_notification,
                },
            }));
        } else {
            setStateTest((prevState) => ({
                ...prevState,
                notification_setting: {
                    ...stateTest.notification_setting,
                    system_notification:
                        !stateTest.notification_setting.system_notification,
                },
            }));
        }
    };
    const handlerTestDuration = (event) => {
        setStateTest((prevState) => ({
            ...prevState,
            max_time: event.target.value,
        }));
    };

    return (
        <Grid item md={12} lg={12}>
            <Grid container spacing={2} marginBottom={2}>
                <Grid item md={6} lg={6}>
                    <DropDownComponent
                        label="Test Name"
                        fullWidth={true}
                        onChange={(event) => {
                            handleTestNameChange(event);
                        }}
                        value={stateTest?.test_id}
                        disabled={stateTest.isEdit ? false : true}
                    >
                        {getSelfConfigration?.tests?.map((test, index) => (
                            <MenuItem
                                value={test.id}
                                key={index}
                                className="subheader"
                            >
                                {test.name}
                            </MenuItem>
                        ))}
                    </DropDownComponent>
                </Grid>
                <Grid item md={6} lg={6}>
                    <Typography

                        variant="span"
                        mr={1}
                        mb={1}
                    >
                        Applicable Laws
                    </Typography>
                    <Typography className="font_16_600" style={{ marginTop: '10px' }}>
                        {stateTest?.setestedTest === null
                            ? []
                            : stateTest?.setestedTest?.map((law, index) => (
                                law.applicable_laws

                            ))}
                    </Typography>
                    {/* <DropDownComponent
                        label="Applicable Laws"
                        fullWidth={true}
                        value={
                            stateTest?.applicable_laws !== null
                                ? stateTest?.applicable_laws
                                : ""
                        }
                        disabled={stateTest.isEdit ? false : true}
                    >
                        {stateTest?.setestedTest === null
                            ? []
                            : stateTest?.setestedTest?.map((law, index) => (
                                  <MenuItem
                                      value={law.applicable_laws}
                                      key={index}
                                      className="subheader"
                                  >
                                      {law.applicable_laws}
                                  </MenuItem>
                              ))}
                    </DropDownComponent> */}
                </Grid>
                <Grid item md={6} lg={6}>
                    <Typography

                        variant="span"
                        mr={1}
                        mb={1}
                    >
                        Test Type
                    </Typography>
                    <Typography className="font_16_600" style={{ marginTop: '10px' }}>
                        {stateTest?.setestedTest?.map((type, index) => (
                            type?.test_type.toUpperCase()
                        ))}
                    </Typography>
                    {/* <DropDownComponent
                        label="Test Type"
                        fullWidth={true}
                        required={true}
                        value={stateTest?.test_type}
                        disabled={stateTest.isEdit ? false : true}
                    >
                        {stateTest?.setestedTest &&
                            stateTest?.setestedTest?.map((type, index) => (
                                <MenuItem
                                    value={type.test_type}
                                    key={index}
                                    className="subheader"
                                >
                                    {type?.test_type.toUpperCase()}
                                </MenuItem>
                            ))}
                    </DropDownComponent> */}
                </Grid>
                <Grid item md={6} lg={6}>
                    <Typography

                        variant="span"
                        mr={1}
                        mb={1}
                    >
                        {userLoggedIn?.language_data?.customer?.jurisdiction || "Jurisdiction"}
                    </Typography>
                    <Typography className="font_16_600" style={{ marginTop: '10px' }}>
                        {stateTest?.setestedTest &&
                            stateTest?.setestedTest?.map((juris, index) => (
                                juris.jurisdiction
                            ))}
                    </Typography>
                    {/* <DropDownComponent
                        label={userLoggedIn?.language_data?.customer?.jurisdiction || "Jurisdiction"}
                        fullWidth={true}
                        required={true}
                        value={stateTest?.jurisdiction}
                        disabled={stateTest.isEdit ? false : true}
                    >
                        {stateTest?.setestedTest &&
                            stateTest?.setestedTest?.map((juris, index) => (
                                <MenuItem
                                    value={juris.jurisdiction}
                                    key={index}
                                    className="subheader"
                                >
                                    {juris.jurisdiction}
                                </MenuItem>
                            ))}
                    </DropDownComponent> */}
                </Grid>
            </Grid>
            {stateTest.test_type !== "" && stateTest.test_type === "ata" ? (
                <Grid item md={6} lg={6} display={"flex"} alignItems={"center"}>
                    <Grid item>
                        <RadioButtonGroup
                            row={true}
                            title="Do you want independent contractors to take an Audit Trigger Assessment?"
                            options={yesNoOptions}
                            defaultValue={yesNoOptions[0].value}
                            onChange={(e) =>
                                setOption(parseInt(e.target.value))
                            }
                            disabled={stateTest.isEdit ? false : true}
                        />
                        <RadioButtonGroup
                            row={true}
                            title=" Apply the test only the first time an independent contractor is onboarded into the system?"
                            options={yesNoOptions}
                            defaultValue={yesNoOptions[0].value}
                            onChange={(e) =>
                                setOption(parseInt(e.target.value))
                            }
                            disabled={stateTest.isEdit ? false : true}
                        />
                    </Grid>
                    <Grid item>
                        <Tooltip
                            arrow={true}
                            title={`If “No” is checked, the Contracting Business Health Assessment will                                  be applied every time an independent contractor takes an independent
                                contractor compliance test.  It is important to mention that the Contracting
                                Business Health Assessment is different each time the contractor takes
                                it because the Entity Health questions are relevant to the stage of the
                                relationship (e.g., a contractor that is onboarding for the first time will
                                receive a different set of questions than a contractor who has already been
                                performing services for 6 months), and are taken from a database that changes
                                over time as new Entity Healths are learned and other relevant factors arise`}
                            placement="right"
                        >
                            <Icon
                                className="infoIcon"
                                sx={{ alignItems: "center" }}
                            >
                                <InfoIcon
                                    sx={{
                                        alignItems: "center",
                                    }}
                                />
                            </Icon>
                        </Tooltip>
                    </Grid>
                </Grid>
            ) : null}
            {stateTest.test_type === "cbha" ? (
                <Grid container spacing={2} mt={2}>
                    <Grid
                        item
                        md={6}
                        lg={6}
                        display={"flex"}
                        alignItems={"center"}
                    >
                        <Grid item>
                            <RadioButtonGroup
                                row={true}
                                title=" Do you want independent contractors to take a Contracting Business Health Assessment?"
                                options={yesNoOptions}
                                defaultValue={yesNoOptions[0].value}
                                disabled={stateTest.isEdit ? false : true}
                                onChange={(e) =>
                                    setOption(parseInt(e.target.value))
                                }
                            />
                            <RadioButtonGroup
                                row={true}
                                title={`Apply the test only the first time an independent contractor is onboarded into the system?`}
                                options={yesNoOptions}
                                disabled={stateTest.isEdit ? false : true}
                                defaultValue={yesNoOptions[0].value}
                                onChange={(e) =>
                                    setOption(parseInt(e.target.value))
                                }
                            />
                        </Grid>

                        <Tooltip
                            arrow={true}
                            title={`If “No” is checked, the Contracting Business Health Assessment will
                be applied every time an independent contractor takes an independent
                contractor compliance test. It is important to mention that the Contracting
                Business Health Assessment is different each time the contractor takes
                it because the Entity Health questions are relevant to the stage of the
                relationship (e.g., a contractor that is onboarding for the first time will
                receive a different set of questions than a contractor who has already been
                performing services for 6 months), and are taken from a database that changes
                over time as new Entity Healths are learned and other relevant factors arise`}
                            placement="right"
                        >
                            <Icon
                                className="infoIcon"
                                sx={{ alignItems: "center" }}
                            >
                                <InfoIcon
                                    sx={{
                                        alignItems: "center",
                                    }}
                                />
                            </Icon>
                        </Tooltip>
                    </Grid>
                </Grid>
            ) : null}
            {stateTest.test_type && (
                <>
                    <Grid container spacing={0} mt={2}>
                        <Grid
                            item
                            lg={12}
                            md={12}
                            display={"flex"}
                            alignItems={"center"}
                        >
                            <RadioButtonGroup
                                row={true}
                                title="Category Settings"
                                options={TestOptions}
                                defaultValue={stateTest?.category_setting_type}
                                onChange={(e) =>
                                    selectedCategoryType(e.target.value)
                                }
                                value={stateTest?.category_setting_type}
                                disabled={stateTest.isEdit ? false : true}
                                sx={{
                                    display: "flex",
                                    flexWrap: "nowrap",
                                }}
                            />
                            <Tooltip
                                arrow={true}
                                title={userLoggedIn?.language_data?.comman?.no_info || `No Information Available`}
                                placement="right"
                            >
                                <Icon
                                    className="infoIcon"
                                    sx={{ alignItems: "center" }}
                                >
                                    <InfoIcon
                                        sx={{
                                            alignItems: "center",
                                        }}
                                    />
                                </Icon>
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} marginY={0}>
                        <Grid item lg={12} md={12} className="table-box-shadow">
                            {stateTest?.category_setting_type === 1 && (
                                <BalancingCategory
                                    stateTest={stateTest}
                                    setStateTest={setStateTest}
                                    addNumberOfValue={addNumberOfValue}
                                    guid={guid}
                                />
                            )}
                            {stateTest?.category_setting_type === 2 && (
                                <GroupingCategoryWeight
                                    tableData={
                                        stateMainAgreement.questionsData
                                            ?.formData?.response || []
                                    }
                                    stateTest={stateTest}
                                    setStateTest={setStateTest}
                                    addNumberOfValue={addNumberOfValue}
                                    readOnly={false}
                                    testCategory={testCategory}
                                    name="response"
                                />
                            )}
                            {stateTest?.category_setting_type === 3 && (
                                <HybridCategoryWeight
                                    tableData={
                                        stateMainAgreement.questionsData
                                            ?.formData
                                            ?.hybrid_category_setting || []
                                    }
                                    stateTest={stateTest}
                                    setStateTest={setStateTest}
                                    handleTableDataState={handleTableDataState}
                                    readOnly={false}
                                    testCategory={testCategory}
                                    name="hybrid_category_setting"
                                />
                            )}
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        display={"flex"}
                        mt={3}
                        alignItems={"center"}
                    >
                        <Grid item md={6}>
                            <Typography
                                className="font_16_600"
                                variant="span"
                                mr={1}
                                mb={1}
                            >
                                Max No. of attempt allowed
                            </Typography>
                            <input
                                type="number"
                                className="inputNumberMax font_16_600"
                                min={1}
                                value={stateTest.max_attempt}
                                disabled={stateTest.isEdit ? false : true}
                                onChange={(event) =>
                                    setStateTest((prevState) => ({
                                        ...prevState,
                                        max_attempt: parseInt(
                                            event.target.value
                                        ),
                                    }))
                                }
                            />
                        </Grid>
                        <Grid
                            item
                            flexDirection={"row"}
                            display={"flex"}
                            flexWrap={"wrap"}
                            gap={4}
                            md={6}
                        >
                            <Grid item md={5}>
                                <RadioButtonGroup
                                    sx={{
                                        display: "flex",
                                        flexWrap: "nowrap",
                                    }}
                                    row={true}
                                    disabled={stateTest.isEdit ? false : true}
                                    title="Recurring Test?"
                                    options={yesNoOptions}
                                    defaultValue={yesNoOptions[0].value}
                                    value={
                                        stateTest.recurring_test === 1
                                            ? "yes"
                                            :  "no"
                                    }
                                    onChange={(event) =>
                                        setStateTest((prevState) => ({
                                            ...prevState,
                                            recurring_test: parseInt(
                                                event.target.value === "yes"
                                                    ? 1
                                                    : 0
                                            ),
                                        }))
                                    }
                                />
                            </Grid>
                            <Grid item md={6}>
                                {stateTest.recurring_test === 1 && (
                                    <DropDownComponent
                                        label="No. of Days"
                                        required={true}
                                        sx={{ width: "100%" }}
                                        onChange={handlerNoOfDays}
                                        fullWidth={true}
                                        disabled={
                                            stateTest.isEdit ? false : true
                                        }
                                        defaultValue={
                                            stateTest.recurring_test_time
                                        }
                                        value={stateTest.recurring_test_time}
                                    >
                                        {[1, 7, 15, 30, 45, 60, 90].map(
                                            (obj) => (
                                                <MenuItem
                                                    className="subheader"
                                                    value={obj}
                                                >
                                                    {obj}
                                                </MenuItem>
                                            )
                                        )}
                                    </DropDownComponent>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        mt={2.5}
                        alignItems={"center"}
                        display={"flex"}
                    >
                        <Grid item md={6}>
                            <Typography
                                className="font_16_600"
                                variant="span"
                                mr={1}
                            >
                                Notification Settings
                            </Typography>
                            <Grid container mt={1}>
                                <Grid item md={5}>
                                    <CheckboxComponents
                                        label={"Email Notification"}
                                        name="email_notification"
                                        disabled={
                                            stateTest.isEdit ? false : true
                                        }
                                        checked={
                                            stateTest?.notification_setting
                                                ?.email_notification
                                        }
                                        onChange={(event) => {
                                            handleNotification(event);
                                        }}
                                    />
                                    Email Notification
                                </Grid>
                                <Grid item md={6}>
                                    <CheckboxComponents
                                        name="system_notification"
                                        checked={
                                            stateTest?.notification_setting
                                                ?.system_notification
                                        }
                                        disabled={
                                            stateTest.isEdit ? false : true
                                        }
                                        onChange={(event) => {
                                            handleNotification(event);
                                        }}
                                    />
                                    System Notification
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            flexDirection={"row"}
                            display={"flex"}
                            flexWrap={"wrap"}
                            gap={4}
                            md={6}
                        >
                            <Grid mt={2} md={5} item>
                                <RadioButtonGroup
                                    row={true}
                                    title="Do you want to set Test Duration?"
                                    options={testDuration}
                                    defaultValue={testDuration[0].value}
                                    disabled={stateTest.isEdit ? false : true}
                                    onChange={(e) =>
                                        setTestTimer(parseInt(e.target.value))
                                    }
                                />
                            </Grid>
                            <Grid item md={6}>
                                {testTimer === 0 && (
                                    <TextFieldComponent
                                        sx={{ width: "100%" }}
                                        label={"Minutes"}
                                        onChange={handlerTestDuration}
                                        type={Number}
                                        defaultValue={stateTest.max_time}
                                        value={stateTest.max_time}
                                        disabled={
                                            stateTest.isEdit ? false : true
                                        }
                                        id="testCommonSectionMinutes"
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} marginY={0.5}>
                        <Grid item lg={12} md={12} className="table-box-shadow">
                            <RoleSettings
                                tableData={stateTest.role_setting}
                                handleTableDataState={handleTableDataState}
                                readOnly={stateTest.isEdit ? false : true}
                                name="role_settings"
                            />
                        </Grid>
                        {!stateTest.isView || stateTest.isEdit ? (
                            <>
                                <Grid
                                    container
                                    display={"flex"}
                                    justifyContent={"flex-end"}
                                    gap={2}
                                    mt={2}
                                >
                                    <ButtonComponent
                                        title={
                                            stateTest.isEditId
                                                ? "Update"
                                                : "Create"
                                        }
                                        onClick={
                                            stateTest.isEditId
                                                ? handleEditTest
                                                : handleCreateTest
                                        }
                                    />
                                </Grid>
                            </>
                        ) : null}
                    </Grid>
                </>
            )}
        </Grid>
    );
};

const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,

    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getUserById,
        },
        dispatch
    );
};


export default connect(mapStateToProps, mapDispatchToProps)(TestCommanSection);
