import { Grid, Typography } from "@mui/material";
import React from "react";
import {Translate } from 'react-auto-translate';
const question1 = [
    { question: "Unique Question Code", answer: "113.1" },
    {
        question: " Question",
        answer: `With regard to the Services you will provide, do you
    believe the working relationship will present a degree
    of continuity, loyalty, security, subordination, or
    integration generally associated with an
    employer-employee relationship?`,
    },
    {
        question: "Range of Available Responses + Scoring",
        answer: `7 – Very untrue of what I believe
    6 – Untrue of what I believe
    5 – Somewhat untrue of what I believe
    4 – Neutral
    3 – Somewhat true of what I believe
    2 – True of what I believe
    1 – Very true of what I believe`,
    },
    {
        question: "Test Taker's Response",
        answer: "7 – Very untrue of what I believe",
    },
    { question: "Remediation Information", answer: "N/A" },
    { question: "Test Taker's Response", answer: "N/A" },
    { question: "Second Level Remediation Information", answer: "N/A" },
    { question: "Test Taker's Response", answer: "N/A" },
    { question: "Overrided Questions", answer: "No" },
    {
        question: `Were the Questions listed in the "Overrided Questions" Column Overriden?`,
        answer: "No",
    },
    { question: "Was Knock-Over Status Triggered?", answer: "Yes" },
    { question: "Reservation of Right Content", answer: "No" },

    {
        question:
            "Range of Available Responses for Reservation of Right Content",
        answer: "N/A",
    },
    {
        question: "Was the Reservation of Right Content Triggered?",
        answer: "N/A",
    },
    { question: "Alternative Response Content", answer: "N/A" },
    { question: "Range of Responses to Alternative Content", answer: "N/A" },
    {
        question: "Response that Activates Repression of Question",
        answer: "N/A",
    },
    { question: "Was the Compliance Question Repressed?", answer: "No" },
];
const TestingData = (props) => {
    const {data} = props
    return (
        <>
        {/* <Grid container marginY={2}>
            <Grid item xs={6}>
                <Typography className="testingDataQText">
                    <Translate>Unique Question Code</Translate>
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography className="testingDataQText">
                    {data.unique_question_code}
                </Typography>
            </Grid>
        </Grid>
    
        <Grid container marginY={2}>
            <Grid item xs={6}>
                <Typography className="testingDataQText">
                    <Translate>Question</Translate>
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography className="testingDataQText">
                    {data.question}
                </Typography>
            </Grid>
        </Grid> */}
    
        <Grid container marginY={2}>
            <Grid item xs={6}>
                <Typography className="testingDataQText">
                    <Translate>Test Taker's Response</Translate>
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography className="testingDataQText">
                    {data?.user_response}
                </Typography>
            </Grid>
        </Grid>
    
        <Grid container marginY={2}>
            <Grid item xs={6}>
                <Typography className="testingDataQText testingDataQTextlight">
                    <Translate>Range of Available Responses + Scoring</Translate>
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography className="testingDataQText testingDataQTextlight">
                    {data?.available_response}
                </Typography>
            </Grid>
        </Grid>
    </>
    
    );
};

export default TestingData;
