import React, { Fragment, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TableComponent from "../../components/Table/TableComponent";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import "../../../assets/styles/Container/ScoringConfiguration.scss";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TooltipComponent from "../../components/ToolTip/TooltipComponent";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import { ReactComponent as DeleteIcon } from "../../../assets/Images/Icons/DeleteIcon.svg";
import Fab from "@mui/material/Fab";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ButtonComponent from "../../components/ButtonComponent";
import DialogComponent from "../../components/DialogsBox/DialogComponent";
import DropDownComponent from "../../components/DropDown/DropDownComponent";
import { getUserById } from "../../store/Action/UserAction";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
const CollarColor = (props) => {
    const [age, setAge] = React.useState("");
    const { userLoggedIn } = props;

    const handleChange = (event) => {
        setAge(event.target.value);
    };

    const headerList = [
        {
            id: 1,
            name: "Questions",
            headerClass: "questionTableHeading",
            accessibleKey: "question",
            rowClass: "questionTableHeadingRow",
            headerAlign: "start",
            rowAlign: "center",
        },
    ];

    const CollarColorList = [
        {
            question:
                "Will you provide services in your capacity as a sole proprietor or through a company that you formed, such as, for example, a limited liability company?",
            BestResponse: "No",
            Score: "2",
            value: "bm",
        },
        {
            question:
                "With regard to the services you have been hired to perform, have you performed -- in your capacity as an independent contractor -- the same or substantially similar type of services for any other company within the past 12 months?",
            BestResponse: "No",
            Score: "2",
            value: "bm",
        },
        {
            question:
                "Will you have general liability insurance and at least 1 other type of commercial insurance to protect your business while performing the services?",
            BestResponse: "No",
            Score: "2",
            value: "bm",
        },
        {
            question:
                "Do you have a business website or social media site which describes the business services you offer, and, if yes, will you be providing those type of services to the Service Recipient?",
            BestResponse: "No",
            Score: "2",
            value: "bm",
        },
        {
            question:
                "Will you submit invoices for the services performed as a pre-requisite to get paid, i.e., if you do not submit an invoice, then you do not get paid for the services you performed?",
            BestResponse: "No",
            Score: "2",
            value: "bm",
        },
        {
            question:
                "Will the Contractor provide services in his/her capacity as a sole proprietor or through a company that the Contractor formed, such as, for example, a limited liability company?",
            BestResponse: "No",
            Score: "2",
            value: "bm",
        },
        {
            question:
                "With regard to the services the Contractor has been hired to perform, has the Contractor performed -- in his/her capacity as an independent contractor -- the same or substantially similar type of services for any other company within the past 12 months?",
            BestResponse: "No",
            Score: "2",
            value: "bm",
        },
        {
            question:
                "Will the Contractor be required to carry general liability insurance and at least 1 other type of commercial insurance to protect his/her business while performing the services?",
            BestResponse: "No",
            Score: "2",
            value: "bm",
        },
        {
            question:
                "Does the Contractor have a business website or social media site which describes the business services the Contractor offers, and, if yes, will the Contractor be providing those type of services to the Service Recipient?",
            BestResponse: "No",
            Score: "2",
            value: "bm",
        },
        {
            question:
                "Will the Contractor submit invoices for the services performed as a pre-requisite to get paid, i.e., if the Contractor does not submit an invoice, then the Contractor does not get paid for the services he/she performs?",
            BestResponse: "No",
            Score: "2",
            value: "bm",
        },
    ];

    const tableData = [
        {
            id: "21",
            name: "Yes",
            value: "2.5",
        },
        {
            id: "22",
            name: "Yes",
            value: "2",
        },
        {
            id: "23",
            name: "No",
            value: "1",
        },
    ];

    const [open, setOpen] = useState(false);

    const [rowsData, setRowsData] = useState([tableData]);

    const addTableRows = () => {
        const rowsInput = {
            id: "23",
            name: "No",
            value: "1",
        };

        setRowsData([...rowsData, rowsInput]);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const collarList = [
        {
            name: "Scoring",
            headerClass: "headerScoring",
            accessibleKey: "",
            rowClass: "tableScoring",
            headerAlign: "center",
            rowAlign: "center",
        },
        {
            name: "Weightage",
            headerClass: "headerWeightage",
            accessibleKey: "",
            rowClass: "tableWeightage",
            headerAlign: "center",
            rowAlign: "center",
        },
    ];

    return (
        <Fragment>
            <Box sx={{ px: { md: 4, xs: 2 } }}>
                <Grid container className="questionTableBox">
                    <TableComponent
                        headerList={headerList}
                        // serialNo={{
                        //     name: "S.No.",
                        //     headerClass: "questionTableHeading",
                        //     headerAlign: "center",
                        //     rowAlign: "center",
                        //     rowClass: "seriesCell",
                        // }}
                        extraHeaderRight={
                            <TableCell align="center" className="tableHeading">
                                Edit
                            </TableCell>
                        }
                    >
                        {CollarColorList?.length &&
                            CollarColorList.map((row, index) => (
                                <TableRow key={row.name}>
                                    <TableCell
                                        align="center"
                                        className="questionTableHeadingRow"
                                    >
                                        {index + 1}
                                    </TableCell>
                                    {headerList.map((obj) => (
                                        <TableCell
                                            component="th"
                                            key={obj?.name}
                                            align={obj?.rowAlign}
                                            className={obj?.rowClass}
                                            onClick={() =>
                                                obj.handleButton(
                                                    row,
                                                    obj?.buttonType
                                                ) || null
                                            }
                                        >
                                            {row[obj?.accessibleKey]}
                                        </TableCell>
                                    ))}
                                    <TableCell
                                        align="center"
                                        className="questionTableHeadingRow"
                                    >
                                        <TooltipComponent
                                            title={"Edit Question"}
                                        >
                                            <ButtonComponent
                                                title={<EditOutlinedIcon />}
                                                className="editColor"
                                                onClick={handleClickOpen}
                                            />
                                            {/* <Button
                                                className="editColor"
                                                onClick={handleClickOpen}
                                            >
                                                <EditOutlinedIcon />
                                            </Button> */}
                                        </TooltipComponent>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableComponent>
                </Grid>
            </Box>
            <Box className="dialogStyles">
                <DialogComponent
                    className="editDialog"
                    fullWidth
                    open={open}
                    maxWidth="md"
                    handleClose={handleClose}
                    title={` Edit Question`}
                    content={
                        <>
                            <Grid container>
                                <TableComponent
                                    headerList={collarList}
                                    extraHeaderLeft={
                                        <TableCell className="headerCollar">
                                            Collar
                                        </TableCell>
                                    }
                                    extraHeaderRight={
                                        <TableCell
                                            className="colorAction"
                                            align="center"
                                        >
                                            Action
                                        </TableCell>
                                    }
                                >
                                    {rowsData?.length &&
                                        rowsData.map((row, index) => (
                                            <TableRow key={row.name}>
                                                <TableCell className="tableCollar">
                                                    <FormControl
                                                        sx={{
                                                            m: 1,
                                                            minWidth: 120,
                                                        }}
                                                    >
                                                        <DropDownComponent
                                                            value={age}
                                                            onChange={
                                                                handleChange
                                                            }
                                                            className="selectDrop"
                                                            label="Select Collar"
                                                            children={[
                                                                "Blue",
                                                                "Pink",
                                                                "Dark Pink",
                                                            ].map((obj) => (
                                                                <MenuItem
                                                                    value={obj}
                                                                    key={obj}
                                                                    className="dropDownText"
                                                                >
                                                                    <img
                                                                        src={
                                                                            obj
                                                                        }
                                                                        alt="vector"
                                                                    ></img>
                                                                </MenuItem>
                                                            ))}
                                                        />
                                                    </FormControl>
                                                </TableCell>

                                                {collarList.map((obj) => (
                                                    <TableCell
                                                        component="th"
                                                        key={obj?.name}
                                                        align={obj?.rowAlign}
                                                        className={
                                                            obj?.rowClass
                                                                ? obj?.rowClass
                                                                : "typo5"
                                                        }
                                                        onClick={() =>
                                                            obj.handleButton(
                                                                row,
                                                                obj?.buttonType
                                                            ) || null
                                                        }
                                                    >
                                                        {
                                                            row[
                                                                obj
                                                                    ?.accessibleKey
                                                            ]
                                                        }

                                                        <input
                                                            type="number"
                                                            className="scoringInput"
                                                        />
                                                    </TableCell>
                                                ))}

                                                <TableCell align="center">
                                                    <TooltipComponent
                                                        title={"Edit Question"}
                                                    >
                                                        <IconButton
                                                            sx={{
                                                                color: "#3B3B3B",
                                                            }}
                                                            onClick={
                                                                handleClickOpen
                                                            }
                                                        >
                                                            <EditOutlinedIcon />
                                                        </IconButton>
                                                    </TooltipComponent>
                                                    <TooltipComponent>
                                                        <IconButton
                                                            sx={{
                                                                color: "#3B3B3B",
                                                                paddingLeft:
                                                                    "5px ",
                                                            }}
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </TooltipComponent>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </TableComponent>
                            </Grid>
                        </>
                    }
                    contentClass={"tableDialog"}
                    actionClass={"addIconStyle"}
                    action={
                        <Grid item md={12} lg={1}>
                            <Fab color="primary" aria-label="add">
                                <AddIcon onClick={addTableRows} />
                            </Fab>
                        </Grid>
                    }
                />
            </Box>
            <Grid
                item
                lg={11}
                md={5}
                sm={3}
                xs={4}
                mt={3}
                ml={8}
                xl={1}
                display="flex"
                justifyContent="flex-end"
                gap={"8px"}
            >
                <ButtonComponent title={"Cancel"} variant="outlined" />

                <ButtonComponent
                    sx={{ marginLeft: "20px" }}
                    title={
                        userLoggedIn?.language_data?.comman?.save_btn || "Save"
                    }
                    type="submit"
                    variant="contained"
                />
            </Grid>
        </Fragment>
    );
};
const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,
    };
};
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getUserById,
        },
        dispatch
    );
};
export default connect(mapStateToProps, mapDispatchToProps)(CollarColor);
