import React from "react";
import Navbar from "../../common/Header/Navbar";
import { Box, Grid, Paper, Typography } from "@mui/material";
import "../../../assets/styles/Container/Home.scss";
import ButtonComponent from "../../components/ButtonComponent";
import CorouselMain from "../../components/Carousel/CorouselMain";
import GetDemoContactSection from "../../common/GetDemoContactSection";
import UnloginFooter from "../../common/UnloginFooter";
import {
    projectFeatureImage,
    homePageMeetingImages,
} from "../../utils/CommonData";

function Home(props) {
    return (
        <>
            <Navbar />
            <Grid
                container
                justifyContent={"space-between"}
                alignItems={"center"}
                sx={{ px: { md: 7, xs: 1 }, pt: "110px", mb: "40px" }}
            >
                <Grid item xs={12} md={6} lg={6} xl={6} paddingRight={"50px"}>
                    <Typography className="mainHeading">
                        Work with Elevance compliance partner
                    </Typography>
                    <Typography className="mainSubHeading">
                        {`Elevance is a behind-the-scenes, B2B compliance partner those powers complex employment law compliance functions. Elevance's proprietary process flows, algorithms, and databases turn the difficult into the easy for our customers.`}
                    </Typography>
                    <Grid
                        item
                        gap={3}
                        display={"flex"}
                        sx={{ paddingTop: "25px" }}
                    >
                        <Grid item>
                            <ButtonComponent title={"Get a Demo"} />
                        </Grid>
                        <Grid item>
                            <ButtonComponent
                                title={"Contact Us"}
                                className="button_outline"
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={6}
                    lg={6}
                    xl={6}
                    justifyContent={"center"}
                    sx={{ padding: { xs: "20px", md: "0px" } }}
                >
                    <Paper
                        className="homeCorouselPaper"
                        sx={{
                            width: { xs: "auto", md: "593px" },
                        }}
                    >
                        <CorouselMain
                            images={homePageMeetingImages}
                            showThumbs={false}
                            showArrows={false}
                            showStatus={false}
                            autoPlay={true}
                            infiniteLoop={true}
                            interval={3}
                        />
                    </Paper>
                </Grid>
            </Grid>
            <Box
                className="lightSecondaryDetails"
                sx={{ px: { md: 7, xs: 1 }, py: "30px" }}
            >
                <Grid container display={"flex"}>
                    <Grid item xs={12}>
                        <Typography className="mainHeading">
                            All-in-one Solution
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} xl={6}>
                        <Typography className="lightSecContain">
                            {`  Our solutions can be used by customers with any level of compliance experience and technological competence.`}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} xl={6}>
                        <Typography className="lightSecContain">
                            {`We skillfully built our platform for all types of users, ranging from the least to the most experienced, Corporate Professionals, Lawyers, Compliance Personnel, Procurement Professionals, Auditors, HR Personnel, and Others.`}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className="lightSecContain">
                            {`All of our solutions are designed to automatically update and stay current with the law. No more worrying about conflicting case law decisions, changing regulatory guidance, ambiguous rules, and the shifting political winds.`}
                        </Typography>
                        <Typography className="lightSecHightlightText">
                            {` We take care of it for you. We are one of a kind.`}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            <Box
                className="darkSecondary"
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                sx={{ padding: { xs: "20px" } }}
            >
                <Typography className="darkSecoWhiteText">
                    {`Elevance’s solutions comply with legal and regulatory requirements in all 50 U.S. states and the District of Columbia, and in all provinces in Canada.`}
                </Typography>
            </Box>
            <Box
                className="lightSecondaryDetails"
                sx={{ px: { md: 7, xs: 1 }, py: "30px" }}
            >
                <Grid
                    container
                    justifyContent={"space-between"}
                    alignItems={"center"}
                >
                    <Grid
                        item
                        xs={12}
                        md={6}
                        lg={6}
                        xl={6}
                        sx={{ pr: { md: "50px", xs: 0 } }}
                    >
                        <Typography className="mainHeading">
                            Out-of-box Solution
                        </Typography>
                        <Typography className="mainSubHeading">
                            {`Elevance offers “out-of-the-box” solutions which range from the simple to the complex, including a fully customizable solution where our customer designs the parameters and depth of its own compliance program!  The customizable solution is a first-of-its-kind; not only is the process and substance self-configurable, a compliance navigator also tells you whether undue risk is being incurred should you self-configure outside of certain compliance parameters.  It is like having a compliance or legal professional sitting next to you, guiding you along the way, but without having to pay their expensive rates.`}
                        </Typography>
                        <Typography className="lightSecHightlightText">
                            {`All our solutions are white labeled, and so much more.`}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        lg={6}
                        xl={6}
                        justifyContent={"center"}
                        sx={{ padding: { xs: "20px", md: "0px" } }}
                    >
                        <Paper className="homeCorouselPaper">
                            <CorouselMain
                                images={projectFeatureImage}
                                showThumbs={false}
                                showArrows={false}
                                showStatus={false}
                                autoPlay={true}
                            />
                        </Paper>
                    </Grid>
                </Grid>
            </Box>
            <GetDemoContactSection />
            <UnloginFooter />
        </>
    );
}

export default Home;
