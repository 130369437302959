import React, { Fragment, useEffect } from "react";
import DropDownComponent from "../../components/DropDown/DropDownComponent";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import TableComponent from "../../components/Table/TableComponent";
import TextField from "../../components/TextField/TextFieldComponent";
import Checkbox from "../../components/Checkbox/CheckboxComponent";
import FormControlLabel from "@mui/material/FormControlLabel";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { getMqsResponses } from "../../store/Action/MqsAction";
import { MenuItem } from "@mui/material";
import {Translate } from 'react-auto-translate';
function QuestionResponse(props) {
    const {
        getMqsResponses,
        mqsResoponseDropdown,
        state,
        setState,
        mode,
        value,
    } = props;
    useEffect(() => {
        getMqsResponses();
    }, [getMqsResponses]);
    function handleViewButton(data) {
        setState((prevState) => ({
            ...prevState,
            options_id: data?.id,
        }));
        getResponseData(data?.id);
    }
    const getResponseData = (id) => {
        const newData = mqsResoponseDropdown?.find((obj) => obj.id === id);

        const valueNameArr = newData?.options.map((item) => {
            return {
                name: item,
                value: "",
                isDisabled: true,
            };
        });
        setState((prevState) => ({
            ...prevState,
            options_id: id,
            responses: [...valueNameArr],
        }));
    };
    const headerList = [
        {
            name: "Option",
            headerClass: "headerOptionMqs",
            accessibleKey: "options",
            rowClass: "tableOptionMqs",
            headerAlign: "center",
            rowAlign: "start",
        },
    ];
    const handleChangeValue = (name, value) => {
        let responseValue = state?.responses.map((items) => {
            if (items?.name === name) {
                items.value = value;
            }
            return items;
        });
        setState((prevState) => ({
            ...prevState,
            ...prevState,
            responses: responseValue,
        }));
    };
    return (
        <Fragment>
        <Grid container spacing={2}>
            <Grid item md={5} xs={12} ml={3} className="resposeContainer">
                <Typography className="selectText">
                    <Translate>Choose Response to Answer</Translate>
                </Typography>
                <Grid mt={2}>
                    <DropDownComponent
                        fullWidth={true}
                        name={"options_id"}
                        onChange={(event) => {
                            handleViewButton(event);
                        }}
                        value={state?.options_id}
                        children={mqsResoponseDropdown?.map((response) => (
                            <MenuItem
                                className="mqsItem"
                                onClick={() => handleViewButton(response)}
                            >
                                <Grid item md={11}>
                                    <Typography className="mqsItem">
                                        <Translate>Response{response.id}</Translate>
                                    </Typography>
                                </Grid>
                                <Grid item md={1} ml={20}>
                                    <IconButton
                                        onClick={() =>
                                            handleViewButton(response)
                                        }
                                    >
                                        <VisibilityIcon />
                                    </IconButton>
                                </Grid>
                            </MenuItem>
                        ))}
                    />
                </Grid>
            </Grid>
            {state?.options_id !== "" && (
                <Grid item md={6} xs={12} mt={22} ml={3}>
                    <Typography className="selectText">
                        <Translate>Response{state.options_id}</Translate>
                    </Typography>
                    <Grid mt={2}>
                        <TableComponent
                            tableClass={"tableborder"}
                            headerList={headerList}
                            extraHeaderRight={
                                <TableCell
                                    className="colorScoreMqs"
                                    align="end "
                                >
                                    <Translate>Score</Translate>
                                </TableCell>
                            }
                        >
                            {state?.responses?.map((row) => (
                                <TableRow key={row.name}>
                                    <TableCell
                                        component="th"
                                        align="start"
                                        className="tableOptionMqs"
                                    >
                                        {row.name}
                                    </TableCell>
                                    <TableCell className="rowColor">
                                        <TextField
                                            variant="outlined"
                                            onChange={(event) =>
                                                handleChangeValue(
                                                    row.name,
                                                    event.target.value
                                                )
                                            }
                                            value={state?.responses?.value}
                                            type={"Number"}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableComponent>
                    </Grid>
                </Grid>
            )}
        </Grid>
    </Fragment>
    
    );
}
const mapStateToProps = (state) => {
    return {
        mqsResoponseDropdown: state?.mqs?.mqsResoponseDropdown,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getMqsResponses,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionResponse);
