import { API_CONSTANTS } from "../../config/ApiConstants";
import {
    getApiCall,
    postApiCall,
    putApiCall,
    deleteApiCall,
} from "../../utils/Action";
// import { toast } from "react-hot-toast";

export const GET_MQS_CATEGORY = "GET_MQS_CATEGORY";
export const STORE_CATEGORY = "STORE_CATEGORY";
export const GET_TEST_QUESTION = "GET_TEST_QUESTION";
export const GET_MQS_DROPDOWN = "GET_MQS_DROPDOWN";
export const ADD_STORE_QUESTION = "ADD_STORE_QUESTION";
export const UPDATE_QUESTION = "UPDATE_QUESTION";
export const GET_MQS_RESPONSES = "GET_MQS_RESPONSES";
export const SET_SELECTED_QUESTION = "SET_SELECTED_QUESTION";
export const DELETE_QUESTION = "DELETE_QUESTION";
export const MQS_DEFINATION = "MQS_DEFINATION";
export const MQS_ADD_QUESTION = "MQS_ADD_QUESTION";
export const GET_QUESTION_LIST = "GET_QUESTION_LIST";
export const GET_QUESTION_DETAIL = "GET_QUESTION_DETAIL";
export const SLIED_TO_ANOTHER_QUESTION = "SLIED_TO_ANOTHER_QUESTION";
export const GET_QUESTIONS_IN_BALANCING_CATEGORY =
    "GET_QUESTIONS_IN_BALANCING_CATEGORY";

export const GET_CONFLICT_QUESTIONS = "GET_CONFLICT_QUESTIONS";
export const GET_VIEW_QUESTION_RESPONSES = "GET_VIEW_QUESTION_RESPONSES";
export const UPDATE_CONFLICT_RESPONSES = "UPDATE_CONFLICT_RESPONSES";
export const getMqsQuestion = () => {
    return async (dispatch) => {
        try {
            const response = await getApiCall(`mqs-categories`);
            dispatch({
                type: GET_MQS_CATEGORY,
                payload: response?.data?.result || [],
            });
        } catch (error) {}
    };
};
export const storeCategory = (state) => {
    return async (dispatch) => {
        try {
            const response = await postApiCall(
                API_CONSTANTS.STORE_CATEGORY_MQS,
                state
            );
            if (response.status === 201) {
                return dispatch({
                    type: STORE_CATEGORY,
                    payload: response?.data?.result,
                });
            }
        } catch (error) {
            throw error;
        }
    };
};
export const getTestQuestion = (category_id) => {
    return async (dispatch) => {
        try {
            const response = await getApiCall(`mqs?category_id=${category_id}`);
            dispatch({
                type: GET_TEST_QUESTION,
                payload: response?.data?.result || [],
            });
        } catch (error) {}
    };
};
export const getMqsDropdown = () => {
    return async (dispatch) => {
        try {
            const response = await getApiCall("common/get-dropdowns");
            dispatch({
                type: GET_MQS_DROPDOWN,
                payload: response?.data?.result || [],
            });
        } catch (error) {}
    };
};
export const storeQuestion = (state) => {
    return async (dispatch) => {
        try {
            const response = await postApiCall(
                API_CONSTANTS.STORE_QUESTION,
                state
            );

            if (response.status === 201) {
                return dispatch({
                    type: ADD_STORE_QUESTION,
                    payload: response?.data?.result,
                });
            }
        } catch (error) {
            throw error;
        }
    };
};
export const setSelectedQuestion = (data) => {
    return async (dispatch) => {
        dispatch({ type: SET_SELECTED_QUESTION, payload: data });
    };
};
export const updateQuestion = (data) => {
    return async (dispatch) => {
        try {
            const response = await putApiCall(
                API_CONSTANTS.STORE_QUESTION + `/${data?.id}`,
                data
            );

            if (response.status === 201) {
                return dispatch({
                    type: UPDATE_QUESTION,
                    payload: response?.data?.result,
                });
            }
        } catch (error) {
            throw error;
        }
    };
};

export const DeleteQuestionId = (data) => {
    return async (dispatch) => {
        try {
            const response = await deleteApiCall(
                API_CONSTANTS.STORE_QUESTION + `/${data.id}`
            );
            if (response?.status === 200) {
                dispatch({
                    type: DELETE_QUESTION,
                    payload: data?.id,
                });
            }
        } catch (error) {
            throw error;
        }
    };
};

export const getMqsResponses = () => {
    return async (dispatch) => {
        try {
            const response = await getApiCall("mqs-responses");
            dispatch({
                type: GET_MQS_RESPONSES,
                payload: response?.data?.result || [],
            });
        } catch (error) {
            throw error;
        }
    };
};

export const mqsDefinationAction = () => {
    return async (dispatch) => {
        try {
            const response = await getApiCall(API_CONSTANTS.MQS_DEFINATIONS);
            return dispatch({
                type: MQS_DEFINATION,
                payload: response?.data?.result,
            });
        } catch (error) {
            throw error;
        }
    };
};

export const mqsAddQuestionAction = (data) => {
    return async (dispatch) => {
        try {
            const response = await postApiCall(
                API_CONSTANTS.MQS_QUESTION,
                data
            );
            if (response?.data?.status === 200) {
                // toast.success(response?.data?.message);
                return true;
            } else {
                // toast.error(response?.data?.message);
                return false;
            }
        } catch (error) {
            throw error;
        }
    };
};

export const getQuestionList = (data = null) => {
    return async (dispatch) => {
        try {
            const response = await getApiCall(API_CONSTANTS.MQS_QUESTION, data);
            if (response?.data?.status === 200) {
                return dispatch({
                    type: GET_QUESTION_LIST,
                    payload: response?.data?.result,
                });
            } else {
                //  toast.error(response?.data?.message);
            }
        } catch (error) {
            throw error;
        }
    };
};

export const getQuestionDetail = (id) => {
    return async (dispatch) => {
        try {
            const response = await getApiCall(
                API_CONSTANTS.MQS_QUESTION + `/${id}`
            );
            if (response?.data?.status === 200) {
                return dispatch({
                    type: GET_QUESTION_DETAIL,
                    payload: response?.data?.result,
                });
            } else {
                // toast.error(response?.data?.message);
            }
        } catch (error) {
            throw error;
        }
    };
};

export const updateQuestionDetail = (id, data) => {
    return async (dispatch) => {
        try {
            const response = await putApiCall(
                API_CONSTANTS.MQS_QUESTION + `/${id}`,
                data
            );
            if (response?.data?.status === 200) {
                // toast.success(response?.data?.message);
                return true;
            } else {
                // toast.error(response?.data?.message);
                return false;
            }
        } catch (error) {
            throw error;
        }
    };
};

export const uploadQuestions = (data, optionalData = null) => {
    return async (dispatch) => {
        try {
            const response = await postApiCall(
                API_CONSTANTS.IMPORT_MQS_QUESTION,
                data
            );
            if (response?.data?.status === 200) {
                dispatch(getQuestionList(optionalData));
                return true;
            } else {
                // toast.error(response?.data?.message);
                return false;
            }
        } catch (error) {
            throw error;
        }
    };
};

export const getQuestionListByOptions = (optionId) => {
    return async (dispatch) => {
        try {
            const response = await getApiCall(
                API_CONSTANTS.SETUP_QUESTIONS + "/" + optionId
            );
            if (response?.data?.status === 200) {
                return dispatch({
                    type: GET_QUESTION_LIST,
                    payload: response?.data?.result,
                });
            } else {
                // toast.error(response?.data?.message);
            }
        } catch (error) {
            throw error;
        }
    };
};

export const setSliedQuestionView = (id, buttonName) => {
    return async (dispatch, getState) => {
        try {
            const currentState = getState();
            const questionList = currentState?.mqs?.testQuestion;

            const index = questionList.findIndex((obj) => obj === id);
            if (buttonName === "Next") {
                await dispatch(getQuestionDetail(questionList[index + 1]));
            } else if (buttonName === "Previous") {
                await dispatch(getQuestionDetail(questionList[index - 1]));
            }
        } catch (error) {
            throw error;
        }
    };
};
export const getViewQuestionsInBalancing = (data) => {
    return async (dispatch) => {
        try {
            const response = await getApiCall(
                API_CONSTANTS.VIEW_QUESTIONS_BALANCING_CATEGORY,
                data
            );
            if (response?.status == 200) {
                return dispatch({
                    type: GET_QUESTIONS_IN_BALANCING_CATEGORY,
                    payload: response?.data?.result,
                });
            } else {
                // toast.error(response?.message);
            }
        } catch (error) {
            throw error;
        }
    };
};

export const submitTest = (data, testStartTime) => {
    return async (dispatch) => {
        try {
            const response = await postApiCall(API_CONSTANTS.SUBMIT_TEST, data);
        } catch (error) {
            throw error;
        }
    };
};

export const getConflictResponses = (data) => {
    return async (dispatch) => {
        try {
            const response = await getApiCall(
                API_CONSTANTS.GET_CONFLICT_RESPONSES,
                data
            );
            if (response?.status == 200) {
                return dispatch({
                    type: GET_CONFLICT_QUESTIONS,
                    payload: response?.data?.result,
                });
            } else {
                // toast.error(response?.message);
            }
        } catch (error) {
            throw error;
        }
    };
};

export const getViewQuestionResponses = (data) => {
    return async (dispatch) => {
        try {
            const response = await getApiCall(
                API_CONSTANTS.GET_QUESTION_RESPONSES,
                data
            );
            if (response?.status == 200) {
                return dispatch({
                    type: GET_VIEW_QUESTION_RESPONSES,
                    payload: response?.data?.result,
                });
            } else {
                // toast.error(response?.message);
            }
        } catch (error) {
            throw error;
        }
    };
};

export const updateConflict = (data) => {
    return async (dispatch) => {
        try {
            const response = await postApiCall(
                API_CONSTANTS.UPDATE_CONFILCT,
                data
            );
            if (response?.status == 200) {
                return dispatch({
                    type: UPDATE_CONFLICT_RESPONSES,
                    payload: response?.data?.result,
                });
            } else {
                // toast.error(response?.message);
            }
        } catch (error) {
            throw error;
        }
    };
};
