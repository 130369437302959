import React, { useState } from "react";
import Tooltip from "../../components/ToolTip/TooltipComponent";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import EventTracking from "../EventTracking/EventTracking";
import "../../../assets/styles/Container/EventTracking.scss";
import ButtonComponent from "../../components/ButtonComponent";
import DialogComponent from "../../components/DialogsBox/DialogComponent";

export default function DialogEventTracking() {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <ButtonComponent
                id="dialogEventTrackingButton"
                variant="text"
                sx={{ color: "#3B3B3B" }}
                title={
                    <Tooltip
                        arrow={true}
                        title="Event Tracking"
                        placement="top"
                        sx={{
                            left: 10,
                            top: 5,
                        }}
                    >
                        <VisibilityOutlinedIcon />
                    </Tooltip>
                }
                onClick={handleClickOpen}
            />
            <DialogComponent
                id="dialogEventTrackingdialog"
                className="eventDialog"
                open={open}
                ariaLabelledby="EventTracking-dialog-title"
                handleClose={handleClose}
                title={`Event Tracking`}
                content={<EventTracking />}
            />
        </div>
    );
}
