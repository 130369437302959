import * as Actions from "../Action/ScoringConfigurationAction";
const initialState = {
    circuitData: [],
    stateData: [],
    categoryDropdown: null,
};
const ScoringConfigurationReducer = (state = initialState, action) => {
    switch (action?.type) {
        case Actions.GET_SCORING_CATEGORY_DROPDOWN: {
            return {
                ...state,
                categoryDropdown: action.payload,
            };
        }
        case Actions.GET_CIRCUIT_DATA: {
            return {
                ...state,
                circuitData: action.payload,
            };
        }
        case Actions.GET_STATE_DATA: {
            return {
                ...state,
                stateData: action.payload,
            };
        }
        default: {
            return state;
        }
    }
};
export default ScoringConfigurationReducer;
