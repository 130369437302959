import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import EmailHistory from "./EmailHistory";
import EmailSections from "./EmailSection";
import ResendEmailModel from "./ResendEmailModel";
import ImportDialog from "./ImportDialog";
import Typography from "@mui/material/Typography";
import Navbar from "../../../../../common/Header/Navbar";
import LeftEmailDeleteSection from "./LeftEmailDeleteSection";
import CommonDeleteDialog from "../../../../../components/DialogsBox/CommonDeleteDialog";
import SuccessfullModel from "../../../../../components/DialogsBox/SuccessfullModel";
import ImpExpoHeader from "./ImpExpoHeader";
import {
    deleteContractorEmailTemplate,
    getContractorList,
    getContractorEmailHistoryList,
} from "../../../../../store/Action/ContractorEmailAction";
import { getUserById } from "../../../../../store/Action/UserAction";
import "../../../../../../assets/styles/Common/_CommonStyle.scss";
import "../../../../../../assets/styles/Container/ContractorEmail.scss";
import { toast } from "react-hot-toast";
import LoadingComponent from "../../../../../components/Loading/LoadingComponent";
import Footer from "../../../../../common/Footer/Footer";

const EmailWorkflow = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    let params = useParams();
    const {
        selectedUser,
        selectedClient,
        auth,
        deleteContractorEmailTemplate,
        getContractorList,
        getContractorEmailHistoryList,
        getUserById,
        templateList,
        userLoggedIn,
    } = props;

    const [state, setState] = useState({
        openDelete: false,
        openResend: false,
        openSuccess: false,
        content: "",
        emailId: null,
        deleteId: null,
        deletedData: {},
        resendId: null,
        tosterContent: {},
        openImport: false,
        contarctorSate: "",
        isDeleteCheckAll: false,
        isDeleteCheck: [],
        isLoading: false,
    });

    const {
        openDelete,
        openResend,
        openSuccess,
        content,
        emailId,
        deleteId,
        deletedData,
        resendId,
        openImport,
        contarctorSate,
        isLoading,
    } = state;

    const handleClose = () => {
        setState((prevState) => ({ ...prevState, openDelete: false }));
    };

    const commonData = {
        contractorName: selectedUser
            ? selectedUser?.display_name
            : location?.state?.row?.display_name,
        guid: selectedClient?.guid || params?.customerId,
        contractor_id: selectedUser
            ? selectedUser?.id
            : location?.state?.row?.id,
        client_id: selectedClient
            ? selectedClient?.id
            : selectedUser?.client_id,
        state: contarctorSate,
    };
    useEffect(() => {
        getUserById(params?.id);
        fetchStateName();
        async function apiCall() {
            setState((prevState) => ({ ...prevState, isLoading: true }));
            await getContractorList(commonData);
            await getContractorEmailHistoryList(commonData);
            setState((prevState) => ({ ...prevState, isLoading: false }));
        }
        if (selectedUser && templateList === null) {
            apiCall();
        }
    }, [
        getContractorList,
        getContractorEmailHistoryList,
        getUserById,
        selectedUser,
    ]);
    const deleteTemplate = async () => {
        let data;
        setState((prevState) => ({
            ...prevState,
            openDelete: false,
            isLoading: true,
        }));
        if (deleteId) data = { template_id: [deleteId] };
        else data = { template_id: deletedData.id };
        const res = await deleteContractorEmailTemplate(data);
        if (res?.payload?.response?.status === 200) {
            toast.success(res?.payload?.response?.data?.message);
        } else {
            toast.error(res?.payload?.response?.data?.message);
        }
        setState((prevState) => ({ ...prevState, isLoading: false }));
    };

    const fetchStateName = () => {
        const addressState = auth?.commonDropdown?.states?.find(
            (item) => item.id === Number(selectedUser?.address?.state)
        );
        setState((prevState) => ({
            ...prevState,
            contarctorSate: addressState?.label,
        }));
    };

    const handleCloseDialog = () => {
        setState((prevState) => ({ ...prevState, openSuccess: false }));
    };

    return (
        <>
            <ImportDialog
                openImport={openImport}
                contractor_id={commonData?.contractor_id}
                setState={setState}
                state={state}
            />
            <CommonDeleteDialog
                open={openDelete}
                title={"Delete Email"}
                content={
                    <>
                        Are you sure, you want to remove the "
                        <span
                            style={{
                                fontWeight: 900,
                                textTransform: "capitalize",
                            }}
                        >
                            {deletedData?.templateName}
                        </span>
                        " from the Emails?
                    </>
                }
                cancelButtonTitle="Cancel"
                cancelButtonAction={handleClose}
                submitButtonTitle="Delete"
                submitButtonAction={deleteTemplate}
            />
            <ResendEmailModel
                commonData={commonData}
                open={openResend}
                resendId={resendId}
                setState={setState}
                state={state}
            />
            <SuccessfullModel
                open={openSuccess}
                content={content}
                handleClose={handleCloseDialog}
            />
            <Navbar />
            {isLoading || templateList === null ? (
                <LoadingComponent sx={{ height: "600px" }} />
            ) : (
                <Box sx={{ pt: "60px" }}>
                    <ImpExpoHeader setState={setState} />
                    <Grid container px={5} my={3}>
                        <Grid item xs={12} xl={3} md={3}>
                            <Typography
                                className="backButtonText"
                                onClick={() => navigate(-1)}
                            >
                                Previous
                            </Typography>
                        </Grid>
                        <Grid container justifyContent={"space-between"}>
                            <Grid item>
                                <Typography className="tempText">
                                    {`Temporary ID: ${commonData?.contractor_id}`}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography className="tempText">
                                    {`Contractor Name: ${commonData?.contractorName}`}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography className="tempText">
                                    {userLoggedIn?.language_data
                                        ?.complianceworkflow?.state || "State"}
                                    : {contarctorSate}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} xl={3} md={3}>
                            <LeftEmailDeleteSection
                                commonData={commonData}
                                setState={setState}
                                state={state}
                            />
                        </Grid>
                        <Grid
                            px={2}
                            sx={{ my: { xs: 2, md: 0 } }}
                            item
                            xs={12}
                            xl={7}
                            md={7}
                        >
                            <EmailSections
                                commonData={commonData}
                                emailId={emailId}
                                setState={setState}
                                state={state}
                            />
                        </Grid>
                        <Grid item xs={12} xl={2} md={2}>
                            <EmailHistory
                                commonData={commonData}
                                setState={setState}
                                state={state}
                            />
                        </Grid>
                    </Grid>
                </Box>
            )}
            <Footer />
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        userLoggedIn: state?.auth?.userLoggedIn,
        selectedClient: state?.customer?.selectedCustomer,
        selectedUser: state?.user?.selectedUser,
        auth: state?.auth,
        templateList: state.contractor.emailTemplateList,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            deleteContractorEmailTemplate,
            getContractorList,
            getContractorEmailHistoryList,
            getUserById,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailWorkflow);
