import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextFieldComponent from "../../../components/TextField/TextFieldComponent";

const Heading = (props) => {
    const { state, setState, data, children, handleChange, isEdit } = props;

    const handlerIsEdit = () => {
        if (isEdit) {
            setState((prevState) => ({ ...prevState, editLabel: true }));
        }
    };

    const onBlur = () => {
        setState((prevState) => ({ ...prevState, editLabel: false }));
    };

    return (
        <Grid container>
            <Grid
                item
                xs={12}
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <TextFieldComponent
                    sx={{
                        display:
                            state?.selectedEle?.selected && state?.editLabel
                                ? "block"
                                : "none",
                        color: state?.fontColor,
                        "& .MuiInputBase-input": {
                            color: state?.fontColor,
                        },
                    }}
                    id="headingLabel"
                    onBlur={onBlur}
                    onChange={handleChange}
                    value={data.label}
                    className="labelEleTextField"
                    autoFocus={true}
                    inputProps={{ maxLength: 30 }}
                />
                <Typography
                    display={
                        state?.selectedEle?.selected && state?.editLabel
                            ? "none"
                            : "block"
                    }
                    className="labelEle"
                    sx={{ color: state?.fontColor }}
                    onClick={handlerIsEdit}
                >
                    {data?.label}
                </Typography>
                {children}
            </Grid>
        </Grid>
    );
};

export default Heading;
