import { Tooltip } from "@mui/material";
import React from "react";
import "./ToolTip.scss";

function TooltipComponent(props) {
    const { arrow, placement, title, sx, followCursor, className } = props;
    return (
        <Tooltip
            arrow={arrow}
            placement={placement}
            title={title}
            sx={sx}
            followCursor={followCursor}
            className={className}
        >
            {props.children}
        </Tooltip>
    );
}

export default TooltipComponent;
