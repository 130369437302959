import * as Actions from '../Action/AssessmentPartiesAction'

const initialState = {
    assessmentPartiesList:[]
};

const AssessmentPartiesReducer = (state = initialState, action) => {
	switch (action?.type) {
		case Actions.GET_ASSESSMENT_PARTIES_ACTION: {
			return {
				...state,
				assessmentPartiesList:action.payload
			};
		}
		case Actions.ADD_ASSESSMENT_PARTIES_ACTION: {
			return {
				...state,
				assessmentPartiesList:action.payload
			};
		}
		case Actions.SINGLE_UPDATE_ASSESSMENT_PARTIES_ACTION: {
			return {
				...state,
				assessmentPartiesList:action.payload
			};
		}
		case Actions.BULK_UPDATE_ASSESSMENT_PARTIES_ACTION: {
			return {
				...state,
				assessmentPartiesList:action.payload
			};
		}	
		case Actions.DELETE_ASSESSMENT_PARTIES_ACTION: {
			return {
				...state,
				assessmentPartiesList:action.payload
			};	
		}
		default: {
			return state;
		}
	}
};

export default AssessmentPartiesReducer; 