import React from "react";
import Box from "@mui/material/Box";
import ButtonComponent from "../../components/ButtonComponent";
import { ROUTES } from "../../router/RouteList";

function SignUpNavbar(props) {
    const { setState, navigate } = props;
    const pathName = window.location.pathname.split("/");
    const handleSignUp = () => navigate(ROUTES.SIGNUP);
    const handleLogIn = () => navigate(ROUTES.LOGIN);

    return (
        <Box
            mx={3}
            sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
                justifyContent: "flex-end",
            }}
            className="signUpNavbar"
        >
            {/* <div>
                <ButtonComponent
                    className="headerButton"
                    onClick={() => navigate(ROUTES.HOME)}
                    title={"Home"}
                />
                <ButtonComponent
                    className="headerButton"
                    onClick={() => navigate(ROUTES.SERVICES)}
                    title={"Services"}
                />
                 <ButtonComponent
                    className="headerButton"
                    title={"Solutions"}
                />
                <ButtonComponent
                    className="headerButton"
                    title={"Value Prop"}
                />
                <ButtonComponent
                    className="headerButton"
                    onClick={() => navigate(ROUTES.PRICING)}
                    title={"Pricing"}
                />
                <ButtonComponent
                    className="headerButton"
                    onClick={() => navigate(ROUTES.ABOUT_US)}
                    title={"About Us"}
                />
            </div> */}
            {pathName[1] !== "reset-password" ? (
                <>
                    {pathName[1] !== "login" ? (
                        <ButtonComponent
                            size="small"
                            sx={{ height: "36px", margin: "20px 0px 0px 20px" }}
                            onClick={() => handleLogIn()}
                            variant="outlined"
                            title={"Login"}
                        />
                    ) : null}
                    {pathName[1] !== "sign-up" ? (
                        <ButtonComponent
                            size="small"
                            sx={{ height: "36px", margin: "20px 0px 0px 20px" }}
                            onClick={() => handleSignUp()}
                            variant="outlined"
                            title={"Sign Up"}
                        />
                    ) : null}
                </>
            ) : null}
        </Box>
    );
}

export default SignUpNavbar;
