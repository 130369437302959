import * as Actions from "../Action/StatesAction";

const initialState = {
    list: [],
};

const states = (state = initialState, action) => {
    switch (action?.type) {
        case Actions.STATES_LIST_ACTION: {
            return {
                ...state,
                list: [...action.payload],
            };
        }
        default: {
            return state;
        }
    }
};

export default states;
