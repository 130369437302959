import React, { Fragment, cloneElement } from "react";
import TableCell from "@mui/material/TableCell";
import Checkbox from "../../Checkbox/CheckboxComponent";
import "./WorkFlowTable.scss";

const EditableRow = (props) => {
    const {
        editFormData,
        max,
        handleInputChange,
        handleSequenceChange,
        handleRequiredChange,
        handleOptionalChange,
        isEdit,
        handleSaveClick,
        extraColRight,
        extraRightColProps,
        extraColLeft,
        extraLeftColProps,
        sx,
    } = props;

    const clickHandler = (event)=>{
        event.stopPropagation();
        handleSaveClick();
    }

    return (
        <Fragment key={editFormData.id}>
            {
                extraColLeft ? <TableCell className="typo4" align="left">
                    {cloneElement(extraColLeft, {...extraLeftColProps, value: editFormData.id})}
                </TableCell> : null

            }
            <TableCell className="typo4" align="left" onClick={(event)=> clickHandler(event)}>
                {isEdit === editFormData.id ? (
                    <input
                        onChange={(e) => handleInputChange(editFormData.id, e)}
                        value={editFormData.name}
                        name="name"
                        className="font_16_600 inputText"
                        onClick={(e)=>e.stopPropagation()}
                    />
                ) : (
                    editFormData.name
                )}
            </TableCell>
            <TableCell className="typo4" align="center" onClick={(event)=> clickHandler(event)}>
                {isEdit === editFormData.id ? (
                    <input
                        onChange={(e) =>
                            handleSequenceChange(editFormData.id, e)
                        }
                        value={editFormData.sequence}
                        name="sequence"
                        type="number"
                        min={1}
                        max={max}
                        className="font_16_600 inputNumber"
                        onClick={(e)=>e.stopPropagation()}
                    />
                ) : (
                    editFormData.sequence
                )}
            </TableCell>
            <TableCell className="typo4" align="center" onClick={(event)=> clickHandler(event)}>
                {isEdit === editFormData.id ? (
                    <Checkbox
                        type="checkbox"
                        name="required"
                        checked={editFormData.required}
                        onChange={(e) =>
                            handleRequiredChange(editFormData.id, e)
                        }
                        onClick={(e)=>e.stopPropagation()}
                    />
                ) : (
                    <Checkbox checked={editFormData.required} />
                )}
            </TableCell>
            <TableCell className="typo4" align="center" onClick={(event)=> clickHandler(event)}>
                {isEdit === editFormData.id ? (
                    <Checkbox
                        type="checkbox"
                        name="optional"
                        checked={
                            editFormData.required !== null
                                ? (editFormData.required !== 1 ? true : false)
                                : false
                        }
                        onChange={(e) =>{
                            handleOptionalChange(editFormData.id, e)
                        }
                            
                        }
                        onClick={(e)=>e.stopPropagation()}
                    />
                ) : (
                    <Checkbox
                        checked={
                            editFormData.required !== null
                                ? (editFormData.required !== 1 ? true : false)
                                : false
                        }
                    />
                )}
            </TableCell>
            {
                extraColRight ? <TableCell className="typo4" align="center">
                    {cloneElement(extraColRight, {...extraRightColProps, value: editFormData.id, status: editFormData.status})}
                </TableCell> : null

            }
        </Fragment>
    );
};

export default EditableRow;
