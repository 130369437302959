import React from "react";
import Navbar from './../../common/Header/Navbar';
import { connect } from "react-redux";
import PageHeader from "../../common/PageHeader";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";

function Documentation(props) {
    const {
        userLoggedIn
    } = props;
    return (
        <>
            <Navbar />
            <Box sx={{ px: { md: 7, xs: 1 }, mb: 6 }}>
                <PageHeader
                    title={'Training Videos'}
                />
                <Grid
                    container
                    display={"flex"}
                    flexWrap={"wrap"}
                    spacing={2}
                    className="userCardMainDiv"
                >
                    <Grid item className="userCardDiv">
                        <Grid
                            item
                            display={"flex"}
                            flexWrap={"wrap"}
                            justifyContent={"center"}
                            gap={1}
                        >
                           <div style={{textAlign: 'center', margin: '0 10% 0 10%'}}>
                           <h1 style={{ fontSize: '20px'}}>What to expect as a Contractor?</h1>
                            <span>What independent Contractors should expect when onboarding with the American Chemical Society?</span>
                           <iframe width="100%" height="630" src="https://www.youtube.com/embed/wWLv_lDms4k?si=mboOosPL6hLJFRJp" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                           </div>

                            <div style={{textAlign: 'center', margin: '0 10% 0 10%'}} >
                            <h1 style={{ fontSize: '20px'}}>What to expect as a Contractor?</h1>
                            <span>What independent Contractors should expect when onboarding with the American Chemical Society?</span>
                                 <iframe width="100%" height="630" src="https://www.youtube.com/embed/qX72II6tfTA?si=0duxL5vz29PgNpHB" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

                            </div>
                                                    
                          
                        </Grid>
                    </Grid>
                </Grid>
                {/* <div
                    style={{
                        width: "100%",
                        height: "600px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "26px",
                        fontWeight: "600",
                    }}
                >
                    {userLoggedIn?.language_data?.documentation?.coming_soon || "Coming Soon"}
                </div> */}
            </Box>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,

    };
};


export default connect(mapStateToProps, null)(Documentation);
