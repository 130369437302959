import { API_CONSTANTS } from "../../config/ApiConstants";
import { getApiCall, postApiCall ,deleteApiCall,putApiCall} from "../../utils/Action"
export const GET_ASSESSMENT_PARTIES_ACTION = "GET_ASSESSMENT_PARTIES_ACTION";
export const ADD_ASSESSMENT_PARTIES_ACTION = "ADD_ASSESSMENT_PARTIES_ACTION";
export const DELETE_ASSESSMENT_PARTIES_ACTION = "DELETE_ASSESSMENT_PARTIES_ACTION";
export const SINGLE_UPDATE_ASSESSMENT_PARTIES_ACTION = "SINGLE_UPDATE_ASSESSMENT_PARTIES_ACTION";
export const BULK_UPDATE_ASSESSMENT_PARTIES_ACTION = "UPDATE_ASSESSMENT_PARTIES_ACTION";

export const getPartiesAction = (guid,id) => {
    return async (dispatch) => {
        try {
            const response = await getApiCall(API_CONSTANTS.ASSESSMENT_PARTIES, { 
                guid: guid,
                user_id:id
            });
            if (response?.status === 200) {
                return dispatch({
                    type: GET_ASSESSMENT_PARTIES_ACTION,
                    payload: response.data.result,
                });
            } 
        } catch (error) {
            throw error;
        }
    };
};

export const addPartiesAction = (guid,user_id,id) => {
    return async (dispatch) => {
        try {
            const response = await postApiCall(API_CONSTANTS.ASSESSMENT_PARTIES, { 
                guid:guid ,
                user_id:user_id,
                contractor_id:id
            });
            if (response.status === 200) {
                 return dispatch({
                     type: ADD_ASSESSMENT_PARTIES_ACTION,
                     payload: [],
                 });
            } 
        } catch (error) {
            throw error;
        }
    };
};

export const singleUpdatePartiesAction = (guid,data) => {
    return async (dispatch) => {
        try {
            const response = await putApiCall(API_CONSTANTS.UPDATE_ASSESSMENT_PARTIES+`/${guid}`,data);
            if (response.status === 200) {
                return dispatch({
                    type: SINGLE_UPDATE_ASSESSMENT_PARTIES_ACTION,
                    payload: [],
                });
            } 
        } catch (error) {
            throw error;
        }
    };
};

export const updatePartiesAction = (guid,data) => {
    return async (dispatch) => {
        try {
            const response = await putApiCall(API_CONSTANTS.ASSESSMENT_PARTIES,+ `/${guid}`,{parties:data});
            if (response?.status === 200) {
                return dispatch({
                    type: BULK_UPDATE_ASSESSMENT_PARTIES_ACTION,
                    payload: [],
                });
            } 
        } catch (error) {
            throw error;
        }
    };
};


export const deletePartiesAction = (guid,id) => {
    return async (dispatch) => {
        try {
            const response = await deleteApiCall(API_CONSTANTS.ASSESSMENT_PARTIES +`/${id}`,{guid:guid});
            if (response.status === 200) {
                return dispatch({
                    type: DELETE_ASSESSMENT_PARTIES_ACTION,
                    payload: [],
                });
            } 
        } catch (error) {
            throw error;
        }
    };
};