import { API_CONSTANTS } from "../../config/ApiConstants";
import { postApiCall, getApiCall } from "../../utils/Action";
import { toast } from "react-hot-toast";
// import { setLoader } from "./LoginAction";
export const POST_SAVE_CONTRACTOR_EMAIL = "POST_SAVE_CONTRACTOR_EMAIL";
export const GET_CONTRACTOR_LIST = "GET_CONTRACTOR_LIST";
export const GET_CONTRACTOR_TEMPLATE_DETAILS =
    "GET_CONTRACTOR_TEMPLATE_DETAILS";
export const UPDATE_CONTRACTOR_TEMPLATE_DETAILS =
    "UPDATE_CONTRACTOR_TEMPLATE_DETAILS";
export const REMOVE_CONTRACTOR_EMAIL_TEMPLATES =
    "REMOVE_CONTRACTOR_EMAIL_TEMPLATES";
export const GET_CONTRACTOR_EMAIL_HISTORY_LIST =
    "GET_CONTRACTOR_EMAIL_HISTORY_LIST";
export const GET_CONTRACTOR_EMAIL_HISTORY_DETAIL =
    "GET_CONTRACTOR_EMAIL_HISTORY_DETAIL";
export const SEND_CONTRACTOR_EMAIL_TEMPLATES =
    "SEND_CONTRACTOR_EMAIL_TEMPLATES";
export const GET_IMPORT_TEMPLATES = "GET_IMPORT_TEMPLATES";
export const IMPORT_EMAIL_TEMPLATES = "IMPORT_EMAIL_TEMPLATES";
export const saveContractorEmail = (data) => {
    return async (dispatch) => {
        try {
            // dispatch(setLoader(true));
            const response = await postApiCall(
                API_CONSTANTS.SAVE_CONTRACTOR_EMAIL_TEMPLATE,
                data
            );
            // dispatch(setLoader(false));
            if (response?.status === 201) {
                return dispatch({
                    type: POST_SAVE_CONTRACTOR_EMAIL,
                    payload:
                        {
                            ...response?.data?.result,
                            message: response?.data?.message,
                        } || [],
                });
            } else {
                // toast.error(response?.data?.message);
            }
        } catch (error) {
            // toast.error(error?.response?.data?.message);
        }
    };
};
export const getContractorList = (data) => {
    return async (dispatch) => {
        try {
            const response = await postApiCall(
                API_CONSTANTS.CONTRACTOR_EMAIL_LISTS,
                data
            );
            if (response?.status === 200) {
                dispatch({
                    type: GET_CONTRACTOR_LIST,
                    payload: response?.data?.result || [],
                });
            } else {
                // toast.error(response?.data?.message);
            }
        } catch (error) {
            // toast.error(error?.response?.data?.message);
        }
    };
};
export const getContractorEmailDetail = (id) => {
    return async (dispatch) => {
        try {
            const response = await getApiCall(
                API_CONSTANTS.GET_CONTRACTOR_TEMPLATE_DETAILS + "/" + id
            );
            if (response?.status == 200) {
                dispatch({
                    type: GET_CONTRACTOR_TEMPLATE_DETAILS,
                    payload: response?.data?.result || [],
                });
            } else {
                // toast.error(response?.data?.message);
            }
        } catch (error) {
            // toast.error(error?.response?.data?.message);
        }
    };
};
export const updateContractorEmailDetail = (data) => {
    return async (dispatch) => {
        try {
            const response = await postApiCall(
                API_CONSTANTS.UPDATE_CONTRACTOR_EMAIL_TEMPLATE,
                data
            );
            if (response?.status == 200) {
                return dispatch({
                    type: UPDATE_CONTRACTOR_TEMPLATE_DETAILS,
                    payload: {
                        ...response?.data?.result,
                        data,
                        message: response?.data?.message,
                        status: response?.data?.status,
                    }, // this data is will be update by ayan
                });
            } else {
                // toast.error(response?.data?.message);
            }
        } catch (error) {
            // toast.error(error?.response?.data?.message);
        }
    };
};
export const deleteContractorEmailTemplate = (ids) => {
    return async (dispatch) => {
        try {
            const response = await postApiCall(
                API_CONSTANTS.REMOVE_CONTRACTOR_EMAIL_TEMPLATE,
                ids
            );
            if (response?.status == 200) {
                return dispatch({
                    type: REMOVE_CONTRACTOR_EMAIL_TEMPLATES,
                    payload: { ids, response },
                });
            } else {
                // toast.error(response?.data?.message);
            }
        } catch (error) {
            // toast.error(error?.response?.data?.message);
        }
    };
};
export const getContractorEmailHistoryList = (data) => {
    return async (dispatch) => {
        try {
            const response = await postApiCall(
                API_CONSTANTS.GET_CONTRACTOR_EMAIL_HISTORY_LIST,
                data
            );
            if (response?.status == 200) {
            } else {
                // toast.error(response?.message);
            }
            dispatch({
                type: GET_CONTRACTOR_EMAIL_HISTORY_LIST,
                payload: response?.data?.result || [],
            });
        } catch (error) {
            // toast.error(error?.response?.data?.message);
        }
    };
};
export const setEmailHistoryDetails = (id) => {
    let data = { client_id: 37, id: id };
    return async (dispatch) => {
        try {
            const response = await postApiCall(
                API_CONSTANTS.GET_CONTRACTOR_EMAIL_HISTORY_DETAIL,
                data
            );
            if (response?.status == 200) {
                return dispatch({
                    type: GET_CONTRACTOR_EMAIL_HISTORY_DETAIL,
                    payload: {
                        result: response?.data?.result,
                        message: response?.data?.message,
                        status: response?.data?.status,
                    },
                });
            } else {
                // toast.error(response?.data?.message);
            }
        } catch (err) {
            // toast.error(err?.response?.data?.message);
        }
    };
};
export const sendContractorEmail = (data) => {
    return async (dispatch) => {
        try {
            const response = await postApiCall(
                API_CONSTANTS.SEND_CONTRACTOR_EMAIL,
                data
            );
            if (response?.status == 200) {
                return dispatch({
                    type: SEND_CONTRACTOR_EMAIL_TEMPLATES,
                    payload: {
                        ...response?.data?.result,
                        message: response?.data?.message,
                        status: response?.data?.status,
                    },
                });
            } else {
                // toast.error(response?.data?.message);
            }
        } catch (error) {
            // toast.error(error?.response?.data?.message);
        }
    };
};
export const importEmailTemplates = (data) => {
    return async (dispatch) => {
        try {
            const response = await postApiCall(
                API_CONSTANTS.IMPORT_EMAIL_TEMPLATES,
                data
            );
            if (response?.status == 200) {
                return dispatch({
                    type: IMPORT_EMAIL_TEMPLATES,
                    payload: {
                        result: response?.data?.result,
                        message: response?.data?.message,
                        status: response?.data?.status,
                    },
                });
            } else {
                // toast.error(response?.data?.message);
            }
        } catch (error) {
            // toast.error(error?.response?.data?.message);
        }
    };
};
export const getEmailTemplates = () => {
    return async (dispatch) => {
        try {
            const response = await getApiCall(
                API_CONSTANTS.GET_EMAIL_TEMPLATES
            );
            if (response?.status == 200) {
                dispatch({
                    type: GET_IMPORT_TEMPLATES,
                    payload: response?.data?.result || [],
                });
            } else {
                // toast.error(response?.data?.message);
            }
        } catch (error) {
            // toast.error(error?.response?.data?.message);
        }
    };
};
