import * as Actions from "../Action/LiteTestSettingsAction";

const initialState = {
    liteTestList: null,
};

const LiteTestReducer = (state = initialState, action) => {
    switch (action?.type) {
        case Actions.CREATE_LITE_TEST: {
            return {
                ...state,
                liteTestList: action.payload,
            };
        }
        case Actions.GET_LITE_TEST: {
            return {
                ...state,
                liteTestList: action.payload,
            };
        }
        case Actions.EDIT_LITE_TEST: {
            let list = state.liteTestList;
            let index = list?.findIndex((obj) => obj.id === action.payload.id);
            if (index !== -1)
                list[index] = {
                    ...list[index],
                    test_type: action.payload?.test_type,
                    test_id: action.payload?.test_id,
                    total_questions: action.payload?.total_questions,
                };
            return {
                ...state,
                liteTestList: list,
            };
        }
        case Actions.DELETE_LITE_TEST_BY_ID: {
            let list = state.liteTestList;
            let updateList = list?.filter((obj) => obj.id !== action.payload);
            return {
                ...state,
                liteTestList: updateList,
            };
        }
        default: {
            return state;
        }
    }
};

export default LiteTestReducer;
