import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import MainLogo from "../../assets/Images/Logo/Logo.png";
import { ROUTES } from "../router/RouteList";
import { Link } from "react-router-dom";

const UnloginFooter = (props) => {
    const { whiteLabel } = props;

    const today = new Date();
  const year = today.getFullYear();

    return (
        <Box paddingY={"30px"} className="unloginFooterBox">
               <Grid
                container
                sx={{
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Grid item>
                    <Box sx={{ display: "flex" }}>
                        <img
                            src={whiteLabel?.logo || MainLogo}
                            alt="mainLogo"
                            style={{
                                width: " 231px",
                                height: "40px",
                                flexShrink: "0",
                            }}
                        />
                    </Box>
                </Grid>
                <Grid item display={"flex"} gap={4} marginY={"30px"}>
                    <Grid item>
                        <Link className="unLoginLink" to={ROUTES.ABOUT_US}>
                            About
                        </Link>
                    </Grid>
                    <Grid item>
                        <Link className="unLoginLink">Contact</Link>
                    </Grid>
                    <Grid item>
                        <Link className="unLoginLink">Site Map</Link>
                    </Grid>
                    <Grid item>
                        <Link className="unLoginLink">Privacy Policy</Link>
                    </Grid>
                </Grid>
                <Grid item>
                    <Typography className="unLoginCopyRight">
                        {year} Copyright | Elevance All Right Reserved
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
};

const mapStateToProps = (state) => {
    return {
        whiteLabel: state?.whiteLabel,
    };
};

export default connect(mapStateToProps)(UnloginFooter);
