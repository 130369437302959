import { getApiCall } from "../../utils/Action";
import { API_CONSTANTS } from "../../config/ApiConstants";
import { toast } from "react-hot-toast";

export const STATES_LIST_ACTION = "STATES_LIST_ACTION";

const performStatesListAction = ()=>{
    return async(dispatch)=>{
        try{
            const response = await getApiCall(API_CONSTANTS.STATES_LIST);
            if(response.status === 200){
                return dispatch({
                    type: STATES_LIST_ACTION,
                    payload: response?.data?.result?.states
                })
            }else{
                // toast.error(response?.message)
            }
        }catch(error){
            // toast.error(error?.message);
        }
    }
}

export default performStatesListAction;
