import React, { Fragment } from "react";
import AccordionCustom from "../../../../../components/AccordionCustom";
import "../../../../../../assets/styles/Container/CustomerOfficePageHeader.scss";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { ROUTES } from "../../../../../router/RouteList";
import { useLocation } from "react-router-dom";

const Section = (props) => {
    const { showComment, showTooltip, tooltipTitle, tooltipCildren, expand } = props;
    const location = useLocation();
    let customer = useSelector((state) => state.customer.selectedCustomer);

    if (location.pathname.search(ROUTES.SETTINGS) !== -1) {
        customer = location?.state?.selectedCustomer;
    }

    const comments = useSelector((state) => state.comments.comments);

    const filterComment = (objAllComments, title) => {
   
        const lowerTitle = title?.split(" ")?.join("_").toLowerCase();
        const comments = objAllComments[lowerTitle];
        return comments?.comments;
    };

    return (
        <Fragment>
            <Grid container className={props.className}>
                <Grid item lg={12}>
                    <AccordionCustom
                        title={props.title}
                        customer={customer}
                        comments={filterComment(comments, props.title)}
                        showComment={showComment}
                        showTooltip={showTooltip}
                        tooltipTitle={tooltipTitle}
                        tooltipCildren={tooltipCildren}    
                        expand={expand}                    
                    >
                        {props.children}
                    </AccordionCustom>
                </Grid>
            </Grid>
        </Fragment>
    );
};

Section.defaultProps = {
    className: "my-20",
};

export default Section;
