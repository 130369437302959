import { getApiCall } from "../../utils/Action";
import { toast } from "react-hot-toast";
import { API_CONSTANTS } from "../../config/ApiConstants";
export const AUDIT_DEFENCE_REPORT = "AUDIT_DEFENCE_REPORT";

export const getAuditDefenseReport = (guid, user_id,mode) => {
    return async (dispatch) => {
        try {
            const response = await getApiCall(API_CONSTANTS.AUDIT_DEFENCE_REPORT + `?guid=${guid}` + `&user_id=${user_id}`,{mode:mode});
            if (response?.status === 200) {
                return dispatch({
                    type: AUDIT_DEFENCE_REPORT,
                    payload: response?.data,
                });
            } else {
                // toast.error(response?.data?.message);
            }
        } catch (error) {
            throw error;
        }
    };
};