import React from "react";
import Typography from "@mui/material/Typography";
import Navbar from "./../common/Header/Navbar";

function PermissionDenied() {
    return (
        <>
            <Navbar />
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "600px",
                }}
            >
                <Typography sx={{ fontSize: "170px" }} className="primaryColor">
                    403
                </Typography>
                <Typography
                    sx={{
                        fontSize: "30px",
                        fontWeight: 600,
                        marginLeft: "50px",
                        marginTop: "114px",
                    }}
                    className="primaryColor"
                >
                    Permission Denied ......
                </Typography>
            </div>
        </>
    );
}

export default PermissionDenied;
