import {
    deleteApiCall,
    getApiCall,
    postApiCall,
    putApiCall,
} from "../../utils/Action";
import { API_CONSTANTS } from "../../config/ApiConstants";

export const CREATE_LITE_TEST = "CREATE_LITE_TEST";
export const GET_LITE_TEST = "GET_LITE_TEST";
export const EDIT_LITE_TEST = "EDIT_LITE_TEST";
export const DELETE_LITE_TEST_BY_ID = "DELETE_LITE_TEST_BY_ID";

export const saveLiteTestOption = (data) => {
    return async (dispatch) => {
        try {
            const response = await postApiCall(API_CONSTANTS.LITE_TEST, data);
            if (response?.status == 201) {
                return dispatch({
                    type: CREATE_LITE_TEST,
                    payload: response?.data?.result,
                });
            } else {
                // toast.error(response?.message);
            }
        } catch (error) {
            throw error;
        }
    };
};

export const getLiteTestList = (guid) => {
    return async (dispatch) => {
        try {
            const response = await getApiCall(API_CONSTANTS.LITE_TEST, {
                guid: guid,
            });
            if (response?.status === 200) {
                return dispatch({
                    type: GET_LITE_TEST,
                    payload: response?.data?.result,
                });
            } else {
            }
        } catch (error) {
            throw error;
        }
    };
};

export const editLiteTestOption = (data) => {
    return async (dispatch) => {
        try {
            const response = await putApiCall(
                API_CONSTANTS.LITE_TEST + "/" + data.id,
                data
            );
            if (response?.status == 201) {
                return dispatch({
                    type: EDIT_LITE_TEST,
                    payload: response?.data?.result || null,
                });
            } else {
                // toast.error(response?.message);
            }
        } catch (error) {
            throw error;
        }
    };
};

export const deleteLiteTestRow = (data) => {
    return async (dispatch) => {
        try {
            const response = await deleteApiCall(
                API_CONSTANTS.LITE_TEST + "/" + data.id,
                data
            );
            if (response?.status === 200) {
                dispatch({
                    type: DELETE_LITE_TEST_BY_ID,
                    payload: data.id,
                });
            }
        } catch (error) {
            throw error;
        }
    };
};
