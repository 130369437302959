import * as React from "react";
import { connect } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Box from "@mui/material/Box";
import DialogContentText from "@mui/material/DialogContentText";
import Typography from "@mui/material/Typography";
import LinkIcon from "@mui/icons-material/Link";
import ButtonComponent from "../../components/ButtonComponent";
import DialogComponent from "../../components/DialogsBox/DialogComponent";
import {Translate } from 'react-auto-translate';
const ShareFormBuilderDialog = (props) => {
    const { open, state, setState } = props;
    const handleClose = () => {
        setState((prevState) => ({
            ...prevState,
            openShareDialog: { open: false, data: null },
        }));
    };

    return (
        <DialogComponent
    open={open}
    title={<Translate>Link To Share</Translate>}
    handleClose={handleClose}
    content={
        <>
            <DialogContentText>
                <Translate>Your form is securely published and ready to use at this address.</Translate>
            </DialogContentText>
            <Box className="shareLinkCon">
                <LinkIcon sx={{ fontSize: "25px" }} />
                <Typography variant="h5">
                    {state?.formShareLink}
                </Typography>
            </Box>
            {state?.copied ? (
                <span style={{ color: "red" }}>
                    <Translate>Copied.</Translate>
                </span>
            ) : null}
        </>
    }
    action={
        <>
            <CopyToClipboard
                text={state?.formShareLink}
                onCopy={() =>
                    setState((prevState) => ({
                        ...prevState,
                        copied: true,
                    }))
                }
            >
                <ButtonComponent title={<Translate>Copy Link</Translate>} />
            </CopyToClipboard>
            <ButtonComponent
                id="shareFormBuilderNewTabButton"
                title={<Translate>Open In New Tab</Translate>}
                onClick={() => window.open(state?.formShareLink)}
            />
        </>
    }
/>

    );
};

const mapStateToProps = (state) => {
    return {
        domain: state.whiteLabel.domain,
    };
};

export default connect(mapStateToProps, null)(ShareFormBuilderDialog);
