import { getApiCall, postApiCall, putApiCall } from "../../utils/Action";
import { API_CONSTANTS } from "../../config/ApiConstants";

export const TEST_SCOPE_SETTING = "TEST_SCOPE_SETTING";
export const SET_TEST_SCOPE_DETAILS = "SET_TEST_SCOPE_DETAILS";
export const GET_TEST_SCOPE_DETAILS = "GET_TEST_SCOPE_DETAILS";
export const EDIT_TEST_SCOPE_DETAILS = "EDIT_TEST_SCOPE_DETAILS";

const performTestScopeSettingAction = (guid) => {
    return async (dispatch) => {
        try {
            const response = await getApiCall(
                API_CONSTANTS.TEST_SCOPE_SETTINGS,
                { guid: guid }
            );
            if (response.status === 200) {
                return dispatch({
                    type: TEST_SCOPE_SETTING,
                    payload: response?.data?.result,
                });
            } else {
                // toast.error(response?.message);
            }
        } catch (error) {
            // toast.error(error?.message);
        }
    };
};
export const saveTestScopeDetails = (data) => {
    return async (dispatch) => {
        try {
            const response = await postApiCall(
                API_CONSTANTS.TEST_SCOPE_SETTINGS,
                data
            );
            if (response?.status == 201) {
                return dispatch({
                    type: SET_TEST_SCOPE_DETAILS,
                    payload: response?.data?.result || null,
                });
            } else {
                // toast.error(response?.message);
            }
        } catch (error) {
            throw error;
        }
    };
};

export const getTestScopeDetails = (guid) => {
    return async (dispatch) => {
        try {
            const response = await getApiCall(
                API_CONSTANTS.TEST_SCOPE_SETTINGS,
                {
                    guid: guid,
                }
            );
            if (response?.status == 200) {
                return dispatch({
                    type: GET_TEST_SCOPE_DETAILS,
                    payload: response?.data?.result,
                });
            } else {
                // toast.error(response?.message);
            }
        } catch (error) {
            throw error;
        }
    };
};
export const editTestScopeDetails = (data) => {
    return async (dispatch) => {
        try {
            const response = await putApiCall(
                API_CONSTANTS.TEST_SCOPE_SETTINGS + "/" + data.id,
                data
            );
            if (response?.status == 201) {
                return dispatch({
                    type: EDIT_TEST_SCOPE_DETAILS,
                    payload: response?.data?.result || null,
                });
            } else {
                // toast.error(response?.message);
            }
        } catch (error) {
            throw error;
        }
    };
};

export default performTestScopeSettingAction;
