import { getApiCall } from "../../utils/Action";
import { API_CONSTANTS } from "../../config/ApiConstants";
import { toast } from "react-hot-toast";

export const AGREEMENT_SETTING = "AGREEMENT_SETTING";

const performAgreementSettingAction = ()=>{
    return async(dispatch)=>{
        try{
            const response = await getApiCall(API_CONSTANTS.AGREEMENT_SETTINGS);
            if(response?.status === 200){
                return dispatch({
                    type: AGREEMENT_SETTING,
                    payload: response?.data?.result
                });
            }else{
                // toast.error(response?.data?.message);
            }
        }catch(error){
            throw error;
        }
    }
}

export default performAgreementSettingAction;
