import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import ButtonComponent from './ButtonComponent';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '6px',
  p: 4,
};

export default function SubscriptionDialog(props) {
    const {open, setOpen} = props
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography sx={{textAlign: 'center', fontWeight: '600'}} id="modal-modal-title" variant="h6" component="h2">
            {" "}{" "}
           
          Requires Subscription
          </Typography>
          
          <ButtonComponent
                    sx={{ justfyContent: 'center', margin: 'auto', display: 'flex', marginTop: '20px' }}
                             id="cancel"
                            title={"Cancel"}
                            onClick={() => setOpen(false)}
                            variant={"filled"}
                        />
        </Box>
      </Modal>
    </div>
  );
}
