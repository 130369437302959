import { API_CONSTANTS } from "../../config/ApiConstants";
import { getApiCall,postApiCall } from "../../utils/Action";


export const GET_COMMENTS_ACTION = "GET_COMMENTS_ACTION";
export const ADD_COMMENT_ACTION = "ADD_COMMENT_ACTION";
export const SET_COMMENTS_COUNT_ACTION = "SET_COMMENTS_COUNT_ACTION";

export const getCommentsAction = (guid) => {
    return async (dispatch) => {
        try {
            const response = await getApiCall(API_CONSTANTS.COMMENTS, {
                guid: guid,
            });
            
            if (response?.status === 200) {
                return dispatch({
                    type: GET_COMMENTS_ACTION,
                    payload: response.data.result.comments,
                });
            }else if (response?.status === 422) {
                return dispatch({
                    type: GET_COMMENTS_ACTION,
                    payload: [],
                });
            } 
        } catch (error) {
            throw error
        }
    };
};
export const addCommetAction = (guid, section, comment) => {
    return async(dispatch)=>{
        try{
            const response = await postApiCall(API_CONSTANTS.COMMENTS, comment, {
                guid: guid,
                section: section,
            });
            if (response?.status === 201) {
                return dispatch({
                    type: ADD_COMMENT_ACTION,
                    payload: [],
                });
            }
        }catch(error){
            throw error
        }
    }
}

export const setCommentsCountAction = (count) => {
    return async (dispatch) => {
        try {
            return dispatch({
                type: SET_COMMENTS_COUNT_ACTION,
                payload: count,
            });
        } catch (error) {
            throw error
        }
    };
};
