import { API_CONSTANTS } from "../../config/ApiConstants";
import {
    getApiCall,
    postApiCall,
    deleteApiCall,
    putApiCall,
} from "../../utils/Action";

export const GET_CONTRACTS_ACTION = "GET_CONTRACTS_ACTION";
export const GET_CONTRACTS_TEMPLATES_ACTION = "GET_CONTRACTS_TEMPLATES_ACTION";
export const GET_CONTRACTS_CONTENT_ACTION = "GET_CONTRACTS_CONTENT_ACTION";
export const SET_SELECTED_TEMPLATE_ACTION = "SET_SELECTED_TEMPLATE_ACTION";
export const ADD_CONTRACT_ACTION = "ADD_CONTRACT_ACTION";
export const UPDATE_CONTRACTS_ACTION = "UPDATE_CONTRACTS_ACTION";
export const UPDATE_CONTENT_LOCAL_ACTION = "UPDATE_CONTENT_LOCAL_ACTION";
export const UPDATE_CONTENT_GLOBAL_ACTION = "UPDATE_CONTENT_GLOBAL_ACTION";
export const DELETE_CONTRACT_ACTION = "DELETE_CONTRACT_ACTION";
export const DELETE_TEMPLATE_ACTION = "DELETE_TEMPLATE_ACTION";

export const getContractsAction = (guid, id) => {
    return async (dispatch) => {
        try {
            const response = await getApiCall(
                API_CONSTANTS.CONTRACTS_DATABASE,
                {
                    guid: guid,
                    contractor_id: id,
                }
            );
            if (response?.status === 200) {
                return dispatch({
                    type: GET_CONTRACTS_ACTION,
                    payload: response.data.result,
                });
            }
        } catch (error) {
            throw error;
        }
    };
};

export const addContractAction = (data) => {
    return async (dispatch) => {
        try {
            const response = await postApiCall(
                API_CONSTANTS.CONTRACTS_COLLECTION,
                data,
                { guid: data.guid }
            );
            if (response?.status === 201) {
                return dispatch({
                    type: ADD_CONTRACT_ACTION,
                    payload: [],
                });
            }
        } catch (error) {
            throw error;
        }
    };
};

export const ImportContractsAction = (data) => {
    return async (dispatch) => {
        try {
            const response = await postApiCall(
                API_CONSTANTS.IMPORT_CONTRACTS,
                data
            );
        } catch (error) {
            throw error;
        }
    };
};

export const updateContractsAction = (guid, data) => {
    return async (dispatch) => {
        try {
            const response = await putApiCall(
                API_CONSTANTS.CONTRACTS_COLLECTION + `/${guid}`,
                { collection: data }
            );
            if (response?.status === 200) {
                return dispatch({
                    type: UPDATE_CONTRACTS_ACTION,
                    payload: [],
                });
            }
        } catch (error) {
            throw error;
        }
    };
};

export const deleteContractAction = (guid, id) => {
    return async (dispatch) => {
        try {
            const response = await deleteApiCall(
                API_CONSTANTS.CONTRACTS_COLLECTION + `/${guid}`,
                { id: id }
            );
            if (response?.status === 200) {
                return dispatch({
                    type: DELETE_CONTRACT_ACTION,
                    payload: [],
                });
            }
        } catch (error) {
            throw error;
        }
    };
};

export const getContractsTemplatesAction = (id) => {
    return async (dispatch) => {
        try {
            const response = await postApiCall(`signed-document/get-list/${id}`);
            if (response?.status === 200) {
                return dispatch({
                    type: GET_CONTRACTS_TEMPLATES_ACTION,
                    payload: response.data.result,
                });
            }
        } catch (error) {
            throw error;
        }
    };
};
// export const getSignedTemplatesAction = () => {
//     return async (dispatch) => {
//         try {
//             const response = await getApiCall(`signed-document/get-list`);
//             if (response?.status === 200) {
//                 return dispatch({
//                     type: GET_CONTRACTS_TEMPLATES_ACTION,
//                     payload: response.data.result,
//                 });
//             }
//         } catch (error) {
//             throw error;
//         }
//     };
// };

export const getContractsContentAction = (id) => {
    return async (dispatch) => {
        try {
            const response = await getApiCall(`/contract/${id}`);
            if (response?.status === 200) {
                return dispatch({
                    type: SET_SELECTED_TEMPLATE_ACTION,
                    payload: response?.data?.result,
                });
            }
        } catch (error) {
            throw error;
        }
    };
};

export const updateContentLocalAction = (data) => {
    return async (dispatch) => {
        try {
            return dispatch({
                type: UPDATE_CONTENT_LOCAL_ACTION,
                payload: data,
            });
        } catch (error) {
            throw error;
        }
    };
};
export const updateContentGlobalAction = (category_id, data) => {
    return async (dispatch) => {
        try {
            const response = await postApiCall(
                `signed-document/update/${category_id}`,
                data
            );
            if (response?.status === 200) {
                return dispatch({
                    type: UPDATE_CONTENT_GLOBAL_ACTION,
                    payload: [],
                });
            }
        } catch (error) {
            throw error;
        }
    };
};
export const setSelectedTemplateAction = (data) => {
    return async (dispatch) => {
        try {
            return dispatch({
                type: SET_SELECTED_TEMPLATE_ACTION,
                payload: data,
            });
        } catch (error) {
            throw error;
        }
    };
};

export const deleteTemplatetAction = (id, data) => {
    return async (dispatch) => {
        try {
            const response = await putApiCall(
                `sign-document-active/${id}`,
                data,
            );
            if (response?.status === 200) {
                return dispatch({
                    type: DELETE_TEMPLATE_ACTION,
                });
            }
        } catch (error) {
            throw error;
        }
    };
};
export const deleteDocumenttAction = (id, data) => {
    return async (dispatch) => {
        try {
            const response = await putApiCall(
                `document-database-active/${id}`,
                data,
                {
                    is_active: false,
                }
            );
            if (response?.status === 200) {
                return dispatch({
                    type: DELETE_TEMPLATE_ACTION,
                });
            }
        } catch (error) {
            throw error;
        }
    };
};
export const deleteAllTemplatetAction = (data) => {
    return async (dispatch) => {
        try {
            const response = await postApiCall(
                `sign-document-active/bulk`,
                data,
            );
            if (response?.status === 200) {
                return dispatch({
                    type: DELETE_TEMPLATE_ACTION,
                });
            }
        } catch (error) {
            throw error;
        }
    };
};
export const deleteAllDocumentAction = (data) => {
    return async (dispatch) => {
        try {
            const response = await postApiCall(
                `document-database-active/bulk`,
                data,
            );
            if (response?.status === 200) {
                return dispatch({
                    type: DELETE_TEMPLATE_ACTION,
                });
            }
        } catch (error) {
            throw error;
        }
    };
};
