import React from "react";
import TextField from "@mui/material/TextField";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "./DatePickerComponent.scss";

function DatePickerComponent(props) {
    const {
        name,
        onChange,
        checked,
        value,
        label,
        className,
        variant,
        disabled,
        required,
        maxDate,
        minDate,
        id,
    } = props;

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
                id={id}
                onChange={onChange}
                name={name}
                value={value}
                checked={checked}
                label={label}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant={variant || "filled"}
                        InputLabelProps={{ shrink: true }}
                        required={required}
                        id="datePickerComponent"
                    />
                )}
                className={className || "datePickerComponent"}
                disabled={disabled}
                maxDate={maxDate}
                minDate={minDate}
            />
        </LocalizationProvider>
    );
}

export default DatePickerComponent;
