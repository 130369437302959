import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import DropDownComponent from "../../components/DropDown/DropDownComponent";
import MenuItem from "@mui/material/MenuItem";
import ButtonComponent from "../../components/ButtonComponent";
import CheckboxComponents from "../../components/Checkbox/CheckboxComponent";
import {
    getAssignUsersData,
    getEmailStatusData,
} from "../../store/Action/EmailTemplateAction";
import "../../../assets/styles/Container/ContractorEmail.scss";
import DialogComponent from "../../components/DialogsBox/DialogComponent";
import TextFieldComponent from "../../components/TextField/TextFieldComponent";
import AutoCompleteDropDown from "../../components/DropDown/AutoCompleteDropDown";

function SaveEmailModel(props) {
    const {
        open,
        client_id,
        getAssignUsersData,
        assignUserData,
        getEmailStatusData,
        emailStatusData,
        state,
        setState,
        saveTemplate,
        UpdateEmailTemplate,
    } = props;
    const [index, setIndex] = useState({ indexStatus: null, hide: false });
    const { hide } = index;

    useEffect(() => {
        getEmailStatusData();
        getAssignUsersData(client_id);
    }, [client_id, getAssignUsersData, getEmailStatusData]);

    const handleChangeStatus = (indexkey) => {
        setState((pre) => ({ ...pre, status: indexkey }));
        setIndex((pre) => ({ ...pre, indexStatus: indexkey }));
    };
    const handleChangeStatusReason = (indexkey) => {
        setState((pre) => ({ ...pre, status_reason: indexkey }));
    };
    const handleChangeAssign = (option) => {
        setState((pre) => ({
            ...pre,
            assign_to: option.id,
            defaultAssign_to: [option],
        }));
    };
    const handleChangeDescription = (value) => {
        setState((pre) => ({ ...pre, description: value }));
    };
    const handleChecked = () => {
        setIndex((pre) => ({ ...pre, hide: !hide }));
    };
    const pathName = window?.location?.pathname;
    let edit = pathName.includes("edit");

    const handleClose = () => {
        setState((prevState) => ({ ...prevState, openSaveModel: false }));
    };

    return (
        <DialogComponent
            open={open}
            handleClose={handleClose}
            className="saveEmailDialog"
            title={`Created: ${state?.template_name}`}
            content={
                <>
                    <Grid container mt={4}>
                        <Grid item md={2} xs={12}>
                            <Typography className="dialogContentHeading">
                                Decision
                            </Typography>
                        </Grid>
                        <Grid item md={10} xs={12}>
                            <DropDownComponent
                                id="saveEmailDecision"
                                label={"Select Decision"}
                                name={"status"}
                                fullWidth={true}
                                onChange={(event) =>
                                    handleChangeStatus(event.target.value)
                                }
                                value={state?.status}
                            >
                                {emailStatusData?.length !== 0 &&
                                    emailStatusData?.status?.map(
                                        (option, index) => (
                                            <MenuItem
                                                key={option}
                                                value={index}
                                            >
                                                {option}
                                            </MenuItem>
                                        )
                                    )}
                            </DropDownComponent>
                        </Grid>
                    </Grid>
                    <Grid container mt={4}>
                        <Grid item md={2} xs={12}>
                            <Typography className="dialogContentHeading">
                                Reason
                            </Typography>
                        </Grid>
                        <Grid item md={10} xs={12}>
                            <DropDownComponent
                                id="saveEmailReason"
                                label={"Select Reason"}
                                name={"status_reason"}
                                fullWidth={true}
                                onChange={(event) =>
                                    handleChangeStatusReason(event.target.value)
                                }
                                value={state?.status_reason}
                            >
                                {emailStatusData?.length !== 0 &&
                                    emailStatusData?.status_reasons[
                                        state?.status
                                    ]?.map((option, index) => (
                                        <MenuItem key={index} value={index}>
                                            {option}
                                        </MenuItem>
                                    ))}
                            </DropDownComponent>
                        </Grid>
                    </Grid>
                    <Grid container mt={4}>
                        <Grid item md={2} xs={12}>
                            <Typography className="dialogContentHeading">
                                Comments
                            </Typography>
                        </Grid>
                        <Grid item md={10} xs={12}>
                            <TextFieldComponent
                                label="Comments"
                                name="description"
                                onChange={(event) =>
                                    handleChangeDescription(event.target.value)
                                }
                                multiline={true}
                                sx={{
                                    paddingTop: "20px",
                                    paddingLeft: "3px",
                                }}
                                rows={3}
                                fullWidth={true}
                                value={state?.description}
                                id="saveEmailDialogDescription"
                            />
                        </Grid>
                    </Grid>
                    <Grid container mt={2}>
                        <Grid item md={2.5} xs={12}>
                            <Typography className="dialogContentHeading">
                                Assign/Reassign
                            </Typography>
                        </Grid>
                        <Grid item md={1} mt={-0.5}>
                            <CheckboxComponents
                                value={
                                    state?.defaultAssign_to?.length > 0
                                        ? true
                                        : false
                                }
                                checked={hide}
                                id="saveEmailDialognAssignReassign"
                                onChange={(event) =>
                                    handleChecked(event?.target?.checked)
                                }
                            />
                        </Grid>
                    </Grid>
                    <Grid container mt={2}>
                        <Grid item md={10} ml={12.5} xs={12}>
                            <AutoCompleteDropDown
                                id="saveEmailDialogAutoCom1"
                                options={assignUserData || []}
                                getOptionLabel={(option) => `${option?.first_name} ${" "} ${option?.last_name}`}
                                onChange={(_, option) =>
                                    handleChangeAssign(option)
                                }
                                defaultValue={state?.defaultAssign_to[0]}
                                disabled={!hide}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        id="saveEmailDialogAssignReassign1"
                                        className={"auto"}
                                        placeholder={"Assign/Reassign"}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                </>
            }
            action={
                <Grid
                    container
                    mt={2}
                    display={"flex"}
                    justifyContent={"center"}
                >
                    <ButtonComponent
                        id="saveEmailDialogSubmitButton"
                        onClick={() =>
                            edit ? UpdateEmailTemplate() : saveTemplate()
                        }
                        title={"Submit"}
                    />
                </Grid>
            }
        />
    );
}

const mapStateToProps = (state) => {
    return {
        emailStatusData: state?.email?.emailStatusData,
        assignUserData: state?.email?.assignUserData,
        client_id: state?.auth?.userLoggedIn?.user?.client_id,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        { getAssignUsersData, getEmailStatusData },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(SaveEmailModel);
