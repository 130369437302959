import React from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import TableComponent from "../../../../../../components/Table/TableComponent";
import NoInfoAvailable from "../../../../../../components/NoInfoAvailable";
import { ROUTES } from "../../../../../../router/RouteList";
import { connect } from "react-redux";
const BalancingCategory = (props) => {
    const { stateTest, setStateTest, addNumberOfValue, guid, userLoggedIn } = props;

    const headerList = [
        {
            name: userLoggedIn?.language_data?.faqs?.category_name || "Category Name",
            headerClass: "tableHeading",
            accessibleKey: "category",
            rowClass: "typo4",
            headerAlign: "left",
            rowAlign: "left",
        },
        {
            id: 1,
            name: "Min. No. of Question",
            headerClass: "tableHeading customerNameTableHeading",
            accessibleKey: "min_questions",
            rowClass: "typo4",
            headerAlign: "center",
            rowAlign: "center",
        },
        {
            id: 2,
            name: "Max. No. of Question",
            headerClass: "tableHeading",
            accessibleKey: "max_questions",
            rowClass: "typo4",
            headerAlign: "center",
            rowAlign: "center",
        },
        {
            id: 3,
            name: "Min. Category Weight Percentage",
            headerClass: "tableHeading",
            accessibleKey: "min_weight",
            rowClass: "typo4",
            headerAlign: "center",
            rowAlign: "center",
        },
        {
            id: 4,
            name: "Max. Category Weight Percentage",
            headerClass: "tableHeading",
            accessibleKey: "max_weight",
            rowClass: "typo4",
            headerAlign: "center",
            rowAlign: "center",
        },
        {
            id: 5,
            name: "Level of Priority Percentage",
            headerClass: "tableHeading",
            accessibleKey: "priority",
            rowClass: "typo4",
            headerAlign: "center",
            rowAlign: "center",
        },
        {
            id: 6,
            name: "Min. Passing Score Percentage",
            headerClass: "tableHeading",
            // accessibleKey: "priority",
            rowClass: "typo4",
            headerAlign: "center",
            rowAlign: "center",
        },
        {
            id: 7,
            name: "View Question",
            headerClass: "tableHeading",
            // accessibleKey: "priority",
            rowClass: "typo4",
            headerAlign: "center",
            rowAlign: "center",
        },
    ];
    const getViewQuestions = (row) => {
        window.open(
            `${ROUTES.SELECT_TEST}/${guid}/${row?.category_id}/${stateTest?.test_id}`,
            "_blank"
        );
    };
    return (
        <>
            <TableComponent headerList={headerList} tableClass="SelfConfTable">
                {stateTest?.category_settings_list?.length > 0 ? (
                    stateTest?.category_settings_list?.map(
                        (category, index) => {
                            return (
                                <TableRow
                                    key={index}
                                    className={
                                        index % 2 !== 0
                                            ? "tertiaryBackground"
                                            : ""
                                    }
                                >
                                    <TableCell align="left" className="typo4">
                                        {category.category}
                                    </TableCell>
                                    <TableCell align="center">
                                        <input
                                            type="number"
                                            className="inputNumber font_16_600"
                                            min={0}
                                            defaultValue={
                                                category?.min_questions
                                            }
                                            name={"min_questions"}
                                            onChange={(event) =>
                                                addNumberOfValue(
                                                    event,
                                                    category?.id
                                                )
                                            }
                                            disabled={
                                                stateTest.isEdit ? false : true
                                            }
                                        />
                                    </TableCell>
                                    <TableCell align="center">
                                        <input
                                            type="number"
                                            className="inputNumber font_16_600"
                                            min={0}
                                            defaultValue={
                                                category?.max_questions
                                            }
                                            name={"max_questions"}
                                            onChange={(event) =>
                                                addNumberOfValue(
                                                    event,
                                                    category?.id
                                                )
                                            }
                                            disabled={
                                                stateTest.isEdit ? false : true
                                            }
                                        />
                                    </TableCell>
                                    <TableCell align="center">
                                        <input
                                            type="number"
                                            className="inputNumber font_16_600"
                                            min={0}
                                            defaultValue={category?.min_weight}
                                            name={"min_weight"}
                                            onChange={(event) =>
                                                addNumberOfValue(
                                                    event,
                                                    category?.id
                                                )
                                            }
                                            disabled={
                                                stateTest.isEdit ? false : true
                                            }
                                        />
                                    </TableCell>
                                    <TableCell align="center">
                                        <input
                                            type="number"
                                            className="inputNumber font_16_600"
                                            min={0}
                                            defaultValue={category?.max_weight}
                                            name={"max_weight"}
                                            onChange={(event) =>
                                                addNumberOfValue(
                                                    event,
                                                    category.id
                                                )
                                            }
                                            disabled={
                                                stateTest.isEdit ? false : true
                                            }
                                        />
                                    </TableCell>
                                    <TableCell align="center">
                                        <input
                                            type="number"
                                            className="inputNumber font_16_600"
                                            min={0}
                                            name={"priority"}
                                            defaultValue={category?.priority}
                                            onChange={(event) =>
                                                addNumberOfValue(
                                                    event,
                                                    category.id
                                                )
                                            }
                                            disabled={
                                                stateTest.isEdit ? false : true
                                            }
                                        />
                                    </TableCell>
                                    <TableCell align="center">
                                        <input
                                            type="number"
                                            className="inputNumber font_16_600"
                                            min={0}
                                            name={"passing_score"}
                                            defaultValue={
                                                category?.passing_score
                                            }
                                            onChange={(event) =>
                                                addNumberOfValue(
                                                    event,
                                                    category.id
                                                )
                                            }
                                            disabled={
                                                stateTest.isEdit ? false : true
                                            }
                                        />
                                    </TableCell>
                                    <TableCell align="center">
                                        <IconButton
                                            aria-label=""
                                            onClick={(e) => {
                                                getViewQuestions(category);
                                            }}
                                        >
                                            <Link
                                                fontSize={16}
                                                sx={{
                                                    cursor: "pointer",
                                                }}
                                            >
                                                View
                                            </Link>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            );
                        }
                    )
                ) : (
                    <NoInfoAvailable />
                )}
            </TableComponent>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,
    };
};


export default connect(mapStateToProps, null) (BalancingCategory);
