import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Navbar from "../../common/Header/Navbar";
import PageHeader from "../../common/PageHeader";
import HeaderPagination from "../../common/PaginationSection/HeaderPagination";
import UserCard from "./UserCard";
import FooterPagination from "../../common/PaginationSection/FooterPagination";
import NoInfoAvailable from "../../components/NoInfoAvailable";
import UserTableView from "./UserTableView";
import CommonDeleteDialog from "../../components/DialogsBox/CommonDeleteDialog";
import LoadingComponent from "./../../components/Loading/LoadingComponent";
import Footer from "../../common/Footer/Footer";
import {
    getUserList,
    toggleDeletePopup,
    deleteUserById,
    getUserById,
    setSelectedUser,
    changeViewType,
    setListViewLimit,
    setCurrentPage,
    getUserByRole,
    getUserModuleList,
} from "../../store/Action/UserAction";
import { setSelectedCustomer } from "../../store/Action/CustomerAction";
import { ROUTES } from "../../router/RouteList";
import { permissionDenied } from "./../../utils/CommonUtils";
import { pageLimit } from "../../utils/CommonData";
import "../../../assets/styles/Container/CustomerCard.scss";
import "../../../assets/styles/Container/UserCard.scss";
import ButtonComponent from "../../components/ButtonComponent";
import { Translate } from "react-auto-translate";
function ManageUser(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const {
        getUserList,
        usersList,
        toggleDeletePopup,
        deletePopUp,
        deleteUserById,
        getUserById,
        changeViewType,
        viewType,
        userLoggedIn,
        userPagination,
        setListViewLimit,
        setCurrentPage,
        getUserModuleList,
        setSelectedUser,
    } = props;

    const status = 'active';

    const userPermission =
        userLoggedIn?.scope?.length &&
        userLoggedIn?.scope?.find((obj) => obj.prefix === "users")
            ?.role_permissions;

    const [state, setState] = useState({
        isLoading: false,
        filteredData: null,
    });
    const { isLoading, filteredData } = state;
    const [search, setSearch] = useState("");
    useEffect(() => {
        if (location?.state?.roleId) {
            getUserByRole(
                location?.state === "" ? undefined : location?.state?.roleId,
                userPagination?.currentPage,
                userPagination?.listViewLimit,
                search
            );
        } else {
            getUserList(
                location?.state === "" ? undefined : location?.state,
                userPagination?.currentPage,
                userPagination?.listViewLimit,
                search,
                userLoggedIn?.user?.client_id
            );
        }
    }, [getUserList, search, location?.state, location?.pathname, status]);

    useEffect(() => {
        if (usersList) {
            setState((prevState) => ({
                ...prevState,
                filteredData: usersList,
            }));
        }
    }, [usersList, location?.state?.roleId, location.pathname, status]);

    const closeDelete = () => {
        toggleDeletePopup({ open: false });
    };

    const handleEdit = async (user, type) => {
        setState((prevState) => ({ ...prevState, isLoading: true }));
        await getUserById(user?.id, type);
        navigate(
            `${type === "edit" ? ROUTES.EDIT_USER : ROUTES.VIEW_USER}?${
                user?.id
            }`
        );
        setState((prevState) => ({ ...prevState, isLoading: false }));
    };
    const handleDelete = (user) => {
        toggleDeletePopup({ open: true, data: user });
    };

    const handleDeleteAction = async (id) => {
        closeDelete();
        setState((prevState) => ({ ...prevState, isLoading: true }));
        await deleteUserById(id);
        await getUserList(
            location?.state === "" ? undefined : location?.state,
            userPagination?.currentPage,
            userPagination?.listViewLimit,
            search,
            userLoggedIn?.user?.client_id
        );
        setState((prevState) => ({ ...prevState, isLoading: false }));
    };

    const handleSettings = async (user) => {
        await getUserModuleList(user?.id);
        navigate(`${ROUTES.USER_SETTING}/${user?.id}`);
    };

    const handleChangeView = (viewType) => {
        changeViewType(viewType);
    };

    useEffect(() => {
        changeViewType("row");
    }, []);

    const handleSearch = (data) => {
        let newList = filteredData;
        setState((prevState) => ({ ...prevState, isLoading: true }));
        getUserList(
            location?.state === "" ? undefined : location?.state,
            "",
            userPagination?.listViewLimit,
            search,
            userLoggedIn?.user?.client_id
        );

        if (data !== "") {
            setState((prevState) => ({
                ...prevState,
                filteredData: usersList,
                isLoading: false,
            }));
        } else {
            setState((prevState) => ({
                ...prevState,
                filteredData: usersList,
                isLoading: false,
            }));
        }
    };

    const handleViewLimitChange = async (data) => {
        setListViewLimit(data?.target?.value);
        setState((prevState) => ({ ...prevState, isLoading: true }));
        if (location?.state?.roleId) {
            await getUserByRole(
                location?.state === "" ? undefined : location?.state?.roleId,
                userPagination?.currentPage,
                data?.target?.value,
                search
            );
        } else {
            await getUserList(
                location?.state === "" ? undefined : location?.state,
                userPagination?.currentPage,
                data?.target?.value,
                search,
                userLoggedIn?.user?.client_id
            );
        }
        setState((prevState) => ({ ...prevState, isLoading: false }));
    };

    const handlePaginationOnChange = async (_, pageNumber) => {
        setCurrentPage(pageNumber);
        setState((prevState) => ({ ...prevState, isLoading: true }));
        if (location?.state?.roleId) {
            await getUserByRole(
                location?.state === "" ? undefined : location?.state?.roleId,
                pageNumber,
                userPagination?.listViewLimit,
                search
            );
        } else {
            await getUserList(
                location?.state === "" ? undefined : location?.state,
                pageNumber,
                userPagination?.listViewLimit,
                search,
                userLoggedIn?.user?.client_id
            );
        }
        setState((prevState) => ({ ...prevState, isLoading: false }));
    };

    const fetchRoleName = (roleName) => {
        const roleWithSpace = roleName.split(" ");
        const role = roleName.split(`("`);
        if (roleWithSpace?.length <= 4) return role[0];
        else if (roleWithSpace?.length >= 2) {
            const roleWithoutBracket = role[1].split(`")`);
            return roleWithoutBracket[0];
        }
    };

    // useEffect(() => {
    //     handleSearch(search)
    // }, [search])

    const handleAddButton = () => {
        setSelectedUser(null);
        navigate(
            `/user/create?${
                window.location?.href?.split("?")[1] ||
                userLoggedIn?.user?.client_id
            }`
        );
    };

    return (
        <Fragment>
            <Navbar />
            <CommonDeleteDialog
                open={deletePopUp?.open}
                title={`${
                    deletePopUp?.data?.status === "active"
                        ? "Deactivate"
                        : "Activate"
                }  User`}
                cancelButtonAction={closeDelete}
                cancelButtonTitle={"Cancel"}
                submitButtonTitle={
                    deletePopUp?.data?.status === "active"
                        ? "Deactivate"
                        : "Activate"
                }
                content={`${
                    deletePopUp?.data?.status === "active"
                        ? "Deactivating"
                        : "Activating"
                } user will ${
                    deletePopUp?.data?.status === "active"
                        ? "disable"
                        : "enable"
                } the access to the application.`}
                submitButtonAction={handleDeleteAction}
                submitButtonParameter={deletePopUp?.data}
            />

            <Box sx={{ px: { md: 7, xs: 1 }, mb: 6 }}>
                <PageHeader
                    title={
                        <Translate>
                            {userLoggedIn?.language_data?.user?.manage_user ||
                                "Manage User"}
                        </Translate>
                    }
                    showSearchBar={true}
                    handleSearch={handleSearch}
                    setSearch={setSearch}
                    buttonTitle={
                        userLoggedIn?.user?.client_id !== null ||
                        window.location.href?.split("?")[1] ? (
                            <Translate>Add User</Translate>
                        ) : null
                    }
                    handleAdd={handleAddButton}
                />
                <HeaderPagination
                    showViewButton={true}
                    handleChangeView={handleChangeView}
                    total={userPagination?.total}
                    listViewLimit={userPagination?.listViewLimit}
                    handleViewLimitChange={handleViewLimitChange}
                    showPagination={pageLimit < userPagination?.total}
                />
            </Box>

            {!filteredData || isLoading ? (
                <LoadingComponent sx={{ height: "200px !important" }} />
            ) : (
                <Box sx={{ px: { md: 7, xs: 1 }, mb: 6 }}>
                    {filteredData?.length ? (
                        viewType === "card" ? (
                            <Grid
                                container
                                display={"flex"}
                                flexWrap={"wrap"}
                                spacing={2}
                                className="userCardMainDiv"
                            >
                                <Grid item className="userCardDiv">
                                    <Grid
                                        item
                                        display={"flex"}
                                        flexWrap={"wrap"}
                                        justifyContent={"center"}
                                        gap={1}
                                    >
                                        {filteredData.map((user) => (
                                            <UserCard
                                                user={user}
                                                key={user.id}
                                                getUserById={getUserById}
                                                handleDelete={handleDelete}
                                                handleEdit={handleEdit}
                                                handleSettings={handleSettings}
                                                userPermission={userPermission}
                                                permissionDenied={
                                                    permissionDenied
                                                }
                                                fetchRoleName={fetchRoleName}
                                            />
                                        ))}
                                    </Grid>
                                </Grid>
                            </Grid>
                        ) : (
                            <UserTableView
                                display={"flex"}
                                flexWrap={"wrap"}
                                justifyContent={"center"}
                                usersList={filteredData}
                                handleDelete={handleDelete}
                                handleEdit={handleEdit}
                                handleSettings={handleSettings}
                                userPermission={userPermission}
                                permissionDenied={permissionDenied}
                                fetchRoleName={fetchRoleName}
                            />
                        )
                    ) : (
                        <NoInfoAvailable
                            className="noCustomerInfo"
                            noInfoTypo="noInfoTypoCustomer"
                        />
                    )}

                    {filteredData?.length > 0 && (
                        <>
                            {pageLimit < userPagination?.total ? (
                                <FooterPagination
                                    count={userPagination?.lastPage}
                                    page={userPagination?.currentPage}
                                    onChange={handlePaginationOnChange}
                                    defaultPage={userPagination?.currentPage}
                                />
                            ) : null}
                        </>
                    )}
                </Box>
            )}
            <Footer />
        </Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        usersList: state.user.usersList,
        deletePopUp: state?.user?.deletePopUp,
        viewType: state?.user?.viewType,
        userLoggedIn: state?.auth?.userLoggedIn,
        userPagination: state?.user?.userPagination,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getUserList,
            toggleDeletePopup,
            deleteUserById,
            getUserById,
            setSelectedUser,
            setSelectedCustomer,
            changeViewType,
            setListViewLimit,
            setCurrentPage,
            getUserModuleList,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageUser);
