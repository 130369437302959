import React from "react";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import ButtonComponent from "../../components/ButtonComponent";
import DropDownComponent from "../../components/DropDown/DropDownComponent";
import "../../../assets/styles/Container/PageHeader.scss";

const HeaderPagination = (props) => {
    const {
        showViewButton,
        handleChangeView,
        oneLine,
        handleViewLimitChange,
        total,
        value,
        listViewLimit,
        showPagination,
    } = props;

    const [anchorEl, setAnchorEl] = React.useState(null);

    const open = Boolean(anchorEl);
    const openViewMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseViewMenu = () => {
        setAnchorEl(null);
    };
    const selectViewMenu = (viewType) => {
        handleChangeView(viewType);
        setAnchorEl(null);
    };

    return (
        <Box className="paginationGrid">
            <Grid
                container
                gap={2}
                display={"flex"}
                justifyContent={"flex-end"}
                alignItems={"center"}
            >
                {showPagination ? (
                    <>
                        <Grid
                            item
                            xs={oneLine ? 2 : 1}
                            display={"flex"}
                            justifyContent={"flex-end"}
                            alignItems={"center"}
                        >
                            <Typography>Showing</Typography>
                        </Grid>
                        <Grid
                            item
                            xs={oneLine ? 2 : 1}
                            display={"flex"}
                            justifyContent={"flex-end"}
                        >
                            <DropDownComponent
                                onChange={handleViewLimitChange}
                                fullWidth={true}
                                variant={"outlined"}
                                defaultValue={listViewLimit}
                                value={value}
                            >
                                {[8, 12, 16, 20, 24].map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </DropDownComponent>
                        </Grid>
                        <Grid
                            item
                            xs={oneLine ? 2 : 1}
                            display={"flex"}
                            justifyContent={"flex-end"}
                            alignItems={"center"}
                        >
                            <Typography>
                                of {total ? total : "0"} entries
                            </Typography>
                        </Grid>
                    </>
                ) : null}
                {showViewButton ? (
                    total === 0 ? (
                        ""
                    ) : (
                        <>
                            <ButtonComponent
                                onClick={openViewMenu}
                                className="button_outline"
                                sx={{ ml: 3 }}
                                startIcon={<GridViewOutlinedIcon />}
                                title={"View"}
                            />
                            <Menu
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleCloseViewMenu}
                                className="menuOnViewButton"
                            >
                                <MenuItem
                                    onClick={() => selectViewMenu("card")}
                                    value={1}
                                >
                                    Card
                                </MenuItem>
                                <MenuItem
                                    onClick={() => selectViewMenu("table")}
                                    value={0}
                                >
                                    Table
                                </MenuItem>
                            </Menu>
                        </>
                    )
                ) : null}
            </Grid>
        </Box>
    );
};

export default HeaderPagination;
