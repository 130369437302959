import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextFieldComponent from "../../../components/TextField/TextFieldComponent";
import { connect } from "react-redux";
const Phone = (props) => {
    const {
        state,
        setState,
        data,
        children,
        handleChange,
        isRead,
        handlerFieldChange,
        isEdit,
        userLoggedIn
    } = props;

    const handlerIsEdit = () => {
        if (isEdit) {
            setState((prevState) => ({ ...prevState, editLabel: true }));
        }
    };

    const onBlur = () => {
        setState((prevState) => ({ ...prevState, editLabel: false }));
    };

    return (
        <Grid container>
            <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "space-between" }}
            >
                <TextFieldComponent
                    sx={{
                        display:
                            state?.selectedEle?.selected && state?.editLabel
                                ? "block"
                                : "none",
                        color: state?.fontColor,
                        "& .MuiInputBase-input": {
                            color: state?.fontColor,
                        },
                    }}
                    onBlur={onBlur}
                    onChange={handleChange}
                    value={data?.label}
                    className="elementHeadingTextField"
                    autoFocus={true}
                />
                <Typography
                    display={
                        state?.selectedEle?.selected && state?.editLabel
                            ? "none"
                            : "block"
                    }
                    className="elementHeading"
                    variant="h5"
                    onClick={handlerIsEdit}
                    sx={{ color: state?.fontColor }}
                >
                    {data?.label}
                </Typography>
                {children}
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextFieldComponent
                        label={userLoggedIn?.language_data?.customer?.mobile_number || "Mobile Number"}
                        className="textFieldFromBuilder"
                        disabled={isRead}
                        onChange={(event) =>
                            handlerFieldChange(event, data?.id)
                        }
                        name={"mobileNumber"}
                        value={data?.formValues?.mobileNumber}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextFieldComponent
                        label={userLoggedIn?.language_data?.customer?.email_address || "Email Address"}
                        className="textFieldFromBuilder"
                        disabled={isRead}
                        onChange={(event) =>
                            handlerFieldChange(event, data?.id)
                        }
                        name={"email"}
                        value={data?.formValues?.email}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextFieldComponent
                        label={userLoggedIn?.language_data?.customer?.work_phone || "Work Phone"}
                        className="textFieldFromBuilder"
                        disabled={isRead}
                        onChange={(event) =>
                            handlerFieldChange(event, data?.id)
                        }
                        name={"workPhone"}
                        value={data?.formValues?.workPhone}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextFieldComponent
                        label={userLoggedIn?.language_data?.customer?.fax || "Fax"}
                        className="textFieldFromBuilder"
                        disabled={isRead}
                        onChange={(event) =>
                            handlerFieldChange(event, data?.id)
                        }
                        name={"faxNumber"}
                        value={data?.formValues?.faxNumber}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,
        
    };
};

export default connect(mapStateToProps, null) (Phone);
