import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useNavigate } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import Navbar from "../../common/Header/Navbar";
import PageHeader from "../../common/PageHeader";
import TableComponent from "../../components/Table/TableComponent";
import NoInfoAvailable from "../../components/NoInfoAvailable";
import CommonDeleteDialog from "./../../components/DialogsBox/CommonDeleteDialog";
import FooterPagination from "../../common/PaginationSection/FooterPagination";
import TooltipComponent from "../../components/ToolTip/TooltipComponent";
import LoadingComponent from "../../components/Loading/LoadingComponent";
import { ReactComponent as DeleteIcon } from "../../../assets/Images/Icons/DeleteIcon.svg";
import { ReactComponent as EditIcon } from "../../../assets/Images/Icons/EditIcon.svg";
import { getContractsContentAction } from "../../store/Action/ContractAction";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

import {
    getContractInUsActionList,
    addContractInUsAction,
    deleteSignedDocument,
    getSignedDocumentData,
    getSignedDocumentById,
    setCurrentPage,
    setCustomerPageLimit,
    getCategoryDropdown,
    addTrainingLib,
    editTrainingLib,
    deleteTrainingLib,
    getTrainingLib,
} from "../../store/Action/SignedDocumentAction";
import { ROUTES } from "./../../router/RouteList";
import { setSelectedDetails } from "../../store/Action/SignedDocumentAction";
import { permissionDenied } from "../../utils/CommonUtils";
import HeaderPagination from "../../common/PaginationSection/HeaderPagination";
import "../../../assets/styles/Container/SignedDocument.scss";
import { pageLimit } from "../../utils/CommonData";
import TextFieldComponent from "../../components/TextField/TextFieldComponent";


function TrainingLibrary(props) {
    const navigate = useNavigate();
    const {
        deleteSignedDocument,
        getSignedDocumentData,
        signedDocuments,
        dataTrainingLib,
        getSignedDocumentById,
        userLoggedIn,
        signedDocPagination,
        setCurrentPage,
        setCustomerPageLimit,
        getCategoryDropdown,
        addTrainingLib,
        editTrainingLib,
        deleteTrainingLib,
        getTrainingLib,
    } = props;

    const [state, setState] = useState({
        openDeleteDialog: false,
        deleteData: null,
        openAddDialog: false,
        openSuccesfullModel: false,
        modelContent: "",
        id: null,
        address: { state: "" },
        isLoading: false,
        filteredData: [],
        sortConfig: { key: null, direction: "asc" },
    });
    const [search, setSearch] = useState([])
    const [lib, setLib] = useState({
        'title': '',
        'url': '',
        'domain': '',
    });

    const [editValue, setEditValue] = useState({
        data: [],
        mode: "add"
    })


    const {
        openDeleteDialog,
        deleteData,
        isLoading,
        filteredData,
        sortConfig,
    } = state;


    const permission =
        userLoggedIn?.scope?.length &&
        userLoggedIn?.scope?.find((obj) => obj.prefix === "signed-document")
            ?.role_permissions;

    useEffect(() => {
        setState((prevState) => ({ ...prevState, isLoading: true }));
        const callAPIGet = async () => {
            await getTrainingLib();
        }
        callAPIGet();
    }, []);

    // Load the function
    const stateChange = () => {
        setState((prevState) => ({
            ...prevState,
            filteredData: dataTrainingLib?.result ,
            isLoading: false
        }));
    }

    useEffect(() => {
        stateChange();
    }, [dataTrainingLib]);

    const closeDelete = () => {
        setState((prevState) => ({ ...prevState, openDeleteDialog: false }));
    };
    const handleDelete = (data) => {
        setState((prevState) => ({
            ...prevState,
            deleteData: data,
            openDeleteDialog: true,
        }));
    };
    const handleDeleteAction = async (data) => {
        closeDelete();
        await deleteTrainingLib(data);
        getTrainingLib();
        // setState((prevState) => ({ ...prevState, isLoading: true }));
        // setState((prevState) => ({ ...prevState, isLoading: false }));
    };
    const handleClickOpen = async () => {
        // setState((prevState) => ({ ...prevState, isLoading: true }));
        await getSignedDocumentById(null);
        // setState((prevState) => ({ ...prevState, isLoading: false }));
        navigate(ROUTES.ADD_SIGNED_DOCUMENT);
    };
    async function signedDocumentView(data, mode) {
        // setState((prevState) => ({ ...prevState, isLoading: true }));
        await getSignedDocumentById(data?.id, mode);
        // setState((prevState) => ({ ...prevState, isLoading: false }));
        navigate(`${ROUTES.VIEW_SIGNED_DOCUMENT}?${data?.id}`, {
            state: { ...data, mode: mode },
        });
    }

    // UPDATE FUNCTION
    async function signedDocumentEdit(data, mode) {
        handleClickOpenDilogAdd(data, mode)
    }


    const renderClassStatus = (obj, row) => {
        if (obj?.rowClass) return obj?.rowClass;
        else if (row?.status_name === "Approved") return "cellApproved";
        else if (
            row?.status_name?.replace(/\s/g, "") === "PendingforApproval" ||
            row?.status_name === "Others"
        )
            return "cellPending";
        else if (
            row?.status_name?.replace(/\s/g, "") === "OnHold" ||
            row?.status_name === "Rejected" ||
            row?.status_name === "Reassigned"
        )
            return "cellOnHold";
    };

    const handleSort = (property) => {
        let sortedData = [...filteredData].sort((a, b) => {
            if (property !== "created_at") {
                return sortConfig.direction === "desc"
                    ? a?.[property]?.toLowerCase() <
                      b?.[property]?.toLowerCase()
                        ? 1
                        : b?.[property]?.toLowerCase() <
                          a?.[property]?.toLowerCase()
                        ? -1
                        : 0
                    : a?.[property]?.toLowerCase() >
                      b?.[property]?.toLowerCase()
                    ? 1
                    : b?.[property]?.toLowerCase() >
                      a?.[property]?.toLowerCase()
                    ? -1
                    : 0;
            } else {
                return sortConfig.direction === "desc"
                    ? new Date(a?.[property]) - new Date(b?.[property])
                    : new Date(b?.[property]) - new Date(a?.[property]);
            }
        });
        setState((prevState) => ({
            ...prevState,
            filteredData: sortedData,
            sortConfig: {
                direction: sortConfig.direction === "desc" ? "asc" : "desc",
            },
        }));
    };

    const headerList = [
        {
            id: 1,
            name: "Title",
            headerClass: "tableHeading",
            accessibleKey: "title",
            rowClass: "nameFile",
            headerAlign: "center",
            rowAlign: "center",
            sort: true,
            sortFunction: handleSort,
        },
        {
            id: 2,
            name: "Domain",
            headerClass: "tableHeading",
            accessibleKey: "domain",
            rowClass: "tableBodyRow",
            headerAlign: "center",
            rowAlign: "center",
            sort: false,
        },
        {
            id: 3,
            name: "URL",
            headerClass: "tableHeading Assign ToTableHeading",
            accessibleKey: "url",
            rowClass: "companyName",
            headerAlign: "center",
            rowAlign: "center",
            sort: false,
        },
        // {
        //     id: 4,
        //     name: "Status",
        //     headerClass: "tableHeading",
        //     accessibleKey: "status_name",
        //     headerAlign: "center",
        //     rowAlign: "center",
        //     sort: true,
        //     sortFunction: handleSort,
        // },
        {
            id: 6,
            name: "Time Stamp",
            headerClass: "tableHeading noOfUserTableHeading",
            accessibleKey: "created_at",
            rowClass: "stampTime",
            headerAlign: "center",
            rowAlign: "center",
            sort: true,
            sortFunction: handleSort,
        },
    ];

    function handleSearch(data) {
        getSignedDocumentData(
            null,
            signedDocPagination?.listViewLimit,
            true,
            search)
            setState((prevState) => ({
                ...prevState,
                filteredData: signedDocuments,
                search
            }));
    }

    const handleViewLimitChange = async (data) => {
        setCustomerPageLimit(data?.target?.value);
        // setState((prevState) => ({ ...prevState, isLoading: true }));
        await getSignedDocumentData(null, data?.target?.value, true, search);
        // setState((prevState) => ({ ...prevState, isLoading: false }));
    };

    const handlePaginationOnChange = async (_, pageNumber) => {
        setCurrentPage(pageNumber);
        // setState((prevState) => ({ ...prevState, isLoading: true }));
        await getSignedDocumentData(
            pageNumber,
            signedDocPagination?.listViewLimit,
            true,
            search
        );
        setState((prevState) => ({ ...prevState, isLoading: false }));
    };

    const [open, setOpen] = useState(false);

    const handleClickOpenDilogAdd = (data, mode) => {
        setEditValue({
            data, 
            mode
        });

        if(mode == "edit") {
            if(data) {
                setLib({
                    'title': data?.title,
                    'url': data?.url,
                    'domain': data?.domain,
                    'id': data?.id
                })
            }
        } else {
            setLib({
                'title': '',
                'url': '',
                'domain': '',
            })
        }
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const toggle = editValue.mode;
        if(toggle == "add") {
            await addTrainingLib(lib);
        } else {
            await editTrainingLib(lib);
        }

        getTrainingLib();
        setOpen(false);
    }

    
    return (
        <>
            <Navbar />
            <div>
                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>Add/Edit</DialogTitle>
                    <DialogContent>
                    <DialogContentText>
                        
                    <Box>
                        <Grid container className="headerGrid">
                            <Grid mt={8} item xs={4} md={4} lg={4} display="flex">
                                <Typography className="font_18_400">Title</Typography>
                            </Grid>
                            <Grid item mt={5} xs={8} md={8} lg={8} className="searchBar">
                                <TextFieldComponent
                                    id="title"
                                    name={"title"}
                                    value={lib?.title || ""}
                                    onChange={(event) => 
                                        setLib((prevState) => ({ ...prevState, title: event?.target?.value }))
                                    }
                                />
                            </Grid>
                            
                            <Grid item mt={8} xs={4} md={4} lg={4} display="flex">
                                <Typography className="font_18_400">Source URL</Typography>
                            </Grid>
                            <Grid item mt={5} xs={8} md={8} lg={8} className="searchBar">
                                <TextFieldComponent
                                    id="url"
                                    name={"url"}
                                    value={lib?.url || ""}
                                    onChange={(event) => 
                                        setLib((prevState) => ({ ...prevState, url: event?.target?.value }))
                                    }
                                />
                            </Grid>
                            
                            <Grid item mt={8} xs={4} md={4} lg={4} display="flex">
                                <Typography className="font_18_400">Domain</Typography>
                            </Grid>
                            <Grid item mt={5} xs={8} md={8} lg={8} className="searchBar">
                                <TextFieldComponent
                                    id="domain"
                                    name={"domain"}
                                    value={lib?.domain || ""}
                                    onChange={(event) => 
                                        setLib((prevState) => ({ ...prevState, domain: event?.target?.value }))
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} md={12} lg={12} className="searchBar">
                                
                            </Grid>
                        </Grid>
                    </Box>

                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={handleSubmit} className="addButton" variant={"contained"} color="primary">
                        Submit
                    </Button>
                    <Button onClick={handleClose} className="addButton" variant={"contained"} color="primary">
                        Close
                    </Button>
                    </DialogActions>
                </Dialog>
            </div>

            <CommonDeleteDialog
                open={openDeleteDialog}
                title={"Delete"}
                cancelButtonAction={closeDelete}
                cancelButtonTitle="Cancel"
                submitButtonTitle="Delete"
                content={`Are you sure, you want to remove the (${deleteData?.title}) from the list ?`}
                submitButtonAction={handleDeleteAction}
                submitButtonParameter={deleteData}
            />
            {!filteredData || isLoading ? (
                <LoadingComponent /> 
            ) : (
                <Box sx={{ px: { md: 7, xs: 1, mt: "40px" } }}>
                    <PageHeader
                        title={"Training Library"}
                        showSearchBar={
                             false
                        }
                        buttonTitle={"Training Library Add"}
                        handleAdd={() => handleClickOpenDilogAdd([], 'add')}
                        handleSearch={handleSearch}
                        setSearch={setSearch}
                    />
                    <HeaderPagination
                        showViewButton={false}
                        total={signedDocPagination?.total}
                        listViewLimit={signedDocPagination?.listViewLimit}
                        handleViewLimitChange={handleViewLimitChange}
                        showPagination={pageLimit < signedDocPagination?.total}
                    />
                    {filteredData?.length === 0 ? (
                        <NoInfoAvailable
                            className="noCustomerInfo"
                            noInfoTypo="noInfoTypeQuestion"
                        />
                    ) : (
                        <TableComponent
                            headerList={headerList}
                            serialNo={{
                                name: "S.No.",
                                headerClass: "tableHeading",
                                headerAlign: "center",
                                rowClass: "typo4",
                                rowAlign: "center",
                            }}
                            sortConfig={sortConfig}
                            extraHeaderRight={
                                <TableCell
                                    align="center"
                                    className="tableHeading actionTableHeading"
                                >
                                    Action
                                </TableCell>
                            }
                        >
                            {filteredData?.length > 0 &&
                                filteredData?.map((row, index) => (
                                    <TableRow
                                        key={row?.name}
                                        className={
                                            index % 2 !== 0
                                                ? "tertiaryBackground"
                                                : ""
                                        }
                                    >
                                        <TableCell
                                            align="center"
                                            className="typo4"
                                        >
                                            {index + 1}
                                        </TableCell>
                                        {headerList?.map((obj) => (
                                            <TableCell
                                                component="th"
                                                key={obj?.name}
                                                align={obj?.rowAlign}
                                                className={renderClassStatus(
                                                    obj,
                                                    row
                                                )}
                                            >
                                                {obj?.id === 5 &&
                                                !row?.approved_by
                                                    ? "Not Assigned"
                                                    : row[obj?.accessibleKey]}
                                            </TableCell>
                                        ))}
                                        <TableCell sx={{ textAlign: "center" }}>
                                            {row?.action}
                                            {/* <TooltipComponent
                                                title="View Signed Document"
                                                arrow={true}
                                            >
                                                <IconButton
                                                    className="iconButton"
                                                    onClick={() =>
                                                        permission?.read
                                                            ? signedDocumentView(
                                                                  row,
                                                                  "view"
                                                              )
                                                            : permissionDenied()
                                                    }
                                                >
                                                    <RemoveRedEyeOutlinedIcon />
                                                </IconButton>
                                            </TooltipComponent> */}
                                            <TooltipComponent
                                                title="Edit Signed Document"
                                                arrow={true}
                                            >
                                                <IconButton
                                                    className="iconButton"
                                                    onClick={() =>
                                                        permission?.edit
                                                            ? signedDocumentEdit(
                                                                  row,
                                                                  "edit"
                                                              )
                                                            : permissionDenied()
                                                    }
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                            </TooltipComponent>
                                            <TooltipComponent
                                                title="Delete Signed Doc"
                                                arrow={true}
                                            >
                                                <IconButton
                                                    className="iconButton"
                                                    onClick={() =>
                                                        permission?.delete
                                                            ? handleDelete(row)
                                                            : permissionDenied()
                                                    }
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </TooltipComponent>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableComponent>
                    )}
                    {pageLimit < signedDocPagination?.total ? (
                        <FooterPagination
                            count={signedDocPagination?.lastPage}
                            page={signedDocPagination?.currentPage}
                            onChange={handlePaginationOnChange}
                            defaultPage={signedDocPagination?.currentPage}
                        />
                    ) : null}
                </Box>
            )}
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        customerDropdown: state?.auth?.commonDropdown?.states,
        signedDocuments: state?.signedDocs?.signedDocuments,
        signedDocPagination: state?.signedDocs?.signedDocPagination,
        userLoggedIn: state?.auth?.userLoggedIn,
        dataTrainingLib: state?.signedDocs?.trainingLib,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            addContractInUsAction,
            getContractInUsActionList,
            deleteSignedDocument,
            getContractsContentAction,
            getSignedDocumentData,
            setSelectedDetails,
            getSignedDocumentById,
            setCurrentPage,
            setCustomerPageLimit,
            getCategoryDropdown,
            addTrainingLib,
            editTrainingLib,
            deleteTrainingLib,
            getTrainingLib,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(TrainingLibrary);
