import React, { useState, useEffect } from 'react'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Box, Grid, Typography } from "@mui/material"
import {
  workflow_heading1_text,
  workflow_heading2_text,
} from "./Style";
import { rcpTasksAction } from "../../../store/Action/RcpTasksAction";

function RcpTasks(props) {
  const { rcpTasksAction,guid,contractor_id,tasks,toggale} = props
  const [taskList, setTasks] = useState([])

  useEffect(() => {
    if(guid !==''){
      getTasks(guid,contractor_id);
    }
    // eslint-disable-next-line
  }, [taskList?.length,toggale])

  async function getTasks(guid,contractor_id) {
    try{
      const res = await rcpTasksAction(guid,contractor_id);
      setTasks(res?.payload)
    }catch(error){
      throw error
    }
  }
  return (
    <>
      <Box
      className='workflowTaskSideBar'
      height='550px'
      >
        <Grid container direction={'column'} sx={{ paddingX: '10px', pb: '20px' }} >
          <Grid item md={12} sm={12}>
            <Typography sx={workflow_heading1_text} >Workflow</Typography>
          </Grid>
          <Grid item md={3} sm={3}>
              { tasks===null? <Typography ml={2} mt={2}>Loading....</Typography> : <Grid container direction={'row'} spacing={1}>
              {taskList?.length === 0 ? <Typography ml={2} mt={2}>Task not available</Typography> : ""}
              {taskList?.map((task) => {
                const cl = task.status === 'Completed' ? '#0B9611' : task.status === 'In Progress' ? '#FFB344 ': '#E05D5D';
                return < >
                  <Grid item md={7} sm={7} key={task.task_master_id} ><Typography sx={{ ...workflow_heading2_text }} >{task.task}</Typography></Grid>
                  <Grid item md={5} sm={5}  key={task.task_master_id + 2} >
                  <Typography key={task.task_master_id + 3} sx={{ ...workflow_heading2_text, color: cl, fontSize: '16px' }}>{task.status}</Typography></Grid>
                </>
              })}
            </Grid>}
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    tasks: state.tasks,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      rcpTasksAction,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(RcpTasks);
