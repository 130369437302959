import React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import PersonOffOutlinedIcon from "@mui/icons-material/PersonOffOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import TooltipComponent from "../../components/ToolTip/TooltipComponent";
import { connect } from "react-redux";
import {Translate } from 'react-auto-translate';
function RoleCardView(props) {
    const { roleList, handleDelete, handleView, handlerGetUsers,userLoggedIn } = props;

    return (
        <>
        {roleList?.length
            ? roleList.map((role) => {
                  return (
                      <Grid
                          item
                          xs={6}
                          lg={3}
                          md={3}
                          paddingX={1}
                          paddingY={2}
                          key={role.id} // Add a unique key here if "role.id" or something similar is available
                      >
                          <Card
                              className="cardStyle"
                              sx={{
                                  minWidth: { md: "100%", xs: "auto" },
                                  maxWidth: { md: "100%", xs: "auto" },
                              }}
                          >
                              <CardHeader
                                  className="roleCardHeader"
                                  sx={{
                                      "& .MuiCardHeader-content": {
                                          whiteSpace: "nowrap",
                                          textOverflow: "ellipsis",
                                          overflow: "hidden",
                                      },
                                  }}
                                  title={
                                      <Typography
                                          sx={{
                                              fontSize: 16,
                                              textTransform: "capitalize",
                                              textOverflow: "ellipsis",
                                              overflow: "hidden",
                                          }}
                                          color="white"
                                      >
                                          <Translate>{role?.name}</Translate>
                                      </Typography>
                                  }
                              ></CardHeader>
                              <CardContent sx={{ paddingBottom: "10px" }}>
                                  <Typography
                                      className="cardContentText"
                                      gutterBottom
                                  >
                                      <Translate>{role?.description}</Translate>
                                  </Typography>
                                  <Typography
                                      sx={{ cursor: "pointer" }}
                                      className="font_14_600"
                                      onClick={() => handlerGetUsers(role)}
                                  >
                                      <Translate>
                                          {userLoggedIn?.language_data?.role?.user_count ||
                                              "No. of Users"}{" "}
                                      </Translate>
                                      : {role.users_count}
                                  </Typography>
                              </CardContent>
                              <CardActions
                                  textAlign={"center"}
                                  display={"flex"}
                                  sx={{ justifyContent: "center" }}
                              >
                                  <TooltipComponent
                                      arrow={true}
                                      title={
                                          <Translate>
                                              {userLoggedIn?.language_data?.role?.view_role ||
                                                  "View Role"}
                                          </Translate>
                                      }
                                  >
                                      <IconButton
                                          aria-label="add to favorites"
                                          onClick={() => handleView(role, "view")}
                                      >
                                          <RemoveRedEyeOutlinedIcon
                                              sx={{ color: "#3B3B3B" }}
                                          />
                                      </IconButton>
                                  </TooltipComponent>
                                  <TooltipComponent
                                      arrow={true}
                                      title={
                                          <Translate>
                                              {userLoggedIn?.language_data?.role?.edit_role ||
                                                  "Edit Role"}
                                          </Translate>
                                      }
                                  >
                                      <IconButton
                                          aria-label="add to favorites"
                                          onClick={() => handleView(role, "edit")}
                                      >
                                          <ModeEditOutlineOutlinedIcon
                                              sx={{ color: "#3B3B3B" }}
                                          />
                                      </IconButton>
                                  </TooltipComponent>
                                  {role?.status !== "active" ? (
                                      <TooltipComponent
                                          arrow={true}
                                          title={<Translate>Activate Role</Translate>}
                                      >
                                          <IconButton
                                              className="iconButton"
                                              onClick={() => handleDelete(role)}
                                          >
                                              <ManageAccountsOutlinedIcon />
                                          </IconButton>
                                      </TooltipComponent>
                                  ) : (
                                      <TooltipComponent
                                          arrow={true}
                                          title={
                                              <Translate>
                                                  {userLoggedIn?.language_data?.role?.deactivate_role ||
                                                      "Deactivate Role"}
                                              </Translate>
                                          }
                                      >
                                          <IconButton
                                              className="iconButton"
                                              onClick={() => handleDelete(role)}
                                          >
                                              <PersonOffOutlinedIcon />
                                          </IconButton>
                                      </TooltipComponent>
                                  )}
                              </CardActions>
                          </Card>
                      </Grid>
                  );
              })
            : null}
    </>
    
    );
}

const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,
    };
};

export default connect(mapStateToProps, null) (RoleCardView);
