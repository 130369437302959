import {
    getApiCall,
    postApiCall,
    deleteApiCall,
    putApiCall,
} from "../../utils/Action";
import { toast } from "react-hot-toast";
import { API_CONSTANTS } from "../../config/ApiConstants";

export const GET_SELF_CONFIGURATION_DETAILS = "GET_SELF_CONFIGURATION_DETAILS";
export const GET_SELF_CONFIGURATION_CREATED_LIST =
    "GET_SELF_CONFIGURATION_CREATED_LIST";
export const SET_TEST_OPTION = "SET_TEST_OPTION";
export const SAVE_SELF_CONFIGURATION_LIST = "SAVE_SELF_CONFIGURATION_LIST";
export const EDIT_SELF_CONFIGURATION_TEST = "EDIT_SELF_CONFIGURATION_TEST";

export const getSelfConfigrationList = (guid) => {
    return async (dispatch) => {
        try {
            const response = await getApiCall(
                API_CONSTANTS.SELF_CONFIGURATION_SETTING,
                {
                    guid: guid,
                }
            );
            if (response?.status === 200) {
                return dispatch({
                    type: GET_SELF_CONFIGURATION_DETAILS,
                    payload: response?.data?.result || [],
                });
            } else {
            }
        } catch (error) {
            throw error;
        }
    };
};

export const setTestOption = (data) => {
    return async (dispatch) => {
        try {
            return dispatch({
                type: SET_TEST_OPTION,
                payload: data || [],
            });
        } catch (error) {
            throw error;
        }
    };
};

export const getSelfConfigrationCreatedList = (guid) => {
    return async (dispatch) => {
        try {
            const response = await getApiCall(
                API_CONSTANTS.SELF_CONFIGURATION_SETTING_LIST,
                {
                    guid: guid,
                }
            );
            if (response?.status === 200) {
                return dispatch({
                    type: GET_SELF_CONFIGURATION_CREATED_LIST,
                    payload: response?.data?.result,
                });
            } else if (response?.status === 404) {
                return dispatch({
                    type: GET_SELF_CONFIGURATION_CREATED_LIST,
                    payload: [],
                });
            } else {
                // toast.error(response?.data?.message);
            }
        } catch (error) {
            throw error;
        }
    };
};

export const saveSelfConfigration = (data) => {
    return async (dispatch) => {
        try {
            const response = await postApiCall(
                API_CONSTANTS.SELF_CONFIGURATION_SETTING,
                data
            );

            if (response?.status === 201) {
                return dispatch({
                    type: GET_SELF_CONFIGURATION_CREATED_LIST,
                    payload: response?.data?.result || [],
                });
            } else {
                // toast.error(response?.data?.message);
            }
        } catch (error) {
            throw error;
        }
    };
};
export const editSelfConfigrationTest = (data) => {
    return async (dispatch) => {
        try {
            const response = await putApiCall(
                API_CONSTANTS.SELF_CONFIGURATION_SETTING + "/" + data.id,
                data
            );
            if (response?.status == 201) {
                return dispatch({
                    type: EDIT_SELF_CONFIGURATION_TEST,
                    payload: response?.data?.result || null,
                });
            } else {
                // toast.error(response?.message);
            }
        } catch (error) {
            throw error;
        }
    };
};

export const deleteSelfConfigTest = (guid, id) => {
    return async (dispatch) => {
        try {
            const response = await deleteApiCall(
                API_CONSTANTS.SELF_CONFIGURATION_SETTING + `/${guid}`,
                { id: id }
            );
            if (response?.status === 200) {
                return dispatch({
                    type: "",
                    payload: [],
                });
            }
        } catch (error) {
            throw error;
        }
    };
};
