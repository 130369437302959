import React, { useEffect, useState } from "react";
import { ROUTES } from "../router/RouteList";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import "../../assets/styles/Container/CustomerOfficePageHeader.scss";
import { directIndirectOptions } from "../utils/CommonData";
import { useNavigate, Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getCustomerById } from "../store/Action/CustomerAction";
import { getUserById } from "../store/Action/UserAction";
import {Translate } from 'react-auto-translate';

const CustomerOfficePageHeader = (props) => {
    const { users, service, userLoggedIn } = props;

    const navigate = useNavigate();

    const redirectToViewCustomoer = async () => {
        await getCustomerById(users?.id, 'view');
        navigate(`${ROUTES.VIEW_CLIENT}?${users?.id}`);
    }

    const conducterType = directIndirectOptions.find(option => option.id === users?.client_type)
    return (
        <React.Fragment>
  <Grid container className="headerGrid">
    <Grid item>
      <Typography className="font_24_600" letterSpacing={1}>
        <Translate>Customer Office</Translate>
      </Typography>
    </Grid>
    <Grid>
      <Link className="linkResponse" to={'/compliance-workflow'}>
        <Translate>Back to Compliance WorkFlow</Translate>
      </Link>
    </Grid>
  </Grid>
  <Box className="my-20">
    <Grid container spacing={0}>
      <Grid item sm={12} md={2} lg={2}>
        <Typography className="font_18_600 textLabel" variant="span">
          <Translate>{userLoggedIn?.language_data?.complianceworkflow?.conductor_type || "Conductor Type"}</Translate>:
        </Typography>
      </Grid>
      <Grid item sm={12} md={4} lg={4}>
        <Typography className="font_20_600 textValue" variant="span">
          <Translate>{conducterType?.label}</Translate>
        </Typography>
      </Grid>
      <Grid item sm={12} md={2} lg={2}>
        <Typography className="font_18_600 textLabel" variant="span">
          <Translate>{userLoggedIn?.language_data?.comman?.created_by || "Created By"}</Translate>:
        </Typography>
      </Grid>
      <Grid item sm={12} md={4} lg={4}>
        <Typography
          className="font_20_600 text-decoration-underline textValue"
          variant="span"
          sx={{ cursor: 'pointer' }}
          onClick={redirectToViewCustomoer}
        >
          <Translate>{users?.display_name}</Translate>
        </Typography>
        <Typography className="font_18_600 ms-15 textValue" variant="span">
          <Translate>{users?.updated_at}</Translate>
        </Typography>
      </Grid>
    </Grid>
  </Box>
  <Box className="my-20">
    <Grid container spacing={0}>
      <Grid item sm={12} md={2} lg={2}>
        <Typography className="font_18_600 textLabel" variant="span">
          <Translate>Compliance Services</Translate>:
        </Typography>
      </Grid>
      <Grid item sm={12} md={4} lg={4}>
        <Typography className="font_20_600 textValue" variant="span">
          <Translate>{service?.label}</Translate>
        </Typography>
      </Grid>
      <Grid item sm={12} md={2} lg={2}>
        <Typography className="font_18_600 textLabel" variant="span">
          <Translate>Invoice</Translate>:
        </Typography>
      </Grid>
      <Grid item sm={12} md={4} lg={4}>
        <Typography className="font_20_600 text-decoration-underline textValue">
          <Translate>View/Edit</Translate>
        </Typography>
      </Grid>
    </Grid>
  </Box>
</React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,
       
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ getCustomerById, getUserById}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerOfficePageHeader);
