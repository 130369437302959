import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import AccordionWithCheckBox from "../../../../../components/AccordionWithCheckBox/AccordionWithCheckBox";
import AgreementCommon from "./AgreementCommon";
import { bindActionCreators } from "redux";
import ButtonComponent from "../../../../../components/ButtonComponent";
import {
    saveTestLawDeatils,
    editTestLawDetails,
} from "../../../../../store/Action/TestLawSettingAction";

const TestLawSettings = (props) => {
    const {
        settings,
        setStateMainAgreement,
        stateMainAgreement,
        guid,
        saveTestLawDeatils,
        editTestLawDetails,
        modules,
    } = props;
    const [testLawSettings, setTestLawSettings] = useState([]);

    useEffect(() => {
        if (settings)
            setTestLawSettings([
                ...settings.filter((item) => item.type === "I"),
            ]);
    }, [settings, stateMainAgreement.testLawDetails?.id]);

    function onHandlerCheckBox(event, testLaw) {
        if (event.target.checked) {
            if (stateMainAgreement?.testLawDetails?.settings?.length) {
                setStateMainAgreement((prevState) => ({
                    ...prevState,
                    testLawDetails: {
                        ...stateMainAgreement?.testLawDetails,
                        settings: [
                            ...stateMainAgreement?.testLawDetails?.settings,
                            {
                                master_id: parseInt(event.target?.value),
                                rule: testLaw?.rules[0],
                            },
                        ],
                    },
                }));
            } else {
                setStateMainAgreement((prevState) => ({
                    ...prevState,
                    testLawDetails: {
                        settings: [
                            {
                                master_id: parseInt(event.target?.value),
                                rule: testLaw?.rules[0],
                            },
                        ],
                    },
                }));
            }
        } else {
            let filterData =
                stateMainAgreement?.testLawDetails?.settings.filter(
                    (data) => data.master_id != event.target?.value
                );
            setStateMainAgreement((prevState) => ({
                ...prevState,
                testLawDetails: {
                    ...stateMainAgreement?.testLawDetails,
                    settings: filterData,
                },
            }));
        }
    }
    const handlerSave = async () => {
        setStateMainAgreement((prevState) => ({
            ...prevState,
            isLoading: true,
        }));
        let data = {
            settings: stateMainAgreement?.testLawDetails?.settings,
            guid: guid,
        };
        await saveTestLawDeatils(data);
        setStateMainAgreement((prevState) => ({
            ...prevState,
            isLoading: false,
        }));
    };
    const handlerEdit = async () => {
        setStateMainAgreement((prevState) => ({
            ...prevState,
            isLoading: true,
        }));
        let data = {
            id: stateMainAgreement?.testLawDetails?.id,
            guid: guid,
            settings: stateMainAgreement?.testLawDetails?.settings,
        };
        await editTestLawDetails(data);
        setStateMainAgreement((prevState) => ({
            ...prevState,
            isLoading: false,
        }));
    };
    const editButton = () => (
        <ButtonComponent title="Update" onClick={handlerEdit} />
    );
    const saveButton = () => {
        return <ButtonComponent title="Save" onClick={handlerSave} />;
    };

    return (
        <>
            <Grid container spacing={0}>
                {testLawSettings.map((testLaw) => {
                    return (
                        <Grid
                            item
                            lg={12}
                            md={12}
                            className="my-10"
                            key={testLaw.id}
                        >
                            {/* Accrodion checkbox headings */}
                            <AccordionWithCheckBox
                                id={testLaw.id}
                                title={testLaw.option}
                                onHandlerCheckBox={(e) =>
                                    onHandlerCheckBox(e, testLaw)
                                }
                                selectedModules={
                                    stateMainAgreement?.testLawDetails
                                }
                            >
                                {/* Dropdown menu */}
                                <AgreementCommon
                                    showTitle={false}
                                    testLawSettings={testLaw}
                                    isTestSettings={true}
                                    state={stateMainAgreement}
                                    setStateTestLawSetting={
                                        setStateMainAgreement
                                    }
                                />
                            </AccordionWithCheckBox>
                        </Grid>
                    );
                })}

                <Grid item justifyContent={"end"} display={"flex"} xs={12}>
                    {modules ? editButton() : saveButton()}
                </Grid>
            </Grid>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        settings: state.agreementSettings.settings,
        modules: state.testLawSettings.modules,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            saveTestLawDeatils,
            editTestLawDetails,
        },
        dispatch
    );
};
export default connect(mapStateToProps, mapDispatchToProps)(TestLawSettings);
