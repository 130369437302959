import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate } from "react-router-dom";
import { Menu, MenuItem } from "@mui/material";

export default function NavbarDrawer(props) {
    const { open, setClose } = props;
    const handleClose = () => {
        setClose(false);
    };
    const navigate = useNavigate();
    const list = (anchor) => (
        <Box
            sx={{
                width: anchor === "top" || anchor === "bottom" ? "auto" : 250,
            }}
            role="presentation"
            onClick={handleClose}
            onKeyDown={handleClose}
        >
            <List>
                <ListItem disablePadding>
                    <ListItemButton>
                        <ListItemIcon></ListItemIcon>
                        <ListItemText onClick={() => navigate("/dashboard")}>
                            Dashboard
                        </ListItemText>
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton>
                        <ListItemIcon></ListItemIcon>
                        <ListItemText>
                            Manage
                            {/* <Menu></Menu> */}
                        </ListItemText>
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton>
                        <ListItemIcon></ListItemIcon>
                        <ListItemText onClick={() => navigate("/dashboard")}>
                            Data Capture Tool
                        </ListItemText>
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton>
                        <ListItemIcon></ListItemIcon>
                        <ListItemText onClick={() => navigate("/dashboard")}>
                        Resources
                        </ListItemText>
                    </ListItemButton>
                </ListItem>
            </List>
            {/* <Divider />
            <List>
                {["All mail", "Trash", "Spam"].map((text, index) => (
                    <ListItem key={text} disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                            </ListItemIcon>
                            <ListItemText primary={text} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List> */}
        </Box>
    );

    return (
        <div>
            <Drawer anchor={"left"} open={open || false} onClose={handleClose}>
                {list("left")}
            </Drawer>
        </div>
    );
}
