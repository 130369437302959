import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from "moment";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {
    createClientAction,
    setSelectedCustomer,
    updateCustomerById,
    getCustomerById,
    getCustomerList,
} from "../../store/Action/CustomerAction";
import PageHeader from "../../common/PageHeader";
import TextFieldComponent from "../../components/TextField/TextFieldComponent";
import CheckboxComponents from "../../components/Checkbox/CheckboxComponent";
import DropDownComponent from "../../components/DropDown/DropDownComponent";
import ButtonComponent from "../../components/ButtonComponent";
import RadioButtonGroup from "../../components/RadioButtonGroup/RadioButtonGroup";
import Navbar from "../../common/Header/Navbar";
import DatePickerComponent from "../../components/DatePicker/DatePickerComponent";
import {
    directIndirectOptions,
    business_entity,
    onboardNewCustomer,
    subscription,
    genderOptions,
    yesNoOptions,
    pageLimit,
} from "../../utils/CommonData";
import FileUpload from "../../components/FileUpload";
import { ROUTES } from "../../router/RouteList";
import NoImage from "../../../assets/Images/Logo/NoImage.png";
import LoadingComponent from "./../../components/Loading/LoadingComponent";
import { Translate } from "react-auto-translate";

function CreateClient(props) {
    const {
        userLoggedIn,
        commonDropdown,
        selectedCustomer,
        createClientAction,
        updateCustomerById,
        setSelectedCustomer,
        getCustomerById,
        getCustomerList,
        customerList,
    } = props;
    const pathName = window.location.search.split("?");
    const pageType = window.location.pathname.split("/");
    const mode =
        selectedCustomer?.mode || pageType[2] !== "create" ? pageType[2] : null;
    const navigate = useNavigate();
    const [state, setState] = useState({
        id: mode ? selectedCustomer?.id : "",
        client_type: mode ? selectedCustomer?.client_type : "dc",
        first_name: mode ? selectedCustomer?.first_name : "",
        // middle_name: mode ? selectedCustomer?.middle_name : "",
        last_name: mode ? selectedCustomer?.last_name : "",
        // display_name: mode ? selectedCustomer?.display_name : "",
        gender: mode ? selectedCustomer?.gender : "",
        dob: mode ? selectedCustomer?.dob : "",
        email: mode ? selectedCustomer?.email : "",
        phone: mode ? selectedCustomer?.phone : "",
        work_phone: mode ? selectedCustomer?.work_phone : "",
        fax: mode ? selectedCustomer?.fax : "",
        language: mode ? selectedCustomer?.language : 38,
        address: mode
            ? selectedCustomer?.address
            : {
                  line1: "",
                  line2: "",
                  city: "",
                  state: "",
                  zip: "",
              },
        company_info: mode
            ? selectedCustomer?.company_info
            : {
                  name: "",
                  website: "",
                  principle_place_of_business: "",
                  state_company_incorporated: "",
              },
        details: mode
            ? selectedCustomer?.details
            : {
                  onboard_representative: false,
                  sign_on_behalf: "",
                  business_entity: "c",
                  party_details: {
                      first_name: "",
                      //   middle_name: "",
                      last_name: "",
                      //   display_name: "",
                      email: "",
                      phone: "",
                      work_phone: "",
                      fax: "",
                      gender: "",
                      dob: "",
                      address: {
                          line1: "",
                          line2: "",
                          city: "",
                          state: "",
                          zip: "",
                      },
                      company_info: {
                          name: "",
                          website: "",
                          state_company_incorporated: "",
                          principle_place_of_business: "",
                      },
                  },
                  onboard_new: false,
                  subscription: 1,
                  jurisdiction: ["United States"],
                  services: ["rcp"],
              },
        logo:
            (mode && selectedCustomer?.logo) || selectedCustomer?.logo
                ? [{ preview: selectedCustomer?.logo }]
                : null,
        favicon:
            (mode && selectedCustomer?.favicon) || selectedCustomer?.favicon
                ? [{ preview: selectedCustomer?.favicon }]
                : null,
        loading: false,
    });
    const {
        client_type,
        first_name,
        // middle_name,
        last_name,
        // display_name,
        // gender,
        // dob,
        email,
        phone,
        work_phone,
        fax,
        company_info,
        address,
        details,
        id,
        logo,
        favicon,
        language,
        loading,
    } = state;

    useEffect(() => {
        if (customerList === null) {
            getCustomerList(null, pageLimit);
        }
        if (selectedCustomer && !id && mode) {
            setState((prevState) => ({
                ...prevState,
                ...selectedCustomer,
                logo:
                    mode && selectedCustomer?.logo
                        ? [{ preview: selectedCustomer?.logo }]
                        : null,
                favicon:
                    mode && selectedCustomer?.favicon
                        ? [{ preview: selectedCustomer?.favicon }]
                        : null,
            }));
        } else if (selectedCustomer && !id) {
            setState((prevState) => ({
                ...prevState,
                ...selectedCustomer,
                logo: null,
                favicon: null,
            }));
        }
    }, [getCustomerList, selectedCustomer]);

    if (!selectedCustomer && pathName && pathName?.length > 1) {
        getCustomerById(parseInt(pathName[1]), pageType[2]);
    }
    async function handleSubmit() {
        setState((prevState) => ({ ...prevState, loading: true }));
        let res;
        let newObj = {
            ...state,
            logo: logo?.length
                ? logo[0]?.content
                    ? logo[0] || null
                    : null
                : null,
            favicon: favicon?.length
                ? favicon[0]?.content
                    ? favicon[0] || null
                    : null
                : null,
        };
        if (mode === "edit") res = await updateCustomerById(newObj);
        else {
            delete state.id;
            res = await createClientAction(newObj);
        }

        await setSelectedCustomer(null);
        setState((prevState) => ({ ...prevState, loading: false }));
        if (res) navigate(ROUTES.HOME);
    }
    function handleRadioButton(event) {
        setState((prevState) => ({
            ...prevState,
            client_type: event.target.value,
        }));
    }
    const handleCloseLogo = (event) => {
        event.stopPropagation();
        setState((prevState) => ({ ...prevState, logo: null }));
    };
    const handleCloseFavicon = (event) => {
        event.stopPropagation();
        setState((prevState) => ({ ...prevState, favicon: null }));
    };
    const handleSelectAllChecked = (name) => {
        let serviceData = details?.services.find((obj) => obj === name);
        let service = details?.services;
        if (serviceData) {
            service = details?.services.filter(
                (obj) => obj !== name && obj !== "tcp"
            );
        } else {
            if (name === "tcp") service = ["pcp", "dcp", "rcp", "tcp"];
            else service.push(name);
        }
        setState((prevState) => ({
            ...prevState,
            details: {
                ...details,
                services: service,
            },
        }));
    };
    function handleChecked(option) {
        let data = details?.services?.find((obj) => obj === option.id);
        return !!data;
    }
    function handleJurisdiction(name) {
        let jurisdictionData = details?.jurisdiction?.find(
            (obj) => obj === name
        );
        let jurisdictionService = details?.jurisdiction;
        if (jurisdictionData)
            jurisdictionService = details?.jurisdiction.filter(
                (obj) => obj !== name
            );
        else jurisdictionService?.push(name);
        setState((prevState) => ({
            ...prevState,
            details: {
                ...details,
                jurisdiction: jurisdictionService,
            },
        }));
    }
    function handleJurisdictionCheck(option) {
        const data = details?.jurisdiction?.find((obj) => obj === option);
        return !!data;
    }
    function handleLogoUpload(file) {
        const reader = new FileReader();
        reader.readAsDataURL(file[0]);
        let newData;
        newData = file.map((fileObj) =>
            Object.assign(fileObj, {
                preview: URL.createObjectURL(fileObj),
            })
        );
        reader.onload = () => {
            let fileUrl = reader.result;
            newData = {
                ...newData[0],
                content: fileUrl,
                filename: file[0].name,
                type: file[0].type,
            };
            setState((prevState) => ({ ...prevState, logo: [newData] }));
        };
        setState((prevState) => ({ ...prevState, logo: newData }));
    }
    function handleFaviconUpload(file) {
        const reader = new FileReader();
        reader.readAsDataURL(file[0]);
        let newData;
        newData = file.map((fileObj) =>
            Object.assign(fileObj, {
                preview: URL.createObjectURL(fileObj),
            })
        );
        reader.onload = () => {
            let fileUrl = reader.result;
            newData = {
                ...newData[0],
                content: fileUrl,
                filename: file[0].name,
                type: file[0].type,
            };
            setState((prevState) => ({ ...prevState, favicon: [newData] }));
        };
        setState((prevState) => ({ ...prevState, favicon: newData }));
    }

    const handlechangeRepresentative = (event) => {
        setState((prevState) => ({
            ...prevState,
            details: {
                ...details,
                onboard_representative:
                    event.target.value === "yes" ? true : false,
            },
        }));
        if (event.target.value === "no") {
            setState((prevState) => ({
                ...prevState,
                details: {
                    onboard_representative: false,
                    sign_on_behalf: "",
                    business_entity: "",
                    party_details: {
                        first_name: "",
                        // middle_name: "",
                        last_name: "",
                        // display_name: "",
                        email: "",
                        phone: "",
                        work_phone: "",
                        fax: "",
                        gender: "",
                        dob: "",
                        address: {
                            line1: "",
                            line2: "",
                            city: "",
                            state: "",
                            zip: "",
                        },
                        company_info: {
                            name: "",
                            website: "",
                            principle_place_of_business: "",
                            state_company_incorporated: "",
                        },
                    },
                    onboard_new: false,
                    subscription: 1,
                    jurisdiction: ["United States"],
                    services: ["pcp", "dcp", "rcp", "tcp"],
                },
            }));
        }
    };

    return (
        <Fragment>
            <Navbar />
            <Box sx={{ px: { md: 7, xs: 1 } }}>
                <PageHeader
                    title={`${
                        mode === "edit"
                            ? "Edit"
                            : mode === "view"
                            ? "View"
                            : "Add"
                    } Customer`}
                />
                {loading ? (
                    <LoadingComponent sx={{ height: "72%", width: "95%" }} />
                ) : (
                    <Grid container className="userGrid">
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography className="formSubHeading">
                                    <Translate>
                                        {userLoggedIn?.language_data
                                            ?.complianceworkflow
                                            ?.conductor_type ||
                                            "Conductor Type"}
                                    </Translate>
                                    *
                                </Typography>
                            </Grid>
                            <Grid item my={-1} mx={5} xs={12}>
                                <RadioButtonGroup
                                    id="createClientDIOptions"
                                    row={true}
                                    options={directIndirectOptions}
                                    onChange={(event) =>
                                        handleRadioButton(event)
                                    }
                                    value={client_type || ""}
                                    required={true}
                                    className={"radio_label"}
                                    disabled={mode !== "view" ? false : true}
                                    infoIcon={true}
                                    infoTitle={
                                        directIndirectOptions?.defination
                                    }
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className="formSubHeading">
                                <Translate>
                                    Customer Super Admin Details
                                </Translate>
                            </Typography>
                        </Grid>
                        <Grid container px={3}>
                            <Grid item md={6} xs={12} sx={{ padding: "10px" }}>
                                <TextFieldComponent
                                    label={
                                        <Translate>
                                            {userLoggedIn?.language_data
                                                ?.customer?.first_name ||
                                                "First Name"}
                                        </Translate>
                                    }
                                    required={true}
                                    onChange={(event) =>
                                        setState((prevState) => ({
                                            ...prevState,
                                            first_name: event.target.value,
                                            // display_name:
                                            //     event.target.value +
                                            //     " " +
                                            //     last_name,
                                        }))
                                    }
                                    disabled={mode !== "view" ? false : true}
                                    value={first_name || ""}
                                    id="createClientFirstName"
                                />
                            </Grid>
                            {/* <Grid item md={6} xs={12} sx={{ padding: "10px" }}>
                                <TextFieldComponent
                                    id="createClientMiddleName"
                                    label={
                                        <Translate>
                                            {
                                                userLoggedIn?.language_data?.customer
                                                    ?.middle_name || "Middle Name"
                                            }
                                        </Translate>

                                    }
                                    onChange={(event) =>
                                        setState((prevState) => ({
                                            ...prevState,
                                            middle_name: event.target.value,
                                        }))
                                    }
                                    value={middle_name || ""}
                                    disabled={mode !== "view" ? false : true}
                                />
                            </Grid> */}
                            <Grid item md={6} xs={12} sx={{ padding: "10px" }}>
                                <TextFieldComponent
                                    id="createClientLastName"
                                    label={
                                        <Translate>
                                            {userLoggedIn?.language_data
                                                ?.customer?.last_name ||
                                                "Last Name"}
                                        </Translate>
                                    }
                                    required={true}
                                    onChange={(event) =>
                                        setState((prevState) => ({
                                            ...prevState,
                                            last_name: event.target.value,
                                            // display_name:
                                            //     first_name +
                                            //     " " +
                                            //     event.target.value,
                                        }))
                                    }
                                    value={last_name || ""}
                                    disabled={mode !== "view" ? false : true}
                                />
                            </Grid>
                            {/* <Grid item md={6} xs={12} sx={{ padding: "10px" }}>
                                <TextFieldComponent
                                    label={
                                        <Translate>
                                            {userLoggedIn?.language_data?.customer
                                                ?.display_name || "Display Name"}
                                        </Translate>

                                    }
                                    required={true}
                                    onChange={(event) =>
                                        setState((prevState) => ({
                                            ...prevState,
                                            display_name: event.target.value,
                                        }))
                                    }
                                    value={display_name}
                                    disabled={mode !== "view" ? false : true}
                                    id="createClientDisplayName"
                                />
                            </Grid> */}
                            {/* <Grid item md={4} xs={12} sx={{ padding: "10px" }}>
                                <DropDownComponent
                                    label={
                                        userLoggedIn?.language_data?.customer
                                            ?.gender || "Gender"
                                    }
                                    required={true}
                                    name={"Gender"}
                                    fullWidth={true}
                                    onChange={(event) =>
                                        setState((prevState) => ({
                                            ...prevState,
                                            gender: event.target.value,
                                        }))
                                    }
                                    value={gender || ""}
                                    disabled={mode !== "view" ? false : true}
                                >
                                    {genderOptions.map((option, index) => (
                                        <MenuItem
                                            id={`createClientGenderOp${index}`}
                                            key={option.id}
                                            value={option.id}
                                        >
                                            {option?.name}
                                        </MenuItem>
                                    ))}
                                </DropDownComponent>
                            </Grid>
                            <Grid item md={4} xs={12} sx={{ padding: "10px" }}>
                                <DatePickerComponent
                                    id="createClientDOB"
                                    onChange={(event) => {
                                        let date = new Date(event);
                                        setState((prevState) => ({
                                            ...prevState,
                                            dob: moment(date).format(
                                                "YYYY-MM-DD"
                                            ),
                                        }));
                                    }}
                                    value={dob || null}
                                    label={
                                        userLoggedIn?.language_data?.customer
                                            ?.date_of_birth || "Date of Birth"
                                    }
                                    required={true}
                                    disabled={mode !== "view" ? false : true}
                                    maxDate={new Date()}
                                />
                            </Grid> */}
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className="formSubHeading">
                                {/* Customer information */}
                                <Translate>
                                    {userLoggedIn?.language_data?.customer
                                        ?.company_info ||
                                        "Customer information"}
                                </Translate>
                            </Typography>
                        </Grid>
                        <Grid container px={3}>
                            <Grid item md={6} xs={12} sx={{ padding: "10px" }}>
                                <TextFieldComponent
                                    id="createClientCompanyName"
                                    label={
                                        <Translate>
                                            {userLoggedIn?.language_data
                                                ?.customer?.company_name ||
                                                "Customer Name"}
                                        </Translate>
                                    }
                                    required={true}
                                    onChange={(event) =>
                                        setState((prevState) => ({
                                            ...prevState,
                                            company_info: {
                                                ...company_info,
                                                name: event.target.value,
                                            },
                                        }))
                                    }
                                    value={company_info?.name || ""}
                                    disabled={mode !== "view" ? false : true}
                                />
                            </Grid>
                            <Grid item md={6} xs={12} sx={{ padding: "10px" }}>
                                <TextFieldComponent
                                    id="createClientCompanyWebsite"
                                    label={
                                        <Translate>
                                            {userLoggedIn?.language_data
                                                ?.customer?.company_website ||
                                                "Customer Whitelabel URL"}
                                        </Translate>
                                    }
                                    required={true}
                                    onChange={(event) =>
                                        setState((prevState) => ({
                                            ...prevState,
                                            company_info: {
                                                ...company_info,
                                                website: event.target.value,
                                            },
                                        }))
                                    }
                                    value={company_info?.website || ""}
                                    disabled={mode !== "view" ? false : true}
                                />
                            </Grid>
                            <Grid item md={6} xs={12} sx={{ padding: "10px" }}>
                                <DropDownComponent
                                    id="stateCompanyIncorporated"
                                    label={
                                        <Translate>
                                            State Company Incorporated
                                        </Translate>
                                    }
                                    name={"stateCompanyIncorporated"}
                                    fullWidth={true}
                                    onChange={(event) =>
                                        setState((prevState) => ({
                                            ...prevState,
                                            company_info: {
                                                ...company_info,
                                                state_company_incorporated:
                                                    event.target.value,
                                            },
                                        }))
                                    }
                                    disabled={mode !== "view" ? false : true}
                                    // required={true}
                                    value={
                                        company_info?.state_company_incorporated ||
                                        ""
                                    }
                                    children={commonDropdown?.states?.map(
                                        (option) => (
                                            <MenuItem
                                                id={`stateCompanyIncorporated${option.id}`}
                                                key={option.id}
                                                value={option.id}
                                            >
                                                {option.label}
                                            </MenuItem>
                                        )
                                    )}
                                />
                            </Grid>

                            <Grid item md={6} xs={12} sx={{ padding: "10px" }}>
                                <DropDownComponent
                                    id="principlePlaceofbusiness"
                                    label={
                                        <Translate>
                                            Principal Place of Business
                                        </Translate>
                                    }
                                    name={"principlePlaceofbusiness"}
                                    fullWidth={true}
                                    onChange={(event) =>
                                        setState((prevState) => ({
                                            ...prevState,
                                            company_info: {
                                                ...company_info,
                                                principle_place_of_business:
                                                    event.target.value,
                                            },
                                        }))
                                    }
                                    disabled={mode !== "view" ? false : true}
                                    // required={true}
                                    value={
                                        company_info?.principle_place_of_business ||
                                        ""
                                    }
                                    children={commonDropdown?.states?.map(
                                        (option) => (
                                            <MenuItem
                                                id={`principlePlaceofbusiness${option.id}`}
                                                key={option.id}
                                                value={option.id}
                                            >
                                                {option.label}
                                            </MenuItem>
                                        )
                                    )}
                                />
                            </Grid>
                        </Grid>
                        {/* <Grid item xs={12}>
                            <Typography className="formSubHeading">
                                <Translate>
                                    {userLoggedIn?.language_data?.customer
                                        ?.contact_information ||
                                        "Contact Information"}
                                </Translate>
                            </Typography>
                        </Grid> */}
                        <Grid container px={3}>
                            <Grid item xs={12} md={6} sx={{ padding: "10px" }}>
                                <TextFieldComponent
                                    id="createClientAddress1"
                                    label={`${
                                        userLoggedIn?.language_data?.customer
                                            ?.address_line || "Address Line"
                                    } 1`}
                                    onChange={(event) =>
                                        setState((prevState) => ({
                                            ...prevState,
                                            address: {
                                                ...address,
                                                line1: event.target.value,
                                            },
                                        }))
                                    }
                                    value={address?.line1 || ""}
                                    disabled={mode !== "view" ? false : true}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} sx={{ padding: "10px" }}>
                                <TextFieldComponent
                                    id="createClientEmail"
                                    label={
                                        <Translate>
                                            {userLoggedIn?.language_data
                                                ?.customer?.email_address ||
                                                "Email Address"}
                                        </Translate>
                                    }
                                    required
                                    onChange={(event) =>
                                        setState((prevState) => ({
                                            ...prevState,
                                            email: event.target.value,
                                        }))
                                    }
                                    value={email || ""}
                                    disabled={mode !== "view" ? false : true}
                                />
                            </Grid>
                            <Grid item md={6} xs={12} sx={{ padding: "10px" }}>
                                <TextFieldComponent
                                    id="createClientAddress2"
                                    label={
                                        <Translate>
                                            {" "}
                                            {userLoggedIn?.language_data
                                                ?.customer?.address_line ||
                                                "Address Line"}{" "}
                                            2{" "}
                                        </Translate>
                                    }
                                    onChange={(event) =>
                                        setState((prevState) => ({
                                            ...prevState,
                                            address: {
                                                ...address,
                                                line2: event.target.value,
                                            },
                                        }))
                                    }
                                    value={address?.line2 || ""}
                                    disabled={mode !== "view" ? false : true}
                                />
                            </Grid>
                            <Grid item md={6} xs={12} sx={{ padding: "10px" }}>
                                <TextFieldComponent
                                    id="createClientMobileNumber"
                                    label={<Translate>Phone Number</Translate>}
                                    onChange={(event) =>
                                        setState((prevState) => ({
                                            ...prevState,
                                            phone: event.target.value,
                                        }))
                                    }
                                    value={phone || ""}
                                    required={false}
                                    disabled={mode !== "view" ? false : true}
                                />
                            </Grid>
                            <Grid item md={3} xs={12} sx={{ padding: "10px" }}>
                                <TextFieldComponent
                                    label={
                                        <Translate>
                                            {userLoggedIn?.language_data
                                                ?.customer?.city || "City"}
                                        </Translate>
                                    }
                                    onChange={(event) =>
                                        setState((prevState) => ({
                                            ...prevState,
                                            address: {
                                                ...address,
                                                city: event.target.value,
                                            },
                                        }))
                                    }
                                    value={address?.city || ""}
                                    disabled={mode !== "view" ? false : true}
                                    id="createClienCity"
                                    required
                                />
                            </Grid>
                            <Grid item md={3} xs={12} sx={{ padding: "10px" }}>
                                <DropDownComponent
                                    label={
                                        <Translate>
                                            {userLoggedIn?.language_data
                                                ?.complianceworkflow?.state ||
                                                "State"}
                                        </Translate>
                                    }
                                    name={"State"}
                                    fullWidth={true}
                                    onChange={(event) =>
                                        setState((prevState) => ({
                                            ...prevState,
                                            address: {
                                                ...address,
                                                state: event.target.value,
                                            },
                                        }))
                                    }
                                    required={true}
                                    value={`${address?.state || ""}`}
                                    disabled={mode !== "view" ? false : true}
                                    id="createClientState"
                                >
                                    {commonDropdown?.states?.map(
                                        (option, index) => (
                                            <MenuItem
                                                id={`createClientStateList ${index}`}
                                                key={option.id}
                                                value={option.id}
                                            >
                                                <Translate>
                                                    {option.label}
                                                </Translate>
                                            </MenuItem>
                                        )
                                    )}
                                </DropDownComponent>
                            </Grid>
                            <Grid item md={3} xs={12} sx={{ padding: "10px" }}>
                                <TextFieldComponent
                                    id="createClientWorkPhone"
                                    label={<Translate>Phone Number</Translate>}
                                    onChange={(event) =>
                                        setState((prevState) => ({
                                            ...prevState,
                                            work_phone: event.target.value,
                                        }))
                                    }
                                    value={work_phone || ""}
                                    disabled={mode !== "view" ? false : true}
                                />
                            </Grid>
                            <Grid item md={3} xs={12} sx={{ padding: "10px" }}>
                                <TextFieldComponent
                                    label={
                                        <Translate>
                                            {userLoggedIn?.language_data
                                                ?.customer?.fax || "Fax"}
                                        </Translate>
                                    }
                                    onChange={(event) =>
                                        setState((prevState) => ({
                                            ...prevState,
                                            fax: event.target.value,
                                        }))
                                    }
                                    value={fax || ""}
                                    disabled={mode !== "view" ? false : true}
                                    id="createClientFax"
                                />
                            </Grid>
                            <Grid item md={3} xs={12} sx={{ padding: "10px" }}>
                                <TextFieldComponent
                                    id="createClientZipCode"
                                    label={
                                        <Translate>
                                            {userLoggedIn?.language_data
                                                ?.customer?.zi_code ||
                                                "Zip Code"}
                                        </Translate>
                                    }
                                    onChange={(event) =>
                                        setState((prevState) => ({
                                            ...prevState,
                                            address: {
                                                ...address,
                                                zip: event.target.value,
                                            },
                                        }))
                                    }
                                    value={address?.zip || ""}
                                    disabled={mode !== "view" ? false : true}
                                    required={true}
                                />
                            </Grid>
                        </Grid>
                        {/* <Grid item md={6} xs={12}>
                            <Typography className="formSubHeading">
                                {
                                    <Translate>
                                        {userLoggedIn?.language_data?.customer
                                            ?.want_onboard_indi ||
                                            "Do you want to onboard an individual or entity to represent on behalf of you?"}
                                    </Translate>
                                }
                            </Typography>
                            <Grid item ml={4.5}>
                                <RadioButtonGroup
                                    id="createClientYesNoOptions"
                                    row={true}
                                    options={yesNoOptions}
                                    defaultValue={false}
                                    required={true}
                                    onChange={(event) =>
                                        handlechangeRepresentative(event)
                                    }
                                    value={
                                        details?.onboard_representative
                                            ? "yes"
                                            : "no"
                                    }
                                    disabled={mode !== "view" ? false : true}
                                />
                            </Grid>
                        </Grid> */}
                        {details?.onboard_representative && (
                            <Grid item md={6} xs={12}>
                                <Typography className="formSubHeading">
                                    <Translate>
                                        Do you want an individual or entity to
                                        sign on behalf of you?
                                    </Translate>
                                </Typography>
                                <Grid item ml={4.5} mt={3}>
                                    <RadioButtonGroup
                                        row={true}
                                        id="createClientYesNoOptions2"
                                        options={yesNoOptions}
                                        defaultValue={false}
                                        required={true}
                                        onChange={(event) =>
                                            setState((prevState) => ({
                                                ...prevState,
                                                details: {
                                                    ...details,
                                                    sign_on_behalf:
                                                        event.target.value ===
                                                        "yes"
                                                            ? true
                                                            : false,
                                                },
                                            }))
                                        }
                                        value={
                                            details?.sign_on_behalf
                                                ? "yes"
                                                : "no"
                                        }
                                        disabled={
                                            mode !== "view" ? false : true
                                        }
                                    />
                                </Grid>
                            </Grid>
                        )}
                        {details?.onboard_representative && (
                            <Fragment>
                                <Grid container>
                                    <Grid container spacing={2}>
                                        <Grid
                                            item
                                            my={2}
                                            xs={12}
                                            className="party"
                                        >
                                            <Typography className="formSubHeading">
                                                <Translate>
                                                    Representing Party User
                                                    Profile
                                                </Translate>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container px={3}>
                                        <Grid
                                            item
                                            xs={12}
                                            md={6}
                                            sx={{ padding: "10px" }}
                                        >
                                            <TextFieldComponent
                                                id="createClientPartyFirstName"
                                                label={
                                                    <Translate>
                                                        {userLoggedIn
                                                            ?.language_data
                                                            ?.customer
                                                            ?.first_name ||
                                                            "First Name"}
                                                    </Translate>
                                                }
                                                onChange={(event) =>
                                                    setState((prevState) => ({
                                                        ...prevState,
                                                        details: {
                                                            ...details,
                                                            party_details: {
                                                                ...details?.party_details,
                                                                first_name:
                                                                    event.target
                                                                        .value,
                                                                // display_name:
                                                                //     event.target
                                                                //         .value +
                                                                //         " " +
                                                                //         details
                                                                //             ?.party_details
                                                                //             ?.last_name ||
                                                                //     "",
                                                            },
                                                        },
                                                    }))
                                                }
                                                value={
                                                    details?.party_details
                                                        ?.first_name || ""
                                                }
                                                disabled={
                                                    mode !== "view"
                                                        ? false
                                                        : true
                                                }
                                                required={true}
                                            />
                                        </Grid>
                                        {/* <Grid
                                            item
                                            xs={12}
                                            md={6}
                                            sx={{ padding: "10px" }}
                                        >
                                            <TextFieldComponent
                                                id="clientPartyMiddleName"
                                                label={
                                                    <Translate>
                                                        {userLoggedIn?.language_data
                                                            ?.customer
                                                            ?.middle_name ||
                                                            "Middle Name"}
                                                    </Translate>

                                                }
                                                onChange={(event) =>
                                                    setState((prevState) => ({
                                                        ...prevState,
                                                        details: {
                                                            ...details,
                                                            party_details: {
                                                                ...details?.party_details,
                                                                middle_name:
                                                                    event.target
                                                                        .value,
                                                            },
                                                        },
                                                    }))
                                                }
                                                value={
                                                    details?.party_details
                                                        ?.middle_name || ""
                                                }
                                                disabled={
                                                    mode !== "view"
                                                        ? false
                                                        : true
                                                }
                                            />
                                        </Grid> */}
                                        <Grid
                                            item
                                            xs={12}
                                            md={6}
                                            sx={{ padding: "10px" }}
                                        >
                                            <TextFieldComponent
                                                id="clientPartyLastName"
                                                label={
                                                    <Translate>
                                                        {userLoggedIn
                                                            ?.language_data
                                                            ?.customer
                                                            ?.last_name ||
                                                            "Last Name"}
                                                    </Translate>
                                                }
                                                onChange={(event) =>
                                                    setState((prevState) => ({
                                                        ...prevState,
                                                        details: {
                                                            ...details,
                                                            party_details: {
                                                                ...details?.party_details,
                                                                last_name:
                                                                    event.target
                                                                        .value,
                                                                // display_name:
                                                                //     details
                                                                //         ?.party_details
                                                                //         ?.first_name +
                                                                //     " " +
                                                                //     event.target
                                                                //         .value,
                                                            },
                                                        },
                                                    }))
                                                }
                                                value={
                                                    details?.party_details
                                                        ?.last_name || ""
                                                }
                                                required={true}
                                                disabled={
                                                    mode !== "view"
                                                        ? false
                                                        : true
                                                }
                                            />
                                        </Grid>
                                        {/* <Grid
                                            item
                                            xs={12}
                                            md={6}
                                            sx={{ padding: "10px" }}
                                        >
                                            <TextFieldComponent
                                                label={
                                                    <Translate>
                                                        {
                                                            userLoggedIn?.language_data
                                                                ?.customer
                                                                ?.display_name ||
                                                            "Display Name"
                                                        }
                                                    </Translate>

                                                }
                                                onChange={(event) =>
                                                    setState((prevState) => ({
                                                        ...prevState,
                                                        details: {
                                                            ...details,
                                                            party_details: {
                                                                ...details?.party_details,
                                                                display_name:
                                                                    event.target
                                                                        .value,
                                                            },
                                                        },
                                                    }))
                                                }
                                                required={true}
                                                value={
                                                    details?.party_details
                                                        ?.display_name
                                                }
                                                disabled={
                                                    mode !== "view"
                                                        ? false
                                                        : true
                                                }
                                                id="clientPartyDisplayName"
                                            />
                                        </Grid> */}
                                        {/* <Grid
                                            item
                                            xs={12}
                                            md={4}
                                            sx={{ padding: "10px" }}
                                        >
                                            <DropDownComponent
                                                label={
                                                    userLoggedIn?.language_data
                                                        ?.customer?.gender ||
                                                    "Gender"
                                                }
                                                name={"Gender"}
                                                fullWidth={true}
                                                required={true}
                                                onChange={(event) =>
                                                    setState((prevState) => ({
                                                        ...prevState,
                                                        details: {
                                                            ...details,
                                                            party_details: {
                                                                ...details?.party_details,
                                                                gender: event
                                                                    .target
                                                                    .value,
                                                            },
                                                        },
                                                    }))
                                                }
                                                value={
                                                    details?.party_details
                                                        ?.gender || ""
                                                }
                                                disabled={
                                                    mode !== "view"
                                                        ? false
                                                        : true
                                                }
                                                id="clientPartyGender"
                                            >
                                                {genderOptions.map(
                                                    (option, index) => (
                                                        <MenuItem
                                                            id={`clientPartyGenderList ${index}`}
                                                            key={option.id}
                                                            value={option.id}
                                                        >
                                                            {option.name}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </DropDownComponent>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={4}
                                            sx={{ padding: "10px" }}
                                        >
                                            <DatePickerComponent
                                                id="clientPartyDOB"
                                                onChange={(event) => {
                                                    let date = new Date(event);
                                                    setState((prevState) => ({
                                                        ...prevState,
                                                        details: {
                                                            ...details,
                                                            party_details: {
                                                                ...details?.party_details,
                                                                dob: moment(
                                                                    date
                                                                ).format(
                                                                    "YYYY-MM-DD"
                                                                ),
                                                            },
                                                        },
                                                    }));
                                                }}
                                                value={
                                                    details?.party_details
                                                        ?.dob || null
                                                }
                                                label={
                                                    userLoggedIn?.language_data
                                                        ?.customer
                                                        ?.date_of_birth ||
                                                    "Date of Birth"
                                                }
                                                required={true}
                                                maxDate={new Date()}
                                                disabled={
                                                    mode !== "view"
                                                        ? false
                                                        : true
                                                }
                                            />
                                        </Grid> */}
                                    </Grid>
                                    <Grid item>
                                        <Typography className="formSubHeading">
                                            <Translate>
                                                Representing Party Company
                                                Information
                                            </Translate>
                                        </Typography>
                                    </Grid>
                                    <Grid container px={3}>
                                        <Grid
                                            item
                                            md={6}
                                            xs={12}
                                            sx={{ padding: "10px" }}
                                        >
                                            <TextFieldComponent
                                                label={
                                                    userLoggedIn?.language_data
                                                        ?.customer
                                                        ?.company_name ||
                                                    "Customer Name"
                                                }
                                                onChange={(event) =>
                                                    setState((prevState) => ({
                                                        ...prevState,
                                                        details: {
                                                            ...details,
                                                            party_details: {
                                                                ...details?.party_details,
                                                                company_info: {
                                                                    ...details
                                                                        ?.party_details
                                                                        ?.company_info,
                                                                    name: event
                                                                        .target
                                                                        .value,
                                                                },
                                                            },
                                                        },
                                                    }))
                                                }
                                                value={
                                                    details?.party_details
                                                        ?.company_info?.name ||
                                                    ""
                                                }
                                                disabled={
                                                    mode !== "view"
                                                        ? false
                                                        : true
                                                }
                                                id="clientPartyComapanyName"
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            md={6}
                                            xs={12}
                                            sx={{ padding: "10px" }}
                                        >
                                            <TextFieldComponent
                                                label={
                                                    <Translate>
                                                        {userLoggedIn
                                                            ?.language_data
                                                            ?.customer
                                                            ?.company_website ||
                                                            "Customer Whitelabel URL"}
                                                    </Translate>
                                                }
                                                onChange={(event) =>
                                                    setState((prevState) => ({
                                                        ...prevState,
                                                        details: {
                                                            ...details,
                                                            party_details: {
                                                                ...details?.party_details,
                                                                company_info: {
                                                                    ...details
                                                                        ?.party_details
                                                                        ?.company_info,
                                                                    website:
                                                                        event
                                                                            .target
                                                                            .value,
                                                                },
                                                            },
                                                        },
                                                    }))
                                                }
                                                value={
                                                    details?.party_details
                                                        ?.company_info
                                                        ?.website || ""
                                                }
                                                disabled={
                                                    mode !== "view"
                                                        ? false
                                                        : true
                                                }
                                                id="clientPartyCompanyWebsite"
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Typography className="formSubHeading">
                                            <Translate>
                                                Representing Party Contact
                                                Information
                                            </Translate>
                                        </Typography>
                                    </Grid>
                                    <Grid container px={3}>
                                        <Grid
                                            item
                                            md={6}
                                            xs={12}
                                            sx={{ padding: "10px" }}
                                        >
                                            <TextFieldComponent
                                                id="clientPartyAddress1"
                                                label={`${
                                                    userLoggedIn?.language_data
                                                        ?.customer
                                                        ?.address_line ||
                                                    "Address Line"
                                                } 1`}
                                                onChange={(event) =>
                                                    setState((prevState) => ({
                                                        ...prevState,
                                                        details: {
                                                            ...details,
                                                            party_details: {
                                                                ...details?.party_details,
                                                                address: {
                                                                    ...details
                                                                        ?.party_details
                                                                        ?.address,
                                                                    line1: event
                                                                        .target
                                                                        .value,
                                                                },
                                                            },
                                                        },
                                                    }))
                                                }
                                                value={
                                                    details?.party_details
                                                        ?.address?.line1 || ""
                                                }
                                                disabled={
                                                    mode !== "view"
                                                        ? false
                                                        : true
                                                }
                                                required={true}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            md={6}
                                            xs={12}
                                            sx={{ padding: "10px" }}
                                        >
                                            <TextFieldComponent
                                                id="clientPartyEmail"
                                                label={
                                                    <Translate>
                                                        {userLoggedIn
                                                            ?.language_data
                                                            ?.customer
                                                            ?.email_address ||
                                                            "Email Address"}
                                                    </Translate>
                                                }
                                                onChange={(event) =>
                                                    setState((prevState) => ({
                                                        ...prevState,
                                                        details: {
                                                            ...details,
                                                            party_details: {
                                                                ...details?.party_details,
                                                                email: event
                                                                    .target
                                                                    .value,
                                                            },
                                                        },
                                                    }))
                                                }
                                                value={
                                                    details?.party_details
                                                        ?.email || ""
                                                }
                                                disabled={
                                                    mode !== "view"
                                                        ? false
                                                        : true
                                                }
                                                required={true}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            md={6}
                                            xs={12}
                                            sx={{ padding: "10px" }}
                                        >
                                            <TextFieldComponent
                                                id="clientPartyAddress2"
                                                label={
                                                    <Translate>
                                                        `$
                                                        {userLoggedIn
                                                            ?.language_data
                                                            ?.customer
                                                            ?.address_line ||
                                                            "Address Line"}{" "}
                                                        2`
                                                    </Translate>
                                                }
                                                onChange={(event) =>
                                                    setState((prevState) => ({
                                                        ...prevState,
                                                        details: {
                                                            ...details,
                                                            party_details: {
                                                                ...details?.party_details,
                                                                address: {
                                                                    ...details
                                                                        ?.party_details
                                                                        ?.address,
                                                                    line2: event
                                                                        .target
                                                                        .value,
                                                                },
                                                            },
                                                        },
                                                    }))
                                                }
                                                value={
                                                    details?.party_details
                                                        ?.address?.line2 || ""
                                                }
                                                disabled={
                                                    mode !== "view"
                                                        ? false
                                                        : true
                                                }
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            md={6}
                                            xs={12}
                                            sx={{ padding: "10px" }}
                                        >
                                            <TextFieldComponent
                                                id="clientPartyMobileNumber"
                                                label={
                                                    <Translate>
                                                        {userLoggedIn
                                                            ?.language_data
                                                            ?.customer
                                                            ?.mobile_number ||
                                                            "Mobile Number"}
                                                    </Translate>
                                                }
                                                onChange={(event) =>
                                                    setState((prevState) => ({
                                                        ...prevState,
                                                        details: {
                                                            ...details,
                                                            party_details: {
                                                                ...details?.party_details,
                                                                phone: event
                                                                    .target
                                                                    .value,
                                                            },
                                                        },
                                                    }))
                                                }
                                                value={
                                                    details?.party_details
                                                        ?.phone || ""
                                                }
                                                disabled={
                                                    mode !== "view"
                                                        ? false
                                                        : true
                                                }
                                                required={true}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            md={3}
                                            xs={12}
                                            sx={{ padding: "10px" }}
                                        >
                                            <TextFieldComponent
                                                id="clientPartyCity"
                                                label={
                                                    <Translate>
                                                        {userLoggedIn
                                                            ?.language_data
                                                            ?.customer?.city ||
                                                            "City"}
                                                    </Translate>
                                                }
                                                onChange={(event) =>
                                                    setState((prevState) => ({
                                                        ...prevState,
                                                        details: {
                                                            ...details,
                                                            party_details: {
                                                                ...details?.party_details,
                                                                address: {
                                                                    ...details
                                                                        ?.party_details
                                                                        ?.address,
                                                                    city: event
                                                                        .target
                                                                        .value,
                                                                },
                                                            },
                                                        },
                                                    }))
                                                }
                                                value={
                                                    details?.party_details
                                                        ?.address?.city || ""
                                                }
                                                disabled={
                                                    mode !== "view"
                                                        ? false
                                                        : true
                                                }
                                                required={true}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            md={3}
                                            xs={12}
                                            sx={{ padding: "10px" }}
                                        >
                                            <DropDownComponent
                                                id="clientPartyState"
                                                label={
                                                    <Translate>
                                                        {userLoggedIn
                                                            ?.language_data
                                                            ?.complianceworkflow
                                                            ?.state || "State"}
                                                    </Translate>
                                                }
                                                name={"State"}
                                                fullWidth={true}
                                                onChange={(event) =>
                                                    setState((prevState) => ({
                                                        ...prevState,
                                                        details: {
                                                            ...details,
                                                            party_details: {
                                                                ...details?.party_details,
                                                                address: {
                                                                    ...details
                                                                        ?.party_details
                                                                        ?.address,
                                                                    state: event
                                                                        .target
                                                                        .value,
                                                                },
                                                            },
                                                        },
                                                    }))
                                                }
                                                required={true}
                                                value={`${
                                                    details?.party_details
                                                        ?.address?.state || ""
                                                }`}
                                                disabled={
                                                    mode !== "view"
                                                        ? false
                                                        : true
                                                }
                                            >
                                                {commonDropdown?.states?.map(
                                                    (option, index) => (
                                                        <MenuItem
                                                            id={`clientPartyStateList ${index}`}
                                                            key={option.id}
                                                            value={option.id}
                                                        >
                                                            <Translate>
                                                                {option.label}
                                                            </Translate>
                                                        </MenuItem>
                                                    )
                                                )}
                                            </DropDownComponent>
                                        </Grid>
                                        <Grid
                                            item
                                            md={3}
                                            xs={12}
                                            sx={{ padding: "10px" }}
                                        >
                                            <TextFieldComponent
                                                id="clientPartyWorkPhone"
                                                label={
                                                    <Translate>
                                                        {userLoggedIn
                                                            ?.language_data
                                                            ?.customer
                                                            ?.work_phone ||
                                                            "Work Phone"}
                                                    </Translate>
                                                }
                                                onChange={(event) =>
                                                    setState((prevState) => ({
                                                        ...prevState,
                                                        details: {
                                                            ...details,
                                                            party_details: {
                                                                ...details?.party_details,
                                                                work_phone:
                                                                    event.target
                                                                        .value,
                                                            },
                                                        },
                                                    }))
                                                }
                                                value={
                                                    details?.party_details
                                                        ?.work_phone || ""
                                                }
                                                disabled={
                                                    mode !== "view"
                                                        ? false
                                                        : true
                                                }
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            md={3}
                                            xs={12}
                                            sx={{ padding: "10px" }}
                                        >
                                            <TextFieldComponent
                                                id="clientPartyFax"
                                                label={
                                                    <Translate>
                                                        {userLoggedIn
                                                            ?.language_data
                                                            ?.customer?.fax ||
                                                            "Fax"}
                                                    </Translate>
                                                }
                                                onChange={(event) =>
                                                    setState((prevState) => ({
                                                        ...prevState,
                                                        details: {
                                                            ...details,
                                                            party_details: {
                                                                ...details?.party_details,
                                                                fax: event
                                                                    .target
                                                                    .value,
                                                            },
                                                        },
                                                    }))
                                                }
                                                value={
                                                    details?.party_details
                                                        ?.fax || ""
                                                }
                                                disabled={
                                                    mode !== "view"
                                                        ? false
                                                        : true
                                                }
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            md={3}
                                            xs={12}
                                            sx={{ padding: "10px" }}
                                        >
                                            <TextFieldComponent
                                                id="clientPartyZipCode"
                                                label={
                                                    <Translate>
                                                        {userLoggedIn
                                                            ?.language_data
                                                            ?.customer
                                                            ?.zi_code ||
                                                            "Zip Code"}
                                                    </Translate>
                                                }
                                                onChange={(event) =>
                                                    setState((prevState) => ({
                                                        ...prevState,
                                                        details: {
                                                            ...details,
                                                            party_details: {
                                                                ...details?.party_details,
                                                                address: {
                                                                    ...details
                                                                        ?.party_details
                                                                        ?.address,
                                                                    zip: event
                                                                        .target
                                                                        .value,
                                                                },
                                                            },
                                                        },
                                                    }))
                                                }
                                                value={
                                                    details?.party_details
                                                        ?.address?.zip || ""
                                                }
                                                disabled={
                                                    mode !== "view"
                                                        ? false
                                                        : true
                                                }
                                                required={true}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Fragment>
                        )}
                        <Grid container spacing={2}>
                            <Grid item md={6} xs={12}>
                                <Typography className="formSubHeading">
                                    <Translate>
                                        {userLoggedIn?.language_data?.customer
                                            ?.jurisdiction ||
                                            "Jurisdiction (for scope of testing purpose)"}
                                    </Translate>
                                </Typography>
                                <Grid container spacing={2} ml={4} mt={0.5}>
                                    <CheckboxComponents
                                        disabled={
                                            mode !== "view" ? false : true
                                        }
                                        checked={handleJurisdictionCheck(
                                            "United States"
                                        )}
                                        name="United States"
                                        onChange={(event) =>
                                            handleJurisdiction(
                                                event?.target?.name
                                            )
                                        }
                                        id="createClientJurisdication"
                                    />
                                    <Typography
                                        className="services"
                                        sx={{
                                            marginTop: "5px",
                                            marginLeft: "10px",
                                        }}
                                    >
                                        <Translate>United States</Translate>
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Typography className="formSubHeading">
                                    <Translate>
                                        {userLoggedIn?.language_data?.customer
                                            ?.services ||
                                            "Type of Compliance Provider"}
                                    </Translate>
                                </Typography>
                                <Grid container spacing={2} mx={3}>
                                    {commonDropdown?.services?.map((option) => (
                                        <Grid item md={3}>
                                            <Typography className="services">
                                                <CheckboxComponents
                                                    onChange={() =>
                                                        handleSelectAllChecked(
                                                            option.id
                                                        )
                                                    }
                                                    name={option.label}
                                                    checked={handleChecked(
                                                        option
                                                    )}
                                                    id="createClientServices"
                                                />
                                                <Translate>
                                                    {option.id.toUpperCase()}
                                                </Translate>
                                            </Typography>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container pl={3} pr={5}>
                            {/* <Grid item md={6} xs={12} sx={{ padding: "10px" }}>
                                <Grid item ml={-4}>
                                    <Typography className="formSubHeading">
                                        <Translate>CBE/SBE/PTE</Translate>
                                    </Typography>
                                </Grid>
                                <DropDownComponent
                                    label={
                                        <Translate>
                                            Select CBE/SBE/PTE
                                        </Translate>
                                    }
                                    id="createClientBusinessEntity"
                                    fullWidth={true}
                                    onChange={(event) =>
                                        setState((prevState) => ({
                                            ...prevState,
                                            details: {
                                                ...details,
                                                business_entity:
                                                    event.target.value,
                                            },
                                        }))
                                    }
                                    value={details?.business_entity || ""}
                                    disabled={mode !== "view" ? false : true}
                                    required={
                                        client_type === "dc" ? false : true
                                    }
                                >
                                    {business_entity.map((option) => (
                                        <MenuItem
                                            key={option.id}
                                            value={option.id}
                                        >
                                            <Translate>{option.name}</Translate>
                                        </MenuItem>
                                    ))}
                                </DropDownComponent>
                            </Grid> */}
                            {client_type === "idc" && (
                                <Grid
                                    item
                                    lg={6}
                                    md={6}
                                    xs={12}
                                    sx={{ padding: "10px" }}
                                >
                                    <Grid ml={-5}>
                                        <Typography
                                            mx={1}
                                            className="formSubHeading"
                                        >
                                            <Translate>
                                                {userLoggedIn?.language_data
                                                    ?.customer
                                                    ?.onboard_new_customer ||
                                                    "Onboard New Customer"}
                                            </Translate>
                                        </Typography>
                                    </Grid>
                                    <DropDownComponent
                                        label={
                                            <Translate>
                                                Select Onboard New Customer
                                            </Translate>
                                        }
                                        fullWidth={true}
                                        id="createClientOnboardYesNoOption"
                                        onChange={(event) =>
                                            setState((prevState) => ({
                                                ...prevState,
                                                details: {
                                                    ...details,
                                                    onboard_new:
                                                        event.target.value ===
                                                        "yes"
                                                            ? true
                                                            : false,
                                                },
                                            }))
                                        }
                                        value={
                                            details?.onboard_new ? "yes" : "no"
                                        }
                                        disabled={
                                            mode !== "view" ? false : true
                                        }
                                    >
                                        {onboardNewCustomer.map((option) => (
                                            <MenuItem
                                                key={option.id}
                                                value={option.id}
                                            >
                                                <Translate>
                                                    {option.name}
                                                </Translate>
                                            </MenuItem>
                                        ))}
                                    </DropDownComponent>
                                </Grid>
                            )}
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Typography className="formSubHeading">
                                    {userLoggedIn?.language_data?.customer
                                        ?.sub_license_type ||
                                        "Subscription/License Type"}
                                    :
                                </Typography>
                                <Grid container pl={3}>
                                    <Grid item xs={12} sx={{ padding: "10px" }}>
                                        <DropDownComponent
                                            label={
                                                <Translate>
                                                    Select Subscription/License
                                                    Type
                                                </Translate>
                                            }
                                            id="createClientSubscription"
                                            name={"subscription"}
                                            fullWidth={true}
                                            onChange={(event) =>
                                                setState((prevState) => ({
                                                    ...prevState,
                                                    details: {
                                                        ...details,
                                                        subscription:
                                                            event.target.value,
                                                    },
                                                }))
                                            }
                                            value={details?.subscription || ""}
                                            disabled={
                                                mode !== "view" ? false : true
                                            }
                                            required={false}
                                        >
                                            {subscription.map((option) => (
                                                <MenuItem
                                                    key={option.id}
                                                    value={option.id}
                                                >
                                                    {option.name}
                                                </MenuItem>
                                            ))}
                                        </DropDownComponent>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography
                                    className="formSubHeading"
                                    sx={{ ml: "-32px" }}
                                >
                                    {userLoggedIn?.language_data?.customer
                                        ?.preferred_language ||
                                        "Prefered Lanugauge"}
                                </Typography>
                                <Grid container pr={3}>
                                    <Grid
                                        item
                                        xs={12}
                                        sx={{ paddingY: "10px" }}
                                    >
                                        <DropDownComponent
                                            id="createClientPreferredLanguage"
                                            label={`Select Entity Type`}
                                            name={"language"}
                                            fullWidth={true}
                                            onChange={(event) =>
                                                setState((prevState) => ({
                                                    ...prevState,
                                                    language:
                                                        event.target.value,
                                                }))
                                            }
                                            value={language || ""}
                                            disabled={
                                                mode !== "view" ? false : true
                                            }
                                            required={true}
                                        >
                                            {commonDropdown?.languages?.map(
                                                (option) => (
                                                    <MenuItem
                                                        key={option.id}
                                                        value={option.id}
                                                    >
                                                        {option.label}
                                                    </MenuItem>
                                                )
                                            )}
                                        </DropDownComponent>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography className="formSubHeading">
                                    {userLoggedIn?.language_data?.customer
                                        ?.attachment_information ||
                                        "Attachment Information"}
                                </Typography>
                            </Grid>
                            <Grid container className="clientUploads">
                                <Grid item xs={12} lg={6} md={6} sm={12} pl={7}>
                                    <Typography className="font_18_600">
                                        Customer Logo
                                    </Typography>
                                    {mode === "view" ? (
                                        <img
                                            alt="logo"
                                            src={
                                                logo
                                                    ? logo[0]?.preview ||
                                                      NoImage
                                                    : NoImage
                                            }
                                            className="viewImage"
                                        />
                                    ) : (
                                        <Grid item className="fileGrid">
                                            <FileUpload
                                                id="createClientLogo"
                                                file={logo}
                                                keyName={"logo"}
                                                setFiles={setState}
                                                validation={"image/*"}
                                                uploadIcon={
                                                    <CloudUploadOutlinedIcon
                                                        size="large"
                                                        className="createClientUploadIcon"
                                                    />
                                                }
                                                text={
                                                    userLoggedIn?.language_data
                                                        ?.customer
                                                        ?.drag_and_drop_click ||
                                                    "Drag and drop a file here or click"
                                                }
                                                showClose={
                                                    mode !== "view"
                                                        ? true
                                                        : false
                                                }
                                                edit={
                                                    mode === "edit" && logo
                                                        ? true
                                                        : false
                                                }
                                                handleDelete={handleCloseLogo}
                                                handleFileUpload={
                                                    handleLogoUpload
                                                }
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                                <Grid item xs={12} lg={6} md={6} sm={12} pl={3}>
                                    <Typography className="font_18_600">
                                        Customer Favicon
                                    </Typography>
                                    {mode === "view" ? (
                                        <img
                                            alt="favicon"
                                            src={
                                                favicon
                                                    ? favicon[0]?.preview ||
                                                      NoImage
                                                    : NoImage
                                            }
                                            className="viewImage"
                                        />
                                    ) : (
                                        <Grid item className="fileGrid">
                                            <FileUpload
                                                id="createClientFavicon"
                                                file={favicon}
                                                keyName={"favicon"}
                                                setFiles={setState}
                                                validation={"image/*"}
                                                uploadIcon={
                                                    <CloudUploadOutlinedIcon
                                                        size="large"
                                                        className="createClientUploadIcon"
                                                    />
                                                }
                                                text={
                                                    userLoggedIn?.language_data
                                                        ?.customer
                                                        ?.drag_and_drop_click ||
                                                    "Drag and drop a file here or click"
                                                }
                                                showClose={
                                                    mode !== "view"
                                                        ? true
                                                        : false
                                                }
                                                edit={
                                                    mode === "edit" && favicon
                                                        ? true
                                                        : false
                                                }
                                                handleDelete={
                                                    handleCloseFavicon
                                                }
                                                handleFileUpload={
                                                    handleFaviconUpload
                                                }
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            mt={9}
                            mb={2}
                            pr={4}
                            item
                            xs={12}
                            sx={{
                                justifyContent: "flex-end",
                                display: "flex",
                            }}
                        >
                            <div style={{ paddingRight: "10px" }}>
                                <ButtonComponent
                                    id="createClientCancelButton"
                                    title={"Cancel"}
                                    variant={"outlined"}
                                    onClick={() => navigate(ROUTES.HOME)}
                                />
                            </div>
                            {mode !== "view" && (
                                <div>
                                    <ButtonComponent
                                        title={
                                            mode === "edit"
                                                ? "Update"
                                                : "Submit"
                                        }
                                        variant={"contained"}
                                        onClick={() => handleSubmit()}
                                        id="createClientCreateButton"
                                    />
                                </div>
                            )}
                        </Grid>
                    </Grid>
                )}
            </Box>
        </Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,
        commonDropdown: state?.auth?.commonDropdown,
        selectedCustomer: state?.customer?.selectedCustomer,
        customerList: state.customer.customerList,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            createClientAction,
            updateCustomerById,
            setSelectedCustomer,
            getCustomerById,
            getCustomerList,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateClient);
