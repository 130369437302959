import React from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableComponent from "../../../../../components/Table/TableComponent";
import { connect } from "react-redux";
import {Translate } from 'react-auto-translate';
const PartiesTable = (props) => {
    const {data,userLoggedIn } = props
    const headerList = [
        {
            id: 1,
            name: <Translate>Party</Translate>,
            headerClass: "tableHeading",
            accessibleKey: "party",
            rowClass: "typo4",
            headerAlign: "center",
            rowAlign: "center",
        },
        {
            id: 2,
            name: <Translate>Information/Response/Name</Translate>,
            headerClass: "tableHeading",
            accessibleKey: "information",
            rowClass: "typo4",
            headerAlign: "center",
            rowAlign: "center",
            buttonType: "view",
        },
        {
            id: 3,
            name: <Translate>Collar Color</Translate>,
            headerClass: "tableHeading",
            accessibleKey: "collar",
            rowClass: "typo4",
            headerAlign: "center",
            rowAlign: "center",
            buttonType: "view",
        },
        {
            id: 4,
            name: <Translate>Explanation of Collar Color</Translate>,
            headerClass: "tableHeading",
            accessibleKey: "collar_description",
            rowClass: "typo4",
            headerAlign: "center",
            rowAlign: "center",
            buttonType: "view",
        },
        {
            id: 5,
            name: <Translate>Phase</Translate>,
            headerClass: "tableHeading",
            accessibleKey: "phase",
            rowClass: "typo4",
            headerAlign: "center",
            rowAlign: "center",
            buttonType: "view",
        },
    ];

    const partiesList = [
        { party_name: "Contracting Business", response: "L1C" },
        { party_name: "Contractor", response: "John Doe" },
        { party_name: "Hiring Party", response: "RCP" },
        { party_name: "Service Recipient", response: "Johnny Doe" },
        { party_name: "Manager", response: "John Smith" },
        { party_name: "Other Party(ies)", response: "DCP" },
    ];

    return (
        <TableComponent
            headerList={headerList}
           
        >
            {data?.length &&
                data.map((row, index) => (
                    <TableRow
                        key={row.name}
                        className={index % 2 !== 0 ? "tertiaryBackground" : ""}
                    >
                        {headerList.map((obj) => (
                            <TableCell
                                component="th"
                                key={obj?.name}
                                align={obj?.rowAlign}
                                className={obj?.rowClass}
                            >
                                <Translate>{row[obj?.accessibleKey]}</Translate>
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
        </TableComponent>
    );
};
const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,
    };
};
export default connect(mapStateToProps, null) (PartiesTable);
