import React from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOffOutlinedIcon from "@mui/icons-material/PersonOffOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import HierarchyLogo from "../../../assets/Images/Icons/HierarchyLogo.png";
import NoImage from "../../../assets/Images/Logo/NoImage.png";
import TooltipComponent from "../../components/ToolTip/TooltipComponent";
import { connect } from "react-redux";
import {Translate} from 'react-auto-translate';

function CustomerCard(props) {
    const {
        customer,
        handleDelete,
        handleEdit,
        handleAdd,
        handleUsers,
        handleSettings,
        handleHierarchy,
        permission,
        userPermission,
        hierarchyPermission,
        permissionDenied,
        userLoggedIn,
        status
    } = props;

    return (

        <Paper elevation={3} className="cardPaper">
        <Grid container>
          <Grid item xs={12} className="cardHeader">
            <Grid container>
              <Grid item xs={12}>
                <Typography className="cardHeading">
                  <Translate>{customer?.client_type}</Translate>
                </Typography>
              </Grid>
              <Grid container>
                <Grid item xs={5}>
                  <TooltipComponent
                    title={<Translate>{userLoggedIn?.language_data?.user?.manage_user || "Manage User"}</Translate>}
                    arrow={true}
                  >
                    <Typography
                      id="cutomerCardViewUsers"
                      className="customerNoOfUsers"
                      onClick={() => handleUsers(customer)}
                    >
                      <Translate>{userLoggedIn?.language_data?.role?.user_count || "No. of Users"}</Translate>:{" "}
                      {customer?.users_count || 0}
                    </Typography>
                  </TooltipComponent>
                </Grid>
                <Grid item xs={7}>
                  <Typography className="customerClientId">
                    <Translate>Client ID</Translate>: {customer?.client_id}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} display={"flex"} justifyContent={"center"}>
              <div className="logoDiv">
                {customer?.logo ? (
                  <img
                    className="logoImg"
                    src={customer?.logo}
                    alt={<Translate>L1C compliance</Translate>}
                  />
                ) : (
                  <img
                    src={NoImage}
                    alt={<Translate>L1C compliance</Translate>}
                    className="logoImg"
                  />
                )}
              </div>
            </Grid>
            <Grid item xs={12}>
              <TooltipComponent title={<Translate>View Customer</Translate>} arrow={true}>
                <Typography
                  id="customerCardViewClient"
                  className="typo1"
                  onClick={() =>
                    permission?.read
                      ? handleEdit(customer, "view")
                      : permissionDenied()
                  }
                >
                  <Translate>{customer?.company_name}</Translate>
                </Typography>
              </TooltipComponent>
            </Grid>
            <Grid item xs={12}>
              <Typography className="typo2">
                {customer?.phone}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className="typo2">
                {customer?.email || <Translate>---</Translate>}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                className={
                  customer?.status === "active"
                    ? "typo3"
                    : "typo5"
                }
              >
                {customer?.status}
              </Typography>
            </Grid>
            <Grid container spacing={2} className="actionBtn">
              {
                status == 'inactive' ? null :
                <>
                <TooltipComponent arrow={true} title={<Translate>Add User</Translate>}>
                <IconButton
                  id="customerCardAddUser"
                  className="iconButton"
                  onClick={() =>
                    userPermission?.create
                      ? handleAdd(customer)
                      : permissionDenied()
                  }
                  disabled={
                    customer?.status === "active" ? false : true
                  }
                >
                  <PersonAddAltOutlinedIcon />
                </IconButton>
              </TooltipComponent>
              <TooltipComponent arrow={true} title={<Translate>Edit Customer</Translate>}>
                <IconButton
                  id="customerCardEditCustomer"
                  className="iconButton"
                  onClick={() =>
                    permission?.edit
                      ? handleEdit(customer, "edit")
                      : permissionDenied()
                  }
                >
                  <EditOutlinedIcon />
                </IconButton>
              </TooltipComponent>
              </>
              }
              <TooltipComponent arrow={true} title={<Translate>Hierarchy</Translate>}>
                <IconButton
                  id="customerCardViewHierarchy"
                  onClick={
                    () =>
                      handleHierarchy(customer)
                  }
                >
                  <img
                    src={HierarchyLogo}
                    alt={<Translate>Hierarchy</Translate>}
                    className="hierarchyLogo"
                  />
                </IconButton>
              </TooltipComponent>
              {
                status == 'inactive'? null :
                <TooltipComponent arrow={true} title={<Translate>Settings</Translate>}>
                <IconButton
                  id="customerCardSettings"
                  className="iconButton"
                  onClick={() => handleSettings(customer)}
                >
                  <SettingsOutlinedIcon />
                </IconButton>
              </TooltipComponent>
              }
              
              <TooltipComponent
                arrow={true}
                title={
                  customer?.status === "active"
                    ? <Translate>{userLoggedIn?.language_data?.user?.deactivate_customer || "Deactivate Customer"}</Translate>
                    : <Translate>Activate Customer</Translate>
                }
              >
                <IconButton
                  id="customerCardDeleteClient"
                  className="iconButton"
                  onClick={() =>
                    permission?.delete
                      ? handleDelete(customer)
                      : permissionDenied()
                  }
                >
                  {customer?.status === "active" ? (
                    <PersonOffOutlinedIcon />
                  ) : (
                    <ManageAccountsOutlinedIcon />
                  )}
                </IconButton>
              </TooltipComponent>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      
    );
}
const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,
    };
};

export default connect(mapStateToProps, null) (CustomerCard);
