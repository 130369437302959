import React from "react";
import Navbar from "../../common/Header/Navbar";
import { connect } from "react-redux";
function Products(props) {
    const {
        userLoggedIn
    } = props;
    return (
        <>
            <Navbar />
            <div
                style={{
                    width: "100%",
                    height: "600px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "26px",
                    fontWeight: "600",
                }}
            >
                {userLoggedIn?.language_data?.documentation?.coming_soon || "Coming Soon"}
            </div>
        </>
    );
}
const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,

    };
};

export default connect(mapStateToProps, null) (Products);
