import React from "react";
import Checkbox from "@mui/material/Checkbox";
import "./CheckboxComponent.scss";

const CheckboxComponents = (props) => {
    const { name, onChange, color, checked, value, sx, disabled, label, id } =
        props;

    return (
        <Checkbox
            onChange={onChange}
            name={name}
            id={id}
            value={value}
            color={color}
            checked={checked}
            sx={sx}
            disabled={disabled}
            label={label}
            className="checkboxComponent"
        />
    );
};

export default CheckboxComponents;
