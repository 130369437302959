import React from "react";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import "./DropDown.scss";

function DropDownComponent(props) {
    const {
        label,
        children,
        name,
        onChange,
        onOpen,
        onClose,
        value,
        id,
        fullWidth,
        required,
        sx,
        variant,
        className,
        additionalClass,
        disabled,
        readOnly,
        defaultValue,
        labelId,
        size,
    } = props;

    return (
        <FormControl
            fullWidth={fullWidth}
            className={
                variant === "outlined"
                    ? "dropDownOutline"
                    : className || `dropdownComponent ${additionalClass || ""}`
            }
            sx={sx}
            inputlabelprops={{ shrink: true }}
            size={size}
        >
            {label && (
                <InputLabel
                    id={id}
                    required={required}
                    className="labelClass"
                    shrink={true}
                >
                    {label}
                </InputLabel>
            )}
            <Select
                labelId={labelId}
                id={id}
                label={label}
                name={name}
                onChange={onChange}
                value={value}
                variant={variant || "filled"}
                required={required}
                disabled={disabled}
                readOnly={readOnly}
                defaultValue={defaultValue}
                onOpen={onOpen}
                onClose={onClose}
            >
                {children}
            </Select>
        </FormControl>
    );
}

export default DropDownComponent;
