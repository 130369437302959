import * as Actions from "../Action/GetDctAssesmentAction";

const initialState = {
    dctData: null,
};

const GetDctAssesmentReducer = (state = initialState, action) => {
    switch (action?.type) {
        case Actions.GET_DCT_DATA: {
            return {
                ...state,
                dctData: action.payload,
            };
        }
       
        default: {
            return state;
        }
    }
};

export default GetDctAssesmentReducer;
