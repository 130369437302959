import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import VideoCameraBackOutlinedIcon from "@mui/icons-material/VideoCameraBackOutlined";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import TextFieldsOutlinedIcon from "@mui/icons-material/TextFieldsOutlined";
import AudioFileOutlinedIcon from "@mui/icons-material/AudioFileOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import { getFileValidation } from "../utils/CommonUtils";

export default function FileUpload(props) {
    const {
        file,
        setFiles,
        validation,
        uploadIcon,
        keyName,
        handleDelete,
        text,
        className,
        disabled,
        handleFileUpload,
        showClose,
        showEdit,
        id
    } = props;
    const [edit, setEdit] = useState(true);

    const { getRootProps, getInputProps } = useDropzone({
        accept: validation
            ? getFileValidation(validation)
            : "image/*,video/*,application/pdf,audio/*",
        maxSize: "1000000000",
        onDrop: (acceptedFiles) => {
            if (handleFileUpload) handleFileUpload(acceptedFiles);
            else
                setFiles((prevState) => ({
                    ...prevState,
                    [keyName]: acceptedFiles.map((fileObj) =>
                        Object.assign(fileObj, {
                            preview: URL.createObjectURL(fileObj),
                        })
                    ),
                }));
        },
    });

    function renderIcon(fileTypes) {
        const type = fileTypes?.split("/")[0];
        switch (type) {
            case "image":
                return <ImageOutlinedIcon />;

            case "video":
                return <VideoCameraBackOutlinedIcon />;

            case "application":
                return <PictureAsPdfOutlinedIcon />;

            case "audio":
                return <AudioFileOutlinedIcon />;

            default:
                return <TextFieldsOutlinedIcon />;
        }
    }

    const renderCloseButton = () => (
        <IconButton
            onClick={(event) => handleDelete(event)}
            className="iconButtonFileUpload"
        >
            <CloseOutlinedIcon className="fileClose" />
        </IconButton>
    );

    const renderEditButton = (iconClass) => (
        <IconButton
            className="iconButtonFileUpload"
            onClick={() => setEdit(true)}
        >
            <EditOutlinedIcon className={iconClass} />
        </IconButton>
    );

    return (
        <div
            {...getRootProps({
                onClick: (event) =>
                    !edit || disabled ? event.stopPropagation() : null,
            })}
            style={{ width: "100%", height: "100%", display: "flex" }}
        >
            <div className="fileUploadDiv">
                <input {...getInputProps()} accept={validation} />
                {!file?.length || !file ? (
                    <Grid
                        container
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        sx={{ cursor: disabled ? "no" : "pointer" }}
                    >
                        <Grid
                            item
                            xs={12}
                            display={"flex"}
                            justifyContent={"center"}
                            alignItems={"center"}
                        >
                            {uploadIcon}
                        </Grid>
                        {text && (
                            <Typography
                                className={className || "fileUploadText"}
                            >
                                {text}
                            </Typography>
                        )}
                    </Grid>
                ) : file[0]?.type?.includes("video") ? (
                    <div>
                        <video
                            src={URL.createObjectURL(file[0])}
                            alt="Not Found"
                        ></video>
                        <Typography>
                            {file[0]?.filename || file[0]?.name || ""}
                        </Typography>
                    </div>
                ) : file[0]?.type?.includes("audio") ? (
                    <div>
                        <AudioFileOutlinedIcon sx={{ fontSize: 80 }} />
                        <Typography>
                            {file[0]?.filename || file[0]?.name || ""}
                        </Typography>
                    </div>
                ) : file[0]?.type?.includes("csv") ||
                  file[0]?.name?.split(".").pop() === "csv" ? (
                    <div>
                        <DescriptionOutlinedIcon sx={{ fontSize: 80 }} />
                        <Typography>
                            {file[0]?.filename || file[0]?.name || ""}
                        </Typography>
                    </div>
                ) : file[0]?.type?.includes("pdf") ? (
                    <Grid container height={"80%"}>
                        <Grid item xs={8}>
                            <PictureAsPdfOutlinedIcon sx={{ fontSize: 80 }} />
                            <Typography>
                                {file[0]?.filename || file[0]?.name || ""}
                            </Typography>
                        </Grid>
                        <Grid item>{renderEditButton("fileClose")}</Grid>
                        <Grid item>{renderCloseButton()}</Grid>
                    </Grid>
                ) : file[0]?.type?.includes("application") ? (
                    <Grid container height={"80%"}>
                        <Grid item xs={8}>
                            <TextFieldsOutlinedIcon sx={{ fontSize: 80 }} />
                            <Typography>
                                {file[0]?.filename || file[0]?.name || ""}
                            </Typography>
                        </Grid>
                        <Grid item>{renderEditButton("fileClose")}</Grid>
                        <Grid item>{renderCloseButton()}</Grid>
                    </Grid>
                ) : (
                    (file[0]?.type?.includes("image") ||
                        file?.type?.includes("image") ||
                        file) && (
                        <Grid container className="fileUploadGrid">
                            <Grid
                                item
                                xs={8}
                                display={"flex"}
                                justifyContent={"center"}
                                alignItems={"center"}
                            >
                                {file[0]?.type === "" || file?.type === "" ? (
                                    <>
                                        <DescriptionOutlinedIcon
                                            sx={{ fontSize: 80 }}
                                        />
                                        <Typography>
                                            {file[0]?.filename ||
                                                file[0]?.name ||
                                                ""}
                                        </Typography>
                                    </>
                                ) : (
                                    <img
                                        className={
                                            !showEdit
                                                ? "fileUploadImage"
                                                : "fileUplaodImg"
                                        }
                                        alt="Not Found"
                                        src={
                                            file?.length
                                                ? file[0]?.preview ||
                                                  URL.createObjectURL(file[0])
                                                : file?.content || file
                                        }
                                    />
                                )}
                            </Grid>
                            {showEdit ? (
                                <Grid
                                    item
                                    xs={1.5}
                                    display={"flex"}
                                    justifyContent={"start"}
                                    alignItems={"flex-end"}
                                >
                                    {renderEditButton("fileEdit")}
                                </Grid>
                            ) : (
                                <Grid
                                    item
                                    xs={1.5}
                                    display={"flex"}
                                    justifyContent={"center"}
                                    alignItems={"flex-start"}
                                >
                                    {renderEditButton("fileClose")}
                                </Grid>
                            )}
                            <Grid
                                item
                                xs={1}
                                display={"flex"}
                                justifyContent={"center"}
                                alignItems={"flex-start"}
                            >
                                {showClose && renderCloseButton()}
                            </Grid>
                        </Grid>
                    )
                )}
            </div>
        </div>
    );
}