import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Grid from "@mui/material/Grid";
import ButtonComponent from "../../components/ButtonComponent";
import TextFieldComponent from "../../components/TextField/TextFieldComponent";
import ResponseAnswerMqs from "./ResponseAnswerMqs";
import TransferMqs from "./TransferMqs";
import {
    storeQuestion,
    setSelectedQuestion,
    updateQuestion,
} from "../../store/Action/MqsAction";
import DialogComponent from "../../components/DialogsBox/DialogComponent";

function AddQuestionDialog(props) {
    const {
        open,
        categoryData,
        cancelButtonAction,
        storeQuestion,
        selectedQuestion,
        updateQuestion,
        userLoggedIn
    } = props;
    const mode = selectedQuestion?.mode || null;
    const [state, setState] = useState({
        category_id: mode ? selectedQuestion?.category_id : categoryData?.id,
        explanation: mode ? selectedQuestion?.explanation : "",
        question: mode ? selectedQuestion?.question : "",
        question_types: "1",
        options_id: mode ? selectedQuestion?.options_id : "",
        responses: mode ? selectedQuestion?.responses : [],
        jurisdiction: mode ? selectedQuestion?.jurisdiction : [],
    });
    const { explanation, question, jurisdiction, options_id, responses } =
        state;
    if (selectedQuestion && mode && question === "") {
        setState((prevState) => ({ ...prevState, ...selectedQuestion }));
    }
    async function handlequestionSubmit() {
        let res;
        if (mode === "edit") res = await updateQuestion(state);
        else {
            res = await storeQuestion(state);
        }
        if (res) cancelButtonAction();
    }
    return (
        <div>
            <DialogComponent
                className="questionDialog"
                open={open}
                handleClose={cancelButtonAction}
                title={categoryData?.category}
                content={
                    <>
                       <Grid container>
                        <Grid
                            item
                            md={12}
                            xs={12}
                            ml={3}
                            mt={1}
                            sx={{ height: "100px" }}
                            className="questionGrid"
                        >
                            <TextFieldComponent
                            id="AddQuetionsGrid"
                                sx={{ height: "100px" }}
                                label={"Question"}
                                multiline={true}
                                onChange={(event) =>
                                    setState((prevState) => ({
                                        ...prevState,
                                        question: event.target.value,
                                    }))
                                }
                                value={question}
                            />
                        </Grid>

                        <Grid
                            item
                            ml={3}
                            md={12}
                            xs={12}
                            mt={1}
                            className="questionGrid"
                        >
                            <TextFieldComponent
                            id="addQuestionDescription"
                                sx={{ height: "100px" }}
                                label={"Question Description"}
                                onChange={(event) =>
                                    setState((prevState) => ({
                                        ...prevState,
                                        explanation: event.target.value,
                                    }))
                                }
                                value={explanation}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item md={12} xs={12} mt={2} ml={3}>
                            <Typography className="selectText">
                                Select {userLoggedIn?.language_data?.customer?.jurisdiction || "Jurisdiction"}
                            </Typography>
                        </Grid>
                        <Grid item md={12} xs={12} ml={1}>
                            <TransferMqs
                                state={state}
                                setState={setState}
                                mode={mode}
                                value={jurisdiction}
                            />
                        </Grid>
                    </Grid>
                    <Grid>
                        <ResponseAnswerMqs
                            state={state}
                            setState={setState}
                            mode={mode}
                            value={options_id}
                        />
                    </Grid>
                    </>
                }
                sxaction={{ paddingX: 4, paddingBottom: 2 }}
                action={<>
                   <ButtonComponent title={"Cancel"}  className="button_outline"  onClick={() => cancelButtonAction()} />
                    <ButtonComponent
                        title={mode === "edit" ? "Update" : "Submit"}
                        variant={"contained"}
                        onClick={() => handlequestionSubmit()}
                    />
                   </>}
            />
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,
        selectedQuestion: state?.mqs?.selectedQuestion,
    };
};
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            storeQuestion,
            setSelectedQuestion,
            updateQuestion,
        },
        dispatch
    );
};
export default connect(mapStateToProps, mapDispatchToProps)(AddQuestionDialog);
