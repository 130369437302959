import React from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOffOutlinedIcon from "@mui/icons-material/PersonOffOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import IconButton from "@mui/material/IconButton";
import HierarchyLogo from "../../../assets/Images/Icons/HierarchyLogo.png";
import TableComponent from "../../components/Table/TableComponent";
import TooltipComponent from "../../components/ToolTip/TooltipComponent";
import { connect } from "react-redux";
import { Translate } from "react-auto-translate";

function CustomerTableView(props) {
    const {
        customerList,
        handleDelete,
        handleAdd,
        handleEdit,
        handleUsers,
        handleSettings,
        handleHierarchy,
        permission,
        userPermission,
        hierarchyPermission,
        permissionDenied,
        userLoggedIn,
        status,
    } = props;

    const headerList = [
        {
            id: 1,
            name: "Id",
            headerClass: "tableHeading",
            accessibleKey: "client_id",
            rowClass: "typo4",
            headerAlign: "center",
            rowAlign: "center",
        },
        {
            id: 2,
            name: "Customer Name",
            headerClass: "tableHeading customerNameTableHeading",
            accessibleKey: "first_name",
            rowClass: "typo1",
            headerAlign: "center",
            rowAlign: "center",
            handleButton: handleEdit,
            buttonType: "view",
        },
        {
            id: 3,
            name: "Company",
            headerClass: "tableHeading",
            accessibleKey: "company_name",
            rowClass: "companyName",
            headerAlign: "center",
            rowAlign: "center",
        },
        {
            id: 4,
            name:
                userLoggedIn?.language_data?.customer?.conductor_type ||
                "Contractor Type",
            headerClass: "tableHeading conductorTypeTableHeading",
            accessibleKey: "client_type",
            rowClass: "typo4",
            headerAlign: "center",
            rowAlign: "center",
        },
        {
            id: 5,
            name: userLoggedIn?.language_data?.comman?.status || "Status",
            headerClass: "tableHeading",
            accessibleKey: "status",
            headerAlign: "center",
            rowAlign: "center",
        },
        {
            id: 6,
            name: "No. of Users",
            headerClass: "tableHeading noOfUserTableHeading",
            accessibleKey: "users_count",
            rowClass: "typo4 noOfUserTableContent",
            headerAlign: "center",
            rowAlign: "center",
            handleButton: handleUsers,
        },
    ];

    return (
        <TableComponent
            headerList={headerList}
            // serialNo={{
            //     name: <Translate>S.No.</Translate>,
            //     headerClass: "tableHeading",
            //     headerAlign: "center",
            //     rowClass: "typo4",
            //     rowAlign: "center",
            // }}
            extraHeaderRight={
                <TableCell
                    align="center"
                    className="tableHeading actionTableHeading"
                >
                    <Translate>Action</Translate>
                </TableCell>
            }
        >
            {customerList?.length &&
                customerList.map((row, index) => (
                    <TableRow
                        key={row.name}
                        className={index % 2 !== 0 ? "tertiaryBackground" : ""}
                    >
                        {/* <TableCell align="center" className="typo4">
                            {index + 1}
                        </TableCell> */}
                        {headerList.map((obj) => (
                            <TableCell
                                id={`customerTable ${obj?.accessibleKey} ${index}`}
                                component="th"
                                key={obj?.name}
                                align={obj?.rowAlign}
                                className={
                                    obj?.rowClass
                                        ? obj?.rowClass
                                        : row?.status === "active"
                                        ? "typo3"
                                        : "typo5"
                                }
                                onClick={() =>
                                    (obj?.accessibleKey === "first_name" &&
                                        permission?.read) ||
                                    (obj?.accessibleKey === "users_count" &&
                                        userPermission?.read)
                                        ? obj.handleButton(row, obj?.buttonType)
                                        : obj?.accessibleKey === "first_name" ||
                                          obj?.accessibleKey === "users_count"
                                        ? permissionDenied()
                                        : null
                                }
                            >
                                <Translate>
                                    {obj?.accessibleKey === "first_name"
                                        ? `${
                                              row?.first_name +
                                              " " +
                                              row?.last_name
                                          }`
                                        : row[obj?.accessibleKey]}
                                </Translate>
                            </TableCell>
                        ))}
                        <TableCell align="center">
                            {status == "inactive" ? null : (
                                <>
                                    {" "}
                                    <TooltipComponent
                                        title={<Translate>Add User</Translate>}
                                        arrow={true}
                                    >
                                        <IconButton
                                            id="customerTableAddUser"
                                            className="iconButton"
                                            onClick={() =>
                                                userPermission?.create
                                                    ? handleAdd(row, "add")
                                                    : permissionDenied()
                                            }
                                        >
                                            <PersonAddAltOutlinedIcon />
                                        </IconButton>
                                    </TooltipComponent>
                                    <TooltipComponent
                                        title={
                                            <Translate>Edit Customer</Translate>
                                        }
                                        arrow={true}
                                    >
                                        <IconButton
                                            id="customerTableEditClent"
                                            className="iconButton"
                                            onClick={() =>
                                                permission?.edit
                                                    ? handleEdit(row, "edit")
                                                    : permissionDenied()
                                            }
                                        >
                                            <EditOutlinedIcon />
                                        </IconButton>
                                    </TooltipComponent>
                                </>
                            )}
                            <TooltipComponent
                                title={<Translate>Hierarchy</Translate>}
                                arrow={true}
                            >
                                <IconButton
                                    className="iconButton"
                                    id="customerTableHierarchy"
                                    onClick={
                                        () =>
                                            // hierarchyPermission?.edit ||
                                            // hierarchyPermission?.read
                                            //     ?
                                            handleHierarchy(row)
                                        // : permissionDenied()
                                    }
                                >
                                    <img
                                        className="hierarchyLogo"
                                        src={HierarchyLogo}
                                        alt={
                                            <Translate>HierarchyLogo</Translate>
                                        }
                                    />
                                </IconButton>
                            </TooltipComponent>
                            {status == "inactive" ? null : (
                                <TooltipComponent
                                    title={<Translate>Settings</Translate>}
                                    arrow={true}
                                >
                                    <IconButton
                                        id="customerTableSettings"
                                        className="iconButton"
                                        onClick={() =>
                                            handleSettings(row, "settings")
                                        }
                                    >
                                        <SettingsOutlinedIcon />
                                    </IconButton>
                                </TooltipComponent>
                            )}
                            
                            <TooltipComponent
                                title={
                                    userLoggedIn?.user?.client_id ===
                                    row.id ? null : row?.status === "active" ? (
                                        <Translate>
                                            {userLoggedIn?.language_data?.user
                                                ?.deactivate_customer ||
                                                "Deactivate Customer"}
                                        </Translate>
                                    ) : (
                                        <Translate>Activate Customer</Translate>
                                    )
                                }
                                arrow={true}
                            >
                                {userLoggedIn?.user?.client_id === row?.id ? null : (
                                    <IconButton
                                        id="customerTableDelete"
                                        className="iconButton"
                                        onClick={() =>
                                            permission?.delete
                                                ? handleDelete(row, "delete")
                                                : permissionDenied()
                                        }
                                    >
                                        {row?.status === "active" ? (
                                            <PersonOffOutlinedIcon />
                                        ) : (
                                            <ManageAccountsOutlinedIcon />
                                        )}
                                    </IconButton>
                                )}
                            </TooltipComponent>
                        </TableCell>
                    </TableRow>
                ))}
        </TableComponent>
    );
}

const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,
    };
};

export default connect(mapStateToProps, null)(CustomerTableView);
