import {
    Box,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Radio,
    RadioGroup,
    Typography,
    Link,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import Navbar from "../../../common/Header/Navbar";
import { connect } from "react-redux";
import { json, useLocation, useNavigate, useParams } from "react-router-dom";
import { agreeOrNotOptions } from "../../../utils/CommonData";
import "./TestQuestion.scss";
import CheckboxComponents from "../../../components/Checkbox/CheckboxComponent";
import ButtonComponent from "../../../components/ButtonComponent";
import DialogComponent from "../../../components/DialogsBox/DialogComponent";
import LoadingComponent from "../../../components/Loading/LoadingComponent";
import { submitTest } from "../../../store/Action/MqsAction";
import { bindActionCreators } from "redux";
import SuccessfullModel from "../../../components/DialogsBox/SuccessfullModel";
import Timer from "./Timer";
import { ROUTES } from "../../../router/RouteList";
import {
    addResponse,
    checkAlternativeResponse,
    getContingentQuestions,
    getWordDefByGlosary,
    setStartArrayValue,
    setTestData,
    skipQuestionRes,
} from "../../../store/Action/TestAction";
import { useHotkeys } from "react-hotkeys-hook";
import { Translate } from "react-auto-translate";
import TooltipComponent from "../../../components/ToolTip/TooltipComponent";
import LinearProgress from "@mui/material/LinearProgress";
import Color from "../../../../assets/styles/Color.scss";
import { updateRepressedQuestion } from "../../../store/Action/AssessmentSettingAction";

const MainTestQuestion = (props) => {
    const {
        testQuestionList,
        submitTest,
        addResponse,
        skipQuestionRes,
        setTestData,
        testResponses,
        testArrayIndex,
        leftTimer,
        checkAlternativeResponse,
        getContingentQuestions,
        updateRepressedQuestion,
        setStartArrayValue,
    } = props;

    const [diloagTitle, setDiloagTitle] = useState('');
    const [diloagOpen, setDiloagOpen] = useState(false);
    const [diloagContent, setDiloagContent] = useState('');

    const [stateMainTest, setStateMainTest] = useState({
        accept: true,
        submitDialogOpen: false,
        timer: {
            seconds: 59,
            minutes: 1,
        },
        isOpenNoInfoDialog: false,
        isLoading: false,
        isLoader: false,
        nextAdavanceQuestion: false,
        start: 0,
        selectedValue: null,
        pausedTimer: false,
        timeOutPopUp: false,
        timeOut: false,
        selectedResponse: null,
        updateState: false,
        questionContainDialog: {
            open: false,
            content: null,
            title: null,
        },
        testQustion: null,
        glosarryData: null,
        showAdavanceQuestion: false,
        alternativeResponse: null,
        countAlternativeQuestion: [],
        repressedIDS: [],
        showContigentQuestion: {
            show: false,
            contigentQueIndex: 0,
            dipendentResponses: [],
        },
        questiosBeforeRepressedList: [],
        showSubmitionAgrement: false,
    });
    const {
        isLoading,
        isLoader,
        isOpenNoInfoDialog,
        submitDialogOpen,
        showAdavanceQuestion,
        showContigentQuestion,
        showSubmitionAgrement,
    } = stateMainTest;
    const location = useLocation();
    const contractor_id = location?.state?.contractor_id;
    useEffect(() => {
        callTimeOut();
        //eslint-disable-next-line
    }, [stateMainTest.timer.minutes === 0, stateMainTest.timer.seconds === 1]);
    const callTimeOut = () => {
        if (
            stateMainTest.timer.minutes === 0 &&
            stateMainTest.timer.seconds === 1
        ) {
            setStateMainTest((prevState) => ({
                ...prevState,
                timeOutPopUp: true,
                timeOut: true,
                pausedTimer: true,
            }));
        }
    };
    const navigate = useNavigate();
    const params = useParams();
    useEffect(() => {
        callUpdateState();
        //eslint-disable-next-line
    }, []);

    // useEffect(()=>{updateStart()},[testArrayIndex])
    // const updateStart = () =>{
    //     setStateMainTest((prevState)=>({...prevState,start:testArrayIndex}))
    // }

    const callUpdateState = async () => {
        const guid = params?.guid;
        const user_id = params?.id;
        const now = new Date();
        const unixTimestamp = Math.floor(now.getTime() / 1000);
        if (testArrayIndex && testResponses.test_response.length) {
            setStateMainTest((prevState) => ({
                ...prevState,
                start: testArrayIndex,
                timer: leftTimer,
                testResponses: testResponses,
            }));
        } else {
            if (!testQuestionList) {
                setStateMainTest((prevState) => ({
                    ...prevState,
                    isOpenNoInfoDialog: true,
                }));
            } else {
                if (testQuestionList) {
                    setStateMainTest((prev) => ({
                        ...prev,
                        timer: {
                            seconds: 59,
                            minutes: testQuestionList.max_time - 1,
                        },
                    }));
                    let data = {
                        guid: guid,
                        user_id: user_id,
                        test_id: testQuestionList?.test_id,
                        task_id: localStorage.getItem("task_id"),
                        mode:
                            location.state.mode || localStorage.getItem("mode"),
                        test_type: testQuestionList?.test_type,
                        role_id: parseInt(testQuestionList?.role_id),
                        testStartTime: unixTimestamp,
                        test_response: [],
                        contractor_id: contractor_id,
                    };
                    await setTestData(data);
                }
                if (testQuestionList) {
                    if (
                        testQuestionList.mqs[stateMainTest.start]
                            .apply_alternative_response_content
                    ) {
                        checkAdavanceQuestion();
                    }
                }
            }
        }
    };

    useEffect(() => {
        const handleKeyPress = (event) => {
            // Check if the pressed key is a number key (1, 2, 3, ...)
            if (/^\d$/.test(event.key)) {
                const number = parseInt(event.key);
                const options = showAdavanceQuestion
                    ? testQuestionList?.mqs[stateMainTest?.start]
                          ?.alternative_content_responses
                    : testQuestionList?.mqs[stateMainTest?.start]
                          ?.available_response;
                if (number >= 1 && number <= options?.length) {
                    // Set the selected response based on the pressed number key
                    const selectedOption = options[number - 1];
                    if (showAdavanceQuestion) {
                        setStateMainTest((prevState) => ({
                            ...prevState,
                            alternativeResponse: selectedOption?.value,
                        }));
                    } else {
                        setStateMainTest((prevState) => ({
                            ...prevState,
                            selectedResponse: selectedOption?.value,
                        }));
                        handlerQustionAnswer(
                            selectedOption?.value,
                            testQuestionList?.mqs[stateMainTest?.start]
                        );
                    }
                }
            }
        };
        window.addEventListener("keydown", handleKeyPress);
        return () => {
            window.removeEventListener("keydown", handleKeyPress);
        };
    }, [stateMainTest]);
    useEffect(() => {
        if (testResponses?.test_response) {
            let findIndex = testResponses?.test_response.findIndex(
                (obj) =>
                    obj.question_id ===
                    testQuestionList?.mqs[stateMainTest?.start]?.id
            );
            if (findIndex !== -1) {
                setStateMainTest((prevState) => ({
                    ...prevState,
                    selectedValue: testResponses?.test_response[findIndex],
                }));
            }
            if (testArrayIndex) {
                setStateMainTest((prevState) => ({
                    ...prevState,
                    start: testArrayIndex,
                }));
                setStartArrayValue(null);
            }
        }
        if (stateMainTest.selectedResponse) {
            setStateMainTest((prevState) => ({
                ...prevState,
                selectedResponse: null,
            }));
        }
        if (testQuestionList) {
            if (
                testQuestionList.mqs[stateMainTest.start]
                    ?.apply_alternative_response_content &&
                !testQuestionList?.mqs[stateMainTest.start]?.question
            ) {
                checkAdavanceQuestion();
            } else {
                setStateMainTest((prevState) => ({
                    ...prevState,
                    showAdavanceQuestion: false,
                }));
            }
        }

        handleHighlightWord();

        //eslint-disable-next-line
    }, [
        stateMainTest.start,
        stateMainTest.countAlternativeQuestion,
        testArrayIndex,
    ]);
    useEffect(() => {
        const preventDefault = (e) => {
            if (e.key === "Backspace" && stateMainTest.start > 0) {
                e.preventDefault();
                e.stopPropagation();
            }
        };
        window.addEventListener("keydown", preventDefault);
        return () => {
            window.removeEventListener("keydown", preventDefault);
        };
    }, [stateMainTest]);

    useEffect(() => {
        const preventDefault = (e) => {
            if (
                e.key === "Enter" &&
                stateMainTest.start + 1 === testQuestionList?.mqs?.length
            ) {
                e.preventDefault();
                e.stopPropagation();
            }
        };
        window.addEventListener("keydown", preventDefault);
        return () => {
            window.removeEventListener("keydown", preventDefault);
        };
    }, [stateMainTest]);

    const handleHighlightWord = () => {
        if (
            testQuestionList?.mqs[stateMainTest?.start]?.glosarry_words
                ?.length > 0 &&
            localStorage.getItem("languageCode") == "en"
        ) {
            const parts = testQuestionList?.mqs[
                stateMainTest?.start
            ].question.split(
                new RegExp(
                    `(${testQuestionList?.mqs[
                        stateMainTest?.start
                    ].glosarry_words.join("|")})`,
                    "gi"
                )
            );
            let tempKeyword = [];
            tempKeyword = parts.map((part, index) => {
                const filterWord = testQuestionList?.mqs[
                    stateMainTest?.start
                ].glosarry_words.findIndex((obj) => obj === part);
                const defination = (
                    <div
                        dangerouslySetInnerHTML={{
                            __html: testQuestionList?.mqs[stateMainTest?.start]
                                .glosarry_defination[filterWord],
                        }}
                    />
                );
                if (filterWord !== -1) {
                    return (
                        <TooltipComponent
                            arrow
                            title={defination}
                            sx={{
                                "& .MuiTooltip-tooltip": {
                                    maxWidth: "500px !important", // Adjust the value as needed
                                },
                            }}
                        >
                            <span
                                style={{
                                    color: "#1976D2",
                                    cursor: "pointer",
                                    maxWidth: 500,
                                }}
                                className="font_20_600 "
                            >
                                {part}
                            </span>
                        </TooltipComponent>
                    );
                } else {
                    return (
                        <span className="font_20_600  text-black"> {part}</span>
                    );
                }
            });
            setStateMainTest((prevState) => ({
                ...prevState,
                testQustion: tempKeyword,
            }));
        } else {
            setStateMainTest((prevState) => ({
                ...prevState,
                testQustion:
                    testQuestionList?.mqs[stateMainTest?.start]?.question,
            }));
        }
    };

    const disabledBtnSx = {
        background: "rgba(59, 59, 59, 0.5) !important",
    };
    const handlerSelectedResponse = (e) => {
        setStateMainTest((prevState) => ({
            ...prevState,
            selectedResponse: e?.target?.value,
        }));
        handlerQustionAnswer(
            e?.target?.value,
            testQuestionList?.mqs[stateMainTest?.start]
        );
    };
    const handleSkipResponse = async () => {
        let data = {
            response:
                stateMainTest?.selectedResponse ||
                stateMainTest?.selectedValue?.response,
            object: testQuestionList?.mqs[stateMainTest?.start],
        };
        await skipQuestionRes(data);
    };

    const handlePreviousClick = () => {
        setStateMainTest((prevState) => ({
            ...prevState,
            start: prevState.start - 1,
        }));
    };

    const handleEmptyResponse = () => {
        return;
    };
    useHotkeys(
        "Backspace",
        stateMainTest.start > 0 ? handlePreviousClick : handleEmptyResponse
    );
    const handleNextClick = async () => {
        if (
            testQuestionList?.mqs[stateMainTest?.start]
                ?.apply_alternative_response_content &&
            stateMainTest?.selectedResponse
        ) {
            await checkAdavanceQuestion();
        } else {
            if (
                testQuestionList?.mqs[stateMainTest?.start]
                    ?.contingent_ques_master &&
                stateMainTest.selectedResponse
            ) {
                await checkContingentQuestion();
            } else {
                handleSkipResponse();
                setStateMainTest((prevState) => ({
                    ...prevState,
                    start: prevState.start + 1,
                }));
            }
        }
    };
    const showSubmitionAgreement = () => {
        setStateMainTest((prevState) => ({
            ...prevState,
            showSubmitionAgrement: true,
            pausedTimer: true,
        }));
    };
    const handleSubmitClick = async () => {
        setStateMainTest((prevState) => ({
            ...prevState,
            showSubmitionAgrement: false,
            isLoading: true,
        }));
        await handleSkipResponse();
        await submitTest(testResponses);
        let data = {
            test_id: "",
            test_response: [],
            guid: null,
            test_type: "",
            user_id: null,
            role_id: null,
            testStartTime: null,
            task_id: null,
            contractor_id: null,
            mode: null,
        };
        if (contractor_id && testResponses["role_id"] == 44) {
            data["contractor_id"] = null;
        }
        await setTestData(data);
        handleCloseDialog();
    };

    const handleCloseDialog = () => {
        setStateMainTest((prevState) => ({
            ...prevState,
            isLoading: false,
        }));
        navigate(-1);
    };

    // const showGlossary = (term) => {
    //     // alert(`You clicked on: ${term}`);
    //     const a = document.querySelector('a'); // You can select the button in a more specific way based on your actual DOM structure
    //     const dataAction = a.getAttribute('data-action');
    //     const dataValue = a.getAttribute('data-value');
    //     console.log(dataAction);
    //     console.log(decodeURIComponent(dataValue));

    //     setDiloagTitle(dataAction);
    //     setDiloagContent(decodeURIComponent(dataValue).replace(/\+/g, ' '));
    //     setDiloagOpen(true);
    // };

    const handlerQustionAnswer = (e, questionObject) => {
        let copyResponse = testResponses?.test_response;
        let findDuplicateIndex = copyResponse.findIndex(
            (obj) => obj.question_id === questionObject.id
        );
        if (findDuplicateIndex !== -1) {
            copyResponse[findDuplicateIndex] = {
                question_id: questionObject.id,
                response: e,
            };
            setStateMainTest((prevState) => ({
                ...prevState,
                testResponses: {
                    ...testResponses,
                    test_type: testQuestionList.test_type,
                    test_id: testQuestionList.test_id,
                    test_response: copyResponse,
                },
            }));
        } else {
            let data = null;
            if (e) {
                data = {
                    question_id: questionObject.id,
                    response: e,
                };
            } else {
                data = {
                    question_id: questionObject.id,
                    response: null,
                };
            }
            setStateMainTest((prevState) => ({
                ...prevState,
                testResponses: {
                    ...testResponses,
                    test_type: testQuestionList.test_type,
                    test_id: testQuestionList.test_id,
                    test_response: [...testResponses?.test_response, data],
                },
            }));
        }
    };
    const handlerQuestionContainDialog = (content, title) => {
        setStateMainTest((prevState) => ({
            ...prevState,
            questionContainDialog: {
                open: true,
                content: content,
                title: title,
            },
        }));
    };

    useHotkeys("Enter", (e) => {
        e.preventDefault();
        e.stopPropagation();
        showAdavanceQuestion
            ? handleNextAdavanceQuestion()
            : stateMainTest.start + 1 === testQuestionList?.mqs?.length
            ? handleEmptyResponse()
            : handleNextClick();
    });

    const handleReviewClick = async () => {
        await handleSkipResponse();
        navigate(ROUTES.REVIEW_RESPONSE, {
            state: {
                ReviewData: {
                    ...location.state.assessmentSummaryData,
                    total_questions: testQuestionList?.total_questions,
                    leftTimeReview: stateMainTest.timer,
                    start: stateMainTest.start,
                },
                type: "edit",
            },
        });
    };
    const normalise =
        ((stateMainTest.start + 1) / testQuestionList?.mqs.length) * 100;
    function LinearProgressWithLabel(props) {
        return (
            <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box sx={{ width: "100%", mr: 1, position: "relative" }}>
                    <LinearProgress
                        variant="determinate"
                        {...props}
                        className="linerProgresStyle"
                        sx={{
                            "& .MuiLinearProgress-bar": {
                                backgroundColor: Color.primary,
                            },
                        }}
                    />
                </Box>
                <Box sx={{ minWidth: 35, position: "absolute", left: "50%" }}>
                    <Typography
                        className="font_18_600"
                        color={"black"}
                    >{`${Math.round(props.value)}%`}</Typography>
                </Box>
            </Box>
        );
    }
    const checkAdavanceQuestion = async () => {
        let getId = testQuestionList.mqs[stateMainTest.start].id;
        const checkId = stateMainTest.countAlternativeQuestion.find(
            (obj) => obj === getId
        );
        if (checkId) {
            setStateMainTest((prevState) => ({
                ...prevState,
                showAdavanceQuestion: true,
            }));
        } else {
            setStateMainTest((prevState) => ({
                ...prevState,
                showAdavanceQuestion: true,
                countAlternativeQuestion: [
                    ...stateMainTest.countAlternativeQuestion,
                    getId,
                ],
            }));
        }
    };
    const handlerAvalableResponse = (e) => {
        setStateMainTest((prevState) => ({
            ...prevState,
            alternativeResponse: e.target.value,
        }));
    };
    const handleNextAdavanceQuestion = async () => {
        const filteredIDs = testQuestionList.mqs.map((obj) => {
            return obj.id;
        });
        let mergerArray = filteredIDs.concat(
            stateMainTest.repressedIDS,
            stateMainTest.countAlternativeQuestion
        );
        const uniqueArray = [...new Set(mergerArray)];
        setStateMainTest((prevState) => ({
            ...prevState,
            pausedTimer: true,
            // isLoading: true,
            // nextAdavanceQuestion: true,
        }));
        let data = {
            question_id: testQuestionList.mqs[stateMainTest.start].id,
            response: stateMainTest?.alternativeResponse,
            questions: stateMainTest.repressedIDS.length
                ? uniqueArray
                : filteredIDs,
            guid: params?.guid,
            role_id: location?.state?.roleId,
            type: location?.state?.test_type,
            user_id: params?.id,
            mode: 1,
            question_number:
                testQuestionList.mqs[stateMainTest.start].question_number || 0,
        };
        const res = await checkAlternativeResponse(data);
        if (res?.payload) {
            let copyQuestions = [...testQuestionList.mqs];
            let getId = testQuestionList.mqs[stateMainTest.start].id;

            const findRandomIndex = copyQuestions.findIndex(
                (obj) =>
                    obj.id === getId &&
                    obj.apply_alternative_response_content === false
            );
            let updateObject = {
                ...testQuestionList.mqs[findRandomIndex],
                is_scorable: false,
            };
            copyQuestions[findRandomIndex] = updateObject;
            let newIndex = findRandomIndex + 1;
            let setNewAlternative = stateMainTest.start + 1;
            if (res.payload.apply_alternative_response_content) {
                let addAlternativeQuestion = {
                    id: res.payload.id,
                    alternative_response_content:
                        res.payload.alternative_response_content,
                    alternative_content_responses:
                        res.payload.alternative_content_responses,
                    apply_alternative_response_content: true,
                };
                copyQuestions.splice(
                    setNewAlternative,
                    0,
                    addAlternativeQuestion
                );
            }
            let updateResponseQuestion = {
                ...res.payload,
                apply_alternative_response_content: false,
                question_number:
                    testQuestionList.mqs[findRandomIndex].question_number,
            };

            copyQuestions[findRandomIndex] = updateResponseQuestion;
            // copyQuestions.splice(newIndex, 0, updateResponseQuestion);
            setStateMainTest((prevState) => ({
                ...prevState,
                questiosBeforeRepressedList: [
                    ...stateMainTest.questiosBeforeRepressedList,
                    updateObject,
                ],
            }));
            await updateRepressedQuestion(copyQuestions);
            setStateMainTest((prevState) => ({
                ...prevState,
                showAdavanceQuestion:
                    testQuestionList.mqs[stateMainTest.start]
                        .apply_alternative_response_content,
                start: prevState.start + 1,
                alternativeResponse: null,
                repressedIDS: [...stateMainTest.repressedIDS, res?.payload?.id],
            }));
        } else {
            if (
                testQuestionList?.mqs[stateMainTest?.start]
                    ?.contingent_ques_master &&
                (stateMainTest?.selectedResponse ||
                    stateMainTest?.selectedValue?.response)
            ) {
                await checkContingentQuestion();
                setStateMainTest((prevState) => ({
                    ...prevState,
                    showAdavanceQuestion: false,
                }));
            } else {
                setStateMainTest((prevState) => ({
                    ...prevState,
                    showAdavanceQuestion: false,
                    start: prevState.start + 1,
                }));
            }
        }
        setStateMainTest((prevState) => ({
            ...prevState,
            pausedTimer: false,
            isLoading: false,
            selectedResponse: null,
        }));
    };
    const checkContingentQuestion = async () => {
        setStateMainTest((prevState) => ({
            ...prevState,
            pausedTimer: true,
            isLoader: true,
        }));
        let data = {
            question_id: testQuestionList.mqs[stateMainTest.start].id,
            response:
                stateMainTest?.selectedResponse ||
                stateMainTest?.selectedValue?.response,
            guid: params?.guid,
            role_id: location?.state?.roleId,
            type: location?.state?.test_type,
            user_id: params?.id,
            mode: 1,
            question_number:
                testQuestionList.mqs[stateMainTest.start].question_number,
        };
        const res = await getContingentQuestions(data);
        if (res?.payload) {
            let copyQuestions = [];
            copyQuestions = [...testQuestionList.mqs];
            const filterData = testQuestionList.mqs.filter(
                (obj) =>
                    parseInt(obj?.parent_id) !=
                    testQuestionList.mqs[stateMainTest.start].id
            );
            copyQuestions = filterData;
            await updateRepressedQuestion(filterData);
            let newIndex = stateMainTest.start + 1;
            copyQuestions.splice(newIndex, 0, ...res.payload);
            await updateRepressedQuestion(copyQuestions);
            setStateMainTest((prevState) => ({
                ...prevState,
                pausedTimer: false,
                isLoading: false,
                isLoader: false,
                start: prevState.start + 1,
                showContigentQuestion: {
                    show: true,
                    dipendentResponses: {
                        question_id:
                            testQuestionList.mqs[stateMainTest.start].id,
                        contigentData: res?.payload,
                    },
                },
            }));
        } else {
            const filterData = testQuestionList.mqs.filter(
                (obj) =>
                    parseInt(obj?.parent_id) !=
                    testQuestionList.mqs[stateMainTest.start].id
            );
            await updateRepressedQuestion(filterData);
            setStateMainTest((prevState) => ({
                ...prevState,
                pausedTimer: false,
                isLoading: false,
                isLoader: false,
                start: prevState.start + 1,
            }));
        }
    };
    const onCloseShowSubmittion = () => {
        setStateMainTest((prevState) => ({
            ...prevState,
            showSubmitionAgrement: false,
            pausedTimer: false,
        }));
    };
    return (
        <Box className="app-container">
            <Navbar />
            <DialogComponent
                title={" "}
                open={isOpenNoInfoDialog}
                handleClose={() => {
                    setStateMainTest((prevState) => ({
                        ...prevState,
                        isOpenNoInfoDialog: false,
                    }));
                    navigate(-1);
                }}
                content={
                    <Translate>There are no questions available.</Translate>
                }
                contentClass="noQuestionContain"
                className="noQuestionDialog"
                action={
                    <ButtonComponent
                        title={<Translate>Cancel</Translate>}
                        onClick={() => {
                            setStateMainTest((prevState) => ({
                                ...prevState,
                                isOpenNoInfoDialog: false,
                            }));
                            navigate(-1);
                        }}
                    />
                }
            />
            <DialogComponent
                title={" "}
                open={stateMainTest.timeOutPopUp}
                handleClose={() => {
                    setStateMainTest((prevState) => ({
                        ...prevState,
                        pausedTimer: true,
                        timeOutPopUp: false,
                        timeOut: true,
                    }));
                }}
                content={`Test session has timed out, and you will not be able to
                        proceed further at this time. If you have any concerns
                        or need assistance, please reach out to your Manager.
                        Thank you!`}
                contentClass="noQuestionContain"
                className="noQuestionDialog"
                action={
                    <>
                        <ButtonComponent
                            title={"OK"}
                            onClick={() => {
                                setStateMainTest((prevState) => ({
                                    ...prevState,
                                    pausedTimer: false,
                                    timeOutPopUp: false,
                                    timeOut: true,
                                }));
                                navigate(-1);
                            }}
                        />
                    </>
                }
            />
            <DialogComponent
                title={diloagTitle}
                open={diloagOpen}
                handleClose={() => {
                    setDiloagOpen(false)
                }}
                
                content={<div dangerouslySetInnerHTML={{ __html: diloagContent }}/>}
                contentClass="noQuestionContain"
                className="noQuestionDialog"
                action={
                    <>
                        <ButtonComponent
                            title={"close"}
                            onClick={() => {
                                setDiloagOpen(false)
                            }}
                        />
                    </>
                }
            />
            <SuccessfullModel
                customizeIcon={true}
                open={submitDialogOpen}
                handleClose={handleCloseDialog}
                content={`The " ${testQuestionList?.test_name} " has been successfully submitted.`}
            />
            <DialogComponent
                maxWidth={"md"}
                title={
                    <Translate>
                        {stateMainTest.questionContainDialog.title}
                    </Translate>
                }
                open={stateMainTest.questionContainDialog.open}
                content={stateMainTest.questionContainDialog.content}
                handleClose={() => {
                    setStateMainTest((prevState) => ({
                        ...prevState,
                        questionContainDialog: {
                            open: false,
                            title: null,
                        },
                    }));
                }}
                isShowContentArrenge={true}
                action={
                    <>
                        <ButtonComponent
                            title={"Close"}
                            onClick={() => {
                                setStateMainTest((prevState) => ({
                                    ...prevState,
                                    questionContainDialog: {
                                        open: false,
                                        title: null,
                                    },
                                }));
                            }}
                        />
                    </>
                }
            />
            <DialogComponent
                handleClose={onCloseShowSubmittion}
                maxWidth={"md"}
                title={<Translate>Declaration Statement</Translate>}
                open={showSubmitionAgrement}
                content={
                    <Grid container my={2}>
                        <Grid item md={12} lg={12}>
                            <Typography className="font_18_600">
                                <Translate>
                                    I hereby certify that, to the best of my
                                    knowledge and ability, my response to each
                                    question in this test was true and accurate.
                                    I understand that, when given the choice
                                    between 2 or more correct or seemingly
                                    correct responses, I chose the most correct
                                    response. Finally, I hereby certify that I
                                    am not aware of any facts or circumstances
                                    which cause or reasonably could cause my
                                    responses to be untrue or inaccurate.
                                </Translate>
                            </Typography>
                        </Grid>

                        <Grid
                            item
                            md={12}
                            lg={12}
                            sx={{
                                marginLeft: "-10px !important",
                            }}
                            mt={1}
                        >
                            <RadioGroup
                                value={stateMainTest.accept}
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    marginBottom: "18px",
                                    marginLeft: "10px",
                                }}
                            >
                                {agreeOrNotOptions?.map((option, index) => (
                                    <Fragment key={index}>
                                        <FormControlLabel
                                            value={option.value}
                                            control={<Radio />}
                                            label={
                                                <Typography
                                                    className="font_16_600"
                                                    variant="span"
                                                    sx={{
                                                        cursor: "pointer",
                                                    }}
                                                >
                                                    <Translate>
                                                        {option.label}
                                                    </Translate>
                                                </Typography>
                                            }
                                            sx={{
                                                marginBottom: 0.5,
                                                marginRight:
                                                    index === 0 ? 3 : 0,
                                            }}
                                            onClick={() => {
                                                setStateMainTest(
                                                    (prevState) => ({
                                                        ...prevState,
                                                        accept: option.value,
                                                    })
                                                );
                                            }}
                                        />
                                    </Fragment>
                                ))}
                            </RadioGroup>
                        </Grid>
                    </Grid>
                }
                action={
                    <>
                        <ButtonComponent
                            title={<Translate>Cancel</Translate>}
                            onClick={onCloseShowSubmittion}
                        />
                        <ButtonComponent
                            title={<Translate>Submit</Translate>}
                            onClick={handleSubmitClick}
                            disabled={!stateMainTest.accept ? true : false}
                            sx={!stateMainTest.accept ? disabledBtnSx : null}
                        />
                    </>
                }
            />
            {isLoading ? (
                <LoadingComponent
                    sx={{ height: "600px" }}
                    customizeText={
                        !showAdavanceQuestion
                            ? "Please Wait. Your responses have not been finalized and submitted yet. The system is doing a quick check, and this check should just take a few seconds. Please wait for a notification on this screen that the process is complete."
                            : "Please Wait"
                    }
                />
            ) : (
                testQuestionList && (
                    <>
                        <Grid
                            container
                            justifyContent={"center"}
                            mb={"10px"}
                            className="mainConatin"
                            sx={{
                                maxHeight: "90vh",
                                overflowY: "scroll",
                                scrollBehavior: "smooth",
                            }}
                        >
                            <Grid item md={12} lg={12} sm={12} xs={12}>
                                <Grid
                                    container
                                    sx={{
                                        px: { md: 10, xs: 1 },
                                        paddingTop: "80px",
                                        paddingBottom: "10px",
                                    }}
                                >
                                    <Grid item md={12} lg={12}>
                                        <Grid container>
                                            <Grid
                                                item
                                                xs={12}
                                                md={12}
                                                lg={12}
                                                my={1.5}
                                            >
                                                <Grid
                                                    container
                                                    alignItems={"center"}
                                                    justifyContent={
                                                        "space-between"
                                                    }
                                                    xs={12}
                                                >
                                                    <Grid
                                                        item
                                                        display={"flex"}
                                                        gap={
                                                            showAdavanceQuestion
                                                                ? "2px"
                                                                : "20px"
                                                        }
                                                        alignItems={
                                                            showAdavanceQuestion
                                                                ? "left"
                                                                : "center"
                                                        }
                                                        flexDirection={
                                                            showAdavanceQuestion
                                                                ? "column"
                                                                : "row"
                                                        }
                                                    >
                                                        <Typography
                                                            className="font_20_600"
                                                            variant="span"
                                                        >
                                                            {showAdavanceQuestion ? (
                                                                <Translate>
                                                                    Additional
                                                                    Question{" "}
                                                                    {
                                                                        stateMainTest
                                                                            .countAlternativeQuestion
                                                                            ?.length
                                                                    }
                                                                </Translate>
                                                            ) : (
                                                                <Translate>
                                                                    Question{" "}
                                                                    {
                                                                        testQuestionList
                                                                            .mqs[
                                                                            stateMainTest
                                                                                .start
                                                                        ]
                                                                            .question_number
                                                                    }{" "}
                                                                    of{" "}
                                                                    {
                                                                        testQuestionList?.total_questions
                                                                    }
                                                                </Translate>
                                                            )}
                                                        </Typography>
                                                        {!showAdavanceQuestion ? (
                                                            <Box
                                                                display={"flex"}
                                                                gap={2}
                                                            >
                                                                {testQuestionList
                                                                    ?.mqs[
                                                                    stateMainTest
                                                                        ?.start
                                                                ]
                                                                    ?.explanation !==
                                                                    (null ||
                                                                        "N/A") && (
                                                                    <Link
                                                                        onClick={() =>
                                                                            handlerQuestionContainDialog(
                                                                                testQuestionList
                                                                                    ?.mqs[
                                                                                    stateMainTest
                                                                                        ?.start
                                                                                ]
                                                                                    ?.explanation,
                                                                                "Description"
                                                                            )
                                                                        }
                                                                        sx={{
                                                                            cursor: "pointer",
                                                                        }}
                                                                    >
                                                                        <Translate>
                                                                            Description
                                                                        </Translate>
                                                                    </Link>
                                                                )}
                                                                {testQuestionList
                                                                    ?.mqs[
                                                                    stateMainTest
                                                                        ?.start
                                                                ]?.examples !==
                                                                    (null ||
                                                                        "N/A") && (
                                                                    <Link
                                                                        sx={{
                                                                            cursor: "pointer",
                                                                        }}
                                                                        onClick={() =>
                                                                            handlerQuestionContainDialog(
                                                                                testQuestionList
                                                                                    ?.mqs[
                                                                                    stateMainTest
                                                                                        ?.start
                                                                                ]
                                                                                    ?.examples,
                                                                                "Example"
                                                                            )
                                                                        }
                                                                    >
                                                                        <Translate>
                                                                            Example
                                                                        </Translate>
                                                                    </Link>
                                                                )}
                                                            </Box>
                                                        ) : null}
                                                    </Grid>
                                                    {testQuestionList &&
                                                    testQuestionList.mqs[
                                                        stateMainTest.start
                                                    ]
                                                        ?.apply_alternative_response_content ? null : (
                                                        <Grid item md={6}>
                                                            <Box
                                                                sx={{
                                                                    width: "100%",
                                                                }}
                                                            >
                                                                <LinearProgressWithLabel
                                                                    value={
                                                                        normalise
                                                                    }
                                                                />
                                                            </Box>
                                                        </Grid>
                                                    )}
                                                    <Grid
                                                        item
                                                        textAlign="right"
                                                    >
                                                        <Timer
                                                            stateMainTest={
                                                                stateMainTest
                                                            }
                                                            setStateMainTest={
                                                                setStateMainTest
                                                            }
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    rowSpacing={1}
                                    sx={{
                                        px: { md: 10, xs: 1 },
                                    }}
                                >
                                    {!showAdavanceQuestion ? (
                                        <Grid item md={12} lg={12}>
                                            <FormControl>
                                                <FormLabel className="font_20_600 mb-15 text-black">
                                                    {testQuestionList?.mqs
                                                        ?.length < 1 ? (
                                                        ""
                                                    ) : (
                                                        <div className="font_20_600  text-black">
                                                            <Translate>
                                                                {
                                                                    stateMainTest.testQustion
                                                                }
                                                            </Translate>
                                                        </div>
                                                    )}
                                                </FormLabel>
                                                <RadioGroup
                                                    disabled={
                                                        stateMainTest.pausedTimer
                                                            ? true
                                                            : false
                                                    }
                                                    onChange={(e) =>
                                                        handlerSelectedResponse(
                                                            e
                                                        )
                                                    }
                                                    className="testQuestionOption"
                                                >
                                                    {testQuestionList?.mqs[
                                                        stateMainTest?.start
                                                    ]?.available_response?.map(
                                                        (element) => {
                                                            const val =
                                                                testResponses.test_response.find(
                                                                    (item) => {
                                                                        return (
                                                                            item.question_id ===
                                                                            testQuestionList
                                                                                ?.mqs[
                                                                                stateMainTest
                                                                                    ?.start
                                                                            ]
                                                                                ?.id
                                                                        );
                                                                    }
                                                                );
                                                            const sel =
                                                                val?.response ||
                                                                stateMainTest.selectedResponse ||
                                                                "";

                                                            return (
                                                                <FormControlLabel
                                                                    value={
                                                                        element?.value
                                                                    }
                                                                    sx={{
                                                                        "& .MuiRadio-root":
                                                                            {
                                                                                padding:
                                                                                    "7px ", // Remove padding
                                                                            },
                                                                    }}
                                                                    control={
                                                                        <Radio />
                                                                    }
                                                                    label={
                                                                        <Translate>
                                                                            {
                                                                                element?.label
                                                                            }
                                                                        </Translate>
                                                                    }
                                                                    checked={
                                                                        sel ===
                                                                        element?.value
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    disabled={
                                                                        stateMainTest.pausedTimer
                                                                            ? true
                                                                            : false
                                                                    }
                                                                />
                                                            );
                                                        }
                                                    )}
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                    ) : (
                                        <Grid item md={12} lg={12}>
                                            <FormControl>
                                                <FormLabel className="font_20_600 mb-15 text-black">
                                                    <div className="font_20_600  text-black">
                                                        <Translate>
                                                            {
                                                                testQuestionList
                                                                    ?.mqs[
                                                                    stateMainTest
                                                                        ?.start
                                                                ]
                                                                    ?.alternative_response_content
                                                            }
                                                        </Translate>
                                                    </div>
                                                </FormLabel>
                                                <RadioGroup
                                                    disabled={
                                                        stateMainTest.pausedTimer
                                                            ? true
                                                            : false
                                                    }
                                                    onChange={(e) =>
                                                        handlerAvalableResponse(
                                                            e
                                                        )
                                                    }
                                                    className="testQuestionOption"
                                                >
                                                    {testQuestionList?.mqs[
                                                        stateMainTest?.start
                                                    ]?.alternative_content_responses?.map(
                                                        (element) => {
                                                            return (
                                                                <FormControlLabel
                                                                    value={
                                                                        element?.value
                                                                    }
                                                                    sx={{
                                                                        "& .MuiRadio-root":
                                                                            {
                                                                                padding:
                                                                                    "7px ", // Remove padding
                                                                            },
                                                                    }}
                                                                    control={
                                                                        <Radio />
                                                                    }
                                                                    checked={
                                                                        stateMainTest?.alternativeResponse ===
                                                                        element?.value
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    label={
                                                                        <Translate>
                                                                            {
                                                                                element?.label
                                                                            }
                                                                        </Translate>
                                                                    }
                                                                    disabled={
                                                                        stateMainTest.pausedTimer
                                                                            ? true
                                                                            : false
                                                                    }
                                                                />
                                                            );
                                                        }
                                                    )}
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                    )}
                                </Grid>
                                {/* {stateMainTest.start + 1 ===
                                    testQuestionList?.mqs?.length && (
                                    <Grid
                                        container
                                        sx={{
                                            px: {
                                                md: 10,
                                                xs: 1,
                                                minHeight: "140px",
                                            },
                                        }}
                                    >
                                        <Grid item md={12} lg={12}>
                                            <Fragment>
                                                <Divider
                                                    sx={{
                                                        borderColor:
                                                            "#3B3B3B80",
                                                        margin: "10px 0px !important",
                                                    }}
                                                />
                                                <Grid container my={2}>
                                                    <Grid item md={12} lg={12}>
                                                        <Typography className="font_18_600">
                                                            <Translate>
                                                                I hereby certify
                                                                that, to the
                                                                best of my
                                                                knowledge and
                                                                ability, my
                                                                response to each
                                                                question in this
                                                                test was true
                                                                and accurate. I
                                                                understand that,
                                                                when given the
                                                                choice between 2
                                                                or more correct
                                                                or seemingly
                                                                correct
                                                                responses, I
                                                                chose the most
                                                                correct
                                                                response.
                                                                Finally, I
                                                                hereby certify
                                                                that I am not
                                                                aware of any
                                                                facts or
                                                                circumstances
                                                                which cause or
                                                                reasonably could
                                                                cause my
                                                                responses to be
                                                                untrue or
                                                                inaccurate.
                                                            </Translate>
                                                        </Typography>
                                                    </Grid>

                                                    <Grid
                                                        item
                                                        md={12}
                                                        lg={12}
                                                        sx={{
                                                            marginLeft:
                                                                "-10px !important",
                                                        }}
                                                        mt={1}
                                                    >
                                                        <RadioGroup
                                                            value={
                                                                stateMainTest.accept
                                                            }
                                                            style={{
                                                                display: "flex",
                                                                flexDirection:
                                                                    "row",
                                                                marginBottom:
                                                                    "18px",
                                                                marginLeft:
                                                                    "10px",
                                                            }}
                                                        >
                                                            {agreeOrNotOptions?.map(
                                                                (
                                                                    option,
                                                                    index
                                                                ) => (
                                                                    <Fragment
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        <FormControlLabel
                                                                            value={
                                                                                option.value
                                                                            }
                                                                            control={
                                                                                <Radio />
                                                                            }
                                                                            label={
                                                                                <Typography
                                                                                    className="font_16_600"
                                                                                    variant="span"
                                                                                    sx={{
                                                                                        cursor: "pointer",
                                                                                    }}
                                                                                >
                                                                                    <Translate>
                                                                                        {
                                                                                            option.label
                                                                                        }
                                                                                    </Translate>
                                                                                </Typography>
                                                                            }
                                                                            sx={{
                                                                                marginBottom: 0.5,
                                                                                marginRight:
                                                                                    index ===
                                                                                    0
                                                                                        ? 3
                                                                                        : 0,
                                                                            }}
                                                                            onClick={() => {
                                                                                setStateMainTest(
                                                                                    (
                                                                                        prevState
                                                                                    ) => ({
                                                                                        ...prevState,
                                                                                        accept: option.value,
                                                                                    })
                                                                                );
                                                                            }}
                                                                        />
                                                                    </Fragment>
                                                                )
                                                            )}
                                                        </RadioGroup>
                                                    </Grid>
                                                </Grid>
                                            </Fragment>
                                        </Grid>
                                    </Grid>
                                )} */}
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            my={3}
                            sx={{
                                px: { md: 10, xs: 1 },
                                position: "absolute",
                                bottom: 0,
                                flexShrink: 0,
                            }}
                        >
                            <Grid item md={6} lg={6} xs={6}>
                                {stateMainTest.start > 0 &&
                                    !showAdavanceQuestion &&
                                    testQuestionList.mqs[
                                        stateMainTest.start - 1
                                    ].question && (
                                        <ButtonComponent
                                            title={
                                                <Translate>Previous</Translate>
                                            }
                                            variant="outlined"
                                            onClick={handlePreviousClick}
                                            disabled={
                                                stateMainTest.pausedTimer
                                                    ? true
                                                    : false
                                            }
                                        />
                                    )}
                            </Grid>
                            <Grid item md={6} lg={6} xs={6} textAlign="end">
                                {stateMainTest.start + 1 ===
                                testQuestionList?.mqs?.length ? (
                                    <Grid
                                        item
                                        gap={2}
                                        display={"flex"}
                                        justifyContent={"end"}
                                    >
                                        <ButtonComponent
                                            title={
                                                <Translate>Review</Translate>
                                            }
                                            disabled={
                                                stateMainTest.pausedTimer
                                                    ? true
                                                    : false
                                            }
                                            // sx={
                                            //     !stateMainTest.accept
                                            //         ? disabledBtnSx
                                            //         : null
                                            // }
                                            onClick={handleReviewClick}
                                        />
                                        <ButtonComponent
                                            title={
                                                <Translate>
                                                    Proceed to Submit
                                                </Translate>
                                            }
                                            disabled={
                                                stateMainTest.pausedTimer
                                                    ? true
                                                    : false
                                            }
                                            // sx={
                                            //     !stateMainTest.pausedTimer
                                            //         ? disabledBtnSx
                                            //         : null
                                            // }
                                            onClick={showSubmitionAgreement}
                                        />
                                    </Grid>
                                ) : (
                                    <ButtonComponent
                                        disabled={
                                            stateMainTest.pausedTimer
                                                ? true
                                                : false
                                        }
                                        title={
                                            isLoader ? (
                                                <svg
                                                    height={"26px"}
                                                    version="1.1"
                                                    id="L9"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    x="0px"
                                                    y="0px"
                                                    viewBox="0 0 100 100"
                                                    enable-background="new 0 0 0 0"
                                                >
                                                    <path
                                                        fill="#fff"
                                                        d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                                                    >
                                                        <animateTransform
                                                            attributeName="transform"
                                                            attributeType="XML"
                                                            type="rotate"
                                                            dur="1s"
                                                            from="0 50 50"
                                                            to="360 50 50"
                                                            repeatCount="indefinite"
                                                        />
                                                    </path>
                                                </svg>
                                            ) : (
                                                <Translate> Next</Translate>
                                            )
                                        }
                                        onClick={
                                            showAdavanceQuestion
                                                ? handleNextAdavanceQuestion
                                                : handleNextClick
                                        }
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </>
                )
            )}
        </Box>
    );
};
const mapStateToProps = (state) => {
    return {
        testQuestionList: state?.assessmentSettings?.testQuestionList,
        testResponses: state?.testReducer?.testResponses,
        testArrayIndex: state?.testReducer?.start,
        leftTimer: state?.testReducer.timer,
    };
};
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            submitTest,
            addResponse,
            skipQuestionRes,
            setTestData,
            getWordDefByGlosary,
            checkAlternativeResponse,
            updateRepressedQuestion,
            getContingentQuestions,
            setStartArrayValue,
        },
        dispatch
    );
};
export default connect(mapStateToProps, mapDispatchToProps)(MainTestQuestion);
