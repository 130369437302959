import React from "react";
import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ButtonComponent from "../../components/ButtonComponent";
import { Translate } from "react-auto-translate";

function MqsList(props) {
    const { mqs } = props;
    const navigate = useNavigate();
    const handelTest = () => {
        navigate("/select-test", { state: mqs });
    };
    return (
        <Fragment>
            <Grid container display={"flex"} justifyContent={"center"} mt={2}>
                <Grid
                    item
                    md={12}
                    xs={12}
                    className="mqsCard"
                    display={"flex"}
                    justifyContent={"center"}
                >
                    <Grid container display={"flex"} justifyContent={"center"}>
                        <Grid
                            item
                            xs={12}
                            display={"flex"}
                            justifyContent={"flex-start"}
                        >
                            <Typography className="question" ml={2} mt={2}>
                               <Translate>{mqs?.category}</Translate> 
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            display={"flex"}
                            justifyContent={"flex-end"}
                            alignItems={"center"}
                        >
                             <ButtonComponent title={<Translate>Select Test</Translate>}  onClick={handelTest} className="selectButton" />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Fragment>
    );
}
export default MqsList;
