import React from "react";
import TableComponent from "../../components/Table/TableComponent";
import "../../../assets/styles/Container/ScoringConfiguration.scss";
import TextField from "../../components/TextField/TextFieldComponent";
import Checkbox from "../../components/Checkbox/CheckboxComponent";
import FormControlLabel from "@mui/material/FormControlLabel";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";


const tableData = [
    {
        id: "21",
        name: "True",
        value: "2.5",
    },
    {
        id: "22",
        name: "False",
        value: "2",
    },
    {
        id: "23",
        name: "Do Not Know / Unsure Maybe",
        value: "1",
    },
];

const headerList = [
    {
        name: "Option",
        headerClass: "headerOption",
        accessibleKey: "name",
        rowClass: "tableOption",
        headerAlign: "center",
        rowAlign: "start",
    },
];

const handleSelectAll = (e) => {};

const USBasedTable = () => {
    return (

        <TableComponent
        headerList={headerList}


        extraHeaderLeft={
            <TableCell className="colorModification" align="center">
                Best
            </TableCell>
        }
        extraColLeft={
            <TableCell className="rowColor">
                <FormControlLabel
                    control={<Checkbox onChange={handleSelectAll} />}
                    labelPlacement="Select"
                ></FormControlLabel>
            </TableCell>
        }
        extraHeaderRight={
            <TableCell className="colorScore" align="end ">
                Score
            </TableCell>
        }

    >
        {tableData?.length &&
            tableData.map((row, index) => (
                <TableRow key={row.name} className="seriesCell">
                      <TableCell className="rowColor">
                      <FormControlLabel
                    control={<Checkbox onChange={handleSelectAll} />}
                    labelPlacement="Select"
                ></FormControlLabel>
                    </TableCell>
                    {headerList.map((obj) => (
                        <TableCell
                            component="th"
                            key={obj?.name}
                            align={obj?.rowAlign}
                            className={
                                obj?.rowClass
                                    ? obj?.rowClass
                                    : row?.status === "active"
                                    ? "typo3"
                                    : "typo5"
                            }
                            onClick={() =>
                                obj.handleButton(row, obj?.buttonType) ||
                                null
                            }
                        >
                            {row[obj?.accessibleKey]}
                        </TableCell>
                    ))}
                    <TableCell className="rowColor">
                        <TextField
                            variant="outlined"
                            className="fieldNumber"
                            type={"Number"}
                        />
                    </TableCell>
                </TableRow>
            ))}
    </TableComponent>

     );
};

export default USBasedTable;
