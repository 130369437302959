import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { uploadDocFileAction } from "../../../../../store/Action/DocumentsAction";
import { getContractInUsActionList } from "../../../../../store/Action/SignedDocumentAction";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import "../../../../../../assets/styles/Container/DocumentRepository.scss";
import DocsTemplates from "./DocsTemplates";
import "../../../../../../assets/styles/Container/ComplianceWorkflow.scss";
import ButtonComponent from "../../../../../components/ButtonComponent";

function DocsImportUpload(props) {
    const {
        title,
        customer,
        user,
        uploadDocFileAction,
        templates,
        toggle,
        setToggle,
    } = props;
    const [state, setState] = useState({
        oprn: false,
        openImport: false,
        document: {
            filename: null,
            content: null,
        },
        files: [],
    });
    const [doc, setDoc] = useState({
        filename: null,
        content: null,
    });
    const { document } = state;
    useEffect(() => {
        if (document?.filename !== null) {
            document.map((file, name) => {
                handlerFileUpload(file, name);
            });
        }
    }, [document.filename]);

    const handleClick = () => {
        setState((prevState) => ({ ...prevState, openImport: true }));
    };

    const handlerFileUpload = (file, name) => {
        const reander = new FileReader();
        reander.readAsDataURL(file);
        reander.onload = () => {
            let fileUrl = reander.result;
            let fileName = file.path;
            setDoc((prevState) => ({
                ...prevState,
                filename: fileName,
                content: fileUrl,
            }));
        };
    };
    const handleClickToOpen = () => {
        setState((prevState) => ({ ...prevState, open: true }));
    };
    async function handleSubmit() {
        setState((prevState) => ({ ...prevState, open: false }));
        await uploadDocFileAction({
            contract: {
                filename: doc.filename,
                content: doc.content,
            },
            guid: null,
            type: "us",
            state_id: "1",
        });
        setState((prevState) => ({
            ...prevState,
            document: {
                filename: null,
                content: null,
            },
        }));
        setDoc((prevState) => ({
            ...prevState,
            filename: null,
            content: null,
        }));
        setToggle(!toggle);
    }
    const handleToClose = () => {
        setState((prevState) => ({ ...prevState, open: false }));
    };
    return (
        <Box sx={{ pt: "100px", paddingLeft: "100px" }}>
            <DocsTemplates
                open={state.openImport}
                onClose={handleToClose}
                setState={setState}
                contractsList={templates}
            />
            <Grid container>
                <Grid
                    item
                    xs={12}
                    md={9}
                    xl={11}
                    display="flex"
                    justifyContent="center"
                >
                    <Typography
                        sx={{
                            fontSize: "24px",
                            lineHeight: "30.17px",
                            fontWeight: 600,
                            marginLeft: 30,
                        }}
                    >
                        {title}
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={3}
                    xl={1}
                    display="flex"
                    justifyContent="flex-end"
                    gap={"20px"}
                >
                    <ButtonComponent
                        onClick={handleClick}
                        sx={{ marginRight: "60px" }}
                        title={"Import"}
                        endIcon={<FileDownloadOutlinedIcon />}
                    />
                </Grid>
            </Grid>
        </Box>
    );
}
const mapStateToProps = (state) => {
    return {
        customer: state.customer,
        user: state.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            uploadDocFileAction,
            getContractInUsActionList,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(DocsImportUpload);
