import { Typography } from "@mui/material";
import ButtonComponent from "../ButtonComponent";
import React from "react";
import DialogComponent from "./DialogComponent";
import { connect } from "react-redux";

const DescriptionDialog = (props) => {
    const { open, setOpen, description, userLoggedIn } = props;
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <DialogComponent
            className="dialogBoxMain"
            open={open}
            handleClose={handleClose}
            titleContent={"font_18_600 light-sea-green"}
            sxaction={{ px: 4, py: 3 }}
            sx={{
                boxShadow:
                    "0px 0px 10px 4px rgba(214, 214, 214, 0.2) !important",
                borderRadius: "25px !important",
            }}
            title={userLoggedIn?.language_data?.role?.description || "Description"}
            content={<Typography className="font_16_600">{description}</Typography>}
            action={<ButtonComponent title="Close" onClick={handleClose} />}
        />
    );
};

const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,
    };
};

export default connect(mapStateToProps, null)(DescriptionDialog);
