import React, { useState, useEffect } from "react";
import Navbar from "../../../common/Header/Navbar";
import Box from "@mui/material/Box";
import PageHeader from "../../../common/PageHeader";
import {
    Typography,
    Grid,
    FormControlLabel,
    Radio,
    FormGroup,
    FormControl,
    FormLabel,
    RadioGroup,
} from "@mui/material";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "../../../common/Footer/Footer";
import { bindActionCreators } from "redux";
import { getViewQuestionResponses } from "../../../store/Action/MqsAction";
import LoadingComponent from "../../../components/Loading/LoadingComponent";
import ShowAssesmentUserData from "./ShowAssesmentUserData";
import ButtonComponent from "../../../components/ButtonComponent";
import {
    addResponse,
    setStartArrayValue,
    setTestTimer,
} from "../../../store/Action/TestAction";
import { Translate } from "react-auto-translate";

function ViewResponse(props) {
    const {
        getViewQuestionResponses,
        testResponses,
        addResponse,
        setStartArrayValue,
        setTestTimer,
    } = props;
    const location = useLocation();
    const navigate = useNavigate();
    const [state, setState] = useState({
        assessmentSummaryDetails: null,
        isLoading: false,
        filteredQuestionsForShow: null,
    });
    const { assessmentSummaryDetails, isLoading, filteredQuestionsForShow } =
        state;
    useEffect(() => {
        if (location?.state.type === "edit") {
            if (testResponses?.test_response.length) {
                setReviewQuestion();
            } else {
                handlerGoBackSubmition();
            }
        } else {
            callApi(location?.state?.data);
        }
    }, []);

    const callApi = async (data) => {
        setState((prevState) => ({ ...prevState, isLoading: true }));
        const res = await getViewQuestionResponses(data);
        setState((prevState) => ({
            ...prevState,
            assessmentSummaryDetails: res?.payload,
            isLoading: false,
        }));
    };
    const setReviewQuestion = async () => {
        // const filterData = testResponses.test_response.filter((obj) => {
        //     return obj.question_number;
        // });
        setState((prevState) => ({
            ...prevState,
            // filteredQuestionsForShow: filterData.sort(
            //     (a, b) => a.question_number - b.question_number
            // ),
            assessmentSummaryDetails: location?.state?.ReviewData,
        }));
    };
    const handlerUpdateResponse = async (e, questionObject) => {
        if (location?.state.type === "edit") {
            let data = { response: e.target.value, object: questionObject };
            await addResponse(data);
        }
    };
    const handlerGoBackSubmition = () => {
        if (location?.state.type === "edit") {
            localStorage.setItem(
                "reviewData",
                JSON.stringify(location?.state?.ReviewData)
            );
            setTestTimer(location?.state?.ReviewData?.leftTimeReview);
            setStartArrayValue(location?.state?.ReviewData?.start);
        } else {
            localStorage.setItem("mode", location?.state?.data?.mode);
        }

        navigate(-1);
    };

    return (
        <>
            <Navbar />
            {isLoading ? (
                <LoadingComponent />
            ) : (
                <Box sx={{ px: { md: 7, xs: 1 }, mb: "50px", pt: "70px" }}>
                    <Grid
                        container
                        sx={{
                            justifyContent: "space-between",
                            marginY: "15px",
                            alignItems: "center",
                        }}
                    >
                        <Grid item>
                            <Typography className="font_24_600">
                                <Translate>
                                    {assessmentSummaryDetails?.test_name}
                                </Translate>
                            </Typography>
                        </Grid>
                        <Grid item>
                            <ButtonComponent
                                title={`Go Back To ${
                                    location?.state.type === "edit"
                                        ? "Submit Test"
                                        : "Workflow"
                                }`}
                                onClick={() => handlerGoBackSubmition()}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} mt={1}>
                        <ShowAssesmentUserData
                            assessmentSummaryDetails={assessmentSummaryDetails}
                        />
                    </Grid>
                    <div
                        style={{
                            height: "calc(100vh - 100px - 220px)",
                            overflowX: "auto",
                            marginTop: "10px",
                            width: "100%",
                        }}
                    >
                        {assessmentSummaryDetails &&
                            (location?.state?.type === "edit"
                                ? testResponses.test_response
                                : assessmentSummaryDetails?.questions
                            ).map((ques, index) => {
                                return (
                                    <>
                                        <Grid item md={12} lg={12} mt={3}>
                                            <Typography
                                                className="managerText"
                                                my={2}
                                            >
                                                {/* Question {index + 1} of{" "} */}
                                                Question{" "}
                                                {ques?.question_number
                                                    ? ques?.question_number
                                                    : index + 1}{" "}
                                                of{" "}
                                                {
                                                    assessmentSummaryDetails?.total_questions
                                                }
                                            </Typography>
                                            <FormControl>
                                                <FormLabel
                                                    className={"font_18_400"}
                                                >
                                                    <Translate>
                                                        {ques.question}
                                                    </Translate>
                                                </FormLabel>
                                                <RadioGroup
                                                    onChange={(e) =>
                                                        handlerUpdateResponse(
                                                            e,
                                                            ques
                                                        )
                                                    }
                                                    disabled={
                                                        location?.state.type ===
                                                        "edit"
                                                            ? false
                                                            : true
                                                    }
                                                    className="testQuestionOption"
                                                >
                                                    {ques?.available_response?.map(
                                                        (obj) => {
                                                            return (
                                                                <FormControlLabel
                                                                    value={
                                                                        location
                                                                            ?.state
                                                                            .type ===
                                                                        "edit"
                                                                            ? obj.label
                                                                            : obj.response
                                                                    }
                                                                    control={
                                                                        <Radio />
                                                                    }
                                                                    checked={
                                                                        ques.response
                                                                            ? (location
                                                                                  ?.state
                                                                                  .type ===
                                                                              "edit"
                                                                                  ? obj.value
                                                                                  : obj.response) ===
                                                                              ques.response
                                                                            : false
                                                                    }
                                                                    disabled={
                                                                        location
                                                                            ?.state
                                                                            .type ===
                                                                        "edit"
                                                                            ? false
                                                                            : true
                                                                    }
                                                                    label={
                                                                        location
                                                                            ?.state
                                                                            .type ===
                                                                        "edit" ? (
                                                                            <Translate>
                                                                                {
                                                                                    obj.label
                                                                                }
                                                                            </Translate>
                                                                        ) : (
                                                                            <Translate>
                                                                                {
                                                                                    obj.response
                                                                                }
                                                                            </Translate>
                                                                        )
                                                                    }
                                                                />
                                                            );
                                                        }
                                                    )}
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                    </>
                                );
                            })}
                    </div>
                </Box>
            )}
            <Footer />
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        assessmentSummaryData: state?.assessmentSettings?.assessmentSummaryData,
        testResponses: state?.testReducer?.testResponses,
    };
};
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getViewQuestionResponses,
            addResponse,
            setTestTimer,
            setStartArrayValue,
        },
        dispatch
    );
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewResponse);
