import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "../../../assets/styles/Container/Mqs.scss";
import Navbar from "../../common/Header/Navbar";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import MqsList from "./MqsList";
import Grid from "@mui/material/Grid";
import ButtonComponent from "../../components/ButtonComponent";
import Typography from "@mui/material/Typography";
import { getMqsQuestion, storeCategory } from "../../store/Action/MqsAction";
import CommonUploadDialog from "../../components/DialogsBox/CommonUploadDialog";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import FileUpload from "../../components/FileUpload";
import AddCategoryDialogMqs from "./AddCategoryDialogMqs";

const ManageMqs = (props) => {
    const { getMqsQuestion, mqsQuestion, storeCategory, userLoggedIn } = props;
    useEffect(() => {
        getMqsQuestion();
    }, [getMqsQuestion]);
    const [state, setState] = useState({
        open: false,
        files: [],
        openDialog: false,
        category: "",
    });
    const { open, files, openDialog } = state;
    const handleUpload = () => {
        setState((prevState) => ({ ...prevState, open: true }));
    };
    const handleUploadClose = () => {
        setState((prevState) => ({ ...prevState, open: false }));
    };
    const handleUploadXlsv = (file) => {
        const reander = new FileReader();
        reander.onload = () => {
            let fileUrl = reander.result;
            let fileName = file.path;
            setState((prevState) => ({
                ...prevState,
                filename: fileName,
                content: fileUrl,
            }));
        };
        URL.revokeObjectURL(file.preview);
        reander.readAsDataURL(file);
    };
    const thumbs = files.map((file) => (
        <div key={file.name}>
            <div>
                <Typography sx={{ color: "black", fontSize: "15px" }}>
                    {file.name}
                    {() => handleUploadXlsv(file)}
                </Typography>
            </div>
        </div>
    ));
    const handleClickToOpen = async () => {
        setState((prevState) => ({ ...prevState, openDialog: true }));
    };
    const handleClose = () => {
        setState((prevState) => ({ ...prevState, openDialog: false }));
    };
    async function handleCategoryAdd() {
        await storeCategory(state);
        setState((prevState) => ({ ...prevState, openDialog: false }));
    }
    return (
        <Fragment>
            <Navbar />
            <CommonUploadDialog
                open={open}
                title={"Upload Master Question"}
                cancelButtonAction={handleUploadClose}
                cancelButtonTitle="Cancel"
                uploadButtonTitle="Upload"
                validation="application/vnd.ms-excel"
                content={
                    files?.length !== 0 ? (
                        <aside>{thumbs}</aside>
                    ) : (
                        <FileUpload
                            file={state}
                            keyName={"files"}
                            setFiles={setState}
                            uploadIcon={
                                <CloudUploadOutlinedIcon className="uploadIcon" />
                            }
                        />
                    )
                }
            />
            <AddCategoryDialogMqs
                openDialog={openDialog}
                handleClose={handleClose}
                setState={setState}
                state={state}
                handleCategoryAdd={handleCategoryAdd}
            />
            <Grid
                container
                spacing={2}
                display="flex"
                mt={0}
                justifyContent={"flex-start"}
            >
                <Grid item md={3} xs={10} mt={10} ml={2}>
                    <Typography className="mqsHeading">Manage MQS</Typography>
                </Grid>
                <Grid
                    item
                    className="buttonUploadCategory"
                    xs={8.2}
                    gap={"18px"}
                    display="flex"
                    justifyContent={"flex-end"}
                >
                    <ButtonComponent
                        title={userLoggedIn?.language_data?.faqs?.add_category_btn || " Add Category"}
                        onClick={() => handleClickToOpen()}
                        startIcon={<AddCircleOutlineIcon />}
                        variant="contained"
                    />
                </Grid>
            </Grid>
            <Grid
                container
                spacing={2}
                display={"flex"}
                justifyContent={"center"}
            >
                {mqsQuestion?.length
                    ? mqsQuestion?.map((mqs) => (
                          <Grid
                              item
                              display={"flex"}
                              justifyContent={"center"}
                              md={3.5}
                              xs={9}
                              sm={10}
                              ml={2}
                          >
                              <MqsList mqs={mqs} />
                          </Grid>
                      ))
                    : null}
            </Grid>
        </Fragment>
    );
};
const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,
        mqsQuestion: state.mqs?.mqsQuestion,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getMqsQuestion,
            storeCategory,
        },
        dispatch
    );
};
export default connect(mapStateToProps, mapDispatchToProps)(ManageMqs);
