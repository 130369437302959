import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

const CorouselMain = (props) => {
    const { images, showThumbs, showArrows, showStatus, autoPlay, infiniteLoop, interval } = props;
    return (
        <Carousel
            showThumbs={showThumbs}
            showArrows={showArrows}
            showStatus={showStatus}
            autoPlay={autoPlay}
        >
            {images.map((obj) => {
                return (
                    <div style={{ borderRadius: "30px" }}>
                        <img

                            src={obj.path}
                            style={{ borderRadius: "30px", height: '395px', objectFit: 'cover' }}
                            width={"auto"}
                        />
                    </div>
                );
            })}
        </Carousel>
    );
};

export default CorouselMain;
