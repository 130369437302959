// import { toast } from "react-hot-toast";
import { getApiCall, deleteApiCall, putApiCall } from "../../utils/Action";
import { API_CONSTANTS } from "../../config/ApiConstants";

export const GET_LIST_WITH_COUNT = "GET_LIST_WITH_COUNT";
export const REFRESH_LIST = "REFRESH_LIST";
export const READ_SINGLE = "READ_SINGLE";
export const READ_ALL = "READ_ALL";
export const NOTIFICATION_DELETE = "NOTIFICATION_DELETE";

export const getListWithCountAction = () => {
    return async (dispach) => {
        try {
            const response = await getApiCall(API_CONSTANTS.NOTIFICATION_LIST);
            if (response?.status === 200) {
                return dispach({
                    type: GET_LIST_WITH_COUNT,
                    payload: response?.data?.result,
                });
            }
        } catch (error) {
            throw error;
        }
    };
};

export const refreshNotificationListAction = (notification) => {
    return (dispach) => {
        return dispach({
            type: REFRESH_LIST,
            payload: notification,
        });
    };
};

export const deleteNotificationAction = (id) => {
    return async (dispach) => {
        try {
            const response = await deleteApiCall(
                API_CONSTANTS.NOTIFICATION_DELETE + "/" + id
            );
            if (response?.status === 200) {
                // toast.success(response.data.message);
                return dispach({
                    type: NOTIFICATION_DELETE,
                    payload: id,
                });
            } else {
                // toast.error(response?.data?.message);;
            }
        } catch (error) {
            throw error;
        }
    };
};

export const readSingleNotificationAction = (id) => {
    return async (dispach) => {
        try {
            const response = await putApiCall(
                API_CONSTANTS.NOTIFICATION_SINGLE_READ,
                { id }
            );
            if (response?.status === 200) {
                // toast.success(response.data.message);
                const read_at = response.data.message;
                return dispach({
                    type: READ_SINGLE,
                    payload: {
                        id,
                        read_at,
                    },
                });
            } else {
                // toast.error(response?.data?.message);;
            }
        } catch (error) {
            throw error;
        }
    };
};

export const readAllNotificationAction = () => {
    return async (dispach) => {
        try {
            const response = await putApiCall(
                API_CONSTANTS.NOTIFICATION_READ_ALL,
                null
            );
            if (response?.status === 200) {
                return dispach({
                    type: READ_ALL,
                    payload: response.data.result,
                });
            } else {
                // toast.error(response?.data?.message);;
            }
        } catch (error) {
            throw error;
        }
    };
};
