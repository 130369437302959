import React, { useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import VerifiedIcon from "@mui/icons-material/Verified";
import Navbar from "../../common/Header/Navbar";
import PageHeader from "../../common/PageHeader";
import CustomSwitch from "../../components/CustomSwitch";
import "../../../assets/styles/Container/Pricing.scss";
import ButtonComponent from "../../components/ButtonComponent";
import { packageList } from "../../utils/CommonData";
import SubscriptionPreviewDialog from "./SubscriptionPreviewDialog";
import { Link } from "react-router-dom";
import TableComponent from "../../components/Table/TableComponent";

function Pricing(props) {
    const [state, setState] = useState({
        open: false,
        selectedPlan: "",
        planDuration: true,
    });

    const { planDuration } = state;
    const handleOpen = (planName) => {
        setState((prevState) => ({
            ...prevState,
            open: true,
            selectedPlan: planName,
        }));
    };

    const handleClose = () => {
        setState((prevState) => ({
            ...prevState,
            open: false,
            selectedPlan: "",
        }));
    };

    const handlePlanChange = (event) => {
        setState((prevState) => ({
            ...prevState,
            planDuration: event.target.checked,
        }));
    };

    return (
        <>
            <Navbar />
            <SubscriptionPreviewDialog
                handleClose={handleClose}
                packageList={packageList}
                state={state}
            />
            <Box sx={{ px: { md: 7, xs: 1 } }}>
                <PageHeader title={"Package & Pricing"} />
                <Grid container gap={1}>
                    <Grid xs={12} display={"flex"} justifyContent={"center"}>
                        <Typography className="pricingTitle">
                            {"Choose the package that best suit you"}
                        </Typography>
                    </Grid>
                    <Grid
                        xs={12}
                        display={"flex"}
                        justifyContent={"center"}
                        ml={12}
                    >
                        <Typography className="monthlyTitle">
                            Monthly
                        </Typography>
                        <CustomSwitch
                            value={planDuration}
                            onChange={(event) => handlePlanChange(event)}
                        />
                        <Typography className="monthlyTitle">Yearly</Typography>
                        <Typography className="discountTitle">
                            30% discount
                        </Typography>
                    </Grid>
                </Grid>
                {packageList?.length !== 0 && (
                    <TableComponent
                    tableClass={"packageTable"}
                        extraHeaderLeft={
                            <>
                                 <TableCell>
                                    <Typography className="featureTitle">
                                        Features
                                    </Typography>
                                    <Typography>
                                        {
                                            "Choose your suitable and needed package according to the feature."
                                        }
                                    </Typography>
                                </TableCell>
                                <TableCell align="center">
                                    <Grid
                                        container
                                        mb={1}
                                        display={"flex"}
                                        justifyContent={"center"}
                                    >
                                        <Typography className="pricingCol">
                                            Standard
                                        </Typography>
                                        <Typography className="pricingPerCol">
                                            {!planDuration ? "/$100" : "/$70"}
                                        </Typography>
                                    </Grid>
                                    <ButtonComponent
                                        title={"Start Free Trial"}
                                        onClick={() => handleOpen("standard")}
                                    />
                                </TableCell>
                                <TableCell align="center">
                                    <Typography className="pricingCol" mb={1.7}>
                                        Custom
                                    </Typography>
                                    <ButtonComponent
                                        title={"Select Features"}
                                        onClick={() => handleOpen("custom")}
                                    />
                                </TableCell>
                                <TableCell align="center">
                                    <Grid
                                        container
                                        mb={1}
                                        display={"flex"}
                                        justifyContent={"center"}
                                    >
                                        <Typography className="pricingCol">
                                            Premium
                                        </Typography>
                                        <Typography className="pricingPerCol">
                                            {!planDuration ? "/$300" : "/$240"}
                                        </Typography>
                                    </Grid>
                                    <ButtonComponent
                                        title={"Start Free Trial"}
                                        onClick={() => handleOpen("premium")}
                                    />
                                </TableCell>
                            </>
                        }
                    >
                        {packageList.map((obj) => (
                            <TableRow>
                                <TableCell>
                                    <Link className="packageName">
                                        {obj.name}
                                    </Link>
                                </TableCell>
                                <TableCell align="center">
                                    {obj?.standard ? (
                                        <VerifiedIcon className="verifiedIcon" />
                                    ) : null}
                                </TableCell>
                                <TableCell align="center">
                                    {obj?.custom ? (
                                        <VerifiedIcon className="verifiedIcon" />
                                    ) : null}
                                </TableCell>
                                <TableCell align="center">
                                    {obj?.premium ? (
                                        <VerifiedIcon className="verifiedIcon" />
                                    ) : null}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableComponent>
                )}
            </Box>
        </>
    );
}

export default Pricing;
