import { getApiCall, postApiCall, putApiCall } from "../../utils/Action";
import { API_CONSTANTS } from "../../config/ApiConstants";

export const SET_TEST_LAW_DETAILS = "SET_TEST_LAW_DETAILS";
export const GET_TEST_LAW_DETAILS = "GET_TEST_LAW_DETAILS";
export const EDIT_TEST_LAW_DETAILS = "EDIT_TEST_LAW_DETAILS";

export const saveTestLawDeatils = (data) => {
    return async (dispatch) => {
        try {
            const response = await postApiCall(
                API_CONSTANTS.TEST_LAW_SETTINGS,
                data
            );
            if (response?.status == 201) {
                return dispatch({
                    type: SET_TEST_LAW_DETAILS,
                    payload: response?.data?.result || null,
                });
            } else {
                // toast.error(response?.message);
            }
        } catch (error) {
            throw error;
        }
    };
};

export const getTestLawDetails = (guid) => {
    return async (dispatch) => {
        try {
            const response = await getApiCall(API_CONSTANTS.TEST_LAW_SETTINGS, {
                guid: guid,
            });
            if (response?.status == 200) {
                return dispatch({
                    type: GET_TEST_LAW_DETAILS,
                    payload: response?.data?.result,
                });
            } else {
                // toast.error(response?.message);
            }
        } catch (error) {
            throw error;
        }
    };
};
export const editTestLawDetails = (data) => {
    return async (dispatch) => {
        try {
            const response = await putApiCall(
                API_CONSTANTS.TEST_LAW_SETTINGS + "/" + data.id,
                data
            );
            if (response?.status == 201) {
                return dispatch({
                    type: EDIT_TEST_LAW_DETAILS,
                    payload: response?.data?.result || null,
                });
            } else {
                // toast.error(response?.message);
            }
        } catch (error) {
            throw error;
        }
    };
};
