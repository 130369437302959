import React from "react";
import { connect } from "react-redux";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import emailSendIcon from "../../../../../../assets/Images/Icons/EmailSend.png";

const EmailHistory = (props) => {
    const { emailHistoryData, setState } = props;
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("sm"));
    const checkboxLable = {
        color: "#3B3B3B !important",
        fontWeight: "600",
        fontSize: "16px",
        lineHeight: "20px",
        cursor: "pointer",
    };
    const email_history_text = {
        fontWeight: "600",
        fontSize: "16px",
        lineHeight: "20px",
        textDecorationLine: "underline",
        color: "#3B3B3B",
    };
    const getEmailHistory = (id) => {
        setState((prevState) => ({
            ...prevState,
            openResend: true,
            resendId: id,
        }));
    };

    return (
        <>
            <Box className="emailTempBox" height={matches ? "670px" : "auto"}>
                <Box className="emailHistoryHead">
                    <Typography sx={checkboxLable} px={1}>
                        Email History
                    </Typography>
                </Box>
                <Grid container sx={{ paddingX: "20px" }}>
                    {emailHistoryData?.map((data) => (
                        <Grid
                            item
                            pb={1}
                            xs={12}
                            display="flex"
                            justifyContent={"space-between"}
                            gap={1}
                            alignItems={"center"}
                        >
                            <Grid item>
                                <Typography
                                    sx={email_history_text}
                                    onClick={() => getEmailHistory(data?.id)}
                                >
                                    {data?.template_name}
                                </Typography>
                                <Typography
                                    variant="subtitle1"
                                    sx={{ fontSize: "12px" }}
                                >
                                    {data?.created_at}
                                </Typography>
                            </Grid>
                            <IconButton
                                value={data?.id}
                                aria-label="delete"
                                onClick={() => getEmailHistory(data?.id)}
                            >
                                <img
                                    src={emailSendIcon}
                                    className="icon"
                                    alt="email Recieve"
                                />
                            </IconButton>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </>
    );
};
const mapStateToProps = (state) => {
    return {
        emailHistoryData: state.contractor.emailHistoryList,
    };
};
export default connect(mapStateToProps)(EmailHistory);
