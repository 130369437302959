import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    setSelectedTemplateAction,
    deleteTemplatetAction,
    deleteAllTemplatetAction,
} from "../../store/Action/ContractAction";
import { getSignedDocumentAprrovedList } from "../../store/Action/SignedDocumentAction";
import {
    Box,
    Checkbox,
    Divider,
    FormControlLabel,
    Grid,
    IconButton,
} from "@mui/material";
import CommonDeleteDialog from "../DialogsBox/CommonDeleteDialog";
import { ReactComponent as DeleteIcon } from "../../../assets/Images/Icons/DeleteIcon.svg";
import { ReactComponent as EditIcon } from "../../../assets/Images/Icons/EditIcon.svg";
import "../../../assets/styles/Container/DocumentRepository.scss";
import ButtonComponent from "../ButtonComponent";
function LeftSideBar(props) {
    const {
        templates,
        deleteTemplatetAction,
        deleteAllTemplatetAction,
        getSignedDocumentAprrovedList,
        signedDocuments,
        selectedDocumentData,
        toggle,
        setToggle,
        handleSelectedFile,
    } = props;
    const [state, setState] = useState({
        open: false,
        toDelete: null,
        fileName: null,
        document_name: null,
        isCheckedAll: false,
        isChecked: [],
    });
    const { isCheckedAll, isChecked } = state;
    async function callApi() {
        setState((prevState) => ({ ...prevState, isLoading: true }));
        const signedDocs = await getSignedDocumentAprrovedList({});
        setState((prevState) => ({ ...prevState, isLoading: false }));
        setState((prevState) => ({ ...prevState, filteredData: signedDocs }));
      }
      
      useEffect(() => {
        if (signedDocuments === null) {
          callApi();
        }
      }, [signedDocuments]); // Only trigger the effect when `signedDocuments` changes
      
      useEffect(() => {
        callApi();
      }, [getSignedDocumentAprrovedList, selectedDocumentData]);

    useEffect(() => {
        if (templates?.length > 0 && !isChecked?.includes(templates[0].id)) {
            setState((prevState) => ({
                ...prevState,
                isChecked: [Number(templates[0].id)],
            }));
        }
    }, []);

    const handleSelectAll = (e) => {
        e.stopPropagation();
        setState((prevState) => ({
            ...prevState,
            isCheckedAll: !isCheckedAll,
            isChecked:
                signedDocuments?.length && signedDocuments?.map((li) => li.id),
        }));
        if (signedDocuments?.length === isChecked.length) {
            setState((prevState) => ({
                ...prevState,
                isChecked: [],
            }));
        }
    };
    const handleClickOnCheckbox = (e) => {
        const { value, checked } = e.target;
        if (!checked) {
            setState((prevState) => ({
                ...prevState,
                isChecked: isChecked.filter((item) => item !== Number(value)),
            }));
        } else {
            setState((prevState) => ({
                ...prevState,
                isChecked: [...isChecked, Number(value)],
            }));
        }
    };

    async function handleDeleteAll(e,showDocumentName) {
        if (showDocumentName) {
            setState((prevState) => ({
                ...prevState,
                open: true,
                document_name: "",
            }));
        } else {
            await deleteAllTemplatetAction({
                is_active: false,
                ids: isChecked,
            });
            setState((prevState) => ({
                ...prevState,
                open: true,
            }));
        }
        setToggle(!toggle);
    }
    const setDelete = (data) => {
        setState((prevState) => ({
            ...prevState,
            open: true,
            toDelete: data?.id,
            document_name: data?.document_name,
        }));
    };

    async function handleDelete() {
        let data ={
            is_active: false,
        }
        await deleteTemplatetAction(state.toDelete, data);
        
        setState((prevState) => ({
            ...prevState,
            open: false,
        }));
        setToggle(!toggle);
    }
    const deleteContent = `Are you sure,you want to remove the (${state.document_name} Signed Document) from the Signed Document?`;
    const deleteAllContent =
        "Are you sure,you want to remove the selected Signed Document?";

    return (
        <Box className="leftSideBarContainer">
            <CommonDeleteDialog
                open={state.open}
                setOpen={(isOpen) => {}}
                cancelButtonAction={() => {
                    setState({
                        open: false,
                        toDelete: [],
                        document_name: null,
                    });
                }}
                onClick={isChecked?.length === 1 ? handleDelete : handleDeleteAll}
                title="Delete Document"
                content={
                    isChecked?.length !== 1 ? deleteAllContent : deleteContent
                }
            />
            <Box
             pl={4} pr={0.8} py={1.2} display="flex"
            >
                <FormControlLabel
                    className="sidebar-chebox-control"
                    value="end"
                    sx={{marginRight: "0px"}}
                    control={<Checkbox  checked={
                        isChecked?.length === signedDocuments?.length
                    } onChange={(event) => handleSelectAll(event)} />}
                    labelPlacement="Select"
                />
                <ButtonComponent
                    disabled={isChecked?.length<2}
                    onClick={(event) => handleDeleteAll(event,true)}
                    title={"Delete All"}
                    className={`deleteAllbutton ${isChecked?.length> 1 && "active"}`}
                />
            </Box>
            <Divider />
            {signedDocuments &&
                signedDocuments.map((template) => {
                    return (
                    <Grid container px={4}>
                            <Grid item md={9}>
                                <FormControlLabel
                                    value="end"
                                    control={
                                        <Checkbox
                                            checked={isChecked?.includes(
                                                template?.id
                                            )}
                                            onChange={handleClickOnCheckbox}
                                            value={template?.id}
                                        />
                                    }
                                    label={
                                        <span
                                            style={{
                                                fontSize: "17px",
                                                fontWeight: 600,
                                            }}
                                        >
                                            {template?.document_name}
                                        </span>
                                    }
                                    labelPlacement="Select"
                                />
                            </Grid>
                            <Grid item md={3} display={"flex"}>
                                <IconButton
                                    onClick={() => {
                                        setState((prevState) => ({
                                            ...prevState,
                                            isChecked: [Number(template.id)],
                                        }));
                                        handleSelectedFile(template);
                                    }}
                                >
                                    <EditIcon />
                                </IconButton>
                                <Grid item md={3}>
                                    <IconButton
                                    disabled={!isChecked?.includes(template.id)}
                                        aria-label="delete"
                                        onClick={() => {
                                            setDelete(template);
                                        }}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    );
                })}
        </Box>
    );
}
const mapStateToProps = (state) => {
    return {
        customer: state.customer,
    };
};
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            setSelectedTemplateAction,
            getSignedDocumentAprrovedList,
            deleteAllTemplatetAction,
            deleteTemplatetAction,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(LeftSideBar);
