import { Fragment, useEffect, useState } from "react";
import Navbar from "../../common/Header/Navbar";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import CommonUploadDialog from "../../components/DialogsBox/CommonUploadDialog";
import FileUpload from "../../components/FileUpload";
import ButtonComponent from "../../components/ButtonComponent";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
    getTestQuestion,
    setSelectedQuestion,
    getQuestionList,
    uploadQuestions,
    getViewQuestionsInBalancing,
} from "../../store/Action/MqsAction";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import "../../../assets/styles/Container/PageHeader.scss";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Box from "@mui/material/Box";
import TableRow from "@mui/material/TableRow";
import { Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import TableComponent from "../../components/Table/TableComponent";
import { Link } from "react-router-dom";
import FooterPagination from "../../common/PaginationSection/FooterPagination";
import AddQuestionDialog from "./AddQuestionDialog";
import NoInfoAvailable from "../../components/NoInfoAvailable";
import TableCell from "@mui/material/TableCell";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import TooltipComponent from "../../components/ToolTip/TooltipComponent";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import CommonDeleteDialog from "../../components/DialogsBox/CommonDeleteDialog";
import { DeleteQuestionId } from "../../store/Action/MqsAction";
import { ROUTES } from "../../router/RouteList";
import SuccessfullModel from "../../components/DialogsBox/SuccessfullModel";
import LoadingComponent from "./../../components/Loading/LoadingComponent";
import HeaderPagination from "../../common/PaginationSection/HeaderPagination";
import SearchBar from "../../components/SearchBar";
import { Translate } from "react-auto-translate";
const SelectTest = (props) => {
    const {
        userLoggedIn,
        getTestQuestion,
        testQuestion,
        setSelectedQuestion,
        DeleteQuestionId,
        getQuestionList,
        mqsQuestion,
        uploadQuestions,
        getViewQuestionsInBalancing,
    } = props;
    const location = useLocation();
    const navigate = useNavigate();
    const [search, setSearch] = useState([]);
    const [state, setState] = useState({
        open: false,
        delete: false,
        deletedData: null,
        openSuccess: false,
        isLoading: false,
        page: 1,
        per_page: 10,
    });
    const { isLoading, page, per_page } = state;
    const [fileState, setFileState] = useState({
        open: false,
        files: [],
        openDialog: false,
        filename: null,
        content: null,
    });
    const prams = useParams();
    useEffect(() => {
        const data = {
            page: page,
            per_page: per_page,
        };
        if (location?.state?.id) {
            data["category_id"] = location?.state?.id;
        }
        if (prams?.test_id) {
            handleLoadingState();
            getViewQuestionsInBalancing(prams);
            handleLoadingState();
        } else {
            handleLoadingState();
            getQuestionList(data);
            handleLoadingState();
        }
    }, [location?.state?.id]);
    const { open, deletedData } = state;
    const closeDelete = () => {
        setState({ delete: false });
    };
    const handleQuestion = async () => {
        setState((prevState) => ({ ...prevState, open: true }));
        await setSelectedQuestion(null);
    };
    const handleQuestionClose = () => {
        setState((prevState) => ({ ...prevState, open: false }));
    };
    const handleDeleteAction = async (data) => {
        closeDelete();
        await DeleteQuestionId(data);
    };

    const handleEdit = async (testQuestion, mode) => {
        navigate(ROUTES.ADD_QUESTION + `/${testQuestion?.id}`, {
            state: { id: location?.state?.id, mode: mode },
        });
    };

    const handleDelete = (row) => {
        setState({
            delete: true,
            deletedData: row,
        });
    };

    const handleUpload = async (event) => {
        const data = { ...fileState };
        delete data.open;
        delete data.openDialog;
        handleLoadingState();
        setFileState((previous) => {
            return { ...previous, open: false };
        });
        const optionalData = {
            page: page,
            per_page: event?.target?.value,
        };
        if (location?.state?.id) {
            optionalData["category_id"] = location?.state?.id;
        }
        const response = await uploadQuestions(data, optionalData);
        handleLoadingState();
        setFileState((previous) => {
            return {
                ...previous,
                openDialog: response,
                content: null,
                filename: null,
                files: [],
            };
        });
    };

    const handleClickToOpen = () => {
        setFileState((previous) => {
            return {
                ...previous,
                open: true,
            };
        });
    };

    const handleUploadClose = () => {
        setFileState((previous) => {
            return {
                ...previous,
                open: false,
            };
        });
    };

    const handleViewLimitChange = async (event) => {
        const data = {
            page: page,
            per_page: event?.target?.value,
        };
        if (location?.state?.id) {
            data["category_id"] = location?.state?.id;
        }
        handleLoadingState();
        await getQuestionList(data);
        setState((previous) => {
            return {
                ...previous,
                per_page: data["per_page"],
            };
        });
        handleLoadingState();
    };

    const headerList = [
        {
            id: 1,
            name: <Translate>Questions</Translate>,
            headerClass: "mqsTableHeading",
            accessibleKey: "question",
            rowClass: "mqsTableHeadingRow",
            headerAlign: "left",
            rowAlign: "center",
            width: 500,
        },
        {
            id: 2,
            name: <Translate>Best Response</Translate>,
            headerClass: "questionTableHeading",
            accessibleKey: "best_response",
            rowClass: "questionTableHeadingRow",
            headerAlign: "left",
            rowAlign: "left",
            width: 220,
        },
        {
            id: 3,
            name: <Translate>Score</Translate>,
            headerClass: "questionTableHeading",
            accessibleKey: "score",
            rowClass: "questionTableHeadingRow",
            headerAlign: "left",
            rowAlign: "center",
            width: 50,
        },
    ];

    const handleUploadXlsv = (file) => {
        const reander = new FileReader();
        const xlsvFile = file[0];
        reander.onload = () => {
            let fileUrl = reander.result;
            let fileName = xlsvFile.path;
            setFileState((prevState) => ({
                ...prevState,
                files: file,
                filename: fileName,
                content: fileUrl,
            }));
        };
        URL.revokeObjectURL(xlsvFile.preview);
        reander.readAsDataURL(xlsvFile);
    };

    const handleLoadingState = () => {
        setState((previous) => {
            return {
                ...previous,
                isLoading: !previous.isLoading,
            };
        });
    };

    const handlePaginationOnChange = (event, pg) => {
        const data = {
            page: pg,
            per_page: per_page,
        };
        if (location?.state?.id) {
            data["category_id"] = location?.state?.id;
        }
        getQuestionList(data);
        setState((previous) => {
            return {
                ...previous,
                page: pg,
            };
        });
    };

    const thumbs = fileState.files.map((file) => (
        <div key={file.name}>
            <div>
                <Typography sx={{ color: "black", fontSize: "15px" }}>
                    {file.name}
                </Typography>
            </div>
        </div>
    ));
    const handleSearch = async (q) => {
        if (q?.length > 0) {
            const data = {
                page: state.page,
                per_page: state.per_page,
                q: q,
            };
            if (location?.state?.id) {
                data["category_id"] = location?.state?.id;
            }
            handleLoadingState();
            await getQuestionList(data);
            handleLoadingState();
        }
    };
    return (
        <Fragment>
            <Navbar />
            <CommonDeleteDialog
                open={state?.delete}
                title={"Move to Recycle Bin"}
                cancelButtonAction={closeDelete}
                cancelButtonTitle={"No"}
                submitButtonTitle={"Yes"}
                content={
                    "Are you sure, you want to move this question to the recycle bin?"
                }
                submitButtonAction={handleDeleteAction}
                submitButtonParameter={deletedData}
            />
            <CommonUploadDialog
                open={fileState.open}
                title={<Translate>Upload Master Question</Translate>}
                cancelButtonAction={handleUploadClose}
                uploadButtonAction={handleUpload}
                cancelButtonTitle={<Translate>Cancel</Translate>}
                uploadButtonTitle={<Translate>Upload</Translate>}
                validation="application/vnd.ms-excel"
                content={
                    fileState?.files?.length !== 0 ? (
                        <aside>{thumbs}</aside>
                    ) : (
                        <FileUpload
                            file={fileState}
                            keyName={"files"}
                            setFiles={setFileState}
                            handleFileUpload={handleUploadXlsv}
                            uploadIcon={<CloudUploadOutlinedIcon />}
                        />
                    )
                }
            />
            <SuccessfullModel
                open={fileState.openDialog}
                content={
                    <Translate>MQS questions uploaded successfully</Translate>
                }
                handleClose={() =>
                    setFileState((previous) => ({
                        ...previous,
                        openDialog: !previous.openDialog,
                    }))
                }
            />
            {!testQuestion || isLoading ? (
                <LoadingComponent sx={{ height: "600px" }} />
            ) : (
                <Box sx={{ px: { md: 10, xs: 1 } }} className="headerBox">
                    <AddQuestionDialog
                        open={open}
                        categoryData={location?.state}
                        cancelButtonAction={handleQuestionClose}
                        cancelButtonTitle={<Translate>Cancel</Translate>}
                    />
                    <Grid container className="headerGrid" my={2}>
                        <Grid
                            item
                            md={3}
                            lg={3}
                            sx={12}
                            display="flex"
                            alignItems="flex-end"
                        >
                            <Typography
                                className="mqsHeading"
                                sx={{ marginLeft: "0px !important" }}
                            >
                                {location?.state?.category ? (
                                    location?.state?.category
                                ) : (
                                    <Translate>MQS Database</Translate>
                                )}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            md={5}
                            lg={5}
                            sx={12}
                            alignItems="flex-end"
                            display="flex"
                            justifyContent="center"
                        >
                            <SearchBar
                                handleSearch={handleSearch}
                                setSearch={setSearch}
                            />
                        </Grid>
                        <Grid
                            item
                            md={4}
                            lg={4}
                            sm={12}
                            textAlign="end"
                            display="flex"
                            alignItems="flex-end"
                            justifyContent="flex-end"
                        >
                            <ButtonComponent
                                title={<Translate>Upload</Translate>}
                                onClick={handleClickToOpen}
                                variant="outlined"
                                startIcon={<FileUploadOutlinedIcon />}
                                sx={{ mr: 1 }}
                            />
                            <ButtonComponent
                                title={<Translate>Add Question</Translate>}
                                onClick={() => navigate(ROUTES.ADD_QUESTION)}
                                startIcon={<AddCircleOutlineIcon />}
                                variant="contained"
                            />
                        </Grid>
                    </Grid>
                    <HeaderPagination
                        handleViewLimitChange={handleViewLimitChange}
                        total={testQuestion?.total}
                        value={state?.per_page}
                    />
                    <Grid container className="tertiaryBackground">
                        <TableComponent
                            headerList={headerList}
                            serialNo={{
                                name: <Translate>S.No.</Translate>,
                                headerClass: "questionTableHeading",
                                headerAlign: "center",
                                rowClass: "serialNo",
                                align: "left",
                            }}
                            extraHeaderRight={
                                <Fragment>
                                    <TableCell
                                        align="center"
                                        className="questionTableHeading"
                                    >
                                        <Translate>Pre Question</Translate>
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        className="questionTableHeading"
                                    >
                                        <Translate>Action</Translate>
                                    </TableCell>
                                </Fragment>
                            }
                        >
                            {testQuestion?.data?.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell
                                        align="center"
                                        className="questionTableHeadingRow"
                                    >
                                        {index + 1}
                                    </TableCell>
                                    {headerList.map((obj, index) => (
                                        <TableCell
                                            component="th"
                                            key={index}
                                            align={obj?.rowAlign}
                                            className={obj?.rowClass}
                                            width={obj?.width}
                                        >
                                            <Translate>
                                                {row[obj?.accessibleKey]}
                                            </Translate>
                                        </TableCell>
                                    ))}
                                    <TableCell
                                        className="questionTableHeadingRow"
                                        align="center"
                                        width="150"
                                    >
                                        {row?.pre_question === 1 ? (
                                            <Translate>Yes</Translate>
                                        ) : (
                                            <Translate>No</Translate>
                                        )}
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        className="questionTableHeadingRow"
                                    >
                                        <TooltipComponent
                                            title={
                                                <Translate>
                                                    View Question
                                                </Translate>
                                            }
                                            arrow={true}
                                        >
                                            <IconButton
                                                className="iconButton"
                                                onClick={() => {
                                                    handleEdit(row, "view");
                                                }}
                                            >
                                                <RemoveRedEyeOutlinedIcon />
                                            </IconButton>
                                        </TooltipComponent>
                                        <TooltipComponent
                                            title={
                                                <Translate>
                                                    Edit Question
                                                </Translate>
                                            }
                                        >
                                            <IconButton
                                                className="iconButton"
                                                color="black"
                                                onClick={() =>
                                                    handleEdit(row, "edit")
                                                }
                                            >
                                                <EditOutlinedIcon />
                                            </IconButton>
                                        </TooltipComponent>
                                        <TooltipComponent
                                            title={
                                                <Translate>
                                                    Delete Question
                                                </Translate>
                                            }
                                        >
                                            <IconButton
                                                className="iconButton"
                                                color="black"
                                                onClick={() =>
                                                    handleDelete(row)
                                                }
                                            >
                                                <DeleteOutlineOutlinedIcon color="black" />
                                            </IconButton>
                                        </TooltipComponent>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableComponent>
                        {testQuestion?.data?.length === 0 && (
                            <NoInfoAvailable
                                className="noCustomerInfo"
                                noInfoTypo="noInfoTypeQuestion"
                            />
                        )}
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item md={6} xs={12} mt={2}>
                            <Link
                                className="backLink"
                                role="button"
                                onClick={() => {
                                    navigate(-1);
                                }}
                            >
                                <Translate>Back</Translate>
                            </Link>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <FooterPagination
                                count={testQuestion?.last_page}
                                page={testQuestion?.current_page}
                                onChange={handlePaginationOnChange}
                                defaultPage="1"
                            />
                        </Grid>
                    </Grid>
                </Box>
            )}
        </Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,
        testQuestion: state.mqs?.testQuestion,
        DeleteQuestion: state?.mqs?.DeleteQuestion,
        mqsQuestion: state?.mqs?.mqsQuestion,
    };
};
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getTestQuestion,
            setSelectedQuestion,
            DeleteQuestionId,
            getQuestionList,
            uploadQuestions,
            getViewQuestionsInBalancing,
        },
        dispatch
    );
};
export default connect(mapStateToProps, mapDispatchToProps)(SelectTest);
