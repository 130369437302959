import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextFieldComponent from "../../../components/TextField/TextFieldComponent";

const ShortText = (props) => {
    const {
        state,
        setState,
        data,
        children,
        handleChange,
        isRead,
        handlerFieldChange,
        isEdit,
    } = props;

    const handlerIsEdit = () => {
        if (isEdit) {
            setState((prevState) => ({ ...prevState, editLabel: true }));
        }
    };

    const onBlur = () => {
        setState((prevState) => ({ ...prevState, editLabel: false }));
    };

    return (
        <Grid container>
            <Grid item xs={12} className={"elementItemStyle"}>
                <TextFieldComponent
                    id="shortTextLabel"
                    sx={{
                        display:
                            state?.selectedEle?.selected && state?.editLabel
                                ? "block"
                                : "none",
                        color: state.fontColor,
                        "& .MuiInputBase-input": {
                            color: state.fontColor,
                        },
                    }}
                    onBlur={onBlur}
                    onChange={handleChange}
                    value={data?.label}
                    className="elementHeadingTextField"
                    autoFocus={true}
                />
                <Typography
                    display={
                        state?.selectedEle?.selected && state?.editLabel
                            ? "none"
                            : "block"
                    }
                    className="elementHeading"
                    sx={{ color: state.fontColor }}
                    variant="h5"
                    onClick={handlerIsEdit}
                >
                    {data.label}
                </Typography>
                {children}
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextFieldComponent
                        id="shortText"
                        label={"Text "}
                        className="textFieldFromBuilder"
                        disabled={isRead}
                        onChange={(event) =>
                            handlerFieldChange(event, data?.id)
                        }
                        name={"text"}
                        value={data?.formValues?.text}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ShortText;
