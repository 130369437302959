import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import ButtonComponent from "../../../../../components/ButtonComponent";
import CommonDeleteDialog from "../../../../../components/DialogsBox/CommonDeleteDialog";
import {
    getContractsTemplatesAction,
    setSelectedTemplateAction,
    deleteDocumenttAction,
    deleteAllDocumentAction,
} from "../../../../../store/Action/ContractAction";
import { getDocumentAprrovedList } from "../../../../../store/Action/DocumentDatabaseAction";
import { ReactComponent as DeleteIcon } from "../../../../../../assets/Images/Icons/DeleteIcon.svg";
import { ReactComponent as EditIcon } from "../../../../../../assets/Images/Icons/EditIcon.svg";
import "../../../../../../assets/styles/Container/DocumentRepository.scss";

function LeftDocsSidebar(props) {
    const {
        deleteDocumenttAction,
        deleteAllDocumentAction,
        getDocumentAprrovedList,
        documentDatabase,
        toggle,
        setToggle,
        handleSelectedFile,
    } = props;
    const [state, setState] = useState({
        open: false,
        toDelete: null,
        fileName: null,
        document_name: null,
        isCheckedAll: false,
        isChecked: [],
    });

    const { open, isCheckedAll, isChecked, document_name } = state;

    async function callApi() {
        setState((prevState) => ({ ...prevState, isLoading: true }));
        await getDocumentAprrovedList({});
        setState((prevState) => ({ ...prevState, isLoading: false }));
    }

    useEffect(() => {
        if (documentDatabase === null) callApi();
        if (documentDatabase) {
            setState((prevState) => ({
                ...prevState,
                filteredData: documentDatabase,
            }));
        }
        callApi();
    }, [getDocumentAprrovedList]);

    useEffect(() => {
        if (
            documentDatabase &&
            isChecked?.length === 0 &&
            documentDatabase?.length > 0 &&
            !isChecked?.includes(documentDatabase[0].id)
        ) {
            setState((prevState) => ({
                ...prevState,
                isChecked: [Number(documentDatabase[0].id)],
            }));
        }
    }, [documentDatabase]);

    const handleSelectAll = (e) => {
        setState((prevState) => ({
            ...prevState,
            isCheckedAll: !isCheckedAll,
            isChecked:
                documentDatabase?.length && documentDatabase.map((li) => li.id),
        }));
        if (isCheckedAll) {
            setState((prevState) => ({
                ...prevState,
                isChecked: [],
            }));
        }
    };

    const handleClickOnCheckbox = (e) => {
        const { value, checked } = e.target;
        if (!checked) {
            setState((prevState) => ({
                ...prevState,
                isChecked: isChecked?.filter((item) => item !== Number(value)),
            }));
        } else {
            setState((prevState) => ({
                ...prevState,
                isChecked: [...isChecked, Number(value)],
            }));
        }
    };
    async function handleDeleteAll() {
        await deleteAllDocumentAction({
            is_active: false,
            ids: [isChecked],
        });
        setState((prevState) => ({
            ...prevState,
            open: true,
        }));
        setToggle(!toggle);
    }

    const setDelete = (data, category_id) => {
        setState((prevState) => ({
            ...prevState,
            open: true,
            toDelete: category_id,
            document_name: data.document_name,
        }));
    };
    async function handleDelete() {
        let data = {
            is_active: false,
        };
        await deleteDocumenttAction(state?.toDelete, data);
        setState((prevState) => ({
            ...prevState,
            open: false,
        }));
        setToggle(!toggle);
    }
    const deleteContent = `Are you sure,you want to remove the (${document_name} DocumentDatabase) from the DocumentDatabase?`;
    return (
        <Box className="leftSideBarContainer">
            <CommonDeleteDialog
                open={open}
                setOpen={(isOpen) => {}}
                cancelButtonAction={() => {
                    setState({
                        open: false,
                        toDelete: [],
                        document_name: null,
                    });
                }}
                onClick={handleDelete}
                title="Delete Document"
                content={deleteContent}
            />
            <Box pl={4} pr={0.8} py={1.2} display="flex">
                <FormControlLabel
                    className="sidebar-chebox-control"
                    value="end"
                    sx={{ marginRight: "0px" }}
                    control={
                        <Checkbox
                            checked={
                                isChecked?.length === documentDatabase?.length
                            }
                            onChange={handleSelectAll}
                        />
                    }
                    labelPlacement="Select"
                />
                <ButtonComponent
                    disabled={isChecked?.length < 2}
                    onClick={(event) => handleDeleteAll(event, true)}
                    title={"Delete All"}
                    className={`deleteAllbutton ${
                        isChecked?.length > 1 && "active"
                    }`}
                />
            </Box>
            <Divider />
            {documentDatabase &&
                documentDatabase?.map((template) => {
                    return (
                        <Grid container px={4}>
                            <Grid item md={9}>
                                <FormControlLabel
                                    value="end"
                                    control={
                                        <Checkbox
                                            checked={isChecked?.includes(
                                                template?.id
                                            )}
                                            onChange={handleClickOnCheckbox}
                                            value={template?.id}
                                        />
                                    }
                                    label={
                                        <span
                                            style={{
                                                fontSize: "17px",
                                                fontWeight: 600,
                                            }}
                                        >
                                            {template?.document_name}
                                        </span>
                                    }
                                    labelPlacement="Select"
                                />
                            </Grid>
                            <Grid item md={3} display={"flex"}>
                                <IconButton
                                    onClick={() => {
                                        setState((prevState) => ({
                                            ...prevState,
                                            isChecked: [Number(template?.id)],
                                        }));
                                        handleSelectedFile(template);
                                    }}
                                >
                                    <EditIcon />
                                </IconButton>
                                <Grid item md={3}>
                                    <IconButton
                                        aria-label="delete"
                                        disabled={
                                            !isChecked?.includes(template.id)
                                        }
                                        onClick={() => {
                                            setDelete(template, template.id);
                                        }}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    );
                })}
        </Box>
    );
}
const mapStateToProps = (state) => {
    return {
        customer: state.customer,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getContractsTemplatesAction,
            setSelectedTemplateAction,
            getDocumentAprrovedList,
            deleteAllDocumentAction,
            deleteDocumenttAction,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(LeftDocsSidebar);
