import React from "react";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import TableComponent from "../../../../../components/Table/TableComponent";
import { Translate } from "react-auto-translate";
const TestingTable = (props) => {
    const { data } = props;
    const headerList = [
        {
            id: 1,
            name: <Translate>Test Name</Translate>,
            headerClass: "tableHeading",
            accessibleKey: "name",
            rowClass: "typo4",
            headerAlign: "center",
            rowAlign: "center",
        },
        {
            id: 2,
            name: <Translate>Unique Test code</Translate>,
            headerClass: "tableHeading",
            accessibleKey: "unique_test_code",
            rowClass: "typo4",
            headerAlign: "center",
            rowAlign: "center",
        },
        {
            id: 3,
            name: (
                <Translate>
                    Statutes, Rules, Laws, Regulations Subject to the Test
                </Translate>
            ),
            headerClass: "tableHeading",
            accessibleKey: "laws",
            rowClass: "typo4",
            headerAlign: "center",
            rowAlign: "center",
        },
        {
            id: 4,
            name: (
                <Translate>
                    Geographic Applicability (Applicable Circuits, States,
                    Locales)
                </Translate>
            ),
            headerClass: "tableHeading",
            accessibleKey: "jurisdiction",
            rowClass: "typo4",
            headerAlign: "center",
            rowAlign: "center",
        },
        {
            id: 5,
            name: "Score",
            headerClass: "tableHeading",
            accessibleKey: "percentage",
            rowClass: "typo4",
            headerAlign: "center",
            rowAlign: "center",
        },
        {
            id: 6,
            name: "Test Result",
            headerClass: "tableHeading",
            accessibleKey: "result",
            rowClass: "typo4",
            headerAlign: "center",
            rowAlign: "center",
        },
    ];

    const testingList = [
        {
            test_name: (
                <Translate>Common Law "Right to Control" Test</Translate>
            ),
            jurisdiction: <Translate>Federal (United States)</Translate>,
            test_result: <Translate>Pass</Translate>,
            knocked_over: <Translate>Yes</Translate>,
        },
        {
            test_name: <Translate>Worker's Compensation Test</Translate>,
            jurisdiction: <Translate>State (United States)</Translate>,
            test_result: <Translate>Fail</Translate>,
            knocked_over: <Translate>No</Translate>,
        },
        {
            test_name: <Translate>FLSA "Economic Realities" Test</Translate>,
            jurisdiction: <Translate>Federal (United States)</Translate>,
            test_result: <Translate>Fail</Translate>,
            knocked_over: <Translate>No</Translate>,
        },
    ];

    const extraHeaderList = [
        {
            id: 1,
            name: <Translate>Test Name</Translate>,
            headerClass: "tableHeading",
            accessibleKey: "name",
            rowClass: "typo4",
            headerAlign: "center",
            rowAlign: "center",
        },
        {
            id: 2,
            name: <Translate>Score</Translate>,
            headerClass: "tableHeading",
            accessibleKey: "score",
            rowClass: "typo4",
            headerAlign: "center",
            rowAlign: "center",
        },
        {
            id: 3,
            name: <Translate>Result</Translate>,
            headerClass: "tableHeading",
            accessibleKey: "result",
            rowClass: "typo4",
            headerAlign: "center",
            rowAlign: "center",
        },
    ];

    console.log(data)
    return (
        <TableComponent headerList={headerList}>
            {data?.length &&
                data.map((row, index) => (
                    <>
                        <TableRow
                            key={row.name}
                            className={
                                index % 2 !== 0 ? "tertiaryBackground" : ""
                            }
                        >
                            {headerList.map((obj) => (
                                <TableCell
                                    component="th"
                                    key={obj?.name}
                                    align={obj?.rowAlign}
                                    className={obj?.rowClass}
                                    sx={{
                                        borderBottom: row.extra_data
                                            ? "none"
                                            : "1px solid rgba(224, 224, 224, 1)",
                                    }}
                                >
                                     {console.log(row[obj?.accessibleKey])}
                                    {row[obj?.accessibleKey] == "Fail" ? (
                                        <div style={{ color: "red" }}>
                                           
                                            {row[obj?.accessibleKey]}
                                        </div>
                                    ) : row[obj?.accessibleKey] == "Pass" ? (
                                        <div style={{ color: "green" }}>
                                            {row[obj?.accessibleKey]}
                                        </div>
                                    ) : obj?.name === "Score" ? (
                                        row[obj?.accessibleKey] + "%"
                                    ) : (
                                        obj?.accessibleKey == 'laws' ? 
                                        <div style={{textAlign: 'left'}}>{row[obj?.accessibleKey]}</div> :
                                        row[obj?.accessibleKey]
                                    )}
                                </TableCell>
                            ))}
                        </TableRow>

                        {row.extra_data ? (
                            <TableRow>
                                <TableCell
                                    style={{ paddingBottom: 1, paddingTop: 1 }}
                                    colSpan={5}
                                >
                                    <Box sx={{ margin: 1 }}>
                                        <Table
                                            size="small"
                                            aria-label="simple table"
                                        >
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell
                                                        sx={{ fontWeight: 600 }}
                                                        align="left"
                                                    >
                                                        <Translate>
                                                            Test Name
                                                        </Translate>
                                                    </TableCell>
                                                    <TableCell
                                                        align="center"
                                                        sx={{ fontWeight: 600 }}
                                                    >
                                                        <Translate>
                                                            Score
                                                        </Translate>
                                                    </TableCell>
                                                    <TableCell
                                                        align="right"
                                                        sx={{ fontWeight: 600 }}
                                                    >
                                                        <Translate>
                                                            Result
                                                        </Translate>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {row?.extra_data?.map(
                                                    (extraRow) => (
                                                        <TableRow
                                                            key={extraRow.name}
                                                            sx={{
                                                                "&:last-child td, &:last-child th":
                                                                    {
                                                                        border: 0,
                                                                    },
                                                            }}
                                                        >
                                                            <TableCell
                                                                component="th"
                                                                scope="row"
                                                                align="left"
                                                            >
                                                                {extraRow.name}
                                                            </TableCell>
                                                            <TableCell
                                                                align="center"
                                                                component="th"
                                                                scope="row"
                                                            >
                                                                {`${extraRow.score} %`}
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                {
                                                                    extraRow.result
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                )}
                                            </TableBody>
                                        </Table>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        ) : null}
                    </>
                ))}
        </TableComponent>
    );
};

export default TestingTable;
