import React from "react";
import Navbar from './../../common/Header/Navbar';

import { connect } from "react-redux";
function Standard(props) {
    const {
        userLoggedIn
    } = props;
    return (
        <>
            <Navbar />
            <div
                style={{
                    fontSize: "20px",
                    color: "#f26a21",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                {userLoggedIn?.language_data?.documentation?.coming_soon || "Coming Soon"}
            </div>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,

    };
};


export default connect(mapStateToProps, null) (Standard);
