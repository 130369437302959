import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import ListSubheader from "@mui/material/ListSubheader";
import MenuItem from "@mui/material/MenuItem";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Navbar from "../../common/Header/Navbar";
import { ROUTES } from "../../router/RouteList";
import AssignUserDialog from "./AssignUserDialog";
import vector from "../../../assets/Images/Icons/Vector.svg";
import UnassignedTable from "./UnassignedTable";
import NoInfoAvailable from "../../components/NoInfoAvailable";
import DropDownComponent from "../../components/DropDown/DropDownComponent";
import RejectContractor from "./RejectContractor";
import TabPanel from "./TabPanelComponents";
import PageHeader from "../../common/PageHeader";
import ButtonComponent from "../../components/ButtonComponent";
import LoadingComponent from "../../components/Loading/LoadingComponent";
import { directIndirectOptions } from "../../utils/CommonData";
import { headers } from "./Headers";
import TooltipComponent from "../../components/ToolTip/TooltipComponent";
import { Typography } from "@mui/material";
import TableComponent from "../../components/Table/TableComponent";
import {
    conductorTypeAction,
    conductorAction,
    assignUserAction,
    getAllContractorList,
    selectedContractorsAction,
    assignSelectService,
    getWorkersProgressList,
    getCustomerForCompliance,
} from "../../store/Action/ComplianceWorkflowAction";
import {
    setSelectedCustomer,
    getCustomerById,
} from "../../store/Action/CustomerAction";
import { getUserById, setSelectedUser } from "../../store/Action/UserAction";
import { getDropdown } from "../../store/Action/LoginAction";
import "../../../assets/styles/Container/ComplianceWorkflow.scss";
import color from "../../../assets/styles/Color.scss";
import { Translate } from "react-auto-translate";
function ComplianceWorkflow(props) {
    const navigate = useNavigate();
    const {
        commonDropdown,
        getDropdown,
        conductorTypeAction,
        conductorList,
        conductorAction,
        complianceList,
        getAllContractorList,
        contractors,
        assignUserAction,
        setSelectedUser,
        selectedService,
        selectedContractorsAction,
        assignSelectService,
        selectedCustomer,
        getUserById,
        getCustomerById,
        userLoggedIn,
        getWorkersProgressList,
        workflowWorkersList,
        getCustomerForCompliance,
        loggedInCustomer,
    } = props;
    console.log("comspilance props", props);
    const tabs = {
        0: "U",
        1: "I",
        2: "C",
        3: undefined,
    };
    const [state, setState] = useState({
        conductorType: "",
        conductor: "",
        complianceService: "",
        isCheckedAll: false,
        isChecked: [],
        contractor: null,
        contractors: "",
        value: 0,
        isLoading: false,
        result: {
            allResults: [],
            overAllResult: null,
        },
    });
    const {
        conductorType,
        conductor,
        complianceService,
        isChecked,
        contractor,
        value,
        isLoading,
        isCheckedAll,
    } = state;
    const [header, setHeaders] = useState({
        u_header: [],
        i_header: [],
        c_header: [],
        all_header: [],
    });
    const [tabId, setTabId] = useState("pcp");
    const [isDelete, setisDelete] = useState(false);
    const selectContractors = async (data) => {
        await selectedContractorsAction(data);
        await getUserById(data?.id);
    };

    useEffect(() => {
        setHeaders((prevState) => ({
            ...prevState,
            u_header: headers("U"),
            i_header: headers("I"),
            c_header: headers("C"),
            all_header: headers("All"),
        }));
        if (isChecked?.length > 0) {
            selectedContractorsAction(isChecked);
        } else {
            selectedContractorsAction(null);
            setSelectedUser(null);
        }
        if (commonDropdown?.length === 0) {
            getDropdown();
        }
    }, [isChecked, contractor, window.location.pathname]);

    useEffect(() => {
        if (userLoggedIn?.user?.client_id && !loggedInCustomer) {
            getCustomerForCompliance(userLoggedIn?.user?.client_id);
        }
        if (loggedInCustomer && conductorType === "") {
            conductorTypeAction(
                loggedInCustomer?.client_type === "dc"
                    ? "Direct Conductor"
                    : "InDirect Conductor"
            );
            conductorAction(loggedInCustomer?.id);
            setState((prevState) => ({
                ...prevState,
                conductorType:
                    loggedInCustomer?.client_type === "dc"
                        ? {
                              label: "Direct Conductor",
                              value: "dc",
                              id: "dc",
                              name: "DirectConductor",
                          }
                        : {
                              label: "InDirect Conductor",
                              value: "idc",
                              id: "idc",
                              name: "InDirectConductor",
                          },
            }));
        }
        if (conductorList && conductor === "") {
            setState((prevState) => ({
                ...prevState,
                conductor: {
                    id: loggedInCustomer?.id,
                    content: loggedInCustomer?.company_info?.name,
                    uid: loggedInCustomer?.guid,
                },
            }));
        }
    }, [loggedInCustomer, conductorList]);

    async function handleChangeSelect(e) {
        await conductorTypeAction(e.value);
        setState((prevState) => ({ ...prevState, conductorType: e }));
    }
    async function handleConductor(e) {
        await conductorAction(e.id);
        await getCustomerById(e.id);
        setState((prevState) => ({ ...prevState, conductor: e }));
    }
    async function handleCompliance(e) {
        if (conductor.id !== "undefined") {
            setState((prevState) => ({ ...prevState, isLoading: true }));
            await getWorkersProgressList(conductor.id, e.id);
            await assignUserAction(conductor.id, e.id, tabs[value]);
            await assignSelectService(e);
            setState((prevState) => ({
                ...prevState,
                complianceService: e,
                isLoading: false,
            }));
        }
    }
    useEffect(() => {
        conductor?.id && assignUserAction(conductor?.id, tabId, tabs[value]);
    }, [isDelete]);

    async function handleChange(e, newValue) {
        setState((prevState) => ({
            ...prevState,
            isLoading: true,
            isChecked: [],
        }));
        if (newValue !== 3) {
            await assignUserAction(
                conductor?.id,
                complianceService.id,
                tabs[newValue]
            );
        } else {
            await getAllContractorList(conductor?.id, complianceService?.id);
        }
        setState((prevState) => ({
            ...prevState,
            value: newValue,
            contractors: e,
            isLoading: false,
        }));
    }
    const handleNewContractor = async () => {
        await setSelectedUser({ state });
        navigate(`${ROUTES.CREATE_USER}?${conductor?.id}`);
    };
    const handleSetting = (id) => {
        navigate(`${ROUTES.SETTINGS}/${id}`, {
            state: {
                selectedCustomer: selectedCustomer || loggedInCustomer,
                service: selectedService,
            },
        });
    };
    const headerList = [
        {
            id: 1,
            name: "Name",
            headerClass: "tableHeading",
            accessibleKey: "name",
            rowClass: "commentCell",
            headerAlign: "left",
            rowAlign: "left",
            sx: { padding: "20px 16px !important" },
        },
        {
            id: 2,
            name: "Location",
            headerClass: "tableHeading",
            accessibleKey: "state",
            rowClass: "commentCell",
            headerAlign: "left",
            rowAlign: "left",
            sx: { padding: "20px 16px !important" },
        },
        {
            id: 3,
            name: "Position",
            headerClass: "tableHeading",
            accessibleKey: "role",
            rowClass: "typo4",
            headerAlign: "left",
            rowAlign: "left",
            sx: { padding: "20px 16px !important" },
        },
        {
            id: 4,
            name: "Collar",
            headerClass: "tableHeading",
            accessibleKey: "collar",
            rowClass: "commentCell",
            headerAlign: "left",
            rowAlign: "left",
            sx: { padding: "20px 16px !important" },
        },
        {
            id: 5,
            name: "Phase",
            headerClass: "tableHeading",
            accessibleKey: "phase",
            rowClass: "commentCell",
            headerAlign: "left",
            rowAlign: "left",
            sx: { padding: "20px 16px !important" },
        },
        // {
        //     id: 5,
        //     name: "Progress",
        //     headerClass: "tableHeading",
        //     accessibleKey: "tasks",
        //     rowClass: "typo4",
        //     headerAlign: "center",
        //     rowAlign: "center",
        // },
        // {
        //     id: 6,
        //     name: "Action",
        //     headerClass: "tableHeading",
        //     accessibleKey: "action",
        //     rowClass: "typo4",
        //     headerAlign: "center",
        //     rowAlign: "center",
        // },
    ];
    // const handleSelectAll = async (e) => {
    //     setState((prevState) => ({
    //         ...prevState,
    //         isCheckedAll: !isCheckedAll,
    //         isChecked: contractors?.length && contractors.map((li) => li),
    //     }));
    //     if (isChecked?.length === contractors?.length) {
    //         setState((prevState) => ({
    //             ...prevState,
    //             isChecked: [],
    //         }));
    //     }
    // };
    // const handleSelectAllTable = async (row) => {
    //     setState((prevState) => ({
    //         ...prevState,
    //         contractor: row,
    //     }));
    //     await selectContractors(row);
    //     navigate(`${ROUTES.CUSTOMERS_INFORMATION}/${conductor.uid}/${row.id}`, {
    //         state: { row },
    //     });
    // };

    // const handleCheck = (id) => {
    //     const checkedData = isChecked?.find((item) => item.id === id);
    //     if (checkedData) return true;
    //     else return false;
    // };
    // const handleClickOnCheckbox = (e, data) => {
    //     const { value, checked } = e.target;
    //     if (!checked) {
    //         //remove
    //         setState((prevState) => ({
    //             ...prevState,
    //             isChecked: isChecked.filter(
    //                 (item) => item.id !== Number(value)
    //             ),
    //         }));
    //     } else {
    //         //add
    //         let row = contractors.filter((item) => item.id === value);
    //         setState((prevState) => ({
    //             ...prevState,
    //             isChecked: [...isChecked, data],
    //             contractor: row,
    //         }));
    //     }
    // };
    // const handleNavigateProfile = async (row) => {
    //     setState((prevState) => ({ ...prevState, isLoading: true }));
    //     await getUserById(row?.user.id);
    //     setState((prevState) => ({ ...prevState, isLoading: false }));
    //     navigate(`${ROUTES.VIEW_USER}?${row?.user.id}`);
    // };
    const handleViewAction = async (item, rowData) => {
        if (
            item.status === "Completed" &&
            (item.slug === "rcp_contractor" ||
                item?.slug === "rcp_contractor_retest")
        ) {
            navigate(ROUTES.View_Response, {
                state: {
                    data: {
                        guid: rowData.user.guid,
                        user_id:
                            item.slug === "rcp_contractor" ||
                            item?.slug === "rcp_contractor_retest"
                                ? rowData.user.id
                                : rowData.user.manager_id,
                        test_type: item.test_type,
                        role_id:
                            item.slug === "rcp_contractor" ||
                            item?.slug === "rcp_contractor_retest"
                                ? rowData.user?.role_id
                                : rowData.user.manager_role_id,
                        contractor_id:
                            item.slug === "rcp_manager"
                                ? rowData.user.id
                                : null,
                        mode: item.mode,
                    },
                },
            });
        }
        if (
            item.status === "Completed" &&
            (item.slug === "rcp_manager" || item?.slug === "rcp_manager_retest")
        ) {
            localStorage.setItem("task_id", item.id);
            localStorage.setItem("mode", item.mode);
            navigate(
                `${ROUTES.CUSTOMERS_INFORMATION}/${conductor.uid}/${rowData.user.manager_id}`,
                {
                    state: {
                        task_id: item.id,
                        manager_role_id: rowData?.user?.manager_role_id,
                        contractor_ID: rowData.user.id,
                        mode: item?.mode,
                    },
                }
            );
        }
    };
    const handleManageAction = async (row) => {
        let findTask = row.tasks.find((obj) => obj.action === true);
        if (findTask) {
            // await selectContractors(row.user);
            localStorage.setItem("task_id", findTask.id);
            localStorage.setItem("mode", findTask?.mode);
            if (
                findTask?.slug === "rcp_contractor" ||
                findTask?.slug === "rcp_contractor_retest"
            ) {
                navigate(
                    `${ROUTES.CUSTOMERS_INFORMATION}/${conductor.uid}/${row.user.id}`,
                    { state: { task_id: findTask.id, mode: findTask?.mode } }
                );
            }
            if (
                findTask?.slug === "rcp_manager" ||
                findTask?.slug === "rcp_manager_retest"
            ) {
                navigate(
                    `${ROUTES.CUSTOMERS_INFORMATION}/${conductor.uid}/${row.user.manager_id}`,
                    {
                        state: {
                            task_id: findTask.id,
                            manager_role_id: row?.user?.manager_role_id,
                            contractor_ID: row?.user?.id,
                            mode: findTask?.mode,
                        },
                    }
                );
            }
            if (
                findTask?.slug === "rcp_conflict" ||
                findTask?.slug === "rcp_conflict_retest"
            ) {
                localStorage.setItem(
                    "role_id",
                    row?.user?.manager_role_id
                        ? row?.user?.manager_role_id
                        : row?.user?.role_id
                );
                localStorage.setItem("task_id", findTask.id);
                localStorage.setItem("mode", findTask?.mode);
                window.open(
                    `${
                        ROUTES.Conflict_Response +
                        "/" +
                        conductor?.id +
                        "/" +
                        row?.user?.manager_id +
                        "/" +
                        row?.user?.id
                    }`,
                    "_blank"
                );
            }
        }
        //  else {
        //     let findContrator = row.tasks.find(
        //         (obj) =>
        //             obj.action === false &&
        //             obj.status === "Completed" &&
        //             obj?.slug === "rcp_contractor"
        //     );
        //     if (findContrator) {
        //         localStorage.setItem("task_id", findContrator.id);
        //         navigate(
        //             `${ROUTES.CUSTOMERS_INFORMATION}/${conductor.uid}/${row.user.id}`,
        //             { state: { task_id: findContrator.id } }
        //         );

        //     }
        // }
    };
    const showExtraBox = (rowData) => {
        const checkLength = 3 - rowData.tasks.length;
        const cardArray = Array.from(
            { length: checkLength },
            (_, index) => index + 1
        );
        return cardArray.map(() => (
            <Paper
                sx={{
                    boxShadow: "0px 0px 1px",
                    borderRadius: "0",
                    width: "20px",
                    height: "20px",
                    backgroundColor: "#F6F6F6",

                    // cursor: "pointer",
                }}
                // onClick={() => handleViewAction(item)}
            />
        ));
    };
    const showProgressBox = (item, rowData) => {
        // updateStateData(rowData);
        return item.status !== "Not Started" ? (
            <>
                <TooltipComponent
                    title={
                        item.slug === "rcp_contractor" ||
                        item.slug === "rcp_contractor_retest"
                            ? "Contractor Test Completed"
                            : item.slug === "rcp_manager" ||
                              item.slug === "rcp_manager_retest"
                            ? "Manager Test Completed"
                            : item.slug === "rcp_conflict" ||
                              item.slug === "rcp_conflict_retest"
                            ? "Resolved Confilct"
                            : `Result /`
                    }
                    arrow
                    followCursor
                >
                    <Paper
                        sx={{
                            boxShadow: "0px 0px 1px",
                            borderRadius: "0",
                            width: "20px",
                            height: "20px",
                            backgroundColor:
                                item.status === "Not Started"
                                    ? "#F6F6F6"
                                    : "#4BB543",
                            cursor: "pointer",
                        }}
                        onClick={() => handleViewAction(item, rowData)}
                    />
                </TooltipComponent>
            </>
        ) : (
            <Paper
                sx={{
                    boxShadow: "0px 0px 1px",
                    borderRadius: "0",
                    width: "20px",
                    height: "20px",
                    backgroundColor:
                        item.status === "Not Started" ? "#F6F6F6" : "#4BB543",
                }}
            />
        );
    };

    const showResultBox = (row) => {
        let passCount = 0;
        let failCount = 0;
        let backCount = 0;
        for (const result of row.tasks) {
            switch (result.result) {
                case "pass":
                    passCount++;
                    break;
                case "fail":
                    failCount++;
                    break;
                default:
                    break;
            }
        }
        let overallResult;
        if (passCount === 2) {
            overallResult = "pass";
        } else if (failCount > 0) {
            overallResult = "fail";
        }
        return (
            <TooltipComponent
                title={`Result:- ${overallResult}`}
                arrow
                followCursor
            >
                <Paper
                    sx={{
                        boxShadow: "0px 0px 1px",
                        borderRadius: "0",
                        width: "20px",
                        height: "20px",
                        backgroundColor:
                            overallResult === "pass" ? "#4BB543" : " #FF0000",
                        cursor: "pointer",
                    }}
                />
            </TooltipComponent>
        );
    };

    return (
        <>
            <Navbar />
            <Box sx={{ px: { md: 7, xs: 1 }, overflowX: "hidden" }}>
                <PageHeader
                    title={
                        userLoggedIn?.language_data?.comman?.menu_compliance ||
                        "Compliance Workflow"
                    }
                />
                <Grid container>
                    <Grid item md={2.5} sm={3} xs={4} mt={4}>
                        <InputLabel className="complianceSubHeading" required>
                            {userLoggedIn?.language_data?.complianceworkflow
                                ?.conductor_type || "Conductor Type"}
                        </InputLabel>
                    </Grid>
                    <Grid item md={3} sm={6} mt={4}>
                        {userLoggedIn?.user?.client_id ? (
                            <Typography className="font_18_600">
                                {!userLoggedIn?.user?.client_id
                                    ? conductorType
                                    : conductorType?.label || ""}
                            </Typography>
                        ) : (
                            <DropDownComponent
                                label={
                                    userLoggedIn?.language_data
                                        ?.complianceworkflow?.conductor_type ||
                                    "Conductor Type"
                                }
                                required={true}
                                name={"Conductor Type"}
                                fullWidth={true}
                                onChange={(e) =>
                                    handleChangeSelect(e.target.value)
                                }
                                disabled={userLoggedIn?.user?.client_id}
                                value={
                                    !userLoggedIn?.user?.client_id
                                        ? conductorType
                                        : conductorType?.label || ""
                                }
                            >
                                <ListSubheader className="subheader">
                                    Select
                                </ListSubheader>
                                {directIndirectOptions.map((option, index) => (
                                    <MenuItem
                                        id={`conductorType${index}`}
                                        key={option.id}
                                        value={
                                            !userLoggedIn?.user?.client_id
                                                ? option
                                                : option.label
                                        }
                                    >
                                        {option?.label}
                                    </MenuItem>
                                ))}
                            </DropDownComponent>
                        )}
                    </Grid>
                    <Grid item md={3} xs={1} pl={5} mt={4}>
                        {conductorType && conductor && complianceService && (
                            <ButtonComponent
                                title={"Settings"}
                                variant="text"
                                className="link"
                                onClick={() => handleSetting(conductor?.id)}
                            />
                        )}
                    </Grid>
                    <Grid container mt={3}>
                        <Grid item md={2.5} xs={3}>
                            <InputLabel
                                className="complianceSubHeading"
                                required
                            >
                                {userLoggedIn?.language_data?.complianceworkflow
                                    ?.conductor || "Conductor"}
                            </InputLabel>
                        </Grid>
                        <Grid item md={3} xs={3}>
                            {userLoggedIn?.user?.client_id &&
                            conductorType.value === "dc" ? (
                                <Typography className="font_18_600">
                                    {!userLoggedIn?.user?.client_id
                                        ? conductor
                                        : userLoggedIn?.user?.company_name ||
                                          conductor?.content}
                                </Typography>
                            ) : (
                                <DropDownComponent
                                    label={
                                        userLoggedIn?.language_data
                                            ?.complianceworkflow?.conductor ||
                                        "Conductor"
                                    }
                                    required={true}
                                    name={"Conductor"}
                                    fullWidth={true}
                                    disabled={
                                        userLoggedIn?.user?.client_id &&
                                        conductorType?.value === "dc"
                                    }
                                    onChange={(e) =>
                                        handleConductor(e.target.value)
                                    }
                                    value={
                                        !userLoggedIn?.user?.client_id
                                            ? conductor
                                            : conductor?.content ||
                                              userLoggedIn?.user
                                                  ?.company_name ||
                                              ""
                                    }
                                >
                                    <ListSubheader className="subheader">
                                        Select
                                    </ListSubheader>
                                    {conductorList?.length &&
                                        conductorList.map((option, index) => (
                                            <MenuItem
                                                id={`conductor${index}`}
                                                key={option.id}
                                                value={
                                                    !userLoggedIn?.user
                                                        ?.client_id
                                                        ? option
                                                        : option.content
                                                }
                                            >
                                                {option?.content}
                                            </MenuItem>
                                        ))}
                                </DropDownComponent>
                            )}
                        </Grid>
                    </Grid>
                    <Grid container mt={3}>
                        <Grid item md={2.5} xs={3}>
                            <InputLabel
                                className="complianceSubHeading"
                                required
                            >
                                {userLoggedIn?.language_data?.complianceworkflow
                                    ?.compliance_service ||
                                    "Compliance Service"}
                            </InputLabel>
                        </Grid>
                        <Grid item md={3} xs={3}>
                            <DropDownComponent
                                label={
                                    userLoggedIn?.language_data
                                        ?.complianceworkflow
                                        ?.compliance_service ||
                                    "Compliance Services"
                                }
                                name="complianceService"
                                value={
                                    selectedService === null
                                        ? complianceService?.id
                                        : selectedService
                                }
                                fullWidth={true}
                                disabled={
                                    conductor && conductorType ? false : true
                                }
                                required={true}
                            >
                                <ListSubheader className="subheader">
                                    Select
                                </ListSubheader>
                                {complianceList?.length > 0 &&
                                    complianceList.map((obj) => (
                                        <MenuItem
                                            key={obj.id}
                                            value={obj}
                                            className="subheader"
                                            onClick={() =>
                                                handleCompliance(obj)
                                            }
                                        >
                                            {obj.label}
                                        </MenuItem>
                                    ))}
                            </DropDownComponent>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    container
                    mt={5}
                    mb={2}
                    visibility={
                        conductorType && conductor && complianceService
                            ? "visible"
                            : "hidden"
                    }
                    className="tableGridContainer"
                    justifyContent={"center"}
                >
                    {isLoading ? (
                        <LoadingComponent
                            sx={{
                                width: "91.7%",
                                height: "250px !important",
                                backgroundColor: color.tertiary,
                                marginLeft: "-24px",
                            }}
                        />
                    ) : contractors &&
                      complianceService?.id === "rcp" &&
                      contractors.length !== 0 ? (
                        <TableComponent
                            headerList={headerList}
                            extraHeaderRight={
                                <>
                                    <TableCell
                                        align="left"
                                        className="tableHeading"
                                        sx={{ padding: "20px 16px !important" }}
                                    >
                                        Progress
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        className="tableHeading"
                                    >
                                        Pending Action
                                    </TableCell>
                                </>
                            }
                            // extraHeaderLeft={
                            //     <TableCell className="tableColor">
                            //         <FormControlLabel
                            //             control={
                            //                 <Checkbox
                            //                     checked={
                            //                         isChecked?.length ===
                            //                         contractors?.length
                            //                     }
                            //                     onChange={handleSelectAll}
                            //                 />
                            //             }
                            //             labelPlacement="Select"
                            //         ></FormControlLabel>
                            //     </TableCell>
                            // }
                        >
                            {workflowWorkersList?.length !== 0 &&
                                workflowWorkersList.map((row, index) => {
                                    return (
                                        <TableRow
                                            key={index}
                                            style={{
                                                background:
                                                    index % 2 === 0
                                                        ? "#F2F2F2"
                                                        : "",
                                            }}
                                        >
                                            {headerList.map((obj) => (
                                                <TableCell
                                                    component="th"
                                                    key={obj.name}
                                                    align={obj.rowAlign}
                                                    className={obj.rowClass}
                                                >
                                                    {
                                                        row.user[
                                                            obj.accessibleKey
                                                        ]
                                                    }
                                                </TableCell>
                                            ))}
                                            <TableCell
                                                component="th"
                                                className="commentCell"
                                            >
                                                <Box
                                                    display={"flex"}
                                                    gap={"7px"}
                                                    alignItems={"center"}
                                                    alignContent={"center"}
                                                >
                                                    {row.tasks.map((item) =>
                                                        showProgressBox(
                                                            item,
                                                            row
                                                        )
                                                    )}
                                                    {showExtraBox(row)}
                                                    {row.tasks.length >= 2 ? (
                                                        showResultBox(row)
                                                    ) : (
                                                        <Paper
                                                            sx={{
                                                                boxShadow:
                                                                    "0px 0px 1px",
                                                                borderRadius:
                                                                    "0",
                                                                width: "20px",
                                                                height: "20px",
                                                                backgroundColor:
                                                                    "#F6F6F6",

                                                                // cursor: "pointer",
                                                            }}
                                                            // onClick={() => handleViewAction(item)}
                                                        />
                                                    )}
                                                    <Typography className="commentCell">
                                                        {
                                                            row.tasks[0]
                                                                ?.time_elapsed
                                                        }
                                                    </Typography>
                                                </Box>
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                className="cellContractorName"
                                                align="center"
                                                sx={{
                                                    cursor: row.tasks.map(
                                                        (obj) => {
                                                            return obj.action ===
                                                                true
                                                                ? "pointer"
                                                                : "auto";
                                                        }
                                                    ),
                                                }}
                                                onClick={() =>
                                                    handleManageAction(row)
                                                }
                                            >
                                                {row.tasks.map((obj) => {
                                                    return obj.action === true
                                                        ? obj.name
                                                        : // : obj.status ===
                                                          //       "Completed" &&
                                                          //   obj?.slug ===
                                                          //       "rcp_contractor"
                                                          // ? "View Responses"
                                                          null;
                                                })}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableComponent>
                    ) : (
                        <NoInfoAvailable
                            className="noInfoUnassigned"
                            noInfoTypo="noInformationUnassigned"
                        />
                    )}
                </Grid>
            </Box>
        </>
    );
}
const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,
        commonDropdown: state.auth.commonDropdown,
        conductorList: state.complianceWorkflow.conductorList,
        complianceList: state.complianceWorkflow.complianceList,
        contractors: state.complianceWorkflow.contractors,
        selectedService: state.complianceWorkflow.selectedService,
        selectedContractors: state.complianceWorkflow.selectedContractors,
        selectedCustomer: state?.customer?.selectedCustomer,
        selectedUser: state.user.selectedUser,
        workflowWorkersList: state.complianceWorkflow.workflowWorkersList,
        loggedInCustomer: state.complianceWorkflow?.loggedInCustomer,
    };
};
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            conductorTypeAction,
            conductorAction,
            assignUserAction,
            getAllContractorList,
            selectedContractorsAction,
            setSelectedCustomer,
            setSelectedUser,
            getCustomerById,
            assignSelectService,
            getDropdown,
            getUserById,
            getWorkersProgressList,
            getCustomerForCompliance,
        },
        dispatch
    );
};
export default connect(mapStateToProps, mapDispatchToProps)(ComplianceWorkflow);
