import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import InfoIcon from "@mui/icons-material/Info";
import Icon from "@mui/material/Icon";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Navbar from "../../common/Header/Navbar";
import PageHeader from "../../common/PageHeader";
import SaveDocumentDatabase from "./SaveDocumentDatabase";
import ContextProvider from "../../components/Context/ContextProvider";
import ExpandAllButton from "../../components/ExpandAllButton";
import ButtonComponent from "../../components/ButtonComponent";
import TextFieldComponent from "../../components/TextField/TextFieldComponent";
import DropDownComponent from "../../components/DropDown/DropDownComponent";
import RadioButtonGroup from "../../components/RadioButtonGroup/RadioButtonGroup";
import { ROUTES } from "../../router/RouteList";
import {
    yesNoOptions,
    uploadStatus1,
    uploadStatus2,
    uploadStatus3,
    responseOptions,
} from "../../utils/CommonData";
import {
    getCategoryDropdownDatabase,
    storeDocumentDatabase,
    updateDocumentDatabase,
    setSelectedDetailsDataBase,
    getDocumentDatabaseById,
} from "../../store/Action/DocumentDatabaseAction";
import "../../../assets/styles/Container/SignedDocument.scss";
import AccordionCustom from "../../components/AccordionCustom";
function DocumentDatabase(props) {
    const {
        getCategoryDropdownDatabase,
        categoryDropdownDatabase,
        storeDocumentDatabase,
        questionCode,
        setSelectedDetailsDataBase,
        selectedDocumentDatabaseData,
        updateDocumentDatabase,
        getDocumentDatabaseById,
        loggedInUser,
        userLoggedIn,
    } = props;
    const mode = selectedDocumentDatabaseData?.mode || null;
    const [state, setstate] = useState({
        id: mode ? selectedDocumentDatabaseData?.id : "",
        category_id: mode ? selectedDocumentDatabaseData?.category_id : "",
        document_name: mode ? selectedDocumentDatabaseData?.document_name : "",
        content_of_document_request: mode
            ? selectedDocumentDatabaseData?.content_of_document_request
            : "",
        other_document_category: mode
            ? selectedDocumentDatabaseData?.other_document_category
            : "",
        summary: mode ? selectedDocumentDatabaseData?.summary : "",
        examples: mode ? selectedDocumentDatabaseData?.examples : "",
        cannot_fill_request_reason: mode
            ? selectedDocumentDatabaseData?.cannot_fill_request_reason
            : "",
        response_options: mode
            ? selectedDocumentDatabaseData?.response_options
            : "",
        upload_status: mode ? selectedDocumentDatabaseData?.upload_status : [],
        do_not_collect_it: mode
            ? selectedDocumentDatabaseData?.do_not_collect_it
            : "",
        remove_related_questions_from_testing: mode
            ? selectedDocumentDatabaseData?.remove_related_questions_from_testing
            : "",
        apply_scoring_to_optional_documents: mode
            ? selectedDocumentDatabaseData?.apply_scoring_to_optional_documents
            : "",
        formtemplate_document_uploaded_by_admin: mode
            ? selectedDocumentDatabaseData?.formtemplate_document_uploaded_by_admin
            : "",
        repressed: mode ? selectedDocumentDatabaseData?.repressed : "",
        document_score: mode
            ? selectedDocumentDatabaseData?.document_score
            : "",
        question_family_code: mode
            ? selectedDocumentDatabaseData?.question_family_code
            : "",
        status: mode ? selectedDocumentDatabaseData?.status : "",
        status_reason: mode ? selectedDocumentDatabaseData?.status_reason : "",
        assign_to: mode ? selectedDocumentDatabaseData?.assign_to : "",
        comments: mode ? selectedDocumentDatabaseData?.comments : "",
        approved_by: mode ? selectedDocumentDatabaseData?.approved_by : "",
        expand: true,
        hide: mode
            ? selectedDocumentDatabaseData?.category_id === 5
                ? true
                : false
            : false,
        openSaveModel: false,
        sortedCategorys: null,
        defaultAssign_to: [],
    });
    const {
        hide,
        category_id,
        document_name,
        content_of_document_request,
        other_document_category,
        summary,
        examples,
        cannot_fill_request_reason,
        response_options,
        upload_status,
        do_not_collect_it,
        remove_related_questions_from_testing,
        apply_scoring_to_optional_documents,
        formtemplate_document_uploaded_by_admin,
        repressed,
        document_score,
        question_family_code,
        openSaveModel,
        expand,
        sortedCategorys,
    } = state;
    const pathName = window.location.search.split("?");
    const pageType = window.location.pathname.split("-");

    useEffect(() => {
        const pageTypeName = pageType[0].split("/");
        if (
            !selectedDocumentDatabaseData &&
            (pageTypeName[1] === "edit" || pageTypeName[1] === "view")
        ) {
            if (pathName[1] !== null) {
                getDocumentDatabaseById(parseInt(pathName[1]), pageTypeName[1]);
            }
        }
    }, [selectedDocumentDatabaseData]);
    if (selectedDocumentDatabaseData && mode && document_name === "") {
        setstate((prevState) => ({
            ...prevState,
            ...selectedDocumentDatabaseData,
        }));
    }
    useEffect(() => {
        const callApi = async () => {
            const res = await getCategoryDropdownDatabase();
            let sortData = res.payload.sort((a, b) =>
                a.name > b.name ? 1 : -1
            );
            const findIndex = sortData.findIndex((obj) => obj.name === "Other");
            const object = sortData[findIndex];
            const sortCategory = sortData.filter((obj) => obj.name !== "Other");
            sortCategory.push(object);
            setstate((prevState) => ({
                ...prevState,
                sortedCategorys: sortCategory,
            }));
        };
        callApi();
    }, [categoryDropdownDatabase?.length]);

    const navigate = useNavigate();
    const handleClickCancel = async () => {
        await setSelectedDetailsDataBase(null);
        navigate(ROUTES.DOCUMENT_DATABASE);
    };
    const handelResponseChange = (event) => {
        const optionList = [
            { label: uploadStatus1, value: 1 },
            {
                label: `${uploadStatus2} ${cannot_fill_request_reason}`,
                value: 2,
            },
            {
                label: uploadStatus3,
                value: 3,
            },
        ];
        let list;
        switch (event?.target?.value) {
            case "Required":
                list = optionList;
                break;
            case "Hard Required":
                list = [
                    { label: uploadStatus1, value: 1 },
                    { label: uploadStatus2, value: 2 },
                ];
                break;
            case "Optional + Scored":
                list = optionList;
                break;
            case "Optional":
                list = [
                    { label: uploadStatus1, value: 1 },
                    { label: uploadStatus3, value: 2 },
                ];
                break;
            default:
                list = optionList;
        }
        setstate((prevState) => ({
            ...prevState,
            response_options: event?.target?.value,
            upload_status: list,
        }));
    };
    const handelDocumentCategory = (event) => {
        setstate((prevState) => ({
            ...prevState,
            category_id: event?.target?.value,
        }));
        if (event?.target?.value === 5)
            setstate((prevState) => ({ ...prevState, hide: true }));
        else setstate((prevState) => ({ ...prevState, hide: false }));
    };
    async function handleDocumentDatabaseSubmit() {
        if (state.status.length == 0) {
            toast.error("Decision is required");
        } else {
            if (state.status_reason.length == 0) {
                toast.error("Reason is required");
            } else {
                if (state.comments.length == 0) {
                    toast.error("Comments is required");
                } else {
                    if (state.assign_to.length == 0) {
                        toast.error("Assign/Reassign is required");
                    } else {
                        delete state.openSaveModel;
                        delete state.hide;
                        delete state.expand;
                        if (mode !== "edit")
                            await storeDocumentDatabase({
                                ...state,
                                approved_by: loggedInUser,
                            });
                        else {
                            await updateDocumentDatabase({
                                ...state,
                                approved_by: loggedInUser,
                            });
                        }
                        setSelectedDetailsDataBase(null);
                        navigate(ROUTES.DOCUMENT_DATABASE);
                    }
                }
            }
        }
    }
    function handleOpen() {
        if (!document_name) {
            toast.error("Please Enter Document Name.");
        } else {
            if (!category_id) {
                toast.error("Please Enter Document Category.");
            } else {
                setstate((prevState) => ({
                    ...prevState,
                    openSaveModel: true,
                }));
            }
        }
    }
    return (
        <>
            <Navbar />
            {openSaveModel ? (
                <SaveDocumentDatabase
                    open={openSaveModel}
                    setOpen={setstate}
                    handleDocumentDatabaseSubmit={handleDocumentDatabaseSubmit}
                    state={state}
                    setstate={setstate}
                />
            ) : null}
            <Box sx={{ px: { md: 7, xs: 1 } }}>
                <PageHeader
                    title={
                        userLoggedIn?.language_data?.documentdatabase
                            ?.document_database || "Document Database"
                    }
                />
                <ExpandAllButton expand={expand} setState={setstate} />
                <ContextProvider.Provider value={expand}>
                    <Grid container>
                        <Grid item md={12} xs={12} mt={2}>
                            <AccordionCustom
                                title={
                                    userLoggedIn?.language_data
                                        ?.documentdatabase
                                        ?.document_database_info ||
                                    "Document Database Information"
                                }
                            >
                                <Grid container spacing={2}>
                                    <Grid item md={6} xs={12}>
                                        <TextFieldComponent
                                            id="addDocDataBaseDocumentName"
                                            required={true}
                                            label={
                                                userLoggedIn?.language_data
                                                    ?.documentdatabase
                                                    ?.document_name ||
                                                "Document Name"
                                            }
                                            name={"document_name"}
                                            onChange={(event) =>
                                                setstate((prevState) => ({
                                                    ...prevState,
                                                    document_name:
                                                        event?.target?.value,
                                                }))
                                            }
                                            disabled={
                                                mode !== "view" ? false : true
                                            }
                                            value={document_name || ""}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <DropDownComponent
                                            id="addDocDataBaseDocumentCategory"
                                            label={
                                                userLoggedIn?.language_data
                                                    ?.documentdatabase
                                                    ?.document_category ||
                                                "Document Category"
                                            }
                                            name={"subscription"}
                                            fullWidth={true}
                                            onChange={handelDocumentCategory}
                                            value={category_id || ""}
                                            disabled={
                                                mode !== "view" ? false : true
                                            }
                                            required={true}
                                        >
                                            {sortedCategorys?.map((option) => (
                                                <MenuItem
                                                    key={option?.id}
                                                    value={option?.id}
                                                >
                                                    {option?.name}
                                                </MenuItem>
                                            ))}
                                        </DropDownComponent>
                                    </Grid>
                                </Grid>
                                <Grid container mt={3} spacing={2}>
                                    <Grid item md={6} xs={12}>
                                        <TextFieldComponent
                                            required={true}
                                            label={
                                                userLoggedIn?.language_data
                                                    ?.documentdatabase
                                                    ?.list_success ||
                                                "Content of Document Request"
                                            }
                                            id="addDocDataBaseContentOfDocumentReq"
                                            multiline={true}
                                            rows={3}
                                            name={"content_of_document_request"}
                                            sx={{ paddingTop: "20px" }}
                                            onChange={(event) =>
                                                setstate((prevState) => ({
                                                    ...prevState,
                                                    content_of_document_request:
                                                        event?.target?.value,
                                                }))
                                            }
                                            value={
                                                content_of_document_request ||
                                                ""
                                            }
                                            disabled={
                                                mode !== "view" ? false : true
                                            }
                                        />
                                    </Grid>
                                    {hide ? (
                                        <Grid item md={5.9} xs={12}>
                                            <TextFieldComponent
                                                required={true}
                                                label={
                                                    "Other Document Category"
                                                }
                                                id="addDocDataBaseOtherDocCategory"
                                                name={"other_document_category"}
                                                onChange={(event) =>
                                                    setstate((prevState) => ({
                                                        ...prevState,
                                                        other_document_category:
                                                            event?.target
                                                                ?.value,
                                                    }))
                                                }
                                                value={
                                                    other_document_category ||
                                                    ""
                                                }
                                                disabled={
                                                    mode !== "view"
                                                        ? false
                                                        : true
                                                }
                                            />
                                        </Grid>
                                    ) : null}
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item md={12} xs={12} mt={2}>
                                        <Typography className="selectText">
                                            {userLoggedIn?.language_data
                                                ?.documentdatabase
                                                ?.add_success || "Summary"}
                                            <Icon className="icon">
                                                <InfoIcon />
                                            </Icon>
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item md={12} xs={12} mt={3}>
                                        <TextFieldComponent
                                            required={true}
                                            id="addDocDataBaseSummary"
                                            label={
                                                userLoggedIn?.language_data
                                                    ?.documentdatabase
                                                    ?.add_success || "Summary"
                                            }
                                            multiline={true}
                                            name={
                                                userLoggedIn?.language_data
                                                    ?.documentdatabase
                                                    ?.add_success || "Summary"
                                            }
                                            sx={{ paddingTop: "20px" }}
                                            rows={3}
                                            onChange={(event) =>
                                                setstate((prevState) => ({
                                                    ...prevState,
                                                    summary:
                                                        event?.target?.value,
                                                }))
                                            }
                                            value={summary || ""}
                                            disabled={
                                                mode !== "view" ? false : true
                                            }
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item md={12} xs={12} mt={2}>
                                        <Typography className="selectText">
                                            {userLoggedIn?.language_data
                                                ?.documentdatabase
                                                ?.update_success || "Examples"}
                                            <Icon className="icon">
                                                <InfoIcon />
                                            </Icon>
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item md={12} xs={12} mt={3}>
                                        <TextFieldComponent
                                            required={true}
                                            id="addDocDataBaseExamples"
                                            sx={{ paddingTop: "20px" }}
                                            label={"Example"}
                                            multiline={true}
                                            rows={3}
                                            name={"examples"}
                                            onChange={(event) =>
                                                setstate((prevState) => ({
                                                    ...prevState,
                                                    examples:
                                                        event.target.value,
                                                }))
                                            }
                                            value={examples || ""}
                                            disabled={
                                                mode !== "view" ? false : true
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </AccordionCustom>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} mt={2}>
                        <Grid item lg={12} md={12} xs={12}>
                            <AccordionCustom title={"Cannot Fulfill Request"}>
                                <Grid container>
                                    <Grid item md={12} xs={12}>
                                        <Typography className="selectText">
                                            Content of "Cannot Fulfill Request
                                            because…"
                                            <Icon className="icon">
                                                <InfoIcon />
                                            </Icon>
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container mt={3}>
                                    <Grid item md={12} xs={12} mt={1}>
                                        <TextFieldComponent
                                            required={true}
                                            id="addDocDataBaseCannotFulfillRequest"
                                            sx={{ paddingTop: "20px" }}
                                            label={
                                                userLoggedIn?.language_data
                                                    ?.documentdatabase
                                                    ?.cannot_fulfill_request_reason ||
                                                "Cannot Fulfill Request Reason"
                                            }
                                            multiline={true}
                                            rows={3}
                                            name={"cannot_fill_request_reason"}
                                            onChange={(event) =>
                                                setstate((prevState) => ({
                                                    ...prevState,
                                                    cannot_fill_request_reason:
                                                        event?.target?.value,
                                                }))
                                            }
                                            value={
                                                cannot_fill_request_reason || ""
                                            }
                                            disabled={
                                                mode !== "view" ? false : true
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </AccordionCustom>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} mt={2}>
                        <Grid item lg={12}>
                            <AccordionCustom
                                title={
                                    userLoggedIn?.language_data
                                        ?.documentdatabase?.response_options ||
                                    "Response Options"
                                }
                            >
                                <Grid container spacing={2}>
                                    <Grid item md={6} xs={12}>
                                        <DropDownComponent
                                            required={true}
                                            id="addDocDataBaseResponseOption"
                                            label={
                                                userLoggedIn?.language_data
                                                    ?.documentdatabase
                                                    ?.response_options ||
                                                "Response Options"
                                            }
                                            name={"response"}
                                            fullWidth={true}
                                            onChange={handelResponseChange}
                                            value={response_options || ""}
                                            disabled={
                                                mode !== "view" ? false : true
                                            }
                                        >
                                            {responseOptions?.map((option) => (
                                                <MenuItem
                                                    key={option}
                                                    value={option}
                                                >
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </DropDownComponent>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item lg={12} md={12} sm={12} mt={3}>
                                        <Typography className="font_18_600">
                                            {userLoggedIn?.language_data
                                                ?.documentdatabase
                                                ?.upload_status_options_are ||
                                                "Upload Status Options are:"}
                                            <Icon className="icon">
                                                <InfoIcon />
                                            </Icon>
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={7} md={7} sm={7} mt={2}>
                                        {upload_status?.length ? (
                                            upload_status?.map((option) => (
                                                <Typography>
                                                    {option?.label}
                                                </Typography>
                                            ))
                                        ) : (
                                            <Typography>
                                                {userLoggedIn?.language_data
                                                    ?.documentdatabase
                                                    ?.no_option_available ||
                                                    "No option available..."}
                                            </Typography>
                                        )}
                                    </Grid>
                                </Grid>
                            </AccordionCustom>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} mt={2}>
                        <Grid item lg={12} md={12} xs={12}>
                            <AccordionCustom
                                title={
                                    userLoggedIn?.language_data
                                        ?.documentdatabase
                                        ?.document_database_settings ||
                                    "Document Database Settings"
                                }
                                id="addDocDataBaseAccordion1"
                            >
                                <Grid container spacing={2}>
                                    <Grid item lg={6} md={6} sm={6}>
                                        <Grid container>
                                            <Grid item lg={12} md={12} sm={12}>
                                                <Typography className="font_18_600">
                                                    {userLoggedIn?.language_data
                                                        ?.documentdatabase
                                                        ?.remove_document_from ||
                                                        "Remove Document from Collection Process, i.e., Do Not Collect It"}
                                                    <Icon className="icon">
                                                        <InfoIcon />
                                                    </Icon>
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={12} md={12} sm={12}>
                                                <RadioButtonGroup
                                                    required={true}
                                                    id="addDocDataBaseRadioButton1"
                                                    options={yesNoOptions}
                                                    row={true}
                                                    defaultValue={false}
                                                    name="do_not_collect_it"
                                                    onChange={(event) =>
                                                        setstate(
                                                            (prevState) => ({
                                                                ...prevState,
                                                                do_not_collect_it:
                                                                    event
                                                                        ?.target
                                                                        ?.value ===
                                                                    "yes"
                                                                        ? true
                                                                        : false,
                                                            })
                                                        )
                                                    }
                                                    value={
                                                        do_not_collect_it
                                                            ? "yes"
                                                            : "no"
                                                    }
                                                    disabled={
                                                        mode !== "view"
                                                            ? false
                                                            : true
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6}>
                                        <Grid container>
                                            <Grid item lg={12} md={12} sm={12}>
                                                <Typography className="font_18_600">
                                                    {userLoggedIn?.language_data
                                                        ?.documentdatabase
                                                        ?.remove_ralatedques ||
                                                        "Remove Related Questions from Testing*"}
                                                    <Icon className="icon">
                                                        <InfoIcon />
                                                    </Icon>
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={12} md={12} sm={12}>
                                                <RadioButtonGroup
                                                    required={true}
                                                    id="addDocDataBaseRadioButton2"
                                                    options={yesNoOptions}
                                                    row={true}
                                                    defaultValue={false}
                                                    name="remove_related_questions_from_testing"
                                                    onChange={(event) =>
                                                        setstate(
                                                            (prevState) => ({
                                                                ...prevState,
                                                                remove_related_questions_from_testing:
                                                                    event
                                                                        ?.target
                                                                        ?.value ===
                                                                    "yes"
                                                                        ? true
                                                                        : false,
                                                            })
                                                        )
                                                    }
                                                    value={
                                                        remove_related_questions_from_testing
                                                            ? "yes"
                                                            : "no"
                                                    }
                                                    disabled={
                                                        mode !== "view"
                                                            ? false
                                                            : true
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} mt={2}>
                                    <Grid item lg={6} md={6} sm={6}>
                                        <Grid container>
                                            <Grid item lg={12} md={12} sm={12}>
                                                <Typography className="font_18_600">
                                                    {userLoggedIn?.language_data
                                                        ?.documentdatabase
                                                        ?.apply_scoring_doc ||
                                                        "Apply Scoring to Optional Documents? (Note)"}
                                                    <Icon className="icon">
                                                        <InfoIcon />
                                                    </Icon>
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={12} md={12} sm={12}>
                                                <RadioButtonGroup
                                                    id="addDocDataBaseRadioButton3"
                                                    options={yesNoOptions}
                                                    row={true}
                                                    required={true}
                                                    name="apply_scoring_to_optional_documents"
                                                    onChange={(event) =>
                                                        setstate(
                                                            (prevState) => ({
                                                                ...prevState,
                                                                apply_scoring_to_optional_documents:
                                                                    event
                                                                        ?.target
                                                                        ?.value ===
                                                                    "yes"
                                                                        ? true
                                                                        : false,
                                                            })
                                                        )
                                                    }
                                                    value={
                                                        apply_scoring_to_optional_documents
                                                            ? "yes"
                                                            : "no"
                                                    }
                                                    disabled={
                                                        mode !== "view"
                                                            ? false
                                                            : true
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6}>
                                        <Grid container>
                                            <Grid item lg={12} md={12} sm={12}>
                                                <Typography className="font_18_600">
                                                    {userLoggedIn?.language_data
                                                        ?.documentdatabase
                                                        ?.form_document_by_admin ||
                                                        "Form/Template Document Uploaded by Admin into the Documents Database?"}
                                                    <Icon className="icon">
                                                        <InfoIcon />
                                                    </Icon>
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={12} md={12} sm={12}>
                                                <RadioButtonGroup
                                                    required={true}
                                                    id="addDocDataBaseRadioButton4"
                                                    options={yesNoOptions}
                                                    row={true}
                                                    defaultValue={true}
                                                    name="formtemplate_document_uploaded_by_admin"
                                                    onChange={(event) =>
                                                        setstate(
                                                            (prevState) => ({
                                                                ...prevState,
                                                                formtemplate_document_uploaded_by_admin:
                                                                    event
                                                                        ?.target
                                                                        ?.value ===
                                                                    "yes"
                                                                        ? true
                                                                        : false,
                                                            })
                                                        )
                                                    }
                                                    value={
                                                        formtemplate_document_uploaded_by_admin
                                                            ? "no"
                                                            : "yes"
                                                    }
                                                    disabled={
                                                        mode !== "view"
                                                            ? false
                                                            : true
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} mt={2}>
                                    <Grid item lg={6} md={6} sm={6}>
                                        <Grid container>
                                            <Grid item lg={12} md={12} sm={12}>
                                                <Typography className="font_18_600">
                                                    {userLoggedIn?.language_data
                                                        ?.documentdatabase
                                                        ?.document_score ||
                                                        "Document Score"}
                                                    <Icon className="icon">
                                                        <InfoIcon />
                                                    </Icon>
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                mt={1.5}
                                            >
                                                <Grid container>
                                                    <Grid item md={2}>
                                                        <Typography className="documentText">
                                                            {userLoggedIn
                                                                ?.language_data
                                                                ?.documentdatabase
                                                                ?.document_score ||
                                                                "Document Score"}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item md={1.5} ml={3}>
                                                        <TextField
                                                            id="addDocDataBaseDocumentScore"
                                                            className="field"
                                                            name={
                                                                "document_score"
                                                            }
                                                            label={"Score"}
                                                            required={true}
                                                            onChange={(event) =>
                                                                setstate(
                                                                    (
                                                                        prevState
                                                                    ) => ({
                                                                        ...prevState,
                                                                        document_score:
                                                                            event
                                                                                ?.target
                                                                                ?.value,
                                                                    })
                                                                )
                                                            }
                                                            value={
                                                                document_score ||
                                                                ""
                                                            }
                                                            disabled={
                                                                mode !== "view"
                                                                    ? false
                                                                    : true
                                                            }
                                                            type={"Number"}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6}>
                                        <Grid container>
                                            <Grid item lg={12} md={12} sm={12}>
                                                <Typography className="font_18_600">
                                                    {userLoggedIn?.language_data
                                                        ?.documentdatabase
                                                        ?.question_family_code ||
                                                        "Question Family Code"}
                                                    <Icon className="icon">
                                                        <InfoIcon />
                                                    </Icon>
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                mt={2}
                                            >
                                                <DropDownComponent
                                                    required={true}
                                                    id="addDocDataBaseQuestionFamilyCode"
                                                    label={
                                                        userLoggedIn
                                                            ?.language_data
                                                            ?.documentdatabase
                                                            ?.question_family_code ||
                                                        "Question Family Code"
                                                    }
                                                    name={
                                                        "question_family_code"
                                                    }
                                                    fullWidth={true}
                                                    onChange={(event) =>
                                                        setstate(
                                                            (prevState) => ({
                                                                ...prevState,
                                                                question_family_code:
                                                                    event
                                                                        ?.target
                                                                        ?.value,
                                                            })
                                                        )
                                                    }
                                                    value={
                                                        question_family_code ||
                                                        ""
                                                    }
                                                    disabled={
                                                        mode !== "view"
                                                            ? false
                                                            : true
                                                    }
                                                >
                                                    {questionCode?.map(
                                                        (option) => (
                                                            <MenuItem
                                                                key={
                                                                    option?.code
                                                                }
                                                                value={
                                                                    option?.code
                                                                }
                                                            >
                                                                {option?.code}
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </DropDownComponent>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item lg={12} md={12} sm={12}>
                                        <Typography className="font_18_600">
                                            {userLoggedIn?.language_data
                                                ?.documentdatabase?.repressed ||
                                                "Repressed"}
                                            <Icon className="icon">
                                                <InfoIcon />
                                            </Icon>
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12}>
                                        <RadioButtonGroup
                                            id="addDocDataBaseRadioButton5"
                                            options={yesNoOptions}
                                            row={true}
                                            required={true}
                                            name={
                                                userLoggedIn?.language_data
                                                    ?.documentdatabase
                                                    ?.repressed || "Repressed"
                                            }
                                            onChange={(event) =>
                                                setstate((prevState) => ({
                                                    ...prevState,
                                                    repressed:
                                                        event?.target?.value ===
                                                        "yes"
                                                            ? true
                                                            : false,
                                                }))
                                            }
                                            value={repressed ? "yes" : "no"}
                                            disabled={
                                                mode !== "view" ? false : true
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </AccordionCustom>
                        </Grid>
                    </Grid>
                </ContextProvider.Provider>
                <Grid container my={1} spacing={2}>
                    <Grid item lg={12} md={12} sm={12} textAlign="end">
                        <ButtonComponent
                            id="addDocDataBaseCancelButton"
                            title="Cancel"
                            sx={{
                                mr: 2,
                            }}
                            variant="outlined"
                            onClick={handleClickCancel}
                        />
                        {mode === "view" ? null : (
                            <ButtonComponent
                                id="addDocDataBaseSubmitButton"
                                title={mode === "edit" ? "Update" : "Save"}
                                onClick={handleOpen}
                            />
                        )}
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,
        categoryDropdownDatabase: state?.docDatabase?.categoryDropdownDatabase,
        questionCode: state?.auth?.commonDropdown?.question_family_codes,
        selectedDocumentDatabaseData:
            state?.docDatabase?.selectedDocumentDatabaseData,
        loggedInUser: state?.auth?.userLoggedIn?.user?.id,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getCategoryDropdownDatabase,
            storeDocumentDatabase,
            setSelectedDetailsDataBase,
            updateDocumentDatabase,
            getDocumentDatabaseById,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentDatabase);
