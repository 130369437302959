import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Grid from "@mui/material/Grid";
import { useLocation, useNavigate } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DropDownComponent from "../../../../../components/DropDown/DropDownComponent";
import ButtonComponent from "../../../../../components/ButtonComponent";
import NoInfoAvailable from "../../../../../components/NoInfoAvailable";
import RoleSettings from "./DefaultTestTables/RoleSettings";
import {
    saveLiteTestOption,
    getLiteTestList,
    editLiteTestOption,
} from "../../../../../store/Action/LiteTestSettingsAction";
import TextFieldComponent from "../../../../../components/TextField/TextFieldComponent";
import { Typography, listItemSecondaryActionClasses } from "@mui/material";
import TitleDescriptionComponent from "./TitleDescription";

const LiteTestOption = (props) => {
    const location = useLocation();
    const [hideState, setHideState] = useState(true);

    const {
        saveLiteTestOption,
        getLiteTestList,
        editLiteTestOption,
        guid,
        list,
        stateMainAgreement,
        setStateMainAgreement,
        testLawsApproch,
        stateList,
        userLoggedIn,
        customer,
    } = props;
    const [state, setState] = useState({
        createType: false,
        isEditId: null,
        isEdit: false,
        isView: false,
        isCreate: true,
        passing_score: 60,
        no_of_questions: 30,
        max_time: 12,
        setestedTest: null,
        role_setting: [],
        testApproch: {
            id: "",
            type: "",
            option: "",
            rules: [],
            summary: "",
            content: "",
            admin_burden: "",
            risk: "",
            recommended_option: "",
            show_states: false,
            selectdObject: [],
        },
        applyRules: null,
        stateId: null,
    });
    useEffect(() => {
        getLiteTestList(guid);
    }, []);
    useEffect(() => {
        if (list) {
            const approchFilterData = testLawsApproch.find(
                (item) => item?.id === list?.test_law_id
            );
            setState((prevState) => ({
                ...prevState,
                passing_score: list?.passing_score,
                no_of_questions: list?.total_questions,
                role_setting: list?.role_setting,
                isEditId: list?.id,
                isView: true,
                isCreate: false,
                testApproch: approchFilterData,
                stateId: list?.state_id,
                applyRules: list?.apply_rules,
            }));
        }
    }, [list]);
    const handleCreateType = () => {
        setState({
            ...state,
            isCreate: false,
            isEdit: true,
            isView: true,
        });
    };
    const handleCancel = () => {
        if (list) {
            setState({
                ...state,
                isCreate: false,
                isEdit: false,
                isView: true,
            });
        } else {
            setState({
                ...state,
                isCreate: true,
                isEdit: false,
                isView: false,
            });
        }
    };
    const handleTableDataState = (key, data) => {
        if (key === "role_settings") {
            setState((prevState) => ({
                ...prevState,
                role_setting: data,
            }));
        }
    };

    const handleCreateTest = async () => {
        setStateMainAgreement((prevState) => ({
            ...prevState,
            isLoading: true,
        }));
        const data = {
            passing_score: state?.passing_score,
            total_questions: state?.no_of_questions,
            guid: guid,
            role_setting: state?.role_setting,
            testLawId: state?.testApproch?.id,
            stateId: state?.stateId,
            applyRules: state?.applyRules,
            max_time: state?.max_time,
        };
        await saveLiteTestOption(data);
        await getLiteTestList(guid);
        setState((prevState) => ({
            ...prevState,
            createType: !state.createType,
        }));
        setStateMainAgreement((prevState) => ({
            ...prevState,
            isLoading: false,
        }));
    };

    const handleEditTest = async () => {
        setStateMainAgreement((prevState) => ({
            ...prevState,
            isLoading: true,
        }));
        const data = {
            passing_score: state?.passing_score,
            total_questions: state?.no_of_questions,
            guid: guid,
            id: state?.isEditId,
            role_setting: state.role_setting,
            testLawId: state?.testApproch?.id,
            stateId: state?.stateId,
            applyRules: state?.applyRules,
            max_time: state?.max_time,
        };
        await editLiteTestOption(data);
        setState((prevState) => ({
            ...prevState,
            createType: !state.createType,
        }));
        setStateMainAgreement((prevState) => ({
            ...prevState,
            isLoading: false,
        }));
    };

    const handleEdit = async (row) => {
        setState((prevState) => ({
            ...prevState,
            createType: !state.createType,
            passing_score: row.passing_score,
            test_id: row.test_id,
            no_of_questions: row.total_questions,
            isEditId: row.id,
        }));
    };

    const handleEditType = () => {
        setState((prevState) => ({
            ...prevState,
            isView: true,
            isEdit: true,
        }));
    };
    const approchChangeHandler = (e) => {
        let state = null;
        const approchFilterData = testLawsApproch.find(
            (item) => item.id === e.target.value
        );

        if (
            approchFilterData.option === "Hiring Party-Centric Approach (PPB)"
        ) {
            setHideState(false);
            state = location?.state?.selectedCustomer?.address?.state;
        } else if (approchFilterData.option === "Contractor-Centric Approach") {
            setHideState(true);
            if (
                location?.state?.selectedCustomer?.company_info
                    ?.principle_place_of_business
            ) {
                state =
                    location?.state?.selectedCustomer?.company_info
                        ?.principle_place_of_business;
            } else {
                state = location?.state?.selectedCustomer?.address?.state;
            }
        } else {
            setHideState(false);
        }
        setState((prevState) => ({
            ...prevState,
            testApproch: approchFilterData,
            stateId: state,
        }));
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item md={12} lg={12} textAlign="end">
                    {state.isCreate && (
                        <ButtonComponent
                            title={"Create Test"}
                            startIcon={<AddCircleOutlineIcon />}
                            onClick={handleCreateType}
                        />
                    )}
                    {state.isView && (
                        <ButtonComponent
                            title={state.isEdit ? "Cancel" : "Edit Test"}
                            startIcon={
                                state.isEdit ? null : <EditOutlinedIcon />
                            }
                            onClick={
                                state.isEdit ? handleCancel : handleEditType
                            }
                        />
                    )}
                </Grid>
                {state.isView ? (
                    <>
                        <Grid item xs={12}>
                            <Typography>Laws Applicable to Testing</Typography>
                        </Grid>

                        <Grid item md={4} lg={4}>
                            <DropDownComponent
                                label="Approach Selection"
                                fullWidth={true}
                                required={true}
                                onChange={approchChangeHandler}
                                value={state?.testApproch?.id}
                                disabled={state.isEdit ? false : true}
                            >
                                {testLawsApproch?.map((obj) => (
                                    <MenuItem
                                        value={obj.id}
                                        className="subheader"
                                    >
                                        {obj?.option}
                                    </MenuItem>
                                ))}
                            </DropDownComponent>
                        </Grid>
                        <Grid item md={8} lg={8}>
                            {state?.testApproch?.id && (
                                <>
                                    <Grid item lg={12} md={12}>
                                        <TitleDescriptionComponent
                                            title={"Summary"}
                                            description={
                                                state.testApproch.summary
                                            }
                                        />
                                    </Grid>
                                    <Grid item lg={12} md={12} mt={1}>
                                        <TitleDescriptionComponent
                                            title={"Description"}
                                            description={
                                                state.testApproch.content
                                            }
                                        />
                                    </Grid>
                                    <Grid container spacing={1} mt={1}>
                                        <Grid item lg={4} md={4} xs={12}>
                                            <TitleDescriptionComponent
                                                title={"Administrative Burden"}
                                                description={
                                                    state.testApproch
                                                        .admin_burden
                                                }
                                            />
                                        </Grid>
                                        <Grid item lg={4} md={4} xs={12}>
                                            <TitleDescriptionComponent
                                                title={"Risk Management"}
                                                description={
                                                    state.testApproch.risk
                                                }
                                            />
                                        </Grid>
                                        <Grid item lg={4} md={4} xs={12}>
                                            <TitleDescriptionComponent
                                                title={
                                                    "Highest Recommended Options"
                                                }
                                                description={
                                                    state.testApproch
                                                        .recommended_option
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                        {hideState == false ? (
                            <Grid item md={4} lg={4}>
                                <DropDownComponent
                                    label={
                                        userLoggedIn?.language_data?.customer
                                            ?.state || "State"
                                    }
                                    fullWidth={true}
                                    required={true}
                                    value={state?.stateId}
                                    onChange={(event) => {
                                        setState((prevState) => ({
                                            ...prevState,
                                            stateId: event.target.value,
                                        }));
                                    }}
                                    disabled={state.isEdit ? false : true}
                                >
                                    {stateList.map((state) => (
                                        <MenuItem
                                            value={state.id}
                                            key={state.id}
                                        >
                                            {state.label}
                                        </MenuItem>
                                    ))}
                                </DropDownComponent>
                            </Grid>
                        ) : (
                            <Grid
                                item
                                md={4}
                                lg={4}
                                style={{ display: "none" }}
                            >
                                <DropDownComponent
                                    label={
                                        userLoggedIn?.language_data?.customer
                                            ?.state || "State"
                                    }
                                    fullWidth={true}
                                    required={true}
                                    value={state?.stateId}
                                    onChange={(event) => {
                                        setState((prevState) => ({
                                            ...prevState,
                                            stateId: event.target.value,
                                        }));
                                    }}
                                    disabled={state.isEdit ? false : true}
                                >
                                    {stateList.map((state) => (
                                        <MenuItem
                                            value={state.id}
                                            key={state.id}
                                        >
                                            {state.label}
                                        </MenuItem>
                                    ))}
                                </DropDownComponent>
                            </Grid>
                        )}
                        <Grid item md={4} lg={4}>
                            <DropDownComponent
                                label="Apply Rules"
                                fullWidth={true}
                                required={true}
                                value={state?.applyRules}
                                onChange={(event) => {
                                    setState((prevState) => ({
                                        ...prevState,
                                        applyRules: event.target.value,
                                    }));
                                }}
                                disabled={state.isEdit ? false : true}
                            >
                                {state?.testApproch?.rules.map((obj) => (
                                    <MenuItem value={obj} className="subheader">
                                        {obj}
                                    </MenuItem>
                                ))}
                            </DropDownComponent>
                        </Grid>
                        <Grid item md={4} lg={4}>
                            <TextFieldComponent
                                label="Max Timer"
                                id="timer"
                                fullWidth={true}
                                required={true}
                                value={state?.max_time}
                                onChange={(event) => {
                                    setState((prevState) => ({
                                        ...prevState,
                                        max_time: event.target.value,
                                    }));
                                }}
                                disabled={state.isEdit ? false : true}
                            />
                        </Grid>
                        <Grid item md={6} lg={6}>
                            <TextFieldComponent
                                label="Min. Passing Score (Percentage)"
                                id="minPassingScore"
                                fullWidth={true}
                                required={true}
                                value={state?.passing_score}
                                onChange={(event) => {
                                    setState((prevState) => ({
                                        ...prevState,
                                        passing_score: event.target.value,
                                    }));
                                }}
                                disabled={state.isEdit ? false : true}
                            />
                        </Grid>
                        <Grid item md={6} lg={6}>
                            <DropDownComponent
                                label="No. Of Questions"
                                fullWidth={true}
                                required={true}
                                value={state?.no_of_questions}
                                onChange={(event) => {
                                    setState((prevState) => ({
                                        ...prevState,
                                        no_of_questions: event.target.value,
                                    }));
                                }}
                                disabled={state.isEdit ? false : true}
                            >
                                {[30, 40, 50].map((obj) => (
                                    <MenuItem value={obj} className="subheader">
                                        {obj}
                                    </MenuItem>
                                ))}
                            </DropDownComponent>
                        </Grid>
                        <Grid
                            item
                            lg={12}
                            md={12}
                            marginY={2}
                            className="table-box-shadow"
                        >
                            <RoleSettings
                                tableData={state.role_setting}
                                setStateMainAgreement={setStateMainAgreement}
                                handleTableDataState={handleTableDataState}
                                readOnly={
                                    !state.isView || state.isEdit ? false : true
                                }
                                name="role_settings"
                                customer={customer}
                            />
                        </Grid>
                        {!state.isView || state.isEdit ? (
                            <>
                                <Grid
                                    container
                                    display={"flex"}
                                    justifyContent={"flex-end"}
                                    gap={2}
                                    mt={2}
                                >
                                    <ButtonComponent
                                        title={
                                            state.isEditId ? "Update" : "Create"
                                        }
                                        onClick={
                                            state.isEditId
                                                ? handleEditTest
                                                : handleCreateTest
                                        }
                                    />
                                </Grid>
                            </>
                        ) : null}
                    </>
                ) : (
                    <NoInfoAvailable
                        className="noCustomerInfo"
                        noInfoTypo="noInfoTypeQuestion"
                    />
                )}
            </Grid>
        </>
    );
};
const mapStateToProps = (state) => {
    return {
        list: state?.liteTestReducer?.liteTestList,
        roles: state?.auth?.commonDropdown?.roles,
        testLawsApproch: state?.auth?.commonDropdown?.test_laws_approch,
        stateList: state.states.list,
        userLoggedIn: state.auth?.userLoggedIn,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            saveLiteTestOption,
            getLiteTestList,
            editLiteTestOption,
        },
        dispatch
    );
};
export default connect(mapStateToProps, mapDispatchToProps)(LiteTestOption);
