import React from "react";
import Button from "@mui/material/Button";

function ButtonComponent(props) {
    const {
        title,
        variant,
        onClick,
        disabled,
        sx,
        endIcon,
        href,
        startIcon,
        type,
        className,
        ariaLabel,
        id,
    } = props;

    function fetchClassName() {
        if (className) return className;
        else if (variant === "outlined") return "button_outline";
        else if (variant === "text") return "";
        else return "button_contained";
    }

    return (
        <Button
            id={id}
            variant={variant}
            onClick={onClick}
            disabled={disabled}
            sx={sx}
            style={{left: 0}}
            className={fetchClassName()}
            endIcon={endIcon}
            href={href}
            startIcon={startIcon}
            type={type ? type : null}
            aria-label={ariaLabel}
        >
            {title}
        </Button>
    );
}

export default ButtonComponent;
