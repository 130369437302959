import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";
import Icon from "@mui/material/Icon";
import Box from "@mui/material/Box";
import Section from "./Section";
import TestLawSettings from "./TestLawSettings";
import WorkFlowSettings from "./WorkFlowSettings";
import Footer from "../../../../../common/Footer/Footer";
import Navbar from "../../../../../common/Header/Navbar";
import AgreementSettings from "./AgreementSettings";
import TestScopeSettings from "./TestScopeSettings";
import ModulesPermissions from "./ModulesPermissions";
import Tooltip from "../../../../../components/ToolTip/TooltipComponent";
import DefaultComplianceSettings from "./DefaultComplianceSettings";
import ExpandAllButton from "../../../../../components/ExpandAllButton";
import ContextProvider from "../../../../../components/Context/ContextProvider";
import performStatesListAction from "../../../../../store/Action/StatesAction";
import CustomerOfficePageHeader from "../../../../../common/CustomerOfficePageHeader";
import performAgreementSettingAction from "../../../../../store/Action/AgreementSettingAction";
import { getTestScopeDetails } from "../../../../../store/Action/TestScopeSettingAction";
import { rcpTaskListAction } from "../../../../../store/Action/RcpTasksAction";
import { getCommentsAction } from "../../../../../store/Action/CommentsAction";
import { getUserList } from "../../../../../store/Action/UserAction";
import { ROUTES } from "../../../../../router/RouteList";
import { getUserDefaultComplianceSetting } from "../../../../../store/Action/DefaultComplianceSettingAction";
import {
    getSelfConfigrationList,
    getSelfConfigrationCreatedList,
} from "../../../../../store/Action/SelfConfigurationAction";
import { getDynamicTestDetailsList } from "../../../../../store/Action/DynamicTestAction";
import LoadingComponent from "../../../../../components/Loading/LoadingComponent";
import DialogComponent from "../../../../../components/DialogsBox/DialogComponent";
import { getTestLawDetails } from "../../../../../store/Action/TestLawSettingAction";
import { deleteLiteTestRow } from "../../../../../store/Action/LiteTestSettingsAction";
import "../../../../../../assets/styles/Container/MainAgreementSetttings.scss";
import ButtonComponent from "../../../../../components/ButtonComponent";
import LiteTestOption from "./LiteTestOption";
import CommonDeleteDialog from "../../../../../components/DialogsBox/CommonDeleteDialog";
import SelfConfigurationTest from "./SelfConfigurationTest";
import DynamicTest from "./DynamicTest";
import Buttongroup from "../../../../../components/ButtonGroup";
import { Translate } from "react-auto-translate";
import SubscriptionDialog from "../../../../../components/SubscriptionDialog";

function MainAgreementSettings(props) {

    const location = useLocation();
    const [open, setOpen] = React.useState(false);
    const { selectedCustomer, service } = location.state;
    console.log(selectedCustomer, 'demo')

    const {
        performStatesListAction,
        performAgreementSettingAction,
        rcpTaskListAction,
        getUserList,
        getCommentsAction,
        getUserDefaultComplianceSetting,
        defaultComplianceSelectData,
        getTestLawDetails,
        testLawSelecteDetails,
        getTestScopeDetails,
        defaultSettingOption,
        deleteLiteTestRow,
        getSelfConfigrationList,
        getSelfConfigrationCreatedList,
        getDynamicTestDetailsList,
    } = props;
    const navigate = useNavigate();
    const [setting, setSetting] = useState({
        expand: false,
    });


    const btnButton = [
        {
            id: 1,
            name: <Translate>Essentials Test Mode</Translate>,
            value: 4,
            requiredSubscription: false
        },
        {
            id: 2,
            name: <Translate>Dynamic Test Mode</Translate>,
            value: 1,
            requiredSubscription: true
        },
        {
            id: 3,
            name: <Translate>Self-Configuration Test Mode</Translate>,
            value: 2,
            requiredSubscription: true
        },
    ];

    const [state, setState] = useState({
        isLoading: false,
        isOpenNoInfoDialog: false,
        defaultComplianceSettingDetails: {
            defaultOption: 4,
            defautIDOption: 1,
            takeComplianceQues: true,
        },
        testModeSetting: {
            defaultOption: 1
        },
        testLawDetails: [],
        deleteTestData: {
            id: null,
            open: false,
            conatin: "",
        },
        questionsData: {
            allSelect: false,
            allSelectCode: false,
            selectJuridication: false,
            selectGroupingCategory: false,
            selectHybridCategory: false,
            formData: {
                pre_question: 0,
                jurisdiction: [],
                response: null,
                role_settings: null,
                category_ids: [],
                hybrid_category_setting: null,
                category_name: [],
            },
        },
        role_setting: null,
        category_settings: [],
    });
    const {
        isOpenNoInfoDialog,
        defaultComplianceSettingDetails,
        isLoading,
        deleteTestData,
    } = state;
    useEffect(() => {
        if (!location?.state) {
            navigate(ROUTES.COMPLIANCE_WORKFLOW);
        } else {
            apiCall();
        }
        if (testLawSelecteDetails) {
            setState((prevState) => ({
                ...prevState,
                testLawDetails: testLawSelecteDetails,
            }));
        }
    }, [testLawSelecteDetails?.id]);
    useEffect(() => {
        if (defaultComplianceSelectData) {
            setState((prevState) => ({
                ...prevState,
                defaultComplianceSettingDetails: {
                    defaultOption: defaultComplianceSelectData?.option,
                    defautIDOption:
                        defaultComplianceSelectData?.default_compliance_id,
                    takeComplianceQues:
                        defaultComplianceSelectData?.take_compliance_ques,
                },
            }));
        }
    }, [
        defaultComplianceSelectData?.option,
        defaultComplianceSelectData?.default_compliance_id,
        defaultComplianceSelectData?.take_compliance_ques,
    ]);
    useEffect(() => {
        getDynamicTestDetailsList(selectedCustomer?.guid);
        getSelfConfigrationCreatedList(selectedCustomer?.guid);
    }, [defaultComplianceSettingDetails.defaultOption]);

    const apiCall = async () => {
        setState((prevState) => ({ ...prevState, isLoading: true }));
        await performStatesListAction();
        await performAgreementSettingAction();
        await getTestScopeDetails(selectedCustomer?.guid);
        await getUserList(selectedCustomer?.id);
        await rcpTaskListAction(selectedCustomer?.guid, service?.id);
        await getCommentsAction(selectedCustomer?.guid);
        await getUserDefaultComplianceSetting(selectedCustomer?.guid);
        await getTestLawDetails(selectedCustomer?.guid);
        await getSelfConfigrationList(selectedCustomer?.guid);
        setState((prevState) => ({ ...prevState, isLoading: false }));
    };
    const closeNoInfoDialog = () => {
        setState((prevState) => ({ ...prevState, isOpenNoInfoDialog: false }));
    };
    const fecthOptionValue = () => {
        const optionTitle = defaultSettingOption?.find(
            (obj) => obj.id === defaultComplianceSettingDetails?.defautIDOption
        );
        return optionTitle?.option || "";
    };
    const closeDeleteDialog = () => {
        setState((prevState) => ({
            ...prevState,
            deleteTestData: {
                ...deleteTestData,
                open: false,
            },
        }));
    };
    const handleDeleteLiteTest = async () => {
        setState((prevState) => ({ ...prevState, isLoading: true }));
        let data = { id: deleteTestData.id, guid: selectedCustomer?.guid };
        await deleteLiteTestRow(data);
        setState((prevState) => ({
            ...prevState,
            deleteTestData: {
                ...deleteTestData,
                open: false,
            },
            isLoading: false,
        }));
    };

    return (
        <>
            <Navbar />
            {isLoading ? (
                <LoadingComponent sx={{ height: "600px" }} />
            ) : (
                <Box sx={{ px: { md: 7, xs: 1 } }} className="headerBox">
                    <CustomerOfficePageHeader
                        users={selectedCustomer}
                        service={service}
                    />
                    <ExpandAllButton
                        setState={setSetting}
                        expand={setting.expand}
                    />
                    <SubscriptionDialog
                        open={open}
                        setOpen={setOpen}
                    />
                    <Buttongroup
                        state={state}
                        btnButton={btnButton}
                        sx={{
                            clear: "both",
                            display: "inline-block",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                        }}
                        open={open}
                        setOpen={setOpen}
                        onClick={(event) => {
                            const value = parseInt(event.target.value);
                            if (value === 1 || value === 2) {
                                setOpen(true);
                            } else {
                                setState((prevState) => ({
                                    ...prevState,
                                    defaultComplianceSettingDetails: {
                                        ...state.defaultComplianceSettingDetails,
                                        defaultOption: parseInt(event.target.value),
                                    },
                                })
                                )
                            }
                        }
                        }
                    />
                    <DialogComponent
                        title={" "}
                        open={isOpenNoInfoDialog}
                        handleClose={closeNoInfoDialog}
                        content={
                            <Translate>{`There are no questions available for ${fecthOptionValue()}.  Please update the MQS database.`}</Translate>
                        }
                        contentClass="noQuestionContain"
                        className="noQuestionDialog"
                        action={
                            <ButtonComponent
                                title={<Translate>Cancel</Translate>}
                                onClick={() => closeNoInfoDialog()}
                            />
                        }
                    />
                    <CommonDeleteDialog
                        title={<Translate>Delete Email</Translate>}
                        content={deleteTestData?.conatin}
                        open={deleteTestData?.open}
                        cancelButtonTitle={<Translate>Cancel</Translate>}
                        submitButtonTitle={<Translate>Delete</Translate>}
                        cancelButtonAction={closeDeleteDialog}
                        submitButtonAction={handleDeleteLiteTest}
                    />
                    <Box sx={{ mb: 8 }}>
                        <ContextProvider.Provider value={setting.expand}>
                            {/* <Section
                                title="Workflow Settings"
                                guid={selectedCustomer?.guid}
                                showComment={true}
                            >
                                <WorkFlowSettings
                                    guid={selectedCustomer?.guid}
                                    selectedCustomer={selectedCustomer}
                                    service={service}
                                />
                            </Section>

                            {/* <Section
                                title="Agreement Settings"
                                guid={selectedCustomer?.guid}
                                showComment={true}
                            >
                                <AgreementSettings />
                            </Section>
                            <Section
                                title="Test Law Settings"
                                guid={selectedCustomer?.guid}
                                showComment={true}
                                showTooltip={true}
                                tooltipTitle={
                                    "Laws Tested in Each Independent Contractor Compliance Test"
                                }
                                tooltipCildren={
                                    <Icon className="customInfoIcon">
                                        <InfoIcon />
                                    </Icon>
                                }
                            >
                                <Box>
                                    <TestLawSettings
                                        setStateMainAgreement={setState}
                                        stateMainAgreement={state}
                                        guid={selectedCustomer?.guid}
                                    />
                                </Box>
                            </Section>
                            <Section
                                title="Test Scope Settings"
                                guid={selectedCustomer?.guid}
                                showComment={true}
                                showTooltip={true}
                                tooltipTitle={"Test Scope Settings"}
                                tooltipCildren={
                                    <Icon className="customInfoIcon">
                                        <InfoIcon />
                                    </Icon>
                                }
                            >
                                <Box>
                                    <TestScopeSettings />
                                </Box>
                            </Section> */}
                            <Section
                                title="Modules Permissions"
                                guid={selectedCustomer?.guid}
                                showComment={true}
                            >
                                <ModulesPermissions />
                            </Section>

                            {/* <Section
                                title="Default Compliance Settings"
                                guid={selectedCustomer?.guid}
                                showComment={true}
                            >
                                <DefaultComplianceSettings
                                    conductorType={
                                        selectedCustomer?.client_type
                                    }
                                    guid={selectedCustomer?.guid}
                                    state={state}
                                    setState={setState}
                                />
                            </Section> */}

                            <Section
                                title="Essentials Test Mode Settings"
                                guid={selectedCustomer?.guid}
                                showComment={true}
                            >
                                <DefaultComplianceSettings
                                    conductorType={
                                        selectedCustomer?.client_type
                                    }
                                    guid={selectedCustomer?.guid}
                                    state={state}
                                    setState={setState}
                                />
                            </Section>
                            {defaultComplianceSettingDetails.defaultOption ==
                                1 ? (
                                <Section
                                    title="Dynamic Test Option"
                                    guid={selectedCustomer?.guid}
                                    showComment={true}
                                >
                                    <DynamicTest
                                        guid={selectedCustomer?.guid}
                                        customer={selectedCustomer}
                                        setStateMainAgreement={setState}
                                        stateMainAgreement={state}
                                        testName={"dynamic"}
                                    />
                                </Section>
                            ) : defaultComplianceSettingDetails?.defaultOption ==
                                2 ? (
                                <Section
                                    title="Self-Configuration Test Option"
                                    guid={selectedCustomer?.guid}
                                    showComment={true}
                                >
                                    <SelfConfigurationTest
                                        guid={selectedCustomer?.guid}
                                        customer={selectedCustomer}
                                        setStateMainAgreement={setState}
                                        stateMainAgreement={state}
                                        testName={"selfConfiguration"}
                                    />
                                </Section>
                            ) : defaultComplianceSettingDetails.defaultOption ==
                                4 ? (
                                <>
                                    <Section
                                        title="Essentials Test"
                                        guid={selectedCustomer?.guid}
                                        showComment={true}
                                        expand={true}
                                    >
                                        <LiteTestOption
                                            guid={selectedCustomer?.guid}
                                            setStateMainAgreement={setState}
                                            stateMainAgreement={state}
                                        />
                                    </Section>
                                </>
                            ) : (
                                <>
                                    <Section
                                        title="Essentials Test"
                                        guid={selectedCustomer?.guid}
                                        showComment={true}
                                    >
                                        <LiteTestOption
                                            guid={selectedCustomer?.guid}
                                            setStateMainAgreement={setState}
                                            stateMainAgreement={state}
                                            customer={selectedCustomer}
                                        />
                                    </Section>
                                    <Section
                                        title="Dynamic Test Option"
                                        guid={selectedCustomer?.guid}
                                        showComment={true}
                                    >
                                        <DynamicTest
                                            guid={selectedCustomer?.guid}
                                            customer={selectedCustomer}
                                            setStateMainAgreement={setState}
                                            stateMainAgreement={state}
                                            testName={"dynamic"}
                                        />
                                    </Section>
                                    <Section
                                        title="Self-Configuration Test Option"
                                        guid={selectedCustomer?.guid}
                                        showComment={true}
                                    >
                                        <SelfConfigurationTest
                                            guid={selectedCustomer?.guid}
                                            customer={selectedCustomer}
                                            setStateMainAgreement={setState}
                                            stateMainAgreement={state}
                                            testName={"selfConfiguration"}
                                        />
                                    </Section>
                                </>
                            )}
                        </ContextProvider.Provider>
                    </Box>
                </Box>
            )}
            <Footer />
        </>
    );
}
const mapStateToProps = (state) => {
    return {
        defaultComplianceSelectData:
            state.defaultComplianceSettings.defaultComplianceSelectData,
        testLawSelecteDetails: state.testLawSettings.modules,
        defaultSettingOption: state.defaultComplianceSettings.settings,
    };
};
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            performStatesListAction,
            performAgreementSettingAction,
            rcpTaskListAction,
            getUserList,
            getCommentsAction,
            getUserDefaultComplianceSetting,
            getTestLawDetails,
            getTestScopeDetails,
            deleteLiteTestRow,
            getSelfConfigrationList,
            getSelfConfigrationCreatedList,
            getDynamicTestDetailsList,
        },
        dispatch
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MainAgreementSettings);
