import * as React from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Checkbox, FormControlLabel } from "@mui/material";
import { useState } from "react";
import { connect } from "react-redux";
import TableComponent from "../../../../../components/Table/TableComponent";

const CustomizationTable = (props) => {
    const { isChecked, setIsChecked, importTemplatesList, userLoggedIn } =
        props;
    const [state, setState] = useState({
        TemplateList: importTemplatesList || [],
        checkedAll: false,
    });
    const { checkedAll, TemplateList } = state;
    const handleSelectAll = (e) => {
        setState((prevstate) => ({
            ...prevstate,
            checkedAll: !checkedAll,
        }));
        setIsChecked(TemplateList?.map((li) => li.id));
        if (checkedAll) {
            setIsChecked([]);
        }
    };
    const handleClickOnCheckbox = (e) => {
        const { value, checked } = e.target;
        if (!checked) {
            //remove
            setIsChecked(isChecked.filter((item) => item !== Number(value)));
        } else {
            //add
            setIsChecked([...isChecked, Number(value)]);
        }
    };
    return (
        TemplateList?.length !== 0 && (
            <TableComponent
                tableClass={"tableHeadRow"}
                sx={{ minWidth: 700 }}
                extraHeaderLeft={
                    <>
                        <TableCell align="center" className="tableHeadCell">
                            <FormControlLabel
                                value="end"
                                control={
                                    <Checkbox
                                        name="selectDeleteAll"
                                        onChange={handleSelectAll}
                                        checked={checkedAll}
                                    />
                                }
                            />
                        </TableCell>
                        <TableCell align="left" className="tableHeadCell">
                            {userLoggedIn?.language_data?.emailtemplates
                                ?.view_user || "Template Name"}
                        </TableCell>
                        <TableCell align="left" className="tableHeadCell">
                            Created At
                        </TableCell>
                    </>
                }
            >
                {TemplateList?.map((row, index) => (
                    <TableRow
                        className={
                            index % 2 === 0
                                ? "tableBodyRow"
                                : "tableBodyRowColor"
                        }
                        tabIndex={-1}
                        key={row?.id}
                        component="th"
                        scope="row"
                    >
                        <TableCell align="center">
                            <FormControlLabel
                                value={row.id}
                                control={
                                    <Checkbox
                                        checked={isChecked.includes(row.id)}
                                        onChange={handleClickOnCheckbox}
                                        value={row.id}
                                    />
                                }
                                labelPlacement="Select"
                                style={{ color: "#3B3B3B" }}
                            />
                        </TableCell>
                        <TableCell align="left">{row.template_name}</TableCell>
                        <TableCell align="left">{row.created_at}</TableCell>
                    </TableRow>
                ))}
            </TableComponent>
        )
    );
};
const mapStateToProps = (state) => {
    return {
        userLoggedIn: state?.auth?.userLoggedIn,
        importTemplatesList: state.contractor.importTemplatesList,
    };
};

export default connect(mapStateToProps, null)(CustomizationTable);
