import React, { useRef, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ButtonComponent from "./../../components/ButtonComponent";
import SunEditorComponent from "../../components/SunEditorComponent";
import DialogComponent from "../../components/DialogsBox/DialogComponent";
import DropDownComponent from "../../components/DropDown/DropDownComponent";
import TextFieldComponent from "../../components/TextField/TextFieldComponent";
import { alphabetsList } from "./GlossaryUtils";
import { connect } from "react-redux";
function AddDefinitionDialog(props) {
    const {userLoggedIn, open, handleClose, type, handleSubmit, setState, state, termList,  } =
        props;
    const { alphabet, term, definition } = state;
    const [showError, setShowError] = React.useState(false);
    const [termError, setTermError] = useState(false);

    const handleValidate = () => {
        const flag = termList?.findIndex(
            (x) => x.term.toLowerCase() === term.toLowerCase()
        );
        if (flag === -1) {
            setShowError(false);
            setTermError(false);
            handleSubmit();
        } else if (state.flag) {
            setShowError(false);
            setTermError(false);
            handleSubmit();
        } else setShowError(true);

        setState({ ...state, currentAlphabet: alphabet });
    };
    const handleChange = (content) => {
        setState((prevState) => ({ ...prevState, definition: content }));
    };

    const handleChangeTerm = (event) => {
        if (event.target.value.length > 200) {
            setTermError(true);
        } else {
            setState((prevState) => ({
                ...prevState,
                term: event.target.value,
            }));
        }
    };
    const editor = useRef();

    const getSunEditorInstance = (sunEditor) => {
        editor.current = sunEditor;
    };

    return (
        <DialogComponent
            open={open || false}
            handleClose={handleClose}
            title={`${type === "edit" ? "Edit" : "Add"} Definition`}
            className="addDefinitionDialog"
            content={
                <>
                    <Grid container gap={2} mt={2}>
                        <Grid item xs={12}>
                            <DropDownComponent
                                id="addDefinationDialogAlphabet"
                                label={userLoggedIn?.language_data?.glossary?.select_alphabet || "Select Alphabet"}
                                name={"alphabet"}
                                fullWidth={true}
                                onChange={(event) =>
                                    setState((prevState) => ({
                                        ...prevState,
                                        alphabet: event.target.value,
                                    }))
                                }
                                value={alphabet}
                                required={true}
                            >
                                {alphabetsList.map((option, index) => (
                                    <MenuItem
                                        key={option}
                                        value={option}
                                        id={index}
                                    >
                                        {option}
                                    </MenuItem>
                                ))}
                            </DropDownComponent>
                        </Grid>
                        <Grid item xs={12}>
                            <TextFieldComponent
                                id="addDefinitionDialogDefinedTerm"
                                label={"Defined Term"}
                                onChange={(event) => handleChangeTerm(event)}
                                value={term}
                                required={true}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <SunEditorComponent
                                setDefaultStyle="font-size: 14px;"
                                width="100%"
                                height="220px"
                                getSunEditorInstance={getSunEditorInstance}
                                onChange={handleChange}
                                defaultValue={definition}
                                setContents={definition}
                            />
                        </Grid>
                    </Grid>
                    {termError ? (
                        <Typography color="red">
                            Term Character limit only 200
                        </Typography>
                    ) : (
                        ""
                    )}
                    {showError ? (
                        <Typography color="red">
                            This Term already exist. Please enter some other
                            term.
                        </Typography>
                    ) : (
                        ""
                    )}
                </>
            }
            action={
                <ButtonComponent
                    sx={{ marginRight: "14px" }}
                    title={type === "add" ? "Add" : "Update"}
                    variant={"contained"}
                    onClick={handleValidate}
                    id="addDefinationDialogValidateButton"
                />
            }
        />
    );
}
const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,
    };
};

export default connect(mapStateToProps, null) (AddDefinitionDialog);
