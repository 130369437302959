import {
    FormControl,
    FormLabel,
    Grid,
    IconButton,
    MenuItem,
    Typography,
    TableCell,
    TableRow,
    Link,
    Box,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import Navbar from "../../../common/Header/Navbar";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import "./TestQuestion.scss";
import ButtonComponent from "../../../components/ButtonComponent";
import DialogComponent from "../../../components/DialogsBox/DialogComponent";
import {
    getConflictResponses,
    updateConflict,
} from "../../../store/Action/MqsAction";
import { bindActionCreators } from "redux";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import DropDownComponent from "../../../components/DropDown/DropDownComponent";
import TableComponent from "../../../components/Table/TableComponent";
import { getAssessmentSummary } from "../../../store/Action/AssessmentSettingAction";
import { ROUTES } from "../../../router/RouteList";
import LoadingComponent from "../../../components/Loading/LoadingComponent";
const ResolveConflictQuestion = (props) => {
    const {
        conflictQuestionsData,
        updateConflict,
        getAssessmentSummary,
        getConflictResponses,
    } = props;
    const navigate = useNavigate();
    const params = useParams();
    const [stateEditConflict, setStateEditConflict] = useState({
        accept: true,
        submitDialogOpen: false,
        isOpenNoInfoDialog: false,
        isLoading: false,
        start: 0,
        updatedResponse: {
            test_id: "",
            response: null,
            question_id: null,
            guid: null,
            test_type: "",
            user_id: null,
            role_id: null,
        },
        selectedValue: null,
        pausedTimer: false,
        showAvailableResponse: { role_id: null, open: false },
        questionContainDialog: {
            open: false,
            content: null,
            title: null,
        },
    });
    const headers = [
        {
            name: "Name",
            headerClass: "tableHeading",
            headerAlign: "center",
        },
        {
            name: "Test Taker",
            headerClass: "tableHeading",
            headerAlign: "center",
        },
        {
            name: "Response",
            headerClass: "tableHeading",
            headerAlign: "center",
        },
        {
            name: "Action",
            headerClass: "tableHeading",
            headerAlign: "center",
        },
    ];
    useEffect(() => {
        if (localStorage || params?.user_id) {
            setDefaultState();
        }
        //eslint-disable-next-line
    }, [params?.user_id]);

    const setDefaultState = () => {
        setStateEditConflict((prevState) => ({
            ...prevState,
            updatedResponse: {
                ...stateEditConflict.updatedResponse,
                test_type: conflictQuestionsData?.test_type,
                guid: localStorage?.getItem("guid"),
                user_id: params?.user_id,
            },
        }));
    };
    useEffect(() => {
        ApiCall();
        //eslint-disable-next-line
    }, [params?.user_id, params?.client_id]);
    const ApiCall = async () => {
        setStateEditConflict((prevState) => ({
            ...prevState,
            isLoading: true,
        }));
        const res = await getAssessmentSummary(
            params?.client_id,
            params?.user_id,
            null,
            localStorage?.getItem("mode")
        );
        let data = {
            guid: localStorage?.getItem("guid"),
            user_id: params?.user_id,
            test_id: res.payload[1]?.id,
            test_type: res.payload[1]?.default_compliance_id,
            role_id: localStorage?.getItem("role_id"),
        };
        await getConflictResponses(data);
        setStateEditConflict((prevState) => ({
            ...prevState,
            isLoading: false,
            updatedResponse: {
                ...stateEditConflict.updatedResponse,
                test_type: res.payload[0]?.default_compliance_id,
                test_id: res.payload[0]?.id,
            },
        }));
    };
    const handleEditResponse = (e, object) => {
        setStateEditConflict((prevState) => ({
            ...prevState,
            showAvailableResponse: {
                role_id: object.role_id,
                open: true,
            },
        }));
    };
    const handleSaveNextClick = async (flag) => {
        setStateEditConflict((prevState) => ({
            ...prevState,
            isLoading: true,
        }));
        await updateConflict(stateEditConflict?.updatedResponse);
        if (flag === "submit") {
            setStateEditConflict((prevState) => ({
                ...prevState,
                start: prevState.start + 1,
                isLoading: false,
                showAvailableResponse: {
                    role_id: null,
                    question_id: null,
                    open: false,
                },
            }));
            navigate(
                `${ROUTES.CUSTOMERS_INFORMATION}/${localStorage?.getItem(
                    "guid"
                )}/${params.user_id}`
            );
        } else {
            setStateEditConflict((prevState) => ({
                ...prevState,
                start: prevState.start + 1,
                isLoading: false,
                showAvailableResponse: {
                    role_id: null,
                    question_id: null,
                    open: false,
                },
            }));
        }
    };
    const handlerResponse = (e, role_Id, ques_id) => {
        setStateEditConflict((prevState) => ({
            ...prevState,
            updatedResponse: {
                ...stateEditConflict.updatedResponse,
                response: e?.target?.value,
                guid: localStorage?.getItem("guid"),
                user_id: params?.user_id,
                role_id: role_Id,
                question_id: ques_id,
            },
        }));
    };
    const handlerQuestionContainDialog = (content, title) => {
        setStateEditConflict((prevState) => ({
            ...prevState,
            questionContainDialog: {
                open: true,
                content: content,
                title: title,
            },
        }));
    };
    return (
        <>
            <Navbar />
            <DialogComponent
                title={" "}
                open={stateEditConflict.isOpenNoInfoDialog}
                handleClose={() => {
                    setStateEditConflict((prevState) => ({
                        ...prevState,
                        isOpenNoInfoDialog: false,
                    }));
                    navigate(-1);
                }}
                content={`There are no questions available.`}
                contentClass="noQuestionContain"
                className="noQuestionDialog"
                action={
                    <ButtonComponent
                        title={"Cancel"}
                        onClick={() => {
                            setStateEditConflict((prevState) => ({
                                ...prevState,
                                isOpenNoInfoDialog: false,
                            }));
                            navigate(-1);
                        }}
                    />
                }
            />
            <DialogComponent
                maxWidth={"md"}
                title={stateEditConflict?.questionContainDialog?.title}
                open={stateEditConflict?.questionContainDialog?.open}
                content={stateEditConflict?.questionContainDialog?.content}
                isShowContentArrenge={true}
                handleClose={() => {
                    setStateEditConflict((prevState) => ({
                        ...prevState,
                        questionContainDialog: {
                            open: false,
                            title: null,
                            content: null,
                        },
                    }));
                }}
                action={
                    <>
                        <ButtonComponent
                            title={"Close"}
                            onClick={() => {
                                setStateEditConflict((prevState) => ({
                                    ...prevState,
                                    questionContainDialog: {
                                        open: false,
                                        title: null,
                                        content: null,
                                    },
                                }));
                            }}
                        />
                    </>
                }
            />
            {stateEditConflict.isLoading ? (
                <LoadingComponent sx={{ height: "600px" }} />
            ) : (
                conflictQuestionsData &&
                conflictQuestionsData?.data?.length && (
                    <>
                        <Grid
                            container
                            justifyContent={"center"}
                            mb={"10px"}
                            className="mainConatin"
                            sx={{
                                minHeight: "90vh",
                                overflowY: "scroll",
                                scrollBehavior: "smooth",
                            }}
                        >
                            <Grid item md={12} lg={12} sm={12} xs={12}>
                                <Grid
                                    container
                                    sx={{
                                        px: { md: 10, xs: 1 },
                                        paddingTop: "80px",
                                        paddingBottom: "10px",
                                    }}
                                >
                                    <Grid item md={12} lg={12}>
                                        <Grid container>
                                            {/* <Grid item md={12} lg={12}>
                                                <Typography
                                                    variant="span"
                                                    className="font_24_600 light-sea-green"
                                                >
                                                    {
                                                        conflictQuestionsData?.test_name
                                                    }
                                                </Typography>
                                                <Icon
                                                    sx={{
                                                        position: "relative",
                                                        left: 20,
                                                        top: 3,
                                                    }}
                                                >
                                                    <InfoIcon />
                                                </Icon>
                                            </Grid> */}
                                            <Grid item md={12} lg={12} my={1.5}>
                                                <Grid container>
                                                    <Grid
                                                        item
                                                        md={9}
                                                        display={"flex"}
                                                        gap={4}
                                                        alignItems={"center"}
                                                    >
                                                        <Typography
                                                            className="font_20_600"
                                                            variant="span"
                                                        >
                                                            {`Question ${
                                                                conflictQuestionsData
                                                                    ?.data[
                                                                    stateEditConflict
                                                                        ?.start
                                                                ]?.ques_number
                                                            } of ${
                                                                conflictQuestionsData?.total_questions
                                                            }`}
                                                        </Typography>
                                                        <Box
                                                            display={"flex"}
                                                            gap={2}
                                                        >
                                                            {conflictQuestionsData
                                                                ?.data[
                                                                stateEditConflict
                                                                    ?.start
                                                            ]?.explanation !==
                                                                ("N/A" ||
                                                                    null) && (
                                                                <Link
                                                                    onClick={() =>
                                                                        handlerQuestionContainDialog(
                                                                            conflictQuestionsData
                                                                                ?.data[
                                                                                stateEditConflict
                                                                                    ?.start
                                                                            ]
                                                                                ?.explanation,
                                                                            "Description"
                                                                        )
                                                                    }
                                                                    sx={{
                                                                        cursor: "pointer",
                                                                    }}
                                                                >
                                                                    Description
                                                                </Link>
                                                            )}
                                                            {conflictQuestionsData
                                                                ?.data[
                                                                stateEditConflict
                                                                    ?.start
                                                            ]?.examples !==
                                                                ("N/A" ||
                                                                    null) && (
                                                                <Link
                                                                    sx={{
                                                                        cursor: "pointer",
                                                                    }}
                                                                    onClick={() =>
                                                                        handlerQuestionContainDialog(
                                                                            conflictQuestionsData
                                                                                ?.data[
                                                                                stateEditConflict
                                                                                    ?.start
                                                                            ]
                                                                                ?.examples,
                                                                            "Example"
                                                                        )
                                                                    }
                                                                >
                                                                    Example
                                                                </Link>
                                                            )}
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    rowSpacing={1}
                                    sx={{
                                        px: { md: 10, xs: 1 },
                                    }}
                                >
                                    <Grid
                                        item
                                        md={12}
                                        lg={12}
                                        sx={{ width: "100%" }}
                                    >
                                        <FormControl sx={{ width: "100%" }}>
                                            <FormLabel
                                                className="font_20_500 text-black"
                                                sx={{ mt: "20px", mb: "30px" }}
                                            >
                                                <span
                                                    className="font_20_600  text-black"
                                                    dangerouslySetInnerHTML={{
                                                        __html: conflictQuestionsData
                                                            ?.data[
                                                            stateEditConflict
                                                                ?.start
                                                        ]?.question,
                                                    }}
                                                />
                                            </FormLabel>

                                            <TableComponent
                                                headerList={headers}
                                            >
                                                <TableRow>
                                                    <TableCell
                                                        align="center"
                                                        className="typo4"
                                                    >
                                                        {
                                                            conflictQuestionsData
                                                                ?.data[
                                                                stateEditConflict
                                                                    ?.start
                                                            ]?.manager_response
                                                                ?.test_taker_name
                                                        }
                                                    </TableCell>
                                                    <TableCell
                                                        align="center"
                                                        className="typo4"
                                                    >
                                                        {
                                                            conflictQuestionsData
                                                                ?.data[
                                                                stateEditConflict
                                                                    ?.start
                                                            ]?.manager_response
                                                                ?.role_name
                                                        }
                                                    </TableCell>
                                                    <TableCell
                                                        align="center"
                                                        className="typo4"
                                                    >
                                                        {stateEditConflict
                                                            .showAvailableResponse
                                                            .role_id ===
                                                            conflictQuestionsData
                                                                ?.data[
                                                                stateEditConflict
                                                                    ?.start
                                                            ]?.manager_response
                                                                ?.role_id &&
                                                        stateEditConflict
                                                            .showAvailableResponse
                                                            .open ? (
                                                            <DropDownComponent
                                                                fullWidth={true}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    handlerResponse(
                                                                        e,
                                                                        conflictQuestionsData
                                                                            ?.data[
                                                                            stateEditConflict
                                                                                ?.start
                                                                        ]
                                                                            ?.manager_response
                                                                            ?.role_id,
                                                                        conflictQuestionsData
                                                                            ?.data[
                                                                            stateEditConflict
                                                                                ?.start
                                                                        ]
                                                                            ?.manager_response
                                                                            ?.ques_id
                                                                    );
                                                                }}
                                                            >
                                                                {conflictQuestionsData
                                                                    ?.data
                                                                    ?.length !==
                                                                    0 &&
                                                                    conflictQuestionsData?.data[
                                                                        stateEditConflict
                                                                            ?.start
                                                                    ]?.available_response?.map(
                                                                        (
                                                                            option,
                                                                            index
                                                                        ) => (
                                                                            <MenuItem
                                                                                key={
                                                                                    index
                                                                                }
                                                                                value={
                                                                                    option.response
                                                                                }
                                                                            >
                                                                                {
                                                                                    option.response
                                                                                }
                                                                            </MenuItem>
                                                                        )
                                                                    )}
                                                            </DropDownComponent>
                                                        ) : (
                                                            conflictQuestionsData
                                                                ?.data[
                                                                stateEditConflict
                                                                    ?.start
                                                            ]?.manager_response
                                                                ?.response
                                                        )}
                                                    </TableCell>
                                                    <TableCell
                                                        align="center"
                                                        className="typo4"
                                                    >
                                                        <IconButton
                                                            onClick={(e) => {
                                                                handleEditResponse(
                                                                    e,
                                                                    conflictQuestionsData
                                                                        ?.data[
                                                                        stateEditConflict
                                                                            ?.start
                                                                    ]
                                                                        ?.manager_response
                                                                );
                                                            }}
                                                        >
                                                            <ModeEditOutlinedIcon fontSize="19px" />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell
                                                        align="center"
                                                        className="typo4"
                                                    >
                                                        {
                                                            conflictQuestionsData
                                                                ?.data[
                                                                stateEditConflict
                                                                    ?.start
                                                            ]
                                                                ?.contactor_response
                                                                .test_taker_name
                                                        }
                                                    </TableCell>
                                                    <TableCell
                                                        align="center"
                                                        className="typo4"
                                                    >
                                                        {
                                                            conflictQuestionsData
                                                                ?.data[
                                                                stateEditConflict
                                                                    ?.start
                                                            ]
                                                                ?.contactor_response
                                                                .role_name
                                                        }
                                                    </TableCell>
                                                    <TableCell
                                                        align="center"
                                                        className="typo4"
                                                    >
                                                        {stateEditConflict
                                                            .showAvailableResponse
                                                            .role_id ===
                                                            conflictQuestionsData
                                                                ?.data[
                                                                stateEditConflict
                                                                    ?.start
                                                            ]
                                                                ?.contactor_response
                                                                ?.role_id &&
                                                        stateEditConflict
                                                            .showAvailableResponse
                                                            .open ? (
                                                            <DropDownComponent
                                                                fullWidth={true}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    handlerResponse(
                                                                        e,
                                                                        conflictQuestionsData
                                                                            ?.data[
                                                                            stateEditConflict
                                                                                ?.start
                                                                        ]
                                                                            ?.contactor_response
                                                                            ?.role_id,
                                                                        conflictQuestionsData
                                                                            ?.data[
                                                                            stateEditConflict
                                                                                ?.start
                                                                        ]
                                                                            ?.contactor_response
                                                                            ?.ques_id
                                                                    );
                                                                }}
                                                            >
                                                                {conflictQuestionsData
                                                                    ?.data
                                                                    ?.length !==
                                                                    0 &&
                                                                    conflictQuestionsData?.data[
                                                                        stateEditConflict
                                                                            ?.start
                                                                    ]?.available_response?.map(
                                                                        (
                                                                            option,
                                                                            index
                                                                        ) => (
                                                                            <MenuItem
                                                                                key={
                                                                                    index
                                                                                }
                                                                                value={
                                                                                    option.response
                                                                                }
                                                                            >
                                                                                {
                                                                                    option.response
                                                                                }
                                                                            </MenuItem>
                                                                        )
                                                                    )}
                                                            </DropDownComponent>
                                                        ) : (
                                                            conflictQuestionsData
                                                                ?.data[
                                                                stateEditConflict
                                                                    ?.start
                                                            ]
                                                                ?.contactor_response
                                                                .response
                                                        )}
                                                    </TableCell>
                                                    <TableCell
                                                        align="center"
                                                        className="typo4"
                                                    >
                                                        <IconButton
                                                            onClick={(e) => {
                                                                handleEditResponse(
                                                                    e,
                                                                    conflictQuestionsData
                                                                        ?.data[
                                                                        stateEditConflict
                                                                            ?.start
                                                                    ]
                                                                        ?.contactor_response
                                                                );
                                                            }}
                                                        >
                                                            <ModeEditOutlinedIcon fontSize="19px" />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            </TableComponent>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            my={2}
                            sx={{
                                px: { md: 10, xs: 1 },
                                position: "absolute",
                                bottom: 0,
                                flexShrink: 0,
                            }}
                        >
                            <Grid item md={12} lg={12} xs={12} textAlign="end">
                                {stateEditConflict.start + 1 ===
                                conflictQuestionsData?.data?.length ? (
                                    <ButtonComponent
                                        title="Save & Submit"
                                        disabled={
                                            !stateEditConflict.accept &&
                                            stateEditConflict.pausedTimer
                                                ? true
                                                : false
                                        }
                                        onClick={() =>
                                            handleSaveNextClick("submit")
                                        }
                                    />
                                ) : (
                                    <ButtonComponent
                                        disabled={
                                            stateEditConflict.pausedTimer
                                                ? true
                                                : false
                                        }
                                        title="Save & Next"
                                        onClick={() =>
                                            handleSaveNextClick("next")
                                        }
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </>
                )
            )}
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        conflictQuestionsData: state?.mqs?.conflictQuestion,
    };
};
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            updateConflict,
            getAssessmentSummary,
            getConflictResponses,
        },
        dispatch
    );
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ResolveConflictQuestion);
