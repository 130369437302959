import React from "react";
import Grid from "@mui/material/Grid";
import ButtonComponent from "../../components/ButtonComponent";
import TextFieldComponent from "../../components/TextField/TextFieldComponent";
import DialogComponent from "../../components/DialogsBox/DialogComponent";
import { connect } from "react-redux";
function AddCategoyDialog(props) {
    const {
        open,
        setState,
        handleCategorySubmit,
        state,
        selectedCategory,
        setSelectedCategory,
        userLoggedIn
    } = props;

    const handleClose = () => {
        setState((prevState) => ({
            ...prevState,
            open: false,
            name: "",
            description: "",
        }));
        setSelectedCategory(null);
    };

    return (
        <DialogComponent
            handleClose={handleClose}
            className="dialogFaqs"
            open={open}
            title={selectedCategory === null ? "Add Category" : userLoggedIn?.language_data?.user?.edit_category_text || "Edit Category"}
            content={
                <Grid container>
                    <Grid item md={12} xs={12} mx={2} mt={1}>
                        <TextFieldComponent
                            id="addCategoryDialogCategoryName"
                            label={ userLoggedIn?.language_data?.faqs?.category_name || "Category Name"}
                            onChange={(event) =>
                                setState((prevState) => ({
                                    ...prevState,
                                    name: event.target.value || '',
                                }))
                            }
                            value={state?.name}
                            inputProps={{ maxLength: 25 }}
                        />
                    </Grid>
                    <Grid item mx={2} md={12} xs={12} mt={3}>
                        <TextFieldComponent
                            sx={{ paddingTop: "20px" }}
                            multiline={true}
                            rows={3}
                            id="addCategoryDialogDescription"
                            label={userLoggedIn?.language_data?.faqs?.short_desc || "Short Description"}
                            onChange={(event) =>
                                setState((prevState) => ({
                                    ...prevState,
                                    description: event.target.value,
                                }))
                            }
                            value={state?.description}
                            inputProps={{ maxLength: 100 }}
                        />
                    </Grid>
                </Grid>
            }
            action={
                <Grid
                    container
                    pr={3.9}
                    pb={1}
                    display={"flex"}
                    justifyContent={"flex-end"}
                >
                    <ButtonComponent
                        id="addCategoryDialogSubmitButton"
                        title={selectedCategory === null ? "Add" : "Update"}
                        onClick={() => handleCategorySubmit()}
                    />
                </Grid>
            }
        />
    );
}

const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,
    };
};


export default connect(mapStateToProps, null) (AddCategoyDialog);
