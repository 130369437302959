import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    getContractsAction,
    addContractAction,
    updateContractsAction,
    deleteContractAction,
    deleteTemplatetAction,
} from "../../../../../store/Action/ContractAction";
import { getSignedDocumentData } from "../../../../../store/Action/SignedDocumentAction";
import WorkFlowTable2 from "../../DocumentTable";
import ImportTemplate from "../../../../../components/DocumentEditor/ImportTemplate";
import SuccessfullModel from "../../../../../components/DialogsBox/SuccessfullModel";
import CommonDeleteDialog from "../../../../../components/DialogsBox/CommonDeleteDialog";
import "../../../../../../assets/styles/Container/RcpCustomerInformation.scss";

function ContractToBeSigned(props) {
    const {
        contractList,
        initilizeStates,
        addContractAction,
        updateContractsAction,
        signedDocuments,
        getSignedDocumentData,
        deleteTemplatetAction,
        customer,
        user,
        guid,
        userLoggedIn
    } = props;

    const [editFormData, setEditFormData] = useState({
        listItem: null,
        openSuccesfullModel: false,
        openAdd: false,
        openImport: false,
        loading: false,
        sequence: 0,
        contract_form: {
            guid: guid,
            contractor_id: user?.selectedUser?.id,
            name: "",
            sequence: 1,
            required: true,
        },
    });
    const [tableList, setTableList] = useState([]);
    const [state, setState] = useState({
        open: false,
        toDelete: null,
        fileName: null,
    });

    const headerList = [
        {
            name: userLoggedIn?.language_data?.documentdatabase?.document_name || "Document Name",
            headerClass: "tableHeading",
            accessibleKey: "name",
            rowClass: "typo4",
            headerAlign: "left",
            rowAlign: "left",
        },
    ];
    useEffect(() => {
        getSignedDocumentData();
        if (contractList.length > 0) {
            setTableList([
                ...contractList.map((contract) => {
                    return { ...contract, name: contract.name };
                }),
            ]);
            setEditFormData({
                ...editFormData,
                contract_form: {
                    ...editFormData?.contract_form,
                    sequence: contractList?.length + 1,
                },
            });
        } else {
            setTableList([]);
        }
    }, [getSignedDocumentData, editFormData?.openImport]);

    // Add Document
    const handleAdd = async () => {
        const res = await addContractAction(editFormData?.contract_form);
        initilizeStates(customer.selectedCustomer.guid, user.selectedUser.id);
        setEditFormData({
            ...editFormData,
            openAdd: false,
            contract_form: { ...editFormData.contract_form, name: "" },
            openSuccesfullModel: true,
            modelContain: "Contract added sucessfully!",
        });
    };
    //update
    async function handleUpdate() {
        const res = await updateContractsAction(guid, tableList);
        initilizeStates(customer.selectedCustomer.guid, user.selectedUser.id);
        setEditFormData({
            ...editFormData,
            openSuccesfullModel: true,
            modelContain: "Contracts updated successfully!",
        });
    }

    const [selectContract, setSelectContracts] = useState({
        isCheckedAll: false,
        isChecked: [],
    });
    const { isCheckedAll, isChecked } = selectContract;

    //Delete Document
    async function handleDelete() {
        await deleteTemplatetAction(editFormData.listItem?.id, { guid: guid });
        initilizeStates(customer.selectedCustomer.guid, user.selectedUser.id);
        setEditFormData({
            ...editFormData,
            listItem: null,
            openSuccesfullModel: true,
            modelContain: "Contract removed sucessfully!",
        });
    }

    const deleteContent = `Are you sure,you want to remove the 
                      (${editFormData?.listItem?.name} Contract) from the Contract?`;

    return (
        <Fragment>
            <SuccessfullModel
                open={editFormData.openSuccesfullModel}
                handleClose={() =>
                    setEditFormData({
                        ...editFormData,
                        openSuccesfullModel: false,
                        modelContain: null,
                    })
                }
                content={editFormData.modelContain}
            />
            <ImportTemplate
                open={editFormData.openAdd}
                onClose={(isOpen) => {
                    setEditFormData({
                        ...editFormData,
                        openAdd: isOpen,
                    });
                }}
                setState={setEditFormData}
                tableData={signedDocuments}
                headers={headerList}
                setSelectContracts={setSelectContracts}
                selectContract={selectContract}
                isChecked={isChecked}
                isCheckedAll={isCheckedAll}
            />
            <CommonDeleteDialog
                open={editFormData.listItem != null ? true : false}
                setOpen={(isOpen) => {}}
                cancelButtonAction={() => {
                    setEditFormData({
                        ...editFormData,
                        listItem: null,
                    });
                }}
                onClick={handleDelete}
                title="Delete Contract"
                content={deleteContent}
            />
            <WorkFlowTable2
                headerList={headerList}
                signedDocuments={signedDocuments}
                templates={signedDocuments}
                tableFor={"signedDocuments"}
                setTableList={(list) => {
                    setTableList([...list]);
                }}
                setListItem={(item) => {
                    setEditFormData({
                        ...editFormData,
                        listItem: { ...item },
                    });
                }}
                handleUpdate={handleUpdate}
                setOpenAdd={(isOpen) => {
                    setEditFormData({
                        ...editFormData,
                        openAdd: isOpen,
                    });
                }}
            />
        </Fragment>
    );
}
const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,
        customer: state.customer,
        user: state.user,
        contractsList: state.contractsList,
        signedDocuments: state?.signedDocs?.selectedDocumentData,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getContractsAction,
            addContractAction,
            updateContractsAction,
            deleteContractAction,
            getSignedDocumentData,
            deleteTemplatetAction,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ContractToBeSigned);
