import React from "react";
import Box from "@mui/material/Box";
import Slide from "@mui/material/Slide";
import ButtonComponent from "../ButtonComponent";
import DialogComponent from "./DialogComponent";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

 function StatusUpdateDialog(props) {
    const {
        open,
        title,
        openBtnTxt,
        handleSubmit,
        handleToClose,
        actionBtnText,
        handleClickToOpen,
        className,
        children
    }=props;

    return (
        <Box>
             <DialogComponent
                className="dialog assignDialog"
                open={open}
                handleClose={handleToClose}
                TransitionComponent={Transition}
                title={title}
                content={children}
                action={
                    <ButtonComponent title={actionBtnText} className="btn buttonCommonClass"
                     onClick={handleSubmit} />
                }
            />
            <ButtonComponent title={openBtnTxt} className={className}
                        onClick={handleClickToOpen} />
        </Box>
    );
}
export default StatusUpdateDialog;