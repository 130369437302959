import React, { Fragment, useState } from "react";
import Grid from "@mui/material/Grid";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import EditableRow from "./EditableRow";
import ButtonComponent from "../../ButtonComponent";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as DeleteIcon } from "../../../../assets/Images/Icons/DeleteIcon.svg";
import { ReactComponent as EditIcon } from "../../../../assets/Images/Icons/EditIcon.svg";
import { toast } from "react-hot-toast";
import TableComponent from "../TableComponent";
import { getUserById } from "../../../store/Action/UserAction";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
const WorkFlowTable = (props) => {
    const [workFlowState, setWorkFlowState] = useState({
        isEdit: null,
        openSuccesfullModel: false,
        modelContain: "",
    });
    const {
        tableList,
        headerList,
        tableClass,
        setTableList,
        extraHeaderLeft,
        extraHeaderRight,
        extraRightColProps,
        extraLeftColProps,
        extraColLeft,
        extraColRight,
        setListItem,
        setOpenAdd,
        handleUpdate,
        max,
        userLoggedIn
    } = props;
    const handleSequenceChange = (id, e) => {
        setTableList([
            ...tableList.map((item) => {
                if (item.id === id) {
                    return { ...item, sequence: parseInt(e.target.value) };
                }
                return item;
            }),
        ]);
    };

    const handleInputChange = (id, e) => {
        setTableList([
            ...tableList.map((item) => {
                if (item.id === id) {
                    return { ...item, name: e.target.value };
                }
                return item;
            }),
        ]);
    };

    const handleRequiredChange = (id, e) => {
        setTableList([
            ...tableList.map((item) => {
                if (item.id === id) {
                    return { ...item, required: 1 };
                }
                return item;
            }),
        ]);
    };

    const handleOptionalChange = (id, e) => {
        setTableList([
            ...tableList.map((item) => {
                if (item.id === id) {
                    return { ...item, required: 0 };
                }
                return item;
            }),
        ]);
    };

    const fixedHeaderList = [
        {
            name: "Sequence",
            headerClass: "tableHeading",
            accessibleKey: "sequence",
            rowClass: "typo4",
            headerAlign: "center",
            rowAlign: "left",
        },
        {
            name: "Required",
            headerClass: "tableHeading",
            accessibleKey: "required",
            rowClass: "typo4",
            headerAlign: "center",
            rowAlign: "left",
        },
        {
            name: "Optional",
            headerClass: "tableHeading",
            accessibleKey: "optional",
            rowClass: "typo4",
            headerAlign: "center",
            rowAlign: "left",
        },
    ];

    const handleAdd = () => {
        setOpenAdd(true);
        setWorkFlowState({
            ...setWorkFlowState,
            isEdit: null,
        });
    };

    const handleSaveClick = () => {
        setTableList([
            ...tableList.sort((a, b) => {
                if (a.sequence > b.sequence) {
                    return 1;
                }
                if (a.sequence < b.sequence) {
                    return -1;
                }
                return 0;
            }),
        ]);
    //     setWorkFlowState({
    //         ...setWorkFlowState,
    //         isEdit: null,
    //     });
     };

    const deleteAction = (id) => {
        const listItem = tableList.find((item) => item.id === id);
        setListItem(listItem);
    };

    const headerFinalList = headerList.concat(fixedHeaderList);

    const handleUpdateClick = () => {
        const sequence = tableList?.map((item) => item?.sequence);
        const duplicateSequence = sequence?.filter((item, index) => {
            return index !== sequence.indexOf(item);
        });
        if (duplicateSequence?.length > 0) {
            toast.error("Duplicate sequence is not allowed.");
        } else {
            handleUpdate();
            setWorkFlowState({
                        ...setWorkFlowState,
                        isEdit: null,
                    });
        }
    };

    return (
        <Fragment>
            <Grid container spacing={2}>
                <Grid item md={12} lg={12} className="table-box-shadow">
                {tableList?.length !== 0 && (
                        <TableComponent
                            headerList={headerFinalList}
                            tableClass={tableClass}
                            extraHeaderRight ={<>
                                <TableCell
                                    align="center"
                                    className="tableHeading"
                                >
                                    {extraHeaderRight}
                                </TableCell> <TableCell
                                        align="center"
                                        className="tableHeading"
                                    >
                                        Action
                                    </TableCell></>}
                        >
                             {tableList?.length > 0
                                    ? tableList.map((obj, index) => {
                                          return (
                                              <TableRow
                                                  key={obj.id}
                                                  className={
                                                      index % 2 !== 0
                                                          ? "tertiaryBackground"
                                                          : ""
                                                  }
                                              >
                                                  <EditableRow
                                                      editFormData={obj}
                                                      max={max}
                                                      handleInputChange={
                                                          handleInputChange
                                                      }
                                                      handleSequenceChange={
                                                          handleSequenceChange
                                                      }
                                                      handleRequiredChange={
                                                          handleRequiredChange
                                                      }
                                                      handleOptionalChange={
                                                          handleOptionalChange
                                                      }
                                                      handleSaveClick={
                                                          handleSaveClick
                                                      }
                                                      isEdit={
                                                          workFlowState.isEdit
                                                      }
                                                      extraColLeft={
                                                          extraColLeft
                                                      }
                                                      extraLeftColProps={
                                                          extraLeftColProps
                                                      }
                                                      extraColRight={
                                                          extraColRight
                                                      }
                                                      extraRightColProps={
                                                          extraRightColProps
                                                      }
                                                  />
                                                  <TableCell
                                                      //   className="typo4"
                                                      align="center"
                                                  >
                                                      <Grid
                                                          container
                                                          direction={"row"}
                                                          spacing={1}
                                                          justifyContent="center"
                                                      >
                                                          <Grid item md={3}>
                                                              <IconButton
                                                                  onClick={() => {
                                                                      setWorkFlowState(
                                                                          {
                                                                              ...workFlowState,
                                                                              isEdit: obj.id,
                                                                          }
                                                                      );
                                                                  }}
                                                              >
                                                                  <EditIcon />
                                                              </IconButton>
                                                          </Grid>
                                                          <Grid item md={3}>
                                                              <IconButton
                                                                  onClick={() =>
                                                                      deleteAction(
                                                                          obj.id
                                                                      )
                                                                  }
                                                              >
                                                                  <DeleteIcon />
                                                              </IconButton>
                                                          </Grid>
                                                      </Grid>
                                                  </TableCell>
                                              </TableRow>
                                          );
                                      })
                                    : null}
                        </TableComponent>
                    )}
                </Grid>
                <Grid item md={12} lg={12} textAlign="end">
                    <ButtonComponent
                        title={userLoggedIn?.language_data?.comman?.add_btn || "Add"}
                        sx={{
                            mr: 2,
                        }}
                        variant="outlined"
                        onClick={handleAdd}
                    />
                    <ButtonComponent
                        title= {userLoggedIn?.language_data?.comman?.edit_btn || "Update"}
                        disabled={tableList.length === 0}
                        onClick={handleUpdateClick}
                    />
                </Grid>
            </Grid>
        </Fragment>
    );
};
const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getUserById,
        },
        dispatch
    );
}
export default connect (mapStateToProps, mapDispatchToProps) (WorkFlowTable);