import React, { useState, useEffect } from "react";
import {
    Routes as Switch,
    Route,
    Navigate,
    useLocation,
} from "react-router-dom";
import { ROUTES } from "./RouteList";
import EmailWorkflow from "../pages/ComplianceWorkflow/ContractorDetails/FooterComponents/Email/EmailWorkflow";
import ManageRoles from "../pages/ManageRoles/ManageRoles";
import Document from "../pages/ComplianceWorkflow/ContractorDetails/FooterComponents/DocumentDatabase/Document";
import ContractTemplates from "../pages/ComplianceWorkflow/ContractorDetails/FooterComponents/SignedDocument/ContractTemplates";
import Glossary from "../pages/Glossary/Glossary";
import FormStudio from "../pages/FormStudio/FormStudio";
import ViewForm from "../pages/FormStudio/ViewForm";
import ComplianceWorkflow from "../../app/pages/ComplianceWorkflow/ComplianceWorkflow";
import Login from "../pages/Login/Login";
import CustomerInformation from "../pages/ComplianceWorkflow/ContractorDetails/AccordionList/CustomerInformation";
import ManageCustomers from "../pages/ManageCustomers/ManageCustomers";
import EmailTemplates from "../pages/EmailTemplates/EmailTemplates";
import AddEmailTemplate from "../pages/EmailTemplates/AddEmailTemplate";
import CreateUser from "../pages/UserModule/CreateUser";
import CreateNewRoles from "../pages/ManageRoles/CreateNewRoles";
import CreateClient from "../pages/ManageCustomers/CreateClient";
import ManageModulePermission from "../pages/ManageRoles/ManageModulePermission";
import MainAgreementSettings from "../pages/ComplianceWorkflow/CustomerSetting/Rcp/AgreementSettings/MainAgreementSettings";
import ManageUser from "../pages/UserModule/ManageUser";
import ForgetPassword from "../pages/Login/ForgetPassword";
import ManageMqs from "../pages/ManageMqs/ManageMqs";
import SelectTest from "../pages/ManageMqs/SelectTest";
import Profile from "../pages/Login/Profile";
import ScoringConfiguration from "../pages/ScoringConfiguration/ScoringConfiguration";
import WhiteLabeling from "../pages/WhiteLabeling/WhiteLabeling";
import SignedDocument from "../pages/SignedDocument/SignedDocument";
import Standard from "../pages/Standard/Standard";
import Documentation from "../pages/Documentation/Documentation";
import Faqs from "../pages/Faqs/Faqs";
import SelfConfiguration from "../pages/SelfConfiguration/SelfConfiguration";
import EditProfile from "../pages/Login/EditProfile";
import Assessments from "../pages/Assessments/ManageAssessments";
import ViewResponse from "../pages/ComplianceWorkflow/ContractorDetails/ViewResponse";
import ConflictResponse from "../pages/ComplianceWorkflow/ContractorDetails/ConflictRespone";
import ResetPassword from "../pages/Login/ResetPassword";
import MainTestQuestion from "../pages/ManageMqs/TestQuestion/MainTestQuestion";
import PageNotFound from "../components/PageNotFound";
import CustomerHierarchy from "../pages/ManageCustomers/CustomerHierarchy";
import UserSettings from "../../app/pages/UserModule/UserSettings";
import ProtectedRoutes from "./ProtectedRoutes";
import PermissionDenied from "./../components/PermissionDenied";
import AddEditQuestion from "../pages/ManageMqs/AddEditQuestion";
import SubmissionForm from "../pages/FormStudio/SubmissionForm";
import AddFaqs from "../pages/Faqs/AddFaqs";
import DocumentDatabase from "../pages/DocumentDatabase/DocumentDatabase";
import AddSignedDocument from "../pages/SignedDocument/AddSignedDocument";
import AddDocumentDatatbase from "../pages/DocumentDatabase/AddDocumentDatatbase";
import PermissionSetting from "../pages/ComplianceWorkflow/CustomerSetting/Rcp/ModulePermission/PermissionSetting";
import AuditDefenseReport from "../pages/ComplianceWorkflow/ContractorDetails/FooterComponents/AuditDefenseReport/AuditDefenseReport";
import Pricing from "../pages/Pricing/Pricing";
import SignUp from "../pages/Login/SignUp";
import Products from "../pages/Products/Products";
import ContactUs from "../pages/ContactUs/ContactUs";
import Training from "../pages/Training/Training";
import TrainingLibrary from "../pages/Training/TrainingLibrary";


import Home from "../pages/Home/Home";
import PaymentMethod from "../pages/Pricing/PaymentMethod";
import AboutPage from "../pages/About/AboutPage";
import ResolveConflictQuestion from "../pages/ManageMqs/TestQuestion/ResolveConflictQuestion";
import DirectLogin from "../pages/Login/DirectLogin";
import { Translator } from "react-auto-translate";
import ArchivedUser from "../pages/UserModule/ArchivedUser";
import ArchivedClients from "../pages/ManageCustomers/ArchivedClients"

const Router = (props) => {
    const token = window.localStorage.getItem("token");
    const location = useLocation();
    const [languageId, setLanguageId] = useState(
        localStorage.getItem("languageCode")
    );
    if (window.location.hostname === "localhost") {
        if (caches) {
            caches.keys().then((names) => {
                for (const name of names) {
                    caches.delete(name);
                }
            });
        }
    }

    useEffect(() => {
        const handleStorageChange = (e) => {
            if (e.key === "languageCode") {
                setLanguageId(e.newValue);
            }
        };
        window.addEventListener("storage", handleStorageChange);
        return () => {
            window.removeEventListener("storage", handleStorageChange);
        };
    }, [token, localStorage.getItem("language")]);

    return token && token !== undefined ? (
        <Translator
            from="en"
            to={localStorage.getItem("languageCode")}
            googleApiKey={process.env.REACT_APP_TRANSLATE_KEY}
        >
            <Switch>
                <Route
                    exact
                    path={ROUTES.MANAGE_ROLES}
                    element={
                        <ProtectedRoutes route={<ManageRoles />} name="roles" />
                    }
                />
                <Route
                    exact
                    path={ROUTES.CREATE_NEW_ROLES}
                    element={
                        <ProtectedRoutes
                            route={<CreateNewRoles />}
                            name="roles"
                            page="create"
                        />
                    }
                />
                <Route
                    path={ROUTES.MANAGE_MODULE_ROLE_PERMISSION}
                    element={
                        <ProtectedRoutes
                            route={<ManageModulePermission />}
                            name="roles"
                        />
                    }
                />
                <Route
                    exact
                    path={`${ROUTES.WORKFLOW_EMAIL}/:customerId/:id`}
                    element={<EmailWorkflow />}
                />
                <Route
                    exact
                    path={`${ROUTES.DOCUMENTS}/:guid/:id`}
                    element={<Document />}
                />
                <Route
                    exact
                    path={`${ROUTES.CONTRACTS}/:guid/:id`}
                    element={<ContractTemplates />}
                />
                <Route
                    exact
                    path={ROUTES.PERMISSION_SETTING}
                    element={<PermissionSetting />}
                />
                <Route
                    exact
                    path={ROUTES.GLOSSARY}
                    element={
                        <ProtectedRoutes route={<Glossary />} name="glossary" />
                    }
                />
                <Route
                    exact
                    path={ROUTES.DASHBOARD}
                    element={
                        <ProtectedRoutes
                            route={<ManageCustomers />}
                            name="customer"
                        />
                    }
                />

                <Route
                    exact
                    path={ROUTES.ARCHIVED_CLIENT}
                    element={
                        <ProtectedRoutes
                            route={<ArchivedClients />}
                            name="customer"
                        />
                    }
                />

                <Route
                    exact
                    path={ROUTES.ARCHIVED_USER}
                    element={
                        <ProtectedRoutes
                            route={<ArchivedUser />}
                            name="customer"
                        />
                    }
                />

                <Route
                    exact
                    path={ROUTES.FORM_BUILDER}
                    element={
                        <ProtectedRoutes
                            route={<FormStudio />}
                            name="form-studio"
                            page="create"
                        />
                    }
                />
                <Route
                    path={ROUTES.EDIT_FORM}
                    element={
                        <ProtectedRoutes
                            route={<FormStudio />}
                            name="form-studio"
                            page="edit"
                        />
                    }
                />
                <Route
                    exact
                    path={ROUTES.VIEW_FORM}
                    element={
                        <ProtectedRoutes
                            route={<ViewForm />}
                            name="form-studio"
                            page="view"
                        />
                    }
                />
                <Route
                    exact
                    path={ROUTES.LOGIN}
                    element={
                        <Navigate
                            to="/"
                            replace
                            state={{ path: location.pathname }}
                        />
                    }
                />

                <Route
                    path={`${ROUTES.CUSTOMERS_INFORMATION}/:id/:id`}
                    element={<CustomerInformation />}
                />
                <Route
                    exact
                    path={ROUTES.DEFAULT_PAGE}
                    element={
                        <ProtectedRoutes
                            route={<ManageCustomers />}
                            name="customer"
                        />
                    }
                />
                <Route
                    exact
                    path={ROUTES.COMPLIANCE_WORKFLOW}
                    element={
                        <ProtectedRoutes
                            route={<ComplianceWorkflow />}
                            name="compliance-workflow"
                        />
                    }
                />
                <Route
                    exact
                    path={`${ROUTES.USER_SETTING}/:id`}
                    element={<UserSettings />}
                />
                <Route
                    path={ROUTES.CREATE_USER}
                    element={
                        <ProtectedRoutes
                            route={<CreateUser />}
                            name="users"
                            page="create"
                        />
                    }
                />
                <Route
                    path={ROUTES.EDIT_USER}
                    element={
                        <ProtectedRoutes
                            route={<CreateUser />}
                            name="users"
                            page="edit"
                        />
                    }
                />
                <Route
                    path={ROUTES.VIEW_USER}
                    element={
                        <ProtectedRoutes
                            route={<CreateUser />}
                            name="users"
                            page="read"
                        />
                    }
                />
                <Route
                    path={ROUTES.CREATE_CLIENT}
                    element={
                        <ProtectedRoutes
                            route={<CreateClient />}
                            name="customer"
                            page="create"
                        />
                    }
                />
                <Route
                    path={ROUTES.EDIT_CLIENT}
                    element={
                        <ProtectedRoutes
                            route={<CreateClient />}
                            name="customer"
                            page="edit"
                        />
                    }
                />
                <Route
                    path={ROUTES.VIEW_CLIENT}
                    element={
                        <ProtectedRoutes
                            route={<CreateClient />}
                            name="customer"
                            page="view"
                        />
                    }
                />
                <Route path={"/*"} element={<Navigate to={ROUTES.LOGIN} />} />
                <Route
                    exact
                    path={ROUTES.EMAIL_TEMPLATES}
                    element={
                        <ProtectedRoutes
                            route={<EmailTemplates />}
                            name="email-templates"
                        />
                    }
                />
                <Route
                    exact
                    path={ROUTES.ADD_EMAIL_TEMPLATE}
                    element={
                        <ProtectedRoutes
                            route={<AddEmailTemplate />}
                            name="email-templates"
                            page="create"
                        />
                    }
                />
                <Route
                    exact
                    path="/edit-email-template/:id"
                    element={
                        <ProtectedRoutes
                            route={<AddEmailTemplate />}
                            name="email-templates"
                            page="edit"
                        />
                    }
                />
                <Route
                    exact
                    path="/view-email-template/:id"
                    element={
                        <ProtectedRoutes
                            route={<AddEmailTemplate />}
                            name="email-templates"
                            page="view"
                        />
                    }
                />
                <Route
                    exact
                    path={`${ROUTES.SETTINGS}/:id`}
                    element={<MainAgreementSettings />}
                />
                <Route
                    exact
                    path={ROUTES.USER}
                    element={
                        <ProtectedRoutes route={<ManageUser />} name="users" />
                    }
                />
                <Route
                    exact
                    path={ROUTES.PROFILE}
                    element={
                        <ProtectedRoutes route={<Profile />} name="profile" />
                    }
                />
                <Route
                    exact
                    path={ROUTES.SCORING_CONFIGURATION}
                    element={<ScoringConfiguration />}
                />
                <Route
                    path={ROUTES.WHITE_LABELING}
                    element={<WhiteLabeling />}
                />
                <Route
                    exact
                    path={ROUTES.SIGNED_DOCUMENT}
                    element={
                        <ProtectedRoutes
                            route={<SignedDocument />}
                            name="signed-document"
                        />
                    }
                />
                <Route
                    exact
                    path={ROUTES.ADD_SIGNED_DOCUMENT}
                    element={
                        <ProtectedRoutes
                            route={<AddSignedDocument />}
                            name="signed-document"
                            page="create"
                        />
                    }
                />
                <Route
                    exact
                    path={ROUTES.VIEW_SIGNED_DOCUMENT}
                    element={
                        <ProtectedRoutes
                            route={<AddSignedDocument />}
                            name="signed-document"
                            page="view"
                        />
                    }
                />
                <Route
                    exact
                    path={ROUTES.EDIT_SIGNED_DOCUMENT}
                    element={
                        <ProtectedRoutes
                            route={<AddSignedDocument />}
                            name="signed-document"
                            page="edit"
                        />
                    }
                />
                <Route
                    exact
                    path={ROUTES.ADD_DOCUMENT_DATABASE}
                    element={
                        <ProtectedRoutes
                            route={<AddDocumentDatatbase />}
                            name="document-database"
                            page="create"
                        />
                    }
                />
                <Route
                    exact
                    path={ROUTES.VIEW_DOCUMENT_DATABASE}
                    element={
                        <ProtectedRoutes
                            route={<AddDocumentDatatbase />}
                            name="document-database"
                            page="view"
                        />
                    }
                />
                <Route
                    exact
                    path={ROUTES.EDIT_DOCUMENT_DATABASE}
                    element={
                        <ProtectedRoutes
                            route={<AddDocumentDatatbase />}
                            name="document-database"
                            page="edit"
                        />
                    }
                />
                <Route
                    exact
                    path={ROUTES.DOCUMENT_DATABASE}
                    element={
                        <ProtectedRoutes
                            route={<DocumentDatabase />}
                            name="document-database"
                        />
                    }
                />
                <Route exact path={ROUTES.STANDARD} element={<Standard />} />
                <Route
                    exact
                    path={ROUTES.TRAINING_VIDEOS}
                    element={<Documentation />}
                />
                <Route
                    exact
                    path={ROUTES.TRAINING}
                    element={
                        <ProtectedRoutes route={<Training />} name="training" />
                    }
                />
                <Route
                    exact
                    path={ROUTES.TRAINING_LIBRARY_MASTER}
                    element={
                        <ProtectedRoutes route={<TrainingLibrary />} name="trainingLibrary" />
                    }
                />
                <Route
                    exact
                    path={ROUTES.FAQS}
                    element={<ProtectedRoutes route={<Faqs />} name="faqs" />}
                />
                <Route
                    exact
                    path={ROUTES.ADD_FAQS}
                    element={
                        <ProtectedRoutes
                            route={<AddFaqs />}
                            name="faqs"
                            page="create"
                        />
                    }
                />
                <Route
                    exact
                    path={ROUTES.EDIT_FAQS}
                    element={
                        <ProtectedRoutes
                            route={<AddFaqs />}
                            name="faqs"
                            page="edit"
                        />
                    }
                />
                <Route
                    exact
                    path={ROUTES.SELF_CONFIGURATION}
                    element={<SelfConfiguration />}
                />
                <Route
                    exact
                    path={ROUTES.EDIT_PROFILE}
                    element={<EditProfile />}
                />
                <Route
                    exact
                    path={ROUTES.View_Response}
                    element={<ViewResponse />}
                />
                <Route
                    exact
                    path={ROUTES.REVIEW_RESPONSE}
                    element={<ViewResponse />}
                />
                <Route
                    exact
                    path={`${ROUTES.Conflict_Response}/:client_id/:user_id/:contractor_id`}
                    element={<ConflictResponse />}
                />
                <Route
                    path={`${ROUTES.Resolve_Conflict}/:client_id/:user_id`}
                    element={<ResolveConflictQuestion />}
                />
                <Route
                    exact
                    path={ROUTES.ASSESSMENTS}
                    element={<Assessments />}
                />
                <Route path={ROUTES.MANAGE_MQS} element={<ManageMqs />} />
                <Route
                    path={`${ROUTES.SELECT_TEST}/:guid/:category_id/:test_id`}
                    element={<SelectTest />}
                />
                <Route path={ROUTES.SELECT_TEST} element={<SelectTest />} />
                <Route
                    path={`${ROUTES.TAKE_TEST}/:guid/:id`}
                    element={<MainTestQuestion />}
                />
                <Route
                    path={ROUTES.TEST_QUESTIONS}
                    element={<MainTestQuestion />}
                />
                <Route
                    exact
                    path={ROUTES.CUSTOMER_HIERARCHY}
                    element={<CustomerHierarchy />}
                />
                <Route
                    exact
                    path={ROUTES.PERMISSION_DENIED}
                    element={<PermissionDenied />}
                />
                <Route
                    exact
                    path={ROUTES.ADD_QUESTION}
                    element={<AddEditQuestion />}
                />
                <Route
                    exact
                    path={ROUTES.EDIT_QUESTION}
                    element={<AddEditQuestion />}
                />
                <Route
                    exact
                    path={ROUTES.FORM_SUBMISSION}
                    element={<SubmissionForm />}
                />
                <Route
                    exact
                    path={ROUTES.AUDIT_DEFENSE_REPORT}
                    element={<AuditDefenseReport />}
                />
                <Route
                    exact
                    path={ROUTES.DIRECT_LOGIN}
                    element={<DirectLogin />}
                />
            </Switch>
        </Translator>
    ) : (
        <Switch>
            <Route exact path={ROUTES.DIRECT_LOGIN} element={<DirectLogin />} />
            <Route exact path={ROUTES.LOGIN} element={<Login {...props} />} />
            <Route
                exact
                path={ROUTES.FORGOT_PASSWORD}
                element={<ForgetPassword />}
            />
            <Route path={ROUTES.RESET_PASSWORD} element={<ResetPassword />} />
            <Route path={ROUTES.PAGE_NOT_FOUND} element={<PageNotFound />} />
            <Route
                exact
                path={ROUTES.FORM_SUBMISSION}
                element={<SubmissionForm />}
            />
            <Route exact path={ROUTES.PRICING} element={<Pricing />} />
            <Route exact path={ROUTES.SIGNUP} element={<SignUp />} />
            <Route exact path={ROUTES.PRODUCTS} element={<Products />} />
            <Route exact path={ROUTES.CONTACTUS} element={<ContactUs />} />
            <Route exact path={ROUTES.HOME} element={<Home />} />
            <Route exact path={ROUTES.ABOUT_US} element={<AboutPage />} />
            <Route
                exact
                path={ROUTES.PAYMENT_METHOD}
                element={<PaymentMethod />}
            />
            {/* <Route
                exact
                path={ROUTES.PERMISSION_DENIED}
                element={<PermissionDenied />}
            /> */}
            <Route path={"/*"} element={<Navigate to="/login" />} />
        </Switch>
    );
};

export default Router;
