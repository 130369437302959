import { API_CONSTANTS } from "../../config/ApiConstants";
import { postApiCall, getApiCall, putApiCall } from "../../utils/Action";

export const GET_CATEGORY_DROPDOWN = "GET_CATEGORY_DROPDOWN";
export const GET_SIGNED_DATA = " GET_SIGNED_DATA";
export const SET_SIGNED_DOCUMENT = " SET_SIGNED_DOCUMENT";
export const STORE_SIGNED_DOCUMENT = "STORE_SIGNED_DOCUMENT";
export const UPDATE_SIGNED_DOUCUMENT = "UPDATE_SIGNED_DOUCUMENT";
export const GET_CONTRACT_IN_US_ACTION = "GET_CONTRACT_IN_US_ACTION";
export const ADD_CONTRACT_IN_US_ACTION = "ADD_CONTRACT_IN_US_ACTION";
export const DELETE_SIGNED_DOCUMENT = "DELETE_SIGNED_DOCUMENT";
export const SET_SELECTED_TEMPLATE_ACTION = "SET_SELECTED_TEMPLATE_ACTION";
export const UPDATE_CONTRACTS_ACTION = "UPDATE_CONTRACTS_ACTION";
export const SUBMIT_CONTRACT_IN_US_ACTION = "SUBMIT_CONTRACT_IN_US_ACTION";
export const GET_SIGNED_DOCUMENT_APRROVED_LIST =
"GET_SIGNED_DOCUMENT_APRROVED_LIST";
export const SET_SIGNED_PER_PAGE = "SET_SIGNED_PER_PAGE";
export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
export const GET_ADD_TRAINING_LIB = "GET_ADD_TRAINING_LIB";
export const LIST_TRAINING_LIB = "LIST_TRAINING_LIB";
export const DELETE_TRAINING_LIB = "DELETE_TRAINING_LIB";
export const EDIT_TRAINING_LIB = "EDIT_TRAINING_LIB";

export const getContractInUsActionList = (guid) => {
    return async (dispatch) => {
        try {
            const response = await getApiCall(
                API_CONSTANTS.CONTRACTS_DATABASE,
                {
                    guid: "",
                }
            );
            if (response?.status === 200) {
                return dispatch({
                    type: GET_CONTRACT_IN_US_ACTION,
                    payload: response.data.result,
                });
            }
        } catch (error) {
            throw error;
        }
    };
};
export const getSignedDocumentAprrovedList = (guid) => {
    return async (dispatch) => {
        try {
            const response = await postApiCall(`signed-document/get-list`, {
                guid: "",
                status: "1",
                is_active: true,
            });
            if (response?.status === 200) {
                return dispatch({
                    type: GET_SIGNED_DOCUMENT_APRROVED_LIST,
                    payload: response.data.result,
                });
            }
        } catch (error) {
            throw error;
        }
    };
};
export const getContractsContentAction = (id) => {
    return async (dispatch) => {
        try {
            const response = await getApiCall(`/contract/${id}`);
            if (response?.status === 200) {
                return dispatch({
                    type: SET_SELECTED_TEMPLATE_ACTION,
                    payload: response?.data?.result,
                });
            }
        } catch (error) {
            throw error;
        }
    };
};

export const addContractInUsAction = (data) => {
    return async (dispatch) => {
        try {
            const response = await postApiCall(
                API_CONSTANTS.CONTRACTS_DATABASE,
                data
            );
            if (response?.status === 201) {
                return dispatch({
                    type: ADD_CONTRACT_IN_US_ACTION,
                    payload: response.data.result,
                });
            }
        } catch (error) {
            throw error;
        }
    };
};
export const submitSignedDocumentAction = (data) => {
    return async (dispatch) => {
        try {
            const response = await postApiCall(
                API_CONSTANTS.CONTRACTS_DATABASE,
                data
            );
            if (response?.status === 201) {
                return dispatch({
                    type: SUBMIT_CONTRACT_IN_US_ACTION,
                    payload: response.data.result,
                });
            }
        } catch (error) {
            throw error;
        }
    };
};

export const updateContractsAction = (id, data) => {
    return async (dispatch) => {
        try {
            const response = await putApiCall(
                API_CONSTANTS.CONTRACTS_COLLECTION + (`/contract/${id}`, data)
            );
            if (response.status === 200) {
                return dispatch({
                    type: UPDATE_CONTRACTS_ACTION,
                    payload: [],
                });
            }
        } catch (error) {
            throw error;
        }
    };
};

export const deleteSignedDocument = (data) => {
    return async (dispatch, getState) => {
        try {
            const currentState = getState();
            let signedData = currentState.signedDocs.signedDocPagination;
            const response = await postApiCall(
                `signed-document/delete` + `/${data.id}`
            );
            if (response.status === 200) {
                dispatch({
                    type: DELETE_SIGNED_DOCUMENT,
                    payload: data,
                });
                dispatch(
                    getSignedDocumentData(
                        signedData?.currentPage,
                        signedData.listViewLimit,
                        true
                    )
                );
            }
        } catch (error) {
            throw error;
        }
    };
};

export const getCategoryDropdown = () => {
    return async (dispatch) => {
        try {
            const response = await getApiCall(
                API_CONSTANTS.GET_SIGNED_DOCUMENT_CATEGORY
            );
            return dispatch({
                type: GET_CATEGORY_DROPDOWN,
                payload: response?.data?.result || [],
            });
        } catch (error) {}
    };
};
export const setSelectedDetails = (data) => {
    return async (dispatch) => {
        dispatch({ type: SET_SIGNED_DOCUMENT, payload: data });
    };
};
export const getSignedDocumentById = (id, mode) => {
    return async (dispatch) => {
        try {
            if (!id) {
                return;
            }

            const response = await postApiCall(
                API_CONSTANTS.GET_SIGNED_DOCUMENT + `/${id}`
            );
            
            dispatch({
                type: SET_SIGNED_DOCUMENT,
                payload: { ...response?.data?.result, mode: mode } || [],
            });
        } catch (error) {
            throw error;
        }
    };
};
export const getSignedDocumentData = (
    currentPage = null,
    customerPageLimit = null,
    pagination = null,
    search=''
) => {
    return async (dispatch) => {
        try {
            let response;
            if (pagination) {
                response = await postApiCall(
                    API_CONSTANTS.GET_SIGNED_DOCUMENT_DETAIL +
                        "?page=" +
                        currentPage +
                        "&page_limit=" +
                        customerPageLimit +
                        "&search=" + 
                        search
                );
            } else {
                response = await postApiCall(
                    API_CONSTANTS.GET_SIGNED_DOCUMENT_DETAIL
                );
            }
            dispatch({
                type: GET_SIGNED_DATA,
                payload: response?.data?.result || [],
            });
        } catch (error) {
            throw error;
        }
    };
};
export const storeSignedDocument = (state) => {
    return async (dispatch, getState) => {
        try {
            const currentState = getState();
            const listView = currentState.signedDocs.signedDocPagination;
            const response = await postApiCall(
                API_CONSTANTS.STORE_SIGNED_DOCUMENT_DATA,
                state
            );

            if (response.status === 201) {
                if (listView.listViewLimit > listView.total) {
                    return dispatch({
                        type: STORE_SIGNED_DOCUMENT,
                        payload: response?.data?.result,
                    });
                } else return true;
            }
        } catch (error) {
            
        }
    };
};
export const updateSignedDocument = (data) => {
    return async (dispatch) => {
        try {
            const response = await postApiCall(
                API_CONSTANTS.UPDATE_SIGNED_DOCUMENT_DATA + `/${data?.id}`,
                data
            );
            if (response.status === 201) {
                return dispatch({
                    type: UPDATE_SIGNED_DOUCUMENT,
                    payload: {
                        ...response?.data?.result,
                        data,
                    },
                });
            }
        } catch (error) {

        }
    };
};

export const setCustomerPageLimit = (data) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: SET_SIGNED_PER_PAGE,
                payload: data,
            });
        } catch (error) {
            throw error;
        }
    };
};

export const setCurrentPage = (pageNumber) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: SET_CURRENT_PAGE,
                payload: pageNumber,
            });
        } catch (error) {
            throw error;
        }
    };
};

// export const getCategoryDropdown = () => {
//     return async (dispatch) => {
//         try {
//             const response = await getApiCall(
//                 API_CONSTANTS.GET_SIGNED_DOCUMENT_CATEGORY
//             );
//             return dispatch({
//                 type: GET_CATEGORY_DROPDOWN,
//                 payload: response?.data?.result || [],
//             });
//         } catch (error) {}
//     };
// };


export const addTrainingLib = (data) => {
    return async (dispatch) => {
        try {
            const response = await postApiCall(
                API_CONSTANTS.GET_ADD_TRAINING_LIB,
                data
            );
            if (response.status === 201) {
                return dispatch({
                    type: GET_ADD_TRAINING_LIB,
                    payload: {
                        ...response?.data?.result,
                        data,
                    },
                });
            }
        } catch (error) {

        }
    };
};

export const getTrainingLib = () => {
    return async (dispatch) => {
        try {
            const response = await getApiCall(
                API_CONSTANTS.GET_LIST_TRAINING_LIB
            );

            console.log('response', response?.data?.result);
            return dispatch({
                type: LIST_TRAINING_LIB,
                payload: response || [],
            });
        } catch (error) {}
    };
};

export const deleteTrainingLib = (data) => {
    return async (dispatch) => {
        try {
            const response = await postApiCall(
                API_CONSTANTS.GET_DELETE_TRAINING_LIB,
                data
            );
            if (response.status === 201) {
                return dispatch({
                    type: DELETE_TRAINING_LIB,
                    payload: {
                        ...response?.data?.result,
                        data,
                    },
                });
            }
        } catch (error) {

        }
    };
};

export const editTrainingLib = (data) => {
    return async (dispatch) => {
        try {
            const response = await postApiCall(
                API_CONSTANTS.GET_EDIT_TRAINING_LIB,
                data
            );
            if (response.status === 201) {
                return dispatch({
                    type: EDIT_TRAINING_LIB,
                    payload: {
                        ...response?.data?.result,
                        data,
                    },
                });
            }
        } catch (error) {

        }
    };
};

