import React, { Fragment } from "react";
import { Select, FormControl, InputLabel } from "@mui/material";

const MultiSelectDropDown = (props) => {
    const {
        renderValue,
        variant,
        title,
        value,
        onChange,
        required,
        children,
        name,
        readOnly,
        filterSelectedOptions,
        id,
    } = props;

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    return (
        <Fragment>
            <FormControl
                fullWidth={true}
                Inputlabelprops={{ shrink: true }}
                className="dropdownComponent"
            >
                <InputLabel
                    required={true}
                    id={id}
                    shrink={true}
                    className="labelClass"
                >
                    {title}
                </InputLabel>
                <Select
                    labelId={id}
                    fullWidth={true}
                    multiple={true}
                    required={required}
                    placeholder={title}
                    variant={variant || "filled"}
                    renderValue={renderValue}
                    value={value}
                    onChange={onChange}
                    MenuProps={MenuProps}
                    name={name}
                    readOnly={readOnly}
                    filterSelectedOptions={filterSelectedOptions}
                    id={id}
                >
                    {children}
                </Select>
            </FormControl>
        </Fragment>
    );
};

export default MultiSelectDropDown;
