import * as Actions from "../Action/TestScopeSettingAction";

const initialState = {
    settings: []
};

const testScopeSettings = (state=initialState, action)=>{
    switch(action?.type){
        case Actions.GET_TEST_SCOPE_DETAILS:{
            return {
                ...state,
                settings: [...action.payload]
            }
        }
        default:{
            return state;
        }
    }
}

export default testScopeSettings;
