import React, { useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Navbar from "../../common/Header/Navbar";
import PageHeader from "../../common/PageHeader";
import ButtonComponent from "../../components/ButtonComponent";
import CheckboxComponents from "../../components/Checkbox/CheckboxComponent";
import DatePickerComponent from "../../components/DatePicker/DatePickerComponent";
import TextFieldComponent from "../../components/TextField/TextFieldComponent";
import DropDownComponent from "../../components/DropDown/DropDownComponent";
import InvoiceDialog from "./InvoiceDialog";

function PaymentMethod(props) {
    const { commonDropdown, userLoggedIn } = props;
    const [state, setState] = useState({
        cardNumber: "",
        expiryDate: "",
        cvv: "",
        showCvv: false,
        saveCreditDetails: true,
        saveAddress: true,
        addressLine1: "",
        addressLine2: "",
        city: "",
        stateValue: "",
        zipCode: "",
        openInvoiceDialog: false,
    });

    const {
        cardNumber,
        expiryDate,
        cvv,
        showCvv,
        saveCreditDetails,
        saveAddress,
        addressLine1,
        addressLine2,
        city,
        stateValue,
        zipCode,
        openInvoiceDialog,
    } = state;

    const handleShowCvv = () => {
        setState((prevState) => ({ ...prevState, showCvv: !showCvv }));
    };

    const handleClose = () => {
        setState((prevState) => ({ ...prevState, openInvoiceDialog: false }));
    };

    const handleOpen = () => {
        setState((prevState) => ({ ...prevState, openInvoiceDialog: true }));
    };

    return (
        <>
            <Navbar />
            <InvoiceDialog open={openInvoiceDialog} handleClose={handleClose} />
            <Box sx={{ px: { md: 7, xs: 1 } }}>
                <PageHeader title={"Payment Method"} />
                <Card className="paymentCard">
                    <Grid container>
                        <Grid item xs={6}>
                            <Typography className="paymentTitles">
                                {"Enter your card details"}
                            </Typography>
                            <Grid container gap={2} pr={3}>
                                <Grid item xs={12}>
                                    <TextFieldComponent
                                        id="paymentMethodCardNumber"
                                        label={"Card Number"}
                                        onChange={(event) =>
                                            setState((prevState) => ({
                                                ...prevState,
                                                cardNumber: event.target.value,
                                            }))
                                        }
                                        required={true}
                                        value={cardNumber || ""}
                                    />
                                </Grid>
                                <Grid item xs={5.79}>
                                    <DatePickerComponent
                                        label={"Expiry Date"}
                                        onChange={(event) => {
                                            let date = new Date(event);
                                            setState((prevState) => ({
                                                ...prevState,
                                                expiryDate:
                                                    moment(date).format(
                                                        "YYYY-MM-DD"
                                                    ),
                                            }));
                                        }}
                                        required={true}
                                        value={expiryDate || ""}
                                        id="paymentMethodExpiryDate"
                                    />
                                </Grid>
                                <Grid item xs={5.79}>
                                    <TextFieldComponent
                                        id="paymentMethodCvv"
                                        label={"CVV"}
                                        onChange={(event) =>
                                            setState((prevState) => ({
                                                ...prevState,
                                                cvv: event.target.value,
                                            }))
                                        }
                                        required={true}
                                        value={cvv || ""}
                                        type={showCvv ? "text" : "password"}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() =>
                                                            handleShowCvv()
                                                        }
                                                        edge="start"
                                                    >
                                                        {!showCvv ? (
                                                            <VisibilityOff />
                                                        ) : (
                                                            <Visibility />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item display={"flex"}>
                                    <CheckboxComponents
                                        id="paymentMethodSaveCreditNextTime"
                                        value={saveCreditDetails}
                                    />
                                    <Typography className="paymentCheckbox">
                                        {"Save credit card for next time"}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography className="paymentTitles">
                                {"Billing Address"}
                            </Typography>
                            <Divider />
                            <Grid container gap={2}>
                                <Grid
                                    item
                                    xs={12}
                                    display={"flex"}
                                    justifyContent={"flex-end"}
                                >
                                    <Typography>
                                        {
                                            "*Add zip code to auto fill city & state."
                                        }
                                    </Typography>
                                </Grid>
                                <Grid item xs={5.8}>
                                    <TextFieldComponent
                                        label={`${userLoggedIn?.language_data?.customer?.address_line || "Address Line"} 1`}
                                        onChange={(event) =>
                                            setState((prevState) => ({
                                                ...prevState,
                                                addressLine1:
                                                    event.target.value,
                                            }))
                                        }
                                        required={true}
                                        value={addressLine1 || ""}
                                    />
                                </Grid>
                                <Grid item xs={5.85}>
                                    <TextFieldComponent
                                        label={`${userLoggedIn?.language_data?.customer?.address_line || "Address Line"} 2`}
                                        onChange={(event) =>
                                            setState((prevState) => ({
                                                ...prevState,
                                                addressLine2:
                                                    event.target.value,
                                            }))
                                        }
                                        required={true}
                                        value={addressLine2 || ""}
                                    />
                                </Grid>
                                <Grid item xs={3.76}>
                                    <TextFieldComponent
                                        label={userLoggedIn?.language_data?.customer?.city || "City"}
                                        onChange={(event) =>
                                            setState((prevState) => ({
                                                ...prevState,
                                                city: event.target.value,
                                            }))
                                        }
                                        value={city || ""}
                                        required={true}
                                    />
                                </Grid>
                                <Grid item xs={3.76}>
                                    <DropDownComponent
                                        label={userLoggedIn?.language_data?.complianceworkflow?.state || "State"}
                                        name={userLoggedIn?.language_data?.complianceworkflow?.state || "State"}
                                        fullWidth={true}
                                        onChange={(event) =>
                                            setState((prevState) => ({
                                                ...prevState,
                                                stateValue: event.target?.value,
                                            }))
                                        }
                                        required={true}
                                        value={`${stateValue || ""}`}
                                    >
                                        {commonDropdown?.states?.map(
                                            (option) => (
                                                <MenuItem
                                                    key={option.id}
                                                    value={option.id}
                                                >
                                                    {option.label}
                                                </MenuItem>
                                            )
                                        )}
                                    </DropDownComponent>
                                </Grid>
                                <Grid item xs={3.76}>
                                    <TextFieldComponent
                                        id="paymentMethodZipCode"
                                        label={userLoggedIn?.language_data?.customer?.zi_code || "Zip Code"}
                                        onChange={(event) =>
                                            setState((prevState) => ({
                                                ...prevState,
                                                zipCode: event.target.value,
                                            }))
                                        }
                                        value={zipCode || ""}
                                        required={true}
                                    />
                                </Grid>
                                <Grid item display={"flex"}>
                                    <CheckboxComponents
                                        value={saveAddress}
                                        id="paymentMethodSaveAddress"
                                    />
                                    <Typography className="paymentCheckbox">
                                        {"Save Address for future payments"}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>
                <Grid
                    container
                    display={"flex"}
                    justifyContent={"center"}
                    my={3}
                >
                    <ButtonComponent
                        id="paymentMethodPayButton"
                        title={"Proceed to Pay"}
                        onClick={() => handleOpen()}
                    />
                </Grid>
            </Box>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,
        commonDropdown: state?.auth?.commonDropdown,
    };
};

export default connect(mapStateToProps, null)(PaymentMethod);
