import * as Actions from "../Action/SignedDocumentAction";

const initialState = {
    signedDocuments: null,
    categoryDropdown: null,
    selectedDocumentData: null,
    signedDocPagination: {
        listViewLimit: 8,
        total: 0,
        currentPage: 1,
        lastPage: 0,
    },
    trainingLib: [],
};

const SignedDocumentReducer = (state = initialState, action) => {
    switch (action?.type) {
        case Actions.GET_CATEGORY_DROPDOWN: {
            return {
                ...state,
                categoryDropdown: action.payload,
            };
        }

        case Actions.GET_SIGNED_DATA: {
            return {
                ...state,
                signedDocuments: action.payload.data,
                signedDocPagination: {
                    ...state.signedDocPagination,
                    total: action?.payload?.total,
                    lastPage: action?.payload?.last_page,
                    currentPage: action?.payload?.current_page,
                },
            };
        }
        case Actions.STORE_SIGNED_DOCUMENT: {
            return {
                ...state,
                signedDocuments: [action.payload, ...state.signedDocuments],
            };
        }
        case Actions.DELETE_SIGNED_DOCUMENT: {
            let documentList = state?.signedDocuments?.filter(
                (obj) => obj.id !== action?.payload?.id
            );
            return {
                ...state,
                signedDocuments: documentList,
            };
        }
        case Actions.UPDATE_SIGNED_DOUCUMENT: {
            let documentList = state?.signedDocuments;
            let index = state?.signedDocuments?.findIndex(
                (obj) => obj?.id === action?.payload?.id
            );
            if (index !== -1) documentList[index] = action.payload;
            return {
                ...state,
                signedDocuments: documentList,
            };
        }
        case Actions.SET_SIGNED_DOCUMENT: {
            return {
                ...state,
                selectedDocumentData: action.payload,
            };
        }
        case Actions.GET_SIGNED_DOCUMENT_APRROVED_LIST: {
            return {
                ...state,
                selectedDocumentData: action.payload,
            };
        }
        case Actions.GET_CONTRACT_IN_US_ACTION: {
            return {
                ...state,
                signedDocuments: action.payload,
            };
        }

        case Actions.ADD_CONTRACT_IN_US_ACTION: {
            return {
                ...state,
                signedDocuments: [...state.signedDocuments, action.payload],
            };
        }
        case Actions.SUBMIT_CONTRACT_IN_US_ACTION: {
            return {
                ...state,
                signedDocuments: [...state.signedDocuments, action.payload],
            };
        }
        case Actions.UPDATE_CONTRACTS_ACTION: {
            return {
                ...state,
                signedDocuments: action.payload,
            };
        }
        case Actions.SET_SIGNED_PER_PAGE: {
            return {
                ...state,
                signedDocPagination: {
                    ...state.signedDocPagination,
                    listViewLimit: action?.payload,
                },
            };
        }
        case Actions.SET_CURRENT_PAGE: {
            return {
                ...state,
                signedDocPagination: {
                    ...state.signedDocPagination,
                    currentPage: action.payload?.data,
                },
            };
        }
        case Actions.GET_ADD_TRAINING_LIB: {
            return action.payload?.data;
        }
        
        case Actions.EDIT_TRAINING_LIB: {
            return action.payload?.data;
        }
        
        case Actions.LIST_TRAINING_LIB: {
            return {
                ...state,
                trainingLib: action.payload.data,
            };
                
        }
        case Actions.DELETE_TRAINING_LIB: {
            return action.payload?.data;
        }
        
        default: {
            return state;
        }
    }
};

export default SignedDocumentReducer;
