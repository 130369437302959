import Grid from "@mui/material/Grid";
import { Fragment, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import DropDownComponent from "../../components/DropDown/DropDownComponent";
import TableComponent from "../../components/Table/TableComponent";
import TextFieldComponent from "../../components/TextField/TextFieldComponent";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import ButtonComponent from "../../components/ButtonComponent";
import { bindActionCreators } from "redux";
import { getUserById } from "../../store/Action/UserAction";
import { connect } from "react-redux";
const FederalModifiers = (props) => {
    const {
        categoryDropdown,
        circuitData,
        state,
        setState,
        getCircuitsData,
        updateCircuitsData,
        userLoggedIn
    } = props;
    const [states, setStates] = useState({
        circuitList: circuitData || [],
    });
    const { circuitList } = states;
    if (circuitList?.length === 0 && circuitData?.metadata?.length) {
        setStates((prevState) => ({
            ...prevState,
            circuitList: circuitData?.metadata || [],
        }));
    }
    const headerList = [
        {
            name: "Percentage",
            headerClass: "colorCell",
            accessibleKey: "id",
            rowClass: "tableContent",
            headerAlign: "center",
            rowAlign: "start",
        },
    ];
    function handleFedral(event) {
        setState((prevState) => ({
            ...prevState,
            federal_law_modifiers: event.target.value,
        }));
        getCircuitsData(event.target.value);
    }
    function handleChangeValue(value, row) {
        let newData = circuitList;
        let index = circuitList.findIndex((obj) => obj.id === row?.id);
        let data;
        if (index !== -1) {
            data = {
                ...newData[index],
                label: value,
            };
        }
        newData[index] = data;
        setStates((prevState) => ({
            ...prevState,
            circuitList: newData,
        }));
    }
    return (
        <Fragment>
            <Grid container display="flex" justifyContent={"center"}>
                <Grid container>
                    <Grid item md={12} xs={12} lg={4} mt={2} ml={8} xl={4}>
                        <DropDownComponent
                            fullWidth={true}
                            label={"Select Category"}
                            name={"federal_law_modifiers"}
                            required={true}
                            value={state.federal_law_modifiers}
                            onChange={(event) => handleFedral(event)}
                        >
                            {categoryDropdown?.circuits?.map((obj) => (
                                <MenuItem
                                    value={obj.id}
                                    key={obj.id}
                                    className="dropDownText"
                                >
                                    {obj.label}
                                </MenuItem>
                            ))}
                        </DropDownComponent>
                    </Grid>
                    <Grid item md={6} lg={6} mt={2} ml={8}>
                        <Box className="boxTable">
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography className="federal">
                                        {circuitData?.circuit?.label}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3} mt={2} ml={1.5}>
                                    <Typography className="demoHeading">
                                        Democrate Appointed
                                    </Typography>
                                </Grid>
                                <Grid item xs={1} mt={8} ml={1}>
                                    <Box className="demoBox">
                                        <Typography className="demoData">
                                            {circuitData?.democrat}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid
                                    item
                                    xs={4}
                                    mt={2}
                                    ml={4}
                                    display={"flex"}
                                    justifyContent={"flex-end"}
                                >
                                    <Typography className="republicHeading">
                                        Republic Appointed
                                    </Typography>
                                </Grid>
                                <Grid item xs={1} mt={4} ml={4}>
                                    <Box className="republicBox">
                                        <Typography className="demoData">
                                            {circuitData?.republican}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={4} ml={50}>
                                    <DropDownComponent
                                        fullWidth={true}
                                        value={"U.S."}
                                    >
                                        {["California", "U.S.", "Canada"].map(
                                            (obj) => (
                                                <MenuItem
                                                    value={obj}
                                                    key={obj}
                                                    className="dropDownText"
                                                >
                                                    {obj}
                                                </MenuItem>
                                            )
                                        )}
                                    </DropDownComponent>
                                </Grid>
                            </Grid>
                            <Grid item md={12} xs={12} mt={2} mx={4}>
                                <TableComponent
                                    headerList={headerList}
                                    tableList={circuitList}
                                    extraHeaderRight={
                                        <TableCell
                                            className="colorModification"
                                            align="right"
                                        >
                                            Modification
                                        </TableCell>
                                    }
                                >
                                    {circuitList?.length &&
                                        circuitList?.map((row, index) => (
                                            <TableRow
                                                key={row.name}
                                                className="seriesCell"
                                            >
                                                {headerList.map((obj) => (
                                                    <TableCell
                                                        component="th"
                                                        align={obj?.rowAlign}
                                                        className={
                                                            obj?.rowClass
                                                        }
                                                    >
                                                        {
                                                            row[
                                                                obj
                                                                    ?.accessibleKey
                                                            ]
                                                        }
                                                    </TableCell>
                                                ))}
                                                <TableCell className="rowColor">
                                                    <TextFieldComponent
                                                        id="federalLabel"
                                                        variant="outlined"
                                                        className="tableField"
                                                        onChange={(event) =>
                                                            handleChangeValue(
                                                                event.target
                                                                    .value,
                                                                row
                                                            )
                                                        }
                                                        value={row.label}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </TableComponent>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
                <Grid mt={2} ml={110}>
                    <ButtonComponent
                        title={"Cancel"}
                        variant="outlined"
                        id="federalCancelButton"
                    />

                    <ButtonComponent
                        id="federalSaveButton"
                        sx={{ marginLeft: "20px" }}
                        title={userLoggedIn?.language_data?.comman?.save_btn || "Save"}
                        type="submit"
                        variant="contained"
                        onClick={() =>
                            updateCircuitsData(
                                state?.federal_law_modifiers,
                                circuitData
                            )
                        }
                    />
                </Grid>
            </Grid>
        </Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,
    };
};
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getUserById,

        },
        dispatch
    );
};
export default connect(mapStateToProps, mapDispatchToProps) (FederalModifiers);
