import React from "react";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";

const DividerCom = (props) => {
    const { children } = props;
    return (
        <>
            <Grid container>
                <Grid
                    item
                    xs={12}
                    sx={{
                        display: "flex",
                        justifyContent: "end",
                    }}
                >
                    {children}
                </Grid>
            </Grid>
            <Divider className="divider" />
        </>
    );
};
export default DividerCom;
