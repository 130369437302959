import * as Actions from "../Action/FaqsAction";

const initialState = {
    faqsCategory: null,
    selectedCategory: null,
    faqsList: null,
    selectedQuestion: null,
};

const MqsReducer = (state = initialState, action) => {
    switch (action?.type) {
        case Actions.GET_FAQS_CATEGORY: {
            return { ...state, faqsCategory: action.payload };
        }

        case Actions.ADD_STORE_CATEGORY: {
            return {
                ...state,
                faqsCategory: [...state.faqsCategory, action.payload],
            };
        }

        case Actions.SET_SELECTED_CATEGORY: {
            return { ...state, selectedCategory: action.payload };
        }

        case Actions.UPDATE_CATEGORY: {
            let list = state?.faqsCategory;
            let index = state?.faqsCategory.findIndex(
                (obj) => obj.id === action.payload.id
            );
            if (index !== -1) list[index] = action?.payload;
            return {
                ...state,
                faqsCategory: list,
                selectedCategory: null,
            };
        }

        case Actions.DELETE_CATEGORY: {
            let questionList = state.faqsCategory.filter(
                (obj) => obj.id !== action?.payload
            );
            return {
                ...state,
                faqsCategory: questionList,
                faqsList: null,
            };
        }

        case Actions.GET_FAQS: {
            return { ...state, faqsList: action.payload };
        }

        case Actions.ADD_STORE_FAQS: {
            return {
                ...state,
                faqsList: [...state.faqsList, action.payload],
            };
        }
        case Actions.SET_SELECTED_QUESTION: {
            return {
                ...state,
                selectedQuestion: action.payload,
            };
        }

        case Actions.UPDATE_FAQ: {
            let list = state.faqsList;
            let index = list.findIndex((obj) => obj.id === action.payload.id);
            if (index !== -1) list[index] = action.payload;
            return {
                ...state,
                faqsList: list,
                selectedQuestion: null,
            };
        }

        case Actions.DELETE_FAQS: {
            let list = state?.faqsList.filter(
                (obj) => obj?.id !== action?.payload
            );
            return { ...state, faqsList: list };
        }

        default: {
            return state;
        }
    }
};
export default MqsReducer;
