import { API_CONSTANTS } from "../../config/ApiConstants";
import {
    getApiCall,
    postApiCall,
    putApiCall,
    deleteApiCall,
} from "../../utils/Action";
export const GET_FAQS_CATEGORY = "GET_FAQS_CATEGORY";
export const ADD_STORE_CATEGORY = "ADD_STORE_CATEGORY";
export const SET_SELECTED_CATEGORY = "SET_SELECTED_CATEGORY";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const GET_FAQS = "GET_FAQS";
export const ADD_STORE_FAQS = "ADD_STORE_FAQS";
export const SET_SELECTED_QUESTION = "SET_SELECTED_QUESTION";
export const DELETE_FAQS = "DELETE_FAQS";
export const UPDATE_FAQ = "UPDATE_FAQ";

export const getFaqsCategory = () => {
    return async (dispatch) => {
        try {
            const response = await getApiCall(`faq-category`);
            dispatch({
                type: GET_FAQS_CATEGORY,
                payload: response?.data?.result || [],
            });
        } catch (error) {}
    };
};
export const storeCategory = (state) => {
    return async (dispatch) => {
        try {
            const response = await postApiCall(
                API_CONSTANTS.STORE_CATEGORY,
                state
            );

            if (response.status === 201) {
                return dispatch({
                    type: ADD_STORE_CATEGORY,
                    payload: response?.data?.result,
                });
            }
        } catch (error) {
            
        }
    };
};
export const setSelectedCategory = (data) => {
    return async (dispatch) => {
        dispatch({ type: SET_SELECTED_CATEGORY, payload: data });
    };
};
export const updateCategory = (data) => {
    return async (dispatch) => {
        try {
            const response = await putApiCall(
                API_CONSTANTS.STORE_CATEGORY + `/${data?.id}`,
                data
            );
            if (response.status === 200) {
                return dispatch({
                    type: UPDATE_CATEGORY,
                    payload: {
                        ...response?.data?.result,
                        data,
                    },
                });
            }
        } catch (error) {

        }
    };
};
export const deleteCategoryId = (data) => {
    return async (dispatch) => {
        try {
            const response = await deleteApiCall(
                API_CONSTANTS.STORE_CATEGORY + `/${data}`
            );
            if (response?.status === 200) {
                dispatch({ type: DELETE_CATEGORY, payload: data });
            }
        } catch (error) {
            throw error;
        }
    };
};
export const getFaqs = () => {
    return async (dispatch) => {
        try {
            const response = await getApiCall(`faq`);
            dispatch({
                type: GET_FAQS,
                payload: response?.data?.result || [],
            });
        } catch (error) {}
    };
};
export const getFaqsById = (id) => {
    return async (dispatch) => {
        try {
            const response = await getApiCall(`faq` + `/${id}`);
            dispatch({
                type: SET_SELECTED_QUESTION,
                payload: { ...response?.data?.result} || [],
            });
        } catch (error) {

            throw error;
        }
    };
};
export const storeFaqs = (state) => {
    return async (dispatch) => {
        try {
            const response = await postApiCall(API_CONSTANTS.FAQS, state);

            if (response.status === 201) {
                return dispatch({
                    type: ADD_STORE_FAQS,
                    payload: response?.data?.result,
                });
            }
        } catch (error) {

        }
    };
};
export const setSelectedQuestion = (data) => {
    return async (dispatch) => {
        dispatch({ type: SET_SELECTED_QUESTION, payload: data });
    };
};
export const updateFaqs = (data) => {
    return async (dispatch) => {
        try {
            const response = await putApiCall(
                API_CONSTANTS.FAQS + `/${data?.id}`,
                data
            );
            if (response.status === 200) {
                return dispatch({
                    type: UPDATE_FAQ,
                    payload: {
                        ...response?.data?.result,
                        data,
                    },
                });
            }
        } catch (error) {

        }
    };
};
export const deleteFaqsId = (data) => {
    return async (dispatch) => {
        try {
            const response = await deleteApiCall(
                API_CONSTANTS.FAQS + `/${data}`
            );
            if (response?.status === 200) {
                dispatch({
                    type: DELETE_FAQS,
                    payload: data,
                });
            }
        } catch (error) {
            throw error;
        }
    };
};

export const getFaqsBySearchTerm = (catId = 0, searchTerm) => {
    return async (dispatch) => {
        try {
            const response = await getApiCall(`faq/searchValues/${catId}/${searchTerm}`);
            return response?.data?.result || []
        } catch (error) {

            throw error;
        }
    };
};
