import React ,{Fragment} from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import DropDownComponent from "../../components/DropDown/DropDownComponent";
import ButtonComponent from "../../components/ButtonComponent";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getUserById } from "../../store/Action/UserAction";
const StateModifiers = (props) => {
    const {
        categoryDropdown,
        state,
        setState,
        getStateData,
        stateData,
        updateStateData,
        userLoggedIn
    } = props;

    function handleState(event) {
        setState((prevState) => ({
            ...prevState,
            state_law_modifiers: event.target.value,
        }));
        getStateData(event.target.value);
    }
    return (
        <Fragment>
            <Grid container display="flex" justifyContent={"center"}>
                <Grid container>
                    <Grid item md={12} xs={12} lg={4} mt={2} ml={8} xl={4}>
                        <DropDownComponent
                            fullWidth={true}
                            label={"Select State"}
                            name={"state_law_modifiers"}
                            required={true}
                            value={state?.state_law_modifiers}
                            onChange={(event) => handleState(event)}
                        >
                            {categoryDropdown?.states?.map((obj) => (
                                <MenuItem
                                    value={obj.id}
                                    key={obj.id}
                                    className="dropDownText"
                                >
                                    {obj.label}
                                </MenuItem>
                            ))}
                        </DropDownComponent>
                    </Grid>
                    <Grid item md={6} lg={6} mt={2} ml={8}>
                        <Box className="firstBox">
                            <Grid container>
                                <Grid item lg={12} ml={1}>
                                    <Typography className="federal">
                                        {stateData?.state}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    lg={5}
                                    xs={3}
                                    xl={3}
                                    mt={2}
                                    ml={2}
                                >
                                    <Typography className="demoHeading">
                                        Controlled
                                    </Typography>
                                </Grid>

                                <Grid
                                    item
                                    md={4}
                                    lg={4}
                                    xl={4}
                                    xs={4}
                                    mt={2}
                                    ml={8}
                                >
                                    <Typography className="republicHeading">
                                        Adjustment
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    lg={4}
                                    xl={4}
                                    xs={4}
                                    mt={2}
                                    mr={15}
                                    ml={2}
                                >
                                    <DropDownComponent
                                        fullWidth={true}
                                        value={stateData?.controlled}
                                    >
                                        {[
                                            "Republican Controlled",
                                            "California",
                                            "U.S.",
                                        ].map((obj) => (
                                            <MenuItem
                                                value={obj}
                                                key={obj}
                                                className="dropDownText"
                                            >
                                                {obj}
                                            </MenuItem>
                                        ))}
                                    </DropDownComponent>
                                </Grid>
                                <Grid item md={4} lg={4} xl={4} xs={4} mt={2}>
                                    <DropDownComponent
                                        fullWidth={true}
                                        value={stateData?.adjustment}
                                    >
                                        {["1", "2", "3"].map((obj) => (
                                            <MenuItem
                                                value={obj}
                                                key={obj}
                                                className="dropDownText"
                                            >
                                                {obj}
                                            </MenuItem>
                                        ))}
                                    </DropDownComponent>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
                <Grid
                    item
                    lg={11}
                    md={1}
                    sm={12}
                    mt={2}
                    xl={1}
                    display="flex"
                    justifyContent="flex-end"
                    gap={"8px"}
                >
                    <ButtonComponent title={"Cancel"} variant="outlined" />

                    <ButtonComponent
                        sx={{ marginLeft: "20px" }}
                        title={userLoggedIn?.language_data?.comman?.save_btn || "Save"}
                        type="submit"
                        variant="contained"
                        onClick={() =>
                            updateStateData(
                                state?.state_law_modifiers,
                                stateData
                            )
                        }
                    />
                </Grid>
            </Grid>
        </Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,
    };
};
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getUserById,

        },
        dispatch
    );
};


export default connect(mapStateToProps, mapDispatchToProps) (StateModifiers);
