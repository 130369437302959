import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "../../../assets/styles/Container/AccordionCustom.scss";
import CheckboxComponents from "../../components/Checkbox/CheckboxComponent";

import { connect } from "react-redux";
const AccordionWithCheckBox = (props) => {
    const { title, userLoggedIn, children, onHandlerCheckBox, id, selectedModules } = props;

    const [checked, setChecked] = useState(false);
    useEffect(() => {
        if (selectedModules) setCheckedValue();
    }, [selectedModules?.id]);
    const setCheckedValue = () => {
        const findData = selectedModules?.settings?.find(
            (obj) => obj.master_id == id
        );
        if (findData) {
            setChecked(true);
        }
    };
    const callOnChange = (e) => {
        onHandlerCheckBox(e);
        setChecked(!checked);
    };
    return (
        <Accordion className="accordionCheckbox">
            <AccordionSummary
                className="accordionSummary"
                expandIcon={<ExpandMoreIcon />}
            >
                <CheckboxComponents
                    onChange={callOnChange}
                    name={title}
                    value={id}
                    id={id}
                    checked={checked}
                    label={userLoggedIn?.language_data?.formstudio?.label || "Label"}
                    sx={{ pl: 0, mt: "5px" }}
                />
                <Typography
                    variant="span"
                    className="accordionTitle"
                    mt={2}
                >
                    {title}
                </Typography>
            </AccordionSummary>
            <AccordionDetails className="accordionDetails">
                {children}
            </AccordionDetails>
        </Accordion>
    );
};
const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,
    };
};

export default connect(mapStateToProps, null)(AccordionWithCheckBox);
