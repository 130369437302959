import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Slide from "@mui/material/Slide";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getSignedDocumentData } from "../../store/Action/SignedDocumentAction";
import { ImportContractsAction } from "../../store/Action/ContractAction";
import ImportTable from "./ImportTable";
import { headerList } from "./ImportTableHeadres";
import ButtonComponent from "../ButtonComponent";
import DialogComponent from "../DialogsBox/DialogComponent";
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
function ImportTemplate(props) {
    const {
        ImportContractsAction,
        getSignedDocumentData,
        initilizeStates,
        onClose,
        selectedValue,
        setState,
        open,
        signedDocuments,
        user,
        customer,
    } = props;
    const location = useLocation();
    const urlData = location.pathname?.split("/");
    const id = urlData[3];
    const guid = urlData[2];
    const [selectContract, setSelectContracts] = useState({
        isCheckedAll: false,
        isChecked: [],
    });
    const { isCheckedAll, isChecked } = selectContract;
    useEffect(() => {
        getSignedDocumentData();
    }, [selectContract.isChecked, getSignedDocumentData]);

    const handleClose = () => {
        setState((prevState) => ({ ...prevState, openImport: false }));
        onClose(selectedValue);
    };

    async function onClickOnImport() {
        setState((prevState) => ({ ...prevState, openImport: false }));
        onClose(selectedValue);
        await ImportContractsAction({
            guid: customer.guid,
            is_active: true,
            contractor_id: Number(id),
            document_ids: isChecked,
            contract_ids: isChecked,
        });
        setState((prevState) => ({ ...prevState, loading: true }));
        initilizeStates(guid, Number(id));
        setSelectContracts((prevState) => ({
            ...prevState,
            isChecked: [],
        }));
        setState((prevState) => ({ ...prevState, loading: false }));
    }
    return (
        <DialogComponent
        direction="up"
            className="dialogBoxMain"
            open={open}
            handleClose={handleClose}
            title={`Contract Template`}
            TransitionComponent={Transition}
            content={
                <> <ImportTable
                        tableData={signedDocuments}
                        headers={headerList}
                        setSelectContracts={setSelectContracts}
                        selectContract={selectContract}
                        isChecked={isChecked}
                        isCheckedAll={isCheckedAll}
                    />
                </>
            }
            action={selectContract.isChecked.length !== 0 ? (
                     <ButtonComponent title={"Import"}  autoFocus
                     className="button_contained" onClick={onClickOnImport} />
                ) : (
                    <ButtonComponent title={"Camcel"}   autoFocus
                    className="button_contained"
                    onClick={handleClose}/>
                    )
            }
        />
    );
}
const mapStateToProps = (state) => {
    return {
        saveTemplateId: state?.saveTemplateId,
        emailTemplateList: state?.contractor?.emailTemplateList,
        user: state?.user?.selectedUser?.id,
        customer: state?.customer?.selectedCustomer,
        signedDocuments: state?.signedDocs?.signedDocuments,
    };
};
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            ImportContractsAction,
            getSignedDocumentData,
        },
        dispatch
    );
};
export default connect(mapStateToProps, mapDispatchToProps)(ImportTemplate);
