import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useNavigate } from "react-router-dom";
import Box from "@mui/system/Box";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import Navbar from "../../common/Header/Navbar";
import PageHeader from "../../common/PageHeader";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import TooltipComponent from "../../components/ToolTip/TooltipComponent";
import CommonDeleteDialog from "../../components/DialogsBox/CommonDeleteDialog";
import LoadingComponent from "./../../components/Loading/LoadingComponent";
import TableComponent from "../../components/Table/TableComponent";
import FooterPagination from "../../common/PaginationSection/FooterPagination";
import NoInfoAvailable from "../../components/NoInfoAvailable";
import {
    getEmailTemplatesData,
    deleteTemplate,
    setSelectedDetails,
    setCurrentPage,
    setCustomerPageLimit,
} from "../../store/Action/EmailTemplateAction";
import "../../../assets/styles/Container/EmailTemplate.scss";
import "../../../assets/styles/Common/_CommonStyle.scss";
import { ReactComponent as DeleteIcon } from "../../../assets/Images/Icons/DeleteIcon.svg";
import { ReactComponent as EditIcon } from "../../../assets/Images/Icons/EditIcon.svg";
import { ROUTES } from "../../router/RouteList";
import HeaderPagination from "../../common/PaginationSection/HeaderPagination";
import { pageLimit } from "../../utils/CommonData";
import { Translate } from "react-auto-translate";
const EmailTemplates = (props) => {
    const {
        getEmailTemplatesData,
        emailTemplatesData,
        deleteTemplate,
        setSelectedDetails,
        setCurrentPage,
        setCustomerPageLimit,
        emailTemPagination,
        userLoggedIn,
    } = props;
    const navigate = useNavigate();
    const [state, setState] = useState({
        delete: false,
        deletedData: null,
        isLoading: false,
        data: null,
        filteredData: null,
        sortConfig: { key: null, direction: "asc" },
    });
    const [search, setSearch] = useState("");
    const [open, setOpen] = useState(false);
    const { deletedData, isLoading, filteredData, sortConfig } = state;
    useEffect(() => {
        async function callApi() {
            console.log();
            setState((prevState) => ({ ...prevState, isLoading: false }));
            await getEmailTemplatesData(
                search,
                null,
                emailTemPagination?.listViewLimit
            );
            setState((prevState) => ({ ...prevState, isLoading: false }));
        }
        if (emailTemplatesData === null) callApi();
        if (emailTemplatesData) {
            setState((prevState) => ({
                ...prevState,
                filteredData: emailTemplatesData,
            }));
        }
    }, [getEmailTemplatesData, emailTemplatesData]);

    const closeDelete = () => {
        setState((prevState) => ({ ...prevState, delete: false }));
    };
    const handleDelete = (data) => {
        setState((prevState) => ({
            ...prevState,
            delete: true,
            filteredData: emailTemplatesData,
            deletedData: data,
        }));
    };
    const handleDeleteAction = async (data) => {
        setState((prevState) => ({ ...prevState, isLoading: false }));
        closeDelete();
        await deleteTemplate(data?.id);
        setState((prevState) => ({ ...prevState, isLoading: false }));
    };

    const editEmailTemplateIcon = async (row) => {
        await setSelectedDetails(row);
        navigate("/edit-email-template/" + row?.id);
    };
    const emailTemplateView = async (row) => {
        await setSelectedDetails(row);
        navigate("/view-email-template/" + row?.id);
    };
    const handleAddButton = () => {
        setSelectedDetails(null);
        setState((prevState) => ({
            ...prevState,
            filteredData: emailTemplatesData,
        }));
        navigate(ROUTES.ADD_EMAIL_TEMPLATE);
    };

    useEffect(() => {
        emailTemPagination?.currentPage &&
            getEmailTemplatesData(
                search,
                emailTemPagination?.currentPage,
                emailTemPagination?.listViewLimit
            );
    }, []);

    const renderClass = (obj, row) => {
        if (obj?.rowClass) return obj?.rowClass;
        else if (row?.status_name === "Approved") return "cellApproved";
        else if (
            row?.status_name?.replace(/\s/g, "") === "PendingforApproval" ||
            row?.status_name === "Others"
        )
            return "cellPending";
        else if (
            row?.status_name?.replace(/\s/g, "") === "OnHold" ||
            row?.status_name === "Rejected" ||
            row?.status_name === "Reassigned"
        )
            return "cellOnHold";
    };

    const handleSort = (property) => {
        let sortedData = [...filteredData].sort((a, b) => {
            if (property !== "created_date") {
                return sortConfig.direction === "desc"
                    ? a?.[property]?.toLowerCase() <
                      b?.[property]?.toLowerCase()
                        ? 1
                        : b?.[property]?.toLowerCase() <
                          a?.[property]?.toLowerCase()
                        ? -1
                        : 0
                    : a?.[property]?.toLowerCase() >
                      b?.[property]?.toLowerCase()
                    ? 1
                    : b?.[property]?.toLowerCase() >
                      a?.[property]?.toLowerCase()
                    ? -1
                    : 0;
            } else {
                return sortConfig.direction === "desc"
                    ? new Date(a?.[property]) - new Date(b?.[property])
                    : new Date(b?.[property]) - new Date(a?.[property]);
            }
        });
        setState((prevState) => ({
            ...prevState,
            filteredData: sortedData,
            sortConfig: {
                direction: sortConfig.direction === "desc" ? "asc" : "desc",
            },
        }));
    };

    const headerList = [
        {
            id: 1,
            name:
                userLoggedIn?.language_data?.emailtemplates?.view_user ||
                "Template Name",
            headerClass: "tableHeading",
            accessibleKey: "template_name",
            rowClass: "tableBodyRowEmail",
            headerAlign: "center",
            rowAlign: "center",
            width: 400,
            sort: true,
            sortFunction: handleSort,
        },
        {
            id: 2,
            name:
                userLoggedIn?.language_data?.comman?.created_by || "Created By",
            accessibleKey: "user",
            headerClass: "tableHeading customerNameTableHeading",
            rowClass: "tableBodyRowEmail",
            headerAlign: "center",
            rowAlign: "center",
            buttonType: "view",
            width: 150,
            sort: false,
        },
        {
            id: 3,
            name: userLoggedIn?.language_data?.comman?.assign_to || "Assign to",
            headerClass: "tableHeading",
            accessibleKey: "assign_to",
            rowClass: "companyName",
            headerAlign: "center",
            rowAlign: "center",
            width: 150,
            sort: false,
        },
        {
            id: 4,
            name: userLoggedIn?.language_data?.comman?.status || "Status",
            headerClass: "tableHeading conductorTypeTableHeading",
            accessibleKey: "status_name",
            headerAlign: "center",
            rowAlign: "center",
            width: 150,
            sort: true,
            sortFunction: handleSort,
        },
        {
            id: 5,
            name:
                userLoggedIn?.language_data?.comman?.approved_by ||
                "Approved By",
            accessibleKey: "approved_by",
            headerClass: "tableHeading",
            headerAlign: "center",
            rowAlign: "center",
            width: 150,
            sort: false,
        },
        {
            id: 6,
            name: "Time Stamp",
            headerClass: "tableHeading",
            accessibleKey: "created_date",
            rowClass: "timeStamp",
            headerAlign: "center",
            rowAlign: "center",
            width: 150,
            sort: true,
            sortFunction: handleSort,
        },
    ];

    const handleSearch = (data) => {
        getEmailTemplatesData(search, null, emailTemPagination?.listViewLimit);
    };

    const handleViewLimitChange = async (data) => {
        setCustomerPageLimit(data?.target?.value);
        setState((prevState) => ({ ...prevState, isLoading: false }));
        await getEmailTemplatesData(search, null, data?.target?.value);
        setState((prevState) => ({ ...prevState, isLoading: false }));
    };

    const handlePaginationOnChange = async (_, pageNumber) => {
        setCurrentPage(pageNumber);
        setState((prevState) => ({ ...prevState, isLoading: true }));
        await getEmailTemplatesData(
            search,
            pageNumber,
            emailTemPagination?.listViewLimit
        );
        setState((prevState) => ({ ...prevState, isLoading: false }));
    };

    return (
        <>
            <CommonDeleteDialog
                open={state?.delete}
                title={<Translate>Delete Email</Translate>}
                cancelButtonAction={closeDelete}
                cancelButtonTitle={<Translate>Cancel</Translate>}
                submitButtonTitle={<Translate>Delete</Translate>}
                content={
                    <Translate>
                        {`Are you sure, you want to remove the "${deletedData?.template_name}" template from the Emails?`}
                    </Translate>
                }
                submitButtonAction={handleDeleteAction}
                submitButtonParameter={deletedData}
                onClick={handleDeleteAction}
                id="emailTemplatesDialog"
                setOpen={setOpen}
            />
            <Navbar />
            {!filteredData || isLoading ? (
                <LoadingComponent sx={{ height: "600px" }} />
            ) : (
                <Box sx={{ px: { md: 7, xs: 1 } }}>
                    <PageHeader
                        title={
                            <Translate>
                                {userLoggedIn?.language_data?.emailtemplates
                                    ?.manage_user ||
                                    "Email Templates (United States) - List View"}
                            </Translate>
                        }
                        showSearchBar={true}
                        buttonTitle={
                            <Translate>
                                {userLoggedIn?.language_data?.emailtemplates
                                    ?.edit_user || "New Template"}
                            </Translate>
                        }
                        handleAdd={handleAddButton}
                        handleSearch={handleSearch}
                        setSearch={setSearch}
                    />
                    <HeaderPagination
                        showViewButton={false}
                        total={emailTemPagination?.total}
                        listViewLimit={emailTemPagination?.listViewLimit}
                        handleViewLimitChange={handleViewLimitChange}
                        showPagination={pageLimit < emailTemPagination?.total}
                    />
                    {filteredData?.length !== 0 ? (
                        <TableComponent
                            headerList={headerList}
                            serialNo={{
                                name: <Translate>S.No.</Translate>,
                                headerClass: "tableHeading",
                                headerAlign: "center",
                                rowClass: "typo4",
                                rowAlign: "center",
                            }}
                            sortConfig={sortConfig}
                            extraHeaderRight={
                                <TableCell
                                    align="center"
                                    className="tableHeading actionTableHeading"
                                >
                                    <Translate>Action</Translate>
                                </TableCell>
                            }
                        >
                            {filteredData?.length
                                ? filteredData?.map((row, index) => (
                                      <TableRow
                                          key={row.name}
                                          className={
                                              index % 2 !== 0
                                                  ? "tertiaryBackground"
                                                  : ""
                                          }
                                      >
                                          <TableCell
                                              align="center"
                                              className="typo4"
                                          >
                                              {index + 1}
                                          </TableCell>
                                          {headerList.map((obj) => (
                                              <TableCell
                                                  component="th"
                                                  key={obj?.name}
                                                  align={obj?.rowAlign}
                                                  width={obj?.width}
                                                  className={renderClass(
                                                      obj,
                                                      row
                                                  )}
                                              >
                                                  {obj?.id === 2 &&
                                                  row.default ? (
                                                      <Translate>
                                                          Default
                                                      </Translate>
                                                  ) : obj?.id === 5 &&
                                                    !row?.approved_by ? (
                                                      <Translate>
                                                          Not Assigned
                                                      </Translate>
                                                  ) : (
                                                      <Translate>
                                                          {
                                                              row[
                                                                  obj
                                                                      ?.accessibleKey
                                                              ]
                                                          }
                                                      </Translate>
                                                  )}
                                              </TableCell>
                                          ))}
                                          <TableCell align="center" width="200">
                                              <TooltipComponent
                                                  title={
                                                      <Translate>
                                                          {userLoggedIn
                                                              ?.language_data
                                                              ?.emailtemplates
                                                              ?.view_email_template ||
                                                              "View Email Template"}
                                                      </Translate>
                                                  }
                                                  arrow={true}
                                              >
                                                  <IconButton
                                                      id="emailTemView"
                                                      className="iconButton"
                                                      onClick={() =>
                                                          emailTemplateView(row)
                                                      }
                                                  >
                                                      <RemoveRedEyeOutlinedIcon />
                                                  </IconButton>
                                              </TooltipComponent>
                                              <TooltipComponent
                                                  title={
                                                      <Translate>
                                                          {userLoggedIn
                                                              ?.language_data
                                                              ?.emailtemplates
                                                              ?.edit_email_template ||
                                                              "Edit Email Template"}
                                                      </Translate>
                                                  }
                                              >
                                                  <IconButton
                                                      id="emailTemEdit"
                                                      className="iconButton"
                                                      onClick={() =>
                                                          editEmailTemplateIcon(
                                                              row
                                                          )
                                                      }
                                                  >
                                                      <EditIcon />
                                                  </IconButton>
                                              </TooltipComponent>
                                              <TooltipComponent
                                                  title={
                                                      <Translate>
                                                          {userLoggedIn
                                                              ?.language_data
                                                              ?.emailtemplates
                                                              ?.delete_email_template ||
                                                              "Email template deleted successfully!"}
                                                      </Translate>
                                                  }
                                              >
                                                  <IconButton
                                                      id="emailTemDelete"
                                                      className="iconButton"
                                                      onClick={() =>
                                                          handleDelete(row)
                                                      }
                                                  >
                                                      <DeleteIcon />
                                                  </IconButton>
                                              </TooltipComponent>
                                          </TableCell>
                                      </TableRow>
                                  ))
                                : null}
                        </TableComponent>
                    ) : (
                        <NoInfoAvailable
                            className="noCustomerInfo"
                            noInfoTypo="noInfoTypeQuestion"
                        />
                    )}
                    {pageLimit < emailTemPagination?.total ? (
                        <FooterPagination
                            count={emailTemPagination?.lastPage}
                            page={emailTemPagination?.currentPage}
                            onChange={handlePaginationOnChange}
                            defaultPage={emailTemPagination?.currentPage}
                        />
                    ) : null}
                </Box>
            )}
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,
        emailTemplatesData: state?.email?.emailTemplatesData,
        emailTemPagination: state?.email?.emailTemPagination,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getEmailTemplatesData,
            deleteTemplate,
            setSelectedDetails,
            setCurrentPage,
            setCustomerPageLimit,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailTemplates);
