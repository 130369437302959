import React, { useState } from "react";
import TableCell from "@mui/material/TableCell";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import TableRow from "@mui/material/TableRow";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOffOutlinedIcon from "@mui/icons-material/PersonOffOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import IconButton from "@mui/material/IconButton";
import TableComponent from "../../components/Table/TableComponent";
import TooltipComponent from "../../components/ToolTip/TooltipComponent";
import { connect } from "react-redux";
import { Translate } from "react-auto-translate";
function UserTableView(props) {
    const {
        usersList,
        handleDelete,
        handleEdit,
        handleSettings,
        fetchRoleName,
        userLoggedIn,
        status
    } = props;

    const [state, setState] = useState({
        filteredData: usersList,
        sortConfig: { key: null, direction: "asc" },
    });
    const { filteredData, sortConfig } = state;

    const handleSort = (property) => {
        let sortedData = [...usersList].sort((a, b) => {
            if (property !== "created_date") {
                return sortConfig.direction === "desc"
                    ? a?.[property]?.toLowerCase() <
                      b?.[property]?.toLowerCase()
                        ? 1
                        : b?.[property]?.toLowerCase() <
                          a?.[property]?.toLowerCase()
                        ? -1
                        : 0
                    : a?.[property]?.toLowerCase() >
                      b?.[property]?.toLowerCase()
                    ? 1
                    : b?.[property]?.toLowerCase() >
                      a?.[property]?.toLowerCase()
                    ? -1
                    : 0;
            } else {
                return sortConfig.direction === "desc"
                    ? new Date(a?.[property]) - new Date(b?.[property])
                    : new Date(b?.[property]) - new Date(a?.[property]);
            }
        });
        setState((prevState) => ({
            ...prevState,
            filteredData: sortedData,
            sortConfig: {
                direction: sortConfig.direction === "desc" ? "asc" : "desc",
            },
        }));
    };

    const headerList = [
        // {
        //     id: 1,
        //     name:
        //         userLoggedIn?.language_data?.customer?.first_name ||
        //         "First Name",
        //     headerClass: "userTableHeading",
        //     accessibleKey: "first_name",
        //     rowClass: "typo4",
        //     headerAlign: "center",
        //     rowAlign: "center",
        //     sort: true,
        //     sortFunction: handleSort,
        // },
        // {
        //     id: 2,
        //     name:
        //         userLoggedIn?.language_data?.customer?.last_name || "Last Name",
        //     headerClass: "userTableHeading",
        //     accessibleKey: "last_name",
        //     rowClass: "typo4",
        //     headerAlign: "center",
        //     rowAlign: "center",
        //     sort: true,
        //     sortFunction: handleSort,
        // },
        // {
        //     id: 3,
        //     name: <Translate>Title</Translate>,
        //     headerClass: "userTableHeading",
        //     accessibleKey: "user_title",
        //     rowClass: "typo4",
        //     headerAlign: "center",
        //     rowAlign: "center",
        //     sort: true,
        //     sortFunction: handleSort,
        // },
        {
            id: 3,
            name: (
                <Translate>
                    {userLoggedIn?.language_data?.customer?.user_name ||
                        "User Name"}
                </Translate>
            ),
            headerClass: "userTableHeading",
            accessibleKey: "first_name",
            rowClass: "typo4",
            headerAlign: "center",
            rowAlign: "center",
            sort: true,
            sortFunction: handleSort,
        },
        {
            id: 4,
            name: <Translate>Email</Translate>,
            headerClass: "userTableHeading",
            accessibleKey: "email",
            rowClass: "typo4",
            headerAlign: "center",
            rowAlign: "center",
            sort: true,
            sortFunction: handleSort,
        },
        {
            id: 5,
            name: <Translate>Role</Translate>,
            headerClass: "userTableHeading",
            accessibleKey: "role",
            rowClass: "typo3",
            headerAlign: "center",
            rowAlign: "center",
            sort: true,
            sortFunction: handleSort,
        },
        {
            id: 6,
            name: <Translate>Reporting Manager</Translate>,
            headerClass: "userTableHeading",
            accessibleKey: "reporting_manager",
            rowClass: "typo4",
            headerAlign: "center",
            rowAlign: "center",
            sort: false,
        },
        {
            id: 7,
            name: <Translate>Compliance Services</Translate>,
            headerClass: "complianceUserTable userTableHeading",
            accessibleKey: "compliance_service",
            rowClass: "customerName",
            headerAlign: "center",
            rowAlign: "center",
            sort: false,
        },
    ];

    return (
        <TableComponent
            headerList={headerList}
            tableList={usersList}
            // serialNo={{
            //     name: <Translate>S.No.</Translate>,
            //     headerClass: "tableHeading",
            //     headerAlign: "center",
            //     rowClass: "typo4",
            //     rowAlign: "center",
            // }}
            sortConfig={sortConfig}
            extraHeaderRight={
                <TableCell
                    align="center"
                    className="actionUserTable userTableHeading"
                >
                    <Translate>Action</Translate>
                </TableCell>
            }
        >
            {filteredData?.length &&
                filteredData?.map((row, index) => (
                    <TableRow
                        key={row.name}
                        className={index % 2 !== 0 ? "tertiaryBackground" : ""}
                    >
                        {/* <TableCell align="center" className="typo4">
                            {index + 1}
                        </TableCell> */}
                        {headerList?.map((obj) => (
                            <TableCell
                                component="th"
                                key={obj?.name}
                                align={obj?.rowAlign}
                                className={
                                    obj?.rowClass
                                        ? obj?.rowClass
                                        : row?.status === "active"
                                        ? "typo3"
                                        : "typo5"
                                }
                                onClick={() =>
                                    (obj?.handleButton &&
                                        obj?.handleButton(
                                            row,
                                            obj?.buttonType
                                        )) ||
                                    null
                                }
                            >
                                {obj?.accessibleKey === "reporting_manager"
                                    ? `${
                                          row[obj?.accessibleKey]?.first_name +
                                          " " +
                                          row[obj?.accessibleKey]?.last_name
                                      }`
                                    : obj?.accessibleKey === "first_name"
                                    ? `${
                                          row?.first_name + " " + row?.last_name
                                      }`
                                    : obj?.id === 4
                                    ? fetchRoleName(row[obj?.accessibleKey])
                                    : row[obj?.accessibleKey]}
                            </TableCell>
                        ))}
                        <TableCell align="center">
                            {
                                status == 'inactive' ? null : 
                                <>
                                <TooltipComponent
                                title={
                                    <Translate>
                                        {userLoggedIn?.language_data?.user
                                            ?.view_user || "View User"}
                                    </Translate>
                                }
                                arrow={true}
                            >
                                <IconButton
                                    className="iconButton"
                                    onClick={() => handleEdit(row, "view")}
                                >
                                    <RemoveRedEyeOutlinedIcon />
                                </IconButton>
                            </TooltipComponent>
                            <TooltipComponent
                                title={
                                    <Translate>
                                        {userLoggedIn?.language_data?.user
                                            ?.manage_user || "Edit User"}
                                    </Translate>
                                }
                                arrow={true}
                            >
                                <IconButton
                                    className="iconButton"
                                    onClick={() => handleEdit(row, "edit")}
                                >
                                    <EditOutlinedIcon />
                                </IconButton>
                            </TooltipComponent>
                            </>
                            }
                            
                            {userLoggedIn?.user &&
                            
                                row?.id !== userLoggedIn?.user?.id &&    (
                                    (userLoggedIn?.user?.role_id === 44 && row?.role_id === 3) ? null : (
                                    <TooltipComponent
                                        title={
                                            row?.status === "active" ? (
                                                <Translate>
                                                    Deactivate User 
                                                </Translate>
                                            ) : (
                                                <Translate>
                                                    Activate User
                                                </Translate>
                                            )
                                        }
                                        arrow={true}
                                    >
                                        <IconButton
                                            className="iconButton"
                                            onClick={() =>
                                                handleDelete(row, "delete")
                                            }
                                        >
                                            {row?.status === "active" ? (
                                                <PersonOffOutlinedIcon />
                                            ) : (
                                                <ManageAccountsOutlinedIcon />
                                            )}
                                        </IconButton>
                                    </TooltipComponent>
                                    )
                                )}

                            <TooltipComponent
                                title={<Translate>Settings</Translate>}
                                arrow={true}
                            >
                                <IconButton
                                    className="iconButton"
                                    onClick={() => handleSettings(row)}
                                >
                                    <SettingsOutlinedIcon />
                                </IconButton>
                            </TooltipComponent>
                        </TableCell>
                    </TableRow>
                ))}
        </TableComponent>
    );
}

const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,
    };
};

export default connect(mapStateToProps, null)(UserTableView);
