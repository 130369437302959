import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import Navbar from "../../common/Header/Navbar";
import PageHeader from "../../common/PageHeader";
import TableComponent from "../../components/Table/TableComponent";
import NoInfoAvailable from "../../components/NoInfoAvailable";
import CommonDeleteDialog from "./../../components/DialogsBox/CommonDeleteDialog";
import FooterPagination from "../../common/PaginationSection/FooterPagination";
import TooltipComponent from "../../components/ToolTip/TooltipComponent";
import LoadingComponent from "../../components/Loading/LoadingComponent";
import { ReactComponent as DeleteIcon } from "../../../assets/Images/Icons/DeleteIcon.svg";
import { ReactComponent as EditIcon } from "../../../assets/Images/Icons/EditIcon.svg";
import { getContractsContentAction } from "../../store/Action/ContractAction";
import {
    getContractInUsActionList,
    addContractInUsAction,
    deleteSignedDocument,
    getSignedDocumentData,
    getSignedDocumentById,
    setCurrentPage,
    setCustomerPageLimit,
    getCategoryDropdown,
} from "../../store/Action/SignedDocumentAction";
import { ROUTES } from "./../../router/RouteList";
import { setSelectedDetails } from "../../store/Action/SignedDocumentAction";
import { permissionDenied } from "../../utils/CommonUtils";
import HeaderPagination from "../../common/PaginationSection/HeaderPagination";
import "../../../assets/styles/Container/SignedDocument.scss";
import { pageLimit } from "../../utils/CommonData";
import {Translate } from 'react-auto-translate';
function ContractDatabase(props) {
    const navigate = useNavigate();
    const {
        deleteSignedDocument,
        getSignedDocumentData,
        signedDocuments,
        getSignedDocumentById,
        userLoggedIn,
        signedDocPagination,
        setCurrentPage,
        setCustomerPageLimit,
        getCategoryDropdown,
    } = props;

    const [state, setState] = useState({
        openDeleteDialog: false,
        deleteData: null,
        openAddDialog: false,
        openSuccesfullModel: false,
        modelContent: "",
        id: null,
        address: { state: "" },
        isLoading: false,
        filteredData: null,
        sortConfig: { key: null, direction: "asc" },
    });
    const [search, setSearch] = useState([])

    const {
        openDeleteDialog,
        deleteData,
        isLoading,
        filteredData,
        sortConfig,
    } = state;

    const permission =
        userLoggedIn?.scope?.length &&
        userLoggedIn?.scope?.find((obj) => obj.prefix === "signed-document")
            ?.role_permissions;

    useEffect(() => {
        async function callApi() {
            setState((prevState) => ({ ...prevState, isLoading: true }));
            await getSignedDocumentData(null, pageLimit, true, search);
            await getCategoryDropdown();
            setState((prevState) => ({ ...prevState, isLoading: false }));
        }
        if (signedDocuments === null) callApi();
        if (signedDocuments) {
            setState((prevState) => ({
                ...prevState,
                filteredData: signedDocuments,
            }));
            handleSort("document_name");
        }
    }, [getSignedDocumentData, signedDocuments, getCategoryDropdown]);

    const closeDelete = () => {
        setState((prevState) => ({ ...prevState, openDeleteDialog: false }));
    };
    const handleDelete = (data) => {
        setState((prevState) => ({
            ...prevState,
            deleteData: data,
            openDeleteDialog: true,
        }));
    };
    const handleDeleteAction = async (data) => {
        closeDelete();
        setState((prevState) => ({ ...prevState, isLoading: true }));
        await deleteSignedDocument(data);
        setState((prevState) => ({ ...prevState, isLoading: false }));
    };
    const handleClickOpen = async () => {
        setState((prevState) => ({ ...prevState, isLoading: true }));
        await getSignedDocumentById(null);
        setState((prevState) => ({ ...prevState, isLoading: false }));
        navigate(ROUTES.ADD_SIGNED_DOCUMENT);
    };
    async function signedDocumentView(data, mode) {
        setState((prevState) => ({ ...prevState, isLoading: true }));
        await getSignedDocumentById(data?.id, mode);
        setState((prevState) => ({ ...prevState, isLoading: false }));
        navigate(`${ROUTES.VIEW_SIGNED_DOCUMENT}?${data?.id}`, {
            state: { ...data, mode: mode },
        });
    }
    async function signedDocumentEdit(data, mode) {
        setState((prevState) => ({ ...prevState, isLoading: true }));
        await getSignedDocumentById(data?.id, mode);
        setState((prevState) => ({ ...prevState, isLoading: false }));
        navigate(`${ROUTES.EDIT_SIGNED_DOCUMENT}?${data?.id}`, {
            state: { ...data, mode: mode },
        });
    }
    const renderClassStatus = (obj, row) => {
        if (obj?.rowClass) return obj?.rowClass;
        else if (row?.status_name === "Approved") return "cellApproved";
        else if (
            row?.status_name?.replace(/\s/g, "") === "PendingforApproval" ||
            row?.status_name === "Others"
        )
            return "cellPending";
        else if (
            row?.status_name?.replace(/\s/g, "") === "OnHold" ||
            row?.status_name === "Rejected" ||
            row?.status_name === "Reassigned"
        )
            return "cellOnHold";
    };

    const handleSort = (property) => {
        let sortedData = [...signedDocuments].sort((a, b) => {
            if (property !== "created_at") {
                return sortConfig.direction === "desc"
                    ? a?.[property]?.toLowerCase() <
                      b?.[property]?.toLowerCase()
                        ? 1
                        : b?.[property]?.toLowerCase() <
                          a?.[property]?.toLowerCase()
                        ? -1
                        : 0
                    : a?.[property]?.toLowerCase() >
                      b?.[property]?.toLowerCase()
                    ? 1
                    : b?.[property]?.toLowerCase() >
                      a?.[property]?.toLowerCase()
                    ? -1
                    : 0;
            } else {
                return sortConfig.direction === "desc"
                    ? new Date(a?.[property]) - new Date(b?.[property])
                    : new Date(b?.[property]) - new Date(a?.[property]);
            }
        });
        setState((prevState) => ({
            ...prevState,
            filteredData: sortedData,
            sortConfig: {
                direction: sortConfig.direction === "desc" ? "asc" : "desc",
            },
        }));
    };

    const headerList = [
        {
            id: 1,
            name: <Translate>{userLoggedIn?.language_data?.documentdatabase?.document_name || "Document Name"}</Translate>,
            headerClass: "tableHeading",
            accessibleKey: "document_name",
            rowClass: "nameFile",
            headerAlign: "center",
            rowAlign: "center",
            sort: true,
            sortFunction: handleSort,
        },
        {
            id: 2,
            name: <Translate>{userLoggedIn?.language_data?.documentdatabase?.document_category || "Document Category"}</Translate>,
            headerClass: "tableHeading",
            accessibleKey: "category",
            rowClass: "tableBodyRow",
            headerAlign: "center",
            rowAlign: "center",
            sort: false,
        },
        {
            id: 3,
            name: <Translate>{userLoggedIn?.language_data?.comman?.assign_to || "Assign To"}</Translate>,
            headerClass: "tableHeading Assign ToTableHeading",
            accessibleKey: "assign_user",
            rowClass: "companyName",
            headerAlign: "center",
            rowAlign: "center",
            sort: false,
        },
        {
            id: 4,
            name: <Translate>{userLoggedIn?.language_data?.comman?.status || "Status"}</Translate>,
            headerClass: "tableHeading",
            accessibleKey: "status_name",
            headerAlign: "center",
            rowAlign: "center",
            sort: true,
            sortFunction: handleSort,
        },
        {
            id: 5,
            name: <Translate>{userLoggedIn?.language_data?.comman?.approved_by || "Approved By"}</Translate>,
            accessibleKey: "approved_by",
            headerClass: "tableHeading",
            headerAlign: "center",
            rowAlign: "center",
            sort: false,
        },
        {
            id: 6,
            name: <Translate>Time Stamp</Translate>,
            headerClass: "tableHeading noOfUserTableHeading",
            accessibleKey: "created_at",
            rowClass: "stampTime",
            headerAlign: "center",
            rowAlign: "center",
            sort: true,
            sortFunction: handleSort,
        },
    ];


    function handleSearch(data) {
        getSignedDocumentData(
            null,
            signedDocPagination?.listViewLimit,
            true,
            search)
            setState((prevState) => ({
                ...prevState,
                filteredData: signedDocuments,
                search
            }));
    }

    const handleViewLimitChange = async (data) => {
        setCustomerPageLimit(data?.target?.value);
        setState((prevState) => ({ ...prevState, isLoading: true }));
        await getSignedDocumentData(null, data?.target?.value, true, search);
        setState((prevState) => ({ ...prevState, isLoading: false }));
    };

    const handlePaginationOnChange = async (_, pageNumber) => {
        setCurrentPage(pageNumber);
        setState((prevState) => ({ ...prevState, isLoading: true }));
        await getSignedDocumentData(
            pageNumber,
            signedDocPagination?.listViewLimit,
            true,
            search
        );
        setState((prevState) => ({ ...prevState, isLoading: false }));
    };

    useEffect(() => {
        getSignedDocumentData(
            signedDocPagination?.currentPage,
            signedDocPagination?.listViewLimit,
            true,
            search
        );
    }, [])
    return (
        <>
        <Navbar />

        <CommonDeleteDialog
            open={openDeleteDialog}
            title={'Delete Signed Document'}
            cancelButtonAction={closeDelete}
            cancelButtonTitle={'Cancel'}
            submitButtonTitle={'Delete'}
            content={
                    `Are you sure, you want to remove the ({deleteData?.document_name}) from the Signed Document?`
            }
            submitButtonAction={handleDeleteAction}
            submitButtonParameter={deleteData}
        />
        {!signedDocuments || isLoading ? (
            <LoadingComponent />
        ) : (
            <Box sx={{ px: { md: 7, xs: 1, mt: "40px" } }}>
                <PageHeader
                    title={<Translate>Signed Document in US</Translate>}
                    showSearchBar={true}
                    buttonTitle={<Translate>New Signed Document</Translate>}
                    handleAdd={
                        permission?.create
                            ? handleClickOpen
                            : permissionDenied
                    }
                    handleSearch={handleSearch}
                    setSearch={setSearch}
                />
                <HeaderPagination
                    showViewButton={false}
                    total={signedDocPagination?.total}
                    listViewLimit={signedDocPagination?.listViewLimit}
                    handleViewLimitChange={handleViewLimitChange}
                    showPagination={pageLimit < signedDocPagination?.total}
                />
                {filteredData?.length === 0 ? (
                    <NoInfoAvailable
                        className="noCustomerInfo"
                        noInfoTypo={<Translate>noInfoTypeQuestion</Translate>}
                    />
                ) : (
                    <TableComponent
                        headerList={headerList}
                        // serialNo={{
                        //     name: <Translate>S.No.</Translate>,
                        //     headerClass: "tableHeading",
                        //     headerAlign: "center",
                        //     rowClass: "typo4",
                        //     rowAlign: "center",
                        // }}
                        sortConfig={sortConfig}
                        extraHeaderRight={
                            <TableCell
                                align="center"
                                className="tableHeading actionTableHeading"
                            >
                                <Translate>Action</Translate>
                            </TableCell>
                        }
                    >
                        {filteredData?.length !== 0 &&
                            filteredData?.map((row, index) => (
                                <TableRow
                                    key={row?.name}
                                    className={
                                        index % 2 !== 0
                                            ? "tertiaryBackground"
                                            : ""
                                    }
                                >
                                    {/* <TableCell
                                        align="center"
                                        className="typo4"
                                    >
                                        {index + 1}
                                    </TableCell> */}
                                    {headerList?.map((obj) => (
                                        <TableCell
                                            component="th"
                                            key={obj?.name}
                                            align={obj?.rowAlign}
                                            className={renderClassStatus(
                                                obj,
                                                row
                                            )}
                                        >
                                            {obj?.id === 5 &&
                                            !row?.approved_by
                                                ? <Translate>Not Assigned</Translate>
                                                : <Translate>{row?.[obj?.accessibleKey]}</Translate>}
                                        </TableCell>
                                    ))}
                                    <TableCell sx={{ textAlign: "center" }}>
                                        {row?.action}
                                        <TooltipComponent
                                            title={<Translate>View Signed Document</Translate>}
                                            arrow={true}
                                        >
                                            <IconButton
                                                className="iconButton"
                                                onClick={() =>
                                                    permission?.read
                                                        ? signedDocumentView(
                                                              row,
                                                              "view"
                                                          )
                                                        : permissionDenied()
                                                }
                                            >
                                                <RemoveRedEyeOutlinedIcon />
                                            </IconButton>
                                        </TooltipComponent>
                                        <TooltipComponent
                                            title={<Translate>Edit Signed Document</Translate>}
                                            arrow={true}
                                        >
                                            <IconButton
                                                className="iconButton"
                                                onClick={() =>
                                                    permission?.edit
                                                        ? signedDocumentEdit(
                                                              row,
                                                              "edit"
                                                          )
                                                        : permissionDenied()
                                                }
                                            >
                                                <EditIcon />
                                            </IconButton>
                                        </TooltipComponent>
                                        <TooltipComponent
                                            title={<Translate>Delete Signed Doucment</Translate>}
                                            arrow={true}
                                        >
                                            <IconButton
                                                className="iconButton"
                                                onClick={() =>
                                                    permission?.delete
                                                        ? handleDelete(row)
                                                        : permissionDenied()
                                                }
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </TooltipComponent>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableComponent>
                )}
                {pageLimit < signedDocPagination?.total ? (
                    <FooterPagination
                        count={signedDocPagination?.lastPage}
                        page={signedDocPagination?.currentPage}
                        onChange={handlePaginationOnChange}
                        defaultPage={signedDocPagination?.currentPage}
                    />
                ) : null}
            </Box>
        )}
    </>

    );
}

const mapStateToProps = (state) => {
    return {
        customerDropdown: state?.auth?.commonDropdown?.states,
        signedDocuments: state?.signedDocs?.signedDocuments,
        signedDocPagination: state?.signedDocs?.signedDocPagination,
        userLoggedIn: state?.auth?.userLoggedIn,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            addContractInUsAction,
            getContractInUsActionList,
            deleteSignedDocument,
            getContractsContentAction,
            getSignedDocumentData,
            setSelectedDetails,
            getSignedDocumentById,
            setCurrentPage,
            setCustomerPageLimit,
            getCategoryDropdown,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ContractDatabase);
