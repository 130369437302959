import React, { useEffect, useState, Fragment } from "react";
import { useLocation } from "react-router-dom";
import Section from "../../CustomerSetting/Rcp/AgreementSettings/Section";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getCommentsAction } from "../../../../store/Action/CommentsAction";
import { rcpTasksAction } from "../../../../store/Action/RcpTasksAction";
import Link from "@mui/material/Link";
import { Box, Typography, Grid, Paper } from "@mui/material";
import TextField from "@mui/material/TextField";
import {
    temp_text,
    heading1_text,
    heading2_text,
    style,
    textField_style,
} from "../Style";
import "../../../../../assets/styles/Container/AccordionCustom.scss";
import TopBar from "../../../../common/TopBar";
import ContractorInformation from "./ContractorInformation";
import AssessmentParties from "../../CustomerSetting/Rcp/AssessmentParties/AssessmentParties";
import RcpTasks from "../RcpTasks";
import ContractToBeSigned from "../FooterComponents/SignedDocument/ContractToBeSigned";
import { getCustomerById } from "../../../../store/Action/CustomerAction";
import { getUserById } from "../../../../store/Action/UserAction";
import { getDocumentsAction } from "../../../../store/Action/DocumentsAction";
import { getContractsAction } from "../../../../store/Action/ContractAction";
import { getContractInUsActionList } from "../../../../store/Action/SignedDocumentAction";
import DocumentToBeCollected from "../../DocumentCollection/DocumentToBeCollected";
import FinalReview from "./FinalReview";
import AssessmentSummary from "./AssessmentSummary";
import Navbar from "../../../../common/Header/Navbar";
import ContextProvider from "../../../../components/Context/ContextProvider";
import LoadingComponent from "../../../../components/Loading/LoadingComponent";
import ExpandAllButton from "../../../../components/ExpandAllButton";
import Footer from "../../../../common/Footer/Footer";
import { getFormforAssesment } from "../../../../store/Action/FormStudioAction";
import RenderElements from "../../../FormStudio/RenderElements";
import moment from "moment";
import DctAssesmentAction from "../../../../store/Action/DctAssesmentAction";
import GetDctAssesmentAction from "../../../../store/Action/GetDctAssesmentAction";
import { Translate } from "react-auto-translate";

function CustomerInformation(props) {
    const {
        userLoggedIn,
        getContractInUsActionList,
        getCustomerById,
        getUserById,
        rcpTasksAction,
        getDocumentsAction,
        getContractsAction,
        customer,
        formDetail,
        getFormforAssesment,
        user,
        DctAssesmentAction,
        GetDctAssesmentAction,
        submitResponse,
        fetchFormData,
    } = props;
    const [state, setState] = useState({
        popperOpen: false,
        anchorEl: null,
        formData: formDetail?.form_data,
        fontColor: formDetail?.font_color || "#76520E",
        formColor: formDetail?.form_color || "#F2F2F2",
        pageColor: formDetail?.page_color || "",
        isLoading: false,
        activeLink: "",
        logo: null,
        isRead: false,
        isEdit: true,
        toggle: false,
        task_id: null,
        mode: null,
    });
    const [customExpand, setCustomExpand] = useState(false);

    useEffect(
        (prevState) => {
            setState({
                ...prevState,
                formData: formDetail?.form_data,
                fontColor: formDetail?.font_color,
                formColor: formDetail?.form_color,
                pageColor: formDetail?.page_color,
            });
        },
        [formDetail]
    );
    const location = useLocation();
    const urlData = location.pathname?.split("/");
    const managerRoleId = location?.state?.manager_role_id;
    const id = urlData[3];
    const guid = urlData[2];
    localStorage.setItem("id", id);
    const [toggale, setToggle] = useState(false);
    const [customerinfo, setCustomerInfo] = useState({});
    const [userDetails, setUserDetails] = useState({});
    const [documentList, setDocumentList] = useState([]);
    const [contractList, setContractList] = useState([]);
    const [tasks, setTasks] = useState([]);
    const [setting, setSetting] = useState({
        expand: false,
    });
    const { formData, isRead, isEdit, task_id, mode } = state;
    const [isLoading, setLoading] = useState(false);
    useEffect(() => {
        getContractInUsActionList();
        getFormforAssesment();
        if (customer?.selectedCustomer) {
            setCustomerInfo(customer.selectedCustomer);
            initilizeStates(guid);
            getTasks(guid);
        } else {
            setCustomer(guid);
        }
    }, [toggale, guid, id]);
    useEffect(() => {
        if (location?.state?.mode) {
            setState((prevState) => ({
                ...prevState,
                task_id: location?.state?.task_id,
                mode: location?.state?.mode,
            }));
        }
    }, [location?.state?.task_id, location?.state?.mode]);
    console.log("state", state);
    async function setCustomer(guid) {
        await getCustomerById(guid);
        setCustomerInfo(customer.selectedCustomer);
        await getUserById(
            location?.state.contractor_ID ? location?.state.contractor_ID : id
        );
    }
    async function initilizeStates(guid) {
        const docRes = await getDocumentsAction(
            guid,
            location?.state.contractor_ID ? location?.state.contractor_ID : id
        );
        setDocumentList(docRes?.payload);
        const contractRes = await getContractsAction(
            guid,
            location?.state.contractor_ID ? location?.state.contractor_ID : id
        );
        setContractList(contractRes?.payload);
        // await getUserList(customer?.selectedCustomer?.id);
    }
    async function getTasks(guid, id) {
        const getTasks = await rcpTasksAction(
            guid,
            location?.state.contractor_ID ? location?.state.contractor_ID : id
        );
        setTasks(getTasks?.payload);
    }

    const handlerFieldChange = (event, id, name = null) => {
        let copyFormDetails = formData;
        let index = copyFormDetails?.findIndex((obj) => obj.id === id);
        if (index !== -1) {
            let value;
            if (name) {
                if (name == "date") {
                    let date = new Date(event);
                    value = moment(date).format("YYYY-MM-DD");
                }
                if (name == "time") {
                    value = {
                        formatedTime: moment(event["$d"]).format("LT"),
                        timeEvent: event,
                    };
                }
                if (name == "signature") {
                    value = event;
                }
                if (name == "checkBoxOption") {
                    const index = state.formData.findIndex((item) => {
                        return item.id == id;
                    });
                    let options = [];
                    if (index !== -1) {
                        options =
                            state?.formData[index]?.formValues
                                ?.checkBoxOption || [];
                        if (options.includes(event.target.value)) {
                            options = options.filter((item) => {
                                return item !== event.target.value;
                            });
                        } else {
                            options.push(event.target.value);
                        }
                    } else {
                        options.push(event.target.value);
                    }
                    let obj = { id: id, data: options };
                    let arr = state?.formValues?.checkBoxOption || [];
                    arr = [...arr, ...options];
                    value = arr;
                }
                copyFormDetails[index] = {
                    ...copyFormDetails[index],
                    formValues: {
                        ...copyFormDetails[index]?.formValues,
                        [name]: value,
                    },
                };
            } else {
                copyFormDetails[index] = {
                    ...copyFormDetails[index],
                    formValues: {
                        ...copyFormDetails[index]?.formValues,
                        [event.target.name]: event.target.value,
                    },
                };
            }
        }
        setState((prevState) => ({ ...prevState, formData: copyFormDetails }));
    };
    const getFormData = async () => {
        const matchingObject =
            fetchFormData &&
            fetchFormData?.find(
                (item) => item.user.id === userLoggedIn?.user?.id
            );
        await GetDctAssesmentAction(formDetail?.id);

        if (matchingObject) {
            setState((prevState) => ({
                ...prevState,
                formData: matchingObject?.data,
                activeLink: matchingObject?.id,
                isRead: true,
                isEdit: false,
            }));
        }
    };

    useEffect(() => {
        getFormData();
    }, [userLoggedIn, formDetail?.id]);

    const submitSubmission = async () => {
        try {
            await DctAssesmentAction(formData, formDetail?.id);

            let fetchFormData = null;
            const response = await GetDctAssesmentAction(formDetail?.id);

            if (response) {
                fetchFormData = response?.payload?.result[0];
            }
            setState((prevState) => ({
                ...prevState,
                formData: fetchFormData && fetchFormData?.data,
                activeLink: fetchFormData && fetchFormData?.id,
                isRead: true,
                isEdit: false,
            }));
        } catch (error) {}
    };

    return (
        <Fragment>
            {!customer.selectedCustomer || isLoading ? (
                <LoadingComponent sx={{ height: "600px" }} />
            ) : (
                <>
                    <Navbar />
                    <TopBar
                        getTasks={getTasks}
                        setToggle={setToggle}
                        toggale={toggale}
                    />
                    <Box ml={6} sx={{ display: "flex", flexDirection: "row" }}>
                        <Grid container spacing={2}>
                            <Grid item md={12} ml={1} disablePadding>
                                <ExpandAllButton
                                    setState={setSetting}
                                    expand={setting.expand}
                                />
                            </Grid>
                            <Grid item md={9} xs={12} xl={9}>
                                <ContextProvider.Provider
                                    value={setting.expand}
                                >
                                    {localStorage.getItem("role_id") ==
                                    2 ? null : (
                                        <Section
                                            title={"Customer Information"}
                                            guid={guid}
                                            className={"mb-15"}
                                            showComment={false}
                                        >
                                            <Grid
                                                container
                                                mt={1}
                                                justifyContent="flex-end"
                                            >
                                                <Grid item md={3} sm={5} xs={6}>
                                                    <Grid
                                                        container
                                                        direction={"column"}
                                                    >
                                                        <Grid item>
                                                            {customer
                                                                .selectedCustomer
                                                                .logo ===
                                                            null ? (
                                                                "Company Logo"
                                                            ) : (
                                                                <img
                                                                    alt="logo"
                                                                    src={
                                                                        customer
                                                                            .selectedCustomer
                                                                            .logo
                                                                    }
                                                                    width="48px"
                                                                />
                                                            )}
                                                        </Grid>
                                                        <Grid item>
                                                            <Link
                                                                href={
                                                                    customer
                                                                        .selectedCustomer
                                                                        .company_info
                                                                        .website
                                                                }
                                                                target="_blank"
                                                                sx={{
                                                                    ...heading1_text,
                                                                    textDecorationLine:
                                                                        "underline",
                                                                }}
                                                            >
                                                                {
                                                                    customer
                                                                        .selectedCustomer
                                                                        .company_info
                                                                        .name
                                                                }
                                                            </Link>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container mb={2}>
                                                <Grid
                                                    item
                                                    md={2}
                                                    sm={2}
                                                    xs={12}
                                                >
                                                    <Typography
                                                        style={heading2_text}
                                                    >
                                                        {userLoggedIn
                                                            ?.language_data
                                                            ?.complianceworkflow
                                                            ?.conductor_type ||
                                                            "Conductor Type"}
                                                        :
                                                    </Typography>
                                                </Grid>
                                                <Grid
                                                    item
                                                    md={3}
                                                    sm={1}
                                                    xs={12}
                                                >
                                                    <Typography
                                                        style={temp_text}
                                                        sx={{ pl: "0" }}
                                                    >
                                                        {" "}
                                                        {customer
                                                            .selectedCustomer
                                                            .client_type ===
                                                        "dc"
                                                            ? userLoggedIn
                                                                  ?.language_data
                                                                  ?.customer
                                                                  ?.direct_conductoe ||
                                                              "Direct Conductor"
                                                            : "Indirect Conductor"}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Typography
                                                style={heading2_text}
                                                sx={{ mb: 1 }}
                                            >
                                                {userLoggedIn?.language_data
                                                    ?.customer
                                                    ?.customer_profile ||
                                                    "Customer Profile"}
                                            </Typography>
                                            <Grid container spacing={2}>
                                                <Grid
                                                    item
                                                    md={6}
                                                    sm={6}
                                                    xs={12}
                                                >
                                                    <Box sx={{ ...style }}>
                                                        <TextField
                                                            required
                                                            variant="standard"
                                                            id="first-name-required"
                                                            label={
                                                                userLoggedIn
                                                                    ?.language_data
                                                                    ?.customer
                                                                    ?.first_name ||
                                                                "First Name"
                                                            }
                                                            sx={{
                                                                "& .MuiFormLabel-root":
                                                                    {
                                                                        fontSize:
                                                                            "16px",
                                                                    },
                                                            }}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            InputProps={{
                                                                disableUnderline: true,
                                                                readOnly: true,
                                                                style: {
                                                                    ...textField_style,
                                                                },
                                                            }}
                                                            value={
                                                                customer
                                                                    .selectedCustomer
                                                                    .first_name ===
                                                                null
                                                                    ? "-"
                                                                    : customer
                                                                          .selectedCustomer
                                                                          .first_name
                                                            }
                                                        />
                                                    </Box>
                                                </Grid>
                                                {/* <Grid item md={6} sm={6} xs={12}>
                                                <Box sx={{ ...style }}>
                                                    <TextField
                                                        required
                                                        variant="standard"
                                                        id="middle-name-required"
                                                        label={
                                                            userLoggedIn
                                                                ?.language_data
                                                                ?.customer
                                                                ?.middle_name ||
                                                            "Middle Name"
                                                        }
                                                        sx={{
                                                            "& .MuiFormLabel-root":
                                                                {
                                                                    fontSize:
                                                                        "18px",
                                                                },
                                                        }}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        InputProps={{
                                                            disableUnderline: true,
                                                            readOnly: true,
                                                            style: {
                                                                ...textField_style,
                                                            },
                                                        }}
                                                        value={
                                                            customer
                                                                .selectedCustomer
                                                                .middle_name ===
                                                            null
                                                                ? "-"
                                                                : customer
                                                                      .selectedCustomer
                                                                      .middle_name
                                                        }
                                                    />
                                                </Box>
                                            </Grid> */}
                                                <Grid
                                                    item
                                                    md={6}
                                                    sm={6}
                                                    xs={12}
                                                >
                                                    <Box sx={{ ...style }}>
                                                        <TextField
                                                            required
                                                            variant="standard"
                                                            id="last-name-required"
                                                            label={
                                                                userLoggedIn
                                                                    ?.language_data
                                                                    ?.customer
                                                                    ?.last_name ||
                                                                "Last Name"
                                                            }
                                                            sx={{
                                                                "& .MuiFormLabel-root":
                                                                    {
                                                                        fontSize:
                                                                            "18px",
                                                                    },
                                                            }}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            InputProps={{
                                                                disableUnderline: true,
                                                                readOnly: true,
                                                                style: {
                                                                    ...textField_style,
                                                                },
                                                            }}
                                                            value={
                                                                customer
                                                                    .selectedCustomer
                                                                    .last_name ===
                                                                null
                                                                    ? "-"
                                                                    : customer
                                                                          .selectedCustomer
                                                                          .last_name
                                                            }
                                                        />
                                                    </Box>
                                                </Grid>
                                                {/* <Grid item md={6} sm={6} xs={12}>
                                                <Box sx={{ ...style }}>
                                                    <TextField
                                                        required
                                                        variant="standard"
                                                        id="display-name-required"
                                                        label={
                                                            userLoggedIn
                                                                ?.language_data
                                                                ?.customer
                                                                ?.display_name ||
                                                            "Display Name"
                                                        }
                                                        sx={{
                                                            "& .MuiFormLabel-root":
                                                                {
                                                                    fontSize:
                                                                        "18px",
                                                                },
                                                        }}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        InputProps={{
                                                            disableUnderline: true,
                                                            readOnly: true,
                                                            style: {
                                                                ...textField_style,
                                                            },
                                                        }}
                                                        value={
                                                            customer
                                                                .selectedCustomer
                                                                .display_name ===
                                                            null
                                                                ? "-"
                                                                : customer
                                                                      .selectedCustomer
                                                                      .display_name
                                                        }
                                                    />
                                                </Box>
                                            </Grid> */}
                                                {/* <Grid item md={4} sm={6} xs={12}>
                                                <Box sx={{ ...style }}>
                                                    <TextField
                                                        required
                                                        variant="standard"
                                                        id="gender-required"
                                                        label={
                                                            userLoggedIn
                                                                ?.language_data
                                                                ?.customer
                                                                ?.gender ||
                                                            "Gender"
                                                        }
                                                        sx={{
                                                            "& .MuiFormLabel-root":
                                                                {
                                                                    fontSize:
                                                                        "18px",
                                                                },
                                                        }}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        InputProps={{
                                                            disableUnderline: true,
                                                            readOnly: true,
                                                            style: {
                                                                ...textField_style,
                                                            },
                                                        }}
                                                        value={
                                                            customer
                                                                .selectedCustomer
                                                                .gender === "m"
                                                                ? "Male"
                                                                : customer
                                                                      .selectedCustomer
                                                                      .gender ===
                                                                  "f"
                                                                ? "Female"
                                                                : ""
                                                        }
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid item md={4} sm={6} xs={12}>
                                                <Box sx={{ ...style }}>
                                                    <TextField
                                                        required
                                                        variant="standard"
                                                        id="dob-required"
                                                        label="Date of Birth"
                                                        sx={{
                                                            "& .MuiFormLabel-root":
                                                                {
                                                                    fontSize:
                                                                        "18px",
                                                                },
                                                        }}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        InputProps={{
                                                            disableUnderline: true,
                                                            readOnly: true,
                                                            style: {
                                                                ...textField_style,
                                                            },
                                                        }}
                                                        value={
                                                            customer
                                                                .selectedCustomer
                                                                .dob === null
                                                                ? "-"
                                                                : customer
                                                                      .selectedCustomer
                                                                      .dob
                                                        }
                                                    />
                                                </Box>
                                            </Grid> */}
                                            </Grid>
                                            <Typography
                                                style={heading2_text}
                                                sx={{ mt: 2, mb: 1 }}
                                            >
                                                Authorized signing party for the
                                                customer
                                            </Typography>
                                            <Grid container spacing={2}>
                                                <Grid
                                                    item
                                                    md={4}
                                                    sm={6}
                                                    xs={12}
                                                >
                                                    <Box sx={{ ...style }}>
                                                        <TextField
                                                            required
                                                            variant="standard"
                                                            id="first-required"
                                                            label={
                                                                userLoggedIn
                                                                    ?.language_data
                                                                    ?.customer
                                                                    ?.first_name ||
                                                                "First Name"
                                                            }
                                                            sx={{
                                                                "& .MuiFormLabel-root":
                                                                    {
                                                                        fontSize:
                                                                            "18px",
                                                                    },
                                                            }}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            InputProps={{
                                                                disableUnderline: true,
                                                                readOnly: true,
                                                                style: {
                                                                    ...textField_style,
                                                                },
                                                            }}
                                                            value={
                                                                customer
                                                                    .selectedCustomer
                                                                    .first_name ===
                                                                null
                                                                    ? "-"
                                                                    : customer
                                                                          .selectedCustomer
                                                                          .first_name
                                                            }
                                                        />
                                                    </Box>
                                                </Grid>
                                                {/* <Grid item md={4} sm={6} xs={12}>
                                                <Box sx={{ ...style }}>
                                                    <TextField
                                                        required
                                                        variant="standard"
                                                        id="middle-required"
                                                        label={
                                                            userLoggedIn
                                                                ?.language_data
                                                                ?.customer
                                                                ?.middle_name ||
                                                            "Middle Name"
                                                        }
                                                        sx={{
                                                            "& .MuiFormLabel-root":
                                                                {
                                                                    fontSize:
                                                                        "18px",
                                                                },
                                                        }}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        InputProps={{
                                                            disableUnderline: true,
                                                            readOnly: true,
                                                            style: {
                                                                ...textField_style,
                                                            },
                                                        }}
                                                        value={
                                                            customer
                                                                .selectedCustomer
                                                                .middle_name ===
                                                            null
                                                                ? "-"
                                                                : customer
                                                                      .selectedCustomer
                                                                      .middle_name
                                                        }
                                                    />
                                                </Box>
                                            </Grid> */}
                                                <Grid
                                                    item
                                                    md={4}
                                                    sm={6}
                                                    xs={12}
                                                >
                                                    <Box sx={{ ...style }}>
                                                        <TextField
                                                            required
                                                            variant="standard"
                                                            id="last-required"
                                                            label={
                                                                userLoggedIn
                                                                    ?.language_data
                                                                    ?.customer
                                                                    ?.last_name ||
                                                                "Last Name"
                                                            }
                                                            sx={{
                                                                "& .MuiFormLabel-root":
                                                                    {
                                                                        fontSize:
                                                                            "18px",
                                                                    },
                                                            }}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            InputProps={{
                                                                disableUnderline: true,
                                                                readOnly: true,
                                                                style: {
                                                                    ...textField_style,
                                                                },
                                                            }}
                                                            value={
                                                                customer
                                                                    .selectedCustomer
                                                                    .last_name ===
                                                                null
                                                                    ? "-"
                                                                    : customer
                                                                          .selectedCustomer
                                                                          .last_name
                                                            }
                                                        />
                                                    </Box>
                                                </Grid>
                                                <Grid
                                                    item
                                                    md={4}
                                                    sm={6}
                                                    xs={12}
                                                >
                                                    <Box sx={{ ...style }}>
                                                        <TextField
                                                            required
                                                            variant="standard"
                                                            id="email-required"
                                                            label={
                                                                userLoggedIn
                                                                    ?.language_data
                                                                    ?.customer
                                                                    ?.email_address ||
                                                                "Email Address"
                                                            }
                                                            sx={{
                                                                "& .MuiFormLabel-root":
                                                                    {
                                                                        fontSize:
                                                                            "18px",
                                                                    },
                                                                "& .MuiInput-input":
                                                                    {
                                                                        width: "33ch",
                                                                    },
                                                            }}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            InputProps={{
                                                                disableUnderline: true,
                                                                readOnly: true,
                                                                style: {
                                                                    ...textField_style,
                                                                },
                                                            }}
                                                            value={
                                                                customer
                                                                    .selectedCustomer
                                                                    .email ===
                                                                null
                                                                    ? "-"
                                                                    : customer
                                                                          .selectedCustomer
                                                                          .email
                                                            }
                                                        />
                                                    </Box>
                                                </Grid>
                                                <Grid
                                                    item
                                                    md={4}
                                                    sm={6}
                                                    xs={12}
                                                >
                                                    <Box sx={{ ...style }}>
                                                        <TextField
                                                            required
                                                            variant="standard"
                                                            id="company-required"
                                                            label={
                                                                userLoggedIn
                                                                    ?.language_data
                                                                    ?.customer
                                                                    ?.company_name ||
                                                                "Company Name"
                                                            }
                                                            sx={{
                                                                "& .MuiFormLabel-root":
                                                                    {
                                                                        fontSize:
                                                                            "18px",
                                                                    },
                                                            }}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            InputProps={{
                                                                disableUnderline: true,
                                                                readOnly: true,
                                                                style: {
                                                                    ...textField_style,
                                                                },
                                                            }}
                                                            value={
                                                                customer
                                                                    .selectedCustomer
                                                                    .company_info
                                                                    .name ===
                                                                null
                                                                    ? "-"
                                                                    : customer
                                                                          .selectedCustomer
                                                                          .company_info
                                                                          .name
                                                            }
                                                        />
                                                    </Box>
                                                </Grid>
                                                <Grid
                                                    item
                                                    md={4}
                                                    sm={6}
                                                    xs={12}
                                                >
                                                    <Box sx={{ ...style }}>
                                                        <TextField
                                                            required
                                                            variant="standard"
                                                            id="company-website-required"
                                                            label={
                                                                userLoggedIn
                                                                    ?.language_data
                                                                    ?.customer
                                                                    ?.company_website ||
                                                                "Company Website"
                                                            }
                                                            sx={{
                                                                "& .MuiFormLabel-root":
                                                                    {
                                                                        fontSize:
                                                                            "18px",
                                                                    },
                                                                "& .MuiInput-input":
                                                                    {
                                                                        width: "33ch",
                                                                    },
                                                            }}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            InputProps={{
                                                                disableUnderline: true,
                                                                readOnly: true,
                                                                maxLength: 12,
                                                                style: {
                                                                    ...textField_style,
                                                                },
                                                            }}
                                                            value={
                                                                customer
                                                                    .selectedCustomer
                                                                    .company_info
                                                                    .website ===
                                                                null
                                                                    ? "-"
                                                                    : customer
                                                                          .selectedCustomer
                                                                          .company_info
                                                                          .website
                                                            }
                                                        />
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Section>
                                    )}

                                    <Section
                                        title={"Contractor Information"}
                                        guid={guid}
                                        className={"mb-15"}
                                        showComment={false}
                                    >
                                        {user?.selectedUser !== null && (
                                            <ContractorInformation
                                                customerinfo={userDetails}
                                            />
                                        )}
                                    </Section>
                                    {formDetail?.form_data?.length > 0 && (
                                        <Section
                                            title={formDetail?.name}
                                            className={"mb-15"}
                                            showComment={false}
                                        >
                                            <Grid item>
                                                <RenderElements
                                                    state={state}
                                                    formElements={
                                                        formData && formData
                                                    }
                                                    isRead={isRead}
                                                    setState={setState}
                                                    btnText={"Save"}
                                                    isEdit={isEdit}
                                                    handlerFieldChange={
                                                        handlerFieldChange
                                                    }
                                                    submitSubmission={
                                                        submitSubmission
                                                    }
                                                />
                                            </Grid>
                                        </Section>
                                    )}
                                    {/* {customer?.selectedCustomer?.details?.services.includes(
                                        "rcp" || "tcp"
                                    ) && (
                                        <Section
                                            title="Parties Involved in Assessment"
                                            guid={guid}
                                            className={"mb-15"}
                                            showComment={true}
                                        >
                                            {user?.selectedUser !== null && (
                                                <AssessmentParties />
                                            )}
                                        </Section>
                                    )}
                                    {customer?.selectedCustomer?.details?.services.includes(
                                        "pcp" || "tcp"
                                    ) && (
                                        <Section
                                            title="Signed Document"
                                            guid={guid}
                                            className={"mb-15"}
                                            showComment={true}
                                        >
                                            {user?.selectedUser !== null && (
                                                <ContractToBeSigned
                                                    guid={guid}
                                                    contractList={contractList}
                                                    initilizeStates={
                                                        initilizeStates
                                                    }
                                                />
                                            )}
                                        </Section>
                                    )} */}
                                    {/* {customer?.selectedCustomer?.details?.services.includes(
                                        "dcp" || "tcp"
                                    ) && (
                                        <Section
                                            title="Document to be Collected"
                                            guid={guid}
                                            className={"mb-15"}
                                            showComment={true}
                                        >
                                            {user?.selectedUser !== null && (
                                                <DocumentToBeCollected
                                                    documentList={documentList}
                                                    initilizeStates={
                                                        initilizeStates
                                                    }
                                                    guid={guid}
                                                />
                                            )}
                                        </Section>
                                    )}
                                            <Section
                                                title="Document to be Collected"
                                                guid={guid}
                                                className={"mb-15"}
                                                showComment={true}
                                            >
                                                {user?.selectedUser !== null && (
                                                    <DocumentToBeCollected
                                                        documentList={documentList}
                                                        initilizeStates={
                                                            initilizeStates
                                                        }
                                                        guid={guid}
                                                    />
                                                )}
                                            </Section>
                                        )} */}
                                    {customer?.selectedCustomer?.details?.services.includes(
                                        "rcp" || "tcp"
                                    ) && (
                                        <Section
                                            title="Assessment Summary"
                                            guid={guid}
                                            className="mb-15"
                                            showComment={false}
                                            expand={customExpand ? false : true}
                                        >
                                            <AssessmentSummary
                                                contractor_id={
                                                    location?.state
                                                        ?.contractor_ID
                                                }
                                                user_id={id}
                                                guid={guid}
                                                roleId={
                                                    managerRoleId
                                                        ? managerRoleId
                                                        : user?.selectedUser
                                                              ?.role_id
                                                }
                                                setLoading={setLoading}
                                                expand={
                                                    customExpand ? false : true
                                                }
                                                mode={
                                                    mode ||
                                                    localStorage.getItem("mode")
                                                }
                                            />
                                        </Section>
                                    )}
                                    {localStorage.getItem("role_id") ==
                                    2 ? null : (
                                        <Section
                                            title="Final Review"
                                            guid={guid}
                                            className={"mb-15"}
                                            showComment={false}
                                        >
                                            <FinalReview />
                                        </Section>
                                    )}
                                </ContextProvider.Provider>
                            </Grid>
                            {/* <Grid item md={3} xs={12} xl={3}>
                                <RcpTasks
                                    guid={guid}
                                    contractor_id={id}
                                    getTasks={getTasks}
                                    toggale={toggale}
                                />
                            </Grid> */}
                        </Grid>
                    </Box>
                    <Footer
                        guid={guid}
                        contractor_id={
                            location?.state?.contractor_ID
                                ? location?.state?.contractor_ID
                                : id
                        }
                        selectedCustomer={customer?.selectedCustomer?.guid}
                        mode={
                            mode ||
                            location?.state?.mode ||
                            localStorage.setItem("mode", mode)
                        }
                    />
                </>
            )}
        </Fragment>
    );
}
const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,
        customer: state.customer,
        user: state.user,
        complianceWorkflow: state.complianceWorkflow,
        comments: state.comments,
        tasks: state.tasks,
        formDetail: state?.formStudio?.dctforAssesment,
        submitResponse: state?.DCTAssesmentReducer?.dctdatasubmit?.data,
        fetchFormData: state?.GetDctAssesmentReducer?.dctData?.result,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getContractInUsActionList,
            getCommentsAction,
            getDocumentsAction,
            getContractsAction,
            rcpTasksAction,
            getCustomerById,
            getUserById,
            getFormforAssesment,
            DctAssesmentAction,
            GetDctAssesmentAction,
        },
        dispatch
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomerInformation);
