import * as React from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import "../../../assets/styles/Common/_CommonStyle.scss";
import { connect } from "react-redux";
import { ROUTES } from "../../router/RouteList";
import { useNavigate } from "react-router-dom";

const FormElementDrawer = (props) => {
    const { open, closeDrawer, basicElement, elementList, userLoggedIn, formList, openForms } = props;
    const navigate = useNavigate();
    const handleDrawerClose = () => {
        closeDrawer((prevState) => ({ ...prevState, elementDrawer: false }));
    };

    const formRedirect = (item) => {
        navigate(ROUTES.FORM_BUILDER + "/view/" + item?.id, {
            state: { item: item },
        });
    }

    return (
        <Drawer
            className="drawer"
            variant="persistent"
            anchor="left"
            open={open}
        >
            <Grid
                container
                display={"flex"}
                justifyContent={"space-between"}
                paddingLeft={"15px"}
                alignContent={"center"}
                alignItems={"center"}
                className="drawerTitleBox"
            >
                <Typography className={"elementDrawerHeadText"}>
                    {openForms ? 'Form Collections' : "Form Element"}
                </Typography>
                <IconButton
                    onClick={handleDrawerClose}
                    id="formElementDrawerClose"
                >
                    <CloseIcon sx={{ color: "#76520E" }} />
                </IconButton>
            </Grid>
            {
                openForms ?
                    formList.map((item, index) => (
                        <ListItem
                            key={item.id}
                            disablePadding
                            className={"drawerListItem"}
                        >
                            <ListItemButton
                                sx={{
                                    "& .MuiListItemIcon-root":
                                    {
                                        minWidth:
                                            "auto",
                                    },
                                }}
                                onClick={() => formRedirect(item)}
                            >
                                <ListItemIcon
                                    className="listItemIcon"
                                    fontSize={"large"}
                                >
                                    {index + 1}
                                </ListItemIcon>
                                <ListItemText
                                    className="listIconText"
                                    primary={item?.name}
                                />
                            </ListItemButton>
                        </ListItem>
                    ))
                    :
                    <Droppable droppableId={"elementList"}>
                        {(provided, snapshot) => (
                            <div ref={provided.innerRef} {...provided.droppableProps}>
                                <List className="listBox">
                                    {elementList.map((item, index) => (
                                        <Draggable
                                            draggableId={item.id.toString()}
                                            index={index}
                                        >
                                            {(provided, snapshot) => {
                                                return (
                                                    <ListItem
                                                        key={item.id}
                                                        disablePadding
                                                        ref={provided.innerRef}
                                                        snapshot={snapshot}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        className={"drawerListItem"}
                                                    >
                                                        <ListItemButton
                                                            sx={{
                                                                "& .MuiListItemIcon-root":
                                                                {
                                                                    minWidth:
                                                                        "auto",
                                                                },
                                                            }}
                                                        >
                                                            <ListItemIcon
                                                                className="listItemIcon"
                                                                fontSize={"large"}
                                                            >
                                                                {item?.icon}
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                className="listIconText"
                                                                primary={item?.element}
                                                            />
                                                        </ListItemButton>
                                                    </ListItem>
                                                );
                                            }}
                                        </Draggable>
                                    ))}
                                    <Typography className="basicEleText">
                                        {userLoggedIn?.language_data?.formstudio?.basic_element || "Basic Element"}
                                    </Typography>
                                    {basicElement.map((item, index) => (
                                        <Draggable
                                            draggableId={item.id.toString()}
                                            index={index}
                                        >
                                            {(provided, snapshot) => {
                                                return (
                                                    <ListItem
                                                        key={item.id}
                                                        disablePadding
                                                        ref={provided.innerRef}
                                                        snapshot={snapshot}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        className={"drawerListItem"}
                                                    >
                                                        <ListItemButton
                                                            sx={{
                                                                "& .MuiListItemIcon-root":
                                                                {
                                                                    minWidth:
                                                                        "auto",
                                                                },
                                                            }}
                                                        >
                                                            <ListItemIcon
                                                                className="listItemIcon"
                                                                fontSize={"large"}
                                                            >
                                                                {item?.icon}
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                className="listIconText"
                                                                primary={item?.element}
                                                            />
                                                        </ListItemButton>
                                                    </ListItem>
                                                );
                                            }}
                                        </Draggable>
                                    ))}
                                </List>
                            </div>
                        )}
                    </Droppable>
            }

            <Divider />
        </Drawer>
    );
};

const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,
    };
};

export default connect(mapStateToProps, null)(FormElementDrawer);
