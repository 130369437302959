import React, { useState, Fragment, useEffect } from "react";
import {
    Typography,
    TableRow,
    TableCell,
    IconButton,
    MenuItem,
    Chip,
} from "@mui/material";
import { ReactComponent as DeleteIcon } from "../../../../../../../assets/Images/Icons/DeleteIcon.svg";
import ButtonComponent from "../../../../../../components/ButtonComponent";
import DialogComponent from "../../../../../../components/DialogsBox/DialogComponent";
import TableComponent from "../../../../../../components/Table/TableComponent";
import AutoCompleteDropDown from "../../../../../../components/DropDown/AutoCompleteDropDown";
import TextFieldComponent from "../../../../../../components/TextField/TextFieldComponent";
import DropDownComponent from "../../../../../../components/DropDown/DropDownComponent";
import CheckboxComponents from "../../../../../../components/Checkbox/CheckboxComponent";
import { connect } from "react-redux";
import {
    getUserById,
    getUserList,
} from "../../../../../../store/Action/UserAction";
import { bindActionCreators } from "redux";
import { useParams } from "react-router";

const RoleSettings = (props) => {
    const {
        roles,
        tableData,
        handleTableDataState,
        name,
        readOnly,
        getUserList,
        selectedCustomerID,
        userList,
        customer,
    } = props;
    console.log("props", props);
    const [state, setState] = useState({
        selectedRoles: [],
        rolesOpetions: roles || [],
        contractors: [],
        managers: [],
    });
    const {
        selectedRoles,
        rolesOpetions,
        userLoggedIn,
        contractors,
        managers,
    } = state;
    const prams = useParams();
    console.log("state", prams);

    useEffect(() => {
        callUserListApi();
    }, []);
    const callUserListApi = async () => {
        await getUserList(selectedCustomerID || prams.id);
    };
    useEffect(() => {
        if (tableData) {
            let arr = roles || [];
            const ids = tableData.map((a) => a.id);
            const temArr = arr?.filter((obj) => {
                return !ids.includes(obj.id);
            });
            setState((prevState) => ({
                ...prevState,
                selectedRoles: tableData,
                rolesOpetions: temArr,
            }));
        }
    }, [roles?.length, tableData?.length]);
    const [dialogAddRole, setDialogAddRole] = useState(false);
    const [isEditArr, setEdit] = useState([]);

    const handleAddRole = () => {
        let con = [];
        const newData = selectedRoles.map((obj) => ({
            ...obj,
            conflict_resolution: obj.id === 44 ? true : false,
            view_test_responses: true,
            download_test: obj.id === 44 ? true : false,
            // users: obj.id === 44 ? managers : con,
        }));
        const newArr = [...newData];
        handleTableDataState(name, newArr);
        setDialogAddRole(false);
    };
    const handleClose = () => {
        setDialogAddRole(false);
    };

    const handleUpadteFormData = async (event, value) => {
        setState((prevState) => ({
            ...prevState,
            selectedRoles: typeof value === "string" ? value.split(",") : value,
        }));
    };
    const handleContractors = (event, value) => {
        setState((prevState) => ({
            ...prevState,
            contractors: typeof value === "string" ? value.split(",") : value,
        }));
    };
    const handleManagers = (event, value) => {
        setState((prevState) => ({
            ...prevState,
            // managers: typeof value === "string" ? value.split(",") : value,
            managers: value.id,
        }));
    };

    const editCheckBox = (event, row) => {
        const newArr = [...tableData];
        const findIndex = tableData.findIndex((obj) => obj.id === row.id);
        let updateDetails;
        if (event.target.name === "conflict_resolution") {
            updateDetails = {
                ...row,
                conflict_resolution: event.target.checked,
            };
        }
        if (event.target.name === "view_test_responses") {
            updateDetails = {
                ...row,
                view_test_responses: event.target.checked,
            };
        }
        if (event.target.name === "download_test") {
            updateDetails = {
                ...row,
                download_test: event.target.checked,
            };
        }
        if (findIndex !== -1) {
            newArr[findIndex] = updateDetails;
        }
        handleTableDataState(name, newArr);
    };
    const headerList = [
        {
            id: 1,
            name: "Test Taker",
            headerClass: "tableHeading customerNameTableHeading",
            accessibleKey: "role_id",
            rowClass: "typo4",
            headerAlign: "center",
            rowAlign: "center",
        },
        {
            id: 2,
            name: "Conflict Resolution",
            headerClass: "tableHeading",
            accessibleKey: "conflict_resolution",
            rowClass: "typo4",
            headerAlign: "center",
            rowAlign: "center",
        },
        {
            id: 3,
            name: "View Test Responses",
            headerClass: "tableHeading",
            accessibleKey: "view_test_responses",
            rowClass: "typo4",
            headerAlign: "center",
            rowAlign: "center",
        },
        {
            id: 4,
            name: "Download Test",
            headerClass: "tableHeading",
            accessibleKey: "download_test",
            rowClass: "typo4",
            headerAlign: "center",
            rowAlign: "center",
        },
        // {
        //     id: 5,
        //     name: "Manage",
        //     headerClass: "tableHeading",
        //     accessibleKey: "manage",
        //     rowClass: "typo4",
        //     headerAlign: "center",
        //     rowAlign: "center",
        // },
    ];

    const dialogBody = (
        <>
            <AutoCompleteDropDown
                onChange={handleUpadteFormData}
                multiple={true}
                options={roles}
                renderInput={(params) => (
                    <TextFieldComponent
                        {...params}
                        placeholder="Add Test taker"
                        id="roleSettingsAddRole"
                    />
                )}
                // filterOptions={}
                filterSelectedOptions={true}
                value={selectedRoles}
                getOptionLabel={(option) => option.label}
                sx={{ marginBottom: "20px" }}
            />

            {/* <AutoCompleteDropDown
                label={"Contractors"}
                onChange={handleContractors}
                multiple={true}
                options={userList?.filter((obj) => obj.role_id === 2)}
                renderInput={(params) => (
                    <TextFieldComponent
                        {...params}
                        placeholder="Add Contractors"
                        id="roleSettingsAddRole"
                    />
                )}
                // filterOptions={}
                filterSelectedOptions={true}
                // value={selectedRoles}
                getOptionLabel={(option) => option?.first_name}
                sx={{ marginBottom: "20px" }}
            />
            <AutoCompleteDropDown
                label={"Managers"}
                onChange={handleManagers}
                // multiple={true}
                options={userList?.filter((obj) => obj.role_id === 44)}
                renderInput={(params) => (
                    <TextFieldComponent
                        {...params}
                        placeholder="Add Managers"
                        id="roleSettingsAddRole"
                    />
                )}
                // filterOptions={}
                disablePortal
                // value={selectedRoles}
                getOptionLabel={(option) => option?.first_name}
                sx={{ marginBottom: "20px" }}
            /> */}
        </>
    );

    return (
        <Fragment>
            <DialogComponent
                maxWidth={"md"}
                sx={{ "& .MuiPaper-root": { width: "700px" }, padding: "10px" }}
                open={dialogAddRole}
                title={"Select Test taker"}
                content={dialogBody}
                handleClose={handleClose}
                action={<ButtonComponent title="Add" onClick={handleAddRole} />}
            />
            <TableComponent
                headerList={headerList}
                extraHeaderRight={
                    !readOnly ? (
                        <>
                            <TableCell
                                align="center"
                                className="tableHeading actionTableHeading"
                            >
                                Action
                            </TableCell>
                        </>
                    ) : null
                }
                tableFooter={
                    !readOnly && (
                        <TableRow>
                            <TableCell colSpan={4}></TableCell>
                            <TableCell align="center">
                                <ButtonComponent
                                    title="+"
                                    sx={{
                                        fontSize: "30px !important",
                                        borderRadius: "50%",
                                        minWidth: "0px !important",
                                        padding: "12px 18px",
                                        paddingInline: "18px !important",
                                    }}
                                    onClick={() => {
                                        setDialogAddRole(true);
                                    }}
                                />
                            </TableCell>
                        </TableRow>
                    )
                }
            >
                {tableData?.map((row, index) => (
                    <TableRow
                        key={index}
                        className={index % 2 !== 0 ? "tertiaryBackground" : ""}
                    >
                        <TableCell align="center">
                            {isEditArr[index] ? (
                                <DropDownComponent
                                    label={
                                        userLoggedIn?.language_data?.comman
                                            ?.menu_roles || "Roles"
                                    }
                                >
                                    {roles.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </DropDownComponent>
                            ) : (
                                <Typography
                                    className="font_16_600"
                                    variant="span"
                                >
                                    {row.label}
                                </Typography>
                            )}
                        </TableCell>
                        <TableCell align="center">
                            <CheckboxComponents
                                name={"conflict_resolution"}
                                value={row?.conflict_resolution}
                                checked={row?.conflict_resolution}
                                onChange={(event) => {
                                    editCheckBox(event, row);
                                }}
                                disabled={readOnly}
                            />
                        </TableCell>
                        <TableCell align="center">
                            <CheckboxComponents
                                name={"view_test_responses"}
                                value={row?.view_test_responses}
                                checked={row?.view_test_responses}
                                onChange={(event) => {
                                    editCheckBox(event, row);
                                }}
                                disabled={readOnly}
                            />
                        </TableCell>
                        <TableCell align="center">
                            <CheckboxComponents
                                name={"download_test"}
                                value={row?.download_test}
                                checked={row?.download_test}
                                onChange={(event) => {
                                    editCheckBox(event, row);
                                }}
                                disabled={readOnly}
                            />
                        </TableCell>
                        {/* <TableCell align="center">
                            <ButtonComponent title={"View User"} />
                        </TableCell> */}
                        {!readOnly && (
                            <TableCell align="center">
                                <IconButton
                                    onClick={() => {
                                        const tableArr = [...tableData];
                                        if (tableArr?.length > 0) {
                                            const newArr = tableArr?.filter(
                                                (value, ind) => ind !== index
                                            );
                                            setState((prevState) => ({
                                                ...prevState,
                                                selectedRoles: newArr,
                                            }));
                                            handleTableDataState(name, newArr);
                                        }
                                    }}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </TableCell>
                        )}
                    </TableRow>
                ))}
            </TableComponent>
        </Fragment>
    );
};
const mapStateToProps = (state) => {
    return {
        roles: state?.auth?.commonDropdown?.roles,
        userLoggedIn: state.auth?.userLoggedIn,
        selectedCustomerID: state?.customer?.selectedCustomer?.id,
        userList: state.user.usersList,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getUserById,
            getUserList,
        },
        dispatch
    );
};
export default connect(mapStateToProps, mapDispatchToProps)(RoleSettings);
