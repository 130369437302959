import * as Actions from "../Action/RolesModuleAction";

const initialState = {
    moduleList: null,
    roleData: null,
    roleList: null,
    viewRoleType: "table",
    rolePagination: {
        listViewLimit: 8,
        total: "",
        currentPage: 1,
        lastPage: "",
    },
};

const roles = (state = initialState, action) => {
    switch (action?.type) {
        case Actions.GET_ROLES_LIST: {
            return {
                ...state,
                roleList: action.payload?.data,
                rolePagination: {
                    ...state.rolePagination,
                    total: action?.payload?.total,
                    lastPage: action?.payload?.last_page,
                    currentPage: action?.payload?.current_page,
                },
            };
        }

        case Actions.GET_MODULE_LIST: {
            let alphSortRole = action.payload.sort((a, b) =>
                a.name.localeCompare(b.name)
            );
            return {
                ...state,
                moduleList: alphSortRole,
            };
        }

        case Actions.GET_CREATED_ROLE_ID: {
            return {
                ...state,
                roleData: action.payload,
            };
        }

        case Actions.DELETE_ROLE: {
            let list = Array.from(state.roleList);
            let index = list.findIndex((obj) => obj.id === action.payload.id);
            if (index !== -1) list[index] = action.payload;
            return { ...state, roleList: list };
        }

        case Actions.SET_MODULE_LIST: {
            return {
                ...state,
                moduleList: action.payload,
            };
        }

        case Actions.CHANGE_ROLE_VIEW_TYPE: {
            return {
                ...state,
                viewRoleType: action.payload,
            };
        }
        case Actions.SET_ROLE_PER_PAGE: {
            return {
                ...state,
                rolePagination: {
                    ...state.rolePagination,
                    listViewLimit: action?.payload,
                },
            };
        }
        case Actions.SET_CURRENT_PAGE: {
            return {
                ...state,
                rolePagination: {
                    ...state.rolePagination,
                    currentPage: action.payload?.data,
                },
            };
        }
        default: {
            return state;
        }
    }
};

export default roles;
