import React from "react";
import Typography from "@mui/material/Typography";
import { getUserById } from "../store/Action/UserAction";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
function NoInfoAvailable(props) {
    const { className, noInfoTypo, userLoggedIn } = props;
    
    return (
        <div className={`${noInfoTypo}`}>
            <Typography className={`${className}`}>
                {userLoggedIn?.language_data?.comman?.no_info || "No Information Available"}
            </Typography>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,
    };
};
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getUserById,

        },
        dispatch
    );
};

export default connect (mapStateToProps, mapDispatchToProps) (NoInfoAvailable);
