import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Navbar from "../../common/Header/Navbar";
import TextFieldComponent from "../../components/TextField/TextFieldComponent";
import ButtonComponent from "../../components/ButtonComponent";
import { signUpAction } from "../../store/Action/LoginAction";
import "../../../assets/styles/Container/SignUp.scss";
import { ROUTES } from "../../router/RouteList";
function SignUp(props) {
    const { signUpAction, userLoggedIn } = props;

    const navigate = useNavigate();

    const [state, setState] = useState({
        first_name: "",
        middle_name: "",
        last_name: "",
        email: "",
        phone: "",
        password: "",
        confirm_password: "",
        showPassword: false,
        showConfirmPassword: false,
    });
    const {
        first_name,
        middle_name,
        last_name,
        email,
        phone,
        password,
        confirm_password,
        showPassword,
        showConfirmPassword,
    } = state;

    const handleShowPassword = () => {
        setState((prevState) => ({
            ...prevState,
            showPassword: !showPassword,
        }));
    };
    const handleConfirmPassword = () => {
        setState((prevState) => ({
            ...prevState,
            showConfirmPassword: !showConfirmPassword,
        }));
    };

    const handleSignUp = async () => {
        const res = await signUpAction(state);
        if (res) navigate(ROUTES.LOGIN);
    };

    const today = new Date();
  const year = today.getFullYear();
    return (
        <>
            <Navbar />
            <Box className="signUpBox" px={40} pt={8}>
                <Grid container>
                    <Paper className="signUpPaper" elevation={5}>
                        <Grid container px={3}>
                            <Grid
                                item
                                xs={12}
                                mb={2}
                                justifyContent={"center"}
                                display={"flex"}
                            >
                                <Typography className="signUpHeading">
                                    Sign Up
                                </Typography>
                            </Grid>
                            <Grid item md={4} xs={12} sx={{ padding: "10px" }}>
                                <TextFieldComponent
                                    label={userLoggedIn?.language_data?.customer?.first_name || "First Name"}
                                    required={true}
                                    onChange={(event) =>
                                        setState((prevState) => ({
                                            ...prevState,
                                            first_name: event.target.value,
                                        }))
                                    }
                                    value={first_name || ""}
                                />
                            </Grid>
                            <Grid item md={4} xs={12} sx={{ padding: "10px" }}>
                                <TextFieldComponent
                                    id="signUpMiddleName"
                                    label={userLoggedIn?.language_data?.customer?.middle_name || "Middle Name"}
                                    onChange={(event) =>
                                        setState((prevState) => ({
                                            ...prevState,
                                            middle_name: event.target.value,
                                        }))
                                    }
                                    value={middle_name || ""}
                                />
                            </Grid>
                            <Grid item md={4} xs={12} sx={{ padding: "10px" }}>
                                <TextFieldComponent
                                    id="signUpLastName"
                                    label={userLoggedIn?.language_data?.customer?.last_name || "Last Name"}
                                    required={true}
                                    onChange={(event) =>
                                        setState((prevState) => ({
                                            ...prevState,
                                            last_name: event.target.value,
                                        }))
                                    }
                                    value={last_name || ""}
                                />
                            </Grid>
                            <Grid item md={6} xs={12} sx={{ padding: "10px" }}>
                                <TextFieldComponent
                                    label={ "Email Address"}
                                    required={true}
                                    onChange={(event) =>
                                        setState((prevState) => ({
                                            ...prevState,
                                            email: event.target.value,
                                        }))
                                    }
                                    value={email || ""}
                                />
                            </Grid>
                            <Grid item md={6} xs={12} sx={{ padding: "10px" }}>
                                <TextFieldComponent
                                    id="signUpContactNumber"
                                    label={"Contact Number"}
                                    required={true}
                                    onChange={(event) =>
                                        setState((prevState) => ({
                                            ...prevState,
                                            phone: event.target.value,
                                        }))
                                    }
                                    value={phone || ""}
                                />
                            </Grid>
                            <Grid item md={6} xs={12} sx={{ padding: "10px" }}>
                                <TextFieldComponent
                                    id="signUpPassword"
                                    label={"Password"}
                                    required={true}
                                    value={password}
                                    onChange={(event) =>
                                        setState((prevState) => ({
                                            ...prevState,
                                            password: event.target.value,
                                        }))
                                    }
                                    type={showPassword ? "text" : "password"}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    id="resetPassShowPassButton"
                                                    onClick={() =>
                                                        handleShowPassword()
                                                    }
                                                    edge="start"
                                                >
                                                    {!showPassword ? (
                                                        <VisibilityOff />
                                                    ) : (
                                                        <Visibility />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item md={6} xs={12} sx={{ padding: "10px" }}>
                                <TextFieldComponent
                                    id="signUpConfirmPassword"
                                    label={"Confirm Password"}
                                    required={true}
                                    value={confirm_password}
                                    onChange={(event) =>
                                        setState((prevState) => ({
                                            ...prevState,
                                            confirm_password:
                                                event.target.value,
                                        }))
                                    }
                                    type={
                                        showConfirmPassword
                                            ? "text"
                                            : "password"
                                    }
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() =>
                                                        handleConfirmPassword()
                                                    }
                                                    edge="start"
                                                >
                                                    {!showConfirmPassword ? (
                                                        <VisibilityOff />
                                                    ) : (
                                                        <Visibility />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                display={"flex"}
                                justifyContent={"center"}
                                mt={2}
                            >
                                <ButtonComponent
                                    id="signUpButton"
                                    title={"Sign Up"}
                                    onClick={() => handleSignUp()}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                    <Grid
                        item
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        xs={12}
                        mt={3}
                    >
                        <Typography className="year">
                            {year} All Copyright Reserved By
                        </Typography>
                        <Link className="complianceBtn">{"Elevance"}</Link>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ signUpAction }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
