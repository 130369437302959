import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { toast } from "react-hot-toast";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Navbar from "../../common/Header/Navbar";
import TextFieldComponent from "../../components/TextField/TextFieldComponent";
import ButtonComponent from "../../components/ButtonComponent";
import { ROUTES } from "../../router/RouteList";
import {
    getModuleList,
    getCreateRoleID,
} from "../../store/Action/RolesModuleAction";
import "../../../assets/styles/Common/_CommonStyle.scss";
import "../../../assets/styles/Container/ManageRole.scss";
import LoadingComponent from "../../components/Loading/LoadingComponent";
import { getUserById } from "../../store/Action/UserAction";
function CreateNewRoles(props) {
    const { getModuleList, getCreateRoleID, role_description, userLoggedIn } = props;
    const navigate = useNavigate();
    const location = useLocation();
    const [state, setState] = useState({
        viewDeleteId: null,
        name: "",
        description: "",
        isLoading: false,
    });
    const { viewDeleteId, name, description, isLoading } = state;

    if (location?.state?.roleID && !viewDeleteId) {
        setState((prevState) => ({
            ...prevState,
            viewDeleteId: location?.state?.roleID,
        }));
    }

    useEffect(() => {
        getModuleList();
    }, [getModuleList]);

    function handleChange(e) {
        setState((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
    }

    async function handlerAdd(e) {
        if (name !== "" && description !== "") {
            setState((prevState) => ({ ...prevState, isLoading: true }));
            const res = await getCreateRoleID(state);
            setState((prevState) => ({ ...prevState, isLoading: false }));
            if (res?.payload) {
                const path = ROUTES.MANAGE_MODULE_ROLE_PERMISSION?.split("/");
                navigate(`/${path[1]}/` + res?.payload?.id, {
                    state: {
                        roleData: { ...res?.payload, ...state },
                        type: "add",
                    },
                });
            }
        } else {
            toast.error(userLoggedIn?.language_data?.comman?.enter_input || "Please Enter Input");
        }
    }

    console.log(userLoggedIn?.language_data?.comman)
    return (
        <>
            <Navbar />
            {isLoading ? (
                <LoadingComponent sx={{ height: "600px" }} />
            ) : (
                <Box className="mainBox">
                    <Typography
                        className="font_24_600"
                        sx={{ marginBottom: "30px" }}
                    >
                        {location?.state?.type === "view"
                            ? "View Role"
                            : location?.state?.type === "edit"
                            ? "Edit Role"
                            :userLoggedIn?.language_data?.roles?.create_new_role || "Create New Role"}
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6} lg={6}>
                            <TextFieldComponent
                                id="createRoleName"
                                label={userLoggedIn?.language_data?.roles?.role_name || "Role Name*"}
                                onChange={handleChange}
                                name={"name"}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <TextFieldComponent
                                id="createRolesDescription"
                                label={userLoggedIn?.language_data?.roles?.role_description || "Role Description"}
                                onChange={handleChange}
                                name={"description"}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            display={"flex"}
                            sx={{
                                justifyContent: {
                                    xs: "center",
                                    md: "right",
                                    lg: "right",
                                    xl: "right",
                                },
                            }}
                        >
                            <ButtonComponent
                                id="createRoleAddButton"
                                title={userLoggedIn?.language_data?.comman?.add_btn || "Add"}
                                onClick={handlerAdd}
                                className="button_contained"
                            />
                        </Grid>
                    </Grid>
                </Box>
            )}
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,
        moduleList: state?.roles?.moduleList,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getModuleList,
            getCreateRoleID,
            getUserById
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateNewRoles);
