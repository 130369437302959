import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputBase from "@mui/material/InputBase";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import ButtonComponent from "./ButtonComponent";
import Grid from "@mui/material/Grid";
import debounce from "lodash/debounce";
import { getUserById } from "../store/Action/UserAction";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
function SearchBar(props) {
    const { handleSearch, className, setSearch, userLoggedIn } = props;
    const [state, setState] = useState({ search: null });
    const { search } = state;

    function searchValue(event) {
        event.preventDefault();
        if (search !== null) {
            handleSearch(search);
        }
    }

    const throttledHandleSearch = handleSearch !== undefined
  ? debounce(handleSearch, 1000)
  : undefined;

    useEffect(() => {
        if(throttledHandleSearch) {
        if (search !== null ) {
            throttledHandleSearch(search);
          }
          return () => throttledHandleSearch.cancel();
        }
    }, [search]);

    function handleClose() {
        setState((prevState) => ({ ...prevState, search: "" }));
        handleSearch("");
        setSearch("");
    }

    return (
        <form className="searchSubmit">
            <Grid className={className || "searchBox"}>
                <SearchIcon className="searchIcon" />
                <InputBase
                    className="searchInput"
                    type="text"
                    onChange={(e) => {
                        setState((prevState) => ({
                            ...prevState,
                            search: e.target.value,
                        }));
                        
                            setSearch(e.target.value);
                        
                    }}
                    placeholder= {userLoggedIn?.language_data?.comman?.search_btn || "Search..."}
                    inputProps={{ "aria-label": userLoggedIn?.language_data?.comman?.search_btn || "search" }}
                    value={search}
                    endAdornment={
                        <InputAdornment position="end">
                            {search ? (
                                <IconButton onClick={() => handleClose()}>
                                    {!search ? null : <ClearOutlinedIcon />}
                                </IconButton>
                            ) : null}
                            <ButtonComponent
                                title={userLoggedIn?.language_data?.comman?.search_btn || "Search"}
                                type={"submit"}
                                className="buttonSearch"
                                variant="contained"
                                onClick={(e) => searchValue(e)}
                            />
                        </InputAdornment>
                    }
                />
            </Grid>
        </form>
    );
}const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,
    };
};
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getUserById,

        },
        dispatch
    );
};
export default connect(mapStateToProps, mapDispatchToProps) (SearchBar);
