import React, { Fragment } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import SearchIcon from "@mui/icons-material/Search";
import { styled } from "@mui/system";
import InputBase from "@mui/material/InputBase";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import MainLogo from "../../../assets/Images/Logo/Logo.png";
import { ReactComponent as NotificationIcon } from "../../../assets/Images/Icons/Notification.svg";
import ButtonComponent from "../../components/ButtonComponent";
import { getUserById } from "../../store/Action/UserAction";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
const Search = styled("div")(({ theme }) => ({
    position: "relative",
    border: "1px solid #D6D6D6;",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "#FFFFFF",
    "&:hover": {
        backgroundColor: "#FFFFFF",
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(3),
        width: "auto",
    },
    [theme.breakpoints.down("sm")]: {
        display: "none",
    },
    menuPaper: {
        backgroundColor: "#FFFFFF",
    },
}));
const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions?.create("width"),
        width: "100%",
        [theme.breakpoints.up("md")]: {
            width: "100%",
        },
    },
}));

function StaticNavbar(props) {
    const { login, customer, primary, tertiary, userLoggedIn } = props;

    return (
        <>
            <AppBar
                className="labelAppbar"
                style={{ backgroundColor: tertiary }}
                position="static"
            >
                <Container maxWidth="xl" sx={{ padding: "0px" }}>
                    <Toolbar disableGutters>
                        <Box
                            sx={{ display: { xs: "none", md: "flex" }, mr: 1 }}
                            className="labelNavbar"
                        >
                            <img src={MainLogo} alt="mainLogo" />
                        </Box>
                        <Box
                            sx={{
                                flexGrow: 1,
                                display: { xs: "flex", md: "none" },
                                textAlign: "right",
                            }}
                        >
                            <IconButton size="large" sx={{ color: "" }}>
                                <MenuIcon />
                            </IconButton>
                        </Box>
                        {!login ? (
                            <Fragment>
                                <Box
                                    sx={{
                                        display: { xs: "flex", md: "none" },
                                        mr: 1,
                                    }}
                                >
                                    <img src={MainLogo} alt="mainLogo" />
                                </Box>
                                <Box
                                    mx={3}
                                    sx={{
                                        flexGrow: 1,
                                        display: { xs: "none", md: "flex" },
                                        justifyContent: "flex-end",
                                    }}
                                >
                                    <ButtonComponent
                                        title={userLoggedIn?.language_data?.comman?.menu_dashboard ||"Dashboard"}
                                        className="labelButton"
                                    />
                                    <ButtonComponent
                                        title={userLoggedIn?.language_data?.comman?.menu_manage || "Manage"}
                                        className="labelButton"
                                    />
                                    <ButtonComponent
                                        title={"Data Capture Tool"}
                                        className="labelButton"
                                    />
                                    <ButtonComponent
                                        title={userLoggedIn?.language_data?.comman?.menu_help || "Resources"}
                                        className="labelButton"
                                    />
                                    <IconButton variant="contained">
                                        <Badge
                                            badgeContent={7}
                                            overlap="circular"
                                            sx={{
                                                "& .MuiBadge-badge": {
                                                    backgroundColor: `${primary} !important`,
                                                    margin: "-4px",
                                                },
                                                color: "white",
                                            }}
                                        >
                                            <NotificationIcon />
                                        </Badge>
                                    </IconButton>
                                    <IconButton
                                        sx={{ p: 0, marginLeft: "20px" }}
                                    >
                                        <Avatar>A</Avatar>
                                    </IconButton>
                                </Box>
                            </Fragment>
                        ) : null}
                    </Toolbar>
                </Container>
            </AppBar>
            {!login && customer ? (
                <Box sx={{ px: { md: 6, xs: 1 }, marginTop: "10px" }}>
                    <Grid container className="headerGrid">
                        <Grid item md={3} lg={3}>
                            <Typography className="font_20_600">
                                {userLoggedIn?.language_data?.comman?.manage_customer || "Manage Customer"}
                            </Typography>
                        </Grid>
                        <Grid item md={6} lg={6}>
                            <Search>
                                <SearchIconWrapper>
                                    <SearchIcon />
                                </SearchIconWrapper>
                                <StyledInputBase
                                    type="text"
                                    placeholder="Search contractor, company..."
                                />
                            </Search>
                        </Grid>
                        <Grid
                            item
                            sm={12}
                            md={3}
                            lg={3}
                            sx={{ display: "flex", justifyContent: "flex-end" }}
                        >
                            <ButtonComponent
                                variant={"contained"}
                                className="labelContainedBtn"
                                sx={{
                                    justifyContent: "flex-end",
                                    backgroundColor: primary,
                                    color: tertiary,
                                }}
                                startIcon={<AddCircleOutlineIcon />}
                                title={userLoggedIn?.language_data?.customer?.add_customer || "Add Customer"}
                            />
                        </Grid>
                    </Grid>
                    <Box className="paginationGrid">
                        <Grid
                            container
                            gap={2}
                            sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "center",
                                marginTop: "14px",
                            }}
                        >
                            <Grid item xs={1}>
                                <Typography
                                    sx={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        alignItems: "center",
                                    }}
                                >
                                    Showing
                                </Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <Typography
                                    sx={{
                                        border: `1px solid ${primary}`,
                                        paddingX: "10px",
                                        paddingY: "6px",
                                        display: "flex",
                                        borderRadius: "4px",
                                    }}
                                >
                                    2
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography>of 23 entries</Typography>
                            </Grid>
                            <ButtonComponent
                                className="labelOutlinedButton"
                                sx={{
                                    color: primary,
                                    backgroundColor: tertiary,
                                    border: `1px solid ${primary}`,
                                }}
                                startIcon={<GridViewOutlinedIcon />}
                                title={"View"}
                            />
                        </Grid>
                    </Box>
                </Box>
            ) : null}
        </>
    );
}
const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getUserById,
        },
        dispatch
    );
};
export default connect(mapStateToProps, mapDispatchToProps) (StaticNavbar);
