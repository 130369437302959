import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useLocation, useNavigate } from "react-router-dom";
import { AiOutlineDrag } from "react-icons/ai";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import DragIndicatorOutlinedIcon from "@mui/icons-material/DragIndicatorOutlined";
import Navbar from "../../common/Header/Navbar";
import HeaderDrawerLink from "./HeaderDrawerLink";
import FormElementDrawer from "./FormElementDrawer";
import DesignDrawer from "./DesignDrawer";
import FormCollectionPopper from "./FormCollectionPopper";
import ButtonComponent from "../../components/ButtonComponent";
import FormTemplatePreviewDialog from "./FormTemplatePreview";
import TextFieldComponent from "../../components/TextField/TextFieldComponent";
import TooltipComponent from "../../components/ToolTip/TooltipComponent";
import LoadingComponent from "../../components/Loading/LoadingComponent";
import { elementList, basicElement, renderSwitch } from "./FormStudioUtils";
import {
    createForm,
    updateForm,
    getFormById,
} from "../../store/Action/FormStudioAction";
import { ROUTES } from "../../router/RouteList";
import color from "../../../assets/styles/Color.scss";
import "../../../assets/styles/Container/FormStudio.scss";
import {Translate } from 'react-auto-translate';
const FormStudio = (props) => {
    const { createForm, updateForm, getFormById, formDetail, companyLogo, userLoggedIn, formList } =
        props;
    
    const [state, setState] = useState({
        selectedEle: null,
        elementDrawer: true,
        designDrawer: true,
        popperOpen: false,
        saveButton: false,
        previewButton: false,
        formElements: [],
        editDeleteElement: false,
        anchorEl: null,
        formTemplateDialog: false,
        editLabel: false,
        formName: "",
        editFormName: false,
        fontColor: "#76520E",
        formColor: color?.tertiary || "#76520E",
        pageColor: "#FFFFFF",
        radioOptions: ["Yes", "No"],
        isLoading: false,
        create: false,
        openForms: false
    });

    const {
        elementDrawer,
        designDrawer,
        formElements,
        selectedEle,
        popperOpen,
        anchorEl,
        formTemplateDialog,
        formName,
        editFormName,
        fontColor,
        formColor,
        pageColor,
        radioOptions,
        isLoading,
        create,
        openForms
    } = state;
    const location = useLocation();
    const navigate = useNavigate();
    const pathName = window.location?.pathname?.split("/");
    if (pathName?.length <= 2 && !create) {
        setState((prevState) => ({
            ...prevState,
            formElements: [],
            formName: "",
            fontColor: "#76520E",
            formColor: color?.tertiary,
            pageColor: "#FFFFFF",
            create: true,

        }));
    }

    useEffect(() => {
        async function callApi() {
            setState((prevState) => ({ ...prevState, isLoading: true }));
            await getFormById(pathName[3]);
            setState((prevState) => ({ ...prevState, isLoading: false }));
        }
        if ((!formDetail && pathName[3]) || location?.state?.formData) {
            callApi();
        } else {
            setDefaultState();
        }
        if (pathName[3] && formDetail && formName === "") {
            setState((prevState) => ({
                ...prevState,
                formElements: formDetail?.form_data,
                formName: formDetail?.name,
                logo: formDetail?.logo,
                fontColor: formDetail?.font_color,
                formColor: formDetail?.form_color,
                pageColor: formDetail?.page_color,
            }));
        }
    }, [getFormById, formDetail, location?.state?.formData]);
    const setDefaultState = () => {
        setState((prevState) => ({
            ...prevState,
            formElements: [],
            formName: "",
            fontColor: "#76520E",
            formColor: color?.tertiary,
            pageColor: "#FFFFFF",
            create: true,
        }));
    };
    const handlerElementDrawerOpen = () => {

        {
            elementDrawer
                ? setState((prevState) => ({
                      ...prevState,
                      elementDrawer: false,
                      openForms: false
                  }))
                : setState((prevState) => ({
                      ...prevState,
                      elementDrawer: true,
                      openForms: false
                  }));
        }
    };
    const handlerDesignDrawerOpen = () => {
        {
            designDrawer
                ? setState((prevState) => ({
                      ...prevState,
                      designDrawer: false,
                  }))
                : setState((prevState) => ({
                      ...prevState,
                      designDrawer: true,
                  }));
        }
    };
    const onDragEnd = (result) => {
        const { source, destination } = result;
        if (!result.destination) {
            return;
        }
        let dragElement = elementList.filter(
            (obj) => obj.id.toString() === result.draggableId
        );
        let basic = basicElement.filter(
            (obj) => obj.id.toString() === result.draggableId
        );
        let copyFormElements = formElements;
        if (dragElement.length > 0) {
            copyFormElements?.splice(destination?.index, 0, {
                id: Math.random().toString(36).substring(2, 12),
                eleIndex: formElements.length + 1,
                name: dragElement[0].element,
                label: dragElement[0].element,
            });
            setState((prevState) => ({
                ...prevState,
                formElements: copyFormElements,
            }));
        } else if (basic.length > 0) {
            let dragElement = basicElement.filter(
                (obj) => obj.id.toString() === result.draggableId
            );
            copyFormElements?.splice(destination?.index, 0, {
                id: Math.random().toString(36).substring(2, 12),
                eleIndex: formElements.length + 1,
                name: dragElement[0].element,
                label: dragElement[0].element,
            });
            setState((prevState) => ({
                ...prevState,
                formElements: copyFormElements,
            }));
        } else {
            const result = Array.from(formElements);
            const [removed] = result?.splice(source.index, 1);
            if (removed) {
                result?.splice(destination.index, 0, removed);
                setState((prevState) => ({
                    ...prevState,
                    formElements: result,
                }));
            }
        }
    };

    const elementSelectAction = (item, index) => {
        return selectedEle?.id === item.id &&
            selectedEle?.eleIndex === item.eleIndex &&
            !formTemplateDialog ? (
            <Stack direction="row">
                <IconButton onClick={handlerDesignDrawerOpen}>
                    <SettingsOutlinedIcon
                        className="actionIcon"
                        size={"14px"}
                    />
                </IconButton>
                <IconButton
                    onClick={() => {
                        handlerRomove(index);
                    }}
                >
                    <DeleteOutlineOutlinedIcon
                        className="actionIcon"
                        size={"14px"}
                    />
                </IconButton>
                <IconButton>
                    <DragIndicatorOutlinedIcon
                        className="actionIcon"
                        size={"14px"}
                    />
                </IconButton>
            </Stack>
        ) : null;
    };

    const handlerEdit = (elementObj) => {
        let newData = [];
        formElements.forEach((obj) => {
            if (obj.eleIndex == elementObj.eleIndex) {
                newData.push({ ...obj, selected: true });
            } else {
                newData.push({ ...obj, selected: false });
            }
        });
        setState((prevState) => ({
            ...prevState,
            formElements: newData,
            selectedEle: { ...elementObj, selected: true },
        }));
    };
    const handlerRomove = (index) => {
        let copyData = formElements;
        copyData?.splice(index, 1);
        setState((prevState) => ({ ...prevState, formElements: copyData }));
    };
    const handleChange = (e, data) => {
        const copyData = formElements;
        const index = copyData.findIndex((a) => a.id == data.id);
        let updateData = { ...copyData[index], label: e.target.value };
        copyData[index] = updateData;
        setState((prevState) => ({
            ...prevState,
            formElements: copyData,
        }));
    };
    const handlerPreview = () => {
        setState((prevState) => ({
            ...prevState,
            selectedEle: { ...selectedEle, selected: false },
            formTemplateDialog: true,
        }));
    };
    const saveFormButton = async () => {
        setState((prevState) => ({ ...prevState, isLoading: true }));
        let data = {
            name: formName,
            logo: companyLogo,
            form_data: formElements,
            font_color: fontColor,
            page_color: pageColor,
            form_color: formColor,
        };
        const res = await createForm(data);
        if (res?.response?.data?.status == 201) {
            // toast.success(res?.response?.data?.message);
            navigate(
                ROUTES.FORM_BUILDER +
                    "/edit/" +
                    res?.response?.data?.result?.id,
                {
                    state: { formData: data },
                }
            );
        }
        setState((prevState) => ({ ...prevState, isLoading: false }));
    };
    const updateFormButton = async () => {
        let data = {
            id: location?.state?.formData?.id || pathName[3],
            name: formName,
            logo: companyLogo,
            form_data: formElements,
            font_color: fontColor,
            page_color: pageColor,
            form_color: formColor,
        };
        const res = await updateForm(data);
        if (res?.response?.data?.status == 200) {
            // toast.success(res?.response?.data?.message);
            navigate(
                ROUTES.FORM_BUILDER +
                    "/view/" +
                    res?.response?.data?.result?.id,
                {
                    state: { item: res?.response?.data?.result },
                }
            );
        }
    };
    const handlerIsEdit = () => {
        setState((prevState) => ({ ...prevState, editFormName: true }));
    };
    const onBlur = () => {
        setState((prevState) => ({ ...prevState, editFormName: false }));
    };
    const setMenuItem = (e, data) => {
        const copyData = formElements;
        if (
            e.target.name == "radioQuestion" ||
            e.target.name == "checkBoxQuestion"
        ) {
            const index = copyData.findIndex((a) => a.id == data.id);
            let updateData = {
                ...copyData[index],
                fieldDetails: {
                    ...copyData[index]?.fieldDetails,
                    question: e.target.value,
                },
            };
            copyData[index] = updateData;
            setState((prevState) => ({
                ...prevState,
                formElements: copyData,
            }));
        }
        if (e.target.name == "optionLabel") {
            const index = copyData.findIndex((a) => a.id == data.id);
            setState((prevState) => ({
                ...prevState,
                radioOptions: [...radioOptions, e.target.value],
            }));
            let updateData = {
                ...copyData[index],
                fieldDetails: {
                    ...copyData[index]?.fieldDetails,
                    options: [
                        ...(copyData[index]?.fieldDetails?.options ?? []),
                        e.target.value,
                    ],
                },
            };
            copyData[index] = updateData;
            setState((prevState) => ({
                ...prevState,
                formElements: copyData,
            }));
        }
        if (e.target.name == "option") {
            let splitText = e.target.value.split("\n");
            const index = copyData.findIndex((a) => a.id == data.id);
            let updateData = {
                ...copyData[index],
                fieldDetails: {
                    valueLabel: splitText,
                },
            };
            copyData[index] = updateData;
            setState((prevState) => ({
                ...prevState,
                formElements: copyData,
            }));
        }
    };
    const renderFormElements = (provided, snapshot, item, index) => (
        <Box
            sx={{
                cursor: "pointer !important",
                boxShadow:
                    selectedEle?.id === item?.id &&
                    selectedEle?.eleIndex === item?.eleIndex &&
                    !formTemplateDialog
                        ? "0 0 0 2px #0293e2"
                        : "",
                border: "1px solid #959595",
            }}
            ref={provided.innerRef}
            snapshot={snapshot}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className="elementBox"
            onClick={() => handlerEdit(item)}
        >
            <Grid container>
                <Grid item xs={12}>
                    {renderSwitch(
                        item,
                        index,
                        state,
                        setState,
                        handleChange,
                        elementSelectAction,
                        setMenuItem,
                        null,
                        true,
                        true
                    )}
                    {provided.placeholder}
                </Grid>
            </Grid>
        </Box>
    );

    return (
        <>
    <FormTemplatePreviewDialog
        open={formTemplateDialog}
        setState={setState}
        formElements={formElements}
        state={state}
    />
    <FormCollectionPopper
        open={popperOpen}
        anchorEl={anchorEl}
        setState={setState}
    />
    <Navbar />
    {isLoading ? (
        <LoadingComponent sx={{ height: "600px" }} />
    ) : (
        <Box display={"flex"} sx={{ flexDirection: "column" }}>
            <HeaderDrawerLink
                handlerDesignDrawerOpen={handlerDesignDrawerOpen}
                handlerElementDrawerOpen={handlerElementDrawerOpen}
                designDrawer={designDrawer}
                popperOpen={popperOpen}
                elementDrawer={elementDrawer}
                previewButton={formTemplateDialog}
                setState={setState}
                handlerPreview={handlerPreview}
                openForms={openForms}
            />
            <DragDropContext onDragEnd={onDragEnd}>
                <Grid
                    container
                    spacing={2}
                    sx={{
                        background: pageColor,
                        minHeight: "100vh",
                        paddingTop: "65px",
                    }}
                >
                    <Grid item xs={3}>
                        <FormElementDrawer
                            formList={formList}
                            open={elementDrawer}
                            closeDrawer={setState}
                            elementList={elementList}
                            basicElement={basicElement}
                            openForms= {state.openForms}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Box className="formNameBox">
                            <TextFieldComponent
                                id="formStudioFormName"
                                sx={{
                                    display: editFormName
                                        ? "block"
                                        : "none",
                                }}
                                onBlur={onBlur}
                                onChange={(e) =>
                                    setState((prevState) => ({
                                        ...prevState,
                                        formName: e.target.value,
                                    }))
                                }
                                value={formName}
                                className="textFieldFormName"
                                autoFocus={true}
                                placeholder={userLoggedIn?.language_data?.formstudio?.enter_form_name + "..."}
                            />
                            <TooltipComponent
                                title={<Translate>"Click For Edit Form Name"</Translate>}
                                placeholder="bottom-start"
                                arrow={true}
                            >
                                {formName ? (
                                    <Typography
                                        display={
                                            editFormName
                                                ? "none"
                                                : "block"
                                        }
                                        className="typoFormName"
                                        onClick={handlerIsEdit}
                                        placeholder={<Translate>{formName}</Translate>}
                                    >
                                        {formName}
                                    </Typography>
                                ) : (
                                    <Typography
                                        display={
                                            editFormName
                                                ? "none"
                                                : "block"
                                        }
                                        sx={{ opacity: "0.5" }}
                                        className="typoFormName"
                                        onClick={handlerIsEdit}
                                    >
                                        {<Translate>{userLoggedIn?.language_data?.formstudio?.enter_form_name + "..."}</Translate>}
                                    </Typography>
                                )}
                            </TooltipComponent>
                        </Box>
                        <Box id="formTemplate">
                            <Droppable droppableId={"formBox"}>
                                {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                    >
                                        <Box
                                            className="builderContainer"
                                            sx={{
                                                marginTop: "25px",
                                                background: formColor,
                                            }}
                                        >
                                            {formElements?.length ? (
                                                formElements?.map(
                                                    (item, index) => (
                                                        <Draggable
                                                            key={index}
                                                            draggableId={
                                                                item?.id
                                                            }
                                                            index={
                                                                index
                                                            }
                                                        >
                                                            {(
                                                                provided,
                                                                sanpshot
                                                            ) =>
                                                                renderFormElements(
                                                                    provided,
                                                                    snapshot,
                                                                    item,
                                                                    index
                                                                )
                                                            }
                                                        </Draggable>
                                                    )
                                                )
                                            ) : (
                                                <Box
                                                    py={5}
                                                    className="centerPositionBox"
                                                >
                                                    <Box
                                                        gap={1}
                                                        className="dragBox"
                                                    >
                                                        <AiOutlineDrag className="addLogoText" />
                                                        <Typography className="addLogoText">
                                                            {<Translate>{` Drag your first Element here from Form Element`}</Translate>}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            )}
                                        </Box>
                                    </div>
                                )}
                            </Droppable>
                        </Box>
                        <Box className={"saveButtonContainer"}>
                            {location?.state?.formData ? (
                                <ButtonComponent
                                    variant="contained"
                                    title={<Translate>{"Update"}</Translate>}
                                    onClick={updateFormButton}
                                    id="formStudioUpdateButton"
                                />
                            ) : (
                                <ButtonComponent
                                    id="formStudioSaveButton"
                                    variant="contained"
                                    title={<Translate>{userLoggedIn?.language_data?.comman?.save_btn}</Translate>}
                                    onClick={saveFormButton}
                                    disabled={
                                        formElements.length
                                            ? false
                                            : true
                                    }
                                />
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={3}>
                        <DesignDrawer
                            open={designDrawer}
                            formStates={setState}
                            fontColor={fontColor}
                            formColor={formColor}
                            pageColor={pageColor}
                            color={color}
                        />
                    </Grid>
                </Grid>
            </DragDropContext>
        </Box>
    )}
</>

    );
};

const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,
        formDetail: state.formStudio.formDetails,
        companyLogo: state?.whiteLabel?.logo,
        formList: state.formStudio.formsList,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        { createForm, updateForm, getFormById },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(FormStudio);
