import React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import NoInfoAvailable from "../../components/NoInfoAvailable";
import "../../../assets/styles/Container/ContractorEmail.scss";
import { connect } from "react-redux";
const EmailHistory = (props) => {
    const { emailLogData, userLoggedIn} = props;
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("sm"));
    const pathName = window?.location?.pathname?.split("/");

    return (
        <Box height={matches ? "670px" : "auto"} className="emailHistory">
            <Box py={2} >
                <Typography className="emailLogValue">{userLoggedIn?.language_data?.emailtemplates?.email_log || "Email Log"}</Typography>
            </Box>
            {emailLogData?.length && !pathName[1].includes("create") ? (
                emailLogData.map((obj) => (
                    <Grid container>
                        <Grid
                            item
                            display={"flex"}
                            justifyContent={"flex-start"}
                        >
                            <Typography className="emailLogValue">
                                {obj?.log_label} :
                            </Typography>
                        </Grid>
                        <Grid
                            item
                        >
                            <Typography className="emailLogValue">
                                {obj?.user_name}
                            </Typography>
                             <Typography ml={0.5} className="emailLogDate">
                                {obj?.created_at}
                            </Typography>
                        </Grid>
                    </Grid>
                ))
            ) : (
                <NoInfoAvailable
                    className="noCustomerInfo"
                    noInfoTypo="noInfoTypeQuestion"
                />
            )}
        </Box>
    );
};

const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,

    };
};



export default connect(mapStateToProps, null) (EmailHistory);
