import React, { Fragment, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Avatar from "@mui/material/Avatar";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import RadioButtonGroup from "../../components/RadioButtonGroup/RadioButtonGroup";
import TextFieldComponent from "../../components/TextField/TextFieldComponent";
import ButtonComponent from "../../components/ButtonComponent";
import NewUserAvatar from "../../../assets/Images/Avatar/NewUserAvatar.png";
import DropDownComponent from "../../components/DropDown/DropDownComponent";
import Navbar from "../../common/Header/Navbar";
import PageHeader from "../../common/PageHeader";
import "../../../assets/styles/Container/CustomerCard.scss";
import CheckboxComponents from "../../components/Checkbox/CheckboxComponent";
import FileUpload from "../../components/FileUpload";
import LoadingComponent from "./../../components/Loading/LoadingComponent";
import { ROUTES } from "../../router/RouteList";
import {
    getCustomerById,
    setSelectedCustomer,
    getUsersByCustomerId,
} from "../../store/Action/CustomerAction";
import {
    createUserAction,
    updateUserById,
    setSelectedUser,
    getUserById,
} from "../../store/Action/UserAction";
import {
    genderOptions,
    yesNoOptions,
    contractorToContractorOptions,
    employmentTypeField,
} from "../../utils/CommonData";

function CreateUser(props) {
    const {
        userLoggedIn,
        getUsersByCustomerId,
        createUserAction,
        updateUserById,
        commonDropdown,
        selectedCustomer,
        getCustomerById,
        setSelectedCustomer,
        selectedUser,
        setSelectedUser,
        getUserById,
        userList,
    } = props;

    const type = selectedUser?.type;
    const navigate = useNavigate();

    const [state, setState] = useState({
        id: type ? selectedUser?.id : "",
        client_id: type ? selectedUser?.client_id : selectedCustomer?.id || "",
        first_name: type ? selectedUser?.first_name : "",
        // middle_name: type ? selectedUser?.middle_name : "",
        last_name: type ? selectedUser?.last_name : "",
        // display_name: type ? selectedUser?.display_name : "",
        email: type ? selectedUser?.email : "",
        phone: type ? selectedUser?.phone : "",
        work_phone: type ? selectedUser?.work_phone : "",
        fax: type ? selectedUser?.fax : "",
        gender: type ? selectedUser?.gender : "",
        dob: type ? selectedUser?.dob : "",
        contracting_business_manager: type
            ? selectedUser?.contracting_business_manager
            : "",
        company_info: type
            ? selectedUser?.company_info
            : {
                name: selectedCustomer?.company_info?.company_name,
                website: selectedCustomer?.company_info?.company_website,
                principle_place_of_business:
                    selectedCustomer?.company_info
                        ?.principle_place_of_business,
            },
        address: type
            ? selectedUser?.address
            : {
                line1: "",
                line2: "",
                city: "",
                state: "",
                zip: "",
            },
        role_id: type ? selectedUser?.role_id : "",
        user_title: type ? selectedUser?.user_title : "",
        // c2c: type ? selectedUser?.c2c : "",
        details: type
            ? selectedUser?.details
            : {
                language: selectedCustomer?.language || "English",
                collar_id: "",
                employment_type: employmentTypeField,
                job_location: "onsite",
                onboard_representative: false,
                sign_on_behalf: false,
                party_details: type
                    ? selectedUser?.details?.party_details
                    : {
                        first_name: "",
                        // middle_name: "",
                        last_name: "",
                        // display_name: "",
                        email: "",
                        phone: "",
                        work_phone: "",
                        fax: "",
                        gender: "",
                        dob: "",
                        company_info: type
                            ? selectedUser?.details?.party_details
                                ?.company_info
                            : {
                                name: "",
                                website: "",
                                principle_place_of_business: "",
                            },
                        address: type
                            ? selectedUser?.details?.party_details?.address
                            : {
                                line1: "",
                                line2: "",
                                city: "",
                                state: "",
                                zip: "",
                            },
                    },
            },
        compliance_service: type
            ? selectedUser?.compliance_service
            : ["pcp", "dcp", "rcp", "tcp"],
        photograph:
            type && selectedUser?.photograph
                ? [{ preview: selectedUser?.photograph }]
                : null,
        loading: false,
    });

    const {
        first_name,
        // middle_name,
        last_name,
        email,
        phone,
        work_phone,
        fax,
        gender,
        dob,
        company_info,
        address,
        role_id,
        user_title,
        // c2c,
        details,
        compliance_service,
        // display_name,
        id,
        loading,
        contracting_business_manager,
        photograph,
    } = state;

    useEffect(() => {
        const pathName = window.location.search.split("?");
        const pageType = window.location.pathname.split("/");
        async function getData(id) {
            setState((prevState) => ({ ...prevState, loading: true }));
            await getCustomerById(id);
            setState((prevState) => ({ ...prevState, loading: false }));
        }
        async function callApi() {
            setState((prevState) => ({ ...prevState, loading: true }));
            await getUserById(parseInt(pathName[1]), pageType[2]);
            setState((prevState) => ({ ...prevState, loading: false }));
        }
        if (
            !selectedUser &&
            pathName &&
            pathName?.length > 1 &&
            pageType[2] !== "create"
        ) {
            callApi();
        }
        if (
            !selectedCustomer &&
            pathName &&
            pathName?.length > 1 &&
            pageType[2] === "create"
        ) {
            getData(pathName[1]);
        }
        if (!selectedCustomer && pageType[2] !== "create" && selectedUser) {
            getData(selectedUser?.client_id);
        }
        if (
            (selectedCustomer?.id && pageType[2] !== "create") ||
            (pathName[1] && pageType[2] === "create")
        ) {
            getUsersByCustomerId(selectedCustomer?.id || parseInt(pathName[1]));
        }
    }, [
        getUsersByCustomerId,
        selectedUser,
        selectedCustomer,
        getUserById,
        getCustomerById,
    ]);

    if (
        selectedUser &&
        selectedUser?.photograph &&
        selectedUser?.type !== "create" &&
        id === ""
    ) {
        setState((prevState) => ({
            ...prevState,
            ...selectedUser,
            photograph:
                type && selectedUser?.photograph
                    ? [{ preview: selectedUser?.photograph }]
                    : null,
        }));
    }

    if (
        selectedCustomer &&
        (company_info?.name === undefined || company_info?.name === "")
    ) {
        setState((prevState) => ({
            ...prevState,
            company_info: selectedCustomer?.company_info,
            details: {
                ...details,
                party_details: {
                    ...details?.party_details,
                    company_info: selectedCustomer?.company_info,
                },
            },
            client_id: selectedCustomer?.id,
        }));
    }

    async function handleSubmit() {
        setState((prevState) => ({ ...prevState, loading: true }));
        let res;
        let newObj = {
            ...state,
            photograph: photograph?.length
                ? photograph[0]?.content
                    ? photograph[0] || null
                    : null
                : null,
        };
        if (type === "edit") res = await updateUserById(newObj);
        else {
            delete state.id;
            res = await createUserAction(newObj);
        }
        setState((prevState) => ({ ...prevState, loading: false }));
        if (res)
            navigate(
                ROUTES.USER + `${state.client_id ? "?" + state.client_id : ""}`,
                { state: state.client_id || "" }
            );
        await setSelectedCustomer(null);
        await setSelectedUser(null);
    }

    const handleSelectAllChecked = (name) => {
        let serviceData = compliance_service?.length && compliance_service?.find((obj) => obj === name);
        let service = compliance_service;
        if (serviceData) {
            service = compliance_service?.filter(
                (obj) => obj !== name && obj !== "tcp"
            );
        } else {
            if (name === "tcp") service = ["pcp", "dcp", "rcp", "tcp"];
            else service.push(name);
        }
        setState((prevState) => ({
            ...prevState,
            compliance_service: service,
        }));
    };
    function handleChecked(option) {
        console.log("compliance_service",typeof compliance_service)
        let data = compliance_service?.length && compliance_service?.find((obj) => obj === option);
        return !!data;
    }
    function filterUserList() {
        if (role_id === 2) {
            console.log("userList", userList);
            let newList = userList?.data?.filter((obj) => obj?.role_id === 44);
            return newList;
        } else {
            let data = commonDropdown?.roles?.find(
                (obj) => obj.label === "Contractor"
            );
            console.log("role_id", role_id);
            let newList = userList?.data
                ? userList?.data?.filter((obj) => obj?.role_id !== data?.id)
                : userList?.filter((obj) => obj?.role_id !== data?.id);
            return newList;
        }
    }
    function handleProfileUpload(file) {
        const reader = new FileReader();
        reader.readAsDataURL(file[0]);
        let newData;
        newData = file.map((fileObj) =>
            Object.assign(fileObj, {
                preview: URL.createObjectURL(fileObj),
            })
        );
        reader.onload = () => {
            let fileUrl = reader.result;
            newData = {
                ...newData[0],
                content: fileUrl,
                filename: file[0].name,
                type: file[0].type,
            };
            setState((prevState) => ({ ...prevState, photograph: [newData] }));
        };
        setState((prevState) => ({ ...prevState, photograph: newData }));
    }

    const handleChangeRepresentative = (event) => {
        if (event.target.value === "yes") {
            setState((prevState) => ({
                ...prevState,
                details: {
                    ...details,
                    onboard_representative: true,
                },
            }));
        } else if (event.target.value === "no") {
            setState((prevState) => ({
                ...prevState,
                details: {
                    ...details,

                    employment_type: employmentTypeField,
                    onboard_representative: false,
                    sign_on_behalf: false,
                    party_details: type
                        ? selectedUser?.details?.party_details
                        : {
                            ...state.details.party_details,
                            first_name: "",
                            //   middle_name: "",
                            last_name: "",
                            //   display_name: "",
                            email: "",
                            phone: "",
                            work_phone: "",
                            fax: "",
                            gender: "",
                            dob: "",
                            address: type
                                ? selectedUser?.details?.party_details
                                    ?.address
                                : {
                                    line1: "",
                                    line2: "",
                                    city: "",
                                    state: "",
                                    zip: "",
                                },
                        },
                },
            }));
        }
    };

    return (
        <Fragment>
            <Navbar />
            <Box sx={{ px: { md: 7, xs: 1 } }}>
                <PageHeader
                    title={`${type === "edit"
                        ? "Edit"
                        : type === "view"
                            ? "View"
                            : "New"
                        } User`}
                />
                {loading ? (
                    <LoadingComponent sx={{ height: "72%", width: "95%" }} />
                ) : (
                    <Grid container className="userGrid">
                        <Grid
                            item
                            xs={12}
                            sx={{ display: "flex", justifyContent: "center" }}
                        >
                            {selectedCustomer?.logo ? (
                                <img
                                    src={selectedCustomer?.logo}
                                    alt="Company Logo"
                                    className="clientLogo"
                                />
                            ) : null}
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            {selectedCustomer?.company_info?.name ? (
                                <Typography className="clientNameHeading">
                                    {selectedCustomer?.company_info?.name}
                                </Typography>
                            ) : (
                                ""
                            )}
                        </Grid>
                        <Grid item>
                            <Typography className="formSubHeading">
                                {userLoggedIn?.language_data?.user
                                    ?.user_profile || "User Profile"}
                            </Typography>
                        </Grid>
                        <Grid container className="profileImageDiv">
                            <Grid
                                item
                                xs={12}
                                lg={2}
                                md={2}
                                sm={12}
                                pt={1}
                                className="profileImageDiv"
                            >
                                <div className="profileUpload">
                                    <FileUpload
                                        id="userProfileImage"
                                        file={photograph}
                                        setFiles={setState}
                                        uploadIcon={
                                            <div>
                                                <Avatar
                                                    className="profileImage"
                                                    alt="No Photo"
                                                    src={NewUserAvatar}
                                                    variant="square"
                                                />
                                                <EditOutlinedIcon className="profileIcon" />
                                            </div>
                                        }
                                        keyName={"photograph"}
                                        validation={"image/*"}
                                        showEdit={true}
                                        handleFileUpload={handleProfileUpload}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} lg={10} md={10} sm={12}>
                                <Grid container px={3}>
                                    {/* <Grid
                                        item
                                        xs={12}
                                        lg={6}
                                        md={6}
                                        sm={12}
                                        sx={{ padding: "10px" }}
                                    >
                                        <DropDownComponent
                                            label={"Salutation(Mr./ Mrs./ Ms.)"}
                                            name={"userTitle"}
                                            required={false}
                                            value={user_title}
                                            fullWidth={true}
                                            disabled={
                                                type === "view" ? true : false
                                            }
                                            onChange={(event) =>
                                                setState((prevState) => ({
                                                    ...prevState,
                                                    user_title:
                                                        event.target.value,
                                                }))
                                            }
                                            id="userTitle"
                                            children={[
                                                "Mr.",
                                                "Mrs.",
                                                "Ms.",
                                            ].map((option) => (
                                                <MenuItem
                                                    id={`userTitle${option}`}
                                                    key={option}
                                                    value={option}
                                                >
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        />
                                    </Grid> */}
                                    <Grid
                                        item
                                        xs={12}
                                        lg={6}
                                        md={6}
                                        sm={12}
                                        sx={{ padding: "10px" }}
                                    >
                                        <TextFieldComponent
                                            id="userFirstName"
                                            onChange={(event) =>
                                                setState((prevState) => ({
                                                    ...prevState,
                                                    first_name:
                                                        event.target.value,
                                                    // display_name:
                                                    //     event.target.value +
                                                    //         " " +
                                                    //         last_name || "",
                                                }))
                                            }
                                            required={true}
                                            value={first_name}
                                            label={
                                                userLoggedIn?.language_data
                                                    ?.customer?.first_name ||
                                                "First Name"
                                            }
                                            fullWidth={true}
                                            disabled={
                                                type === "view" ? true : false
                                            }
                                        />
                                    </Grid>
                                    {/* <Grid
                                        item
                                        xs={12}
                                        lg={6}
                                        md={6}
                                        sm={12}
                                        sx={{ padding: "10px" }}
                                    >
                                        <TextFieldComponent
                                            id="userMiddleName"
                                            onChange={(event) =>
                                                setState((prevState) => ({
                                                    ...prevState,
                                                    middle_name:
                                                        event.target.value,
                                                }))
                                            }
                                            label={
                                                userLoggedIn?.language_data
                                                    ?.customer?.middle_name ||
                                                "Middle Name"
                                            }
                                            value={middle_name}
                                            disabled={
                                                type === "view" ? true : false
                                            }
                                        />
                                    </Grid> */}
                                    <Grid
                                        item
                                        xs={12}
                                        lg={6}
                                        md={6}
                                        sm={12}
                                        sx={{ padding: "10px" }}
                                    >
                                        <TextFieldComponent
                                            id="userLastName"
                                            onChange={(event) =>
                                                setState((prevState) => ({
                                                    ...prevState,
                                                    last_name:
                                                        event.target.value,
                                                    // display_name:
                                                    //     first_name +
                                                    //     " " +
                                                    //     event.target.value,
                                                }))
                                            }
                                            required={true}
                                            value={last_name}
                                            label={
                                                userLoggedIn?.language_data
                                                    ?.customer?.last_name ||
                                                "Last Name"
                                            }
                                            disabled={
                                                type === "view" ? true : false
                                            }
                                        />
                                    </Grid>
                                    {/* <Grid
                                        item
                                        xs={12}
                                        lg={6}
                                        md={6}
                                        sm={12}
                                        sx={{ padding: "10px" }}
                                    >
                                        <TextFieldComponent
                                            id="userDisplayName"
                                            onChange={(event) =>
                                                setState((prevState) => ({
                                                    ...prevState,
                                                    display_name:
                                                        event.target.value,
                                                }))
                                            }
                                            label={
                                                userLoggedIn?.language_data
                                                    ?.customer?.display_name ||
                                                "Display Name"
                                            }
                                            required={true}
                                            value={display_name}
                                            disabled={
                                                type === "view" ? true : false
                                            }
                                        />
                                    </Grid> */}
                                    {/* <Grid
                                        item
                                        xs={12}
                                        lg={6}
                                        md={6}
                                        sm={12}
                                        sx={{ padding: "10px" }}
                                    >
                                        <DropDownComponent
                                            id="userGender"
                                            label={
                                                userLoggedIn?.language_data
                                                    ?.customer?.gender ||
                                                "Gender"
                                            }
                                            name={"Gender"}
                                            fullWidth={true}
                                            onChange={(event) =>
                                                setState((prevState) => ({
                                                    ...prevState,
                                                    gender: event.target.value,
                                                }))
                                            }
                                            required={true}
                                            value={gender || ""}
                                            disabled={
                                                type === "view" ? true : false
                                            }
                                            children={genderOptions.map(
                                                (option) => (
                                                    <MenuItem
                                                        key={option.id}
                                                        value={option.id}
                                                        id={`userGender_${option.name}`}
                                                    >
                                                        {option.name}
                                                    </MenuItem>
                                                )
                                            )}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        lg={6}
                                        md={6}
                                        sm={12}
                                        sx={{ padding: "10px" }}
                                    >
                                        <DatePickerComponent
                                            id="userDob"
                                            onChange={(event) => {
                                                let date = new Date(event);
                                                setState((prevState) => ({
                                                    ...prevState,
                                                    dob: moment(date).format(
                                                        "YYYY-MM-DD"
                                                    ),
                                                }));
                                            }}
                                            maxDate={new Date()}
                                            value={dob}
                                            label={
                                                userLoggedIn?.language_data
                                                    ?.customer?.date_of_birth ||
                                                "Date of Birth"
                                            }
                                            required={true}
                                            disabled={
                                                type === "view" ? true : false
                                            }
                                        />
                                    </Grid> */}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Typography className="formSubHeading">
                                {userLoggedIn?.language_data?.customer
                                    ?.company_info || "Company Information"}
                            </Typography>
                        </Grid>
                        <Grid container px={3}>
                            <Grid
                                item
                                xs={12}
                                lg={6}
                                md={6}
                                sm={12}
                                sx={{ padding: "10px" }}
                            >
                                <TextFieldComponent
                                    id="userCompanyName"
                                    label={
                                        userLoggedIn?.language_data?.customer
                                            ?.company_name ||
                                        "Contact Information"
                                    }
                                    disabled={selectedCustomer ? true : false}
                                    required={selectedCustomer ? true : false}
                                    value={company_info?.name}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                lg={6}
                                md={6}
                                sm={12}
                                sx={{ padding: "10px" }}
                            >
                                <TextFieldComponent
                                    id="userCompanyWebsite"
                                    label={
                                        userLoggedIn?.language_data?.customer
                                            ?.company_website ||
                                        "Company Website"
                                    }
                                    disabled={selectedCustomer ? true : false}
                                    required={selectedCustomer ? true : false}
                                    value={company_info?.website}
                                />
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Typography className="formSubHeading">
                                {userLoggedIn?.language_data?.customer
                                    ?.contact_information ||
                                    "Contact Information"}
                            </Typography>
                        </Grid>
                        <Grid container px={3}>
                            <Grid
                                item
                                xs={12}
                                lg={6}
                                md={6}
                                sm={12}
                                sx={{ padding: "10px" }}
                            >
                                <TextFieldComponent
                                    onChange={(event) =>
                                        setState((prevState) => ({
                                            ...prevState,
                                            address: {
                                                ...address,
                                                line1: event.target.value,
                                            },
                                        }))
                                    }
                                    label={`${userLoggedIn?.language_data?.customer
                                        ?.address_line || "Address Line"
                                        } 1`}
                                    required={false}
                                    value={address.line1}
                                    disabled={type === "view" ? true : false}
                                    id="userAddress"
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                lg={6}
                                md={6}
                                sm={12}
                                sx={{ padding: "10px" }}
                            >
                                <TextFieldComponent
                                    id="userEmailAddress"
                                    onChange={(event) =>
                                        setState((prevState) => ({
                                            ...prevState,
                                            email: event.target.value,
                                        }))
                                    }
                                    label={
                                        userLoggedIn?.language_data?.customer
                                            ?.email_address || "Email Address"
                                    }
                                    required={true}
                                    value={email}
                                    disabled={type === "view" ? true : false}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                lg={6}
                                md={6}
                                sm={12}
                                sx={{ padding: "10px" }}
                            >
                                <TextFieldComponent
                                    id="userAddress1"
                                    onChange={(event) =>
                                        setState((prevState) => ({
                                            ...prevState,
                                            address: {
                                                ...address,
                                                line2: event.target.value,
                                            },
                                        }))
                                    }
                                    label={`${userLoggedIn?.language_data?.customer
                                        ?.address_line || "Address Line"
                                        } 2`}
                                    value={address.line2}
                                    disabled={type === "view" ? true : false}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                lg={6}
                                md={6}
                                sm={12}
                                sx={{ padding: "10px" }}
                            >
                                <TextFieldComponent
                                    onChange={(event) =>
                                        setState((prevState) => ({
                                            ...prevState,
                                            phone: event.target.value,
                                        }))
                                    }
                                    id="userMobileNumber"
                                    label={
                                        userLoggedIn?.language_data?.customer
                                            ?.mobile_number || "Mobile Number"
                                    }
                                    required={false}
                                    value={phone}
                                    disabled={type === "view" ? true : false}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                lg={3}
                                md={3}
                                sm={12}
                                sx={{ padding: "10px" }}
                            >
                                <TextFieldComponent
                                    id="userCity"
                                    onChange={(event) =>
                                        setState((prevState) => ({
                                            ...prevState,
                                            address: {
                                                ...address,
                                                city: event.target.value,
                                            },
                                        }))
                                    }
                                    label={
                                        userLoggedIn?.language_data?.customer
                                            ?.city || "City"
                                    }
                                    value={address.city}
                                    required={false}
                                    disabled={type === "view" ? true : false}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                lg={3}
                                md={3}
                                sm={12}
                                sx={{ padding: "10px" }}
                            >
                                <DropDownComponent
                                    label={
                                        userLoggedIn?.language_data?.company
                                            ?.state || "State"
                                    }
                                    name={"State"}
                                    fullWidth={true}
                                    onChange={(event) =>
                                        setState((prevState) => ({
                                            ...prevState,
                                            address: {
                                                ...address,
                                                state: event.target.value,
                                            },
                                        }))
                                    }
                                    id="userState"
                                    required={true}
                                    value={address?.state || ""}
                                    disabled={type === "view" ? true : false}
                                    children={commonDropdown?.states?.map(
                                        (option) => (
                                            <MenuItem
                                                key={option.id}
                                                value={option.id}
                                                id={`userState${option.label}`}
                                            >
                                                {option.label}
                                            </MenuItem>
                                        )
                                    )}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                lg={3}
                                md={3}
                                sm={12}
                                sx={{ padding: "10px" }}
                            >
                                <TextFieldComponent
                                    id="userWorkPhone"
                                    onChange={(event) =>
                                        setState((prevState) => ({
                                            ...prevState,
                                            work_phone: event.target.value,
                                        }))
                                    }
                                    label={
                                        userLoggedIn?.language_data?.customer
                                            ?.work_phone || "Work Phone"
                                    }
                                    required={false}
                                    value={work_phone}
                                    disabled={type === "view" ? true : false}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                lg={3}
                                md={3}
                                sm={12}
                                sx={{ padding: "10px" }}
                            >
                                <TextFieldComponent
                                    id="userFax"
                                    onChange={(event) =>
                                        setState((prevState) => ({
                                            ...prevState,
                                            fax: event.target.value,
                                        }))
                                    }
                                    label={
                                        userLoggedIn?.language_data?.customer
                                            ?.fax || "Fax"
                                    }
                                    value={fax}
                                    disabled={type === "view" ? true : false}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                lg={3}
                                md={3}
                                sm={12}
                                sx={{ padding: "10px" }}
                            >
                                <TextFieldComponent
                                    id="userZipCode"
                                    onChange={(event) =>
                                        setState((prevState) => ({
                                            ...prevState,
                                            address: {
                                                ...address,
                                                zip: event.target.value,
                                            },
                                        }))
                                    }
                                    label={
                                        userLoggedIn?.language_data?.customer
                                            ?.zi_code || "Zip Code"
                                    }
                                    required={false}
                                    value={address.zip}
                                    disabled={type === "view" ? true : false}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                lg={3}
                                md={3}
                                sm={12}
                                sx={{ padding: "10px" }}
                            >
                                <DropDownComponent
                                    label={`Select ${userLoggedIn?.language_data?.customer
                                        ?.preferred_language ||
                                        "Preferred Language"
                                        }`}
                                    id="userPreferredLanguage"
                                    name={"language"}
                                    fullWidth={true}
                                    onChange={(event) =>
                                        setState((prevState) => ({
                                            ...prevState,
                                            details: {
                                                ...details,
                                                language: event.target.value,
                                            },
                                        }))
                                    }
                                    value={details?.language || ""}
                                    disabled={type !== "view" ? false : true}
                                    required={true}
                                    children={commonDropdown?.languages?.map(
                                        (option) => (
                                            <MenuItem
                                                key={option.id}
                                                value={option.id}
                                                id={`userPreferredLanguage${option.id}`}
                                            >
                                                {option.label}
                                            </MenuItem>
                                        )
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <Typography className="formSubHeading">
                            {userLoggedIn?.language_data?.user
                                ?.office_information || "Office Information"}
                        </Typography>
                        <Grid container px={3}>
                            <Grid
                                item
                                xs={12}
                                lg={6}
                                md={6}
                                sm={12}
                                sx={{ padding: "10px" }}
                            >
                                <DropDownComponent
                                    id="userCollarColor"
                                    label={
                                        userLoggedIn?.language_data
                                            ?.complianceworkflow
                                            ?.collar_color || "Collor Color"
                                    }
                                    name={"Collor Color"}
                                    fullWidth={true}
                                    onChange={(event) =>
                                        setState((prevState) => ({
                                            ...prevState,
                                            details: {
                                                ...details,
                                                collar_id: event.target.value,
                                            },
                                        }))
                                    }
                                    required={true}
                                    value={details?.collar_id || ""}
                                    disabled={type === "view" ? true : false}
                                    children={commonDropdown?.collars
                                        ?.sort((a, b) =>
                                            a.label.localeCompare(b.label)
                                        )
                                        ?.map((option) => (
                                            <MenuItem
                                                key={option.id}
                                                value={option.id}
                                            >
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                lg={6}
                                md={6}
                                sm={12}
                                sx={{ padding: "10px" }}
                            >
                                <TextFieldComponent
                                    id="userEmploymentType"
                                    label={"Employment Type"}
                                    required={true}
                                    disabled
                                    value={
                                        details?.employment_type
                                            ? employmentTypeField
                                            : details?.employment_type
                                    }
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                lg={6}
                                md={6}
                                sm={12}
                                sx={{ padding: "10px" }}
                            >
                                <DropDownComponent
                                    id="userJobLocation"
                                    label={"Job Location"}
                                    name={"jobLocation"}
                                    fullWidth={true}
                                    onChange={(event) =>
                                        setState((prevState) => ({
                                            ...prevState,
                                            details: {
                                                ...details,
                                                job_location:
                                                    event.target.value,
                                            },
                                        }))
                                    }
                                    required={false}
                                    value={details?.job_location || ""}
                                    disabled={type === "view" ? true : false}
                                    children={commonDropdown?.location?.map(
                                        (option) => (
                                            <MenuItem
                                                id={`userJobLocation${option.id}`}
                                                key={option.id}
                                                value={option.id}
                                            >
                                                {option.label}
                                            </MenuItem>
                                        )
                                    )}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                lg={6}
                                md={6}
                                sm={12}
                                sx={{ padding: "10px" }}
                            >
                                <DropDownComponent
                                    id="userStates"
                                    label={"Principal place of business"}
                                    name={"jobStates"}
                                    fullWidth={true}
                                    onChange={(event) =>
                                        setState((prevState) => ({
                                            ...prevState,
                                            company_info: {
                                                ...company_info,
                                                principle_place_of_business:
                                                    event.target.value,
                                            },
                                        }))
                                    }
                                    required={true}
                                    value={
                                        company_info?.principle_place_of_business ||
                                        ""
                                    }
                                    disabled={type === "view" ? true : false}
                                    children={[
                                        // Custom option
                                        <MenuItem
                                            id="customOption"
                                            key="customOption"
                                            value={"To be determined"}
                                        >
                                            To be determined
                                        </MenuItem>,
                                        // Options from commonDropdown?.states
                                        ...(commonDropdown?.states?.map(
                                            (option) => (
                                                <MenuItem
                                                    id={`userStates${option.id}`}
                                                    key={option.id}
                                                    value={option.id}
                                                >
                                                    {option.label}
                                                </MenuItem>
                                            )
                                        ) || []),
                                    ]}
                                />

                                {/* Custom Menu Item */}
                                {/* <MenuItem value={'Value'}
                                    onChange={(event) => {
                                        alert(event?.target?.value)
                                        setState((prevState) => ({
                                            ...prevState,
                                            details: {
                                                ...details,
                                                principle_place_of_business: event.target.value,
                                            },
                                        }))
                                    }

                                    }

                                    id={`userState${0}`} >
                                        To be determined
                                    </MenuItem> */}

                                {/* Mapping Common Dropdown Options */}
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                lg={6}
                                md={6}
                                sm={12}
                                sx={{ padding: "10px" }}
                            >
                                <Typography
                                    sx={{
                                        fontWeight: "bold",
                                        fontSize: "16px",
                                    }}
                                >
                                    Compliance
                                </Typography>
                                <Grid container spacing={2}>
                                    {selectedCustomer?.details?.services &&
                                        selectedCustomer?.details?.services?.map(
                                            (option) => (
                                                <Grid item md={3}>
                                                    <h2>
                                                        <CheckboxComponents
                                                            onChange={() =>
                                                                handleSelectAllChecked(
                                                                    option
                                                                )
                                                            }
                                                            key={option}
                                                            name={option}
                                                            checked={handleChecked(
                                                                option
                                                            )}
                                                            id="userComplianceServices"
                                                            disabled={
                                                                type === "view"
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                        {option.toUpperCase()}
                                                    </h2>
                                                </Grid>
                                            )
                                        )}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Typography className="formSubHeading">
                                {userLoggedIn?.language_data?.user?.user_role ||
                                    "User Role"}
                            </Typography>
                        </Grid>
                        <Grid container px={3}>
                            <Grid
                                item
                                xs={12}
                                lg={6}
                                md={6}
                                sm={12}
                                sx={{ padding: "10px" }}
                            >
                                <DropDownComponent
                                    id="userSelectRole"
                                    label={"Select Role"}
                                    name={"role_id"}
                                    fullWidth={true}
                                    onChange={(event) =>
                                        setState((prevState) => ({
                                            ...prevState,
                                            role_id: event.target.value,
                                        }))
                                    }
                                    required={true}
                                    value={role_id}
                                    disabled={type === "view" ? true : false}
                                    children={commonDropdown?.roles?.map(
                                        (option) => (
                                            <MenuItem
                                                key={option.id}
                                                value={option.id}
                                            >
                                                {option.label}
                                            </MenuItem>
                                        )
                                    )}
                                />
                            </Grid>
                            {/* <Grid
                                item
                                xs={12}
                                lg={6}
                                md={6}
                                sm={12}
                                sx={{ padding: "10px" }}
                            >
                                <DropDownComponent
                                    label={"C2C(Contractor to Contrator)"}
                                    name={"c2c"}
                                    id="userC2c"
                                    fullWidth={true}
                                    onChange={(event) =>
                                        setState((prevState) => ({
                                            ...prevState,
                                            c2c:
                                                event.target.value === "y"
                                                    ? true
                                                    : false,
                                        }))
                                    }
                                    required={false}
                                    value={c2c ? "y" : !c2c ? "n" : ""}
                                    disabled={type === "view" ? true : false}
                                    children={contractorToContractorOptions.map(
                                        (option) => (
                                            <MenuItem
                                                key={option.id}
                                                value={option.id}
                                            >
                                                {option.name}
                                            </MenuItem>
                                        )
                                    )}
                                />
                            </Grid> */}
                            <Grid
                                item
                                xs={12}
                                lg={6}
                                md={6}
                                sm={12}
                                sx={{ padding: "10px" }}
                            >
                                <DropDownComponent
                                    label={"Select Manager"}
                                    id="userContractingBusinessManage"
                                    name={"contracting_business_manager"}
                                    fullWidth={true}
                                    onChange={(event) =>
                                        setState((prevState) => ({
                                            ...prevState,
                                            contracting_business_manager:
                                                event.target.value,
                                        }))
                                    }
                                    required={true}
                                    value={contracting_business_manager}
                                    disabled={type === "view" ? true : false}
                                    children={filterUserList()?.map(
                                        (option) => (
                                            <MenuItem
                                                key={option.id}
                                                value={option.id}
                                            >
                                                {option.display_name}
                                            </MenuItem>
                                        )
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <Grid container pl={3}>
                            {/* <Grid
                                item
                                xs={12}
                                lg={6}
                                md={6}
                                sm={12}
                                sx={{ padding: "10px" }}
                            >
                                <RadioButtonGroup
                                    id="userYesNoOptions"
                                    row={true}
                                    title={
                                        userLoggedIn?.language_data?.user
                                            ?.do_we_have_individual ||
                                        "Do we have an individual or entity to represent on behalf of the contractor?"
                                    }
                                    options={yesNoOptions}
                                    defaultValue={"no"}
                                    onChange={(event) =>
                                        handleChangeRepresentative(event)
                                    }
                                    value={
                                        details?.onboard_representative
                                            ? "yes"
                                            : "no"
                                    }
                                    disabled={type === "view" ? true : false}
                                />
                            </Grid> */}
                            {details.onboard_representative === true && (
                                <Grid
                                    item
                                    xs={12}
                                    lg={6}
                                    md={6}
                                    sm={12}
                                    sx={{ padding: "10px" }}
                                >
                                    <RadioButtonGroup
                                        row={true}
                                        id="userYesNoOptions2"
                                        title="Do you want an individual or entity to sign on behalf of the contractor?"
                                        options={yesNoOptions}
                                        defaultValue={"no"}
                                        onChange={(event) =>
                                            setState((prevState) => ({
                                                ...prevState,
                                                details: {
                                                    ...details,
                                                    sign_on_behalf:
                                                        event.target.value ===
                                                            "yes"
                                                            ? true
                                                            : false,
                                                },
                                            }))
                                        }
                                        value={
                                            details?.sign_on_behalf
                                                ? "yes"
                                                : "no"
                                        }
                                        disabled={
                                            type === "view" ? true : false
                                        }
                                    />
                                </Grid>
                            )}
                        </Grid>
                        {details.onboard_representative === true && (
                            <Fragment>
                                <Grid item>
                                    <Typography className="formSubHeading">
                                        Representing Party User Profile
                                    </Typography>
                                </Grid>
                                <Grid container sx={{ display: "flex" }}>
                                    <Grid container px={3}>
                                        <Grid
                                            item
                                            xs={12}
                                            lg={6}
                                            md={6}
                                            sm={12}
                                            sx={{ padding: "10px" }}
                                        >
                                            <TextFieldComponent
                                                id="userPartyPersonalInfFirstName"
                                                onChange={(event) =>
                                                    setState((prevState) => ({
                                                        ...prevState,
                                                        details: {
                                                            ...details,
                                                            party_details: {
                                                                ...details?.party_details,
                                                                first_name:
                                                                    event.target
                                                                        .value,
                                                                // display_name:
                                                                //     event.target
                                                                //         .value +
                                                                //     " " +
                                                                //     details
                                                                //         ?.party_details
                                                                //         ?.last_name,
                                                            },
                                                        },
                                                    }))
                                                }
                                                required={true}
                                                value={
                                                    details?.party_details
                                                        ?.first_name
                                                }
                                                label={
                                                    userLoggedIn?.language_data
                                                        ?.customer
                                                        ?.first_name ||
                                                    "First Name"
                                                }
                                                disabled={
                                                    type === "view"
                                                        ? true
                                                        : false
                                                }
                                            />
                                        </Grid>
                                        {/* <Grid
                                            item
                                            xs={12}
                                            lg={6}
                                            md={6}
                                            sm={12}
                                            sx={{ padding: "10px" }}
                                        >
                                            <TextFieldComponent
                                                id="userPartyPersonalInfMiddleName"
                                                onChange={(event) =>
                                                    setState((prevState) => ({
                                                        ...prevState,
                                                        details: {
                                                            ...details,
                                                            party_details: {
                                                                ...details?.party_details,
                                                                middle_name:
                                                                    event.target
                                                                        .value,
                                                            },
                                                        },
                                                    }))
                                                }
                                                value={
                                                    details?.party_details
                                                        ?.middle_name
                                                }
                                                label={
                                                    userLoggedIn?.language_data
                                                        ?.customer
                                                        ?.middle_name ||
                                                    "Middle Name"
                                                }
                                                disabled={
                                                    type === "view"
                                                        ? true
                                                        : false
                                                }
                                            />
                                        </Grid> */}
                                        <Grid
                                            item
                                            xs={12}
                                            lg={6}
                                            md={6}
                                            sm={12}
                                            sx={{ padding: "10px" }}
                                        >
                                            <TextFieldComponent
                                                onChange={(event) =>
                                                    setState((prevState) => ({
                                                        ...prevState,
                                                        details: {
                                                            ...details,
                                                            party_details: {
                                                                ...details?.party_details,
                                                                last_name:
                                                                    event.target
                                                                        .value,
                                                                // display_name:
                                                                //     details
                                                                //         ?.party_details
                                                                //         ?.first_name +
                                                                //     " " +
                                                                //     event.target
                                                                //         .value,
                                                            },
                                                        },
                                                    }))
                                                }
                                                id="userPartyPersonalInfLastName"
                                                label={
                                                    userLoggedIn?.language_data
                                                        ?.customer?.last_name
                                                }
                                                required={true}
                                                value={
                                                    details?.party_details
                                                        ?.last_name
                                                }
                                                disabled={
                                                    type === "view"
                                                        ? true
                                                        : false
                                                }
                                            />
                                        </Grid>
                                        {/* <Grid
                                            item
                                            xs={12}
                                            lg={6}
                                            md={6}
                                            sm={12}
                                            sx={{ padding: "10px" }}
                                        >
                                            <TextFieldComponent
                                                id="userPartyPersonalInfDisplayName"
                                                onChange={(event) =>
                                                    setState((prevState) => ({
                                                        ...prevState,
                                                        details: {
                                                            ...details,
                                                            party_details: {
                                                                ...details?.party_details,
                                                                display_name:
                                                                    event.target
                                                                        .value,
                                                            },
                                                        },
                                                    }))
                                                }
                                                label={
                                                    userLoggedIn?.language_data
                                                        ?.customer
                                                        ?.display_name ||
                                                    "Display Name"
                                                }
                                                required={true}
                                                value={
                                                    details?.party_details
                                                        ?.display_name
                                                }
                                                disabled={
                                                    type === "view"
                                                        ? true
                                                        : false
                                                }
                                            />
                                        </Grid> */}
                                        {/* <Grid
                                            item
                                            xs={12}
                                            lg={4}
                                            md={4}
                                            sm={12}
                                            sx={{ padding: "10px" }}
                                        >
                                            <DropDownComponent
                                                label={
                                                    userLoggedIn?.language_data
                                                        ?.customer?.gender ||
                                                    "Gender"
                                                }
                                                name={"partyGender"}
                                                fullWidth={true}
                                                onChange={(event) =>
                                                    setState((prevState) => ({
                                                        ...prevState,
                                                        details: {
                                                            ...details,
                                                            party_details: {
                                                                ...details?.party_details,
                                                                gender: event
                                                                    .target
                                                                    .value,
                                                            },
                                                        },
                                                    }))
                                                }
                                                required={true}
                                                value={
                                                    details?.party_details
                                                        ?.gender
                                                }
                                                disabled={
                                                    type === "view"
                                                        ? true
                                                        : false
                                                }
                                                id="userPartyPersonalInfGender"
                                                children={genderOptions.map(
                                                    (option) => (
                                                        <MenuItem
                                                            id={`userPartyPersonalInfGender${option.id}`}
                                                            key={option.id}
                                                            value={option.id}
                                                        >
                                                            {option.name}
                                                        </MenuItem>
                                                    )
                                                )}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            lg={4}
                                            md={4}
                                            sm={12}
                                            sx={{ padding: "10px" }}
                                        >
                                            <DatePickerComponent
                                                id="userPartyPersonalInfDOB"
                                                onChange={(event) => {
                                                    let date = new Date(event);
                                                    setState((prevState) => ({
                                                        ...prevState,
                                                        details: {
                                                            ...details,
                                                            party_details: {
                                                                ...details?.party_details,
                                                                dob: moment(
                                                                    date
                                                                ).format(
                                                                    "YYYY-MM-DD"
                                                                ),
                                                            },
                                                        },
                                                    }));
                                                }}
                                                value={
                                                    details?.party_details?.dob
                                                }
                                                label={
                                                    userLoggedIn?.language_data
                                                        ?.customer
                                                        ?.date_of_birth ||
                                                    "Date of Birth"
                                                }
                                                maxDate={new Date()}
                                                required={true}
                                                disabled={
                                                    type === "view"
                                                        ? true
                                                        : false
                                                }
                                            />
                                        </Grid> */}
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Typography className="formSubHeading">
                                        Representing Party{" "}
                                        {userLoggedIn?.language_data
                                            ?.complianceworkflow
                                            ?.company_info ||
                                            "Company Information"}
                                    </Typography>
                                </Grid>
                                <Grid container px={3}>
                                    <Grid
                                        item
                                        xs={12}
                                        lg={6}
                                        md={6}
                                        sm={12}
                                        sx={{ padding: "10px" }}
                                    >
                                        <TextFieldComponent
                                            id="userPartyPersonalInfCompanyName"
                                            onChange={(event) =>
                                                setState((prevState) => ({
                                                    ...prevState,
                                                    details: {
                                                        ...details,
                                                        party_details: {
                                                            ...details?.party_details,
                                                            company_info: {
                                                                ...details
                                                                    ?.party_details
                                                                    .company_info,
                                                                name: event
                                                                    .target
                                                                    .value,
                                                            },
                                                        },
                                                    },
                                                }))
                                            }
                                            label={
                                                userLoggedIn?.language_data
                                                    ?.complianceworkflow
                                                    ?.company_name ||
                                                "Contact Name"
                                            }
                                            value={
                                                details?.party_details
                                                    ?.company_info?.name
                                            }
                                            disabled={
                                                type === "view" ? true : false
                                            }
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        lg={6}
                                        md={6}
                                        sm={12}
                                        sx={{ padding: "10px" }}
                                    >
                                        <TextFieldComponent
                                            id="userPartyPersonalInfCompanyWeb"
                                            label={
                                                userLoggedIn?.language_data
                                                    ?.complianceworkflow
                                                    ?.company_website ||
                                                "Company Website"
                                            }
                                            onChange={(event) =>
                                                setState((prevState) => ({
                                                    ...prevState,
                                                    details: {
                                                        ...details,
                                                        party_details: {
                                                            ...details?.party_details,
                                                            company_info: {
                                                                ...details
                                                                    ?.party_details
                                                                    .company_info,
                                                                website:
                                                                    event.target
                                                                        .value,
                                                            },
                                                        },
                                                    },
                                                }))
                                            }
                                            value={
                                                details?.party_details
                                                    ?.company_info?.website
                                            }
                                            disabled={
                                                type === "view" ? true : false
                                            }
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Typography className="formSubHeading">
                                        Representing Party{" "}
                                        {userLoggedIn?.language_data?.customer
                                            ?.contact_information ||
                                            "Contact Information"}
                                    </Typography>
                                </Grid>
                                <Grid container px={3}>
                                    <Grid
                                        item
                                        xs={12}
                                        lg={6}
                                        md={6}
                                        sm={12}
                                        sx={{ padding: "10px" }}
                                    >
                                        <TextFieldComponent
                                            id="userPartyPersonalInfAddress"
                                            onChange={(event) =>
                                                setState((prevState) => ({
                                                    ...prevState,
                                                    details: {
                                                        ...details,
                                                        party_details: {
                                                            ...details?.party_details,
                                                            address: {
                                                                line1: event
                                                                    .target
                                                                    .value,
                                                            },
                                                        },
                                                    },
                                                }))
                                            }
                                            label={`${userLoggedIn?.language_data
                                                ?.customer?.address_line ||
                                                "Address Line"
                                                } 1`}
                                            required={true}
                                            value={
                                                details?.party_details?.address
                                                    ?.line1
                                            }
                                            disabled={
                                                type === "view" ? true : false
                                            }
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        lg={6}
                                        md={6}
                                        sm={12}
                                        sx={{ padding: "10px" }}
                                    >
                                        <TextFieldComponent
                                            onChange={(event) =>
                                                setState((prevState) => ({
                                                    ...prevState,
                                                    details: {
                                                        ...details,
                                                        party_details: {
                                                            ...details?.party_details,
                                                            email: event.target
                                                                .value,
                                                        },
                                                    },
                                                }))
                                            }
                                            label={
                                                userLoggedIn?.language_data
                                                    ?.customer?.email_address ||
                                                "Email Address"
                                            }
                                            required={true}
                                            value={
                                                details?.party_details?.email
                                            }
                                            disabled={
                                                type === "view" ? true : false
                                            }
                                            id="userPartyPersonalInfEmailAdd"
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        lg={6}
                                        md={6}
                                        sm={12}
                                        sx={{ padding: "10px" }}
                                    >
                                        <TextFieldComponent
                                            onChange={(event) =>
                                                setState((prevState) => ({
                                                    ...prevState,
                                                    details: {
                                                        ...details,
                                                        party_details: {
                                                            ...details?.party_details,
                                                            address: {
                                                                ...details
                                                                    ?.party_details
                                                                    ?.address,
                                                                line2: event
                                                                    .target
                                                                    .value,
                                                            },
                                                        },
                                                    },
                                                }))
                                            }
                                            label={`${userLoggedIn?.language_data
                                                ?.customer?.address_line ||
                                                "Address Line"
                                                } 2`}
                                            value={
                                                details?.party_details?.address
                                                    ?.line2
                                            }
                                            disabled={
                                                type === "view" ? true : false
                                            }
                                            id="userPartyPersonalInfAddress1"
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        lg={6}
                                        md={6}
                                        sm={12}
                                        sx={{ padding: "10px" }}
                                    >
                                        <TextFieldComponent
                                            onChange={(event) =>
                                                setState((prevState) => ({
                                                    ...prevState,
                                                    details: {
                                                        ...details,
                                                        party_details: {
                                                            ...details?.party_details,
                                                            phone: event.target
                                                                .value,
                                                        },
                                                    },
                                                }))
                                            }
                                            id="userPartyPersonalInfMobileNumber"
                                            label={
                                                userLoggedIn?.language_data
                                                    ?.customer?.mobile_number ||
                                                "Mobile Number"
                                            }
                                            required={false}
                                            value={
                                                details?.party_details?.phone
                                            }
                                            disabled={
                                                type === "view" ? true : false
                                            }
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        lg={3}
                                        md={3}
                                        sm={12}
                                        sx={{ padding: "10px" }}
                                    >
                                        <TextFieldComponent
                                            id="userPartyPersonalInfCity"
                                            onChange={(event) =>
                                                setState((prevState) => ({
                                                    ...prevState,
                                                    details: {
                                                        ...details,
                                                        party_details: {
                                                            ...details?.party_details,
                                                            address: {
                                                                ...details
                                                                    ?.party_details
                                                                    ?.address,
                                                                city: event
                                                                    .target
                                                                    .value,
                                                            },
                                                        },
                                                    },
                                                }))
                                            }
                                            label={
                                                userLoggedIn?.language_data
                                                    ?.customer?.city || "City"
                                            }
                                            required={true}
                                            value={
                                                details?.party_details?.address
                                                    ?.city
                                            }
                                            disabled={
                                                type === "view" ? true : false
                                            }
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        lg={3}
                                        md={3}
                                        sm={12}
                                        sx={{ padding: "10px" }}
                                    >
                                        <DropDownComponent
                                            id="userPartyPersonalInfState"
                                            label={
                                                userLoggedIn?.language_data
                                                    ?.complianceworkflow
                                                    ?.state || "State"
                                            }
                                            name={
                                                userLoggedIn?.language_data
                                                    ?.complianceworkflow
                                                    ?.state || "State"
                                            }
                                            fullWidth={true}
                                            onChange={(event) =>
                                                setState((prevState) => ({
                                                    ...prevState,
                                                    details: {
                                                        ...details,
                                                        party_details: {
                                                            ...details?.party_details,
                                                            address: {
                                                                ...details
                                                                    ?.party_details
                                                                    ?.address,
                                                                state: event
                                                                    .target
                                                                    .value,
                                                            },
                                                        },
                                                    },
                                                }))
                                            }
                                            value={
                                                details?.party_details?.address
                                                    ?.state || ""
                                            }
                                            disabled={
                                                type === "view" ? true : false
                                            }
                                            required={true}
                                            children={commonDropdown?.states?.map(
                                                (option) => (
                                                    <MenuItem
                                                        key={option.id}
                                                        value={option.id}
                                                    >
                                                        {option.label}
                                                    </MenuItem>
                                                )
                                            )}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        lg={3}
                                        md={3}
                                        sm={12}
                                        sx={{ padding: "10px" }}
                                    >
                                        <TextFieldComponent
                                            id="userPartyPersonalInfWorkPhone"
                                            onChange={(event) =>
                                                setState((prevState) => ({
                                                    ...prevState,
                                                    details: {
                                                        ...details,
                                                        party_details: {
                                                            ...details?.party_details,
                                                            work_phone:
                                                                event.target
                                                                    .value,
                                                        },
                                                    },
                                                }))
                                            }
                                            label={
                                                userLoggedIn?.language_data
                                                    ?.customer?.work_phone ||
                                                "Work Phone"
                                            }
                                            value={
                                                details?.party_details
                                                    ?.work_phone
                                            }
                                            disabled={
                                                type === "view" ? true : false
                                            }
                                            required={false}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        lg={3}
                                        md={3}
                                        sm={12}
                                        sx={{ padding: "10px" }}
                                    >
                                        <TextFieldComponent
                                            id="userPartyPersonalInfFax"
                                            onChange={(event) =>
                                                setState((prevState) => ({
                                                    ...prevState,
                                                    details: {
                                                        ...details,
                                                        party_details: {
                                                            ...details?.party_details,
                                                            fax: event.target
                                                                .value,
                                                        },
                                                    },
                                                }))
                                            }
                                            label={
                                                userLoggedIn?.language_data
                                                    ?.customer?.fax || "Fax"
                                            }
                                            value={details?.party_details?.fax}
                                            disabled={
                                                type === "view" ? true : false
                                            }
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        lg={3}
                                        md={3}
                                        sm={12}
                                        sx={{ padding: "10px" }}
                                    >
                                        <TextFieldComponent
                                            id="userPartyPersonalInfZipCode"
                                            onChange={(event) =>
                                                setState((prevState) => ({
                                                    ...prevState,
                                                    details: {
                                                        ...details,
                                                        party_details: {
                                                            ...details?.party_details,
                                                            address: {
                                                                ...details
                                                                    ?.party_details
                                                                    ?.address,
                                                                zip: event
                                                                    .target
                                                                    .value,
                                                            },
                                                        },
                                                    },
                                                }))
                                            }
                                            label={
                                                userLoggedIn?.language_data
                                                    ?.customer?.zi_code ||
                                                "Zip Code"
                                            }
                                            required={true}
                                            value={
                                                details?.party_details?.address
                                                    ?.zip
                                            }
                                            disabled={
                                                type === "view" ? true : false
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </Fragment>
                        )}
                        <Grid
                            item
                            xs={12}
                            sx={{
                                justifyContent: "end",
                                display: "flex",
                                paddingBottom: "20px",
                                paddingRight: "32px",
                            }}
                        >
                            <div style={{ paddingRight: "10px" }}>
                                <ButtonComponent
                                    title={"Cancel"}
                                    variant={"outlined"}
                                    onClick={() => {
                                        navigate(
                                            `${ROUTES.USER}${selectedCustomer?.id
                                                ? `?${selectedCustomer?.id}`
                                                : ""
                                            }`,
                                            { state: selectedCustomer?.id }
                                        );
                                        setSelectedUser(null);
                                    }}
                                />
                            </div>
                            {type !== "view" && (
                                <div>
                                    <ButtonComponent
                                        title={
                                            type === "edit"
                                                ? "Update"
                                                : "Submit"
                                        }
                                        variant={"contained"}
                                        onClick={handleSubmit}
                                    />
                                </div>
                            )}
                        </Grid>
                    </Grid>
                )}
            </Box>
        </Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,
        commonDropdown: state.auth.commonDropdown,
        selectedCustomer: state.customer.selectedCustomer,
        selectedUser: state.user.selectedUser,
        userList: state.customer.userList,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getUsersByCustomerId,
            createUserAction,
            updateUserById,
            getCustomerById,
            setSelectedCustomer,
            setSelectedUser,
            getUserById,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateUser);
