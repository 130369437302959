import * as Actions from "../Action/DynamicTestAction";

const initialState = {
    DynamicTestDefualtData: null,
    testOption: null,
    list: null,
};

const DynamicTestReducer = (state = initialState, action) => {
    switch (action?.type) {
        case Actions.SAVE_DYANAMIC_TEST: {
            return {
                ...state,
                list: action.payload,
            };
        }
        case Actions.GET_DYANAMIC_TEST_DETAILS_LIST: {
            return {
                ...state,
                list: action.payload,
            };
        }
        case Actions.EDIT_DYANAMIC_TEST: {
            return {
                ...state,
                list: action.payload,
            };
        }
        default: {
            return state;
        }
    }
};

export default DynamicTestReducer;
