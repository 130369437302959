import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import Navbar from "../../common/Header/Navbar";
import PageHeader from "../../common/PageHeader";
import TableComponent from "../../components/Table/TableComponent";
import NoInfoAvailable from "../../components/NoInfoAvailable";
import CommonDeleteDialog from "./../../components/DialogsBox/CommonDeleteDialog";
import FooterPagination from "../../common/PaginationSection/FooterPagination";
import TooltipComponent from "../../components/ToolTip/TooltipComponent";
import "../../../assets/styles/Container/SignedDocument.scss";
import { ReactComponent as DeleteIcon } from "../../../assets/Images/Icons/DeleteIcon.svg";
import { ReactComponent as EditIcon } from "../../../assets/Images/Icons/EditIcon.svg";
import {
    deleteDocumentDatabase,
    getDocumentDatabaseData,
    setSelectedDetailsDataBase,
    getDocumentDatabaseById,
    setCustomerPageLimit,
    setCurrentPage,
} from "../../store/Action/DocumentDatabaseAction";
import LoadingComponent from "../../components/Loading/LoadingComponent";
import { ROUTES } from "./../../router/RouteList";
import { permissionDenied } from "../../utils/CommonUtils";
import HeaderPagination from "../../common/PaginationSection/HeaderPagination";
import { pageLimit } from "../../utils/CommonData";
import { Translate } from "react-auto-translate";
function ContractDatabase(props) {
    const navigate = useNavigate();
    const {
        deleteDocumentDatabase,
        getDocumentDatabaseData,
        documentDatabase,
        getDocumentDatabaseById,
        userLoggedIn,
        docDatabasePagination,
        setCustomerPageLimit,
        setCurrentPage,
    } = props;

    const [state, setState] = useState({
        openDeleteDialog: false,
        deleteData: null,
        openAddDialog: false,
        openSuccesfullModel: false,
        modelContent: "",
        id: null,
        address: { state: "" },
        isLoading: false,
        filteredData: null,
        sortConfig: { key: null, direction: "asc" },
    });
    const {
        openDeleteDialog,
        deleteData,
        isLoading,
        filteredData,
        sortConfig,
    } = state;
    const [search, setSearch] = useState("");
    const permission =
        userLoggedIn?.scope?.length &&
        userLoggedIn?.scope?.find((obj) => obj.prefix === "document-database")
            ?.role_permissions;
    useEffect(() => {
        async function callApi() {
            setState((prevState) => ({ ...prevState, isLoading: true }));
            await getDocumentDatabaseData(search, null, pageLimit, true);
            setState((prevState) => ({ ...prevState, isLoading: false }));
        }
        if (documentDatabase === null) callApi();
        if (documentDatabase) {
            setState((prevState) => ({
                ...prevState,
                filteredData: documentDatabase,
            }));
            handleSort("document_name");
        }
    }, [getDocumentDatabaseData, documentDatabase]);

    const closeDelete = () => {
        setState((prevState) => ({
            ...prevState,
            openDeleteDialog: false,
        }));
    };

    const handleDelete = (data) => {
        setState((prevState) => ({
            ...prevState,
            deleteData: data,
            openDeleteDialog: true,
        }));
    };

    const handleDeleteAction = async (data) => {
        closeDelete();
        setState((prevState) => ({ ...prevState, isLoading: true }));
        await deleteDocumentDatabase(data);
        setState((prevState) => ({ ...prevState, isLoading: false }));
    };
    const handleClickOpen = async () => {
        setState((prevState) => ({ ...prevState, isLoading: true }));
        await getDocumentDatabaseById(null);
        setState((prevState) => ({ ...prevState, isLoading: false }));
        navigate(ROUTES.ADD_DOCUMENT_DATABASE);
    };
    async function documentDatabaseView(data, mode) {
        setState((prevState) => ({ ...prevState, isLoading: true }));
        await getDocumentDatabaseById(data?.id, mode);
        setState((prevState) => ({ ...prevState, isLoading: false }));
        navigate(`${ROUTES.VIEW_DOCUMENT_DATABASE}?${data?.id}`, {
            state: { ...data, mode: mode },
        });
    }
    async function documentDatabaseEdit(data, mode) {
        setState((prevState) => ({ ...prevState, isLoading: true }));
        await getDocumentDatabaseById(data?.id, mode);
        setState((prevState) => ({ ...prevState, isLoading: false }));
        navigate(`${ROUTES.EDIT_DOCUMENT_DATABASE}?${data?.id}`, {
            state: { ...data, mode: mode },
        });
    }
    const renderClassStatus = (obj, row) => {
        if (obj?.rowClass) return obj?.rowClass;
        else if (row?.status_name === "Approved") return "cellApproved";
        else if (
            row?.status_name?.replace(/\s/g, "") === "PendingforApproval" ||
            row?.status_name === "Others"
        )
            return "cellPending";
        else if (
            row?.status_name?.replace(/\s/g, "") === "OnHold" ||
            row?.status_name === "Rejected" ||
            row?.status_name === "Reassigned"
        )
            return "cellOnHold";
    };

    const handleSort = (property) => {
        let sortedData = [...documentDatabase].sort((a, b) => {
            if (property !== "created_at") {
                return sortConfig.direction === "desc"
                    ? a?.[property]?.toLowerCase() <
                      b?.[property]?.toLowerCase()
                        ? 1
                        : b?.[property]?.toLowerCase() <
                          a?.[property]?.toLowerCase()
                        ? -1
                        : 0
                    : a?.[property]?.toLowerCase() >
                      b?.[property]?.toLowerCase()
                    ? 1
                    : b?.[property]?.toLowerCase() >
                      a?.[property]?.toLowerCase()
                    ? -1
                    : 0;
            } else {
                return sortConfig.direction === "desc"
                    ? new Date(a?.[property]) - new Date(b?.[property])
                    : new Date(b?.[property]) - new Date(a?.[property]);
            }
        });
        setState((prevState) => ({
            ...prevState,
            filteredData: sortedData,
            sortConfig: {
                direction: sortConfig.direction === "desc" ? "asc" : "desc",
            },
        }));
    };

    const headerList = [
        {
            id: 1,
            name:
                userLoggedIn?.language_data?.documentdatabase?.document_name ||
                "Document Name",
            headerClass: "tableHeading",
            accessibleKey: "document_name",
            rowClass: "nameFile",
            headerAlign: "center",
            rowAlign: "center",
            sort: true,
            sortFunction: handleSort,
        },
        {
            id: 2,
            name:
                userLoggedIn?.language_data?.documentdatabase
                    ?.document_category || "Document Category",
            accessibleKey: "category",
            headerClass: "tableHeading",
            rowClass: "tableBodyRow",
            headerAlign: "center",
            rowAlign: "center",
            sort: false,
        },
        {
            id: 3,
            name: userLoggedIn?.language_data?.comman?.assign_to || "Assign To",
            headerClass: "tableHeading Assign ToTableHeading",
            accessibleKey: "assign_user",
            rowClass: "companyName",
            headerAlign: "center",
            rowAlign: "center",
            sort: false,
        },
        {
            id: 4,
            name: userLoggedIn?.language_data?.comman?.status || "Status",
            headerClass: "tableHeading",
            accessibleKey: "status_name",
            headerAlign: "center",
            rowAlign: "center",
            sort: true,
            sortFunction: handleSort,
        },
        {
            id: 5,
            name:
                userLoggedIn?.language_data?.comman?.approved_by ||
                "Approved By",
            headerClass: "tableHeading",
            accessibleKey: "approved_by",
            headerAlign: "center",
            rowAlign: "center",
            sort: false,
        },
        {
            id: 6,
            name: "Time Stamp",
            headerClass: "tableHeading noOfUserTableHeading",
            accessibleKey: "created_at",
            rowClass: "stampTime",
            headerAlign: "center",
            rowAlign: "center",
            sort: true,
            sortFunction: handleSort,
        },
    ];

    function handleSearch(data) {
        getDocumentDatabaseData(
            search,
            docDatabasePagination?.currentPage,
            docDatabasePagination?.listViewLimit,
            true
        );

        setState((prevState) => ({
            ...prevState,
            filteredData: documentDatabase,
        }));
    }

    const handleViewLimitChange = async (data) => {
        setCustomerPageLimit(data?.target?.value);
        setState((prevState) => ({ ...prevState, isLoading: true }));
        await getDocumentDatabaseData(
            null,
            docDatabasePagination?.currentPage,
            data?.target?.value,
            true
        );
        setState((prevState) => ({ ...prevState, isLoading: false }));
    };

    const handlePaginationOnChange = async (_, pageNumber) => {
        setCurrentPage(pageNumber);
        setState((prevState) => ({ ...prevState, isLoading: true }));
        await getDocumentDatabaseData(
            search,
            pageNumber,
            docDatabasePagination?.listViewLimit,
            true
        );
        setState((prevState) => ({ ...prevState, isLoading: false }));
    };

    useEffect(() => {
        getDocumentDatabaseData(
            search,
            docDatabasePagination?.currentPage,
            docDatabasePagination?.listViewLimit,
            true
        );
    }, [search]);
    return (
        <>
            <Navbar />
            <CommonDeleteDialog
                open={openDeleteDialog}
                title={
                    <Translate>
                        {userLoggedIn?.language_data?.documentdatabase
                            ?.delete_document_database ||
                            "Delete Document Database"}
                    </Translate>
                }
                cancelButtonAction={closeDelete}
                cancelButtonTitle={<Translate>Cancel</Translate>}
                submitButtonTitle={<Translate>Delete</Translate>}
                content={
                    <Translate>
                        {`Are you sure, you want to remove the (${deleteData?.document_name}) from the Document Database?`}
                    </Translate>
                }
                submitButtonAction={handleDeleteAction}
                submitButtonParameter={deleteData}
                id="deleteDocumentDatabaseDialog"
            />
            {!documentDatabase || isLoading ? (
                <LoadingComponent />
            ) : (
                <Box sx={{ px: { md: 7, xs: 1, mt: "40px" } }}>
                    <PageHeader
                        title={
                            <Translate>
                                {userLoggedIn?.language_data?.comman
                                    ?.menu_doc_data ||
                                    "Document Database in US"}
                            </Translate>
                        }
                        showSearchBar={true}
                        buttonTitle={
                            <Translate>
                                {userLoggedIn?.language_data?.documentdatabase
                                    ?.new_document_database ||
                                    "New Document Database"}
                            </Translate>
                        }
                        handleAdd={
                            permission?.create
                                ? handleClickOpen
                                : permissionDenied
                        }
                        handleSearch={handleSearch}
                        setSearch={setSearch}
                    />
                    <HeaderPagination
                        showViewButton={false}
                        total={docDatabasePagination?.total}
                        listViewLimit={docDatabasePagination?.listViewLimit}
                        handleViewLimitChange={handleViewLimitChange}
                        showPagination={
                            pageLimit < docDatabasePagination?.total
                        }
                    />
                    {filteredData?.length !== 0 && (
                        <TableComponent
                            headerList={headerList}
                            // serialNo={{
                            //     name: <Translate>S.No.</Translate>,
                            //     headerClass: "tableHeading",
                            //     headerAlign: "center",
                            //     rowClass: "typo4",
                            //     rowAlign: "center",
                            // }}
                            sortConfig={sortConfig}
                            extraHeaderRight={
                                <TableCell
                                    align="center"
                                    className="tableHeading actionTableHeading"
                                >
                                    <Translate>Action</Translate>
                                </TableCell>
                            }
                        >
                            {filteredData?.length !== 0 &&
                                filteredData?.map((row, index) => (
                                    <TableRow
                                        key={row?.name}
                                        className={
                                            index % 2 !== 0
                                                ? "tertiaryBackground"
                                                : ""
                                        }
                                    >
                                        {/* <TableCell
                                        align="center"
                                        className="typo4"
                                    >
                                        {index + 1}
                                    </TableCell> */}
                                        {headerList?.map((obj) => (
                                            <TableCell
                                                component="th"
                                                key={obj?.name}
                                                align={obj?.rowAlign}
                                                className={renderClassStatus(
                                                    obj,
                                                    row
                                                )}
                                            >
                                                {obj?.id === 5 &&
                                                !row?.approved_by ? (
                                                    <Translate>
                                                        Not Assigned
                                                    </Translate>
                                                ) : (
                                                    <Translate>
                                                        {
                                                            row?.[
                                                                obj
                                                                    ?.accessibleKey
                                                            ]
                                                        }
                                                    </Translate>
                                                )}
                                            </TableCell>
                                        ))}
                                        <TableCell sx={{ textAlign: "center" }}>
                                            {row?.action}
                                            <TooltipComponent
                                                title={
                                                    <Translate>
                                                        View Document Database
                                                    </Translate>
                                                }
                                                arrow={true}
                                            >
                                                <IconButton
                                                    id="documentDatabaseView"
                                                    className="iconButton"
                                                    onClick={() =>
                                                        documentDatabaseView(
                                                            row,
                                                            "view"
                                                        )
                                                    }
                                                >
                                                    <RemoveRedEyeOutlinedIcon />
                                                </IconButton>
                                            </TooltipComponent>
                                            <TooltipComponent
                                                title={
                                                    <Translate>
                                                        Edit Document Database
                                                    </Translate>
                                                }
                                                arrow={true}
                                            >
                                                <IconButton
                                                    id="documentDatabaseEdit"
                                                    className="iconButton"
                                                    onClick={() =>
                                                        permission?.edit
                                                            ? documentDatabaseEdit(
                                                                  row,
                                                                  "edit"
                                                              )
                                                            : permissionDenied()
                                                    }
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                            </TooltipComponent>
                                            <TooltipComponent
                                                title={
                                                    <Translate>
                                                        Delete Document Database
                                                    </Translate>
                                                }
                                                arrow={true}
                                            >
                                                <IconButton
                                                    id="documentDatabaseDelete"
                                                    className="iconButton"
                                                    onClick={() =>
                                                        permission?.delete
                                                            ? handleDelete(row)
                                                            : permissionDenied()
                                                    }
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </TooltipComponent>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableComponent>
                    )}
                    {pageLimit > docDatabasePagination?.total ? null : (
                        <FooterPagination
                            count={docDatabasePagination?.lastPage}
                            page={docDatabasePagination?.currentPage}
                            onChange={handlePaginationOnChange}
                            defaultPage={docDatabasePagination?.currentPage}
                        />
                    )}
                    {filteredData?.length === 0 && (
                        <NoInfoAvailable
                            className="noCustomerInfo"
                            noInfoTypo="noInfoTypeQuestion"
                        />
                    )}
                </Box>
            )}
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        documentDatabase: state?.docDatabase?.documentDatabase,
        docDatabasePagination: state?.docDatabase?.docDatabasePagination,
        userLoggedIn: state?.auth?.userLoggedIn,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            deleteDocumentDatabase,
            getDocumentDatabaseData,
            setSelectedDetailsDataBase,
            getDocumentDatabaseById,
            setCustomerPageLimit,
            setCurrentPage,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ContractDatabase);
