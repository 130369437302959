import React, { useEffect } from "react";
import { Fragment } from "react";
import {
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    Typography,
} from "@mui/material";
import { useState } from "react";
import { agreeOrNotOptions } from "../../../utils/CommonData";
import ButtonComponent from "../../../components/ButtonComponent";
import { ROUTES } from "../../../router/RouteList";
import { useNavigate } from "react-router-dom";
import { getStartTest } from "../../../store/Action/AssessmentSettingAction";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Translate } from "react-auto-translate";

const Acknowledge = (props) => {
    const {
        testType,
        selectedId,
        getStartTest,
        defaultTypeId,
        roleId,
        guid,
        setLoading,
        contractor_id,
        user_id,
        assessmentSummaryData,
        mode,
    } = props;
    const [accept, setAccept] = useState(true);
    const navigate = useNavigate();

    const handleBtnClick = async (selectedId) => {
        getQuestionList(roleId, guid, defaultTypeId, selectedId, contractor_id);
    };

    const getQuestionList = async (
        roleId,
        guid,
        defaultTypeId,
        selectedId,
        contractor_id
    ) => {
        setLoading(true);
        await getStartTest(
            selectedId,
            guid,
            defaultTypeId,
            roleId,
            user_id,
            mode
        );
        setLoading(false);
        navigate(ROUTES.TAKE_TEST + "/" + guid + "/" + user_id, {
            state: {
                assessmentSummaryData: assessmentSummaryData,
                contractor_id: contractor_id,
                roleId: roleId,
                test_type: defaultTypeId,
                mode: mode,
            },
        });
    };

    const disabledBtnSx = {
        background: "rgba(59, 59, 59, 0.5) !important",
    };
    const acknowledgeText =
        "I hereby certify that, to the best of my knowledge and ability, my response to each question in this test will be true and accurate.  I understand that, if given the choice between 2 or more correct or seemingly correct responses, I will choose the most correct response.";

    const acknowledgeText2 =
        "You are about to take a compliance assessment. This assessment is timed. If you do not complete the assessment within the allotted time frame, you will be given 1 more opportunity after that to take a new assessment. If you fail to complete the assessment(s) timely, you will be marked as failed or incomplete. This assessment is a critical part of the overall compliance. ";
    return (
        <Fragment>
            <Grid container spacing={2}>
                <Grid item md={12} lg={12}>
                    <Typography className="font_18_600">
                        {testType === "rcp" || "rca" ? (
                            <Translate>{acknowledgeText2}</Translate>
                        ) : (
                            <Translate>{acknowledgeText}</Translate>
                        )}
                    </Typography>
                </Grid>
                <Grid
                    item
                    md={12}
                    lg={12}
                    sx={{ marginLeft: "-10px !important" }}
                    mt={1}
                >
                    <RadioGroup
                        value={accept}
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            marginBottom: "18px",
                            marginLeft: "10px",
                        }}
                    >
                        {agreeOrNotOptions?.map((option, index) => (
                            <FormControlLabel
                                key={index}
                                value={option.value}
                                control={<Radio />}
                                label={
                                    <Typography
                                        className="font_16_600"
                                        variant="span"
                                        sx={{ cursor: "pointer" }}
                                    >
                                        <Translate>{option.label}</Translate>
                                    </Typography>
                                }
                                sx={{
                                    marginBottom: 0.5,
                                    marginRight: index === 0 ? 3 : 0,
                                }}
                                onClick={() => {
                                    setAccept(option.value); // Use the state update function 'setAccept'
                                }}
                            />
                        ))}
                    </RadioGroup>
                </Grid>

                <Grid item md={12} lg={12} textAlign="end">
                    <ButtonComponent
                        title={<Translate>Start Test</Translate>}
                        disabled={!accept}
                        onClick={() => handleBtnClick(selectedId)}
                        sx={!accept ? disabledBtnSx : null}
                    />
                </Grid>
            </Grid>
        </Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        testQuestionList: state?.assessmentSettings?.testQuestionList,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ getStartTest }, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(Acknowledge);
