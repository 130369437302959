import React from "react";
import Slide from "@mui/material/Slide";
import ButtonComponent from "../ButtonComponent";
import { Autocomplete, Grid, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import "../../../assets/styles/Container/AutoComplete.scss";
import DialogComponent from "./DialogComponent";
import TextFieldComponent from "../TextField/TextFieldComponent";
import { connect } from "react-redux";
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const WorkFlowAssignUserDialog = (props) => {
    const {
        onClose,
        handleClose,
        open,
        title,
        label,
        handleOnClick,
        options,
        getOptionLabel,
        assignUser,
        setAssignUser,
        workFlow,
        userLoggedIn
    } = props;
    const item = options?.find(
        (obj) => obj?.id == workFlow?.updateUser?.contractor_id
    );

    return (
        <DialogComponent
            TransitionComponent={Transition}
            className="assignDialog"
            fullWidth={true}
            open={open}
            handleClose={handleClose}
            title={title}
            content={
                <>
                    <Grid container spacing={2}>
                        <Grid item md={12}>
                            <Autocomplete
                                className="auto"
                                fullWidth={true}
                                value={workFlow?.updateUser?.party_name}
                                options={
                                    options && options !== null ? options : []
                                }
                                getOptionLabel={getOptionLabel}
                                sx={{ marginTop: "10px" }}
                                onChange={(e, user) => {
                                    if (options !== null) {
                                        if (user !== undefined) {
                                            setAssignUser(user);
                                        }
                                    }
                                }}
                                renderInput={(params) => (
                                    <TextFieldComponent
                                        {...params}
                                        label={title}
                                        className="autoTextField"
                                        variant="filled"
                                        id="workFlowAssginUserOptionLabel"
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={1} mt={2}>
                        <Grid item md={12}>
                            <Grid container>
                                <Grid item md={7}>
                                    <Grid container spacing={1}>
                                        <Grid item md={4}>
                                            <Typography className="assignHeading">
                                            {userLoggedIn?.language_data?.customer?.first_name || "Firstname"}:
                                            </Typography>
                                        </Grid>
                                        <Grid item md={8}>
                                            <Typography className="assignInfo">
                                                {assignUser.firstName ||
                                                    item?.first_name}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={5}>
                                    <Grid container spacing={1}>
                                        <Grid item md={6}>
                                            <Typography className="assignHeading">
                                                {userLoggedIn?.language_data?.customer?.middle_name || "Middle Name"}:
                                            </Typography>
                                        </Grid>
                                        <Grid item md={6}>
                                            <Typography className="assignInfo">
                                                {assignUser.middleName ||
                                                    item?.middle_name}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={12}>
                            <Grid container>
                                <Grid item md={7}>
                                    <Grid container spacing={1}>
                                        <Grid item md={4}>
                                            <Typography className="assignHeading">
                                            {userLoggedIn?.language_data?.customer?.last_name || "Last Name"}:
                                            </Typography>
                                        </Grid>
                                        <Grid item md={8}>
                                            <Typography className="assignInfo">
                                                {assignUser.lastName ||
                                                    item?.last_name}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={5}>
                                    <Grid container spacing={1}>
                                        <Grid item md={6}>
                                            <Typography className="assignHeading">
                                                ID:
                                            </Typography>
                                        </Grid>
                                        <Grid item md={6}>
                                            <Typography className="assignInfo">
                                                {assignUser.id || item?.id}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={12}>
                            <Grid container>
                                <Grid item md={7}>
                                    <Grid container spacing={1}>
                                        <Grid item md={4}>
                                            <Typography
                                                className="assignHeading"
                                                sx={{
                                                    display: "inline-block",
                                                    width: "200px",
                                                    whiteSpace: "nowrap",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                }}
                                            >
                                                Email ID:
                                            </Typography>
                                        </Grid>
                                        <Grid item md={8}>
                                            <Typography className="assignInfo">
                                                {assignUser?.email?.substring(
                                                    0,
                                                    22
                                                ) ||
                                                    item?.email?.substring(
                                                        0,
                                                        22
                                                    )}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={5}>
                                    <Grid container spacing={1}>
                                        <Grid item md={6}>
                                            <Typography className="assignHeading">
                                                Role:
                                            </Typography>
                                        </Grid>
                                        <Grid item md={6}>
                                            <Typography className="assignInfo">
                                                {assignUser.role || item?.role}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            }
            action={
                <ButtonComponent
                    title={label}
                    onClick={() => {
                        handleOnClick();
                    }}
                    variant={"button_contained"}
                />
            }
        />
    );
};

const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,
        
    };
};


export default connect(mapStateToProps, null) (WorkFlowAssignUserDialog);
