export const ROUTES = {
    DEFAULT_PAGE: "/",
    HOME: "/home",
    ABOUT_US: "/about-us",
    WORKFLOW_EMAIL: "/emails",
    CUSTOMERS_INFORMATION: "/customers",
    ASSESSMENT_INVOLVED_PARTIES: "/parties",
    MANAGE_ROLES: "/roles",
    CREATE_NEW_ROLES: "/create-roles",
    MANAGE_MODULE_ROLE_PERMISSION: "/role-permission/:id",
    DOCUMENTS: "/documents",
    CONTRACTS: "/compliance-workflow/signed-document",
    GLOSSARY: "/glossary",
    FORM_BUILDER: "/form-studio",
    VIEW_FORM: "/form-studio/view/:id",
    EDIT_FORM: "/form-studio/edit/:id",
    FORM_SUBMISSION: "/form-submission/:id",
    MANAGE_MQS: "/mqs",
    SELECT_TEST: "/select-test",
    COMPLIANCE_WORKFLOW: "/compliance-workflow",
    USER_SETTING: "/user-setting",
    LOGIN: "/login",
    FORGOT_PASSWORD: "/forgot-password",
    PROFILE: "/profile",
    DASHBOARD: "/dashboard",
    RESET_PASSWORD: "/reset-password",
    EMAIL_TEMPLATES: "/email-templates",
    ADD_EMAIL_TEMPLATE: "/create-email-template",
    CREATE_USER: "/user/create",
    EDIT_USER: "/user/edit",
    VIEW_USER: "/user/view",
    SCORING_CONFIGURATION: "/scoring-configuration",
    CREATE_CLIENT: "/clients/create",
    EDIT_CLIENT: "/clients/edit",
    VIEW_CLIENT: "/clients/view",
    SETTINGS: "/settings",
    USER: "/user",
    ARCHIVED_CLIENT: "/archived-client",
    ARCHIVED_USER: "/archived-user",
    DOCUMENT_DATABASE: "/document-database",
    TRAINING:"/training",
    SIGNED_DOCUMENT: "/signed-document",
    ADD_SIGNED_DOCUMENT: "/add-signed-document",
    VIEW_SIGNED_DOCUMENT: "/view-signed-document",
    EDIT_SIGNED_DOCUMENT: "/edit-signed-document",
    ADD_DOCUMENT_DATABASE: "/add-document-database",
    VIEW_DOCUMENT_DATABASE: "/view-document-database",
    EDIT_DOCUMENT_DATABASE: "/edit-document-database",
    VIEW_CONTRACT_DATABASE: "/contract-database/view-contract",
    STANDARD: "/standard",
    SELF_CONFIGURATION: "/self-config",
    DOCUMENTATION: "/documentation",
    TRAINING_VIDEOS: "/training_videos",
    FAQS: "/faqs",
    ADD_FAQS: "/add-faqs",
    EDIT_FAQS: "/edit-faqs",
    WHITE_LABELING: "/white-labeling/:id",
    COMPLIANCE_PROFILE: "/compliance-profile",
    EDIT_PROFILE: "/edit-profile",
    ASSESSMENTS: "/assessments",
    View_Response: "/view-response",
    REVIEW_RESPONSE: "/review-response",
    Conflict_Response: "/conflict-response",
    Resolve_Conflict: "/Resolve-Conflict",
    PERMISSION_SETTING: "/settings/:permission/:id",
    TEST_QUESTIONS: "/test-questions",
    TAKE_TEST: "/test-questions",
    PAGE_NOT_FOUND: "/page-not-found",
    CUSTOMER_HIERARCHY: "/customer-hierarchy/:id",
    PERMISSION_DENIED: "/permission-denied",
    ADD_QUESTION: "/question",
    EDIT_QUESTION: "/question/:id",
    AUDIT_DEFENSE_REPORT: "/audit-defense-report",
    PRICING: "/pricing",
    SIGNUP: "/sign-up",
    SERVICES: "/services",
    CONTACTUS: "/contact-us",
    TECHNOLOGY: "/technology",
    DATABASE: "/database",
    TARGET_MARKET: "/target-market",
    PAYMENT_METHOD: "/payment-method",
    DIRECT_LOGIN: "/direct-login/:id",
    TRAINING_LIBRARY_MASTER: "/training-library"
};
