import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Box from "@mui/material/Box";
import Navbar from "./../../common/Header/Navbar";
import FaqsQuestionList from "./FaqsQuestionList";
import AddCategoyDialog from "./AddCategoryDialog";
import SearchBar from "../../components/SearchBar";
import NoInfoAvailable from "../../components/NoInfoAvailable";
import CommonDeleteDialog from "../../components/DialogsBox/CommonDeleteDialog";
import { ReactComponent as DeleteIcon } from "../../../assets/Images/Icons/DeleteIcon.svg";
import { ReactComponent as EditIcon } from "../../../assets/Images/Icons/EditIcon.svg";
import {
    getFaqsCategory,
    storeCategory,
    updateCategory,
    deleteCategoryId,
    getFaqs,
    setSelectedCategory,
    getFaqsBySearchTerm,
} from "../../store/Action/FaqsAction";
import "../../../assets/styles/Container/Faqs.scss";
import color from "../../../assets/styles/Color.scss";
import ButtonComponent from "../../components/ButtonComponent";
import LoadingComponent from "../../components/Loading/LoadingComponent";
import { toast } from "react-hot-toast";

function Faqs(props) {
    const {
        getFaqsCategory,
        faqsCategory,
        storeCategory,
        updateCategory,
        deleteCategoryId,
        setSelectedCategory,
        selectedCategory,
        faqsData,
        getFaqs,
        getFaqsBySearchTerm,
        userLoggedIn
    } = props;
    const [state, setState] = useState({
        id: null,
        name: selectedCategory?.name,
        description: selectedCategory?.description,
        open: false,
        delete: false,
        deletedData: null,
        data: null,
        selectedCategoryId: null,
        isLoading: false,
        filteredData: null,
        questionsID: []
    });
    const [search, setSearch] = useState([])
    const {
        open,
        deletedData,
        data,
        name,
        selectedCategoryId,
        isLoading,
        filteredData,
        questionsID
    } = state;

    if (selectedCategory && !name) {
        setState((prevState) => ({ ...prevState, ...selectedCategory }));
    }

    useEffect(() => {
        async function callApi() {
            setState((prevState) => ({ ...prevState, isLoading: true }));
            await getFaqsCategory();
            await getFaqs();
            setState((prevState) => ({ ...prevState, isLoading: false }));
        }
        if (faqsCategory === null || faqsData === null) callApi();
        if (faqsCategory && filteredData === null) {
            setState((prevState) => ({
                ...prevState,
                filteredData: filteredData,
            }));
        }
        if (filteredData === null && faqsCategory?.length && faqsData?.length) {
            handleClickToCategory(faqsCategory[0]);
        }
    }, [getFaqsCategory, getFaqs, faqsData, faqsCategory, filteredData]);

    useEffect(() => {
        if (selectedCategory) {
            handleClickToCategory(selectedCategory);
        }
    }, [faqsData]);
    const closeDelete = () => {
        setState((prevState) => ({
            ...prevState,
            delete: false,
            isLoading: false,
        }));
    };
    const handleClickToOpen = async () => {
        setState((prevState) => ({
            ...prevState,
            open: true,
            name: "",
            description: "",
        }));
        setSelectedCategory(null);
    };
    const handleDeleteAction = async (data) => {
        setState((prevState) => ({
            ...prevState,
            delete: false,
            isLoading: true,
        }));
        await deleteCategoryId(data);
        await getFaqs();
        setState((prevState) => ({
            ...prevState,
            isLoading: false,
            filteredData: [],
        }));
    };
    const handleEdit = async (faqs) => {
        await setSelectedCategory(faqs);
        setState((prevState) => ({
            ...prevState,
            open: true,
            id: faqs?.id,
        }));
    };
    const handleDelete = async (faqsid, faqs) => {
        setState((prevState) => ({
            ...prevState,
            delete: true,
            deletedData: faqsid,
            data: faqs,
        }));
    };
    async function handleCategorySubmit() {
        if(state.name.length !== 0 && state.description.length !== 0) {
            setState((prevState) => ({
                ...prevState,
                open: false,
                isLoading: true,
            }));
            if (selectedCategory === null) await storeCategory(state);
            else {
                await updateCategory(state);
            }
            setState((prevState) => ({ ...prevState, isLoading: false }));
            setSelectedCategory(null);
        } else {
            if(state.name.length == 0) {
                toast.error('Name is required')
            } else if(state.description.length == 0) {
                toast.error('Description is required')
            }
        }
      
    }
    async function handleClickToCategory(data) {
        await setSelectedCategory(data);
        let questionList = faqsData?.filter(
            (obj) => obj.category_id === data?.id
        );
        setState((prevState) => ({
            ...prevState,
            filteredData: questionList,
            selectedCategoryId: data?.id,
            questionsID: questionList
        }));
    }
    async function handleSearch(data) {
        let searchResults = await getFaqsBySearchTerm(0, data);
        setState((prevState) => ({
            ...prevState,
            filteredData: searchResults
        }))
    }
    

    return (
        <>
            <Navbar />
            <CommonDeleteDialog
                open={state?.delete}
                title={userLoggedIn?.language_data?.faqs?.delete_category_text|| "Delete Category"}
                cancelButtonAction={closeDelete}
                cancelButtonTitle="No"
                submitButtonTitle={"yes"}
                content={`Are you sure, you want to (${data?.name}) delete
                    from the Category?`}
                submitButtonAction={handleDeleteAction}
                submitButtonParameter={deletedData}
                id="faqsDeleteCategoryDialog"
            />
            <AddCategoyDialog
                id="faqsAddCategoryDialog"
                open={open}
                setState={setState}
                state={state}
                setSelectedCategory={setSelectedCategory}
                handleCategorySubmit={handleCategorySubmit}
                selectedCategory={selectedCategory}
            />
            <Grid container className="box">
                <Grid item md={12} display={"flex"} justifyContent={"center"}>
                    <Typography className="textHelp">
                        {userLoggedIn?.language_data?.faqs?.header_title || "How can we help you?"}
                    </Typography>
                </Grid>
                <Grid
                    item
                    mt={3}
                    md={12}
                    display={"flex"}
                    justifyContent={"center"}
                >
                    <SearchBar
                        id="faqsSearchBars"
                        className="searchBars"
                        handleSearch={handleSearch}
                        setSearch = {setSearch}
                    ></SearchBar>
                </Grid>
            </Grid>
            {!faqsCategory || isLoading ? (
                <LoadingComponent />
            ) : (
                <Box sx={{ px: { md: 7, xs: 1 } }}>
                    <Grid container>
                        <Grid
                            item
                            md={7.6}
                            mt={4}
                            display={"flex"}
                            justifyContent={"end"}
                        >
                            {faqsCategory?.length !== 0 && (
                                <Typography className="categoryHeading">
                                    Choose a category to quickly find the help
                                    you need
                                </Typography>
                            )}
                        </Grid>
                        <Grid
                            item
                            md={4.35}
                            mt={3}
                            display={"flex"}
                            justifyContent={"end"}
                        >
                            <ButtonComponent
                                id="faqsAddCategoryButton"
                                onClick={() => handleClickToOpen()}
                                title={userLoggedIn?.language_data?.faqs?.add_category_btn || "Add Category"}
                                startIcon={<AddCircleOutlineIcon />}
                            />
                        </Grid>
                    </Grid>
                    <Grid container gap={2} mt={3}>
                        {faqsCategory?.length ? (
                            faqsCategory.map((faqs) => (
                                <Grid
                                    item
                                    xs={11}
                                    sm={5.8}
                                    lg={3.88}
                                    xl={2.9}
                                    className="categoryCard"
                                    sx={{
                                        backgroundColor:
                                            selectedCategoryId === faqs?.id
                                                ? color.secondary
                                                : color.tertiary,
                                    }}
                                >
                                    <Grid container>
                                        <Grid
                                            item
                                            md={12}
                                            display={"flex"}
                                            justifyContent={"flex-end"}
                                        >
                                            <IconButton
                                                id="faqsEdit"
                                                onClick={() => handleEdit(faqs)}
                                            >
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton
                                                id="faqsDelete"
                                                onClick={() =>
                                                    handleDelete(faqs?.id, faqs)
                                                }
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        mt={1}
                                        md={12}
                                        display={"flex"}
                                        justifyContent={"center"}
                                    >
                                        <Typography
                                            display={"flex"}
                                            justifyContent={"center"}
                                            className="category"
                                            sx={{
                                                color:
                                                    selectedCategoryId ===
                                                    faqs?.id
                                                        ? "white"
                                                        : "#3b3b3b",
                                            }}
                                        >
                                            {faqs?.name}
                                        </Typography>
                                    </Grid>
                                    <Grid item md={12} mt={2} mx={6.7}>
                                        <Box sx={{ height: "30px" }}>
                                            <Typography
                                                className="description"
                                                display={"flex"}
                                                justifyContent={"center"}
                                                sx={{
                                                    color:
                                                        selectedCategoryId ===
                                                        faqs?.id
                                                            ? "white"
                                                            : " rgba(59, 59, 59, 0.7)",
                                                }}
                                            >
                                                {faqs?.description}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    {selectedCategoryId === faqs?.id ? null : (
                                        <Grid
                                            item
                                            md={12}
                                            mt={2}
                                            display={"flex"}
                                            justifyContent={"flex-end"}
                                        >
                                            <Grid
                                                className="selectButtonFaqs"
                                                sx={{
                                                    backgroundColor:
                                                        selectedCategoryId ===
                                                        faqs?.id
                                                            ? color.tertiary
                                                            : color.secondary,
                                                }}
                                            >
                                                <ButtonComponent
                                                    onClick={() =>
                                                        handleClickToCategory(
                                                            faqs
                                                        )
                                                    }
                                                    id="faqsSelectCategoryButton"
                                                    className="no"
                                                    title={
                                                        <Typography
                                                            sx={{
                                                                color:
                                                                    selectedCategoryId ===
                                                                    faqs?.id
                                                                        ? color.secondary
                                                                        : color.tertiary,
                                                                fontSize:
                                                                    "16px",
                                                                textTransform:
                                                                    "none",
                                                            }}
                                                        >
                                                            Select
                                                        </Typography>
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                    )}
                                </Grid>
                            ))
                        ) : (
                            <NoInfoAvailable
                                className="noCustomerInfo"
                                noInfoTypo="noInfoTypeQuestion"
                            />
                        )}
                    </Grid>
                    <FaqsQuestionList
                        faqsCategory={faqsCategory}
                        filteredData={filteredData}
                    />
                </Box>
            )}
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,
        faqsCategory: state.faqs?.faqsCategory,
        selectedCategory: state.faqs?.selectedCategory,
        faqsData: state.faqs?.faqsList,
        selectedQuestion: state?.faqs?.selectedQuestion,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getFaqsCategory,
            storeCategory,
            updateCategory,
            deleteCategoryId,
            setSelectedCategory,
            getFaqs,
            getFaqsBySearchTerm
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Faqs);
