import { toast } from "react-hot-toast";
import { API_CONSTANTS } from "../../config/ApiConstants";
import {
    getApiCall,
    deleteApiCall,
    postApiCall,
    putApiCall,
} from "../../utils/Action";

export const GET_GLOSSARY_LIST = "GET_GLOSSARY_LIST";
export const ADD_GLOSSARY = "ADD_GLOSSARY";
export const DELETE_GLOSSARY = "DELETE_GLOSSARY";
export const UPDATE_GLOSSARY = "UPDATE_GLOSSARY";

export const getGlossaryList = (letter, searchTerm) => {
    
    return async (dispatch) => {
        try {
            const response = await getApiCall(
                API_CONSTANTS.GLOSSARIES + "/" + letter + '?term=' + searchTerm
            );
            if (response?.status === 200) {
                return dispatch({
                    type: GET_GLOSSARY_LIST,
                    payload: response?.data?.result || [],
                });
            }
        } catch (error) {
            throw error;
        }
    };
};

export const addGlossary = (data) => {
    return async (dispatch) => {
        try {
            let response;
            if (
                data.term.startsWith(data.alphabet) ||
                data.term.startsWith(data.alphabet.toLowerCase())
            ) {
                response = await postApiCall(API_CONSTANTS.GLOSSARIES, data);
            } else {
                toast.error("define term is required");
                return false;
            }
            if (response?.status === 201) {
                if (data.currentAlphabet === data.alphabet) {
                    return dispatch({
                        type: ADD_GLOSSARY,
                        payload: response?.data?.result,
                    });
                }else {
                    return true;
                }

            }
        } catch (error) {
            throw error;
        }
    };
};

export const deleteGlossary = (data) => {
    return async (dispatch) => {
        try {
            const response = await deleteApiCall(
                API_CONSTANTS.GLOSSARIES + "/" + data.id
            );
            if (response?.status === 200) {
                return dispatch({
                    type: DELETE_GLOSSARY,
                    payload: data.id,
                });
            }
        } catch (error) {
            throw error;
        }
    };
};

export const updateGlossary = (data) => {
    return async (dispatch) => {
        try {
            let response;
            if (
                data.term.startsWith(data.alphabet) ||
                data.term.startsWith(data.alphabet.toLowerCase())
            ) {  response = await putApiCall(
                API_CONSTANTS.GLOSSARIES + "/" + data.id,
                data
            );
            
            } else {
                toast.error("define term is required");
                return false;
                
            } 
           
            if (response?.status === 200) {
                return dispatch({
                    type: UPDATE_GLOSSARY,
                    payload: response?.data?.result,
                });
            }else {
                return false;
              
            }
            
        } catch (error) {
            throw error;
        }
    };
};
