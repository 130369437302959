import React from "react";
import Grid from "@mui/material/Grid";
import ButtonComponent from "../../components/ButtonComponent";
import { connect } from "react-redux";
const HeaderDrawerLink = (props) => {
    const {
        handlerElementDrawerOpen,
        handlerDesignDrawerOpen,
        elementDrawer,
        designDrawer,
        popperOpen,
        previewButton,
        setState,
        handlerPreview,
        formData,
        userLoggedIn,
        openForms
    } = props;

    return (
        <Grid
            container
            textAlign={"center"}
            className="drawerButtonBox"
            justifyContent={"space-between"}
            sx={{ paddingX: { md: "45px", sx: "0px" } }}
        >
            {formData ? (
                <Grid item container gap={1}>
                    <Grid item>
                        <ButtonComponent
                            id="headerDraFormColle"
                            title={userLoggedIn?.language_data?.formstudio?.form_collection || "Form Collections"}
                            onClick={(event) =>
                                setState((prevState) => ({
                                    ...prevState,
                                    popperOpen: true,
                                    anchorEl: event.currentTarget,
                                }))
                            }
                            className={
                                popperOpen
                                    ? "button_contained_sec"
                                    : "drawerButtonStyle"
                            }
                        />
                    </Grid>
                </Grid>
            ) : (
                <>
                    <Grid container gap={2}>
                        <ButtonComponent
                            id="headerDraFormColle"
                            title={userLoggedIn?.language_data?.formstudio?.form_collection || "Form Collections"}
                            onClick={(event) =>
                                setState((prevState) => ({
                                    ...prevState,
                                    openForms: true,
                                    elementDrawer: true
                                }))
                            }
                            className={
                                popperOpen
                                    ? "button_contained_sec"
                                    : "drawerButtonStyle"
                            }
                        />
                        <ButtonComponent
                            id="headerDraFormElement"
                            title={"Form Element"}
                            onClick={() =>  openForms ? 
                                setState((prevState) => ({
                                    ...prevState,
                                    elementDrawer: true,
                                    openForms: false
                                }))
                                :
                                handlerElementDrawerOpen()}
                            className={
                                elementDrawer
                                    ? "button_contained_sec"
                                    : "drawerButtonStyle"
                            }
                        />
                        <ButtonComponent
                            id="headerDraFontAndDesign"
                            title={userLoggedIn?.language_data?.formstudio?.font_design || "Font & Design"}
                            onClick={handlerDesignDrawerOpen}
                            className={
                                designDrawer
                                    ? "button_contained_sec"
                                    : "drawerButtonStyle"
                            }
                        />
                    </Grid>
                    <Grid item justifyContent={"flex-end"}>
                        <ButtonComponent
                            id="headerDraPreview"
                            title={userLoggedIn?.language_data?.formstudio?.preview || "Preview"}
                            onClick={handlerPreview}
                            className={
                                previewButton
                                    ? "button_contained_sec"
                                    : "drawerButtonStyle"
                            }
                        />
                    </Grid>
                </>
            )}
        </Grid>
    );
};

const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,
    };
};

export default connect(mapStateToProps, null) (HeaderDrawerLink);
