import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import TextFieldComponent from "../../../components/TextField/TextFieldComponent";
import DropDownComponent from "../../../components/DropDown/DropDownComponent";
import { connect } from "react-redux";
function FullName(props) {
    const {
        state,
        setState,
        data,
        children,
        handleChange,
        isRead,
        handlerFieldChange,
        isEdit,
        userLoggedIn
    } = props;

    const handlerIsEdit = () => {
        if (isEdit) {
            setState((prevState) => ({ ...prevState, editLabel: true }));
        }
    };

    const onBlur = () => {
        setState((prevState) => ({ ...prevState, editLabel: false }));
    };

    return (
        <Grid container>
            <Grid item xs={12} className={"elementItemStyle"}>
                <TextFieldComponent
                    id="fullNameLabel"
                    sx={{
                        display:
                            state?.selectedEle?.selected && state?.editLabel
                                ? "block"
                                : "none",
                        color: state?.fontColor,
                        "& .MuiInputBase-input": {
                            color: state?.fontColor,
                        },
                    }}
                    onBlur={onBlur}
                    onChange={handleChange}
                    value={data?.label}
                    className="elementHeadingTextField"
                    autoFocus={true}
                />
                <Typography
                    display={
                        state?.selectedEle?.selected && state?.editLabel
                            ? "none"
                            : "block"
                    }
                    className="elementHeading"
                    sx={{ color: state?.fontColor }}
                    variant="h5"
                    onClick={handlerIsEdit}
                >
                    {data.label}
                </Typography>
                {children}
            </Grid>
            <Grid
                item
                container
                gap={2}
                display={"flex"}
                flexDirection={"row"}
                flexWrap={"nowrap"}
            >
                <Grid item xs={2}>
                    <DropDownComponent
                        label={"Prefix"}
                        sx={{ width: "100%" }}
                        className="textFieldFromBuilder"
                        disabled={isRead}
                        onChange={(event) =>
                            handlerFieldChange(event, data?.id)
                        }
                        name={"prefix"}
                        value={data?.formValues?.prefix || ""}
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        {["Mr.", "Mrs.", "Miss.", "Dr.", "Er."].map(
                            (option) => (
                                <MenuItem value={option}>{option}</MenuItem>
                            )
                        )}
                    </DropDownComponent>
                </Grid>
                <Grid item container spacing={2}>
                    <Grid item xs={6} sm={12} md={6}>
                        <TextFieldComponent
                            label={userLoggedIn?.language_data?.customer?.first_name || "First Name"}
                            className="textFieldFromBuilder"
                            disabled={isRead}
                            onChange={(event) =>
                                handlerFieldChange(event, data?.id)
                            }
                            name={"firstName"}
                            value={data?.formValues?.firstName}
                        />
                    </Grid>
                    <Grid item xs={6} sm={12} md={6}>
                        <TextFieldComponent
                            id="fullNameMiddleName"
                            label={userLoggedIn?.language_data?.customer?.middle_name || "Middle Name"}
                            className="textFieldFromBuilder"
                            disabled={isRead}
                            onChange={(event) =>
                                handlerFieldChange(event, data?.id)
                            }
                            name={"middleName"}
                            value={data?.formValues?.middleName}
                        />
                    </Grid>
                    <Grid item xs={6} sm={12} md={6}>
                        <TextFieldComponent
                            id="fullNameLastName"
                            label={userLoggedIn?.language_data?.customer?.last_name || "Last Name"}
                            className="textFieldFromBuilder"
                            disabled={isRead}
                            onChange={(event) =>
                                handlerFieldChange(event, data?.id)
                            }
                            name={"lastName"}
                            value={data?.formValues?.lastName}
                        />
                    </Grid>
                    <Grid item xs={6} sm={12} md={6}>
                        <TextFieldComponent
                            label={userLoggedIn?.language_data?.customer?.display_name || "Display Name"}
                            className="textFieldFromBuilder"
                            disabled={isRead}
                            onChange={(event) =>
                                handlerFieldChange(event, data?.id)
                            }
                            name={"displayName"}
                            value={data?.formValues?.displayName}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,
        
    };
};

export default connect(mapStateToProps, null) (FullName);
