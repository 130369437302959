import React from "react";
import Typography from "@mui/material/Typography";

function PageNotFound(props) {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "512px",
            }}
        >
            <Typography sx={{ fontSize: "170px" }} className="primaryColor">
                404
            </Typography>
            <Typography
                sx={{
                    fontSize: "30px",
                    fontWeight: 600,
                    marginLeft: "50px",
                    marginTop: "114px",
                }}
                className="primaryColor"
            >
                Page Not Found
            </Typography>
        </div>
    );
}

export default PageNotFound;
