import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import PageHeader from "../../../common/PageHeader";
import {
    Typography,
    Grid,
    FormControlLabel,
    FormGroup,
    Checkbox,
    MenuItem,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Footer from "../../../common/Footer/Footer";
import Navbar from "../../../common/Header/Navbar";
import FiberManualRecordIcon from "@mui/icons-material/Circle";
import ButtonComponent from "../../../components/ButtonComponent";
import { ROUTES } from "../../../router/RouteList";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import color from "../../../../assets/styles/Color.scss";
import { getAssessmentSummary } from "../../../store/Action/AssessmentSettingAction";
import { bindActionCreators } from "redux";
import LoadingComponent from "../../../components/Loading/LoadingComponent";
import {
    getConflictResponses,
    updateConflict,
} from "../../../store/Action/MqsAction";
import DialogComponent from "../../../components/DialogsBox/DialogComponent";
import ShowAssesmentUserData from "./ShowAssesmentUserData";
import CheckboxComponent from "../../../components/Checkbox/CheckboxComponent";
import DropDownComponent from "../../../components/DropDown/DropDownComponent";
import { setUpdateConfiltResponse } from "../../../store/Action/TestAction";

function ConflictResponse(props) {
    const {
        userLoggedIn,
        conflictQuestionsData,
        getAssessmentSummary,
        getConflictResponses,
        setUpdateConfiltResponse,
        updatedConfiltResonses,
        updateConflict,
    } = props;
    const [state, setState] = useState({
        assessmentSummaryDetails: null,
        totalQuestions: 0,
        isLoading: false,
        isShowViewDetailsDialog: { open: false, title: null, content: null },
        isEditConfilt: false,
        showEditDropDown: { ques_id: null, role_id: null, open: false },
    });
    const navigate = useNavigate();
    const params = useParams();
    console.log("params", params);
    const {
        assessmentSummaryDetails,
        isLoading,
        isShowViewDetailsDialog,
        isEditConfilt,
        showEditDropDown,
    } = state;
    useEffect(() => {
        ApiCall();
    }, [params?.user_id, params?.client_id_id, params.contractor_id]);
    const ApiCall = async () => {
        setState((prevState) => ({
            ...prevState,
            isLoading: true,
        }));
        let data = {
            guid: localStorage?.getItem("guid"),
            user_id: params?.user_id,
            test_type: 4,
            role_id: localStorage?.getItem("role_id"),
            contractor_id: params?.contractor_id,
            mode: localStorage?.getItem("mode"),
        };
        const response = await getConflictResponses(data);
        setState((prevState) => ({
            ...prevState,
            assessmentSummaryDetails: response?.payload,
            isLoading: false,
        }));
    };
    useEffect(() => {
        if (conflictQuestionsData) {
            setState((prevState) => ({
                ...prevState,
                totalQuestions: conflictQuestionsData.total_questions,
            }));
        }
    }, [conflictQuestionsData]);

    const handleEditConflicting = () => {
        // navigate(
        //     ROUTES.Resolve_Conflict +
        //         "/" +
        //         params.client_id +
        //         "/" +
        //         params.user_id
        // );
        setState((prevState) => ({ ...prevState, isEditConfilt: true }));
    };
    const editResponse = (id, data) => {
        setState((prevState) => ({
            ...prevState,
            showEditDropDown: {
                open: true,
                ques_id: id,
                role_id: data.role_id,
            },
        }));
    };
    const handlerResponse = async (e, editData) => {
        let data = {
            ques_id: editData.ques_id,
            response: e.target.value,
            role_id: editData.role_id,
        };
        await setUpdateConfiltResponse(data);
        setState((prevState) => ({
            ...prevState,
            showEditDropDown: {
                open: false,
                ques_id: null,
                role_id: null,
            },
        }));
    };
    const updateResponse = async () => {
        setState((prevState) => ({ ...prevState, isLoading: true }));
        let data = {
            test_type: assessmentSummaryDetails.test_type,
            guid: localStorage?.getItem("guid"),
            user_id: params.user_id,
            question_responses: updatedConfiltResonses,
            task_id: localStorage?.getItem("task_id"),
            mode: localStorage?.getItem("mode"),
            contractor_id: params.contractor_id,
        };
        await updateConflict(data);
        setState((prevState) => ({ ...prevState, isLoading: false }));
        navigate(ROUTES.COMPLIANCE_WORKFLOW);
    };
    const handlerLable = (role) => {
        if (updatedConfiltResonses.length) {
            let findData = updatedConfiltResonses.find(
                (obj) => obj.ques_id === role.ques_id
            );
            return findData ? findData.response : role.response;
        } else {
            return role.response;
        }
    };
    return (
        <>
            <Navbar />
            {isLoading ? (
                <LoadingComponent />
            ) : (
                <Box sx={{ px: { md: 7, xs: 1 }, mb: 5 }}>
                    <PageHeader
                        title={assessmentSummaryDetails?.test_name}
                        sx={{ paddingBottom: "0px !important" }}
                    />
                    <Grid container spacing={2} mt={1}>
                        <ShowAssesmentUserData
                            assessmentSummaryDetails={assessmentSummaryDetails}
                            userLoggedIn={userLoggedIn}
                        />
                    </Grid>
                    <Grid container>
                        <Grid
                            item
                            md={2}
                            xs={12}
                            mt={4}
                            display={"flex"}
                            justifyContent={"flex-start"}
                            alignItems={"center"}
                            gap={1}
                        >
                            <FiberManualRecordIcon
                                className="primaryColorIcon"
                                fontSize={"12px"}
                            />
                            <Typography className="roleText">
                                Manager's Response
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            md={2}
                            xs={12}
                            mt={4}
                            display={"flex"}
                            justifyContent={"flex-start"}
                            alignItems={"center"}
                            gap={1}
                        >
                            <FiberManualRecordIcon
                                className="secondaryColorIcon"
                                fontSize={"12px"}
                            />
                            <Typography className="roleText">
                                Contractor's Response
                            </Typography>
                        </Grid>
                        {isEditConfilt ? (
                            <Grid
                                item
                                md={8}
                                xs={12}
                                mt={4}
                                display={"flex"}
                                justifyContent={"flex-end"}
                            >
                                <ButtonComponent
                                    title="Update Responses"
                                    onClick={updateResponse}
                                />
                            </Grid>
                        ) : (
                            <Grid
                                item
                                md={8}
                                xs={12}
                                mt={4}
                                display={"flex"}
                                justifyContent={"flex-end"}
                            >
                                <ButtonComponent
                                    onClick={handleEditConflicting}
                                    title={"Resolve Conflict"}
                                />
                            </Grid>
                        )}
                    </Grid>
                    {conflictQuestionsData.data?.length &&
                        conflictQuestionsData?.data.map((ques) => {
                            let filterOption = ques.available_response.filter(
                                (obj) =>
                                    ![
                                        ques?.contactor_response.response,
                                        ques?.manager_response.response,
                                    ].includes(obj.response)
                            );
                            return (
                                <>
                                    <Grid item md={12} lg={12} mt={3}>
                                        <Typography
                                            className="managerText"
                                            my={2}
                                        >
                                            Question {ques.ques_number} of{" "}
                                            {state.totalQuestions}
                                        </Typography>
                                        <Typography
                                            className={"font_18_400"}
                                            dangerouslySetInnerHTML={{
                                                __html: ques.question,
                                            }}
                                        />
                                        {isEditConfilt ? (
                                            <RadioGroup>
                                                <Box
                                                    display={"flex"}
                                                    gap={2}
                                                    alignItems={"center"}
                                                >
                                                    <FormControlLabel
                                                        control={
                                                            <Radio
                                                                disabled="true"
                                                                sx={{
                                                                    "&.MuiRadio-root":
                                                                        {
                                                                            color: color.primary,
                                                                        },
                                                                }}
                                                            />
                                                        }
                                                        label={handlerLable(
                                                            ques.manager_response
                                                        )}
                                                    />
                                                    {showEditDropDown.open &&
                                                    showEditDropDown.ques_id ===
                                                        ques.ques_id &&
                                                    showEditDropDown.role_id ===
                                                        ques.manager_response
                                                            .role_id ? (
                                                        <DropDownComponent
                                                            size="small"
                                                            sx={{
                                                                backgroundColor:
                                                                    color.tertiary,

                                                                "&.MuiFilledInput-root":
                                                                    {
                                                                        backgroundColor:
                                                                            color.tertiary,
                                                                    },
                                                                m: 1,
                                                                minWidth: 100,
                                                            }}
                                                            onChange={(e) => {
                                                                handlerResponse(
                                                                    e,
                                                                    ques?.manager_response
                                                                );
                                                            }}
                                                        >
                                                            {ques.available_response?.map(
                                                                (
                                                                    option,
                                                                    index
                                                                ) => (
                                                                    <MenuItem
                                                                        key={
                                                                            index
                                                                        }
                                                                        value={
                                                                            option.response
                                                                        }
                                                                    >
                                                                        {
                                                                            option.response
                                                                        }
                                                                    </MenuItem>
                                                                )
                                                            )}
                                                        </DropDownComponent>
                                                    ) : (
                                                        <Box
                                                            display={"flex"}
                                                            alignItems={
                                                                "center"
                                                            }
                                                            gap={2}
                                                        >
                                                            <IconButton
                                                                aria-label="Edit Confilt"
                                                                size="small"
                                                                onClick={() => {
                                                                    editResponse(
                                                                        ques?.ques_id,
                                                                        ques?.manager_response
                                                                    );
                                                                }}
                                                            >
                                                                <EditOutlinedIcon fontSize="inherit" />
                                                            </IconButton>
                                                            {updatedConfiltResonses.map(
                                                                (obj) => {
                                                                    return obj.ques_id ===
                                                                        ques
                                                                            ?.manager_response
                                                                            .ques_id ? (
                                                                        <>
                                                                            <Typography>
                                                                                Original
                                                                                Response:
                                                                            </Typography>
                                                                            <FormControlLabel
                                                                                control={
                                                                                    <Radio
                                                                                        sx={{
                                                                                            "&.MuiRadio-root":
                                                                                                {
                                                                                                    color: color.primary,
                                                                                                },
                                                                                        }}
                                                                                    />
                                                                                }
                                                                                disabled="true"
                                                                                label={
                                                                                    obj.ques_id ===
                                                                                        ques
                                                                                            ?.manager_response
                                                                                            .ques_id &&
                                                                                    ques
                                                                                        ?.manager_response
                                                                                        .response
                                                                                }
                                                                            />
                                                                        </>
                                                                    ) : null;
                                                                }
                                                            )}
                                                        </Box>
                                                    )}
                                                </Box>
                                                <Box
                                                    display={"flex"}
                                                    gap={2}
                                                    alignItems={"center"}
                                                >
                                                    <FormControlLabel
                                                        control={
                                                            <Radio
                                                                disabled="true"
                                                                sx={{
                                                                    "&.MuiRadio-root":
                                                                        {
                                                                            color: color.secondary,
                                                                        },
                                                                }}
                                                            />
                                                        }
                                                        label={handlerLable(
                                                            ques.contactor_response
                                                        )}
                                                    />
                                                    {showEditDropDown.open &&
                                                    showEditDropDown.ques_id ===
                                                        ques.ques_id &&
                                                    showEditDropDown.role_id ===
                                                        ques.contactor_response
                                                            .role_id ? (
                                                        <DropDownComponent
                                                            size="small"
                                                            sx={{
                                                                backgroundColor:
                                                                    color.tertiary,

                                                                "&.MuiFilledInput-root":
                                                                    {
                                                                        backgroundColor:
                                                                            color.tertiary,
                                                                    },
                                                                m: 1,
                                                                minWidth: 100,
                                                            }}
                                                            onChange={(e) => {
                                                                handlerResponse(
                                                                    e,
                                                                    ques?.contactor_response
                                                                );
                                                            }}
                                                        >
                                                            {ques.available_response?.map(
                                                                (
                                                                    option,
                                                                    index
                                                                ) => (
                                                                    <MenuItem
                                                                        key={
                                                                            index
                                                                        }
                                                                        value={
                                                                            option.response
                                                                        }
                                                                    >
                                                                        {
                                                                            option.response
                                                                        }
                                                                    </MenuItem>
                                                                )
                                                            )}
                                                        </DropDownComponent>
                                                    ) : (
                                                        <Box
                                                            display={"flex"}
                                                            alignItems={
                                                                "center"
                                                            }
                                                            gap={2}
                                                        >
                                                            <IconButton
                                                                aria-label="Edit Confilt"
                                                                size="small"
                                                                onClick={() => {
                                                                    editResponse(
                                                                        ques?.ques_id,
                                                                        ques?.contactor_response
                                                                    );
                                                                }}
                                                            >
                                                                <EditOutlinedIcon fontSize="inherit" />
                                                            </IconButton>
                                                            {updatedConfiltResonses.map(
                                                                (obj) => {
                                                                    return obj.ques_id ===
                                                                        ques
                                                                            ?.contactor_response
                                                                            .ques_id ? (
                                                                        <>
                                                                            <Typography>
                                                                                Original
                                                                                Response:
                                                                            </Typography>
                                                                            <FormControlLabel
                                                                                control={
                                                                                    <Radio
                                                                                        sx={{
                                                                                            "&.MuiRadio-root":
                                                                                                {
                                                                                                    color: color.secondary,
                                                                                                },
                                                                                        }}
                                                                                    />
                                                                                }
                                                                                disabled="true"
                                                                                label={
                                                                                    obj.ques_id ===
                                                                                        ques
                                                                                            ?.contactor_response
                                                                                            .ques_id &&
                                                                                    ques
                                                                                        ?.contactor_response
                                                                                        .response
                                                                                }
                                                                            />
                                                                        </>
                                                                    ) : null;
                                                                }
                                                            )}
                                                        </Box>
                                                    )}
                                                </Box>
                                                {/* <Box
                                                    display={"flex"}
                                                    gap={2}
                                                    alignItems={"center"}
                                                >
                                                    <FormControlLabel
                                                        disabled="true"
                                                        control={
                                                            <Radio
                                                                sx={{
                                                                    "&.MuiRadio-root":
                                                                        {
                                                                            color: color.secondary,
                                                                        },
                                                                }}
                                                            />
                                                        }
                                                        label={
                                                            ques
                                                                ?.contactor_response
                                                                .response
                                                        }
                                                    />{" "}
                                                    {showEditDropDown.open &&
                                                    showEditDropDown.ques_id ===
                                                        ques.ques_id &&
                                                    showEditDropDown.role_id ===
                                                        ques.contactor_response
                                                            .role_id ? (
                                                        <DropDownComponent
                                                            size="small"
                                                            sx={{
                                                                backgroundColor:
                                                                    color.tertiary,

                                                                "&.MuiFilledInput-root":
                                                                    {
                                                                        backgroundColor:
                                                                            color.tertiary,
                                                                    },
                                                                m: 1,
                                                                minWidth: 100,
                                                            }}
                                                            onChange={(e) => {
                                                                handlerResponse(
                                                                    e,
                                                                    ques?.contactor_response
                                                                );
                                                            }}
                                                        >
                                                            {ques.available_response?.map(
                                                                (
                                                                    option,
                                                                    index
                                                                ) => (
                                                                    <MenuItem
                                                                        key={
                                                                            index
                                                                        }
                                                                        value={
                                                                            option.response
                                                                        }
                                                                    >
                                                                        {
                                                                            option.response
                                                                        }
                                                                    </MenuItem>
                                                                )
                                                            )}
                                                        </DropDownComponent>
                                                    ) : (
                                                        <IconButton
                                                            aria-label="Edit Confilt"
                                                            size="small"
                                                            onClick={() => {
                                                                editResponse(
                                                                    ques?.ques_id,
                                                                    ques?.contactor_response
                                                                );
                                                            }}
                                                        >
                                                            <EditOutlinedIcon fontSize="inherit" />
                                                        </IconButton>
                                                    )}
                                                </Box> */}
                                                {/* <FormControlLabel
                                                    value="other"
                                                    control={<Radio />}
                                                    disabled="true"
                                                    label={filterOption.map(
                                                        (obj) => {
                                                            return (
                                                                obj.response +
                                                                "/"
                                                            );
                                                        }
                                                    )}
                                                /> */}
                                            </RadioGroup>
                                        ) : (
                                            <FormGroup>
                                                {ques.available_response.map(
                                                    (obj) => {
                                                        return (
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        icon={
                                                                            <RadioButtonUncheckedIcon />
                                                                        }
                                                                        checkedIcon={
                                                                            <RadioButtonCheckedIcon />
                                                                        }
                                                                        checked={
                                                                            obj.response ===
                                                                                ques
                                                                                    ?.contactor_response
                                                                                    .response ||
                                                                            obj.response ===
                                                                                ques
                                                                                    ?.manager_response
                                                                                    .response
                                                                        }
                                                                        sx={{
                                                                            "&.Mui-checked":
                                                                                {
                                                                                    color:
                                                                                        obj.response ===
                                                                                        ques
                                                                                            ?.contactor_response
                                                                                            .response
                                                                                            ? color.secondary
                                                                                            : color.primary,
                                                                                },
                                                                        }}
                                                                    />
                                                                }
                                                                label={
                                                                    obj.response
                                                                }
                                                            />
                                                        );
                                                    }
                                                )}
                                            </FormGroup>
                                        )}
                                    </Grid>
                                </>
                            );
                        })}
                </Box>
            )}
            <Footer />
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,
        assessmentSummaryData: state?.assessmentSettings?.assessmentSummaryData,
        conflictQuestionsData: state?.mqs?.conflictQuestion,
        updatedConfiltResonses: state?.testReducer?.updateConfiltData,
    };
};
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getAssessmentSummary,
            getConflictResponses,
            setUpdateConfiltResponse,
            updateConflict,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ConflictResponse);
