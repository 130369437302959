import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useNavigate } from "react-router-dom";
import Box from "@mui/system/Box";
import Grid from "@mui/material/Grid";
import Navbar from "../../common/Header/Navbar";
import PageHeader from "../../common/PageHeader";
import HeaderPagination from "../../common/PaginationSection/HeaderPagination";
import CardView from "./RoleCardView";
import CommonDeleteDialog from "../../components/DialogsBox/CommonDeleteDialog";
import RoleTableView from "./RoleTableView";
import LoadingComponent from "../../components/Loading/LoadingComponent";
import { ROUTES } from "../../router/RouteList";
import {
    getRolesList,
    deleteRole,
    changeRoleViewType,
    setCustomerPageLimit,
    setCurrentPage,
} from "../../store/Action/RolesModuleAction";
import { getUserByRole } from "../../store/Action/UserAction";
import "../../../assets/styles/Common/_CommonStyle.scss";
import "../../../assets/styles/Container/ManageRole.scss";
import Footer from "../../common/Footer/Footer";
import FooterPagination from "../../common/PaginationSection/FooterPagination";
import { pageLimit } from "../../utils/CommonData";
import {Translate} from 'react-auto-translate';

const ManageRoles = (props) => {
    const navigate = useNavigate();
    const {
        roleList,
        getRolesList,
        deleteRole,
        changeRoleViewType,
        viewRoleType,
        getUserByRole,
        rolePagination,
        setCustomerPageLimit,
        setCurrentPage,
        userLoggedIn
    } = props;

    const [state, setState] = useState({
        openDeleteDialog: false,
        selectedRoleData: null,
        isLoading: false,
        filteredData: null,
    });
    const { openDeleteDialog, selectedRoleData, isLoading, filteredData } =
        state;

    useEffect(() => {
        if (roleList === null)
            getRolesList(null, rolePagination?.listViewLimit);
        if (roleList) {
            setState((prevState) => ({ ...prevState, filteredData: roleList }));
        }
    }, [getRolesList, roleList]);

    function handleView(roleData, type) {
        const path = ROUTES.MANAGE_MODULE_ROLE_PERMISSION.split("/");
        navigate(`/${path[1]}/` + roleData?.id, {
            state: { roleData: roleData, type: type },
        });
    }

    function handleDelete(data) {
        setState((prevState) => ({
            ...prevState,
            openDeleteDialog: true,
            selectedRoleData: data,
        }));
    }

    async function handleDeleteAction() {
        setState((prevState) => ({ ...prevState, isLoading: true }));
        cancelButtonAction();
        await deleteRole(selectedRoleData);
        setState((prevState) => ({ ...prevState, isLoading: false }));
    }

    function cancelButtonAction() {
        setState((prevState) => ({ ...prevState, openDeleteDialog: false }));
    }

    function handleChangeView(viewRoleType) {
        changeRoleViewType(viewRoleType);
    }

    function handleSearch(data) {
        let newList = filteredData;
        if (data !== "") {
            newList = roleList.filter((obj) =>
                obj.name?.toLowerCase()?.includes(data.toLowerCase())
            );
            setState((prevState) => ({
                ...prevState,
                filteredData: newList || roleList,
            }));
        } else {
            setState((prevState) => ({
                ...prevState,
                filteredData: roleList,
            }));
        }
    }

    async function handlerGetUsers(data) {
        setState((prevState) => ({ ...prevState, isLoading: true }));
        await getUserByRole(data?.id, null, rolePagination?.listViewLimit);
        setState((prevState) => ({ ...prevState, isLoading: false }));
        navigate(ROUTES.USER + `${"?" + data?.id}`, {
            state: { roleId: data?.id },
        });
    }

    const handleViewLimitChange = async (data) => {
        setCustomerPageLimit(data?.target?.value);
        setState((prevState) => ({ ...prevState, isLoading: true }));
        await getRolesList(null, data?.target?.value);
        setState((prevState) => ({ ...prevState, isLoading: false }));
    };

    const handlePaginationOnChange = async (_, pageNumber) => {
        setCurrentPage(pageNumber);
        setState((prevState) => ({ ...prevState, isLoading: true }));
        await getRolesList(pageNumber, rolePagination?.listViewLimit);
        setState((prevState) => ({ ...prevState, isLoading: false }));
    };
    return (
        <>
        <CommonDeleteDialog
            open={openDeleteDialog}
            cancelButtonAction={cancelButtonAction}
            title={
                <Translate>
                    {selectedRoleData?.status === "active" ? "Deactivate" : "Activate"} Role
                </Translate>
            }
            cancelButtonTitle={<Translate>Cancel</Translate>}
            submitButtonTitle={
                <Translate>
                    {selectedRoleData?.status === "active" ? "Deactivate" : "Activate"}
                </Translate>
            }
            content={
                <Translate>
                    {selectedRoleData?.status === "active" ? "Deactivating" : "Activating"} Role will {selectedRoleData?.status === "active" ? "disable" : "enable"} the access to all the users.
                </Translate>
            }
            submitButtonAction={handleDeleteAction}
        />
        <Navbar />
        {!roleList || isLoading ? (
            <LoadingComponent sx={{ height: "600px" }} />
        ) : (
            <Box sx={{ px: { md: 7, xs: 1 }, pb: 7 }}>
                <PageHeader
                    title={
                        <Translate>
                            {userLoggedIn?.language_data?.role?.manage_role || "Manage Roles"}
                        </Translate>
                    }
                    buttonNavigateUrl={ROUTES.CREATE_NEW_ROLES}
                    showSearchBar={true}
                    buttonTitle={<Translate>New Role</Translate>}
                    handleSearch={handleSearch}
                />
                <HeaderPagination
                    handleChangeView={handleChangeView}
                    showViewButton={true}
                    total={rolePagination?.total}
                    listViewLimit={rolePagination?.listViewLimit}
                    handleViewLimitChange={handleViewLimitChange}
                    showPagination={pageLimit < rolePagination?.total}
                />
                {viewRoleType === "table" ? (
                    filteredData !== null && 
                    <>
                        <RoleTableView
                            roleList={filteredData}
                            handleDelete={handleDelete}
                            handleView={handleView}
                            handlerGetUsers={handlerGetUsers}
                        />
                    </>     
                ) : (
                    <Grid
                        container
                        display={"flex"}
                        sx={{ mt: 2, paddingBottom: 2 }}
                    >
                        <Grid
                            container
                            display={"flex"}
                            flexWrap={"wrap"}
                            justifyContent={"center"}
                        >
                            <CardView
                                roleList={filteredData}
                                handleDelete={handleDelete}
                                handleView={handleView}
                                handlerGetUsers={handlerGetUsers}
                            />
                        </Grid>
                    </Grid>
                )}
                {pageLimit < rolePagination?.total ? (
                    <FooterPagination
                        count={rolePagination?.lastPage}
                        page={rolePagination?.currentPage}
                        onChange={handlePaginationOnChange}
                        defaultPage={rolePagination?.currentPage}
                    />
                ) : null}
            </Box>
        )}
        <Footer />
    </>
    
    );
};

const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,
        roleList: state.roles.roleList,
        viewRoleType: state.roles?.viewRoleType,
        rolePagination: state.roles?.rolePagination,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getRolesList,
            deleteRole,
            changeRoleViewType,
            getUserByRole,
            setCustomerPageLimit,
            setCurrentPage,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageRoles);
