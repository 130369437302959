import React from "react";
import { TextareaAutosize } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextFieldComponent from "../../../components/TextField/TextFieldComponent";

const LongText = (props) => {
    const {
        state,
        setState,
        data,
        children,
        handleChange,
        isRead,
        handlerFieldChange,
        isEdit,
    } = props;

    const handlerIsEdit = () => {
        if (isEdit) {
            setState((prevState) => ({ ...prevState, editLabel: true }));
        }
    };
    const onBlur = () => {
        setState((prevState) => ({ ...prevState, editLabel: false }));
    };

    return (
        <Grid container>
            <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "space-between" }}
            >
                <TextFieldComponent
                    sx={{
                        display:
                            state?.selectedEle?.selected && state?.editLabel
                                ? "block"
                                : "none",
                        color: state?.fontColor,
                        "& .MuiInputBase-input": {
                            color: state?.fontColor,
                        },
                    }}
                    id="longTextLabel"
                    onBlur={onBlur}
                    onChange={handleChange}
                    value={data?.label}
                    className="elementHeadingTextField"
                    autoFocus={true}
                />
                <Typography
                    display={
                        state?.selectedEle?.selected && state?.editLabel
                            ? "none"
                            : "block"
                    }
                    className="elementHeading"
                    variant="h5"
                    onClick={handlerIsEdit}
                    sx={{ color: state?.fontColor }}
                >
                    {data?.label}
                </Typography>
                {children}
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextareaAutosize
                        className="longTextFromBuilder"
                        minRows={4}
                        disabled={isRead}
                        onChange={(event) =>
                            handlerFieldChange(event, data?.id)
                        }
                        name={"longText"}
                        value={data?.formValues?.longText}
                        id="longTextEnterText"
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default LongText;
