import * as Actions from "../Action/ComplianceWorkflowAction";
const initialState = {
    assignUserIn: null,
    conductorList: null,
    complianceList: null,
    selectedContractors: null,
    selectedService: null,
    workflowWorkersList: [],
    loggedInCustomer:null,
};

const assignUser = (state = initialState, action) => {
    switch (action?.type) {
        case Actions.ASSIGN_USER_ACTION: {
            return {
                ...state,
                ...action.payload,
            };
        }
        case Actions.CONDUCTOR_TYPE_ACTION: {
            return {
                ...state,
                conductorList: action.payload,
            };
        }
        case Actions.CONDUCTOR_ACTION: {
            return {
                ...state,
                complianceList: action.payload,
                selectedService: null,
            };
        }
        case Actions.SELECTED_CONTRACTORS_ACTION: {
            return {
                ...state,
                selectedContractors: action.payload,
            };
        }
        case Actions.ASSIGN_CONTRACTORS_ACTION: {
            return {
                ...state,
                // selectedContractors: action.payload,
            };
        }
        case Actions.ASSIGN_COMP_SERVICE: {
            return {
                ...state,
                selectedService: action.payload,
            };
        }

        case Actions.GET_ASSESMENT_USER_LIST: {
            return { ...state, assesmentUserList: action.payload.data };
        }
        case Actions.GET_WORKERS_PROGESS_LIST: {
            return { ...state, workflowWorkersList: action.payload };
        }
        case Actions.GET_CUSTOMER_FOR_COMPLIANCE: {
            return { ...state, loggedInCustomer: action.payload };
        }
        default: {
            return state;
        }
    }
};

export default assignUser;
