import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { toast } from "react-hot-toast";
import Paper from "@mui/material/Paper";
import Navbar from "../../common/Header/Navbar";
import ButtonComponent from "./../../components/ButtonComponent";
import { ROUTES } from "./../../router/RouteList";
import { forgotPassword } from "../../store/Action/LoginAction";
import "../../../assets/styles/Container/Login.scss";
import TextFieldComponent from "../../components/TextField/TextFieldComponent";

function ForgetPassword(props) {
    const { forgotPassword, userLoggedIn, guid } = props;
    const [email, setEmail] = useState("");
    async function handleResetPassword() {
       const forgetresp = await forgotPassword(email, guid);
        if(forgetresp){
            toast.success(forgetresp?.payload);
        }
    }

    return (
        <>
            <Navbar />
            <Grid container spacing={2} mt={0}>
                <Grid
                    item
                    xs={12}
                    mt={16}
                    justifyContent={"center"}
                    display={"flex"}
                    alignItems={"center"}
                >
                    <Paper className="paperReset">
                        <Grid container spacing={2}>
                            <Grid
                                item
                                xs={12}
                                justifyContent={"center"}
                                display={"flex"}
                            >
                                <Typography className="resetHeading">
                                    Reset Password
                                </Typography>
                            </Grid>
                            <Typography className="textHeading">
                                Please enter your email address, we'll share a
                                link to reset your password
                            </Typography>
                            <Grid item xs={12} className="textfieldGrid" mt={4}>
                                <TextFieldComponent
                                    onChange={(event) =>
                                        setEmail(event.target.value)
                                    }
                                    id="forgetPassword"
                                    variant="standard"
                                    label={userLoggedIn?.language_data?.customer?.email_address || "Email Address"}
                                    className="loginTextfield"
                                    required
                                    value={email}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                justifyContent={"center"}
                                display={"flex"}
                                mt={4}
                            >
                                <ButtonComponent
                                    id="forgetPassResetButton"
                                    title={"Reset Password"}
                                    type="submit"
                                    variant="contained"
                                    sx={{ padding: "12px" }}
                                    onClick={() => handleResetPassword()}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                justifyContent={"center"}
                                display={"flex"}
                                mt={1}
                            >
                                <Link to={ROUTES.LOGIN} className="backToLogin" id="forgetPassBackButton">
                                    Back to Login
                                </Link>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,
        guid:state?.whiteLabel?.guid
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ forgotPassword }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgetPassword);
