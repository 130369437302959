import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";

const CustomSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-track, & .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track":
        {
            backgroundColor: "#00A19D",
            opacity: "0.8",
        },
    "& .MuiSwitch-thumb": {
        backgroundColor: "white",
    },
}));

export default CustomSwitch;
