import * as Actions from "../Action/DefaultComplianceSettingAction";

const initialState = {
    settings: [],
    list: null,
    defaultComplianceSelectData: null,
};

const defaultComplianceSettingReducer = (state = initialState, action) => {
    switch (action?.type) {
        case Actions.GET_DEFAULT_COMPLIANCE_SETTINGS: {
            return {
                ...state,
                settings: action.payload,
            };
        }
        case Actions.GET_USER_DEFAULT_COMPLIANCE_SETTINGS: {
            return {
                ...state,
                defaultComplianceSelectData: action.payload,
            };
        }
        case Actions.CREATE_USER_DEFAULT_COMPLIANCE_SETTINGS: {
            return {
                ...state,
                list: action.payload,
                defaultComplianceSelectData: action.payload,
            };
        }

        case Actions.VIEW_DEFAULT_EXTRA_SETTINGS: {
            return {
                ...state,
                extraSetting: action.payload
            }
        }
        default: {
            return state;
        }
    }
};

export default defaultComplianceSettingReducer;
