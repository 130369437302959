import { Box } from "@mui/system";
import {
    Grid,
    Typography,
    FormControlLabel,
    MenuItem,
    Checkbox,
    ListItemText,
    Chip,
    TableCell,
    TableRow,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import "../../../assets/styles/Container/ScoringConfiguration.scss";
import Navbar from "../../common/Header/Navbar";
import TextFieldComponent from "../../components/TextField/TextFieldComponent";
import Tooltip from "../../components/ToolTip/TooltipComponent";
import InfoIcon from "@mui/icons-material/Info";
import Icon from "@mui/material/Icon";
import QuestionScoreTableComponent from "../../components/Table/QuestionScoreTableComponent";
import RadioButtonGroup from "../../components/RadioButtonGroup/RadioButtonGroup";
import {
    rangeOptions,
    rankingOptions,
    alternativeContentResponses,
    tcOptions,
    mqsYesNoOptions,
} from "../../utils/CommonData";
import "../../components/DropDown/DropDownComponent";
import { connect } from "react-redux";
import "../../components/DropDown/DropDown.scss";
import DropDownComponent from "../../components/DropDown/DropDownComponent";
import MultiSelectDropDown from "../../components/DropDown/MultiSelectDropDown";
import ButtonComponent from "../../components/ButtonComponent";
import {
    mqsDefinationAction,
    mqsAddQuestionAction,
    getQuestionDetail,
    updateQuestionDetail,
    setSliedQuestionView,
    getQuestionListByOptions,
} from "../../store/Action/MqsAction";
import { bindActionCreators } from "redux";
import ContextProvider from "../../components/Context/ContextProvider";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "../../router/RouteList";
import TableComponent from "../../components/Table/TableComponent";
import MqsResponseDialog from "../../components/DialogsBox/MqsResponseDialog";
import CustomSwitch from "../../components/CustomSwitch";
import ExpandAllButton from "../../components/ExpandAllButton";
import LoadingComponent from "../../components/Loading/LoadingComponent";
import DialogComponent from "../../components/DialogsBox/DialogComponent";
import AccordionCustom from "../../components/AccordionCustom";

const AddEditQuestion = (props) => {
    const navigate = useNavigate();
    const { id } = useParams();
    const location = useLocation();
    
    const textBoxSx = {
        height: "122px",
        paddingTop: "22px",
        backgroundColor: "rgba(255, 255, 255, 0.8) !important",
        overflow: "auto",
    };
    const tooTipIconSx = {
        marginLeft: "10px",
        position: "relative",
        top: "2px",
    };
    const {
        userLoggedIn,
        mqs_trigger_code,
        mqsDefinationAction,
        definations,
        jurisdiction,
        circuits,
        mqsAddQuestionAction,
        getQuestionDetail,
        selectedQuestion,
        updateQuestionDetail,
        question_categories,
        test_type,
        mqs_options,
        setSliedQuestionView,
        testQuestion,
        getQuestionListByOptions,
        commondropdown
    } = props;
    const btnText = id !== undefined ? "Update" : userLoggedIn?.language_data?.comman?.save_btn ||  "Save";
    useEffect(() => {
        if (location?.state?.mode === "view") {
            setQuestion((previous) => {
                return {
                    ...previous,
                    isViewMode: true,
                };
            });
        } else {
            setQuestion((previous) => {
                return {
                    ...previous,
                    isCategoryReadOnly: location?.state?.id ? true : false,
                    isViewMode: false,
                };
            });
        }
        mqsDefinationAction();
        if (id !== undefined) {
            getQuestionDetail(id);
        }
        if (location?.state?.optionID) {
            getQuestionListByOptions(location?.state?.optionID);
        }
    }, []);

    const headerList = [
        {
            name: "Collar Color",
            headerClass: "tableHeading",
            accessibleKey: "sequence",
            rowClass: "typo4",
            headerAlign: "left",
            rowAlign: "left",
        },
        {
            name: "Question Weight",
            headerClass: "tableHeading",
            accessibleKey: "sequence",
            rowClass: "typo4",
            headerAlign: "center",
            rowAlign: "left",
        },
        {
            name: "ATA",
            headerClass: "tableHeading",
            accessibleKey: "sequence",
            rowClass: "typo4",
            headerAlign: "center",
            rowAlign: "left",
        },
        {
            name: "CBHA",
            headerClass: "tableHeading",
            accessibleKey: "sequence",
            rowClass: "typo4",
            headerAlign: "center",
            rowAlign: "left",
        },
        {
            name: "RCA",
            headerClass: "tableHeading",
            accessibleKey: "sequence",
            rowClass: "typo4",
            headerAlign: "center",
            rowAlign: "left",
        },
    ];

    const [setting, setSetting] = useState({
        expand: true,
    });

    const [questions, setQuestion] = useState({
        allSelect: false,
        allSelectCode: false,
        selectJuridicationAndCircuits: false,
        selectAllReccurence: false,
        selectAternativeContentResponses: false,
        isLoading: false,
        selectCircuits: false,
        showRemediation: true,
        isCategoryReadOnly: false,
        isViewMode: false,
        openMqsResponse: false,
        isKoWarning: false,
        collar_color: [],
        recurrence_probability_ata: '',
        recurrence_probability_cbha: '',
        recurrence_probability_rcp: '',
        weightage: '',

        recurrenceProbability: [
            {
                isChecked: false,
                collar: 5,
                label: "Blue",
                weightage: 1,
                rcp_probability: 1,
                ata_probability: 1,
                cbha_probability: 1,
            },
            {
                isChecked: false,
                collar: 2,
                label: "White",
                weightage: 1,
                rcp_probability: 1,
                ata_probability: 1,
                cbha_probability: 1,
            },
            {
                isChecked: false,
                collar: 3,
                label: "All Other Collars",
                weightage: 1,
                rcp_probability: 1,
                ata_probability: 1,
                cbha_probability: 1,
            },
        ],
        expanded: {
            expand: true,
        },
        formData: {
            pre_question: 0,
            trigger_code: [],
            jurisdiction: [],
            circuits: [],
            category_id: null,
            unique_ques_family_code: null,
            weightage: '',
            question: null,
            explanation: null,
            remediation_info: null,
            second_level_remediation_info: null,
            key_compliance_lesson: null,
            available_response: null,
            responses_for_remediation: null,
            ques_in_onboarding: null,
            ques_in_ongoing: null,
            ques_in_offboarding: null,
            ques_apply_on_hp_sr: null,
            us_specific_ques: null,
            dependent_ques: null,
            contingent_ques: null,
            responses_for_activate_dq: null,
            override_ques: null,
            responses_for_override_ques: null,
            overrided_questions: null,
            ques_apply_to_cb: null,
            recurrence_probability_ata: null,
            recurrence_probability_cbha: null,
            recurrence_probability_rcp: null,
            all_tc_apply: null,
            for_contractor: null,
            dq_ranking: null,
            contingent_ques_master: null,
            examples: null,
            collar: [],
            best_response: null,
            score: null,
            ko_question: null,
            ko_ques_content: null,
            responses_trigger_ko_status: null,
            apply_reservation_right_content: null,
            responses_trigger_reservation_right_content: null,
            reservation_right_content: null,
            responses_of_rrc: null,
            apply_alternative_response_content: null,
            alternative_response_content: null,
            alternative_content_responses: [],
            repression_question_responses: null,
            test_type_id: null,
            for_sub_contractor: null,
            for_sub_contacting_business: null,
            for_manager: null,
            for_service_recipient: null,
            for_hiring_party: null,
            for_indirect_contractor: null,
        },
        questionIndex: 0,
    });

    useEffect(() => {
        if (selectedQuestion && id !== undefined) {
            const selectQues = { ...selectedQuestion };
            const recurrence = selectQues?.collar_recurrence
                ? [...selectQues?.collar_recurrence]
                : [];
            const recurrenceProbability = questions?.recurrenceProbability?.map(
                (item) => {
                    const collarIndex = recurrence?.findIndex(
                        (element) => item?.collar === element?.collar
                    );
                    if (collarIndex !== -1) {
                        return recurrence[collarIndex];
                    } else {
                        return item;
                    }
                }
            );
            let index = 1;
            if (testQuestion && testQuestion?.length > 0) {
                index = testQuestion?.findIndex(
                    (obj) => obj === selectedQuestion?.id
                );
                setQuestion((previous) => {
                    return {
                        ...previous,
                        questionIndex: index,
                    };
                });
            }
            setQuestion((previous) => {
                return {
                    ...previous,
                    recurrenceProbability: recurrenceProbability,
                    formData: selectQues,
                };
            });
        }
    }, [id, selectedQuestion, questions?.questionIndex, questions.isLoading]);

    const handleFormChange = (event) => {
        setQuestion((previous) => {
            const formData = previous.formData;
            if (event.target.name === "pre_question") {
                formData[event.target.name] =
                    event.target.value === "1" ? 1 : 0;
            } else {
                formData[event.target.name] = event.target.value;
            }
            return {
                ...previous,
                formData: formData,
                isKoWarning:
                    event.target.name === "ko_question"
                        ? event.target.value == 1
                            ? true
                            : false
                        : false,
            };
        });
    };
    const handleReccurence = (event) => {
        if (event?.target?.name == 'recurrence_probability_ata') {
            setQuestion((previous) => {
                return {
                    ...previous,
                    formData: {
                        ...previous.formData,
                        recurrence_probability_ata: event.target.value
                    }
                   
                }

            })
        } else if (event?.target?.name == 'recurrence_probability_cbha') {
            setQuestion((previous) => {
                return {
                    ...previous,
                    formData: {
                        ...previous.formData,
                        recurrence_probability_cbha: event.target.value
                    }
                    
                }

            })
        } else if (event?.target?.name == 'recurrence_probability_rcp') {

            setQuestion((previous) => {
                return {
                    ...previous,
                    formData: {
                        ...previous.formData,
                        recurrence_probability_rcp: event.target.value
                    }
                   
                }

            })
        } else if (event?.target?.name == 'weightage') {
            console.log(event.target)
            setQuestion((previous) => {
                return {
                    ...previous,
                   formData: {
                    ...previous.formData,
                    weightage : event.target.value
                   }
                    
                }

            })
        } else if (event?.target?.name == 'collarcolor') {
            const {
                target: { value },
            } = event;
            if (event?.target?.value?.find((item) => item === 'select_all')) {
                handleAllReccurence();
            } else {
                setQuestion((previous) => {
                    return {
                        ...previous,
                        formData : {
                            ...previous.formData,
                            collar: value,
                        }
                      
                    };
                });
            };

        }


    }
    const koDialogContent = (
        <Typography className="font_16_600">
            You are setting a question as a Knock-Over Question. That means that
            if the test taker fails this question, it will cause the entire
            compliance test to be marked as a Fail irrespective of the
            underlying score. For example, if a passing score on a test is 75%
            and the test taker scores a 90%, but fails a knock-over question,
            then that test is marked as a Fail.
        </Typography>
    );

    const handleCollarAndRecurrence = (event, item, index) => {
        let recurrence = questions.formData.collar_recurrence
            ? [...questions.formData.collar_recurrence]
            : [];
        const recurrenceItem = [...questions.recurrenceProbability];
        if (event.target.name === "collar") {
            recurrenceItem[index]["isChecked"] = event.target.checked;
        } else {
            recurrenceItem[index][event.target.name] = parseFloat(
                event.target.value
            );
        }
        if (recurrence.length > 0) {
            if (event.target.name === "collar") {
                if (event.target.checked) {
                    const itemIndex = recurrence.findIndex(
                        (element) => element?.collar === item?.collar
                    );
                    if (itemIndex === -1) {
                        recurrence.push(item);
                    }
                } else {
                    recurrence = recurrence.filter(
                        (element) => element?.collar !== item?.collar
                    );
                }
            } else {
                const itemIndex = recurrence.findIndex(
                    (element) => element?.collar === item?.collar
                );
                recurrence[itemIndex][event.target.name] = parseFloat(
                    event.target.value
                );
            }
        } else {
            if (event.target.checked) {
                recurrence.push(item);
            }
        }
        setQuestion((previous) => {
            return {
                ...previous,
                recurrenceProbability: recurrenceItem,
                formData: {
                    ...previous.formData,
                    collar_recurrence: recurrence,
                },
            };
        });
    };

    const handleCodeChange = (event) => {
        const {
            target: { value },
        } = event;
        if (event?.target?.value?.find((item) => item === 'select_all')) {
            handleSelectAllCodes();
        } else {
            setQuestion((previous) => {
                return {
                    ...previous,
                    allSelectCode:
                        mqs_trigger_code?.length === value?.length ? true : false,
                    formData: {
                        ...previous.formData,
                        trigger_code: [...value],
                    },
                };
            });
        }
    };

    const handleAlternativeContentResponsesChange = (event) => {
        const {
            target: { value },
        } = event;
        if (event?.target?.value?.find((item) => item === 'select_all')) {
            handleSelectAllAlternativeContentResponses();
        } else {
            setQuestion((previous) => {
                return {
                    ...previous,
                    selectAternativeContentResponses:
                        alternativeContentResponses?.length === value?.length
                            ? true
                            : false,
                    formData: {
                        ...previous.formData,
                        alternative_content_responses: [...value],
                    },
                };
            });
        }
    };

    const handleRenderValue = (selected, values) => {
        return (
            <Box
                sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 0.5,
                    height: 40,
                    overflow: "auto",
                    padding: "4px",
                }}
            >
                {selected?.map((selected_value) => {
                    const value = values?.find(
                        (item) => item?.id === selected_value
                    );
                    return <Chip key={selected_value} label={value?.label} />;
                })}
            </Box>
        );
    };

    const handleSelectAllCodes = () => {
        if (!questions.allSelectCode) {
            const trigger_code = mqs_trigger_code?.map((item) => item?.id);
            setQuestion((previous) => {
                return {
                    ...previous,
                    formData: {
                        ...previous.formData,
                        trigger_code: trigger_code,
                    },
                    allSelectCode: !previous.allSelectCode,
                };
            });
        } else {
            setQuestion((previous) => {
                return {
                    ...previous,
                    formData: {
                        ...previous.formData,
                        trigger_code: [],
                    },
                    allSelectCode: !previous.allSelectCode,
                };
            });
        }
    };

    const handleSelectAllAlternativeContentResponses = () => {
        if (!questions.selectAternativeContentResponses) {
            const responses = alternativeContentResponses?.map(
                (item) => item?.id
            );
            setQuestion((previous) => {
                return {
                    ...previous,
                    formData: {
                        ...previous.formData,
                        alternative_content_responses: responses,
                    },
                    selectAternativeContentResponses:
                        !previous.selectAternativeContentResponses,
                };
            });
        } else {
            setQuestion((previous) => {
                return {
                    ...previous,
                    formData: {
                        ...previous.formData,
                        alternative_content_responses: [],
                    },
                    selectAternativeContentResponses:
                        !previous.selectAternativeContentResponses,
                };
            });
        }
    };

    const handleSelectJuridacationAndCircuits = () => {
        let checked = questions?.selectJuridicationAndCircuits;
        const allJurisdiction = !checked
            ? jurisdiction?.map((item) => item?.id)
            : [];
        const allCircuits = !checked ? circuits?.map((item) => item?.id) : [];
        setQuestion((previous) => {
            return {
                ...previous,
                formData: {
                    ...previous.formData,
                    jurisdiction: allJurisdiction,
                    circuits: allCircuits,
                },
                selectJuridicationAndCircuits: !checked,
            };
        });
    };

    const handleAllReccurence = () => {

        let checked = questions?.selectAllReccurence;
        const allCollor_color = !checked
            ? commondropdown?.collars?.map((item) => item?.id)
            : [];
        setQuestion((previous) => {
            return {
                ...previous,
                formData : {
                    ...previous.formData,
                    collar: allCollor_color
                }
               
                ,
                selectAllReccurence: !checked,
            };
        });
    };

    const handleJuridacationChange = (event) => {
        const {
            target: { value },
        } = event;
        if (event?.target?.value?.find((item) => item === 'select_all')) {
            handleSelectJuridacationAndCircuits();
        } else {
            setQuestion((previous) => {
                return {
                    ...previous,
                    selectJuridicationAndCircuits: jurisdiction?.length === value?.length ? true : false,
                    formData: {
                        ...previous.formData,
                        jurisdiction: value,
                    },
                };
            });
        };
    }

    const handleCircuitsChange = (event) => {
        const {
            target: { value },
        } = event;
        if (event?.target?.value?.find((item) => item === 'select_all')) {
            handleSelectJuridacationAndCircuits();
        } else {
            const cuicuit_ids = value;
            const jurisdictions = jurisdiction?.filter((item) =>
                cuicuit_ids?.find((element) => element === item?.circuit_id)
            );

            setQuestion((previous) => {
                return {
                    ...previous,
                    selectJuridicationAndCircuits: circuits?.length === cuicuit_ids?.length ? true : false,
                    formData: {
                        ...previous.formData,
                        jurisdiction: jurisdictions.map((item) => item?.id),
                        circuits: cuicuit_ids
                    },
                };
            });
        };
    }

    const handleShowHideRemediation = (event) => {
        setQuestion((previous) => {
            return {
                ...previous,
                showRemediation: event.target.checked,
            };
        });
    };

    const handleFormSubmit = async (event) => {
        let result;
        if (id !== undefined) {
            result = await updateQuestionDetail(id, questions.formData);
        } else {
            result = await mqsAddQuestionAction(questions.formData);
        }
        if (result) {
            setTimeout(() => {
                navigate(-1);
            }, 2000);
        }
    };

    const handleTableDataState = (key, data) => {
        setQuestion((previous) => {
            const formData = previous.formData;
            formData[key] = data;
            return {
                ...previous,
                formData: formData,
            };
        });
    };

    const handleOnClick = (responseData) => {
        setQuestion((privious) => {
            return {
                ...privious,
                openMqsResponse: false,
                formData: {
                    ...privious.formData,
                    available_response: responseData,
                },
            };
        });
    };

    const handlePreviousClick = async () => {
        setQuestion((prevState) => ({ ...prevState, isLoading: true }));
        await setSliedQuestionView(questions?.formData?.id, "Previous");
        setQuestion((prevState) => ({ ...prevState, isLoading: false }));
        navigate(
            ROUTES.ADD_QUESTION +
            "/" +
            testQuestion[questions.questionIndex - 1],
            {
                state: {
                    questionID: testQuestion[questions.questionIndex - 1],
                    optionID: location?.state?.optionID,
                },
            }
        );
    };

    const handleNextClick = async () => {
        setQuestion((prevState) => ({ ...prevState, isLoading: true }));
        await setSliedQuestionView(questions?.formData?.id, "Next");
        setQuestion((prevState) => ({ ...prevState, isLoading: false }));
        navigate(
            ROUTES.ADD_QUESTION +
            "/" +
            testQuestion[questions.questionIndex + 1],
            {
                state: {
                    questionID: testQuestion[questions.questionIndex + 1],
                    optionID: location?.state?.optionID,
                },
            }
        );
    };

    const handleKoClose = () => {
        setQuestion((previous) => {
            return {
                ...previous,
                isKoWarning: false,
                formData: {
                    ...previous.formData,
                    ko_question: 0,
                },
            };
        });
    };

    const actions = (
        <Box padding={0.5}>
            <ButtonComponent
                title={"Ok"}
                onClick={() => {
                    setQuestion((previous) => {
                        return { ...previous, isKoWarning: false };
                    });
                }}
                sx={{ marginRight: 1 }}
            />
            <ButtonComponent
                title={"Cancel"}
                variant={"outlined"}
                onClick={handleKoClose}
                sx={{ marginRight: 2 }}
            />
        </Box>
    );

    return (
        <Fragment>
            <Navbar />
            <MqsResponseDialog
                options={mqs_options}
                open={questions?.openMqsResponse}
                handleClose={() => {
                    setQuestion((previous) => {
                        return { ...previous, openMqsResponse: false };
                    });
                }}
                handleOnClick={handleOnClick}
            />
            <DialogComponent
                open={questions?.isKoWarning}
                content={koDialogContent}
                title="Warning"
                action={actions}
                sxContent={{
                    textAlign: "left",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
                className="dialogBoxMain"
                handleClose={handleKoClose}
            />
            {questions?.isLoading ? (
                <LoadingComponent sx={{ height: "600px" }} />
            ) : (
                <Box sx={{ px: { md: 10, xs: 1 } }} className="headerBox">
                    {location?.state?.optionID ? (
                        <Grid
                            container
                            display={"flex"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                        >
                            <Grid
                                item
                                sx={{
                                    display:
                                        questions?.questionIndex === 0
                                            ? "none"
                                            : "block",
                                }}
                            >
                                <ButtonComponent
                                    title="Previous"
                                    variant="outlined"
                                    onClick={handlePreviousClick}
                                    disabled={
                                        questions?.questionIndex === 0
                                            ? true
                                            : false
                                    }
                                />
                            </Grid>
                            <Grid item>
                                <Typography className="font_18_600">
                                    Showing {questions?.questionIndex + 1} of{" "}
                                    {testQuestion?.length} Question
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                sx={{
                                    display:
                                        questions?.questionIndex + 1 ===
                                            testQuestion?.length
                                            ? "none"
                                            : "block",
                                }}
                            >
                                <ButtonComponent
                                    title="Next"
                                    onClick={handleNextClick}
                                    disabled={
                                        questions?.questionIndex + 1 ===
                                            testQuestion?.length
                                            ? true
                                            : false
                                    }
                                />
                            </Grid>
                        </Grid>
                    ) : null}

                    <Grid container className="headerGrid" my={3}>
                        <Grid item lg={6} md={6} sm={6}>
                            <Typography
                                className="font_24_600"
                                letterSpacing={1}
                            >
                                Add New Question
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container my={2} spacing={1}>
                        <Grid item lg={9} md={9} sm={9}>
                            <Grid container>
                                <Grid item lg={12} md={12} sm={12}>
                                    <Typography className="font_18_600">
                                        Is Pre Test Question ?
                                    </Typography>
                                </Grid>
                                <Grid item lg={12} md={12} sm={12}>
                                    <RadioButtonGroup
                                        options={mqsYesNoOptions}
                                        row={true}
                                        defaultValue={0}
                                        value={
                                            questions?.formData?.pre_question
                                        }
                                        disabled={questions?.isViewMode}
                                        name="pre_question"
                                        onChange={handleFormChange}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item lg={3} md={3} sm={3} textAlign="end">
                            {!questions?.formData?.pre_question && (
                                <FormControlLabel
                                    disabled={questions?.isViewMode}
                                    control={
                                        <CustomSwitch
                                            checked={questions.showRemediation}
                                            onChange={handleShowHideRemediation}
                                            color="primary"
                                        />
                                    }
                                    labelPlacement="start"
                                    label="Remediation"
                                    className="switchControl"
                                />
                            )}
                        </Grid>
                    </Grid>
                    <Grid container my={2} spacing={2}>
                        <Grid item lg={12} md={12} sm={12}>
                            <ExpandAllButton
                                setState={setSetting}
                                expand={setting.expand}
                            />
                        </Grid>
                    </Grid>
                    <ContextProvider.Provider value={setting.expand}>
                        <Grid container my={2} spacing={1}>
                            <Grid item lg={12} md={12} sm={12}>
                                <AccordionCustom title="Questions">
                                    <Grid container spacing={2}>
                                        <Grid item lg={12} md={12} sm={12}>
                                            <Typography className="font_18_600">
                                                Question
                                                <Tooltip
                                                    arrow={true}
                                                    title={
                                                        definations?.question
                                                    }
                                                    placement="right"
                                                >
                                                    <Icon sx={tooTipIconSx}>
                                                        <InfoIcon />
                                                    </Icon>
                                                </Tooltip>
                                            </Typography>
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12}>
                                            <TextFieldComponent
                                                id="addEditQuestion"
                                                InputProps={{
                                                    readOnly:
                                                        questions?.isViewMode,
                                                }}
                                                label="Question"
                                                multiline={true}
                                                sx={textBoxSx}
                                                fullWidth={true}
                                                readOnly={questions?.isViewMode}
                                                name="question"
                                                value={
                                                    questions?.formData
                                                        ?.question
                                                }
                                                onChange={handleFormChange}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} my={1}>
                                        <Grid item lg={12} md={12} sm={12}>
                                            <Typography className="font_18_600">
                                                Description
                                                <Tooltip
                                                    arrow={true}
                                                    title={
                                                        definations?.explanation
                                                    }
                                                    placement="right"
                                                >
                                                    <Icon sx={tooTipIconSx}>
                                                        <InfoIcon />
                                                    </Icon>
                                                </Tooltip>
                                            </Typography>
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12}>
                                            <TextFieldComponent
                                                id="addEditQuestionDescription"
                                                InputProps={{
                                                    readOnly:
                                                        questions?.isViewMode,
                                                }}
                                                label="Question Description"
                                                sx={textBoxSx}
                                                multiline={true}
                                                fullWidth={true}
                                                readOnly={questions?.isViewMode}
                                                name="explanation"
                                                value={
                                                    questions?.formData
                                                        ?.explanation
                                                }
                                                onChange={handleFormChange}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} my={1}>
                                        <Grid item lg={12} md={12} sm={12}>
                                            <Typography className="font_18_600">
                                                {userLoggedIn?.language_data?.documentdatabase?.update_success || "Examples"}
                                                <Tooltip
                                                    arrow={true}
                                                    title={
                                                        definations?.examples
                                                    }
                                                    placement="right"
                                                >
                                                    <Icon sx={tooTipIconSx}>
                                                        <InfoIcon />
                                                    </Icon>
                                                </Tooltip>
                                            </Typography>
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12}>
                                            <TextFieldComponent
                                                id="addEditQuestionExample"
                                                InputProps={{
                                                    readOnly:
                                                        questions?.isViewMode,
                                                }}
                                                label="Example"
                                                sx={textBoxSx}
                                                multiline={true}
                                                fullWidth={true}
                                                readOnly={questions?.isViewMode}
                                                name="examples"
                                                value={
                                                    questions?.formData
                                                        ?.examples
                                                }
                                                onChange={handleFormChange}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} my={1}>
                                        <Grid item lg={12} md={12} sm={12}>
                                            <Typography className="font_18_600">
                                                Key Compliance Lesson
                                                <Tooltip
                                                    arrow={true}
                                                    title={
                                                        definations?.key_compliance_lesson
                                                    }
                                                    placement="right"
                                                >
                                                    <Icon sx={tooTipIconSx}>
                                                        <InfoIcon />
                                                    </Icon>
                                                </Tooltip>
                                            </Typography>
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12}>
                                            <TextFieldComponent
                                                id="addEditQuestionKeyCompliance"
                                                InputProps={{
                                                    readOnly:
                                                        questions?.isViewMode,
                                                }}
                                                label="Key Compliance"
                                                sx={textBoxSx}
                                                multiline={true}
                                                fullWidth={true}
                                                readOnly={questions?.isViewMode}
                                                name="key_compliance_lesson"
                                                value={
                                                    questions?.formData
                                                        ?.key_compliance_lesson
                                                }
                                                onChange={handleFormChange}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} my={1}>
                                        <Grid item lg={12} md={12} sm={12}>
                                            <Typography className="font_18_600">
                                                Range of Available Responses &
                                                Scoring
                                                <Tooltip
                                                    arrow={true}
                                                    title={
                                                        definations?.available_response
                                                    }
                                                    placement="right"
                                                >
                                                    <Icon sx={tooTipIconSx}>
                                                        <InfoIcon />
                                                    </Icon>
                                                </Tooltip>
                                            </Typography>
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12}>
                                            <QuestionScoreTableComponent
                                                tableData={
                                                    questions?.formData
                                                        ?.available_response ||
                                                    []
                                                }
                                                handleTableDataState={
                                                    handleTableDataState
                                                }
                                                readOnly={questions?.isViewMode}
                                                name="available_response"
                                                showResponseDialog={() => {
                                                    setQuestion((privious) => {
                                                        return {
                                                            ...privious,
                                                            openMqsResponse: true,
                                                        };
                                                    });
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} my={1}>
                                        <Grid item lg={6} md={6} sm={12}>
                                            <Grid container spacing={2}>
                                                <Grid
                                                    item
                                                    lg={12}
                                                    md={12}
                                                    sm={12}
                                                >
                                                    <Typography className="font_18_600">
                                                        {userLoggedIn?.language_data?.customer?.jurisdiction || "Jurisdiction"}
                                                        <Tooltip
                                                            arrow={true}
                                                            title={userLoggedIn?.language_data?.customer?.jurisdiction || "Jurisdiction"}
                                                            placement="right"
                                                        >
                                                            <Icon
                                                                sx={
                                                                    tooTipIconSx
                                                                }
                                                            >
                                                                <InfoIcon />
                                                            </Icon>
                                                        </Tooltip>
                                                    </Typography>
                                                </Grid>
                                                <Grid
                                                    item
                                                    lg={10}
                                                    md={10}
                                                    sm={12}
                                                >
                                                    <MultiSelectDropDown
                                                        value={
                                                            Array.isArray(
                                                                questions
                                                                    ?.formData
                                                                    ?.jurisdiction
                                                            )
                                                                ? questions
                                                                    ?.formData
                                                                    ?.jurisdiction
                                                                : []
                                                        }
                                                        title={userLoggedIn?.language_data?.customer?.jurisdiction || "Jurisdiction"}
                                                        onChange={
                                                            handleJuridacationChange
                                                        }
                                                        readOnly={
                                                            questions?.isViewMode
                                                        }
                                                        name="jurisdiction"
                                                        renderValue={(
                                                            selected
                                                        ) => {
                                                            return handleRenderValue(
                                                                selected,
                                                                jurisdiction
                                                            );
                                                        }}
                                                    >
                                                        {jurisdiction?.length >
                                                            0 && (
                                                                <MenuItem
                                                                    value="select_all"
                                                                >
                                                                    <Checkbox
                                                                        checked={
                                                                            questions?.selectJuridicationAndCircuits
                                                                        }
                                                                    />
                                                                    <ListItemText primary="Select All" />
                                                                </MenuItem>
                                                            )}

                                                        {jurisdiction?.map(
                                                            (code) => (
                                                                <MenuItem
                                                                    key={
                                                                        code?.id
                                                                    }
                                                                    value={
                                                                        code?.id
                                                                    }
                                                                >
                                                                    <Checkbox
                                                                        checked={
                                                                            questions?.formData?.jurisdiction?.indexOf(
                                                                                code?.id
                                                                            ) >
                                                                            -1
                                                                        }
                                                                    />
                                                                    <ListItemText
                                                                        primary={
                                                                            code?.label
                                                                        }
                                                                    />
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </MultiSelectDropDown>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={12}>
                                            <Grid container spacing={2}>
                                                <Grid
                                                    item
                                                    lg={12}
                                                    md={12}
                                                    sm={12}
                                                >
                                                    <Typography className="font_18_600">
                                                        Circuits
                                                        <Tooltip
                                                            arrow={true}
                                                            title="Circuits"
                                                            placement="right"
                                                        >
                                                            <Icon
                                                                sx={
                                                                    tooTipIconSx
                                                                }
                                                            >
                                                                <InfoIcon />
                                                            </Icon>
                                                        </Tooltip>
                                                    </Typography>
                                                </Grid>
                                                <Grid
                                                    item
                                                    lg={10}
                                                    md={10}
                                                    sm={12}
                                                >
                                                    <MultiSelectDropDown
                                                        value={
                                                            Array.isArray(
                                                                questions
                                                                    ?.formData
                                                                    ?.circuits
                                                            )
                                                                ? questions
                                                                    ?.formData
                                                                    ?.circuits
                                                                : []
                                                        }
                                                        title="Circuits"
                                                        readOnly={
                                                            questions?.isViewMode
                                                        }
                                                        name="circuits"
                                                        onChange={
                                                            handleCircuitsChange
                                                        }
                                                        renderValue={(
                                                            selected
                                                        ) => {
                                                            if (selected) {
                                                                return handleRenderValue(
                                                                    selected,
                                                                    circuits
                                                                );
                                                            }
                                                        }}
                                                    >
                                                        {circuits?.length >
                                                            0 && (
                                                                <MenuItem
                                                                    value="select_all"
                                                                >
                                                                    <Checkbox
                                                                        checked={
                                                                            questions?.selectJuridicationAndCircuits
                                                                        }
                                                                    />
                                                                    <ListItemText primary="Select All" />
                                                                </MenuItem>
                                                            )}

                                                        {circuits?.map(
                                                            (code) => (
                                                                <MenuItem
                                                                    key={
                                                                        code?.id
                                                                    }
                                                                    value={
                                                                        code?.id
                                                                    }
                                                                >
                                                                    <Checkbox
                                                                        checked={
                                                                            questions?.formData?.circuits?.indexOf(
                                                                                code?.id
                                                                            ) >
                                                                            -1
                                                                        }
                                                                    />
                                                                    <ListItemText
                                                                        primary={
                                                                            code?.label
                                                                        }
                                                                    />
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </MultiSelectDropDown>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </AccordionCustom>
                            </Grid>
                        </Grid>
                        {questions.showRemediation &&
                            !questions?.formData?.pre_question && (
                                <Grid container my={2} spacing={1}>
                                    <Grid item lg={12} md={12} sm={12}>
                                        <AccordionCustom title="Remediation">
                                            <Grid container spacing={2}>
                                                <Grid
                                                    item
                                                    lg={12}
                                                    md={12}
                                                    sm={12}
                                                >
                                                    <Typography className="font_18_600">
                                                        Remediation Information
                                                        <Tooltip
                                                            arrow={true}
                                                            title={
                                                                definations?.remediation_info
                                                            }
                                                            placement="right"
                                                        >
                                                            <Icon
                                                                sx={
                                                                    tooTipIconSx
                                                                }
                                                            >
                                                                <InfoIcon />
                                                            </Icon>
                                                        </Tooltip>
                                                    </Typography>
                                                </Grid>
                                                <Grid
                                                    item
                                                    lg={12}
                                                    md={12}
                                                    sm={12}
                                                >
                                                    <TextFieldComponent
                                                        id="addEditQuestionRemediation"
                                                        InputProps={{
                                                            readOnly:
                                                                questions?.isViewMode,
                                                        }}
                                                        label="Remediation"
                                                        sx={textBoxSx}
                                                        multiline={true}
                                                        fullWidth={true}
                                                        readOnly={
                                                            questions?.isViewMode
                                                        }
                                                        name="remediation_info"
                                                        onChange={
                                                            handleFormChange
                                                        }
                                                        value={
                                                            questions?.formData
                                                                ?.remediation_info
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2} my={1}>
                                                <Grid
                                                    item
                                                    lg={12}
                                                    md={12}
                                                    sm={12}
                                                >
                                                    <Typography className="font_18_600">
                                                        Second Level Remediation
                                                        Information
                                                        <Tooltip
                                                            arrow={true}
                                                            title={
                                                                definations?.second_level_remediation_info
                                                            }
                                                            placement="right"
                                                        >
                                                            <Icon
                                                                sx={
                                                                    tooTipIconSx
                                                                }
                                                            >
                                                                <InfoIcon />
                                                            </Icon>
                                                        </Tooltip>
                                                    </Typography>
                                                </Grid>
                                                <Grid
                                                    item
                                                    lg={12}
                                                    md={12}
                                                    sm={12}
                                                >
                                                    <TextFieldComponent
                                                        id="addEditQuestionSecLevRemediation"
                                                        InputProps={{
                                                            readOnly:
                                                                questions?.isViewMode,
                                                        }}
                                                        label="Second Level Remediation"
                                                        sx={textBoxSx}
                                                        multiline={true}
                                                        fullWidth={true}
                                                        readOnly={
                                                            questions?.isViewMode
                                                        }
                                                        name="second_level_remediation_info"
                                                        onChange={
                                                            handleFormChange
                                                        }
                                                        value={
                                                            questions?.formData
                                                                ?.second_level_remediation_info
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2} my={1}>
                                                <Grid
                                                    item
                                                    lg={12}
                                                    md={12}
                                                    sm={12}
                                                >
                                                    <Typography className="font_18_600">
                                                        Responses that Trigger
                                                        Remediation
                                                        <Tooltip
                                                            arrow={true}
                                                            title={
                                                                definations?.responses_for_remediation
                                                            }
                                                            placement="right"
                                                        >
                                                            <Icon
                                                                sx={
                                                                    tooTipIconSx
                                                                }
                                                            >
                                                                <InfoIcon />
                                                            </Icon>
                                                        </Tooltip>
                                                    </Typography>
                                                </Grid>
                                                <Grid
                                                    item
                                                    lg={12}
                                                    md={12}
                                                    sm={12}
                                                >
                                                    <QuestionScoreTableComponent
                                                        tableData={
                                                            questions?.formData
                                                                ?.responses_for_remediation ||
                                                            []
                                                        }
                                                        handleTableDataState={
                                                            handleTableDataState
                                                        }
                                                        readOnly={
                                                            questions?.isViewMode
                                                        }
                                                        responses={
                                                            questions?.formData
                                                                ?.available_response ||
                                                            []
                                                        }
                                                        name="responses_for_remediation"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </AccordionCustom>
                                    </Grid>
                                </Grid>
                            )}

                        {!questions?.formData?.pre_question && (
                            <Fragment>
                                <Grid container spacing={2} my={1}>
                                    <Grid item lg={12} md={12} sm={12}>
                                        <AccordionCustom title="Onboarding / Offboarding phase">
                                            <Grid container spacing={2}>
                                                <Grid item lg={6} md={6} sm={6}>
                                                    <Grid container>
                                                        <Grid
                                                            item
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                        >
                                                            <Typography className="font_18_600">
                                                                Question
                                                                Available in
                                                                Onboarding
                                                                Phase?
                                                                <Tooltip
                                                                    arrow={true}
                                                                    title={
                                                                        definations?.ques_in_onboarding
                                                                    }
                                                                    placement="right"
                                                                >
                                                                    <Icon
                                                                        sx={
                                                                            tooTipIconSx
                                                                        }
                                                                    >
                                                                        <InfoIcon />
                                                                    </Icon>
                                                                </Tooltip>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                        >
                                                            <RadioButtonGroup
                                                                options={
                                                                    mqsYesNoOptions
                                                                }
                                                                row={true}
                                                                disabled={
                                                                    questions?.isViewMode
                                                                }
                                                                name="ques_in_onboarding"
                                                                onChange={
                                                                    handleFormChange
                                                                }
                                                                value={
                                                                    questions
                                                                        ?.formData
                                                                        ?.ques_in_onboarding
                                                                }
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item lg={6} md={6} sm={6}>
                                                    <Grid container>
                                                        <Grid
                                                            item
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                        >
                                                            <Typography className="font_18_600">
                                                                Question
                                                                Available in
                                                                Offboarding
                                                                Phase?
                                                                <Tooltip
                                                                    arrow={true}
                                                                    title={
                                                                        definations?.ques_in_offboarding
                                                                    }
                                                                    placement="right"
                                                                >
                                                                    <Icon
                                                                        sx={
                                                                            tooTipIconSx
                                                                        }
                                                                    >
                                                                        <InfoIcon />
                                                                    </Icon>
                                                                </Tooltip>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                        >
                                                            <RadioButtonGroup
                                                                options={
                                                                    mqsYesNoOptions
                                                                }
                                                                row={true}
                                                                disabled={
                                                                    questions?.isViewMode
                                                                }
                                                                name="ques_in_offboarding"
                                                                onChange={
                                                                    handleFormChange
                                                                }
                                                                value={
                                                                    questions
                                                                        ?.formData
                                                                        ?.ques_in_offboarding
                                                                }
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item lg={6} md={6} sm={6}>
                                                    <Grid container>
                                                        <Grid
                                                            item
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                        >
                                                            <Typography className="font_18_600">
                                                                Question
                                                                available in
                                                                Ongoing Phase /
                                                                Retest Phase?
                                                                <Tooltip
                                                                    arrow={true}
                                                                    title={
                                                                        definations?.ques_in_ongoing
                                                                    }
                                                                    placement="right"
                                                                >
                                                                    <Icon
                                                                        sx={
                                                                            tooTipIconSx
                                                                        }
                                                                    >
                                                                        <InfoIcon />
                                                                    </Icon>
                                                                </Tooltip>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                        >
                                                            <RadioButtonGroup
                                                                options={
                                                                    mqsYesNoOptions
                                                                }
                                                                row={true}
                                                                disabled={
                                                                    questions?.isViewMode
                                                                }
                                                                name="ques_in_ongoing"
                                                                onChange={
                                                                    handleFormChange
                                                                }
                                                                value={
                                                                    questions
                                                                        ?.formData
                                                                        ?.ques_in_ongoing
                                                                }
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item lg={6} md={6} sm={6}>
                                                    <Grid container>
                                                        <Grid
                                                            item
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                        >
                                                            <Typography className="font_18_600">
                                                                Question
                                                                applicable to
                                                                Contractor?
                                                                <Tooltip
                                                                    arrow={true}
                                                                    title={
                                                                        definations?.for_contractor
                                                                    }
                                                                    placement="right"
                                                                >
                                                                    <Icon
                                                                        sx={
                                                                            tooTipIconSx
                                                                        }
                                                                    >
                                                                        <InfoIcon />
                                                                    </Icon>
                                                                </Tooltip>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                        >
                                                            <RadioButtonGroup
                                                                options={
                                                                    mqsYesNoOptions
                                                                }
                                                                row={true}
                                                                disabled={
                                                                    questions?.isViewMode
                                                                }
                                                                name="for_contractor"
                                                                onChange={
                                                                    handleFormChange
                                                                }
                                                                value={
                                                                    questions
                                                                        ?.formData
                                                                        ?.for_contractor
                                                                }
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item lg={6} md={6} sm={6}>
                                                    <Grid container>
                                                        <Grid
                                                            item
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                        >
                                                            <Typography className="font_18_600">
                                                                Question
                                                                applicable to
                                                                Sub Contractor?
                                                                <Tooltip
                                                                    arrow={true}
                                                                    title={
                                                                        definations?.for_sub_contractor
                                                                    }
                                                                    placement="right"
                                                                >
                                                                    <Icon
                                                                        sx={
                                                                            tooTipIconSx
                                                                        }
                                                                    >
                                                                        <InfoIcon />
                                                                    </Icon>
                                                                </Tooltip>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                        >
                                                            <RadioButtonGroup
                                                                options={
                                                                    mqsYesNoOptions
                                                                }
                                                                row={true}
                                                                disabled={
                                                                    questions?.isViewMode
                                                                }
                                                                name="for_sub_contractor"
                                                                onChange={
                                                                    handleFormChange
                                                                }
                                                                value={
                                                                    questions
                                                                        ?.formData
                                                                        ?.for_sub_contractor
                                                                }
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item lg={6} md={6} sm={6}>
                                                    <Grid container>
                                                        <Grid
                                                            item
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                        >
                                                            <Typography className="font_18_600">
                                                                Question
                                                                applicable to
                                                                Manager?
                                                                <Tooltip
                                                                    arrow={true}
                                                                    title={
                                                                        definations?.for_manager
                                                                    }
                                                                    placement="right"
                                                                >
                                                                    <Icon
                                                                        sx={
                                                                            tooTipIconSx
                                                                        }
                                                                    >
                                                                        <InfoIcon />
                                                                    </Icon>
                                                                </Tooltip>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                        >
                                                            <RadioButtonGroup
                                                                options={
                                                                    mqsYesNoOptions
                                                                }
                                                                row={true}
                                                                disabled={
                                                                    questions?.isViewMode
                                                                }
                                                                name="for_manager"
                                                                onChange={
                                                                    handleFormChange
                                                                }
                                                                value={
                                                                    questions
                                                                        ?.formData
                                                                        ?.for_manager
                                                                }
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item lg={6} md={6} sm={6}>
                                                    <Grid container>
                                                        <Grid
                                                            item
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                        >
                                                            <Typography className="font_18_600">
                                                                Question
                                                                applicable to
                                                                Service
                                                                Recipient?
                                                                <Tooltip
                                                                    arrow={true}
                                                                    title={
                                                                        definations?.for_service_recipient
                                                                    }
                                                                    placement="right"
                                                                >
                                                                    <Icon
                                                                        sx={
                                                                            tooTipIconSx
                                                                        }
                                                                    >
                                                                        <InfoIcon />
                                                                    </Icon>
                                                                </Tooltip>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                        >
                                                            <RadioButtonGroup
                                                                options={
                                                                    mqsYesNoOptions
                                                                }
                                                                row={true}
                                                                disabled={
                                                                    questions?.isViewMode
                                                                }
                                                                name="for_service_recipient"
                                                                onChange={
                                                                    handleFormChange
                                                                }
                                                                value={
                                                                    questions
                                                                        ?.formData
                                                                        ?.for_service_recipient
                                                                }
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item lg={6} md={6} sm={6}>
                                                    <Grid container>
                                                        <Grid
                                                            item
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                        >
                                                            <Typography className="font_18_600">
                                                                Question
                                                                applicable to
                                                                Hiring Party?
                                                                <Tooltip
                                                                    arrow={true}
                                                                    title={
                                                                        definations?.for_hiring_party
                                                                    }
                                                                    placement="right"
                                                                >
                                                                    <Icon
                                                                        sx={
                                                                            tooTipIconSx
                                                                        }
                                                                    >
                                                                        <InfoIcon />
                                                                    </Icon>
                                                                </Tooltip>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                        >
                                                            <RadioButtonGroup
                                                                options={
                                                                    mqsYesNoOptions
                                                                }
                                                                row={true}
                                                                disabled={
                                                                    questions?.isViewMode
                                                                }
                                                                name="for_hiring_party"
                                                                onChange={
                                                                    handleFormChange
                                                                }
                                                                value={
                                                                    questions
                                                                        ?.formData
                                                                        ?.for_hiring_party
                                                                }
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item lg={6} md={6} sm={6}>
                                                    <Grid container>
                                                        <Grid
                                                            item
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                        >
                                                            <Typography className="font_18_600">
                                                                Question
                                                                applicable to
                                                                Independent
                                                                Contractor?
                                                                <Tooltip
                                                                    arrow={true}
                                                                    title={
                                                                        definations?.for_indirect_contractor
                                                                    }
                                                                    placement="right"
                                                                >
                                                                    <Icon
                                                                        sx={
                                                                            tooTipIconSx
                                                                        }
                                                                    >
                                                                        <InfoIcon />
                                                                    </Icon>
                                                                </Tooltip>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                        >
                                                            <RadioButtonGroup
                                                                options={
                                                                    mqsYesNoOptions
                                                                }
                                                                row={true}
                                                                disabled={
                                                                    questions?.isViewMode
                                                                }
                                                                name="for_indirect_contractor"
                                                                onChange={
                                                                    handleFormChange
                                                                }
                                                                value={
                                                                    questions
                                                                        ?.formData
                                                                        ?.for_indirect_contractor
                                                                }
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </AccordionCustom>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} my={1}>
                                    <Grid item lg={12} md={12} sm={12}>
                                        <AccordionCustom title="Knock Over">
                                            <Grid container spacing={2}>
                                                <Grid
                                                    item
                                                    lg={12}
                                                    md={12}
                                                    sm={12}
                                                >
                                                    <Grid container>
                                                        <Grid
                                                            item
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                        >
                                                            <Typography className="font_18_600">
                                                                Knock-Over
                                                                Question?
                                                                <Tooltip
                                                                    arrow={true}
                                                                    title={
                                                                        definations?.ko_question
                                                                    }
                                                                    placement="right"
                                                                >
                                                                    <Icon
                                                                        sx={
                                                                            tooTipIconSx
                                                                        }
                                                                    >
                                                                        <InfoIcon />
                                                                    </Icon>
                                                                </Tooltip>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                        >
                                                            <RadioButtonGroup
                                                                options={
                                                                    mqsYesNoOptions
                                                                }
                                                                disabled={
                                                                    questions?.isViewMode
                                                                }
                                                                name="ko_question"
                                                                onChange={
                                                                    handleFormChange
                                                                }
                                                                row={true}
                                                                value={
                                                                    questions
                                                                        ?.formData
                                                                        ?.ko_question
                                                                }
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            {questions?.formData?.ko_question ==
                                                1 && (
                                                    <Fragment>
                                                        <Grid
                                                            container
                                                            spacing={2}
                                                            my={1}
                                                        >
                                                            <Grid
                                                                item
                                                                lg={12}
                                                                md={12}
                                                                sm={12}
                                                            >
                                                                <Typography className="font_18_600">
                                                                    Knock-Over
                                                                    Question Content
                                                                    <Tooltip
                                                                        arrow={true}
                                                                        title={
                                                                            definations?.ko_ques_content
                                                                        }
                                                                        placement="right"
                                                                    >
                                                                        <Icon
                                                                            sx={
                                                                                tooTipIconSx
                                                                            }
                                                                        >
                                                                            <InfoIcon />
                                                                        </Icon>
                                                                    </Tooltip>
                                                                </Typography>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                lg={12}
                                                                md={12}
                                                                sm={12}
                                                            >
                                                                <TextFieldComponent
                                                                    InputProps={{
                                                                        readOnly:
                                                                            questions?.isViewMode,
                                                                    }}
                                                                    id="addEditQuestionKnockOver"
                                                                    label="Knock-Over Question Content"
                                                                    sx={textBoxSx}
                                                                    multiline={true}
                                                                    fullWidth={true}
                                                                    readOnly={
                                                                        questions?.isViewMode
                                                                    }
                                                                    name="ko_ques_content"
                                                                    onChange={
                                                                        handleFormChange
                                                                    }
                                                                    value={
                                                                        questions
                                                                            ?.formData
                                                                            ?.ko_ques_content
                                                                    }
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <Grid
                                                            container
                                                            spacing={2}
                                                            my={1}
                                                        >
                                                            <Grid
                                                                item
                                                                lg={12}
                                                                md={12}
                                                                sm={12}
                                                            >
                                                                <Typography className="font_18_600">
                                                                    Responses that
                                                                    Trigger
                                                                    Knock-Over
                                                                    Status
                                                                    <Tooltip
                                                                        arrow={true}
                                                                        title={
                                                                            definations?.responses_trigger_ko_status
                                                                        }
                                                                        placement="right"
                                                                    >
                                                                        <Icon
                                                                            sx={
                                                                                tooTipIconSx
                                                                            }
                                                                        >
                                                                            <InfoIcon />
                                                                        </Icon>
                                                                    </Tooltip>
                                                                </Typography>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                lg={12}
                                                                md={12}
                                                                sm={12}
                                                            >
                                                                <QuestionScoreTableComponent
                                                                    tableData={
                                                                        questions
                                                                            ?.formData
                                                                            ?.responses_trigger_ko_status ||
                                                                        []
                                                                    }
                                                                    handleTableDataState={
                                                                        handleTableDataState
                                                                    }
                                                                    readOnly={
                                                                        questions?.isViewMode
                                                                    }
                                                                    responses={
                                                                        questions
                                                                            ?.formData
                                                                            ?.available_response ||
                                                                        []
                                                                    }
                                                                    name="responses_trigger_ko_status"
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Fragment>
                                                )}
                                        </AccordionCustom>
                                    </Grid>
                                </Grid>
                            </Fragment>
                        )}

                        <Grid container spacing={2} my={1}>
                            <Grid item lg={12} md={12} sm={12}>
                                <AccordionCustom title="Additional Questions">
                                    <Grid container spacing={2}>
                                        {!questions?.formData?.pre_question && (
                                            <Fragment>
                                                <Grid item lg={6} md={6} sm={6}>
                                                    <Grid container>
                                                        <Grid
                                                            item
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                        >
                                                            <Typography className="font_18_600">
                                                                US-Specific
                                                                Questions?
                                                                <Tooltip
                                                                    arrow={true}
                                                                    title={
                                                                        definations.us_specific_ques
                                                                    }
                                                                    placement="right"
                                                                >
                                                                    <Icon
                                                                        sx={
                                                                            tooTipIconSx
                                                                        }
                                                                    >
                                                                        <InfoIcon />
                                                                    </Icon>
                                                                </Tooltip>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                        >
                                                            <RadioButtonGroup
                                                                options={
                                                                    mqsYesNoOptions
                                                                }
                                                                row={true}
                                                                disabled={
                                                                    questions?.isViewMode
                                                                }
                                                                name="us_specific_ques"
                                                                onChange={
                                                                    handleFormChange
                                                                }
                                                                value={
                                                                    questions
                                                                        ?.formData
                                                                        ?.us_specific_ques
                                                                }
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Fragment>
                                        )}
                                        <Grid item lg={6} md={6} sm={6}>
                                            <Grid container>
                                                <Grid
                                                    item
                                                    lg={12}
                                                    md={12}
                                                    sm={12}
                                                >
                                                    <Typography className="font_18_600">
                                                        Dependent Questions?
                                                        <Tooltip
                                                            arrow={true}
                                                            title={
                                                                definations?.dependent_ques
                                                            }
                                                            placement="right"
                                                        >
                                                            <Icon
                                                                sx={
                                                                    tooTipIconSx
                                                                }
                                                            >
                                                                <InfoIcon />
                                                            </Icon>
                                                        </Tooltip>
                                                    </Typography>
                                                </Grid>
                                                <Grid
                                                    item
                                                    lg={12}
                                                    md={12}
                                                    sm={12}
                                                >
                                                    <RadioButtonGroup
                                                        options={
                                                            mqsYesNoOptions
                                                        }
                                                        row={true}
                                                        disabled={
                                                            questions?.isViewMode
                                                        }
                                                        name="dependent_ques"
                                                        onChange={
                                                            handleFormChange
                                                        }
                                                        value={
                                                            questions?.formData
                                                                ?.dependent_ques
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6}>
                                            <Grid container>
                                                <Grid
                                                    item
                                                    lg={12}
                                                    md={12}
                                                    sm={12}
                                                >
                                                    <Typography className="font_18_600">
                                                        Dependent Question
                                                        Ranking
                                                        <Tooltip
                                                            arrow={true}
                                                            title={
                                                                definations?.dq_ranking
                                                            }
                                                            placement="right"
                                                        >
                                                            <Icon
                                                                sx={
                                                                    tooTipIconSx
                                                                }
                                                            >
                                                                <InfoIcon />
                                                            </Icon>
                                                        </Tooltip>
                                                    </Typography>
                                                </Grid>
                                                <Grid
                                                    item
                                                    lg={12}
                                                    md={12}
                                                    sm={12}
                                                >
                                                    <RadioButtonGroup
                                                        options={rankingOptions}
                                                        row={true}
                                                        disabled={
                                                            questions?.isViewMode
                                                        }
                                                        name="dq_ranking"
                                                        onChange={
                                                            handleFormChange
                                                        }
                                                        value={
                                                            questions?.formData
                                                                ?.dq_ranking
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6}>
                                            <Grid container>
                                                <Grid
                                                    item
                                                    lg={12}
                                                    md={12}
                                                    sm={12}
                                                >
                                                    <Typography className="font_18_600">
                                                        Responses that activate
                                                        the Dependent Question
                                                        <Tooltip
                                                            arrow={true}
                                                            title={
                                                                definations?.responses_for_activate_dq
                                                            }
                                                            placement="right"
                                                        >
                                                            <Icon
                                                                sx={
                                                                    tooTipIconSx
                                                                }
                                                            >
                                                                <InfoIcon />
                                                            </Icon>
                                                        </Tooltip>
                                                    </Typography>
                                                </Grid>
                                                <Grid
                                                    item
                                                    lg={12}
                                                    md={12}
                                                    sm={12}
                                                >
                                                    <RadioButtonGroup
                                                        options={
                                                            mqsYesNoOptions
                                                        }
                                                        row={true}
                                                        disabled={
                                                            questions?.isViewMode
                                                        }
                                                        name="responses_for_activate_dq"
                                                        onChange={
                                                            handleFormChange
                                                        }
                                                        value={
                                                            questions?.formData
                                                                ?.responses_for_activate_dq
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6}>
                                            <Grid container>
                                                <Grid
                                                    item
                                                    lg={12}
                                                    md={12}
                                                    sm={12}
                                                >
                                                    <Typography className="font_18_600">
                                                        Contingent Question?
                                                        <Tooltip
                                                            arrow={true}
                                                            title={
                                                                definations?.contingent_ques
                                                            }
                                                            placement="right"
                                                        >
                                                            <Icon
                                                                sx={
                                                                    tooTipIconSx
                                                                }
                                                            >
                                                                <InfoIcon />
                                                            </Icon>
                                                        </Tooltip>
                                                    </Typography>
                                                </Grid>
                                                <Grid
                                                    item
                                                    lg={12}
                                                    md={12}
                                                    sm={12}
                                                >
                                                    <RadioButtonGroup
                                                        options={
                                                            mqsYesNoOptions
                                                        }
                                                        row={true}
                                                        disabled={
                                                            questions?.isViewMode
                                                        }
                                                        name="contingent_ques"
                                                        onChange={
                                                            handleFormChange
                                                        }
                                                        value={
                                                            questions?.formData
                                                                ?.contingent_ques
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6}>
                                            <Grid container>
                                                <Grid
                                                    item
                                                    lg={12}
                                                    md={12}
                                                    sm={12}
                                                >
                                                    <Typography className="font_18_600">
                                                        Contingent Master
                                                        Question?
                                                        <Tooltip
                                                            arrow={true}
                                                            title={
                                                                definations?.contingent_ques_master
                                                            }
                                                            placement="right"
                                                        >
                                                            <Icon
                                                                sx={
                                                                    tooTipIconSx
                                                                }
                                                            >
                                                                <InfoIcon />
                                                            </Icon>
                                                        </Tooltip>
                                                    </Typography>
                                                </Grid>
                                                <Grid
                                                    item
                                                    lg={12}
                                                    md={12}
                                                    sm={12}
                                                >
                                                    <RadioButtonGroup
                                                        options={
                                                            mqsYesNoOptions
                                                        }
                                                        row={true}
                                                        disabled={
                                                            questions?.isViewMode
                                                        }
                                                        name="contingent_ques_master"
                                                        onChange={
                                                            handleFormChange
                                                        }
                                                        value={
                                                            questions?.formData
                                                                ?.contingent_ques_master
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {!questions?.formData?.pre_question && (
                                            <Fragment>
                                                <Grid
                                                    item
                                                    lg={6}
                                                    md={6}
                                                    sm={12}
                                                >
                                                    <Grid container>
                                                        <Grid
                                                            item
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                        >
                                                            <Typography className="font_18_600">
                                                                Override
                                                                Question?
                                                                <Tooltip
                                                                    arrow={true}
                                                                    title={
                                                                        definations?.override_ques
                                                                    }
                                                                    placement="right"
                                                                >
                                                                    <Icon
                                                                        sx={
                                                                            tooTipIconSx
                                                                        }
                                                                    >
                                                                        <InfoIcon />
                                                                    </Icon>
                                                                </Tooltip>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                        >
                                                            <RadioButtonGroup
                                                                options={
                                                                    mqsYesNoOptions
                                                                }
                                                                row={true}
                                                                disabled={
                                                                    questions?.isViewMode
                                                                }
                                                                name="override_ques"
                                                                onChange={
                                                                    handleFormChange
                                                                }
                                                                value={
                                                                    questions
                                                                        ?.formData
                                                                        ?.override_ques
                                                                }
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid
                                                    item
                                                    lg={6}
                                                    md={6}
                                                    sm={12}
                                                >
                                                    <Grid container spacing={2}>
                                                        <Grid
                                                            item
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                        >
                                                            <Typography className="font_18_600">
                                                                Overrided
                                                                Question
                                                                <Tooltip
                                                                    arrow={true}
                                                                    title={
                                                                        definations?.overrided_questions
                                                                    }
                                                                    placement="right"
                                                                >
                                                                    <Icon
                                                                        sx={
                                                                            tooTipIconSx
                                                                        }
                                                                    >
                                                                        <InfoIcon />
                                                                    </Icon>
                                                                </Tooltip>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                        >
                                                            <Typography
                                                                variant="span"
                                                                marginRight={2}
                                                                className="font_16_600"
                                                            >
                                                                Unique Question
                                                                Code
                                                            </Typography>
                                                            <input
                                                                type="number"
                                                                min={1}
                                                                maxLength={40}
                                                                className="inputNumber font_16_600"
                                                                readOnly={
                                                                    questions?.isViewMode
                                                                }
                                                                name="overrided_questions"
                                                                onChange={
                                                                    handleFormChange
                                                                }
                                                                value={
                                                                    questions
                                                                        ?.formData
                                                                        ?.overrided_questions
                                                                }
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Fragment>
                                        )}
                                    </Grid>
                                    {!questions?.formData?.pre_question && (
                                        <Grid container spacing={2} my={1}>
                                            <Grid item lg={12} md={12} sm={12}>
                                                <Typography className="font_18_600">
                                                    Responses that trigger all
                                                    of the Overrided Questions
                                                    to be overriden
                                                    <Tooltip
                                                        arrow={true}
                                                        title={
                                                            definations?.responses_for_override_ques
                                                        }
                                                        placement="right"
                                                    >
                                                        <Icon sx={tooTipIconSx}>
                                                            <InfoIcon />
                                                        </Icon>
                                                    </Tooltip>
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={12} md={12} sm={12}>
                                                <QuestionScoreTableComponent
                                                    tableData={
                                                        questions?.formData
                                                            ?.responses_for_override_ques ||
                                                        []
                                                    }
                                                    handleTableDataState={
                                                        handleTableDataState
                                                    }
                                                    responses={
                                                        questions?.formData
                                                            ?.available_response ||
                                                        []
                                                    }
                                                    readOnly={
                                                        questions?.isViewMode
                                                    }
                                                    name="responses_for_override_ques"
                                                />
                                            </Grid>
                                        </Grid>
                                    )}
                                </AccordionCustom>
                            </Grid>
                        </Grid>

                        {!questions?.formData?.pre_question && (
                            <Fragment>
                                <Grid container my={1} spacing={2}>
                                    <Grid item lg={12} md={12} sm={12}>
                                        <AccordionCustom title="Recurrence Probability">
                                            <Grid container spacing={2}>
                                                <Grid
                                                    item
                                                    lg={6}
                                                    md={12}
                                                    sm={12}
                                                    className="table-box-shadow"
                                                >
                                                    {/* <TableComponent
                                                        headerList={headerList}
                                                    >
                                                        {questions.recurrenceProbability.map(
                                                            (item, index) => (
                                                                <TableRow
                                                                    key={index}
                                                                    className={
                                                                        index %
                                                                            2 !==
                                                                        0
                                                                            ? "tertiaryBackground"
                                                                            : ""
                                                                    }
                                                                >
                                                                    <TableCell
                                                                        className="typo4"
                                                                        align="left"
                                                                    >
                                                                        <FormControlLabel
                                                                            readOnly={
                                                                                questions?.isViewMode
                                                                            }
                                                                            disabled={
                                                                                questions?.isViewMode
                                                                            }
                                                                            name="collar"
                                                                            control={
                                                                                <Checkbox
                                                                                    readOnly={
                                                                                        questions?.isViewMode
                                                                                    }
                                                                                />
                                                                            }
                                                                            value={
                                                                                item.collar
                                                                            }
                                                                            label={
                                                                                item.label
                                                                            }
                                                                            checked={
                                                                                item.isChecked
                                                                            }
                                                                            classes={{
                                                                                label: "font_16_600",
                                                                            }}
                                                                            onChange={(
                                                                                event
                                                                            ) =>
                                                                                handleCollarAndRecurrence(
                                                                                    event,
                                                                                    item,
                                                                                    index
                                                                                )
                                                                            }
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell
                                                                        className="typo4"
                                                                        align="center"
                                                                    >
                                                                        <input
                                                                            type="number"
                                                                            min={
                                                                                1
                                                                            }
                                                                            max={
                                                                                100
                                                                            }
                                                                            maxLenght={
                                                                                40
                                                                            }
                                                                            readOnly={
                                                                                questions?.isViewMode
                                                                            }
                                                                            name="weightage"
                                                                            value={
                                                                                item.weightage
                                                                            }
                                                                            disabled={
                                                                                !item.isChecked
                                                                            }
                                                                            className="inputNumber font_16_600"
                                                                            onChange={(
                                                                                event
                                                                            ) =>
                                                                                handleCollarAndRecurrence(
                                                                                    event,
                                                                                    item,
                                                                                    index
                                                                                )
                                                                            }
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell
                                                                        className="typo4"
                                                                        align="center"
                                                                    >
                                                                        <input
                                                                            type="number"
                                                                            min={
                                                                                1
                                                                            }
                                                                            maxLenght={
                                                                                40
                                                                            }
                                                                            value={
                                                                                item.ata_probability
                                                                            }
                                                                            disabled={
                                                                                !item.isChecked
                                                                            }
                                                                            readOnly={
                                                                                questions?.isViewMode
                                                                            }
                                                                            name="ata_probability"
                                                                            className="inputNumber font_16_600"
                                                                            onChange={(
                                                                                event
                                                                            ) =>
                                                                                handleCollarAndRecurrence(
                                                                                    event,
                                                                                    item,
                                                                                    index
                                                                                )
                                                                            }
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell
                                                                        className="typo4"
                                                                        align="center"
                                                                    >
                                                                        <input
                                                                            type="number"
                                                                            min={
                                                                                1
                                                                            }
                                                                            readOnly={
                                                                                questions?.isViewMode
                                                                            }
                                                                            name="cbha_probability"
                                                                            value={
                                                                                item.cbha_probability
                                                                            }
                                                                            maxLenght={
                                                                                40
                                                                            }
                                                                            disabled={
                                                                                !item.isChecked
                                                                            }
                                                                            className="inputNumber font_16_600"
                                                                            onChange={(
                                                                                event
                                                                            ) =>
                                                                                handleCollarAndRecurrence(
                                                                                    event,
                                                                                    item,
                                                                                    index
                                                                                )
                                                                            }
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell
                                                                        className="typo4"
                                                                        align="center"
                                                                    >
                                                                        <input
                                                                            type="number"
                                                                            min={
                                                                                1
                                                                            }
                                                                            readOnly={
                                                                                questions?.isViewMode
                                                                            }
                                                                            name="rcp_probability"
                                                                            value={
                                                                                item.rcp_probability
                                                                            }
                                                                            disabled={
                                                                                !item.isChecked
                                                                            }
                                                                            maxLenght={
                                                                                40
                                                                            }
                                                                            className="inputNumber font_16_600"
                                                                            onChange={(
                                                                                event
                                                                            ) =>
                                                                                handleCollarAndRecurrence(
                                                                                    event,
                                                                                    item,
                                                                                    index
                                                                                )
                                                                            }
                                                                        />
                                                                    </TableCell>
                                                                </TableRow>
                                                            )
                                                        )}
                                                    </TableComponent> */}
                                                    <MultiSelectDropDown
                                                        value={
                                                            Array.isArray(
                                                                questions?.formData
                                                                    ?.collar
                                                            )
                                                                ? questions?.formData
                                                                    ?.collar
                                                                : []
                                                        }
                                                        title={ userLoggedIn?.language_data?.complianceworkflow?.collar_color || "Collar Color"}
                                                        readOnly={
                                                            questions?.isViewMode
                                                        }
                                                        name="collarcolor"
                                                        onChange={
                                                            handleReccurence
                                                        }
                                                        renderValue={(
                                                            selected
                                                        ) => {
                                                            if (selected) {
                                                                return handleRenderValue(
                                                                    selected,
                                                                    commondropdown?.collars
                                                                );
                                                            }
                                                        }}
                                                    >
                                                        {commondropdown?.collars?.length >
                                                            0 && (
                                                                <MenuItem
                                                                    value="select_all"
                                                                >
                                                                    <Checkbox
                                                                        checked={
                                                                            questions?.selectAllReccurence
                                                                        }
                                                                    />
                                                                    <ListItemText primary="Select All" />
                                                                </MenuItem>
                                                            )}

                                                        {commondropdown?.collars?.map(
                                                            (code) => (
                                                                <MenuItem
                                                                    key={
                                                                        code?.id
                                                                    }
                                                                    value={
                                                                        code?.id
                                                                    }
                                                                >
                                                                    <Checkbox
                                                                        checked={
                                                                            questions?.formData?.collar?.indexOf(
                                                                                code?.id
                                                                            ) >
                                                                            -1
                                                                        }
                                                                    />
                                                                    <ListItemText
                                                                        primary={
                                                                            code?.label
                                                                        }
                                                                    />
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </MultiSelectDropDown>

                                                </Grid>
                                                <Grid
                                                    item
                                                    lg={6}
                                                    md={12}
                                                    sm={12}
                                                >
                                                    <TextFieldComponent
                                                        id="ata"
                                                        InputProps={{
                                                            readOnly:
                                                                questions?.isViewMode,
                                                        }}
                                                        label="ATA"

                                                        multiline={false}
                                                        fullWidth={false}
                                                        readOnly={
                                                            questions?.isViewMode
                                                        }
                                                        name="recurrence_probability_ata"
                                                        onChange={
                                                            handleReccurence
                                                        }
                                                        value={
                                                            questions?.formData
                                                                ?.recurrence_probability_ata
                                                        }
                                                    />
                                                </Grid>

                                                <Grid
                                                    item
                                                    lg={6}
                                                    md={12}
                                                    sm={12}
                                                >
                                                    <TextFieldComponent
                                                        id="cbha"
                                                        InputProps={{
                                                            readOnly:
                                                                questions?.isViewMode,
                                                        }}
                                                        label="CBHA"

                                                        multiline={false}
                                                        fullWidth={false}
                                                        readOnly={
                                                            questions?.isViewMode
                                                        }
                                                        name="recurrence_probability_cbha"
                                                        onChange={
                                                            handleReccurence
                                                        }
                                                        value={
                                                            questions?.formData
                                                                ?.recurrence_probability_cbha
                                                        }
                                                    />
                                                </Grid>

                                                <Grid
                                                    item
                                                    lg={6}
                                                    md={12}
                                                    sm={12}
                                                >
                                                    <TextFieldComponent
                                                        id="rcp"
                                                        InputProps={{
                                                            readOnly:
                                                                questions?.isViewMode,
                                                        }}
                                                        label="RCP"

                                                        multiline={false}
                                                        fullWidth={false}
                                                        readOnly={
                                                            questions?.isViewMode
                                                        }
                                                        name="recurrence_probability_rcp"
                                                        onChange={
                                                            handleReccurence
                                                        }
                                                        value={
                                                            questions?.formData
                                                                ?.recurrence_probability_rcp
                                                        }
                                                    />
                                                </Grid>

                                                <Grid
                                                    item
                                                    lg={6}
                                                    md={12}
                                                    sm={12}
                                                >
                                                    <TextFieldComponent
                                                        id="weightage"
                                                        InputProps={{
                                                            readOnly:
                                                                questions?.isViewMode,
                                                        }}
                                                        label="Weightage"

                                                        multiline={false}
                                                        fullWidth={false}
                                                        readOnly={
                                                            questions?.isViewMode
                                                        }
                                                        name="weightage"
                                                        onChange={
                                                            handleReccurence
                                                        }
                                                        value={
                                                            questions?.formData
                                                                ?.weightage
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>
                                        </AccordionCustom>
                                    </Grid>
                                </Grid>

                                <Grid container my={1} spacing={2}>
                                    <Grid item lg={12} md={12} sm={12}>
                                        <AccordionCustom title="Content">
                                            <Grid container spacing={2}>
                                                <Grid
                                                    item
                                                    lg={6}
                                                    md={6}
                                                    sm={12}
                                                >
                                                    <Grid container>
                                                        <Grid
                                                            item
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                        >
                                                            <Typography className="font_18_600">
                                                                Apply
                                                                Reservation of
                                                                Right Content?
                                                                <Tooltip
                                                                    arrow={true}
                                                                    title={
                                                                        definations?.apply_reservation_right_content
                                                                    }
                                                                    placement="right"
                                                                >
                                                                    <Icon
                                                                        sx={
                                                                            tooTipIconSx
                                                                        }
                                                                    >
                                                                        <InfoIcon />
                                                                    </Icon>
                                                                </Tooltip>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                        >
                                                            <RadioButtonGroup
                                                                options={
                                                                    mqsYesNoOptions
                                                                }
                                                                row={true}
                                                                disabled={
                                                                    questions?.isViewMode
                                                                }
                                                                name="apply_reservation_right_content"
                                                                onChange={
                                                                    handleFormChange
                                                                }
                                                                value={
                                                                    questions
                                                                        ?.formData
                                                                        ?.apply_reservation_right_content
                                                                }
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid
                                                    item
                                                    lg={6}
                                                    md={6}
                                                    sm={12}
                                                >
                                                    <Grid container spacing={2}>
                                                        <Grid
                                                            item
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                        >
                                                            <Typography className="font_18_600">
                                                                Reservation of
                                                                Right Content
                                                                <Tooltip
                                                                    arrow={true}
                                                                    title={
                                                                        definations?.reservation_right_content
                                                                    }
                                                                    placement="right"
                                                                >
                                                                    <Icon
                                                                        sx={
                                                                            tooTipIconSx
                                                                        }
                                                                    >
                                                                        <InfoIcon />
                                                                    </Icon>
                                                                </Tooltip>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                        >
                                                            <TextFieldComponent
                                                                InputProps={{
                                                                    readOnly:
                                                                        questions?.isViewMode,
                                                                }}
                                                                label="Reservation of Right Content"
                                                                sx={textBoxSx}
                                                                multiline={true}
                                                                fullWidth={true}
                                                                readOnly={
                                                                    questions?.isViewMode
                                                                }
                                                                name="reservation_right_content"
                                                                value={
                                                                    questions
                                                                        ?.formData
                                                                        ?.reservation_right_content
                                                                }
                                                                onChange={
                                                                    handleFormChange
                                                                }
                                                                id="addEditQuestionReservation"
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2} my={1}>
                                                <Grid
                                                    item
                                                    lg={12}
                                                    md={12}
                                                    sm={12}
                                                >
                                                    <Typography className="font_18_600">
                                                        Responses that Trigger
                                                        the Reservation of Right
                                                        Content?
                                                        <Tooltip
                                                            arrow={true}
                                                            title={
                                                                definations?.responses_trigger_reservation_right_content
                                                            }
                                                            placement="right"
                                                        >
                                                            <Icon
                                                                sx={
                                                                    tooTipIconSx
                                                                }
                                                            >
                                                                <InfoIcon />
                                                            </Icon>
                                                        </Tooltip>
                                                    </Typography>
                                                </Grid>
                                                <Grid
                                                    item
                                                    lg={12}
                                                    md={12}
                                                    sm={12}
                                                >
                                                    <QuestionScoreTableComponent
                                                        tableData={
                                                            questions?.formData
                                                                ?.responses_trigger_reservation_right_content ||
                                                            []
                                                        }
                                                        handleTableDataState={
                                                            handleTableDataState
                                                        }
                                                        readOnly={
                                                            questions?.isViewMode
                                                        }
                                                        responses={
                                                            questions?.formData
                                                                ?.available_response ||
                                                            []
                                                        }
                                                        name="responses_trigger_reservation_right_content"
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2} my={1}>
                                                <Grid
                                                    item
                                                    lg={12}
                                                    md={12}
                                                    sm={12}
                                                >
                                                    <Typography className="font_18_600">
                                                        Range of Available
                                                        Responses for
                                                        Reservation of Right
                                                        Content
                                                        <Tooltip
                                                            arrow={true}
                                                            title={
                                                                definations?.responses_of_rrc
                                                            }
                                                            placement="right"
                                                        >
                                                            <Icon
                                                                sx={
                                                                    tooTipIconSx
                                                                }
                                                            >
                                                                <InfoIcon />
                                                            </Icon>
                                                        </Tooltip>
                                                    </Typography>
                                                </Grid>
                                                <Grid
                                                    item
                                                    lg={12}
                                                    md={12}
                                                    sm={12}
                                                >
                                                    <QuestionScoreTableComponent
                                                        tableData={
                                                            questions?.formData
                                                                ?.responses_of_rrc ||
                                                            []
                                                        }
                                                        handleTableDataState={
                                                            handleTableDataState
                                                        }
                                                        readOnly={
                                                            questions?.isViewMode
                                                        }
                                                        responses={
                                                            questions?.formData
                                                                ?.available_response ||
                                                            []
                                                        }
                                                        name="responses_of_rrc"
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2} my={1}>
                                                <Grid
                                                    item
                                                    lg={6}
                                                    md={6}
                                                    sm={12}
                                                >
                                                    <Grid container>
                                                        <Grid
                                                            item
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                        >
                                                            <Typography className="font_18_600">
                                                                Apply
                                                                Alternative
                                                                Response
                                                                Content?
                                                                <Tooltip
                                                                    arrow={true}
                                                                    title={
                                                                        definations?.apply_alternative_response_content
                                                                    }
                                                                    placement="right"
                                                                >
                                                                    <Icon
                                                                        sx={
                                                                            tooTipIconSx
                                                                        }
                                                                    >
                                                                        <InfoIcon />
                                                                    </Icon>
                                                                </Tooltip>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                        >
                                                            <RadioButtonGroup
                                                                options={
                                                                    mqsYesNoOptions
                                                                }
                                                                disabled={
                                                                    questions?.isViewMode
                                                                }
                                                                name="apply_alternative_response_content"
                                                                onChange={
                                                                    handleFormChange
                                                                }
                                                                row={true}
                                                                value={
                                                                    questions
                                                                        ?.formData
                                                                        ?.apply_alternative_response_content
                                                                }
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid
                                                    item
                                                    lg={6}
                                                    md={6}
                                                    sm={12}
                                                >
                                                    <Grid container spacing={2}>
                                                        <Grid
                                                            item
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                        >
                                                            <Typography className="font_18_600">
                                                                Alternative
                                                                Response Content
                                                                <Tooltip
                                                                    arrow={true}
                                                                    title={
                                                                        definations?.alternative_response_content
                                                                    }
                                                                    placement="right"
                                                                >
                                                                    <Icon
                                                                        sx={
                                                                            tooTipIconSx
                                                                        }
                                                                    >
                                                                        <InfoIcon />
                                                                    </Icon>
                                                                </Tooltip>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                        >
                                                            <TextFieldComponent
                                                                id="addEditQuestionAlternativeResponse"
                                                                InputProps={{
                                                                    readOnly:
                                                                        questions?.isViewMode,
                                                                }}
                                                                label="Alternative Response Content"
                                                                sx={textBoxSx}
                                                                multiline={true}
                                                                fullWidth={true}
                                                                readOnly={
                                                                    questions?.isViewMode
                                                                }
                                                                name="alternative_response_content"
                                                                onChange={
                                                                    handleFormChange
                                                                }
                                                                value={
                                                                    questions
                                                                        ?.formData
                                                                        ?.alternative_response_content
                                                                }
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2} my={1}>
                                                <Grid item lg={6} md={6} sm={6}>
                                                    <Grid container spacing={2}>
                                                        <Grid
                                                            item
                                                            lg={10}
                                                            md={10}
                                                            sm={10}
                                                        >
                                                            <Typography className="font_18_600">
                                                                Range of
                                                                Responses to
                                                                Alternative
                                                                Content
                                                                <Tooltip
                                                                    arrow={true}
                                                                    title={
                                                                        definations?.alternative_content_responses
                                                                    }
                                                                    placement="right"
                                                                >
                                                                    <Icon
                                                                        sx={
                                                                            tooTipIconSx
                                                                        }
                                                                    >
                                                                        <InfoIcon />
                                                                    </Icon>
                                                                </Tooltip>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={10}
                                                            md={10}
                                                            sm={12}
                                                        >
                                                            <MultiSelectDropDown
                                                                value={
                                                                    Array.isArray(
                                                                        questions
                                                                            ?.formData
                                                                            ?.alternative_content_responses
                                                                    )
                                                                        ? questions
                                                                            ?.formData
                                                                            ?.alternative_content_responses
                                                                        : []
                                                                }
                                                                title="Range of Responses
                                                    to Alternative
                                                    Content"
                                                                onChange={
                                                                    handleAlternativeContentResponsesChange
                                                                }
                                                                renderValue={(
                                                                    selected
                                                                ) => {
                                                                    if (
                                                                        selected
                                                                    ) {
                                                                        return handleRenderValue(
                                                                            selected,
                                                                            alternativeContentResponses
                                                                        );
                                                                    }
                                                                }}
                                                                readOnly={
                                                                    questions?.isViewMode
                                                                }
                                                                name="alternative_content_responses"
                                                            >
                                                                {alternativeContentResponses?.length >
                                                                    0 && (
                                                                        <MenuItem
                                                                            value="select_all"
                                                                        >
                                                                            <Checkbox
                                                                                checked={
                                                                                    questions.selectAternativeContentResponses
                                                                                }
                                                                            />
                                                                            <ListItemText primary="Select All" />
                                                                        </MenuItem>
                                                                    )}
                                                                {alternativeContentResponses?.map(
                                                                    (code) => (
                                                                        <MenuItem
                                                                            key={
                                                                                code?.id
                                                                            }
                                                                            value={
                                                                                code?.id
                                                                            }
                                                                        >
                                                                            <Checkbox
                                                                                checked={
                                                                                    questions?.formData?.alternative_content_responses?.indexOf(
                                                                                        code?.id
                                                                                    ) >
                                                                                    -1
                                                                                }
                                                                            />
                                                                            <ListItemText
                                                                                primary={
                                                                                    code?.label
                                                                                }
                                                                            />
                                                                        </MenuItem>
                                                                    )
                                                                )}
                                                            </MultiSelectDropDown>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </AccordionCustom>
                                    </Grid>
                                </Grid>
                            </Fragment>
                        )}

                        <Grid container my={1} spacing={2}>
                            <Grid item lg={12} md={12} sm={12}>
                                <AccordionCustom title="Others">
                                    <Grid container spacing={2}>
                                        {!questions?.formData?.pre_question && (
                                            <Fragment>
                                                <Grid
                                                    item
                                                    lg={6}
                                                    md={6}
                                                    sm={12}
                                                >
                                                    <Grid container>
                                                        <Grid
                                                            item
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                        >
                                                            <Typography className="font_18_600">
                                                                Question
                                                                Applicable to
                                                                Contracting
                                                                Business?
                                                                <Tooltip
                                                                    arrow={true}
                                                                    title={
                                                                        definations?.ques_apply_to_cb
                                                                    }
                                                                    placement="right"
                                                                >
                                                                    <Icon
                                                                        sx={
                                                                            tooTipIconSx
                                                                        }
                                                                    >
                                                                        <InfoIcon />
                                                                    </Icon>
                                                                </Tooltip>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                        >
                                                            <RadioButtonGroup
                                                                options={
                                                                    mqsYesNoOptions
                                                                }
                                                                row={true}
                                                                disabled={
                                                                    questions?.isViewMode
                                                                }
                                                                name="ques_apply_to_cb"
                                                                onChange={
                                                                    handleFormChange
                                                                }
                                                                value={
                                                                    questions
                                                                        ?.formData
                                                                        ?.ques_apply_to_cb
                                                                }
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid
                                                    item
                                                    lg={6}
                                                    md={6}
                                                    sm={12}
                                                >
                                                    <Grid container>
                                                        <Grid
                                                            item
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                        >
                                                            <Typography className="font_18_600">
                                                                Question
                                                                applicable to
                                                                Subcontracting
                                                                Business?
                                                                <Tooltip
                                                                    arrow={true}
                                                                    title={
                                                                        definations?.for_sub_contacting_business
                                                                    }
                                                                    placement="right"
                                                                >
                                                                    <Icon
                                                                        sx={
                                                                            tooTipIconSx
                                                                        }
                                                                    >
                                                                        <InfoIcon />
                                                                    </Icon>
                                                                </Tooltip>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                        >
                                                            <RadioButtonGroup
                                                                options={
                                                                    mqsYesNoOptions
                                                                }
                                                                row={true}
                                                                disabled={
                                                                    questions?.isViewMode
                                                                }
                                                                name="for_sub_contacting_business"
                                                                onChange={
                                                                    handleFormChange
                                                                }
                                                                value={
                                                                    questions
                                                                        ?.formData
                                                                        ?.for_sub_contacting_business
                                                                }
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid
                                                    item
                                                    lg={6}
                                                    md={6}
                                                    sm={12}
                                                >
                                                    <Grid container>
                                                        <Grid
                                                            item
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                        >
                                                            <Typography className="font_18_600">
                                                                Response that
                                                                Activates
                                                                Repression of
                                                                Question
                                                                <Tooltip
                                                                    arrow={true}
                                                                    title={
                                                                        definations?.repression_question_responses
                                                                    }
                                                                    placement="right"
                                                                >
                                                                    <Icon
                                                                        sx={
                                                                            tooTipIconSx
                                                                        }
                                                                    >
                                                                        <InfoIcon />
                                                                    </Icon>
                                                                </Tooltip>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                        >
                                                            <RadioButtonGroup
                                                                options={
                                                                    rangeOptions
                                                                }
                                                                row={true}
                                                                disabled={
                                                                    questions?.isViewMode
                                                                }
                                                                name="repression_question_responses"
                                                                onChange={
                                                                    handleFormChange
                                                                }
                                                                value={
                                                                    questions
                                                                        ?.formData
                                                                        ?.repression_question_responses
                                                                }
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid
                                                    item
                                                    lg={6}
                                                    md={6}
                                                    sm={12}
                                                >
                                                    <Grid container>
                                                        <Grid
                                                            lg={10}
                                                            md={10}
                                                            sm={10}
                                                        >
                                                            <Typography className="font_18_600">
                                                                Question Family
                                                                Code
                                                                <Tooltip
                                                                    arrow={true}
                                                                    title={
                                                                        definations?.unique_ques_family_code
                                                                    }
                                                                    placement="right"
                                                                >
                                                                    <Icon
                                                                        sx={
                                                                            tooTipIconSx
                                                                        }
                                                                    >
                                                                        <InfoIcon />
                                                                    </Icon>
                                                                </Tooltip>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            lg={10}
                                                            md={10}
                                                            sm={10}
                                                            mt={2}
                                                            mb={0.5}
                                                        >
                                                            <TextFieldComponent
                                                                id="addEditQuestionFamilyCode"
                                                                InputProps={{
                                                                    readOnly:
                                                                        questions?.isViewMode,
                                                                }}
                                                                fullWidth={true}
                                                                label={userLoggedIn?.language_data?.documentdatabase?.question_family_code || "Question Family Code"}
                                                                type="number"
                                                                readOnly={
                                                                    questions?.isViewMode
                                                                }
                                                                name="unique_ques_family_code"
                                                                onChange={
                                                                    handleFormChange
                                                                }
                                                                value={
                                                                    questions
                                                                        ?.formData
                                                                        ?.unique_ques_family_code
                                                                }
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Fragment>
                                        )}
                                        <Grid item lg={6} md={6} sm={12}>
                                            <Grid container spacing={2}>
                                                <Grid
                                                    item
                                                    lg={12}
                                                    md={12}
                                                    sm={12}
                                                >
                                                    <Typography className="font_18_600">
                                                        All TCs must apply?
                                                        <Tooltip
                                                            arrow={true}
                                                            title={
                                                                definations?.all_tc_apply
                                                            }
                                                            placement="right"
                                                        >
                                                            <Icon
                                                                sx={
                                                                    tooTipIconSx
                                                                }
                                                            >
                                                                <InfoIcon />
                                                            </Icon>
                                                        </Tooltip>
                                                    </Typography>
                                                </Grid>
                                                <Grid
                                                    item
                                                    lg={10}
                                                    md={10}
                                                    sm={12}
                                                >
                                                    <DropDownComponent
                                                        fullWidth={true}
                                                        label="All TCs must apply?"
                                                        required={true}
                                                        readOnly={
                                                            questions?.isViewMode
                                                        }
                                                        name="all_tc_apply"
                                                        onChange={
                                                            handleFormChange
                                                        }
                                                        value={
                                                            questions?.formData
                                                                ?.all_tc_apply
                                                        }
                                                        children={tcOptions.map(
                                                            (item) => (
                                                                <MenuItem
                                                                    key={
                                                                        item.value
                                                                    }
                                                                    value={
                                                                        item.value
                                                                    }
                                                                >
                                                                    {item.label}
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6}>
                                            <Grid container spacing={2}>
                                                <Grid
                                                    item
                                                    lg={12}
                                                    md={12}
                                                    sm={12}
                                                >
                                                    <Typography
                                                        className="font_18_600"
                                                        variant="span"
                                                    >
                                                        Trigger Code
                                                        <Tooltip
                                                            arrow={true}
                                                            title={
                                                                definations?.trigger_code
                                                            }
                                                            placement="right"
                                                        >
                                                            <Icon
                                                                sx={
                                                                    tooTipIconSx
                                                                }
                                                            >
                                                                <InfoIcon />
                                                            </Icon>
                                                        </Tooltip>
                                                    </Typography>
                                                </Grid>
                                                <Grid
                                                    item
                                                    lg={10}
                                                    md={10}
                                                    sm={12}
                                                >
                                                    <MultiSelectDropDown
                                                        value={
                                                            Array.isArray(
                                                                questions
                                                                    ?.formData
                                                                    ?.trigger_code
                                                            )
                                                                ? questions
                                                                    ?.formData
                                                                    ?.trigger_code
                                                                : []
                                                        }
                                                        title="Trigger Code"
                                                        onChange={
                                                            handleCodeChange
                                                        }
                                                        renderValue={(
                                                            selected
                                                        ) => {
                                                            if (selected) {
                                                                return handleRenderValue(
                                                                    selected,
                                                                    mqs_trigger_code
                                                                );
                                                            }
                                                        }}
                                                        readOnly={
                                                            questions?.isViewMode
                                                        }
                                                        name="trigger_code"
                                                    >
                                                        {mqs_trigger_code?.length >
                                                            0 && (
                                                                <MenuItem
                                                                    value="select_all"
                                                                >
                                                                    <Checkbox
                                                                        checked={
                                                                            questions.allSelectCode
                                                                        }
                                                                    />
                                                                    <ListItemText primary="Select All" />
                                                                </MenuItem>
                                                            )}
                                                        {mqs_trigger_code?.map(
                                                            (code) => (
                                                                <MenuItem
                                                                    key={
                                                                        code?.id
                                                                    }
                                                                    value={
                                                                        code?.id
                                                                    }
                                                                >
                                                                    <Checkbox
                                                                        checked={
                                                                            questions?.formData?.trigger_code?.indexOf(
                                                                                code?.id
                                                                            ) >
                                                                            -1
                                                                        }
                                                                    />
                                                                    <ListItemText
                                                                        primary={
                                                                            code?.label
                                                                        }
                                                                    />
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </MultiSelectDropDown>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item sm={12} md={6} lg={6}>
                                            <Grid container spacing={2}>
                                                <Grid
                                                    item
                                                    lg={12}
                                                    md={12}
                                                    sm={12}
                                                >
                                                    <Typography
                                                        className="font_18_600"
                                                        variant="span"
                                                    >
                                                        Question Category
                                                        <Tooltip
                                                            arrow={true}
                                                            title={
                                                                definations?.category_id ||
                                                                "Question Category"
                                                            }
                                                            placement="right"
                                                        >
                                                            <Icon
                                                                sx={
                                                                    tooTipIconSx
                                                                }
                                                            >
                                                                <InfoIcon />
                                                            </Icon>
                                                        </Tooltip>
                                                    </Typography>
                                                </Grid>
                                                <Grid
                                                    item
                                                    lg={10}
                                                    md={10}
                                                    sm={12}
                                                >
                                                    <DropDownComponent
                                                        fullWidth={true}
                                                        label="Category"
                                                        required={true}
                                                        name="category_id"
                                                        onChange={
                                                            handleFormChange
                                                        }
                                                        value={
                                                            questions?.formData
                                                                ?.category_id
                                                        }
                                                        readOnly={
                                                            questions.isCategoryReadOnly ||
                                                            questions?.isViewMode
                                                        }
                                                        children={question_categories?.map(
                                                            (item) => (
                                                                <MenuItem
                                                                    key={
                                                                        item.id
                                                                    }
                                                                    value={
                                                                        item.id
                                                                    }
                                                                >
                                                                    {item.label}
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {questions?.formData?.pre_question ? (
                                            <Grid item sm={12} md={6} lg={6}>
                                                <Grid container spacing={2}>
                                                    <Grid
                                                        item
                                                        lg={12}
                                                        md={12}
                                                        sm={12}
                                                    >
                                                        <Typography
                                                            className="font_18_600"
                                                            variant="span"
                                                        >
                                                            Test Type
                                                            <Tooltip
                                                                arrow={true}
                                                                title={
                                                                    definations?.test_type_id ||
                                                                    "Test Type"
                                                                }
                                                                placement="right"
                                                            >
                                                                <Icon
                                                                    sx={
                                                                        tooTipIconSx
                                                                    }
                                                                >
                                                                    <InfoIcon />
                                                                </Icon>
                                                            </Tooltip>
                                                        </Typography>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        lg={10}
                                                        md={10}
                                                        sm={12}
                                                    >
                                                        <DropDownComponent
                                                            fullWidth={true}
                                                            label="Test Type"
                                                            required={true}
                                                            readOnly={
                                                                questions?.isViewMode
                                                            }
                                                            name="test_type_id"
                                                            onChange={
                                                                handleFormChange
                                                            }
                                                            value={
                                                                questions
                                                                    ?.formData
                                                                    ?.test_type_id
                                                            }
                                                            children={test_type?.map(
                                                                (item) => (
                                                                    <MenuItem
                                                                        key={
                                                                            item.id
                                                                        }
                                                                        value={
                                                                            item.id
                                                                        }
                                                                    >
                                                                        {
                                                                            item.label
                                                                        }
                                                                    </MenuItem>
                                                                )
                                                            )}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        ) : null}
                                    </Grid>
                                </AccordionCustom>
                            </Grid>
                        </Grid>
                    </ContextProvider.Provider>
                    <Grid container my={1} spacing={2}>
                        <Grid item lg={12} md={12} sm={12} textAlign="end">
                            <ButtonComponent
                                title={
                                    questions?.isViewMode ? "Back" : "Cancel"
                                }
                                sx={{
                                    mr: questions?.isViewMode ? 0 : 2,
                                }}
                                variant="outlined"
                                onClick={() => {
                                    navigate(-1);
                                }}
                            />
                            {!questions?.isViewMode && (
                                <ButtonComponent
                                    title={btnText}
                                    onClick={handleFormSubmit}
                                />
                            )}
                        </Grid>
                    </Grid>
                </Box>
            )}
        </Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,
        mqs_trigger_code: state?.auth?.commonDropdown?.mqs_trigger_codes,
        definations: state?.mqs?.mqsDefination,
        jurisdiction: state?.auth?.commonDropdown?.states,
        circuits: state?.auth?.commonDropdown?.circuits,
        selectedQuestion: state?.mqs?.selectedQuestion,
        question_categories: state?.auth?.commonDropdown?.question_categories,
        test_type: state?.auth?.commonDropdown?.test_type,
        mqs_options: state?.auth?.commonDropdown?.mqs_options,
        testQuestion: state.mqs?.testQuestion,
        commondropdown: state?.auth?.commonDropdown
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            mqsDefinationAction,
            mqsAddQuestionAction,
            getQuestionDetail,
            updateQuestionDetail,
            setSliedQuestionView,
            getQuestionListByOptions,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditQuestion);
