import * as Actions from "../Action/TestAction";

const initialState = {
    testResponses: {
        test_id: "",
        test_response: [],
        guid: null,
        test_type: "",
        user_id: null,
        role_id: null,
        testStartTime: null,
    },
    timer: {
        seconds: 59,
        minutes: 1,
    },
    start: null,
    pausedTimer: false,
    updateConfiltData: [],
};

const TestReducer = (state = initialState, action) => {
    switch (action?.type) {
        case Actions.SET_TEST_RESPONSE: {
            let questionObject = action.payload.object;
            let response = action.payload.response;
            let copyResponse = state?.testResponses?.test_response;
            let findDuplicateIndex = copyResponse.findIndex(
                (obj) =>
                    obj.question_id ===
                    (questionObject.id
                        ? questionObject.id
                        : questionObject.question_id)
            );
            if (findDuplicateIndex !== -1) {
                copyResponse[findDuplicateIndex] = {
                    question_id: questionObject.id
                        ? questionObject.id
                        : questionObject.question_id,
                    response: response,
                    question: questionObject?.question,
                    available_response: questionObject?.available_response,
                    is_scorable: questionObject?.is_scorable,
                    is_dependent: questionObject.is_dependent
                        ? questionObject.is_dependent
                        : false,
                    parent_id: questionObject.parent_id
                        ? questionObject.parent_id
                        : null,
                    question_number: questionObject.question_number,
                };
                return {
                    ...state,
                    testResponses: {
                        ...state.testResponses,
                        test_response: copyResponse,
                    },
                };
            } else {
                let data = null;
                if (response) {
                    data = {
                        question_id: questionObject.id
                            ? questionObject.id
                            : questionObject.question_id,
                        response: response,
                        question: questionObject?.question,
                        available_response: questionObject?.available_response,
                        is_scorable: questionObject?.is_scorable,
                        is_dependent: questionObject.is_dependent
                            ? questionObject.is_dependent
                            : false,
                        parent_id: questionObject.parent_id
                            ? questionObject.parent_id
                            : null,
                        question_number: questionObject.question_number,
                    };
                } else {
                    data = {
                        question_id: questionObject.id
                            ? questionObject.id
                            : questionObject.question_id,
                        response: null,
                        question: questionObject?.question,
                        available_response: questionObject?.available_response,
                        is_scorable: questionObject?.is_scorable,
                        is_dependent: questionObject.is_dependent
                            ? questionObject.is_dependent
                            : false,
                        parent_id: questionObject.parent_id
                            ? questionObject.parent_id
                            : null,
                        question_number: questionObject.question_number,
                    };
                }
                return {
                    ...state,
                    testResponses: {
                        ...state.testResponses,

                        test_response: [
                            ...state.testResponses?.test_response,
                            data,
                        ],
                    },
                };
            }
        }
        case Actions.SET_SKIP_QUESTION: {
            let questionObject = action.payload.object;
            let response = action.payload.response;
            let copyResponse = state.testResponses?.test_response;
            let findDuplicateIndex = copyResponse.findIndex(
                (obj) => obj.question_id === questionObject.id
            );
            if (findDuplicateIndex !== -1) {
                copyResponse[findDuplicateIndex] = {
                    ...copyResponse[findDuplicateIndex],
                    question_id: questionObject.id,
                    response: response,
                };
                return {
                    ...state,
                    testResponses: {
                        ...state.testResponses,
                        test_response: copyResponse,
                    },
                };
            } else {
                let data = null;
                data = {
                    question_id: questionObject.id,
                    response: null,
                    question: questionObject?.question,
                    available_response: questionObject?.available_response,
                    is_scorable: questionObject?.is_scorable,
                    is_dependent: questionObject.is_dependent
                        ? questionObject.is_dependent
                        : false,
                    parent_id: questionObject.parent_id
                        ? questionObject.parent_id
                        : null,
                    question_number: questionObject.question_number,
                };
                return {
                    ...state,
                    testResponses: {
                        ...state.testResponses,
                        test_response: [
                            ...state.testResponses?.test_response,
                            data,
                        ],
                    },
                };
            }
        }
        case Actions.SET_TEST_DATA: {
            return {
                ...state,
                testResponses: {
                    ...state.testResponses,
                    ...action.payload,
                },
            };
        }
        case Actions.SET_TEST_TIMER: {
            return {
                ...state,
                timer: {
                    ...action.payload,
                },
            };
        }
        case Actions.SET_PAUSED_TIMER: {
            return {
                ...state,
                pausedTimer: action.payload,
            };
        }
        case Actions.SET_START_VALUE: {
            return {
                ...state,
                start: action.payload,
            };
        }
        case Actions.SET_UPDATE_CONFILT_OBJECT: {
            let payloadData = action.payload;
            let copyResponse = state.updateConfiltData;
            let findDuplicateIndex = copyResponse.findIndex(
                (obj) => obj.ques_id === payloadData.ques_id
            );
            if (findDuplicateIndex !== -1) {
                copyResponse[findDuplicateIndex] = action.payload;
                return {
                    ...state,
                    updateConfiltData: copyResponse,
                };
            } else {
                return {
                    ...state,
                    updateConfiltData: [
                        ...state.updateConfiltData,
                        action.payload,
                    ],
                };
            }
        }

        default: {
            return state;
        }
    }
};

export default TestReducer;
