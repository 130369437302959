import { getApiCall, postApiCall, putApiCall } from "../../utils/Action";
import { API_CONSTANTS } from "../../config/ApiConstants";

export const SAVE_DYANAMIC_TEST = "SAVE_DYANAMIC_TEST";
export const GET_DYANAMIC_TEST_DETAILS_LIST = "GET_DYANAMIC_TEST_DETAILS_LIST";
export const EDIT_DYANAMIC_TEST = "EDIT_DYANAMIC_TEST";

export const saveDynamicTest = (data) => {
    return async (dispatch) => {
        try {
            const response = await postApiCall(
                API_CONSTANTS.DYANAMIC_TEST_API,
                data
            );
            if (response?.status === 201) {
                return dispatch({
                    type: SAVE_DYANAMIC_TEST,
                    payload: response?.data?.result,
                });
            } else {
                // toast.error(response?.data?.message);
            }
        } catch (error) {
            throw error;
        }
    };
};

export const getDynamicTestDetailsList = (guid) => {
    return async (dispatch) => {
        try {
            const response = await getApiCall(
                API_CONSTANTS.DYANAMIC_TEST_DETAILS_LIST,
                {
                    guid: guid,
                }
            );
            if (response?.status === 200) {
                return dispatch({
                    type: GET_DYANAMIC_TEST_DETAILS_LIST,
                    payload: response?.data?.result,
                });
            } else if (response?.status === 404) {
                return dispatch({
                    type: GET_DYANAMIC_TEST_DETAILS_LIST,
                    payload: [],
                });
            } else {
                // toast.error(response?.data?.message);
            }
        } catch (error) {
            throw error;
        }
    };
};
export const editDynamicTest = (data) => {
    return async (dispatch) => {
        try {
            const response = await putApiCall(
                API_CONSTANTS.DYANAMIC_TEST_API + "/" + data.id,
                data
            );
            if (response?.status == 201) {
                return dispatch({
                    type: EDIT_DYANAMIC_TEST,
                    payload: response?.data?.result || null,
                });
            } else {
                // toast.error(response?.message);
            }
        } catch (error) {
            throw error;
        }
    };
};
