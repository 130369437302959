import {
    getApiCall,
    postApiCall,
    deleteApiCall,
    putApiCall,
} from "../../utils/Action";
import { API_CONSTANTS } from "../../config/ApiConstants";

export const GET_CUSTOMER_LIST = "GET_CUSTOMER_LIST";
export const TOGGLE_DELETE_POPUP = "TOGGLE_DELETE_POPUP";
export const CREATE_CLIENT = "CREATE_CLIENT";
export const SET_SELECTED_CUSTOMER = "SET_SELECTED_CUSTOMER";
export const GET_CUSTOMER_BY_ID = "GET_CUSTOMER_BY_ID";
export const DELETE_CUSTOMER_BY_ID = "DELETE_CUSTOMER_BY_ID";
export const UPDATE_CUSTOMER_BY_ID = "UPDATE_CUSTOMER_BY_ID";
export const CHANGE_CUSTOMER_VIEW_TYPE = "CHANGE_CUSTOMER_VIEW_TYPE";
export const UPDATE_NO_OF_USER = "UPDATE_NO_OF_USER";
export const GET_CUSTOMER_HIERARCHY = "GET_CUSTOMER_HIERARCHY";
export const GET_USERS_BY_CUSTOMER_ID = "GET_USERS_BY_CUSTOMER_ID";
export const SET_CUSTOMER_PER_PAGE = "SET_CUSTOMER_PER_PAGE";
export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
export const CHANGE_STATUS_BY_ID = "CHANGE_STATUS_BY_ID";

export const getCustomerList = (
    search = null,
    currentPage = null,
    customerPageLimit = null,
    status = 'active'
) => {
    return async (dispatch) => {
        try {
            let apiURL = API_CONSTANTS.CLIENTS;

            if (search && search?.length > 0) {
                apiURL += "?search=" + encodeURIComponent(search) + "&status=" + encodeURIComponent(status);
            }
            const response = await getApiCall(apiURL, {
                page: currentPage,
                page_limit: customerPageLimit,
                status
            });

            return dispatch({
                type: GET_CUSTOMER_LIST,
                payload: response?.data?.result || [],
            });
        } catch (error) {
            throw error;
        }
    };
};

export const toggleDeletePopup = (data) => {
    return async (dispatch) => {
        dispatch({
            type: TOGGLE_DELETE_POPUP,
            payload: data,
        });
    };
};

export const createClientAction = (state) => {
    return async (dispatch, getState) => {
        try {
            const response = await postApiCall(API_CONSTANTS.CLIENTS, state);
            let profileRes;
            const currentState = getState();
            const currentUser = currentState.auth?.userLoggedIn?.user;
            const customerData = currentState?.customer?.customerPagination;
            if (state?.logo?.filename || state?.favicon?.filename) {
                profileRes = await dispatch(
                    uploadClientLogo(response?.data?.result?.id, {
                        logo: state.logo,
                        favicon: state.favicon,
                    })
                );
            }
            if (response?.status === 201) {
                await postApiCall(API_CONSTANTS.LITE_TEST, {
                    passing_score: 60,
                    total_questions: 30,
                    guid: `${response?.data?.result?.guid}`,
                    role_setting: [
                        {
                            id: 2,
                            label: "Contractor",
                            conflict_resolution: false,
                            view_test_responses: true,
                            download_test: false,
                        },
                        {
                            id: 44,
                            label: "Manager",
                            conflict_resolution: true,
                            view_test_responses: true,
                            download_test: true,
                        },
                    ],
                        testLawId: 8,
                        stateId: state?.address?.state,
                    applyRules: "Every Independent Contractor",
                });
            }
            if (response?.status === 201 || profileRes?.status === 200) {
                
                if (response?.status === 201) {
                    
                    await postApiCall(API_CONSTANTS.CREATE_USER, {
                        ...state,
                        client_id: response?.data?.result?.id,
                        role_id: 3,
                        contracting_business_manager: currentUser?.id || "",
                        c2c: null,
                        onboarded_under: 1,
                        details: {
                            language: state?.language,
                            collar_id: "1",
                            employment_type: "1",
                            job_location: "1",
                            onboard_representative: false,
                            sign_on_behalf: false,
                            party_details: {},
                        },
                        compliance_service: state?.details?.services,
                    });
                }
                if (customerData?.listViewLimit > customerData?.total) {
                    return dispatch({
                        type: CREATE_CLIENT,
                        payload: {
                            ...response?.data?.result,
                            status: "active",
                            users_count: 1,
                            logo: profileRes?.data?.result?.logo,
                            favicon: profileRes?.data?.result?.favicon,
                        },
                    });
                } else return true;
            } else {
                return false;
            }
        } catch (error) {
            throw error;
        }
    };
};

export const uploadClientLogo = (clientId, state) => {
    return async () => {
        try {
            return await putApiCall(
                API_CONSTANTS.CLIENTS + `/${clientId}/update`,
                state
            );
        } catch (error) {
            throw error;
        }
    };
};

export const setSelectedCustomer = (data) => {
    return async (dispatch) => {
        dispatch({ type: SET_SELECTED_CUSTOMER, payload: data });
    };
};

export const getCustomerById = (id, type = null) => {
    return async (dispatch) => {
        try {
            const response = await getApiCall(API_CONSTANTS.CLIENTS + `/${id}`);
            dispatch({
                type: SET_SELECTED_CUSTOMER,
                payload:
                    response?.status === 200
                        ? { mode: type, ...response?.data?.result }
                        : null,
            });
        } catch (error) {
            throw error;
        }
    };
};

export const deleteCustomerById = (data) => {
    return async (dispatch) => {
        try {
            const response = await deleteApiCall(
                API_CONSTANTS.CLIENTS + `/${data.id}`
            );
            if (response?.status === 200) {
                dispatch({
                    type: CHANGE_STATUS_BY_ID,
                    payload: {
                        ...data,
                        status:
                            data?.status === "active" ? "inactive" : "active",
                    },
                });
            }
        } catch (error) {
            throw error;
        }
    };
};

export const updateCustomerById = (data) => {
    return async (dispatch) => {
        try {
            const response = await putApiCall(
                API_CONSTANTS.CLIENTS + `/${data?.id}`,
                data
            );
            let profileRes;
            if (data?.logo?.filename || data?.favicon?.filename) {
                profileRes = await dispatch(
                    uploadClientLogo(response?.data?.result?.id, {
                        logo: data.logo,
                        favicon: data.favicon,
                    })
                );
            }
            if (response?.status === 200 || profileRes?.status === 200) {
                return dispatch({
                    type: UPDATE_CUSTOMER_BY_ID,
                    payload: {
                        ...response?.data?.result,
                        logo: profileRes?.data?.result?.logo || data.logo,
                        favicon:
                            profileRes?.data?.result?.favicon || data.favicon,
                    },
                });
            } else {
                return;
            }
        } catch (error) {
            throw error;
        }
    };
};

export const changeCustomerViewType = (data) => {
    return async (dispatch) => {
        dispatch({ type: CHANGE_CUSTOMER_VIEW_TYPE, payload: data });
    };
};

export const updateNoOfUser = (client_id) => {
    return async (dispatch) => {
        dispatch({ type: UPDATE_NO_OF_USER, payload: client_id });
    };
};

export const getCustomerHierarchy = (id) => {
    return async (dispatch) => {
        try {
            const response = await getApiCall(
                API_CONSTANTS.CUSTOMER_HIERARCHY + `/${id}`
            );
            dispatch({
                type: GET_CUSTOMER_HIERARCHY,
                payload: response?.data?.result,
            });
        } catch (error) {
            throw error;
        }
    };
};

export const getUsersByCustomerId = (id) => {
    return async (dispatch) => {
        try {
            const response = await getApiCall(
                `/users${id ? "?client_id=" + id : ""}`
            );
            dispatch({
                type: GET_USERS_BY_CUSTOMER_ID,
                payload: response?.data?.result || [],
            });
        } catch (error) {
            throw error;
        }
    };
};

export const setCustomerPageLimit = (data) => {
    return async (dispatch) => {
        try {
            dispatch({ type: SET_CUSTOMER_PER_PAGE, payload: data });
        } catch (error) {
            throw error;
        }
    };
};

export const setCurrentPage = (pageNumber) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: SET_CURRENT_PAGE,
                payload: pageNumber,
            });
        } catch (error) {
            throw error;
        }
    };
};
