import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SearchBar from "../components/SearchBar";
import ButtonComponent from "../components/ButtonComponent";
import "../../assets/styles/Container/PageHeader.scss";

const PageHeader = (props) => {
    const {
        title,
        buttonTitle,
        showSearchBar,
        buttonNavigateUrl,
        handleAdd,
        subTitle,
        variant,
        handleSearch,
        setSearch,
        sx,
        customizeIcon,
    } = props;
    const navigate = useNavigate();

    return (
        <Box className="headerBox" sx={sx}>
            <Grid container className="headerGrid">
                <Grid item xs={2} md={3} lg={3} display="flex">
                    <Typography className="font_24_600">{title}</Typography>
                    {subTitle ? (
                        <Typography className="font_20_600">
                            {"(" + subTitle + " Users)"}
                        </Typography>
                    ) : null}
                </Grid>
                <Grid item md={6} lg={6} className="searchBar">
                    {showSearchBar && (
                        <SearchBar
                            handleSearch={handleSearch}
                            setSearch={setSearch}
                        />
                    )}
                </Grid>
                <Grid
                    item
                    sm={12}
                    md={3}
                    lg={3}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"flex-end"}
                >
                    {buttonTitle && (
                        <>
                        <ButtonComponent
                            className="addButton"
                            variant={variant || "contained"}
                            onClick={() =>
                                buttonNavigateUrl
                                    ? navigate(buttonNavigateUrl)
                                    : handleAdd()
                            }
                            title={buttonTitle}
                            startIcon={
                                customizeIcon ? (
                                    customizeIcon
                                ) : (
                                    <AddCircleOutlineIcon />
                                )
                            }
                        />
                        </>
                    )}
                </Grid>
            </Grid>
        </Box>
    );
};

export default PageHeader;
