import { getApiCall, postApiCall } from "../../utils/Action";
import { API_CONSTANTS } from "../../config/ApiConstants";
export const ASSIGN_USER_ACTION = "ASSIGN_USER_ACTION";
export const CONDUCTOR_TYPE_ACTION = "CONDUCTOR_LIST_ACTION";
export const CONDUCTOR_ACTION = "CONDUCTOR_ACTION";
export const ALL_CONDUCTOR_ACTION = "ALL_CONDUCTOR_ACTION";
export const SELECTED_CONTRACTORS_ACTION = "SELECTED_CONTRACTORS_ACTION";
export const ASSIGN_CONTRACTORS_ACTION = "ASSIGN_CONTRACTORS_ACTION";
export const ASSIGN_COMP_SERVICE = "ASSIGN_COMP_SERVICE";
export const SET_CONDUCTOR_ACTION = "SET_CONDUCTOR_ACTION";
export const GET_ASSESMENT_USER_LIST = " GET_ASSESMENT_USER_LIST";
export const GET_WORKERS_PROGESS_LIST = "GET_WORKERS_PROGESS_LIST";
export const GET_CUSTOMER_FOR_COMPLIANCE = "GET_CUSTOMER_FOR_COMPLIANCE";

export const conductorTypeAction = (type) => {
    return async (dispatch) => {
        try {
            const response = await getApiCall(
                `/list-conductors-by-type/${type}`
            );
            if (response?.status === 200) {
                return dispatch({
                    type: CONDUCTOR_TYPE_ACTION,
                    payload: response.data.result,
                });
            }
        } catch (error) {
            throw error;
        }
    };
};
export const conductorAction = (id) => {
    return async (dispatch) => {
        try {
            const response = await getApiCall(
                `/list-compliance-services/${id}`
            );
            if (response?.status === 200) {
                return dispatch({
                    type: CONDUCTOR_ACTION,
                    payload: response.data.result,
                });
            }
        } catch (error) {
            throw error;
        }
    };
};

export const assignUserAction = (client_id, service, status) => {
    return async (dispatch) => {
        try {
            const response = await postApiCall("/list-contractors", {
                client_id: client_id,
                service: service,
                status: status,
            });
            dispatch({
                type: ASSIGN_USER_ACTION,
                payload: response?.data?.result || [],
            });
        } catch (error) {
            throw error;
        }
    };
};

export const getAllContractorList = (client_id, service) => {
    return async (dispatch) => {
        try {
            const response = await postApiCall("/list-all-contractors", {
                client_id: client_id,
                service: service,
            });
            dispatch({
                type: ASSIGN_USER_ACTION,
                payload: response?.data?.result || [],
            });
        } catch (error) {
            throw error;
        }
    };
};

export const selectedContractorsAction = (data) => {
    return async (dispatch) => {
        try {
            return dispatch({
                type: SELECTED_CONTRACTORS_ACTION,
                payload: data || [],
            });
        } catch (error) {
            throw error;
        }
    };
};

export const assignContractorsAction = (data) => {
    return async (dispatch) => {
        try {
            const response = await postApiCall(
                API_CONSTANTS.ASSIGN_CONTRACTORS,
                data
            );
            if (response?.status === 200) {
                return dispatch({
                    type: ASSIGN_CONTRACTORS_ACTION,
                    payload: [],
                });
            }
        } catch (error) {
            throw error;
        }
    };
};

export const assignSelectService = (service) => {
    return async (dispatch) => {
        try {
            return dispatch({
                type: ASSIGN_COMP_SERVICE,
                payload: service,
            });
        } catch (error) {
            throw error;
        }
    };
};

export const getAssesmentUserList = (id = null) => {
    return async (dispatch, getState) => {
        try {
            let response;
            const currentState = getState();
            const loggedInClient =
                currentState?.auth?.userLoggedIn?.user?.client_id;
            response = await getApiCall(
                `/users${id ? "?client_id=" + id : loggedInClient} `
            );
            dispatch({
                type: GET_ASSESMENT_USER_LIST,
                payload: response?.data?.result || [],
            });
        } catch (error) {
            throw error;
        }
    };
};

export const getWorkersProgressList = (clientId, service) => {
    return async (dispatch) => {
        try {
            const res = await getApiCall(
                API_CONSTANTS.GET_WORKERS_LIST + "/" + clientId + "/" + service
            );
            console.log("res", res);
            return dispatch({
                type: GET_WORKERS_PROGESS_LIST,
                payload: res?.data?.result,
            });
        } catch (error) {
            throw error;
        }
    };
};

export const getCustomerForCompliance = (id) => {
    return async (dispatch) => {
        try {
            const response = await getApiCall(API_CONSTANTS.CLIENTS + `/${id}`);
            dispatch({
                type: GET_CUSTOMER_FOR_COMPLIANCE,
                payload: response?.data?.result,
            });
        } catch (error) {
            throw error;
        }
    };
};
