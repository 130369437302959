import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Box from "@mui/system/Box";
import ButtonComponent from "../../../../../components/ButtonComponent";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import toast from "react-hot-toast";
import TextFieldComponent from "../../../../../components/TextField/TextFieldComponent";
import Typography from "@mui/material/Typography";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import validator from "validator";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import TooltipComponent from "../../../../../components/ToolTip/TooltipComponent";
import {
    saveContractorEmail,
    getContractorEmailDetail,
    updateContractorEmailDetail,
    sendContractorEmail,
} from "../../../../../store/Action/ContractorEmailAction";
import SunEditorComponent from "../../../../../components/SunEditorComponent";

const checkValidation = (data) => {
    if (!data) return false;
    else return validator.isEmail(data);
};
const ErrorTooltip = (props) => {
    const { data  } = props;
    return (
        <>
            {checkValidation(data) ? (
                ""
            ) : (
                <TooltipComponent
                    className=""
                    title={<Typography>Incorrect Email</Typography>}
                    placement="right"
                    arrow
                >
                    <InfoOutlinedIcon sx={{ color: "#E05D5D" }} />
                </TooltipComponent>
            )}
        </>
    );
};

const EmailSection = (props) => {
    const {
        emailId,
        saveContractorEmail,
        updateContractorEmailDetail,
        emailTemplateList,
        commonData,
        sendContractorEmail,
        setState,
        userLoggedIn
    } = props;
    const [form, setForm] = useState({
        saveTemplateId: "",
        from_address: "",
        to_address: "",
        cc_address: "",
        bcc_address: "",
        email_subject: "",
        template_content: "",
        template_name: "",
        contractor_id: commonData.contractor_id,
        client_id: commonData.client_id,
        id: null,
        editorContent: null,
        focused: false,
    });
    const {
        from_address,
        to_address,
        cc_address,
        bcc_address,
        email_subject,
        template_content,
        template_name,
        editorContent,
        focused,
        contractor_id,
        id,
    } = form;
    const emailTemplateData = emailTemplateList?.find(
        (obj) => obj.id === emailId
    );
    useEffect(() => {
        if (emailId) {
            setForm((prevState) => ({
                ...prevState,
                template_name: emailTemplateData?.template_name,
                email_subject: emailTemplateData?.email_subject,
                editorContent: emailTemplateData?.template_content,
                id: emailTemplateData?.id,
                from_address: emailTemplateData?.from_address,
                to_address: emailTemplateData?.to_address,
                cc_address: emailTemplateData?.cc_address,
                bcc_address: emailTemplateData?.bcc_address,
            }));
        }
    }, [emailId]);

    const onBlur = () => {
        if (template_name != "") {
            setForm((prevState) => ({ ...prevState, focused: true }));
        }
    };
    const editor = useRef();
    // The sunEditor parameter will be set to the core suneditor instance when this function is called
    const getSunEditorInstance = (sunEditor) => {
        editor.current = sunEditor;
    };
    const handleChange = (content) => {
        setForm((prevState) => ({
            ...prevState,
            editorContent: content,
            template_content: content,
        }));
    };
    const handlerChages = (e) => {
        setForm((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const res = await sendContractorEmail(form);
        if (res?.payload) {
            setState((prevState) => ({
                ...prevState,
                content: res.payload.message,
            }));
            setForm((prevState) => ({
                ...prevState,
                template_name: "",
                email_subject: "",
                template_content: "",
                editorContent: "",
                from_address: "",
                to_address: "",
                cc_address: "",
                bcc_address: "",
            }));
            setState((prevState) => ({
                ...prevState,
                openSuccess: true,
                emailId: null,
            }));
        } else {
            setState((prevState) => ({
                ...prevState,
                content: "Something Went Wrong",
                openSuccess: true,
            }));
        }
    };
    const savaTemplateAPi = async (e) => {
        e.preventDefault();
        if (
            template_name != "" &&
            email_subject != "" &&
            template_content != ""
        ) {
            if (
                (from_address ? checkValidation(from_address) : true) &&
                (to_address ? checkValidation(to_address) : true) &&
                (cc_address ? checkValidation(cc_address) : true) &&
                (bcc_address ? checkValidation(bcc_address) : true)
            ) {
                const res = await saveContractorEmail(form);
                if (res?.payload) {
                    setForm((prevState) => ({
                        ...prevState,
                        saveTemplateId: res.payload.result,
                    }));
                    setState((prevState) => ({
                        ...prevState,
                        content: res.payload.message,
                    }));
                    setForm((prevState) => ({
                        ...prevState,
                        template_name: "",
                        email_subject: "",
                        template_content: "",
                        editorContent: "",
                        from_address: "",
                        to_address: "",
                        cc_address: "",
                        bcc_address: "",
                    }));
                    setState((prevState) => ({
                        ...prevState,
                        openSuccess: true,
                    }));
                } else {
                    setState((prevState) => ({
                        ...prevState,
                        content: "Something Went Wrong",
                        openSuccess: true,
                    }));
                }
            } else {
                toast.error("Please Enter Valid Email Address.");
            }
        } else {
            toast.error("Please Enter Required Input.");
        }
    };
    const handlerTempalateName = () => {
        setForm((prevState) => ({ ...prevState, focused: false }));
    };
    const UpdateEmailTemplate = async () => {
        if (
            (from_address ? checkValidation(from_address) : true) &&
            (to_address ? checkValidation(to_address) : true) &&
            (cc_address ? checkValidation(cc_address) : true) &&
            (bcc_address ? checkValidation(bcc_address) : true)
        ) {
            const res = await updateContractorEmailDetail(form);
            if (res?.payload) {
                setState((prevState) => ({
                    ...prevState,
                    content: res.payload.message,
                }));
                setForm((prevState) => ({
                    ...prevState,
                    from_address: "",
                    to_address: "",
                    cc_address: "",
                    bcc_address: "",
                    email_subject: "",
                    template_content: "",
                    template_name: "",
                    editorContent: "",
                }));
                setState((prevState) => ({
                    ...prevState,
                    openSuccess: true,
                    emailId: null,
                }));
            } else {
                setState((prevState) => ({
                    ...prevState,
                    content: "Something Went Wrong",
                    openSuccess: true,
                }));
            }
        } else {
            toast.error("Please Enter Valid Email Address.");
        }
    };
    const handlerCancelOnClick = () => {
        setForm((prevState) => ({
            ...prevState,
            from_address: "",
            to_address: "",
            cc_address: "",
            bcc_address: "",
            email_subject: "",
            template_content: "",
            template_name: "",
            editorContent: "",
        }));
        setState((prevState) => ({ ...prevState, emailId: null }));
    };

    return (
        <>
            <Box className={"emailSection"}>
                <Box
                    sx={{
                        py: 2,
                        gap: "5px",
                    }}
                    paddingX={3}
                    className={"emailHeadSection"}
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                gap: 10,
                            }}
                        >
                            <TextFieldComponent
                                sx={{
                                    display: focused ? "none" : "block",
                                }}
                                variant="outlined"
                                className="emailSectionTextField"
                                size="small"
                                name="template_name"
                                value={template_name}
                                onBlur={onBlur}
                                onChange={handlerChages}
                                placeholder="Enter Template Name"
                                inputProps={{ maxLength: 35 }}
                                id="emailSectionsTemplateName"
                            />
                            <Typography
                                display={focused ? "block" : "none"}
                                className="email_editor_heading"
                            >
                                {template_name}
                            </Typography>
                            <IconButton onClick={handlerTempalateName}>
                                <EditOutlinedIcon className="icon" />
                            </IconButton>
                        </div>
                        {emailId ? (
                            <ButtonComponent
                                className="button_contained"
                                onClick={UpdateEmailTemplate}
                                title={userLoggedIn?.language_data?.emailtemplates?.update_template || " Update Template"}
                            />
                        ) : (
                            <ButtonComponent
                                className="button_contained"
                                onClick={savaTemplateAPi}
                                title={userLoggedIn?.language_data?.emailtemplates?.save_template || "Save Template"}
                            />
                        )}
                    </div>
                    <Grid container display={"flex"} mt={1} spacing={1}>
                        <Grid
                            item
                            xs={12}
                            display={"flex"}
                            gap={3}
                            alignItems={"center"}
                        >
                            <Grid item xs={1}>
                                <Typography className={"emailTextFieldLabel"}>
                                    {userLoggedIn?.language_data?.emailtemplates?.from || "From"}:
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={10}
                                md={8}
                                xl={8}
                                display={"flex"}
                                gap={1}
                                alignItems={"center"}
                            >
                                <TextFieldComponent
                                    id="emailSectionsFromAddress"
                                    name="from_address"
                                    value={from_address}
                                    onChange={handlerChages}
                                    fullWidth
                                    size="small"
                                    className="emailSectionTextField"
                                    placeholder="contractor@example.com"
                                    variant="outlined"
                                    sx={{
                                        "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
                                            {
                                                border: "1.5px solid #E05D5D",
                                            },
                                    }}
                                    error={
                                        from_address
                                            ? !checkValidation(from_address)
                                            : false
                                    }
                                />
                            </Grid>
                            <Grid item xs={1} md={1} xl={1}>
                                {from_address ? (
                                    <ErrorTooltip data={from_address} />
                                ) : (
                                    ""
                                )}
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            display={"flex"}
                            gap={3}
                            alignItems={"center"}
                        >
                            <Grid item xs={1}>
                                <Typography className={"emailTextFieldLabel"}>
                                    {userLoggedIn?.language_data?.emailtemplates?.to || "TO"}:
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={11}
                                md={8}
                                xl={8}
                                display={"flex"}
                                gap={1}
                                alignItems={"center"}
                            >
                                <TextFieldComponent
                                    fullWidth
                                    className={"emailSectionTextField"}
                                    id="emailSectionsToAddress"
                                    name="to_address"
                                    value={to_address}
                                    onChange={handlerChages}
                                    size="small"
                                    placeholder="john@example.com"
                                    variant="outlined"
                                    sx={{
                                        "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
                                            {
                                                border: "1.5px solid #E05D5D",
                                            },
                                    }}
                                    error={
                                        to_address
                                            ? !checkValidation(to_address)
                                            : false
                                    }
                                />
                            </Grid>
                            <Grid item xs={1} md={1} xl={1}>
                                {to_address ? (
                                    <ErrorTooltip data={to_address} />
                                ) : (
                                    ""
                                )}
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            display={"flex"}
                            gap={3}
                            alignItems={"center"}
                        >
                            <Grid item xs={1}>
                                <Typography className={"emailTextFieldLabel"}>
                                    Cc:
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={11}
                                md={8}
                                xl={8}
                                display={"flex"}
                                gap={1}
                                alignItems={"center"}
                            >
                                <TextFieldComponent
                                    fullWidth
                                    className={` ${
                                        !checkValidation(cc_address)
                                            ? "emailSectionTextField"
                                            : "emailError"
                                    }`}
                                    id="emailSectionsCcAddress"
                                    name="cc_address"
                                    value={cc_address}
                                    onChange={handlerChages}
                                    size="small"
                                    placeholder="john@example.com"
                                    variant="outlined"
                                    error={
                                        cc_address
                                            ? !checkValidation(cc_address)
                                            : false
                                    }
                                />
                            </Grid>
                            <Grid item xs={1} md={1} xl={1}>
                                {cc_address ? (
                                    <ErrorTooltip data={cc_address} />
                                ) : (
                                    ""
                                )}
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            display={"flex"}
                            gap={3}
                            alignItems={"center"}
                        >
                            <Grid item xs={1}>
                                <Typography className={"emailTextFieldLabel"}>
                                    Bcc:
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={11}
                                md={8}
                                xl={8}
                                display={"flex"}
                                gap={1}
                                alignItems={"center"}
                            >
                                <TextFieldComponent
                                    fullWidth
                                    className={"emailSectionTextField"}
                                    id="emailSectionsBccAddress"
                                    name="bcc_address"
                                    value={bcc_address}
                                    onChange={handlerChages}
                                    size="small"
                                    placeholder="john@example.com"
                                    variant="outlined"
                                    sx={{
                                        "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
                                            {
                                                border: "1.5px solid #E05D5D",
                                            },
                                    }}
                                    error={
                                        bcc_address
                                            ? !checkValidation(bcc_address)
                                            : false
                                    }
                                />
                            </Grid>
                            <Grid item xs={1} md={1} xl={1}>
                                {bcc_address ? (
                                    <ErrorTooltip data={bcc_address} />
                                ) : (
                                    ""
                                )}
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            display={"flex"}
                            gap={3}
                            alignItems={"center"}
                        >
                            <Grid item xs={1}>
                                <Typography className={"emailTextFieldLabel"}>
                                    {userLoggedIn?.language_data?.emailtemplates?.subject || "Subject"}:
                                </Typography>
                            </Grid>
                            <Grid item xs={11} md={8} xl={8}>
                                <TextFieldComponent
                                    fullWidth
                                    className={"emailSectionTextField"}
                                    id="emailSectionsTemplateName"
                                    size="small"
                                    placeholder="Invitation mail"
                                    variant="outlined"
                                    onChange={handlerChages}
                                    name="email_subject"
                                    value={email_subject}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
                <Box
                    className="emailSectionEditor"
                    sx={{ overflow: "auto", padding: 0 }}
                >
                    <SunEditorComponent
                        buttonList={true}
                        setDefaultStyle="font-size: 14px;"
                        width="100%"
                        height="220px"
                        getSunEditorInstance={getSunEditorInstance}
                        onChange={handleChange}
                        defaultValue={editorContent}
                        setContents={editorContent}
                    />
                </Box>
            </Box>
            <Box className="saveCancelBox">
                <ButtonComponent
                    className="button_outline"
                    sx={{ display: emailId ? "block" : "none" }}
                    onClick={handlerCancelOnClick}
                    title={"  Cancel"}
                />
                <ButtonComponent
                    className="button_contained"
                    sx={{ display: emailId ? "block" : "none" }}
                    disabled={
                        !(
                            checkValidation(to_address) &&
                            checkValidation(from_address) &&
                            (cc_address ? checkValidation(cc_address) : true) &&
                            (bcc_address ? checkValidation(bcc_address) : true)
                        )
                    }
                    title={"Send"}
                    onClick={handleSubmit}
                />
            </Box>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,
        saveTemplateId: state.saveTemplateId,
        emailTemplateList: state.contractor.emailTemplateList,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            saveContractorEmail,
            getContractorEmailDetail,
            updateContractorEmailDetail,
            sendContractorEmail,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailSection);
