import * as Actions from "../Action/ContractorEmailAction";
const initialState = {
    emailTemplateList: null,
    templateDetail: {},
    emailHistoryList: null,
    importTemplatesList: null,
};
const contractorEmailTemplateReducer = (state = initialState, action) => {
    switch (action?.type) {
        case Actions.POST_SAVE_CONTRACTOR_EMAIL: {
            return {
                ...state,
                emailTemplateList: [action.payload, ...state.emailTemplateList],
            };
        }
        case Actions.GET_CONTRACTOR_LIST: {
            return {
                ...state,
                emailTemplateList: action.payload,
            };
        }
        case Actions.GET_CONTRACTOR_TEMPLATE_DETAILS: {
            return {
                ...state,
                templateDetail: action.payload,
            };
        }
        case Actions.UPDATE_CONTRACTOR_TEMPLATE_DETAILS: {
            let updatedList = state.emailTemplateList;
            const index = state.emailTemplateList.findIndex(
                (obj) => obj.id === action.payload.data.id
            );
            if (index !== -1) updatedList[index] = action.payload.data;
            return {
                ...state,
                emailTemplateList: updatedList,
            };
        }
        case Actions.REMOVE_CONTRACTOR_EMAIL_TEMPLATES: {
            const requestedArr = action.payload.ids.template_id;
            const newArr = state.emailTemplateList.filter(
                (obj) => !requestedArr.includes(obj.id)
            );
            return {
                ...state,
                emailTemplateList: newArr,
            };
        }
        case Actions.GET_CONTRACTOR_EMAIL_HISTORY_LIST: {
            return {
                ...state,
                emailHistoryList: action.payload,
            };
        }
        case Actions.GET_CONTRACTOR_EMAIL_HISTORY_DETAIL: {
            return {
                ...state,
                templateDetail: action.payload,
            };
        }
        case Actions.SEND_CONTRACTOR_EMAIL_TEMPLATES: {
            return {
                ...state,
                emailHistoryList: [action.payload, ...state.emailHistoryList],
            };
        }
        case Actions.IMPORT_EMAIL_TEMPLATES: {
            return {
                ...state,
                emailTemplateList: [
                    ...state.emailTemplateList,
                    ...action?.payload?.result,
                ],
            };
        }
        case Actions.GET_IMPORT_TEMPLATES: {
            return {
                ...state,
                importTemplatesList: action?.payload,
            };
        }
        default: {
            return state;
        }
    }
};
export default contractorEmailTemplateReducer;
