import React from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import VerifiedIcon from "@mui/icons-material/Verified";
import ButtonComponent from "../../components/ButtonComponent";
import DialogComponent from "../../components/DialogsBox/DialogComponent";
import { ROUTES } from "../../router/RouteList";

function SubscriptionPreviewDialog(props) {
    const { state, packageList, handleClose } = props;
    const { open, selectedPlan } = state;
    const navigate = useNavigate();

    return (
        <DialogComponent
            open={open}
            handleClose={handleClose}
            className="subscriptionDialog"
            content={
                <Grid container>
                    <Grid item xs={12} display={"flex"} mx={10}>
                        <Grid
                            container
                            display={"flex"}
                            justifyContent={"center"}
                        >
                            <Typography className="subscriptionTitle1">
                                {"An exclusive overview of selected package "}
                            </Typography>
                            <Typography className="subscriptionTitle2">
                                {" CUSTOM."}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        display={"flex"}
                        justifyContent={"center"}
                        mx={6}
                    >
                        <Typography className="subscriptionMessage">
                            {
                                "You've selected the Custom Package, and included multiple features into it. The features included in the packaged are listed below with their briefs and name. Please check it once & update the package if you want to, else you’re good to go for payment."
                            }
                        </Typography>
                    </Grid>
                    <Grid item display={"flex"}>
                        <Grid container display={"flex"}>
                            {packageList.map((obj) =>
                                obj[selectedPlan] ? (
                                    <Grid item xs={4} mt={3} px={1.5}>
                                        <VerifiedIcon className="verifiedIcon" />
                                        <Typography className="selectedPackage">
                                            {obj.name}
                                        </Typography>
                                    </Grid>
                                ) : null
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            }
            action={
                <Grid
                    container
                    display={"flex"}
                    justifyContent={"flex-end"}
                    gap={2}
                    my={1}
                    mr={5}
                >
                    <ButtonComponent
                        title={"Update"}
                        variant={"outlined"}
                        onClick={() => handleClose()}
                    />
                    <ButtonComponent
                        title={"Proceed"}
                        onClick={() => navigate(ROUTES.PAYMENT_METHOD)}
                    />
                </Grid>
            }
        />
    );
}

export default SubscriptionPreviewDialog;
