import React, { useState } from "react";
import TableCell from "@mui/material/TableCell";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import TableRow from "@mui/material/TableRow";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import PersonOffOutlinedIcon from "@mui/icons-material/PersonOffOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import IconButton from "@mui/material/IconButton";
import TableComponent from "../../components/Table/TableComponent";
import TooltipComponent from "../../components/ToolTip/TooltipComponent";
import { Translate } from "react-auto-translate";
function RoleTableView(props) {
    const { roleList, handleDelete, handleView, handlerGetUsers } = props;
    const [state, setState] = useState({
        filteredData: roleList,
        sortConfig: { key: null, direction: "asc" },
    });
    const { filteredData, sortConfig } = state;

    const handleSort = (property) => {
        let sortedData = [...roleList].sort((a, b) => {
            if (property !== "created_date") {
                return sortConfig.direction === "desc"
                    ? a?.[property]?.toLowerCase() <
                      b?.[property]?.toLowerCase()
                        ? 1
                        : b?.[property]?.toLowerCase() <
                          a?.[property]?.toLowerCase()
                        ? -1
                        : 0
                    : a?.[property]?.toLowerCase() >
                      b?.[property]?.toLowerCase()
                    ? 1
                    : b?.[property]?.toLowerCase() >
                      a?.[property]?.toLowerCase()
                    ? -1
                    : 0;
            } else {
                return sortConfig.direction === "desc"
                    ? new Date(a?.[property]) - new Date(b?.[property])
                    : new Date(b?.[property]) - new Date(a?.[property]);
            }
        });
        setState((prevState) => ({
            ...prevState,
            filteredData: sortedData,
            sortConfig: {
                direction: sortConfig.direction === "desc" ? "asc" : "desc",
            },
        }));
    };

    const headerList = [
        {
            id: 1,
            name: <Translate>Role</Translate>,
            headerClass: "userTableHeading",
            accessibleKey: "name",
            rowClass: "typo4",
            headerAlign: "left",
            rowAlign: "center",
            sort: true,
            sortFunction: handleSort,
        },
        {
            id: 6,
            name: <Translate>No. of Users</Translate>,
            headerClass: "tableHeading noOfUserTableHeading",
            accessibleKey: "users_count",
            rowClass: "typo4 noOfUserTableContent",
            headerAlign: "center",
            rowAlign: "center",
            buttonType: handlerGetUsers,
            handleButton: handlerGetUsers,
            sort: false,
        },
        {
            id: 3,
            name: <Translate>Description</Translate>,
            headerClass: "userTableHeading",
            accessibleKey: "description",
            rowClass: "typo4",
            headerAlign: "center",
            rowAlign: "center",
            sort: false,
        },
    ];

    return (
        <TableComponent
            headerList={headerList}
            // serialNo={{
            //     name: <Translate>S.No.</Translate>,
            //     headerClass: "tableHeading",
            //     headerAlign: "center",
            //     rowClass: "typo4",
            //     rowAlign: "center",
            // }}
            sortConfig={sortConfig}
            extraHeaderRight={
                <TableCell
                    align="center"
                    className="actionServiceUserTableHeading userTableHeading"
                >
                    <Translate>Action</Translate>
                </TableCell>
            }
        >
            {filteredData?.length &&
                filteredData.map((row, index) => (
                    <TableRow
                        key={row.name}
                        className={index % 2 !== 0 ? "tertiaryBackground" : ""}
                    >
                        {headerList.map((obj) => (
                            <TableCell
                                component="th"
                                key={obj?.name}
                                align={obj?.rowAlign}
                                className={
                                    obj?.rowClass
                                        ? obj?.rowClass
                                        : row?.status === "active"
                                        ? "typo3"
                                        : "typo5"
                                }
                                onClick={() =>
                                    obj?.accessibleKey === "users_count"
                                        ? obj.handleButton(row, obj?.buttonType)
                                        : null
                                }
                            >
                                <Translate>{row[obj?.accessibleKey]}</Translate>
                            </TableCell>
                        ))}
                        <TableCell align="center">
                            <TooltipComponent
                                title={<Translate>View Role</Translate>}
                                arrow={true}
                            >
                                <IconButton
                                    className="iconButton"
                                    onClick={() => handleView(row, "view")}
                                >
                                    <RemoveRedEyeOutlinedIcon />
                                </IconButton>
                            </TooltipComponent>
                            <TooltipComponent
                                title={<Translate>Edit Role</Translate>}
                                arrow={true}
                            >
                                <IconButton
                                    className="iconButton"
                                    onClick={() => handleView(row, "edit")}
                                >
                                    <EditOutlinedIcon />
                                </IconButton>
                            </TooltipComponent>
                            <TooltipComponent
                                title={
                                    row?.status === "active" ? (
                                        <Translate>Deactivate Role</Translate>
                                    ) : (
                                        <Translate>Activate Role</Translate>
                                    )
                                }
                                arrow={true}
                            >
                                <IconButton
                                    className="iconButton"
                                    onClick={() => handleDelete(row)}
                                >
                                    {row?.status === "active" ? (
                                        <PersonOffOutlinedIcon />
                                    ) : (
                                        <ManageAccountsOutlinedIcon />
                                    )}
                                </IconButton>
                            </TooltipComponent>
                        </TableCell>
                    </TableRow>
                ))}
        </TableComponent>
    );
}

export default RoleTableView;
