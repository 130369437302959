import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getMqsDropdown } from "../../store/Action/MqsAction";
import TextField from "@mui/material/TextField";
import ButtonComponent from "../../components/ButtonComponent";
import AutoCompleteDropDown from "../../components/DropDown/AutoCompleteDropDown";
import {Translate } from 'react-auto-translate';
function TransferList(props) {
    const { getMqsDropdown, stateDropdown, state, setState, mode, value } =
        props;
    const [leftSelected, setleftSelected] = useState([]);
    const [rightSelected, setrightSelected] = useState([]);
    const [item, setItem] = useState([]);
    const [itemss, setItemss] = useState(null);

    useEffect(() => {
        if (stateDropdown === null) getMqsDropdown();
    }, [getMqsDropdown, stateDropdown]);

    useEffect(() => {
        if (stateDropdown?.states?.length > 0 && itemss === null) {
            setItemss(stateDropdown?.states);
        }
    }, [stateDropdown?.states, itemss]);
    const handleToggle = (value, selectAll, transferType) => {
        if (transferType === "leftToRight") {
            if (selectAll === 0) {
                const data = itemss.filter((item) => {
                    return !leftSelected.includes(item);
                });

                setItem([...leftSelected, ...item]);
                setleftSelected([]);
                setItemss(data);
            } else {
                setItemss([]);
                setItem([...item, ...itemss]);
            }
        } else {
            if (selectAll === 0) {
                const data = item.filter((items) => {
                    return !rightSelected.includes(items);
                });
                setItemss([...rightSelected, ...itemss]);
                setrightSelected([]);
                setItem(data);
            } else {
                setItem([]);
                setItemss([...itemss, ...item]);
            }
        }
    };
    useEffect(() => {}, [leftSelected]);
    const handleonchange = (i) => {
        setleftSelected([...i]);
        const leftSelect = i?.map((item) => {
            return {
                id: item?.id,
                label: item?.label,
            };
        });
        setState((prevState) => ({
            ...prevState,
            jurisdiction: [...leftSelect],
        }));
    };
    return (
        <Grid container ml={2}>
        <Grid item md={4.5} xs={11.7} className="leftAuto">
            <AutoCompleteDropDown
                options={itemss || []}
                open={true}
                value={mode === "edit" ? value : leftSelected}
                onChange={(event, i) => {
                    handleonchange(i);
                }}
                multiple
                renderInput={(params) => (
                    <TextField
                        {...params}
                        placeholder={<Translate>Search state</Translate>}
                        value={state?.jurisdiction}
                    />
                )}
            />
        </Grid>
        <Grid item md={2} xs={11.7} mt={11} className="shiftButton">
            <ButtonComponent
                title={<Translate> ≫</Translate>}
                disabled={itemss?.length === 0}
                sx={{ my: 0.5, color: "black", fontSize: "20px" }}
                size="large"
                onClick={() => handleToggle("", 1, "leftToRight")}
                ariaLabel="move all right"
            />
            <ButtonComponent
                title={<Translate> &gt;</Translate>}
                sx={{
                    my: 0.5,
                    color: "black",
                    fontSize: "20px",
                    fontWeight: "200",
                }}
                size="large"
                onClick={() => handleToggle("", 0, "leftToRight")}
                disabled={itemss?.length === 0}
                ariaLabel="move selected right"
            />
            <ButtonComponent
                title={<Translate> &lt;</Translate>}
                sx={{
                    my: 0.5,
                    color: "black",
                    fontSize: "20px",
                    fontWeight: "200",
                }}
                size="large"
                onClick={() => handleToggle("", 0, "rightToleft")}
                disabled={item?.length === 0}
                ariaLabel="move selected left"
            />
            <ButtonComponent
                title={<Translate> ≪</Translate>}
                sx={{ my: 0.5, color: "black", fontSize: "20px" }}
                size="large"
                onClick={() => handleToggle("", 1, "rightToLeft")}
                disabled={item?.length === 0}
                ariaLabel="move all left"
            />
        </Grid>
        <Grid item md={4.5} xs={11.7} className="rightAuto">
            <AutoCompleteDropDown
                options={item || []}
                open={true}
                value={rightSelected}
                onChange={(event, i) => {
                    setrightSelected([...i]);
                }}
                multiple
                renderInput={(params) => (
                    <TextField {...params} placeholder={<Translate>Search state</Translate>} />
                )}
            />
        </Grid>
    </Grid>
    
    );
}
const mapStateToProps = (state) => {
    return {
        stateDropdown: state.mqs?.stateDropdown,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getMqsDropdown,
        },
        dispatch
    );
};
export default connect(mapStateToProps, mapDispatchToProps)(TransferList);
