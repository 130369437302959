import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Grid } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { getAssessmentSetting } from "../../../../../store/Action/AssessmentSettingAction";
import {
    getSelfConfigrationList,
    saveSelfConfigration,
} from "../../../../../store/Action/SelfConfigurationAction";
import {
    saveDynamicTest,
    getDynamicTestDetailsList,
    editDynamicTest,
} from "../../../../../store/Action/DynamicTestAction";
import { getMqsQuestion } from "../../../../../store/Action/MqsAction";
import ButtonComponent from "../../../../../components/ButtonComponent";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CommonDeleteDialog from "../../../../../components/DialogsBox/CommonDeleteDialog";
import DialogComponent from "../../../../../components/DialogsBox/DialogComponent";
import NoInfoAvailable from "../../../../../components/NoInfoAvailable";
import TestCommanSection from "./DefaultTestTables/TestCommanSection";

function DynamicTest(props) {
    const {
        testCategory,
        getMqsQuestion,
        saveDynamicTest,
        stateMainAgreement,
        setStateMainAgreement,
        deleteTest,
        list,
        guid,
        getSelfConfigration,
        editDynamicTest,
    } = props;

    const [state, setState] = useState({
        isEditId: null,
        isEdit: false,
        isView: false,
        isCreate: true,
        createType: false,
        setestedTest: null,
        test_id: null,
        testName: null,
        applicable_laws: null,
        test_type: null,
        testType: 1,
        jurisdiction: null,
        category_setting_type: null,
        category_settings_list: [],
        recurring_test: 1,
        recurring_test_time: 7,
        notification_setting: {
            email_notification: true,
            system_notification: true,
        },
        role_setting: [],
        tests: [],
        max_time: 15,
        max_attempt: 2,
        timeDuration: 20,
        delete: false,
        deletedData: null,
        category_settings: [],
    });
    const { deletedData } = state;

    useEffect(() => {
        getMqsQuestion();
    }, [testCategory?.length]);
    useEffect(() => {
        if (list) {
            const testData = getSelfConfigration?.setting?.filter((item) => {
                return item.test_id === list?.test_id;
            });
            setState((prevState) => ({
                ...prevState,
                isEditId: list?.id,
                isView: true,
                isCreate: false,
                role_setting: list?.role_setting,
                max_time: list?.max_time,
                max_attempt: list?.max_attempt,
                timeDuration: list?.timeDuration,
                category_settings: list?.category_settings,
                category_settings_list: list?.category_settings,
                category_setting_type: list?.category_setting_type,
                test_id: list?.test_id,
                applicable_laws: list?.applicable_laws,
                jurisdiction: list?.jurisdiction,
                setestedTest: testData,
                test_type: list?.test_type,
                notification_setting: list?.notification_setting,
            }));
        }
    }, [list]);
    const handleTableDataState = (key, data) => {
        setState((prevState) => ({
            ...prevState,
            role_setting: data,
        }));
    };
    const closeDelete = () => {
        setState((prevState) => ({ ...prevState, delete: false }));
    };
    const handleDelete = (data) => {
        setState((prevState) => ({
            ...prevState,
            delete: true,
            deletedData: data,
        }));
    };
    const handleDeleteAction = async (data) => {
        setState((prevState) => ({ ...prevState, isLoading: true }));
        closeDelete();
        await deleteTest(data.id, guid);
        setState((prevState) => ({ ...prevState, isLoading: false }));
    };

    const handleCreateTest = async () => {
        setStateMainAgreement((prevState) => ({
            ...prevState,
            isLoading: true,
        }));
        const data = {
            guid: guid,
            test_id: state.test_id,
            test_type: state.test_type,
            applicable_laws: state.applicable_laws,
            jurisdiction: state.jurisdiction,
            category_setting_type: state.category_setting_type,
            category_settings: state.category_settings,
            recurring_test: state.recurring_test,
            recurring_test_time: state.recurring_test_time,
            notification_setting: state.notification_setting,
            role_setting: state?.role_setting,
            max_attempt: state?.max_attempt,
            timeDuration: state?.timeDuration,
        };
        await saveDynamicTest(data);
        setState({
            ...state,
            createType: !state.createType,
        });
        setStateMainAgreement((prevState) => ({
            ...prevState,
            isLoading: false,
        }));
    };
    const handleEditTest = async () => {
        setStateMainAgreement((prevState) => ({
            ...prevState,
            isLoading: true,
        }));
        const data = {
            guid: guid,
            id: state?.isEditId,
            test_id: state.test_id,
            test_type: state.test_type,
            applicable_laws: state.applicable_laws,
            jurisdiction: state.jurisdiction,
            category_setting_type: state.category_setting_type,
            category_settings: state.category_settings,
            recurring_test: state.recurring_test ? 1 : 0,
            recurring_test_time: state.recurring_test_time,
            notification_setting: state.notification_setting,
            role_setting: state?.role_setting,
            max_attempt: state?.max_attempt,
            timeDuration: state?.timeDuration,
        };
        await editDynamicTest(data);
        setState((prevState) => ({
            ...prevState,
            createType: !state.createType,
        }));
        setStateMainAgreement((prevState) => ({
            ...prevState,
            isLoading: false,
        }));
    };

    const addNumberOfValue = (event, categoryId) => {
        let newCategoryData = state.category_settings;
        let categoryIndex = newCategoryData?.findIndex(
            (obj) => obj.categoryId === categoryId
        );
        if (categoryIndex === -1) {
            newCategoryData.push({
                category_type: "Balancing",
                categoryId: categoryId,
                [event.target.name]: event.target.value,
            });
        } else {
            newCategoryData[categoryIndex] = {
                ...newCategoryData[categoryIndex],
                [event.target.name]: event.target.value,
            };
        }
        setState((prevState) => ({
            ...prevState,
            category_settings: newCategoryData,
        }));
    };

    const handleCreateType = () => {
        setState({
            ...state,
            isCreate: false,
            isEdit: true,
            isView: true,
        });
    };
    const handleCancel = () => {
        if (list) {
            setState({
                ...state,
                isCreate: false,
                isEdit: false,
                isView: true,
            });
        } else {
            setState({
                ...state,
                isCreate: true,
                isEdit: false,
                isView: false,
            });
        }
    };
    const handleEditType = () => {
        setState((prevState) => ({
            ...prevState,
            isView: true,
            isEdit: true,
        }));
    };
    return (
        <>
            <CommonDeleteDialog
                open={state?.delete}
                title={" Delete Email"}
                cancelButtonAction={closeDelete}
                cancelButtonTitle="Cancel"
                submitButtonTitle={"Delete"}
                content={`Are you sure, you want to remove the (${deletedData?.test_name}) template from the Emails?`}
                submitButtonAction={handleDeleteAction}
                submitButtonParameter={deletedData}
            />
            <Grid container spacing={2}>
                <Grid item md={12} lg={12} textAlign="end">
                    {state.isCreate && (
                        <ButtonComponent
                            title={"Create Test"}
                            startIcon={<AddCircleOutlineIcon />}
                            onClick={handleCreateType}
                        />
                    )}
                    {state.isView && (
                        <ButtonComponent
                            title={state.isEdit ? "Cancel" : "Edit Test"}
                            startIcon={
                                state.isEdit ? null : <EditOutlinedIcon />
                            }
                            onClick={
                                state.isEdit ? handleCancel : handleEditType
                            }
                        />
                    )}
                </Grid>
                {state.isView ? (
                    <>
                        <TestCommanSection
                            stateTest={state}
                            setStateTest={setState}
                            stateMainAgreement={stateMainAgreement}
                            setStateMainAgreement={setStateMainAgreement}
                            getSelfConfigration={getSelfConfigration}
                            addNumberOfValue={addNumberOfValue}
                            handleTableDataState={handleTableDataState}
                            testCategory={testCategory}
                            handleEditTest={handleEditTest}
                            handleCreateTest={handleCreateTest}
                            guid={guid}
                        />
                    </>
                ) : (
                    <NoInfoAvailable
                        className="noCustomerInfo"
                        noInfoTypo="noInfoTypeQuestion"
                    />
                )}
            </Grid>
        </>
    );
}
const mapStateToProps = (state) => {
    return {
        getSelfConfigration:
            state?.selfConfigrationList?.selfConfigrationDetails,
        testCategory: state?.mqs?.mqsQuestion,
        list: state?.DynamicTestReducer?.list,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getSelfConfigrationList,
            getAssessmentSetting,
            getMqsQuestion,
            saveSelfConfigration,
            saveDynamicTest,
            getDynamicTestDetailsList,
            editDynamicTest,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(DynamicTest);
