import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, Typography, Grid } from "@mui/material";
import { bindActionCreators } from "redux";
import Divider from "@mui/material/Divider";
import { useNavigate } from "react-router-dom";
import Acknowledge from "../Acknowledge";
import Ppbconfirm from "../Ppbconfirm";
import { setSelectedCustomer } from "../../../../store/Action/CustomerAction";
import { assignSelectService } from "../../../../store/Action/ComplianceWorkflowAction";
import {
    getAssessmentSummary,
    testOverViewData,
} from "../../../../store/Action/AssessmentSettingAction";
import DialogComponent from "../../../../components/DialogsBox/DialogComponent";
import ButtonComponent from "../../../../components/ButtonComponent";
import AccordionCustom from "../../../../components/AccordionCustom";
import "../../../../../assets/styles/Container/RcpCustomerInformation.scss";
import { ROUTES } from "../../../../router/RouteList";
import { getConflictResponses } from "../../../../store/Action/MqsAction";
import TestingTable from "../FooterComponents/AuditDefenseReport/TestingTable";
import { Translate } from "react-auto-translate";

const AssessmentSummary = (props) => {
    const {
        getAssessmentSummary,
        assessmentSummaryData,
        getConflictResponses,
        client_id,
        guid,
        roleId,
        setLoading,
        userLoggedIn,
        contractor_id,
        user_id,
        selectedCustomer,
        selectedService,
        testOverViewData,
        expand,
        mode,
    } = props;
    const [state, setState] = useState({
        dialogStartTest: false,
        dialogPpbStart: false,
        selectedData: null,
        showTestOverviewDialog: { open: false, title: null },
        testResultOverViewData: null,
        selectedAssessmentData: null,
    });
    const [isLoading, setIsLoading] = useState(false);
    const {
        dialogStartTest,
        selectedData,
        dialogPpbStart,
        showTestOverviewDialog,
        testResultOverViewData,
    } = state;
    useEffect(() => {
        localStorage.setItem("guid", guid);
        localStorage.setItem("user_id", user_id && user_id);
    }, [user_id]);
    useEffect(() => {
        if (user_id || contractor_id) {
            setIsLoading(true);
            getAssessmentSummary(
                client_id,
                user_id,
                roleId == 44 ? contractor_id : null,
                mode
            )
                .then(() => {
                    setIsLoading(false);
                })
                .catch((error) => {
                    setIsLoading(false);
                });
        }
    }, [client_id, getAssessmentSummary, contractor_id, roleId]);
    useEffect(() => {
        if (showTestOverviewDialog.open) {
            callActionApi();
        }
    }, [showTestOverviewDialog.open]);
    const callActionApi = async () => {
        setIsLoading(true);
        const res = await testOverViewData(state.selectedAssessmentData);
        const copyData = res?.payload;
        const filterData =
            state.selectedAssessmentData.state === "California"
                ? copyData
                : copyData.filter((obj) => obj.id !== 5);
        setState((prevState) => ({
            ...prevState,
            testResultOverViewData: filterData,
        }));
        setIsLoading(false);
    };

    const handleStartTest = (data) => {
        if (
            selectedCustomer?.company_info?.principle_place_of_business ===
                null ||
            selectedCustomer?.company_info?.principle_place_of_business === 0
        ) {
            setState((prevState) => ({
                ...prevState,
                dialogPpbStart: true,
            }));
        } else {
            setState((prevState) => ({
                ...prevState,
                dialogStartTest: true,
                selectedData: data,
            }));
        }
    };

    const handleClose = () => {
        setState((prevState) => ({
            ...prevState,
            dialogStartTest: false,
            dialogPpbStart: false,
            showTestOverviewDialog: { open: false, title: null },
        }));
    };
    const navigate = useNavigate();
    const handleViewResponse = (assessment) => {
        navigate(ROUTES.View_Response, {
            state: {
                data: {
                    guid: guid,
                    user_id: user_id,
                    test_type: assessment.default_compliance_id,
                    role_id: assessment?.role_id,
                    contractor_id: contractor_id,
                    mode: assessment?.mode || mode,
                },
            },
        });
    };
    const handleConflictResponse = async (assessment) => {
        localStorage.setItem("role_id", assessment.role_id);
        localStorage.setItem("task_id", localStorage.getItem("task_id"));
        localStorage.setItem("mode", localStorage.getItem("mode"));
        window.open(
            `${
                ROUTES.Conflict_Response +
                "/" +
                client_id +
                "/" +
                user_id +
                "/" +
                contractor_id
            }`,
            "_blank"
        );
    };
    function getContractorTestStatus(assessmentSummaryData) {
        for (const key in assessmentSummaryData) {
            if (assessmentSummaryData[key].role === "Contractor") {
                return assessmentSummaryData[key].test_status;
            }
        }
        return "Role not found";
    }
    const contractorTestStatus = getContractorTestStatus(assessmentSummaryData);
    const handleTestingOverviewDialog = async (assessment) => {
        setState((prevState) => ({
            ...prevState,
            showTestOverviewDialog: { open: true },
            selectedAssessmentData: {
                guid: guid,
                user_id: user_id,
                test_type: assessment?.default_compliance_id,
                role_id: assessment?.role_id,
                state: assessment?.state,
                contractor_id: contractor_id,
                mode: assessment?.mode,
            },
        }));
    };
    return (
        <Fragment>
            <DialogComponent
                className="dialogAssessmentSummary"
                open={showTestOverviewDialog.open}
                content={
                    isLoading ? (
                        <div
                            style={{ textAlign: "center" }}
                            className="spinner-container"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                className="spinner"
                                width="30px"
                                height="30px"
                                viewBox="0 0 100 100"
                                preserveAspectRatio="xMidYMid"
                            >
                                <circle
                                    cx="50"
                                    cy="50"
                                    fill="none"
                                    stroke="#f26a21"
                                    stroke-width="10"
                                    r="35"
                                    stroke-dasharray="164.93361431346415 56.97787143782138"
                                >
                                    <animateTransform
                                        attributeName="transform"
                                        type="rotate"
                                        repeatCount="indefinite"
                                        dur="1s"
                                        values="0 50 50;360 50 50"
                                        keyTimes="0;1"
                                    ></animateTransform>
                                </circle>
                            </svg>
                        </div>
                    ) : (
                        <TestingTable data={testResultOverViewData} />
                    )
                }
                handleClose={handleClose}
            />
            <DialogComponent
                className="dialogAssessmentSummary"
                open={dialogStartTest}
                title={<Translate>Acknowledge</Translate>}
                content={
                    <Acknowledge
                        contractor_id={contractor_id}
                        user_id={user_id}
                        testType={selectedData?.test_type}
                        selectedId={selectedData?.id}
                        assessmentSummaryData={selectedData}
                        guid={guid}
                        roleId={selectedData?.role_id}
                        setLoading={setLoading}
                        defaultTypeId={selectedData?.default_compliance_id}
                        mode={localStorage.getItem("mode") || mode}
                    />
                }
                handleClose={handleClose}
            />
            <DialogComponent
                className="dialogAssessmentSummary"
                open={dialogPpbStart}
                title={""}
                content={
                    <Ppbconfirm
                        testType={selectedData?.test_type}
                        selectedId={selectedData?.id}
                        guid={guid}
                        contractor_id={contractor_id}
                        selectedCustomer={selectedCustomer}
                        roleId={selectedData?.role_id}
                        user_id={user_id}
                        setLoading={setLoading}
                        defaultTypeId={selectedData?.default_compliance_id}
                        mode={localStorage.getItem("mode") || mode}
                    />
                }
                handleClose={handleClose}
            />
            <Grid container>
                <Grid item md={12} xs={12} mt={2}>
                    <AccordionCustom
                        title={selectedCustomer?.company_info?.name}
                        className="accordionColor"
                        detailsClassName="accordionColor"
                        expand={expand}
                    >
                        {isLoading ? (
                            <div
                                style={{ textAlign: "center" }}
                                className="spinner-container"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    className="spinner"
                                    width="30px"
                                    height="30px"
                                    viewBox="0 0 100 100"
                                    preserveAspectRatio="xMidYMid"
                                >
                                    <circle
                                        cx="50"
                                        cy="50"
                                        fill="none"
                                        stroke="#f26a21"
                                        stroke-width="10"
                                        r="35"
                                        stroke-dasharray="164.93361431346415 56.97787143782138"
                                    >
                                        <animateTransform
                                            attributeName="transform"
                                            type="rotate"
                                            repeatCount="indefinite"
                                            dur="1s"
                                            values="0 50 50;360 50 50"
                                            keyTimes="0;1"
                                        ></animateTransform>
                                    </circle>
                                </svg>
                            </div>
                        ) : (
                            assessmentSummaryData?.map((assessment, index) => (
                                <Grid>
                                    <Grid container spacing={2} mt={1}>
                                        <Grid
                                            item
                                            md={12}
                                            xs={12}
                                            className="abcCompany"
                                            display={"flex"}
                                            justifyContent={"center"}
                                        >
                                            <Typography className="abcCompany">
                                                <Translate>
                                                    {assessment?.test_name}
                                                </Translate>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} mt={1}>
                                        <Grid item md={3} xs={12}>
                                            <Typography className="roleText">
                                                <Translate>
                                                    Test Taker:
                                                </Translate>
                                            </Typography>
                                        </Grid>
                                        <Grid item md={3} xs={12}>
                                            <Typography className="managerText">
                                                <Translate>
                                                    {assessment?.role}
                                                </Translate>
                                            </Typography>
                                        </Grid>
                                        {assessment?.default_compliance_id !=
                                            4 && (
                                            <Fragment>
                                                <Grid item md={3} xs={12}>
                                                    <Typography className="roleText">
                                                        <Translate>
                                                            Applicable Law:
                                                        </Translate>
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={3} xs={12}>
                                                    <Typography className="managerText">
                                                        <Translate>
                                                            {
                                                                assessment?.applicable_laws
                                                            }
                                                        </Translate>
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={3} xs={12}>
                                                    <Typography className="roleText">
                                                        <Translate>
                                                            {userLoggedIn
                                                                ?.language_data
                                                                ?.customer
                                                                ?.jurisdiction ||
                                                                "Jurisdiction"}
                                                            :
                                                        </Translate>
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={3} xs={12}>
                                                    <Typography className="managerText">
                                                        <Translate>
                                                            {
                                                                assessment?.jurisdiction
                                                            }
                                                        </Translate>
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={3} xs={12}>
                                                    <Typography className="roleText">
                                                        <Translate>
                                                            {userLoggedIn
                                                                ?.language_data
                                                                ?.customer
                                                                ?.state ||
                                                                "State"}
                                                            :
                                                        </Translate>
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={3} xs={12}>
                                                    <Typography className="managerText">
                                                        <Translate>
                                                            {assessment?.state}
                                                        </Translate>
                                                    </Typography>
                                                </Grid>
                                            </Fragment>
                                        )}
                                        {localStorage.getItem("role_id") ==
                                        2 ? null : (
                                            <>
                                                <Grid item md={3} xs={12}>
                                                    <Typography className="roleText">
                                                        <Translate>
                                                            Test Score:
                                                        </Translate>
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={3} xs={12}>
                                                    <Typography className="managerText">
                                                        <Translate>
                                                            {`${assessment?.score}%`}
                                                        </Translate>
                                                    </Typography>
                                                </Grid>
                                            </>
                                        )}
                                        {assessment?.default_compliance_id ===
                                            4 && (
                                            <Fragment>
                                                <Grid item md={3} xs={12}>
                                                    <Typography className="roleText">
                                                        <Translate>
                                                            Minimum Passing
                                                            Score(%):
                                                        </Translate>
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={3} xs={12}>
                                                    <Typography className="managerText">
                                                        <Translate>
                                                            {`${assessment?.passing_score}%`}
                                                        </Translate>
                                                    </Typography>
                                                </Grid>
                                            </Fragment>
                                        )}
                                        {localStorage.getItem("role_id") ==
                                        2 ? null : (
                                            <>
                                                <Grid item md={3} xs={12}>
                                                    <Typography className="roleText">
                                                        <Translate>
                                                            Result:
                                                        </Translate>
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={3} xs={12}>
                                                    <Typography
                                                        className={
                                                            assessment?.result ===
                                                            "Pass"
                                                                ? "completeTextPass"
                                                                : "completeText "
                                                        }
                                                    >
                                                        <Translate>
                                                            {assessment?.result}
                                                        </Translate>
                                                    </Typography>
                                                </Grid>
                                            </>
                                        )}
                                        {localStorage.getItem("role_id") ==
                                        2 ? null : (
                                            <>
                                                <Grid item md={3} xs={12}>
                                                    <Typography className="roleText">
                                                        <Translate>
                                                            Total test attempt:
                                                        </Translate>
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={3} xs={12}>
                                                    <Typography className="managerText">
                                                        <Translate>
                                                            {
                                                                assessment?.test_attempt
                                                            }
                                                        </Translate>
                                                    </Typography>
                                                </Grid>
                                            </>
                                        )}
                                        {localStorage.getItem("role_id") ==
                                        2 ? null : (
                                            <>
                                                <Grid item md={3} xs={12}>
                                                    <Typography className="roleText">
                                                        <Translate>
                                                            Test Status:
                                                        </Translate>
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={3} xs={12}>
                                                    <Typography
                                                        className={
                                                            assessment?.test_status ===
                                                            "Completed"
                                                                ? "completeTextPass"
                                                                : "completeText "
                                                        }
                                                        style={{
                                                            color:
                                                                assessment?.test_status ===
                                                                "Completed"
                                                                    ? "green !important"
                                                                    : "inherit",
                                                        }}
                                                    >
                                                        <Translate>
                                                            {
                                                                assessment?.test_status
                                                            }
                                                        </Translate>
                                                    </Typography>
                                                </Grid>
                                            </>
                                        )}
                                    </Grid>
                                    <Grid container spacing={2} mt={1}>
                                        {localStorage.getItem("role_id") ==
                                        2 ? null : assessment?.test_status ===
                                          "Completed" ? (
                                            <Grid item md={3} xs={12}>
                                                <Link
                                                    className="linkResponse"
                                                    onClick={() =>
                                                        handleTestingOverviewDialog(
                                                            assessment
                                                        )
                                                    }
                                                >
                                                    <Translate>
                                                        Show Testing Overview
                                                    </Translate>
                                                </Link>
                                            </Grid>
                                        ) : null}
                                        {assessment?.view_test_responses ===
                                            true &&
                                        assessment?.test_status ===
                                            "Completed" ? (
                                            <Grid item md={3} xs={12}>
                                                <Link
                                                    className="linkResponse"
                                                    onClick={() =>
                                                        handleViewResponse(
                                                            assessment
                                                        )
                                                    }
                                                >
                                                    <Translate>
                                                        View Responses
                                                    </Translate>
                                                </Link>
                                            </Grid>
                                        ) : null}

                                        {/* {localStorage.getItem("role_id") ==
                                        2 ? null : assessment?.conflict_resolution ===
                                              true &&
                                          assessment?.test_status ===
                                              "Completed" ? (
                                            <>
                                                <Grid item md={3} xs={12}>
                                                    <Typography className="roleText">
                                                        <Translate>
                                                            Action Required:
                                                        </Translate>
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={3} xs={12}>
                                                    <Link
                                                        sx={{
                                                            color: "red",
                                                            textDecorationColor:
                                                                "red",
                                                        }}
                                                        className="linkResponse"
                                                        onClick={() =>
                                                            handleConflictResponse(
                                                                assessment
                                                            )
                                                        }
                                                    >
                                                        <Translate>
                                                            Resolve conflicting
                                                            response
                                                        </Translate>
                                                    </Link>
                                                </Grid>
                                            </>
                                        ) : null} */}
                                    </Grid>
                                    <Grid
                                        container
                                        my={2}
                                        spacing={2}
                                        gap={2}
                                        display={"flex"}
                                        justifyContent={"flex-end"}
                                    >
                                        {assessment?.download_test === "1" ? (
                                            <ButtonComponent
                                                title={
                                                    <Translate>
                                                        Download
                                                    </Translate>
                                                }
                                                variant="outlined"
                                            />
                                        ) : null}
                                        {assessment?.test_attempt === 0 && (
                                            <>
                                                {index === 1 &&
                                                contractorTestStatus ===
                                                    "Pending" ? null : localStorage.getItem(
                                                      "role_id"
                                                  ) == 44 &&
                                                  assessment.role_id ===
                                                      2 ? null : (
                                                    <ButtonComponent
                                                        title={
                                                            <Translate>
                                                                Start Test
                                                            </Translate>
                                                        }
                                                        onClick={() =>
                                                            handleStartTest(
                                                                assessment
                                                            )
                                                        }
                                                    />
                                                )}
                                            </>
                                        )}
                                    </Grid>
                                    <Divider
                                        variant="middle"
                                        className="dividerAssessment"
                                    />
                                </Grid>
                            ))
                        )}
                    </AccordionCustom>
                </Grid>
            </Grid>
            {/* <Grid
                container
                my={2}
                spacing={2}
                gap={2}
                display={"flex"}
                justifyContent={"flex-end"}
            >
                <ButtonComponent
                    title="Add Test"
                    variant="outlined"
                    //  onClick={handleClickCancel}
                />
                <ButtonComponent
                    title={"Update"}
                    //  onClick={handleOpen}
                />
            </Grid> */}
        </Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,
        assessmentPartiesList:
            state.assessmentPartiesList.assessmentPartiesList,
        assessmentSummaryData: state?.assessmentSettings?.assessmentSummaryData,
        client_id: state?.customer?.selectedCustomer?.id,
        selectedCustomer: state.customer.selectedCustomer,
        selectedService: state.complianceWorkflow.selectedService,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getAssessmentSummary,
            setSelectedCustomer,
            assignSelectService,
            getConflictResponses,
            testOverViewData,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(AssessmentSummary);
