import React, { useState } from "react";
import Navbar from "../../common/Header/Navbar";
import Box from "@mui/material/Box";
import PageHeader from "../../common/PageHeader";
import CommonDeleteDialog from "../../components/DialogsBox/CommonDeleteDialog";
import "../../../assets/styles/Container/WorkflowPermission.scss";
import RolesPermissionTable from "../ManageRoles/RolesPermissionTable";

function UserSettings(props) {
    const [state, setState] = useState({
        openDeleteDialog: false,
        openAddDialog: false,
        modulesData: [],
    });
    const [search, setSearch] = useState('')
    
    const { openDeleteDialog, modulesData } = state;
    const closeDelete = () => {
        setState((prevState) => ({
            ...prevState,
            openDeleteDialog: false,
        }));
    };
    const handleDelete = () => {
        setState((prevState) => ({
            ...prevState,
            openDeleteDialog: true,
        }));
    };
    const handleDeleteAction = async () => {
        closeDelete();
    };

    return (
        <>
            <Navbar />
            <CommonDeleteDialog
                open={openDeleteDialog}
                title={"Unassign User"}
                cancelButtonAction={closeDelete}
                cancelButtonTitle="Cancel"
                submitButtonTitle="Unassign"
                content={`Are you sure you want to unassign jhondoe from review task`}
                submitButtonAction={handleDeleteAction}
            />
            <Box sx={{ px: { md: 10, xs: 1, mt: "40px" } }}>
                <PageHeader
                    title={"Workflow Permission"}
                    showSearchBar={true}
                    buttonTitle={"Assign User"}
                    setSearch= {setSearch}
                />
                <RolesPermissionTable modulesData={modulesData} />
            </Box>
        </>
    );
}

export default UserSettings;
