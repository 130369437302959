import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Link from "@mui/material/Link";
import CommonDeleteDialog from "../../../../../components/DialogsBox/CommonDeleteDialog";
import WorkFlowTable from "../../../../../components/Table/WorkFlowTable/WorkFlowTable";
import { rcpTasksAddAction } from "../../../../../store/Action/RcpTasksAction";
import { rcpTasksUpdateAction } from "../../../../../store/Action/RcpTasksAction";
import { rcpTaskDeleteAction } from "../../../../../store/Action/RcpTasksAction";
import { rcpTaskAssignToUserAction } from "../../../../../store/Action/RcpTasksAction";
import SuccessfullModel from "../../../../../components/DialogsBox/SuccessfullModel";
import AddWorkFlowDialog from "../../../../../components/DialogsBox/AddWorkFlowDialog";
import WorkFlowAssignUserDialog from "../../../../../components/DialogsBox/WorkFlowAssginUserDialog";
import {Translate } from 'react-auto-translate';

const WorkFlowSettings = (props) => {

    const {
        rcpTasksAddAction,
        rcpTasksUpdateAction,
        rcpTaskDeleteAction,
        rcpTaskAssignToUserAction,
        taskList,
        userList,
        guid,
        service,
        selectedCustomer,
        userLoggedIn,
        setOpen
    } = props;

    const headerList = [
        {
            name: (
                <Translate>
                  {userLoggedIn?.language_data?.complianceworkflow?.task_name || "Task Name"}
                </Translate>
              ),
            headerClass: "tableHeading",
            accessibleKey: "name",
            rowClass: "typo4",
            headerAlign: "left",
            rowAlign: "center",
        },
    ];

    const [workFlow, setWorkFlow] = useState({
        listItem: null,
        openAssignUser: false,
        openAdd: false,
        openSuccess: false,
        modelContain: null,
        sequence: 0,
        max: 1,
        taskForm: {
            guid: guid,
            type: service?.id,
            task: "",
            sequence: 0,
            required: true,
        },
        assignTask: {
            guid: selectedCustomer?.guid,
            responsible: selectedCustomer?.id,
            compliance_task_id: "",
            status: "",
        },
        assignUser: {
            firstName: "",
            middleName: "",
            lastName: "",
            email: "",
            id: "",
            role: "",
        },
    });
    

    const deleteContent = (
        <Translate>
          {`Deleting this task will disable the access to (${workFlow?.listItem?.name}) for all the users under (${workFlow?.listItem?.name}). This will also change the compliance process. Are you sure, you want to delete (${workFlow?.listItem?.name})`}
        </Translate>
      );

    const [tableList, setTableList] = useState([]);

    useEffect(() => {
        if (taskList.length > 0) {
            setTableList([
                ...taskList.map((task) => {
                    return { ...task, name: task.task, type: service?.id };
                }),
            ]);
            setWorkFlow((previous) => {
                const sequenceArr = taskList
                    ?.map((item) => item?.sequence)
                    .sort();

                const nextSequence =
                    sequenceArr?.length > 0
                        ? sequenceArr?.length === 1
                            ? sequenceArr[0] + 1
                            : sequenceArr[sequenceArr?.length - 1] + 1
                        : 2;

                return {
                    ...previous,
                    max: sequenceArr[sequenceArr?.length - 1],
                    taskForm: {
                        ...previous.taskForm,
                        sequence: nextSequence,
                    },
                };
            });
        } else {
            setTableList([]);
            setWorkFlow((previous) => {
                return {
                    ...previous,
                    taskForm: {
                        ...previous.taskForm,
                        sequence: 1,
                    },
                };
            });
        }
    }, [taskList?.length]);

    const handleUpdate = () => {
        rcpTasksUpdateAction(tableList, guid);
    };

    const handleAdd = () => {
        rcpTasksAddAction(workFlow.taskForm);
        setWorkFlow((previous) => {
            return {
                ...previous,
                openAdd: false,
                taskForm: { ...previous.taskForm, task: "" },
            };
        });
    };

    const handleDelete = () => {
        rcpTaskDeleteAction(workFlow?.listItem?.id, guid);
        setWorkFlow((previous) => {
            return {
                ...previous,
                listItem: null,
                openSuccess: true,
                modelContain: "Task Removed Sucessfully!",
            };
        });
    };

    const extraColRight = (
        <Link fontSize={16} sx={{ cursor: "pointer" }}>
            Setting
        </Link>
    );

    const extraHeaderRight = "User";

    return (
        <Fragment>
            <SuccessfullModel
                open={workFlow.openSuccess}
                handleClose={() =>
                    setWorkFlow((previous) => ({
                        ...previous,
                        openSuccess: false,
                        modelContain: null,
                    }))
                }
                content={workFlow.modelContain}
            />
            <AddWorkFlowDialog
                open={workFlow.openAdd}
                onClose={(isOpen) => {
                    setWorkFlow((previous) => {
                        return {
                            ...previous,
                            openAdd: isOpen,
                        };
                    });
                }}
                inputProps={{ maxLength: 40 }}
                onClick={handleAdd}
                onChange={(e) => {
                    setWorkFlow((previous) => {
                        return {
                            ...previous,
                            taskForm: {
                                ...previous.taskForm,
                                task: e.target.value,
                            },
                        };
                    });
                }}
                title="Task"
                label="Workflow"
                value={workFlow.taskForm.task}
            />
            <CommonDeleteDialog
                open={workFlow.listItem != null ? true : false}
                cancelButtonAction={() => {
                    setWorkFlow({
                        ...workFlow,
                        listItem: null,
                    });
                }}
                setOpen={(isOpen) => {}}
                onClick={handleDelete}
                title="Delete Task"
                content={deleteContent}
            />
            <WorkFlowAssignUserDialog
                open={workFlow.openAssignUser}
                label={userLoggedIn?.language_data?.complianceworkflow?.assign || "Assign"}
                title={<Translate>Assign User</Translate> }
                options={userList}
                assignUser={workFlow.assignUser}
                setAssignUser={(user) => {
                    setWorkFlow((previous) => {
                        return {
                            ...previous,
                            assignUser: {
                                ...previous.assignUser,
                                firstName: user?.first_name,
                                middleName: user?.middle_name,
                                lastName: user?.last_name,
                                email: user?.email,
                                id: user?.id,
                                role: user?.role,
                            },
                            assignTask: {
                                ...previous.assignTask,
                                responsible: user?.id,
                            },
                        };
                    });
                }}
                getOptionLabel={(option) => option?.display_name}
                handleClose={() =>
                    setWorkFlow({
                        ...workFlow,
                        openAssignUser: false,
                    })
                }
                handleOnClick={() => {
                    setWorkFlow({ ...workFlow, openAssignUser: false });
                    rcpTaskAssignToUserAction(workFlow.assignTask);
                }}
            />
            <WorkFlowTable
                headerList={headerList}
                tableList={{tableList}}
                setTableList={(list) => {
                    setTableList([...list]);
                }}
                setListItem={(item) => {
                    setWorkFlow((previous) => {
                        return {
                            ...previous,
                            listItem: { ...item },
                        };
                    });
                }}
                extraHeaderRight={extraHeaderRight}
                extraColRight={extraColRight}
                handleUpdate={handleUpdate}
                max={workFlow.max}
                extraRightColProps={{
                    onClick: (e) => {
                        setWorkFlow((previous) => {
                            return {
                                ...previous,
                                openAssignUser: true,
                                assignUser: {
                                    ...previous.assignUser,
                                    firstName: "",
                                    middleName: "",
                                    lastName: "",
                                    email: "",
                                    id: "",
                                    role: "",
                                },
                                assignTask: {
                                    ...previous.assignTask,
                                    compliance_task_id:
                                        e.target.getAttribute("value"),
                                    status:
                                        e.target.getAttribute("status") !== null
                                            ? e.target.getAttribute("status")
                                            : "Not Started",
                                },
                            };
                        });
                    },
                }}
                setOpenAdd={(isOpen) => {
                    setWorkFlow((previous) => {
                        return {
                            ...previous,
                            openAdd: isOpen,
                        };
                    });
                }}
            />
        </Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,
        taskList: state?.tasks?.tasks,
        userList: state?.user?.usersList?.filter(
            (user) => user?.role !== "Contractor"
        ),
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            rcpTasksAddAction,
            rcpTasksUpdateAction,
            rcpTaskDeleteAction,
            rcpTaskAssignToUserAction,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkFlowSettings);
