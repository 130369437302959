import React, { Fragment, useState, useEffect } from "react";
import Navbar from "../../common/Header/Navbar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import ButtonComponent from "../../components/ButtonComponent";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import TextFieldComponent from "../../components/TextField/TextFieldComponent.js";
import Rectangle from "../../../assets/Images/Avatar/Rectangle.png";
import "../../../assets/styles/Container/Profile.scss";
import FileUpload from "../../components/FileUpload";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { ROUTES } from "../../router/RouteList";
import { bindActionCreators } from "redux";
import { updateProfile } from "../../store/Action/LoginAction";
import LoadingComponent from "../../components/Loading/LoadingComponent";
import DropDownComponent from "../../components/DropDown/DropDownComponent";
import { MenuItem } from "@mui/material";
function EditProfile(props) {
    const navigate = useNavigate();
    const { userLoggedIn, updateProfile, commonDropdown } = props;

    const [state, setState] = useState({
        first_name: userLoggedIn?.user?.first_name || "",
        last_name: userLoggedIn?.user?.last_name || "",
        middle_name: userLoggedIn?.user?.middle_name || "",
        email: userLoggedIn?.user?.email || "",
        language: userLoggedIn?.user?.language || "",
        password: userLoggedIn?.user?.password || "",
        password_confirmation: userLoggedIn?.user?.password_confirmation || "",
        showPassword: false,
        showConfirmPassword: false,
        photograph: userLoggedIn?.user?.photograph
            ? [{ preview: userLoggedIn?.user?.photograph }]
            : null,
        isLoading: false,
        isLoader: false
    });
    const {
        first_name,
        last_name,
        email,
        middle_name,
        language,
        password,
        password_confirmation,
        showPassword,
        showConfirmPassword,
        photograph,
        isLoading,
        isLoader
    } = state;

    useEffect(() => {
        if (userLoggedIn && !email) {
            setState((prevState) => ({
                ...prevState,
                first_name: userLoggedIn?.user?.first_name || "",
                last_name: userLoggedIn?.user?.last_name || "",
                middle_name: userLoggedIn?.user?.middle_name || "",
                email: userLoggedIn?.user?.email || "",
                language: userLoggedIn?.user?.language_id || "",
                password: userLoggedIn?.user?.password || "",
                password_confirmation:
                    userLoggedIn?.user?.password_confirmation || "",
                photograph: userLoggedIn?.user?.photograph
                    ? [{ preview: userLoggedIn?.user?.photograph }]
                    : null,
            }));
        }
    }, [userLoggedIn, email]);

    function handleClick() {
        navigate(ROUTES.PROFILE);
    }

    async function handleSave() {
        setState((prevState) => ({ ...prevState, isLoader: true }));
        const res = await updateProfile(
            {
                ...state,
                photograph: photograph?.length
                    ? photograph[0]?.content
                        ? photograph[0] || null
                        : null
                    : null,
            },
            userLoggedIn?.user?.id
        );
        setState((prevState) => ({ ...prevState, isLoader: false }));
        if (res) navigate(ROUTES.PROFILE);
    }

    function handleShowPassword() {
        setState((prevState) => ({
            ...prevState,
            showPassword: !showPassword,
        }));
    }
    function handleShowConfirmPassword() {
        setState((prevState) => ({
            ...prevState,
            showConfirmPassword: !showConfirmPassword,
        }));
    }
    function handleProfileUpload(file) {
        const reader = new FileReader();
        reader.readAsDataURL(file[0]);
        let newData;
        newData = file.map((fileObj) =>
            Object.assign(fileObj, {
                preview: URL.createObjectURL(fileObj),
            })
        );
        reader.onload = () => {
            let fileUrl = reader.result;
            newData = {
                ...newData[0],
                content: fileUrl,
                filename: file[0].name,
                type: file[0].type,
            };
            setState((prevState) => ({ ...prevState, photograph: [newData] }));
        };
        setState((prevState) => ({ ...prevState, photograph: newData }));
    }

    return (
        <Fragment>
            <Navbar />
            {!userLoggedIn || isLoading ? (
                <LoadingComponent sx={{ height: "600px" }} />
            ) : (
                <Grid container>
                    <Grid item lg={12} mt={12} mx={8}>
                        <Typography className="profileEditHeading">
                            Edit Profile
                        </Typography>
                        <Grid container>
                            <Grid item lg={12} mt={2}>
                                <Box className="boxEdit">
                                    <Grid container>
                                        <Grid item lg={10} mt={4} ml={5}>
                                            <Typography className="colorProfile">
                                                Account Settings
                                            </Typography>
                                        </Grid>
                                        <Grid container className="profileDiv">
                                            <Grid
                                                item
                                                xs={12}
                                                lg={2}
                                                md={2}
                                                sm={12}
                                                pt={1}
                                                className="imageStyle"
                                            >
                                                <div className="profileUpload">
                                                    <FileUpload
                                                        id="editProfileImage"
                                                        file={photograph}
                                                        setFiles={setState}
                                                        uploadIcon={
                                                            <div>
                                                                <img
                                                                    src={
                                                                        Rectangle
                                                                    }
                                                                    alt="Rectangle"
                                                                    className="imgRectangle"
                                                                />
                                                                <EditOutlinedIcon className="profileEdit" />
                                                            </div>
                                                        }
                                                        keyName={"photograph"}
                                                        validation={"image/*"}
                                                        showEdit={true}
                                                        handleFileUpload={
                                                            handleProfileUpload
                                                        }
                                                    />
                                                </div>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                lg={10}
                                                md={10}
                                                sm={12}
                                            >
                                                <Grid container>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        lg={6}
                                                        md={6}
                                                        sm={12}
                                                        sx={{
                                                            padding:
                                                                "10px 32px 10px 2px",
                                                            width: "100%",
                                                        }}
                                                    >
                                                        <TextFieldComponent
                                                            id="editProfileFirstName"
                                                            required={true}
                                                            label={userLoggedIn?.language_data?.customer?.first_name || "First Name"}
                                                            value={first_name}
                                                            onChange={(event) =>
                                                                setState(
                                                                    (
                                                                        prevState
                                                                    ) => ({
                                                                        ...prevState,
                                                                        first_name:
                                                                            event
                                                                                .target
                                                                                .value,
                                                                    })
                                                                )
                                                            }
                                                        />
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        lg={6}
                                                        md={6}
                                                        sm={12}
                                                        sx={{
                                                            padding:
                                                                "10px 32px 10px 2px",
                                                        }}
                                                    >
                                                        <TextFieldComponent
                                                            label={userLoggedIn?.language_data?.customer?.middle_name || "Middle Name"}
                                                            id="editProfileMiddleName"
                                                            value={middle_name}
                                                            onChange={(event) =>
                                                                setState(
                                                                    (
                                                                        prevState
                                                                    ) => ({
                                                                        ...prevState,
                                                                        middle_name:
                                                                            event
                                                                                .target
                                                                                .value,
                                                                    })
                                                                )
                                                            }
                                                        />
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        lg={6}
                                                        md={6}
                                                        sm={12}
                                                        sx={{
                                                            padding:
                                                                "10px 32px 10px 2px",
                                                        }}
                                                    >
                                                        <TextFieldComponent
                                                            id="editProfileLastName"
                                                            required={true}
                                                            label={userLoggedIn?.language_data?.customer?.last_name || "Last Name"}
                                                            value={last_name}
                                                            onChange={(event) =>
                                                                setState(
                                                                    (
                                                                        prevState
                                                                    ) => ({
                                                                        ...prevState,
                                                                        last_name:
                                                                            event
                                                                                .target
                                                                                .value,
                                                                    })
                                                                )
                                                            }
                                                        />
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        lg={6}
                                                        md={6}
                                                        sm={12}
                                                        sx={{
                                                            padding:
                                                                "10px 32px 10px 2px",
                                                        }}
                                                    >
                                                        <TextFieldComponent
                                                            id="editProfileEmail"
                                                            label={"Email"}
                                                            required={true}
                                                            value={email}
                                                            disabled={
                                                                userLoggedIn
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                    </Grid>
                                                  { 
                                                   userLoggedIn.user.role_id != 1 &&
                                                  <Grid
                                                        item
                                                        xs={12}
                                                        lg={6}
                                                        md={6}
                                                        sm={12}
                                                        sx={{
                                                            padding:
                                                                "10px 32px 10px 2px",
                                                        }}
                                                    >
                                            <DropDownComponent
                                            label={"Select your preferred language"}
                                            name={"language"}
                                            fullWidth={true}
                                            onChange={(event) =>
                                                setState((prevState) => ({
                                                    ...prevState,
                                                    language:
                                                        event
                                                            .target
                                                            .value,
                                                }))
                                            }
                                            value={
                                              language
                                            }
                                            disabled={
                                                userLoggedIn
                                                    ? false
                                                    : true
                                            }
                                            required={true}
                                            children={commonDropdown.languages?.map(
                                                (option) => (
                                                    <MenuItem
                                                        key={option.id}
                                                        value={option.id}
                                                    >
                                                        {option.label}
                                                    </MenuItem>
                                                )
                                            )}
                                        />
                                                    </Grid>}
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        lg={12}
                                                        md={11}
                                                        sm={12}
                                                        sx={{
                                                            padding:
                                                                "10px 32px 10px 2px",
                                                        }}
                                                    >
                                                        <Typography className="colorProfile">
                                                            Change Password
                                                        </Typography>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        lg={6}
                                                        md={6}
                                                        sm={12}
                                                        sx={{
                                                            padding:
                                                                "10px 32px 10px 2px",
                                                        }}
                                                    >
                                                        <TextFieldComponent
                                                            id="editProfilePassword"
                                                            label={"Password"}
                                                            required={true}
                                                            value={password}
                                                            onChange={(event) =>
                                                                setState(
                                                                    (
                                                                        prevState
                                                                    ) => ({
                                                                        ...prevState,
                                                                        password:
                                                                            event
                                                                                .target
                                                                                .value,
                                                                    })
                                                                )
                                                            }
                                                            type={
                                                                showPassword
                                                                    ? "text"
                                                                    : "password"
                                                            }
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <IconButton
                                                                            onClick={() =>
                                                                                handleShowPassword()
                                                                            }
                                                                            edge="start"
                                                                        >
                                                                            {!showPassword ? (
                                                                                <VisibilityOff />
                                                                            ) : (
                                                                                <Visibility />
                                                                            )}
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        lg={6}
                                                        md={6}
                                                        sm={12}
                                                        sx={{
                                                            padding:
                                                                "10px 32px 10px 2px",
                                                        }}
                                                    >
                                                        <TextFieldComponent
                                                            id="editProfileConfirmPassword"
                                                            label={
                                                                "Confirm Password"
                                                            }
                                                            required={true}
                                                            value={
                                                                password_confirmation
                                                            }
                                                            onChange={(event) =>
                                                                setState(
                                                                    (
                                                                        prevState
                                                                    ) => ({
                                                                        ...prevState,
                                                                        password_confirmation:
                                                                            event
                                                                                .target
                                                                                .value,
                                                                    })
                                                                )
                                                            }
                                                            type={
                                                                showConfirmPassword
                                                                    ? "text"
                                                                    : "password"
                                                            }
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <IconButton
                                                                            id="editProfileShowPass"
                                                                            onClick={() =>
                                                                                handleShowConfirmPassword()
                                                                            }
                                                                            onMouseDown={(
                                                                                event
                                                                            ) =>
                                                                                event.preventDefault()
                                                                            }
                                                                            edge="start"
                                                                        >
                                                                            {!showConfirmPassword ? (
                                                                                <VisibilityOff />
                                                                            ) : (
                                                                                <Visibility />
                                                                            )}
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        lg={11}
                        md={10}
                        mt={2}
                        ml={6}
                        display="flex"
                        justifyContent="flex-end"
                        gap={"18px"}
                    >
                        <ButtonComponent
                            id="editProfileCancelButton"
                            title={"Cancel"}
                            onClick={() => handleClick()}
                            variant="outlined"
                        />
                        <ButtonComponent
                            id="editProfileSaveButton"
                            title={isLoader ? <svg
                                height={
                                    "26px"
                                }
                                version="1.1"
                                id="L9"
                                xmlns="http://www.w3.org/2000/svg"
                                x="0px"
                                y="0px"
                                viewBox="0 0 100 100"
                                enable-background="new 0 0 0 0"
                            >
                                <path
                                    fill="#fff"
                                    d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                                >
                                    <animateTransform
                                        attributeName="transform"
                                        attributeType="XML"
                                        type="rotate"
                                        dur="1s"
                                        from="0 50 50"
                                        to="360 50 50"
                                        repeatCount="indefinite"
                                    />
                                </path>
                            </svg> : userLoggedIn?.language_data?.comman?.save_btn || "Save"}
                            variant="contained"
                            disabled={isLoader}
                            onClick={() => handleSave()}
                        />
                    </Grid>
                </Grid>
            )}
        </Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth.userLoggedIn,
        commonDropdown: state.auth?.commonDropdown,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ updateProfile }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
