import React, { Fragment, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Navbar from "../../common/Header/Navbar";
import Typography from "@mui/material/Typography";
import DropDownComponent from "../../components/DropDown/DropDownComponent";
import ButtonComponent from "../../components/ButtonComponent";
import "../../../assets/styles/Container/ScoringConfiguration.scss";
import FederalModifiers from "./FederalModifiers";
import StateModifiers from "./StateModifiers";
import USModifiers from "./USModifiers";
import { scoringConfigurationSelectCategory } from "../../utils/CommonData";
import { Switch } from "@mui/material";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
    getScoringDropdown,
    getCircuitsData,
    updateCircuitsData,
    getStateData,
    updateStateData,
} from "../../store/Action/ScoringConfigurationAction";
import LocationOfWork from "./LocationOfWork";
import CollarColor from "./CollarColor";
import { getUserById } from "../../store/Action/UserAction";
const ScoringConfiguration = (props) => {
    const {
        getScoringDropdown,
        categoryDropdown,
        getCircuitsData,
        circuitData,
        updateCircuitsData,
        getStateData,
        stateData,
        updateStateData,
        userLoggedIn
    } = props;
    useEffect(() => {
        getScoringDropdown();
        getCircuitsData();
        getStateData();
        updateStateData();
    }, [getScoringDropdown, getCircuitsData, getStateData, updateStateData]);
    const [visible, setVisible] = useState(false);
    const [state, setState] = useState({
        scoring_configuration: "",
        federal_law_modifiers: "",
        collar_pagination: "",
    });
    const { scoring_configuration } = state;

    return (
        <Fragment>
            <Navbar />
            <Grid container>
                <Grid item xs={12} mt={4} sx={{ paddingTop: "50px" }} ml={8}>
                    <Typography className="scoringHeading">
                        {userLoggedIn?.language_data?.comman?.menu_score_conf || "Scoring Configuration"}
                    </Typography>
                </Grid>
                <Grid item md={4} xs={12} mt={2} ml={8}>
                    <DropDownComponent
                        fullWidth={true}
                        label={"Select Category"}
                        name={"scoring_configuration"}
                        required={true}
                        value={scoring_configuration}
                        onChange={(event) =>
                            setState((prevState) => ({
                                ...prevState,
                                scoring_configuration: event.target.value,
                            }))
                        }
                    >
                        {scoringConfigurationSelectCategory.map((obj) => (
                            <MenuItem
                                value={obj.id}
                                key={obj.id}
                                className="dropDownText"
                            >
                                {obj.name}
                            </MenuItem>
                        ))}
                        <MenuItem className="subheader"></MenuItem>
                    </DropDownComponent>
                </Grid>
                <Grid container display="flex">
                    <Grid item md={3} xs={3} mt={3} ml={8}>
                        <Typography className="federalHeading">
                            {state.scoring_configuration === "political" && (
                                <>
                                    {visible
                                        ? "State Law Modifiers"
                                        : "Federal Law Modifiers"}
                                </>
                            )}
                            {state.scoring_configuration === "initiatives"
                                ? "U.S. Based Modifiers"
                                : ""}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        md={6}
                        lg={6}
                        mt={3}
                        ml={10}
                        display="flex "
                        justifyContent="end"
                        gap={"12px"}
                    >
                        {state.scoring_configuration === "political" && (
                            <Switch
                                onChange={() => {
                                    setVisible(!visible);
                                }}
                                defaultChecked
                                className="switchComponent"
                            />
                        )}
                    </Grid>
                </Grid>
                <Grid container display="flex">
                    <Grid item md={4.8} lg={12} xl={12} xs={12}>
                        {state.scoring_configuration === "political" && (
                            <>
                                {visible ? (
                                    <StateModifiers
                                        state={state}
                                        setState={setState}
                                        categoryDropdown={categoryDropdown}
                                        stateData={stateData}
                                        getStateData={getStateData}
                                        updateStateData={updateStateData}
                                    />
                                ) : (
                                    <FederalModifiers
                                        state={state}
                                        setState={setState}
                                        categoryDropdown={categoryDropdown}
                                        circuitData={circuitData}
                                        getCircuitsData={getCircuitsData}
                                        updateCircuitsData={updateCircuitsData}
                                    />
                                )}
                            </>
                        )}
                        {state.scoring_configuration === "initiatives" && (
                            <USModifiers />
                        )}
                        {state.scoring_configuration === "location" && (
                            <LocationOfWork />
                        )}
                        {state.scoring_configuration === "collar" && (
                            <CollarColor />
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        categoryDropdown: state?.scoringConfig?.categoryDropdown,
        circuitData: state?.scoringConfig?.circuitData,
        stateData: state?.scoringConfig?.stateData,
        userLoggedIn: state.auth?.userLoggedIn,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getScoringDropdown,
            getCircuitsData,
            updateCircuitsData,
            getStateData,
            updateStateData,
            getUserById,
        },
        dispatch
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ScoringConfiguration);
