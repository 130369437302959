import meeting1Image from "../../assets/Images/Common/meeting1.jpg";
import FeatureImage1 from "../../assets/Images/Common/Mask group.png";
import FeatureImage2 from "../../assets/Images/Common/Group 83.png";
import FirstHomeImage from '../../assets/Images/Common/FirstHomeImage.png'
import ThirdHomeImage from '../../assets/Images/Common/ThirdHomeImage.png'
export const genderOptions = [
    { id: "m", name: "Male" },
    { id: "f", name: "Female" },
    { id: "o", name: "Others" },
];

export const business_entity = [
    { id: "c", name: "CBE" },
    { id: "s", name: "SBE" },
    { id: "p", name: "PTE" },
];
export const onboardNewCustomer = [
    { id: "yes", value: true, name: "Yes" },
    { id: "no", value: false, name: "No" },
];
export const subscription = [
    { id: "1", name: "Freemium" },
    { id: "2", name: "Business" },
    { id: "3", name: "Enterprise" },
];

export const contractorToContractorOptions = [
    { id: "y", name: "Yes" },
    { id: "n", name: "No" },
];

// Radio Group Options
export const yesNoOptions = [
    {
        label: "Yes",
        value: "yes",
    },
    {
        label: "No",
        value: "no",
    },
];

export const rankingOptions = [
    {
        label: "1",
        value: "1",
    },
    {
        label: "2",
        value: "2",
    },
];

export const mqsYesNoOptions = [
    {
        label: "Yes",
        value: 1,
    },
    {
        label: "No",
        value: 0,
    },
];

export const directIndirectOptions = [
    {
        label: "Direct Conductor",
        value: "dc",
        id: "dc",
        name: "DirectConductor",
        defination:"Entity that hires, examines, and directly receives the work/services/labor of the W"
    },
    {
        label: "InDirect Conductor",
        value: "idc",
        id: "idc",
        name: "InDirectConductor",
        defination:"Entity responsible for administering MSA Compliance, Document Compliance, and/or Relationship Compliance"
    },
];

export const taskStatusOptions = [
    { value: "1", label: "Not Started", name: "notStarted" },
    { value: "2", label: "In Progress", name: "inProgress" },
    { value: "3", label: "Reassigned", name: "reassign" },
    { value: "4", label: "On-hold", name: "hold" },
    { value: "5", label: "Reject", name: "reject" },
    { value: "6", label: "Complete", name: "complete" },
];

export const employmentTypeField = "Independent Contractor";

export const scoringConfigurationSelectCategory = [
    { id: "political", name: "Potential Political Preferences" },
    { id: "initiatives", name: "Initiatives" },
    { id: "collar", name: "Collar Color" },
    { id: "location", name: "Location" },
];

export const defaultComplianceSettingOptions = [
    {
        label: "Onboarding",
        value: 1,
    },
    {
        label: "Retesting",
        value: 2,
    },
    {
        label: "Offboarding",
        value: 3,
    },
];

export const defaultIDComplianceSettingOptions = [
    {
        label: "Always apply on a customer by customer basis.",
        value: 1,
    },
    {
        label: "Always apply on a Test taker by test taker basis.",
        value: 2,
    },
    {
        label: "Use a hybrid approach (includes 1 & 2).",
        value: 3,
    },
];
export const agreeOrNotOptions = [
    {
        label: "I agree",
        value: true,
    },
    {
        label: "I do not agree",
        value: false,
    },
];

export const questionAnsOptions = [
    {
        label: "Yes",
        value: "yes",
    },
    {
        label: "No",
        value: "no",
    },
    {
        label: "Do Not Know/Unsure/Maybe",
        value: "Do Not Know/Unsure/Maybe",
    },
];
export const TestOptions = [
    {
        label: "Balancing Category Weight",
        value: 1,
    },
    {
        label: "Grouping Category Weight",
        value: 2,
    },
    {
        label: "Hybrid Category Weight",
        value: 3,
    },
];

export const finalReviewArray = ["Approved", "Disapprove", "Reject"];

export const tcOptions = [
    {
        label: "Yes",
        value: 1,
    },
    {
        label: "No",
        value: 2,
    },
    {
        label: "Not Applicable",
        value: 3,
    },
];

export const languageList = [
    { id: "english", label: "English" },
    { id: "spanish", label: "Spanish" },
];

export const rangeOptions = [
    {
        label: "True",
        value: true,
    },
    {
        label: "False",
        value: false,
    },
];

export const alternativeContentResponses = [
    {
        label: "True",
        id: 1,
    },
    {
        label: "False",
        id: 0,
    },
];

export const hiringPartyOptions = [
    {
        label: "Yes",
        value: 1,
    },
    {
        label: "No",
        value: 2,
    },
    {
        label: "Both",
        value: 3,
    },
];

export const defaultFavicon = "";

export const uploadStatus1 = "Click here to upload";

export const uploadStatus2 = " Cannot Fulfill Request to Upload Because";

export const uploadStatus3 = "Other / No Upload";

export const responseOptions = [
    "Required",
    "Hard Required",
    "Optional + Scored",
    "Optional",
];

export const testNames = [
    "Massachusetts ABC Test",
    "Unemployment Compensation Test",
    "Contracting Business Health",
    "California ABC Test",
    "California ABC Test",
    "Borello Test",
    "IRS Test (3 Category Modern Test)",
    'Darden "Common Law of Agency" Test',
    'FLSA "Economic Realities" Test',
    'Common Law "Right to Control" Test',
    "Audit Trigger Assessment",
];

export const pageLimit = 8;

export const packageList = [
    {
        name: "Customer Dashboard - Card View and Table View",
        standard: true,
        custom: true,
        premium: true,
    },
    {
        name: "Add New Customers, Edit & Delete Customers",
        standard: true,
        custom: true,
        premium: true,
    },
    {
        name: "Activate and Deactivate Customers",
        standard: true,
        custom: true,
        premium: true,
    },
    {
        name: "Users Dashboard - Card View and Table View",
        standard: true,
        custom: true,
        premium: true,
    },
    {
        name: "Designing & Development",
        standard: false,
        custom: true,
        premium: true,
    },
    {
        name: "Customizable options to meet your specific needs",
        standard: false,
        custom: true,
        premium: true,
    },
    {
        name: "Add new users, edit and delete users",
        standard: false,
        custom: false,
        premium: true,
    },
    {
        name: "Activate and deactivate users",
        standard: false,
        custom: false,
        premium: true,
    },
    {
        name: "Manage permissions for each users",
        standard: false,
        custom: false,
        premium: true,
    },
];

export const assignOptions = [
    {
        label: "Assign to User",
        value: "assignToUser",
    },
    {
        label: "Assign to Me",
        value: "assignToMe",
    },
];

export const reassignOption = [
    {
        label: "Assign to User",
        value: "assignToUser",
    },
    {
        label: "Assign to Queue",
        value: "assignToQueue",
    },
];
export const homePageMeetingImages = [
    {
        title: "first 1",
        path: FirstHomeImage,
    },
    {
        title: "second 2",
        path: meeting1Image,
    },
    {
        title: "third 3",
        path: ThirdHomeImage,
    },
];
export const projectFeatureImage = [
    {
        title: "first 1",
        path: FeatureImage2,
    },
    {
        title: "first 1",
        path: FeatureImage1,
    },
];
