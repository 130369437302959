import React from "react";
import Box from "@mui/system/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ButtonComponent from "../../../../../components/ButtonComponent";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getEmailTemplates } from "../../../../../store/Action/ContractorEmailAction";

const ImpExpoHeader = (props) => {
    const { setState, getEmailTemplates } = props;
    const onClickOnImportButton = async () => {
        setState((prevState) => ({
            ...prevState,
            isLoading: true,
        }));
        await getEmailTemplates();
        setState((prevState) => ({
            ...prevState,
            isLoading: false,
            openImport: true,
        }));
    };

    return (
        <Box my={3} px={5}>
            <Grid
                container
                display="flex"
                sx={{ alignItems: "center" }}
                justifyContent={"flex-end"}
            >
                <Grid
                    item
                    xs={12}
                    md={7}
                    display="flex"
                    justifyContent="center"
                >
                    <Typography className="font_24_600">Email</Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={2.8}
                    display="flex"
                    justifyContent="flex-end"
                    pr={2.5}
                >
                    <ButtonComponent
                        className="button_contained"
                        onClick={onClickOnImportButton}
                        title={"Import"}
                        endIcon={<FileDownloadOutlinedIcon />}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getEmailTemplates,
        },
        dispatch
    );
};

export default connect(null, mapDispatchToProps)(ImpExpoHeader);
