import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextFieldComponent from "../../../components/TextField/TextFieldComponent";
import { connect } from "react-redux";

const Address = (props) => {
    const {
        state,
        setState,
        data,
        children,
        handleChange,
        isRead,
        handlerFieldChange,
        isEdit,
        userLoggedIn
    } = props;

    const handlerIsEdit = () => {
        if (isEdit) {
            setState((prevState) => ({ ...prevState, editLabel: true }));
        }
    };

    const onBlur = () => {
        setState((prevState) => ({ ...prevState, editLabel: false }));
    };

    return (
        <Grid container>
            <Grid item xs={12} className={"elementItemStyle"}>
                <TextFieldComponent
                    sx={{
                        display:
                            state?.selectedEle?.selected && state?.editLabel
                                ? "block"
                                : "none",
                        color: state?.fontColor,
                        "& .MuiInputBase-input": {
                            color: state?.fontColor,
                        },
                    }}
                    onBlur={onBlur}
                    onChange={handleChange}
                    value={data?.label}
                    className="elementHeadingTextField"
                    autoFocus={true}
                />
                <Typography
                    display={
                        state?.selectedEle?.selected && state?.editLabel
                            ? "none"
                            : "block"
                    }
                    className="elementHeading"
                    variant="h5"
                    onClick={handlerIsEdit}
                    sx={{ color: state?.fontColor }}
                >
                    {data.label}
                </Typography>
                {children}
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={6} sm={12} md={6}>
                    <TextFieldComponent
                        label={`${userLoggedIn?.language_data?.customer?.address_line || "Address Line"} 1 `}
                        className="textFieldFromBuilder"
                        disabled={isRead}
                        onChange={(event) =>
                            handlerFieldChange(event, data?.id)
                        }
                        name={"address1"}
                        id="FormStudioAddress"
                        value={data?.formValues?.address1}
                    />
                </Grid>
                <Grid item xs={6} sm={12} md={6}>
                    <TextFieldComponent
                        label={`${userLoggedIn?.language_data?.customer?.address_line || "Address Line"} 2`}
                        className="textFieldFromBuilder"
                        disabled={isRead}
                        id="FormStudioAddress1"
                        onChange={(event) =>
                            handlerFieldChange(event, data?.id)
                        }
                        name={"address2"}
                        value={data?.formValues?.address2}
                    />
                </Grid>
                <Grid item xs={4} sm={12} md={4}>
                    <TextFieldComponent
                        label={userLoggedIn?.language_data?.customer?.city || "City"}
                        className="textFieldFromBuilder"
                        id="FormStudioAddress2"
                        disabled={isRead}
                        onChange={(event) =>
                            handlerFieldChange(event, data?.id)
                        }
                        name={"city"}
                        value={data?.formValues?.city}
                    />
                </Grid>
                <Grid item xs={4} sm={6} md={4}>
                    <TextFieldComponent
                        label={userLoggedIn?.language_data?.complianceworkflow?.state || "State"}
                        className="textFieldFromBuilder"
                        disabled={isRead}
                        onChange={(event) =>
                            handlerFieldChange(event, data?.id)
                        }
                        name={userLoggedIn?.language_data?.complianceworkflow?.state || "State"}
                        value={data?.formValues?.state}
                    />
                </Grid>
                <Grid item xs={4} sm={6} md={4}>
                    <TextFieldComponent
                        label={userLoggedIn?.language_data?.customer?.zi_code || "Zip Code"}
                        className="textFieldFromBuilder"
                        disabled={isRead}
                        onChange={(event) =>
                            handlerFieldChange(event, data?.id)
                        }
                        name={"zipCode"}
                        value={data?.formValues?.zipCode}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,

    };
};


export default connect(mapStateToProps, null) (Address);
