import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    rcpTasksStatusUpdateAction,
    rcpTasksAction,
} from "../store/Action/RcpTasksAction";
import { temp_text } from "../pages/ComplianceWorkflow/ContractorDetails/Style";
import Box from "@mui/material/Box";
import { Grid, Typography, MenuItem } from "@mui/material";
import SuccessfullModel from "../components/DialogsBox/SuccessfullModel";
import ButtonComponent from "../components/ButtonComponent";
import DropDownComponent from "../components/DropDown/DropDownComponent";
import {
    assignOptions,
    reassignOption,
    taskStatusOptions,
} from "../utils/CommonData";
import ComplienceStatusDilaog from "../components/DialogsBox/ComplienceStatusDilaog";
import RadioButtonGroup from "../components/RadioButtonGroup/RadioButtonGroup";
import AutoCompleteDropDown from "../components/DropDown/AutoCompleteDropDown";
import TextFieldComponent from "../components/TextField/TextFieldComponent";

function TopBar(props) {
    const {
        rcpTasksStatusUpdateAction,
        toggale,
        setToggle,
        title,
        auth,
        customer,
        user,
        tasks,
        userLoggedIn,
    } = props;
    const { id } = user?.selectedUser || "";
    const { guid } = customer?.selectedCustomer || "";
    const navigate = useNavigate();
    const location = useLocation();
    const prams = useParams();
    const [state, setState] = useState({
        t_status: "",
        warnningOpen: false,
        warnningContent: "Task is not available.",
        contarctorSate: null,
        task_status: {
            guid: "",
            contractor_id: "",
            compliance_task_id: "",
            status: "",
        },
        popUpTitle: "",
        showTaskStatus: false,
        workflow_status: "Completed",
        open: {
            hold: false,
            reassign: false,
            notStarted: false,
            reject: false,
            inProgress: false,
            complete: false,
        },
        reassignDialogBox: {
            reassignValue: "",
            reassignRaeson: "",
        },
        reasonForHold: "",
        reasonforReject: "",
        completeDialogBox: {
            completeValue: "",
            completeRaeson: "",
        },
    });
    const {
        workflow_status,
        contarctorSate,
        task_status,
        warnningOpen,
        warnningContent,
        open,
        reasonForHold,
        reassignDialogBox,
        reasonforReject,
        completeDialogBox,
    } = state;

    const { hold, reassign, reject, complete } = open;
    const { reassignValue, reassignReason } = reassignDialogBox;
    const backButtonText =
        title !== "Document" ? "Back to Compliance Workflow" : "Previous";
    useEffect(() => {
        getState();
    }, [userLoggedIn?.user?.id]);

    function getState() {
        if (auth?.commonDropdown?.states) {
            const addressState = auth?.commonDropdown?.states.filter(
                (item) =>
                    item?.id === Number(userLoggedIn?.user?.address?.state)
            );
            setState((prevState) => ({
                ...prevState,
                contarctorSate: addressState[0]?.label,
            }));
        }
        setState((prevState) => ({
            ...prevState,
            task_status: {
                ...prevState.task_status,
                guid: guid,
                contractor_id: id,
            },
        }));
    }

    async function handleTaskCompleted(event) {
        event.stopPropagation();
        const task = tasks?.tasks?.find(
            (element) => element.status !== "Completed"
        );
        if (tasks.tasks.length >= 0) {
            await rcpTasksStatusUpdateAction({
                guid: task_status?.guid,
                contractor_id: location?.state?.contractor_ID
                    ? location?.state?.contractor_ID
                    : prams.id,
                compliance_task_id: task?.id,
                status: "Completed",
            });
            setToggle(!toggale);
        } else {
            setState((prevState) => ({
                ...prevState,
                warnningOpen: true,
            }));
        }
    }
    const handleStatus = (ele, name) => {
        setState((prevState) => ({
            ...prevState,
            workflow_status: ele,
            open: {
                ...open,
                [name]: true,
            },
        }));
    };
    const handleClose = (name) => {
        setState((prevState) => ({
            ...prevState,
            open: {
                ...open,
                [name]: false,
            },
        }));
    };
    const handleOptionChange = () => {};
    const hadleOnChange = (event) => {
        event.preventDefault();
        setState((prevState) => ({
            ...prevState,
            reassignDialogBox: {
                ...reassignDialogBox,
                reassignReason: event.target.value,
            },
        }));
    };
    // const fecthState = () => {
    //     if (auth?.commonDropdown?.states) {
    //         const addressState = auth?.commonDropdown?.states.find(
    //             (item) =>
    //                 item?.id === Number(userLoggedIn?.user?.address?.state)
    //         );
    //         console.log("addressState", addressState);
    //         setState((prevState) => ({
    //             ...prevState,
    //             contarctorSate: addressState[0]?.label,
    //         }));
    //     }

    // };
    return (
        <>
            <SuccessfullModel
                open={warnningOpen}
                handleClose={() => setState({ ...state, warnningOpen: false })}
                content={warnningContent}
            />
            <ComplienceStatusDilaog
                open={hold}
                title={"On Hold"}
                handleClose={() => handleClose("hold")}
                handleButtonSubmit={() => handleClose("hold")}
                buttonTitle="Hold"
                content={
                    <>
                        <TextFieldComponent
                            fullWidth
                            multiline
                            rows={4}
                            sx={{ width: "350px" }}
                            id="reasonForHold"
                            name="reason_for_hold"
                            label={"Reason for Hold"}
                            value={reasonForHold}
                            onChange={(event) => hadleOnChange(event)}
                        />
                    </>
                }
            />
            <ComplienceStatusDilaog
                open={reassign}
                handleButtonSubmit={() => handleClose("reassign")}
                buttonTitle="Reassign"
                title={"Reassign"}
                handleClose={() => handleClose("reassign")}
                content={
                    <>
                        <Grid
                            container
                            overflow={"hidden"}
                            px={4}
                            justifyContent={"center"}
                        >
                            <RadioButtonGroup
                                options={reassignOption}
                                row={true}
                                defaultValue={true}
                                name="assignUser"
                                onChange={handleOptionChange}
                                value={reassignValue}
                            />
                        </Grid>
                        <Grid
                            container
                            mx={8}
                            mt={1.5}
                            className="topBarAutocomplete"
                        >
                            <TextFieldComponent
                                id="reasonForReassigning"
                                name="reason_for_reassigning"
                                label={"Reason for reassigning"}
                                value={reassignReason}
                                onChange={(event) => hadleOnChange(event)}
                            />
                        </Grid>
                    </>
                }
            />
            <ComplienceStatusDilaog
                open={reject}
                handleClose={() => handleClose("reject")}
                handleButtonSubmit={() => handleClose("reject")}
                buttonTitle={
                    userLoggedIn?.language_data?.complianceworkflow?.reject ||
                    "Reject"
                }
                title={
                    userLoggedIn?.language_data?.complianceworkflow?.reject ||
                    "Reject"
                }
                content={
                    <>
                        <TextFieldComponent
                            fullWidth
                            multiline
                            rows={4}
                            sx={{ width: "350px" }}
                            id="reasonForRejecting"
                            name="reason_for_hold"
                            label={"Reason for Rejecting"}
                            value={reasonforReject}
                            onChange={(event) => hadleOnChange(event)}
                            // multiline
                        />
                    </>
                }
            />
            <ComplienceStatusDilaog
                open={complete}
                title={"Review: Complete"}
                handleClose={() => handleClose("complete")}
                handleButtonSubmit={() => handleClose("complete")}
                buttonTitle="Complete"
                content={
                    <>
                        <Grid
                            container
                            px={4}
                            className="statusComplete"
                            display={"flex"}
                            justifyContent={"space-between"}
                        >
                            <RadioButtonGroup
                                options={assignOptions}
                                row={true}
                                defaultValue={true}
                                name="complete"
                                onChange={handleOptionChange}
                                value={completeDialogBox}
                            />
                        </Grid>
                        <Typography
                            variant="body2"
                            px={2}
                            textAlign={"left"}
                            gutterBottom
                        >
                            {userLoggedIn?.language_data?.complianceworkflow
                                ?.assign || "Assign"}
                        </Typography>
                        <Grid
                            container
                            mx={8}
                            mt={1.5}
                            className="autocompleteComplete"
                        >
                            <AutoCompleteDropDown
                                className="autoCompleteComplete"
                                options={assignOptions}
                                onChange={(_, newInputValue) => {
                                    newInputValue &&
                                        setState((prevState) => ({
                                            ...prevState,
                                            assignContractor: {
                                                ...prevState.assignContractor,
                                                assign_user_id:
                                                    newInputValue.value,
                                            },
                                        }));
                                }}
                                value={completeDialogBox.completeRaeson}
                                sx={{ width: 400 }}
                                renderInput={(params) => (
                                    <TextFieldComponent
                                        {...params}
                                        placeholder="user"
                                        id="user"
                                    />
                                )}
                            />
                        </Grid>
                    </>
                }
            />
            <Box
                sx={{
                    ml: 4,
                    "& .MuiGrid-root": {
                        marginBottom: "0 !important",
                    },
                    pt: location.pathname.includes("customers")
                        ? "60px"
                        : "0px",
                }}
            >
                <Grid container px={5} my={3} spacing={2}>
                    <Grid
                        container
                        xs={12}
                        spacing={2}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                    >
                        {userLoggedIn?.user.role_id !== 2 && (
                            <Grid item md={2.7}>
                                <ButtonComponent
                                    variant="text"
                                    disablePadding
                                    className="backToCompliance"
                                    onClick={() =>
                                        navigate("/compliance-workflow")
                                    }
                                    title={backButtonText}
                                />
                            </Grid>
                        )}
                        <Grid item display={"flex"}>
                            <Typography sx={{ ...temp_text }}>
                                {userLoggedIn?.user === null
                                    ? "Unique ID:"
                                    : `Unique ID:  ${userLoggedIn?.user.id}`}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography sx={{ ...temp_text }}>
                                {userLoggedIn?.user === null
                                    ? "Contractor Name: - "
                                    : // : `Contractor Name: ${user.selectedUser.display_name}`
                                    userLoggedIn?.user?.role_id === 2
                                    ? `Independent Contractor Name:  ${userLoggedIn?.user?.display_name}`
                                    : userLoggedIn?.user?.role_id === 44
                                    ? `Manager Name:   ${userLoggedIn?.user?.display_name}`
                                    : userLoggedIn?.user?.role_id === 3
                                    ? `Super Admin Name:   ${userLoggedIn?.user?.display_name}`
                                    : userLoggedIn?.user?.role_id === 1
                                    ? `Controller Name:   ${userLoggedIn?.user?.display_name}`
                                    : `Subcontractor Name:   ${userLoggedIn?.user.display_name}`}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography sx={{ ...temp_text }}>
                                {userLoggedIn?.user === null
                                    ? "State: - "
                                    : `${
                                          userLoggedIn?.language_data
                                              ?.complianceworkflow?.state ||
                                          "State:"
                                      }: ${contarctorSate}`}
                            </Typography>
                        </Grid>
                        {/* <Grid item md={2.2}>
                            <ButtonComponent
                                sx={{ ...temp_text }}
                                variant={"text"}
                                title={
                                    user.selectedUser === null
                                        ? "Unique ID:"
                                        : `Unique ID: ${user.selectedUser.id}`
                                }
                            />
                        </Grid>
                        <Grid item md={3}>
                            <ButtonComponent
                                sx={{ ...temp_text }}
                                variant={"text"}
                                title={
                                    user.selectedUser === null
                                        ? "Contractor Name: - "
                                        : // : `Contractor Name: ${user.selectedUser.display_name}`
                                        user.selectedUser.role_id === 2
                                        ? `Independent Contractor Name: ${user.selectedUser.display_name}`
                                        : user.selectedUser.role_id === 44
                                        ? `Manager Name:  ${user.selectedUser.display_name}`
                                        : `Subcontractor Name:  ${user.selectedUser.display_name}`
                                }
                            />
                        </Grid>
                        <Grid item md={2.2}>
                            <ButtonComponent
                                sx={{ ...temp_text }}
                                variant={"text"}
                                title={
                                    user.selectedUser === null
                                        ? "State: - "
                                        : `${
                                              userLoggedIn?.language_data
                                                  ?.complianceworkflow?.state ||
                                              "State:"
                                          }: ${contarctorSate}`
                                }
                            />
                        </Grid> */}
                        {/* <Grid item md={1.85} textAlign={"end"}>
                            {title !== "Document" ? (
                                <>
                                    <ButtonComponent
                                        onClick={(event) =>
                                            handleTaskCompleted(event)
                                        }
                                        className="completeButton"
                                        title={
                                            <Typography className="completeTitle">
                                                {workflow_status}
                                            </Typography>
                                        }
                                        endIcon={
                                            <DropDownComponent className="">
                                                {taskStatusOptions.map(
                                                    (option) => (
                                                        <MenuItem
                                                            key={option.value}
                                                            className="taskStatusList"
                                                            onClick={() => {
                                                                handleStatus(
                                                                    option.label,
                                                                    option.name
                                                                );
                                                            }}
                                                        >
                                                            {option?.label}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </DropDownComponent>
                                        }
                                    />
                                </>
                            ) : null}
                        </Grid> */}
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}
const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,
        auth: state.auth,
        customer: state.customer,
        user: state.user,
        tasks: state.tasks,
    };
};
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            rcpTasksStatusUpdateAction,
            rcpTasksAction,
        },
        dispatch
    );
};
export default connect(mapStateToProps, mapDispatchToProps)(TopBar);
