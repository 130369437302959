import React from "react";
import TableComponent from "../../components/Table/TableComponent";
import "../../../assets/styles/Container/ScoringConfiguration.scss";
import TextField from "../../components/TextField/TextFieldComponent";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

const tableData = [
    {
        id: "21",
        name: "Yes",
        value: "2.5",
    },
    {
        id: "22",
        name: "Yes, except on a occasion(e.g., for a 1-time presentation of a final deliverable, but all of the work to produce deliverable was done off-site/remotely; or for a very rare in-progress  meeting or for  a 1-time emergency purpose)",
        value: "2",
    },
    {
        id: "23",
        name: "No",
        value: "1",
    },
    {
        id: "24",
        name: "Unsure/Do Not Know/Possibly/Some of the Time",
        value: "1",
    },
];

const headerList = [
    {
        name: "Response",
        headerClass: "headerResponse",
        accessibleKey: "name",
        rowClass: "tableResponse",
        headerAlign: "center",
        rowAlign: "start",
    },
];

const LocationTable = () => {
    return (
        <TableComponent
            headerList={headerList}
            extraHeaderRight={
                <TableCell className="colorAdjustment" align="center">
                    Adjustment
                </TableCell>
            }
        >
            {tableData?.length &&
                tableData.map((row, index) => (
                    <TableRow key={row.name} className="seriesCell">
                        {headerList.map((obj) => (
                            <TableCell
                                component="th"
                                key={obj?.name}
                                align={obj?.rowAlign}
                                className={
                                    obj?.rowClass
                                        ? obj?.rowClass
                                        : row?.status === "active"
                                        ? "typo3"
                                        : "typo5"
                                }
                                onClick={() =>
                                    obj.handleButton(row, obj?.buttonType) ||
                                    null
                                }
                            >
                                {row[obj?.accessibleKey]}
                            </TableCell>
                        ))}
                        <TableCell className="rowColor">
                            <TextField
                                variant="outlined"
                                className="fieldNumber"
                                type={"Number"}
                            />
                        </TableCell>
                    </TableRow>
                ))}
        </TableComponent>
    );
};

export default LocationTable;
