import React from "react";
import succesIcon from "../../../assets/Images/Icons/IconSuccessful.png";
import DialogContentText from "@mui/material/DialogContentText";
import ButtonComponent from "../ButtonComponent";
import DialogComponent from "./DialogComponent";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const SuccessfullModel = (props) => {
    const { open, content, handleClose, customizeIcon } = props;

    return (
        <DialogComponent
            open={open}
            handleClose={handleClose}
            ariaLabelledby="alert-dialog-title"
            ariaDescribedby="alert-dialog-description"
            sxContent={{ textAlign: "center", py: "30px", px: "100px" }}
            content={
                <>
                    {customizeIcon ? (
                        <CheckCircleIcon
                            sx={{
                                color: "#00A19D",
                                height: "60px",
                                width: "60px",
                            }}
                        />
                    ) : (
                        <img
                            src={succesIcon}
                            alt="successIcon"
                            style={{ height: "60px", width: "60px" }}
                        />
                    )}
                    <DialogContentText
                        sx={{
                            color: "#00A19D",
                            textTransform: "capitalize",
                            marginTop: "20px",
                        }}
                        id="alert-dialog-description"
                        className="font_18_600 "
                    >
                        {content}
                    </DialogContentText>
                </>
            }
            action={
                <ButtonComponent
                    title={"Close"}
                    className="button_outline"
                    onClick={handleClose}
                />
            }
        />
    );
};

export default SuccessfullModel;
