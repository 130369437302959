import React from "react";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

const SunEditorComponent = (props) => {
    const {
        onChange,
        getSunEditorInstance,
        defaultValue,
        setContents,
        onClick,
        setDefaultStyle,
        width,
        height,
        disable,
        readOnly,
        buttonList,
    } = props;

    return (
        <SunEditor
            setOptions={{
                templates: [
                    {
                        name: "Template-1",
                        html: "<p>HTML source1</p>",
                    },
                    {
                        name: "Template-2",
                        html: "<p>HTML source2</p>",
                    },
                ],
                buttonList: buttonList
                    ? [
                          // default
                          [
                              "bold",
                              "underline",
                              "italic",
                              "font",
                              "fontSize",
                              "fontColor",
                          ],
                          ["align", "list"],
                          ["image", "video", "link"],
                          ["fullScreen", "preview"],
                          // (min-width: 992)
                          [
                              "%992",
                              [
                                  [
                                      "bold",
                                      "underline",
                                      "italic",
                                      "font",
                                      "fontSize",
                                      "fontColor",
                                  ],
                                  ["align", "list"],
                                  ["image", "video", "link"],
                                  ["fullScreen", "preview", "template"],
                              ],
                          ],
                          // (min-width: 767)
                          [
                              "%767",
                              [
                                  [
                                      "bold",
                                      "underline",
                                      "italic",
                                      "font",
                                      "fontSize",
                                      "fontColor",
                                  ],
                                  ["align", "list"],
                                  ["image", "video", "link"],
                                  ["fullScreen", "preview", "template"],
                              ],
                          ],
                          // (min-width: 480)
                          [
                              "%480",
                              [
                                  [
                                      "bold",
                                      "underline",
                                      "italic",
                                      "font",
                                      "fontSize",
                                      "fontColor",
                                  ],
                                  ["align", "list"],
                                  ["image", "video", "link"],
                                  ["fullScreen", "preview", "template"],
                              ],
                          ],
                      ]
                    : [],
            }}
            setDefaultStyle={setDefaultStyle}
            width={width}
            height={height}
            getSunEditorInstance={getSunEditorInstance}
            onChange={onChange}
            defaultValue={defaultValue}
            setContents={setContents}
            disable={disable}
            onClick={onClick ? onClick : null}
            readOnly={readOnly}
        />
    );
};

export default SunEditorComponent;
