import { API_CONSTANTS } from "../../config/ApiConstants";
import {
    getApiCall,
    postApiCall,
    deleteApiCall,
    putApiCall,
} from "../../utils/Action";
import { toast } from "react-hot-toast";
import { updateUserPermission } from "./LoginAction";

export const GET_ROLES_LIST = "GET_ROLES_LIST";
export const GET_MODULE_LIST = "GET_MODULE_LIST";
export const GET_CREATED_ROLE_ID = "GET_CREATED_ROLE_ID";
export const DELETE_ROLE = "DELETE_ROLE";
export const GET_ROLE_PERMISSIONS = "GET_ROLE_PERMISSIONS";
export const SET_MODULE_LIST = "SET_MODULE_LIST";
export const CHANGE_ROLE_VIEW_TYPE = "CHANGE_ROLE_VIEW_TYPE";
export const GET_USER_LIST = "GET_USER_LIST";
export const SET_ROLE_PER_PAGE = "SET_ROLE_PER_PAGE";
export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";

export const getRolesList = (currentPage, customerPageLimit) => {
    return async (dispatch) => {
        try {

            const response = await getApiCall(
                API_CONSTANTS.MANAGE_ROLES +
                    "?page=" +
                    currentPage +
                    "&page_limit=" +
                    customerPageLimit
            );
            if (response?.status === 200) {
                return dispatch({
                    type: GET_ROLES_LIST,
                    payload: response?.data?.result,
                });
            }
        } catch (error) {
            throw error;
        }
    };
};

export const getModuleList = () => {
    return async (dispatch) => {
        try {
            const response = await getApiCall(API_CONSTANTS.GET_MODULES);
            if (response.status === 200) {
                dispatch({
                    type: GET_MODULE_LIST,
                    payload: response?.data?.result,
                });
            } else {
                // toast.error(response?.data?.message);
            }
        } catch (error) {
            throw error;
        }
    };
};

export const getCreateRoleID = (roleData) => {
    return async (dispatch) => {
        try {
            const response = await postApiCall(
                API_CONSTANTS.CREATE_ROLE,
                roleData
            );
            if (response.status === 201) {
                return dispatch({
                    type: GET_CREATED_ROLE_ID,
                    payload: response.data.result,
                });
            }
        } catch (error) {
            throw error;
        }
    };
};

export const deleteRole = (data) => {
    return async (dispatch) => {
        try {
            const response = await deleteApiCall(
                API_CONSTANTS.DELETE_ROLE + "/" + data.id
            );
            if (response.status === 200) {
                dispatch({
                    type: DELETE_ROLE,
                    payload: {
                        ...data,
                        status:
                            data?.status === "active" ? "inactive" : "active",
                    },
                });
            } else {
                // toast.error(response?.data?.message);
            }
        } catch (error) {
            // toast.error(error?.message);
        }
    };
};

export const createRolePermission = (data) => {
    return async () => {
        try {
            const response = await postApiCall(
                API_CONSTANTS.CREATE_ROLE_PERMISSION,
                data
            );
            return response.data;
        } catch (error) {
            throw error;
        }
    };
};

export const getRolePermissions = (id) => {
    return async (dispatch) => {
        try {
            const moduleList = await getApiCall(API_CONSTANTS.GET_MODULES);
            const response = await getApiCall(
                API_CONSTANTS.GET_ROLE_PERMISSIONS + "/" + id
            );
            let permissionList = moduleList?.data?.result;
            moduleList?.data?.result?.forEach((obj1, index1) => {
                response?.data?.result?.modules?.forEach((obj2) => {
                    if (obj1?.id === obj2?.module_id) {
                        permissionList[index1] = {
                            ...permissionList[index1],
                            permissions: obj2?.permissions,
                        };
                    }
                });
            });
            dispatch({
                type: GET_MODULE_LIST,
                payload: permissionList || moduleList,
            });
        } catch (error) {
            throw error;
        }
    };
};

export const updateRolePermission = (data) => {
    return async (dispatch, getState) => {
        try {
            const currentState = getState();
            let permissionList = currentState.auth?.userLoggedIn;
            const response = await putApiCall(
                API_CONSTANTS.UPDATE_ROLE_PERMISSIONS,
                data
            );
            let newList = [];
            if (
                response.status === 200 &&
                permissionList?.user?.role_id === data.role_id
            ) {
                permissionList?.scope?.forEach((obj1) => {
                    data?.modules?.forEach((obj2) => {
                        if (obj1.module_id === obj2.module_id) {
                            newList.push({
                                ...obj1,
                                role_permissions: obj2.permissions,
                            });
                        }
                    });
                });
                await dispatch(updateUserPermission(newList));
            }
            return response.data;
        } catch (error) {
            throw error;
        }
    };
};

export const setModuleList = (data) => {
    return async (dispatch) => {
        dispatch({ type: SET_MODULE_LIST, payload: data });
    };
};

export const changeRoleViewType = (data) => {
    return async (dispatch) => {
        dispatch({ type: CHANGE_ROLE_VIEW_TYPE, payload: data });
    };
};

export const setCustomerPageLimit = (data) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: SET_ROLE_PER_PAGE,
                payload: data,
            });
        } catch (error) {
            throw error;
        }
    };
};

export const setCurrentPage = (pageNumber) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: SET_CURRENT_PAGE,
                payload: pageNumber,
            });
        } catch (error) {
            throw error;
        }
    };
};
