import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Slide from "@mui/material/Slide";
import CustomizationTable from "./CustomizationTable";
import { importEmailTemplates } from "../../../../../store/Action/ContractorEmailAction";
import ButtonComponent from "../../../../../components/ButtonComponent";
import DialogComponent from "../../../../../components/DialogsBox/DialogComponent";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const ImportDialog = (props) => {
    const {
        openImport,
        importEmailTemplates,
        contractor_id,
        setState,
    } = props;
    const handleClose = () => {
        setState((prevState) => ({ ...prevState, openImport: false }));
    };

    const [isChecked, setIsChecked] = React.useState([]);

    const onClickOnImport = async () => {
        let data = {
            contractor_id: contractor_id,
            templateids: isChecked,
        };
        const res = await importEmailTemplates(data);

        if (res.payload?.status === 200) {
            handleClose();
            setState((prevState) => ({
                ...prevState,
                tosterContent: {
                    message: res?.payload?.message,
                    status: res?.payload?.status,
                },
            }));
        }
    };

    return (
        <DialogComponent
            className="dialogBoxMain"
            open={openImport}
            direction="up"
            TransitionComponent={Transition}
            handleClose={handleClose}
            title={"Email Template"}
            content={
                <CustomizationTable
                    isChecked={isChecked}
                    setIsChecked={setIsChecked}
                    sx={{
                        boxShadow: "0px 0px 10px 4px rgba(59, 59, 59, 0.1)",
                        borderRadius: "10px",
                    }}
                />
            }
            action={
                isChecked.length !== 0 ? (
                    <ButtonComponent
                        title={"Import"}
                        autoFocus
                        className="button_contained"
                        onClick={onClickOnImport}
                    />
                ) : (
                    <ButtonComponent
                        title={"Cancel"}
                        autoFocus
                        className="button_contained"
                        onClick={handleClose}
                    />
                )
            }
        />
    );
};

const mapStateToProps = (state) => {
    return {
        saveTemplateId: state.saveTemplateId,
        emailTemplateList: state.contractor.emailTemplateList,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ importEmailTemplates }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ImportDialog);
