import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import FileUploadComponents from "../../../components/FileUpload";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import TextFieldsOutlinedIcon from "@mui/icons-material/TextFieldsOutlined";
import TextFieldComponent from "../../../components/TextField/TextFieldComponent";

const FileUpload = (props) => {
    const {
        state,
        setState,
        children,
        text,
        className,
        validation,
        data,
        isRead,
        handleChange,
        isEdit,
    } = props;

    const handleFileUpload = (file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file[0]);
        let newData = state?.formData;
        let index = newData?.findIndex((obj) => obj.id === data?.id);
        newData[index] = {
            ...newData[index],
            formValues: file.map((fileObj) =>
                Object.assign(fileObj, {
                    preview: URL.createObjectURL(fileObj),
                })
            ),
        };
        reader.onload = () => {
            let fileUrl = reader.result;
            newData[index] = {
                ...newData[index],
                formValues: [
                    {
                        ...newData[index].formValues[0],
                        content: fileUrl,
                        type: file[0].type,
                        name: file[0].name,
                    },
                ],
            };
            setState((prevState) => ({ ...prevState, formData: newData }));
        };
        setState((prevState) => ({ ...prevState, formData: newData }));
    };

    const handleDelete = (event) => {
        event.stopPropagation();
        let newData = state?.formData;
        let index = newData?.findIndex((obj) => obj.id === data?.id);
        newData[index] = { ...newData[index], formValues: null };
        setState((prevState) => ({ ...prevState, formData: newData }));
    };

    const handlerIsEdit = () => {
        if (isEdit) {
            setState((prevState) => ({ ...prevState, editLabel: true }));
        }
    };

    const onBlur = () => {
        setState((prevState) => ({ ...prevState, editLabel: false }));
    };

    return (
        <Grid container>
            <Grid item xs={12} className={"elementItemStyle"}>
                <TextFieldComponent
                    id="fileUploadLabel"
                    sx={{
                        display:
                            state?.selectedEle?.selected && state?.editLabel
                                ? "block"
                                : "none",
                        color: state?.fontColor,
                        "& .MuiInputBase-input": {
                            color: state?.fontColor,
                        },
                    }}
                    onBlur={onBlur}
                    onChange={handleChange}
                    value={data?.label}
                    className="elementHeadingTextField"
                    autoFocus={true}
                />
                <Typography
                    display={
                        state?.selectedEle?.selected && state?.editLabel
                            ? "none"
                            : "block"
                    }
                    className="elementHeading"
                    sx={{ color: state?.fontColor }}
                    variant="h5"
                    onClick={handlerIsEdit}
                >
                    {data.label}
                </Typography>
                {children}
            </Grid>
            <Grid item container spacing={2}>
                <Grid item xs={9}>
                    <Box className={className}>
                        {isRead && data?.formValues?.length ? (
                            <Grid container>
                                <Grid item xs={12}>
                                    <TextFieldsOutlinedIcon
                                        sx={{ fontSize: 80 }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    {data?.formValues?.length && (
                                        <Typography>
                                            {data?.formValues[0]?.filename ||
                                                data?.formValues[0]?.name ||
                                                ""}
                                        </Typography>
                                    )}
                                </Grid>
                            </Grid>
                        ) : isEdit ? (
                            <Grid
                                container
                                display={"flex"}
                                justifyContent={"center"}
                                sx={{ cursor: "no-drop" }}
                            >
                                <Grid
                                    item
                                    xs={12}
                                    display={"flex"}
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                >
                                    <CloudUploadOutlinedIcon />
                                </Grid>
                                <Typography className={"fileUploadText"}>
                                    {"Drag and drop a " +
                                        text +
                                        " here or click"}
                                </Typography>
                            </Grid>
                        ) : (
                            <FileUploadComponents
                                validation={validation}
                                file={data?.formValues}
                                keyName={"files"}
                                setFiles={setState}
                                disabled={isRead}
                                handleFileUpload={handleFileUpload}
                                handleDelete={handleDelete}
                                uploadIcon={
                                    <CloudUploadOutlinedIcon
                                        disabled={isRead}
                                    />
                                }
                                text={
                                    "Drag and drop a " + text + " here or click"
                                }
                                showClose={!isRead}
                            />
                        )}
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default FileUpload;
