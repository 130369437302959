import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Navbar from "../../common/Header/Navbar";
import PageHeader from "../../common/PageHeader";
import RolesPermissionTable from "./RolesPermissionTable";
import HeaderPagination from "../../common/PaginationSection/HeaderPagination";
import ButtonComponent from "../../components/ButtonComponent";
import LoadingComponent from "../../components/Loading/LoadingComponent";
import { ROUTES } from "../../router/RouteList";
import {
    createRolePermission,
    updateRolePermission,
    getRolePermissions,
    setModuleList,
} from "../../store/Action/RolesModuleAction";
import Footer from "../../common/Footer/Footer";
import {Translate } from 'react-auto-translate';
const ManageModulePermission = (props) => {
    const {
        moduleList,
        createRolePermission,
        updateRolePermission,
        getRolePermissions,
        setModuleList,
    } = props;
    const location = useLocation();
    const navigate = useNavigate();
    const [state, setState] = useState({
        modulesData: moduleList || null,
        roleData: location?.state?.roleData || null,
        isLoading: false,
    });
    const { modulesData, roleData, isLoading } = state;

    if (moduleList?.length && !modulesData) {
        setState((prevState) => ({
            ...prevState,
            modulesData: moduleList || null,
        }));
    }
    if (location?.state?.roleData?.id && !roleData) {
        setState((prevState) => ({
            ...prevState,
            roleData: location?.state?.roleData,
        }));
    }

    useEffect(() => {
        async function getRoleDataWithPermission() {
            setState((prevState) => ({ ...prevState, isLoading: true }));
            const path = window.location.pathname.split("/");
            await getRolePermissions(roleData?.id, path[2]);
            setState((prevState) => ({ ...prevState, isLoading: false }));
        }
        if (location?.state?.type && !modulesData && !moduleList) {
            getRoleDataWithPermission();
        }
    }, [location, getRolePermissions, roleData, modulesData, moduleList]);

    async function handleSave(type) {
        setState((prevState) => ({ ...prevState, isLoading: true }));
        let data = {
            role_id: roleData?.id || "",
            modules: modulesData.map((obj) => ({
                module_id: obj.id,
                permissions: {
                    read: obj?.permissions?.read || false,
                    create: obj?.permissions?.create || false,
                    edit: obj?.permissions?.edit || false,
                    delete: obj?.permissions?.delete || false,
                },
            })),
        };
        let res;
        if (type === "edit") {
            res = await updateRolePermission(data);
        } else {
            res = await createRolePermission(data);
            if (res) navigate(ROUTES.MANAGE_ROLES);
        }
        setState((prevState) => ({ ...prevState, isLoading: false }));
    }

    const handleSearch = (data) => {
        let newList = modulesData;
        if (data !== "") {
            newList = moduleList.filter((obj) =>
                obj.name?.toLowerCase()?.includes(data.toLowerCase())
            );
            setState((prevState) => ({
                ...prevState,
                modulesData: newList || moduleList,
            }));
        } else {
            setState((prevState) => ({
                ...prevState,
                modulesData: moduleList,
            }));
        }
    };

    return (
        <>
        <Navbar />
        {isLoading ? (
            <LoadingComponent sx={{ height: "600px" }} />
        ) : (
            <Box sx={{ px: { md: 7, xs: 1 }, pb: 4, mt: 1 }}>
                <Grid container spacing={1} sx={{ display: "flex" }}>
                    <Grid item xs={12} md={8.5} sm={10}>
                        <PageHeader
                            showSearchBar={true}
                            title={<Translate>{state?.roleData?.name}</Translate>}
                            subTitle={<Translate>{state?.roleData?.users_count}</Translate>}
                            handleSearch={handleSearch}
                        />
                    </Grid>
                    <Grid item xs={12} md={3.5} sm={2} mt={10}>
                        <HeaderPagination oneLine={true} />
                    </Grid>
                </Grid>
                <RolesPermissionTable
                    modulesData={modulesData}
                    setState={setState}
                    disabled={
                        location?.state?.type !== "view" ? false : true
                    }
                />
                <Box
                    display={"flex"}
                    gap={2}
                    sx={{
                        justifyContent: { md: "flex-end", xs: "center" },
                        my: 2,
                    }}
                >
                    <ButtonComponent
                        title={<Translate>{"Cancel"}</Translate>}
                        onClick={() => {
                            navigate(ROUTES.MANAGE_ROLES);
                            setModuleList(null);
                        }}
                    />
                    {location?.state?.type !== "view" && (
                        <ButtonComponent
                            title={
                                location?.state?.type === "edit"
                                    ? <Translate>{"Update"}</Translate>
                                    : <Translate>{"Create"}</Translate>
                            }
                            onClick={() =>
                                handleSave(location?.state?.type)
                            }
                        />
                    )}
                </Box>
            </Box>
        )}
        <Footer />
    </>
    
    );
};

const mapStateToProps = (state) => {
    return { moduleList: state?.roles?.moduleList };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            createRolePermission,
            updateRolePermission,
            getRolePermissions,
            setModuleList,
        },
        dispatch
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ManageModulePermission);
