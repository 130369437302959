import * as React from "react";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import "./Loading.scss";
import {Translate } from 'react-auto-translate';

function LoadingComponent(props) {
    const { sx, customLoader, customizeText = null } = props;
    return (
        <Box
        className={customLoader ? "loadingBoxCustom" : "loadingBox"}
        sx={sx}
      >
        <LinearProgress className={"loadingComponent"} />
        <Typography
          className="loadingTypo"
          sx={{ paddingX: customizeText ? "150px" : "0px" }}
        >
          <Translate>{customizeText ? customizeText : "Loading"}</Translate>
        </Typography>
      </Box>
    );
}

export default LoadingComponent;
