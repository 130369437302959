import React from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOffOutlinedIcon from "@mui/icons-material/PersonOffOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import IconButton from "@mui/material/IconButton";
import HierarchyLogo from "../../../assets/Images/Icons/HierarchyLogo.png";
import TableComponent from "../../components/Table/TableComponent";
import StaticNavbar from "./StaticNavbar";
import { customerList } from "./WhiteLabelUtils";
import { connect } from "react-redux";
function CustomerTable(props) {
    const {
        handleAdd,
        handleEdit,
        handleUsers,
        handleSettings,
        primary,
        secondary,
        tertiary,
        userLoggedIn,
    } = props;

    const headerList = [
        {
            id: 1,
            name: "Id",
            headerClass: "labelTableHeading",
            accessibleKey: "client_id",
            rowClass: "typo4",
            headerAlign: "center",
            rowAlign: "center",
        },
        {
            id: 2,
            name: "Customer Name",
            headerClass: "labelTableHeading customerNameTableHeading",
            accessibleKey: "display_name",
            rowClass: "labelTypo1",
            headerAlign: "center",
            rowAlign: "center",
            handleButton: handleEdit,
            buttonType: "view",
            color: primary,
        },
        {
            id: 3,
            name: "Company",
            headerClass: "labelTableHeading",
            accessibleKey: "company_name",
            rowClass: "labelCompanyName",
            headerAlign: "center",
            rowAlign: "center",
            color: secondary,
        },
        {
            id: 4,
            name:
                userLoggedIn?.language_data?.complianceworkflow
                    ?.conductor_type || "Conductor Type",
            headerClass: "labelTableHeading conductorTypeTableHeading",
            accessibleKey: "client_type",
            rowClass: "typo4",
            headerAlign: "center",
            rowAlign: "center",
        },
        {
            id: 5,
            name: userLoggedIn?.language_data?.comman?.status || "Status",
            headerClass: "labelTableHeading",
            accessibleKey: "status",
            headerAlign: "center",
            rowAlign: "center",
        },
        {
            id: 6,
            name: "No. of Users",
            headerClass: "labelTableHeading noOfUserTableHeading",
            accessibleKey: "users_count",
            rowClass: "typo4 noOfUserTableContent",
            headerAlign: "center",
            rowAlign: "center",
            handleButton: handleUsers,
        },
    ];

    return (
        <>
            <StaticNavbar
                customer={true}
                primary={primary}
                secondary={secondary}
                tertiary={tertiary}
            />
            <TableComponent
                headerList={headerList}
                // serialNo={{
                //     name: "S.No.",
                //     headerClass: "labelTableHeading",
                //     headerAlign: "center",
                //     rowClass: "typo4",
                //     rowAlign: "center",
                // }}
                extraHeaderRight={
                    <TableCell
                        align="center"
                        className="labelTableHeading actionTableHeading"
                    >
                        Action
                    </TableCell>
                }
                tableClass="labelCustomer"
                sx={{
                    transform: "scale(0.7)",
                }}
            >
                {customerList?.length &&
                    customerList.map((row, index) => (
                        <TableRow
                            key={row.name}
                            style={{
                                background: index % 2 !== 0 ? tertiary : "",
                            }}
                        >
                            {/* <TableCell align="center" className="typo4">
                                {index + 1}
                            </TableCell> */}
                            {headerList.map((obj) => (
                                <TableCell
                                    component="th"
                                    key={obj?.name}
                                    align={obj?.rowAlign}
                                    sx={{ color: obj.color }}
                                    className={
                                        obj?.rowClass
                                            ? obj?.rowClass
                                            : row?.status === "active"
                                            ? "typo3"
                                            : "typo5"
                                    }
                                    onClick={() =>
                                        obj.handleButton(
                                            row,
                                            obj?.buttonType
                                        ) || null
                                    }
                                >
                                    {row[obj?.accessibleKey]}
                                </TableCell>
                            ))}
                            <TableCell align="center">
                                <IconButton
                                    className="iconButton"
                                    onClick={() => handleAdd(row, "add")}
                                >
                                    <PersonAddAltOutlinedIcon />
                                </IconButton>
                                <IconButton
                                    className="iconButton"
                                    onClick={() => handleEdit(row, "edit")}
                                >
                                    <EditOutlinedIcon />
                                </IconButton>
                                <IconButton
                                    className="iconButton"
                                    onClick={() => handleAdd(row, "hierarchy")}
                                >
                                    <img
                                        className="hierarchyLogo"
                                        src={HierarchyLogo}
                                        alt={"HierarchyLogo"}
                                    />
                                </IconButton>
                                <IconButton
                                    className="iconButton"
                                    onClick={() =>
                                        handleSettings(row, "settings")
                                    }
                                >
                                    <SettingsOutlinedIcon />
                                </IconButton>
                                <IconButton className="iconButton">
                                    {row?.status === "active" ? (
                                        <PersonOffOutlinedIcon />
                                    ) : (
                                        <ManageAccountsOutlinedIcon />
                                    )}
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
            </TableComponent>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,
    };
};

export default connect(mapStateToProps, null)(CustomerTable);
