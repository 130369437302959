import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import TextFieldComponent from "../../../components/TextField/TextFieldComponent";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import ButtonComponent from "../../../components/ButtonComponent";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";

const RadioGroups = (props) => {
    const {
        state,
        setState,
        data,
        children,
        handleChange,
        isEdit,
        setMenuItem,
        handlerFieldChange,
        isRead,
    } = props;

    const [radioState, setRadioState] = useState({
        editQueLabel: false,
        editOptLabel: false,
        radioOptionEvent: null,
    });

    const { editQueLabel, editOptLabel, radioOptionEvent } = radioState;

    const onBlur = () => {
        setState((prevState) => ({ ...prevState, editLabel: false }));
    };

    const handlerChangeOption = (e) => {
        setRadioState((prevState) => ({
            ...prevState,
            radioOptionEvent: e,
        }));
    };

    const onChangeRadioOpt = () => {
        setRadioState((prevState) => ({
            ...prevState,
            radioOptions: [
                ...(state?.radioOptions ?? ""),
                radioOptionEvent.target.value,
            ],
            editOptLabel: false,
        }));
        setMenuItem(radioOptionEvent);
    };

    const handlerCloseOption = () => {
        setRadioState((prevState) => ({
            ...prevState,
            editOptLabel: false,
        }));
    };

    const handlerIsEdit = () => {
        if (isEdit) {
            setState((prevState) => ({ ...prevState, editLabel: true }));
        }
    };
    const romoveOpt = (index) => {
        let copyData = data?.fieldDetails?.options;
        copyData.splice(index, 1);
        setState((prevState) => ({
            ...prevState,
            radioOptions: copyData,
        }));
    };

    return (
        <Grid container>
            <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "space-between" }}
            >
                <TextFieldComponent
                    id="radioLabel"
                    sx={{
                        display:
                            state?.selectedEle?.selected && state?.editLabel
                                ? "block"
                                : "none",
                        color: state?.fontColor,
                        "& .MuiInputBase-input": {
                            color: state?.fontColor,
                        },
                    }}
                    onBlur={onBlur}
                    onChange={handleChange}
                    value={data?.label}
                    className="elementHeadingTextField"
                    autoFocus={true}
                />
                <Typography
                    display={
                        state?.selectedEle?.selected && state?.editLabel
                            ? "none"
                            : "block"
                    }
                    className="elementHeading"
                    sx={{ color: state?.fontColor }}
                    variant="h5"
                    onClick={handlerIsEdit}
                >
                    {data?.label}
                </Typography>
                {children}
            </Grid>
            <Grid item container spacing={2}>
                {isEdit ? (
                    <Grid item xs={12}>
                        <FormControl sx={{ width: "100%" }}>
                            {editQueLabel ? (
                                <TextFieldComponent
                                    id="radioQuestion"
                                    placeholder={"Enter Question"}
                                    sx={{
                                        display:
                                            state?.selectedEle?.selected &&
                                            editQueLabel
                                                ? "block"
                                                : "none",
                                        color: state?.fontColor,
                                    }}
                                    className="elementHeadingTextField font_16_600"
                                    onBlur={() =>
                                        setRadioState((prevState) => ({
                                            ...prevState,
                                            editQueLabel: false,
                                        }))
                                    }
                                    onChange={setMenuItem}
                                    name={"radioQuestion"}
                                    value={data?.fieldDetails?.question}
                                />
                            ) : (
                                <Typography
                                    className={"font_16_600"}
                                    sx={{
                                        display: editQueLabel
                                            ? "none"
                                            : "block",
                                        color: state?.fontColor,
                                    }}
                                    onClick={() =>
                                        setRadioState((prevState) => ({
                                            ...prevState,
                                            editQueLabel: true,
                                        }))
                                    }
                                >
                                    {data?.fieldDetails?.question ||
                                        " Type Question/Test"}
                                </Typography>
                            )}
                            <RadioGroup
                                row={true}
                                required={true}
                                display={"flex"}
                                sx={{ flexDirection: "column" }}
                            >
                                {data?.fieldDetails?.options?.map(
                                    (element, index) => {
                                        return (
                                            <FormControlLabel
                                                key={index}
                                                value={element}
                                                disabled={isRead ? false : true}
                                                control={
                                                    <Radio
                                                        sx={{
                                                            color: state?.fontColor,
                                                            "&, &.Mui-checked":
                                                                {
                                                                    color: state?.fontColor,
                                                                },
                                                        }}
                                                        checked={
                                                            data?.formValues
                                                                ?.radioOption ===
                                                            element
                                                        }
                                                    />
                                                }
                                                label={
                                                    <Grid
                                                        container
                                                        className="justifyBetweenClass"
                                                        gap={4}
                                                        sx={{
                                                            width: "150px",
                                                            color: state?.fontColor,
                                                        }}
                                                    >
                                                        <Grid item>
                                                            {element}
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            sx={{
                                                                color: state?.fontColor,
                                                            }}
                                                        >
                                                            <IconButton
                                                                sx={{
                                                                    color: state?.fontColor,
                                                                }}
                                                                onClick={() =>
                                                                    romoveOpt(
                                                                        index
                                                                    )
                                                                }
                                                            >
                                                                <HighlightOffIcon
                                                                    sx={{
                                                                        color: state?.fontColor,
                                                                    }}
                                                                />
                                                            </IconButton>
                                                        </Grid>
                                                    </Grid>
                                                }
                                                className="radio_label"
                                            />
                                        );
                                    }
                                )}
                            </RadioGroup>
                            <Box
                                sx={{
                                    mt: 1,
                                }}
                            >
                                {editOptLabel ? (
                                    <Box className="addOptionBox">
                                        <TextFieldComponent
                                            onChange={handlerChangeOption}
                                            className="textFieldFromBuilder"
                                            placeholder="Enter Option"
                                            name={"optionLabel"}
                                            id="RadioOption"
                                        />
                                        <Box className="addCancelButtonBox">
                                            <IconButton
                                                onClick={onChangeRadioOpt}
                                            >
                                                <CheckIcon />
                                            </IconButton>
                                            <IconButton
                                                onClick={handlerCloseOption}
                                            >
                                                <ClearIcon />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                ) : (
                                    <ButtonComponent
                                        variant="text"
                                        title={"Add More Option"}
                                        sx={{ color: state?.fontColor }}
                                        onClick={() => {
                                            setRadioState((prevState) => ({
                                                ...prevState,
                                                editOptLabel: true,
                                            }));
                                        }}
                                    />
                                )}
                            </Box>
                        </FormControl>
                    </Grid>
                ) : (
                    <Grid item xs={12}>
                        <Typography
                            className={"font_16_600"}
                            sx={{ color: state?.fontColor }}
                        >
                            {data?.fieldDetails?.question ||
                                " Type Question/Test"}
                        </Typography>
                        <FormControl>
                            <RadioGroup
                                row={true}
                                disabled={isRead}
                                required={true}
                            >
                                {data?.fieldDetails?.options?.map(
                                    (element, index) => {
                                        return (
                                            <FormControlLabel
                                                onChange={(event) =>
                                                    handlerFieldChange(
                                                        event,
                                                        data?.id
                                                    )
                                                }
                                                name={"radioOption"}
                                                key={index}
                                                value={element}
                                                control={
                                                    <Radio
                                                        sx={{
                                                            color: state?.fontColor,
                                                            "&, &.Mui-checked":
                                                                {
                                                                    color: state?.fontColor,
                                                                },
                                                        }}
                                                        checked={
                                                            data?.formValues
                                                                ?.radioOption ===
                                                            element
                                                        }
                                                    />
                                                }
                                                sx={{ color: state?.fontColor }}
                                                label={element}
                                                className="radio_label"
                                            />
                                        );
                                    }
                                )}
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};

export default RadioGroups;
