import { API_CONSTANTS } from "../../config/ApiConstants";
import { getApiCall, putApiCall } from "../../utils/Action";
export const GET_SCORING_CATEGORY_DROPDOWN = "GET_SCORING_CATEGORY_DROPDOWN";
export const GET_CIRCUIT_DATA = "GET_CIRCUIT_DATA";
export const UPDATE_CIRCUIT_DATA = "UPDATE_CIRCUIT_DATA";
export const GET_STATE_DATA = "GET_STATE_DATA";
export const getScoringDropdown = () => {
    return async (dispatch) => {
        try {
            const response = await getApiCall("common/get-dropdowns");
            dispatch({
                type: GET_SCORING_CATEGORY_DROPDOWN,
                payload: response?.data?.result || [],
            });
        } catch (error) {
            throw error;
        }
    };
};
export const getCircuitsData = (id) => {
    return async (dispatch) => {
        try {
            const response = await getApiCall(
                API_CONSTANTS.UPDATE_CIRCUIT_DATA + `/${id}`
            );
            dispatch({
                type: GET_CIRCUIT_DATA,
                payload: response?.data?.result || [],
            });
        } catch (error) {
            throw error;
        }
    };
};
export const getStateData = (id) => {
    return async (dispatch) => {
        try {
            const response = await getApiCall(
                API_CONSTANTS.UPDATE_State_DATA + `/${id}`
            );
            dispatch({
                type: GET_STATE_DATA,
                payload: response?.data?.result || [],
            });
        } catch (error) {
            throw error;
        }
    };
};
export const updateCircuitsData = (id, data) => {
    return async (dispatch) => {
        try {
            const response = await putApiCall(
                API_CONSTANTS.UPDATE_CIRCUIT_DATA + `/${id}`,
                data
            );

            if (response.status === 201) {
                return dispatch({
                    type: GET_CIRCUIT_DATA,
                    payload: response?.data?.result,
                });
            }
        } catch (error) {
            throw error;
        }
    };
};
export const updateStateData = (id, data) => {
    return async (dispatch) => {
        try {
            const response = await putApiCall(
                API_CONSTANTS.UPDATE_State_DATA + `/${id}`,
                data
            );

            if (response.status === 201) {
                return dispatch({
                    type: GET_STATE_DATA,
                    payload: response?.data,
                });
            }
        } catch (error) {
            throw error;
        }
    };
};
