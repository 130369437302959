import React from "react";
import { Grid, MenuItem } from "@mui/material";
import { Fragment } from "react";
import DropDownComponent from "../../../../components/DropDown/DropDownComponent";
import TextFieldComponent from "../../../../components/TextField/TextFieldComponent";
import Typography from "@mui/material/Typography";
import CheckboxComponents from "../../../../components/Checkbox/CheckboxComponent";
import { finalReviewArray } from "../../../../utils/CommonData";

const FinalReview = () => {
    return (
        <Fragment>
            <Grid container spacing={2}>
                <Grid item md={6} lg={6}>
                    <DropDownComponent label="Decision" fullWidth={true}>
                        {finalReviewArray.map((item, index) => {
                            return (
                                <MenuItem value={item} key={index}>
                                    {item}
                                </MenuItem>
                            );
                        })}
                    </DropDownComponent>
                </Grid>
                <Grid item md={6} lg={6}>
                    <DropDownComponent label="Reason" fullWidth={true}>
                        {finalReviewArray.map((item, index) => {
                            return (
                                <MenuItem value={item} key={index}>
                                    {item}
                                </MenuItem>
                            );
                        })}
                    </DropDownComponent>
                </Grid>
            </Grid>
            <Grid container spacing={2} marginY={0}>
                <Grid item md={12} lg={12}>
                    <TextFieldComponent
                        label="Comment"
                        fullWidth={true}
                        id="finalReviewComment"
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2} marginY={0}>
                <Grid item md={12} lg={12}>
                    <Typography variant="span" fontSize={20} fontWeight={600}>
                        Append Comment
                    </Typography>
                    <CheckboxComponents
                        sx={{ mb: 0.5, ml: 1 }}
                        checked={true}
                    />
                </Grid>
            </Grid>
        </Fragment>
    );
};

export default FinalReview;
