import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { Grid, Typography } from "@mui/material";
import CheckboxComponents from "../../../components/Checkbox/CheckboxComponent";
import { agreeOrNotOptions } from "../../../utils/CommonData";
import ButtonComponent from "../../../components/ButtonComponent";
import MenuItem from "@mui/material/MenuItem";
import DropDownComponent from "../../../components/DropDown/DropDownComponent";
import { ROUTES } from "../../../router/RouteList";
import { useNavigate } from "react-router-dom";
import { getStartTest } from "../../../store/Action/AssessmentSettingAction";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getApiCall } from "../../../utils/Action";


const Ppbconfirm = (props) => {
    const {
        testType,
        selectedId,
        getStartTest,
        defaultTypeId,
        roleId,
        guid,
        commonDropdown,
        setLoading,
        selectedCustomer,
        contractor_id,
    } = props;
   
    const [accept, setAccept] = useState(true);
    const [state, setState] = useState({
        company_info:  selectedCustomer?.company_info
                });

    const navigate = useNavigate();

    const handleBtnClick = async (selectedId) => {
        let userID = selectedCustomer?.id
        let updateValue = state?.company_info?.principle_place_of_business;

        let response = await getApiCall(`/clients/update/ppob/${userID}/${updateValue}`);

        getQuestionList(roleId, guid, defaultTypeId, selectedId);
    };
    const {company_info} = state;

    const getQuestionList = async (roleId, guid, defaultTypeId, selectedId) => {
        setLoading(true);
        await getStartTest(selectedId, guid, defaultTypeId, roleId);
        setLoading(false);
        navigate(ROUTES.TAKE_TEST + "/" + guid + "/" + contractor_id);
    };

    const disabledBtnSx = {
        background: "rgba(59, 59, 59, 0.5) !important",
    };
    const acknowledgeText =
        "For independent contractor compliance testing purposes in connection with the services you will perform, you are required to identify your principal place of business (PPB).  Your PPB is the nerve center of your business.The nerve center is the place where the actual or predominate direction, control, and coordination of your business occurs.";

    return (
        <Fragment>
            <Grid container spacing={2} marginBottom={10}>
                <Grid item md={12} lg={12}>
                    <Typography className="font_18_600">
                        {acknowledgeText}
                    </Typography>
                </Grid>
                <Grid
                    item
                    md={12}
                    lg={12}
                    sx={{ paddingLeft: "8px !important" }}
                >
                    <DropDownComponent
                        id="principlePlaceofbusiness"
                        label={"Principal Place of Business"}
                        name={"principlePlaceofbusiness"}
                        fullWidth={true}
                        onChange={(event) => {  
                            console.log('###', event.target)          
                            setState((prevState) => ({
                                ...prevState,
                                company_info: {
                                    ...company_info,
                                    principle_place_of_business:
                                        event.target.value,
                                },
                            }))
                        }
                        }
                        // disabled={mode !== "view" ? false : true}
                        required={true}
                        value={company_info?.principle_place_of_business || ""}
                        children={commonDropdown?.states?.map((option) => (
                            <MenuItem
                                id={`principlePlaceofbusiness${option.id}`}
                                key={option.id}
                                value={option.id}
                            >
                                {option.label}
                            </MenuItem>
                        ))}
                    />

                    {/* {agreeOrNotOptions.map((option, index) => {
                        return (
                            <Fragment key={index}>
                                <CheckboxComponents
                                    sx={{ mb: 0.5 }}
                                    value={option.value}
                                    onChange={() => setAccept(option.value)}
                                    checked={option.value ? accept : !accept}
                                />
                                <Typography
                                    className="font_16_600"
                                    variant="span"
                                    marginRight={index === 0 ? 3 : 0}
                                    sx={{ cursor: "pointer" }}
                                    onClick={() => setAccept(option.value)}
                                >
                                    {option.label}
                                </Typography>
                            </Fragment>
                        );
                    })} */}
                </Grid>
                <Grid item md={12} lg={12} textAlign="end">
                    <ButtonComponent
                        title="Submit"
                        disabled={!accept}
                        onClick={() => handleBtnClick(selectedId)}
                        sx={!accept ? disabledBtnSx : null}
                    />
                </Grid>
            </Grid>
        </Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        testQuestionList: state?.assessmentSettings?.testQuestionList,
        commonDropdown: state?.auth?.commonDropdown,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ getStartTest }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Ppbconfirm);
