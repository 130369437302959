import React, { Fragment, useState } from "react";
import CircleIcon from "@mui/icons-material/Circle";
import { MenuItem } from "@mui/material";
import { Divider } from "@mui/material";
import { Typography } from "@mui/material";
import { ListItemAvatar } from "@mui/material";
import { ListItemIcon } from "@mui/material";
import { ListItemText } from "@mui/material";
import { Avatar } from "@mui/material";
import { IconButton } from "@mui/material";
import { Grid } from "@mui/material";
import Rectangle from "../../../assets/Images/Avatar/Rectangle.png";
import { ReactComponent as DeleteIcon } from "../../../assets/Images/Icons/DeleteNotification.svg";
import { ReactComponent as DeleteHoverIcon } from "../../../assets/Images/Icons/DeleteNotificationHover.svg";
import moment from "moment/moment";

function NotificationItem(props) {

    const notificationDeleteClickHandler = async (e, id) => {
        e.stopPropagation();
        await props.deleteAction(id);
    };

    const menuItemClickHandler = async (id) => {
        await props.readSingleNotification(id);
    };

    const calculateDateDifference = (prevoiusDate) => {
        const currentTime = moment().utc(true);
        const preTime = moment(prevoiusDate);
        return moment.duration(currentTime.diff(preTime)).humanize();
    };

    const [isHover, setHover] = useState(false);

    return (
        <Fragment>
            <MenuItem
                className="notificationCustomMenuItem"
                onClick={() => menuItemClickHandler(props.item.id)}
            >
                <Grid container spacing={2}>
                    <Grid item lg={2} md={2} sm={2}>
                        <Grid
                            container
                            spacing={2}
                            className="grid"
                            alignContent="center"
                            marginLeft="-25px"
                            paddingTop={0.5}
                        >
                            <Grid item lg={4} md={4} className="grid">
                                <ListItemIcon className="listItemIconForNotification">
                                    {!props.item.read_at ? (
                                        <CircleIcon
                                            fontSize="small"
                                            className="circleIcon"
                                        />
                                    ) : (
                                        ""
                                    )}
                                </ListItemIcon>
                            </Grid>
                            <Grid item lg={4} md={4} className="grid">
                                <ListItemAvatar className="notificationListItemAvatar">
                                    <Avatar
                                        className="notificationAvatar"
                                        src={
                                            props.item.data.user &&
                                            props.item.data.user.details &&
                                            props.item.data.user.details
                                                .photograph
                                                ? props.item.data.user.details
                                                      .photograph
                                                : Rectangle
                                        }
                                    />
                                </ListItemAvatar>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item lg={9} md={9} sm={10}>
                        <Grid
                            container
                            justifyContent="center"
                            className="grid"
                        >
                            <Grid
                                item
                                lg={12}
                                md={12}
                                sm={12}
                                paddingBottom={"8px"}
                            >
                                <ListItemText
                                    classes={{
                                        primary: "notificationText",
                                    }}
                                    className="notificationText"
                                >
                                    {/* <Typography className="notificationText" variant="span"></Typography> */}
                                    <Typography
                                        className="notificationMess"
                                        dangerouslySetInnerHTML={{
                                            __html: props?.item?.data?.msg,
                                        }}
                                    ></Typography>
                                </ListItemText>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} className="grid">
                                <Typography variant="subtitle2">
                                    {calculateDateDifference(
                                        props.item.created_at
                                    )}
                                </Typography>
                            </Grid>
                        </Grid>
                        {/* <ListItemText
                            className="notificationText"
                            classes={{
                                primary: "notificationText",
                            }}
                        >

                        </ListItemText> */}
                    </Grid>
                </Grid>
                <Typography
                    variant="body2"
                    color="text.secondary"
                    lineHeight="0"
                >
                    <IconButton
                        onClick={(e) =>
                            notificationDeleteClickHandler(e, props.item.id)
                        }
                        onMouseOver={() => setHover(true)}
                        onMouseOut={() => setHover(false)}
                    >
                        {isHover ? <DeleteHoverIcon /> : <DeleteIcon />}
                    </IconButton>
                </Typography>
            </MenuItem>
            <Divider light={true} />
        </Fragment>
    );
}

export default NotificationItem;
