export const customerList = [
    {
        id: 1,
        guid: "1a650d8f",
        client_id: "L1C-DC-0001",
        client_type: "Direct Conductor",
        first_name: "John doe",
        display_name: "John Doe",
        company_name: "test",
        company_website: "http://dsad.com",
        email: "johndoe@l1c.com",
        phone: "1234567890",
        logo: null,
        status: "active",
        users_count: 3,
    },
    {
        id: 2,
        guid: "a42f1dd9",
        client_id: "L1C-IDC-0002",
        client_type: "In-Direct Conductor",
        first_name: "Giacomo",
        display_name: "Joel Davenport",
        company_name: "Murphy and Glover Traders",
        company_website: "https://knjkcs.cce",
        email: "nexomilix@mailinator.com",
        phone: "531",
        logo: null,
        status: "active",
        users_count: 0,
    },
    {
        id: 3,
        guid: "5a652767",
        client_id: "L1C-DC-0003",
        client_type: "Direct Conductor",
        first_name: "Beatrice",
        display_name: "Beatrice Potter",
        company_name: "Wilcox Bonner Associates",
        company_website: "https://www.example.com",
        email: "famyfijuq@mailinator.com",
        phone: "+1 (873) 576-4876",
        logo: "https://backend.retengine.co/app/logos/3logo.png",
        status: "inactive",
        users_count: 2,
    },
    {
        id: 4,
        guid: "5a652767",
        client_id: "L1C-DC-0004",
        client_type: "In-Direct Conductor",
        first_name: "Beatrice",
        display_name: "Lattice Danny",
        company_name: "Wilcox Bonner Associates",
        company_website: "https://www.example.com",
        email: "latticedanny@mailinator.com",
        phone: "+1 (873) 576-4876",
        logo: "https://backend.retengine.co/app/logos/3logo.png",
        status: "active",
        users_count: 6,
    },
    {
        id: 5,
        guid: "5a652767",
        client_id: "L1C-DC-0005",
        client_type: "Direct Conductor",
        first_name: "Beatrice",
        display_name: "John Doe",
        company_name: "Wilcox Bonner Associates",
        company_website: "https://www.example.com",
        email: "johndoe@mailinator.com",
        phone: "+1 (873) 576-4876",
        logo: "https://backend.retengine.co/app/logos/3logo.png",
        status: "active",
        users_count: 6,
    },
];
