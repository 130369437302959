import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useLocation, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Card from "@mui/material/Card";
import Pagination from "@mui/material/Pagination";
import PageHeader from "../../common/PageHeader";
import Navbar from "./../../common/Header/Navbar";
import "../../../assets/styles/Container/WhiteLabeling.scss";
import NoImage from "../../../assets/Images/Logo/NoImage.png";
import ColorPicker from "../../../assets/Images/Logo/ColorPicker.png";
import ButtonComponent from "../../components/ButtonComponent";
import { ROUTES } from "../../router/RouteList";
import CheckboxComponents from "../../components/Checkbox/CheckboxComponent";
import LoginPage from "./LoginPage";
import CustomerPage from "./CustomerPage";
import CustomerTable from "./CustomerTable";
import ColorPickerComponents from "../../components/ColorPicker";
import ComplianceTable from "./ComplianceTable";
import {
    updateWhiteLabelling,
    getWhiteLabelById,
    setWhiteLabel,
} from "../../store/Action/WhiteLabelAction";
import TextFieldComponent from "../../components/TextField/TextFieldComponent";
import FileUpload from "../../components/FileUpload";
import LoadingComponent from "./../../components/Loading/LoadingComponent";
import Footer from "../../common/Footer/Footer";

function WhiteLabeling(props) {
    const {
        whiteLabel,
        updateWhiteLabelling,
        selectedCustomer,
        userLoggedIn,
        getWhiteLabelById,
        selectedWhiteLabel,
        setWhiteLabel,
    } = props;
    const location = useLocation();
    const navigate = useNavigate();
    const customer = location?.state;
    console.log(location, 'customer')
    const newString = selectedCustomer
        ? selectedCustomer?.company_website?.split(".")
        : customer !== null && customer?.company_website?.split(".");
    const newDomain = newString[0]?.split("//");
    const [search, setSearch] = useState('')
    const [state, setState] = useState({
        open: false,
        color_scheme: selectedWhiteLabel?.color_scheme || null,
        selectedColor: null,
        defaultColor: "",
        type: "primary",
        page: 1,
        logo: "",
        favicon: "",
        domain: newDomain[1] || selectedWhiteLabel?.domain || "",
        client_id: selectedWhiteLabel?.client_id || selectedCustomer?.id || "",
        use_company_name: selectedWhiteLabel?.use_company_name,
        loading: false,
    });
    const {
        open,
        color_scheme,
        type,
        page,
        logo,
        favicon,
        domain,
        loading,
        use_company_name,
        selectedColor,
        defaultColor,
    } = state;
    useEffect(() => {
        async function apiCall() {
            setState((prevState) => ({ ...prevState, loading: true }));
            const pathName = window.location.pathname.split("/");
            const res = await getWhiteLabelById(
                selectedCustomer?.white_labelling?.id || pathName[2]
            );
            setState((prevState) => ({
                ...prevState,
                ...res?.payload,
                color_scheme: res?.payload?.color_scheme,
                loading: false,
            }));
        }
        apiCall();
    }, [getWhiteLabelById, selectedCustomer]);

    function handleClose() {
        setState((prevState) => ({ ...prevState, open: false }));
    }

    function handleOpen(type, hexColor) {
        setState((prevState) => ({
            ...prevState,
            open: true,
            type: type,
            defaultColor: hexColor,
        }));
    }

    function handleColorChange(event, type) {
        setState((prevState) => ({
            ...prevState,
            type: type,
            selectedColor: event?.hex,
            defaultColor: event?.hex,
        }));
    }
    function updateColor() {
        setState((prevState) => ({
            ...prevState,
            open: false,
            color_scheme: { ...color_scheme, [type]: selectedColor },
        }));
    }
    function handlePageChange(event) {
        if (event.currentTarget.ariaLabel?.includes("next")) {
            setState((prevState) => ({
                ...prevState,
                page: parseInt(page) + 1,
            }));
        } else if (event.currentTarget.ariaLabel?.includes("previous")) {
            setState((prevState) => ({
                ...prevState,
                page: parseInt(page) - 1,
            }));
        } else {
            setState((prevState) => ({
                ...prevState,
                page: parseInt(event.target.textContent),
            }));
        }
    }

    async function handleUpdate() {
        setState((prevState) => ({ ...prevState, loading: true }));
        const res = await updateWhiteLabelling(state);
        if (whiteLabel.client_id === userLoggedIn?.client_id) {
            document.documentElement.style.setProperty(
                "--default-primary-color",
                state?.color_scheme?.primary
            );
            document.documentElement.style.setProperty(
                "--default-secondary-color",
                state?.color_scheme?.secondary
            );
            document.documentElement.style.setProperty(
                "--default-tertiary-color",
                state?.color_scheme?.tertiary
            );
        }
        setState((prevState) => ({ ...prevState, loading: false }));
        if (res) navigate(ROUTES.HOME);
    }

    function renderPages() {
        switch (page) {
            case 1:
                return (
                    <LoginPage
                        primary={color_scheme?.primary}
                        secondary={color_scheme?.secondary}
                        tertiary={color_scheme?.tertiary}
                    />
                );
            case 2:
                return (
                    <CustomerPage
                        primary={color_scheme?.primary}
                        secondary={color_scheme?.secondary}
                        tertiary={color_scheme?.tertiary}
                    />
                );
            case 3:
                return (
                    <CustomerTable
                        primary={color_scheme?.primary}
                        secondary={color_scheme?.secondary}
                        tertiary={color_scheme?.tertiary}
                    />
                );
            case 4:
                return (
                    <ComplianceTable
                        primary={color_scheme?.primary}
                        secondary={color_scheme?.secondary}
                        tertiary={color_scheme?.tertiary}
                    />
                );
            default:
                return null;
        }
    }
    function handleCancel() {
        setWhiteLabel(null);
        navigate(ROUTES.HOME);
    }

    function handleLogoUpload(file) {
        const reader = new FileReader();
        reader.readAsDataURL(file[0]);
        let newData;
        newData = file.map((fileObj) =>
            Object.assign(fileObj, {
                preview: URL.createObjectURL(fileObj),
            })
        );
        reader.onload = () => {
            let fileUrl = reader.result;
            newData = {
                ...newData[0],
                content: fileUrl,
                filename: file[0].name,
                type: file[0].type,
            };
            setState((prevState) => ({ ...prevState, logo: [newData] }));
        };
        setState((prevState) => ({ ...prevState, logo: newData }));
    }

    function handleFaviconUpload(file) {
        const reader = new FileReader();
        reader.readAsDataURL(file[0]);
        let newData;
        newData = file.map((fileObj) =>
            Object.assign(fileObj, {
                preview: URL.createObjectURL(fileObj),
            })
        );
        reader.onload = () => {
            let fileUrl = reader.result;
            newData = {
                ...newData[0],
                content: fileUrl,
                filename: file[0].name,
                type: file[0].type,
            };
            setState((prevState) => ({ ...prevState, favicon: [newData] }));
        };
        setState((prevState) => ({ ...prevState, favicon: newData }));
    }

    return (
        <>
            <Navbar />
            <ColorPickerComponents
                open={open}
                handleClose={handleClose}
                handleColorChange={handleColorChange}
                defaultColor={defaultColor}
                type={type}
                updateFormColor={updateColor}
            />
            <Box sx={{ px: { md: 7, xs: 1 } }}>
                <PageHeader title={"Settings"} setSearch= {setSearch} />
                {loading ? (
                    <LoadingComponent sx={{ height: "600px", width: "95%" }} />
                ) : (
                    <Grid container spacing={8} className="labelSidebar">
                        <Grid item xs={3}>
                            <Grid container>
                                <Grid item xs={11}>
                                    <Typography className="labelHeading">
                                        Logo
                                    </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <IconButton>
                                        <FileUpload
                                            file={state}
                                            keyName={"logo"}
                                            setFiles={setState}
                                            validation={"image/*"}
                                            uploadIcon={
                                                <EditOutlinedIcon className="labelEditIcon" />
                                            }
                                            handleFileUpload={handleLogoUpload}
                                        />
                                    </IconButton>
                                </Grid>
                                <Grid className="labelBox">
                                    <img
                                        alt="logo"
                                        src={
                                            logo
                                                ? logo[0]?.preview || logo
                                                : customer
                                                ? customer?.logo
                                                : NoImage
                                        }
                                        className="labelImage"
                                    />
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={11}>
                                    <Typography className="labelHeading">
                                        Favicon
                                    </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <IconButton>
                                        <FileUpload
                                            file={state}
                                            keyName={"favicon"}
                                            setFiles={setState}
                                            uploadIcon={
                                                <EditOutlinedIcon className="labelEditIcon" />
                                            }
                                            validation="image/*"
                                            handleFileUpload={
                                                handleFaviconUpload
                                            }
                                        />
                                    </IconButton>
                                </Grid>
                                <div className="labelBox">
                                    <img
                                        alt="favicon"
                                        src={
                                            favicon
                                                ? favicon[0]?.preview || favicon
                                                : customer
                                                ? customer?.favicon
                                                : NoImage
                                        }
                                        className="labelImage"
                                    />
                                </div>
                            </Grid>
                            <Typography className="mb-5">
                                For best visual results we suggest a logo &
                                favicon that reaches the indicators on all four
                                sides.
                            </Typography>
                            <Grid container>
                                <Grid item>
                                    <CheckboxComponents
                                        checked={
                                            use_company_name ? true : false
                                        }
                                        onChange={(event) =>
                                            setState((prevState) => ({
                                                ...prevState,
                                                use_company_name:
                                                    event.target.checked,
                                            }))
                                        }
                                        sx={{
                                            paddingLeft: "0px",
                                            paddingBottom: "0px",
                                            paddingRight: "10px",
                                            paddingTop: "4px",
                                        }}
                                        size="medium"
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography className="font_16_600">
                                        Use{" "}
                                        {userLoggedIn?.language_data
                                            ?.complianceworkflow
                                            ?.company_name || "Company Name"}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Typography className="mb-10">
                                Use your{" "}
                                {userLoggedIn?.language_data?.complianceworkflow
                                    ?.company_name || "Company Name"}{" "}
                                instead of logo.
                            </Typography>
                            <TextFieldComponent
                                id="whiteLabelingDomainName"
                                label={"Domain Name"}
                                fullWidth={true}
                                value={domain}
                                disabled={true}
                                onChange={(event) =>
                                    setState((prevState) => ({
                                        ...prevState,
                                        domain: event.target.value,
                                    }))
                                }
                            />
                            <Typography className="labelHeading mb-5 mt-5">
                                Change Color Palette
                            </Typography>
                            <Grid container spacing={3} mb={3}>
                                <Grid item xs={4} mb={3}>
                                    <Typography>Primary</Typography>
                                    <Paper
                                        className="colorPaper"
                                        sx={{
                                            backgroundColor:
                                                color_scheme?.primary,
                                        }}
                                    />
                                    <img
                                        src={ColorPicker}
                                        className="colorImage"
                                        alt="Color picker"
                                        onClick={() =>
                                            handleOpen(
                                                "primary",
                                                color_scheme?.primary
                                            )
                                        }
                                    />
                                </Grid>
                                <Grid item xs={4} mb={3}>
                                    <Typography>Secondary</Typography>
                                    <Paper
                                        className="colorPaper"
                                        sx={{
                                            backgroundColor:
                                                color_scheme?.secondary,
                                        }}
                                    />
                                    <img
                                        src={ColorPicker}
                                        className="colorImage"
                                        alt="Color picker"
                                        onClick={() =>
                                            handleOpen(
                                                "secondary",
                                                color_scheme?.secondary
                                            )
                                        }
                                    />
                                </Grid>
                                <Grid item xs={4} mb={3}>
                                    <Typography>Tertiary</Typography>
                                    <Paper
                                        className="colorPaper"
                                        sx={{
                                            backgroundColor:
                                                color_scheme?.tertiary,
                                        }}
                                    />
                                    <img
                                        src={ColorPicker}
                                        className="colorImage"
                                        alt="Color picker"
                                        onClick={() =>
                                            handleOpen(
                                                "tertiary",
                                                color_scheme?.tertiary
                                            )
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography className="labelHeading">
                                {userLoggedIn?.language_data?.formstudio
                                    ?.preview || "Preview"}
                            </Typography>
                            <Card className="previewCard">{renderPages()}</Card>
                            <Grid className="labelIframe">
                                <Pagination
                                    count={4}
                                    variant="outlined"
                                    shape="rounded"
                                    className="labelFooter"
                                    defaultPage={1}
                                    selected={true}
                                    page={page}
                                    onChange={handlePageChange}
                                />
                            </Grid>
                            <Grid
                                container
                                spacing={2}
                                justifyContent={"end"}
                                marginTop={"1px"}
                                marginBottom={8}
                            >
                                <Grid item>
                                    <ButtonComponent
                                        title={"Cancel"}
                                        variant="outlined"
                                        onClick={() => handleCancel()}
                                    />
                                </Grid>
                                <Grid item>
                                    <ButtonComponent
                                        title={"Update"}
                                        variant="contained"
                                        onClick={handleUpdate}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Box>
            <Footer />
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        whiteLabel: state?.whiteLabel,
        selectedCustomer: state?.customer?.selectedCustomer,
        userLoggedIn: state.auth?.userLoggedIn,
        selectedWhiteLabel: state?.whiteLabel?.selectedWhiteLabel,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        { updateWhiteLabelling, getWhiteLabelById, setWhiteLabel },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(WhiteLabeling);
