import React from "react";
import DialogContentText from "@mui/material/DialogContentText";
import ButtonComponent from "../ButtonComponent";
import DialogComponent from "./DialogComponent";

const CommonDeleteDialog = (props) => {
    const {
        open,
        setOpen,
        content,
        title,
        cancelButtonTitle,
        cancelButtonAction,
        submitButtonTitle,
        submitButtonAction,
        submitButtonParameter,
        id,

    } = props;

    const handleDelete = () => {
         props?.onClick();
        setOpen(false);
    };

    return (
        <DialogComponent
            id={id}
            className="dialog-add"
            open={open || false}
            handleClose={cancelButtonAction}
            title={title}
            sxaction={{ paddingX: 2, paddingBottom: 2 }}
            content={
                <>
                    <DialogContentText
                        sx={{ color: "#3B3B3B", alignItems: "center" }}
                        id="alert-dialog-description"
                        className="font_18_600"
                    >
                        {content}
                    </DialogContentText>
                </>
            }
            action={
                <>
                    <ButtonComponent
                        title={cancelButtonTitle ||   "Cancel"}
                        variant={"outlined"}
                        onClick={() => cancelButtonAction()}
                        id={`${id}CancelButton`}
                    />
                    <ButtonComponent
                        id={`${id}DeleteButton`}
                        title={submitButtonTitle || "Delete"}
                        onClick={() => {
                            cancelButtonTitle === "Cancel" ||
                            cancelButtonTitle === "No"
                                ? submitButtonAction(submitButtonParameter)
                                : handleDelete();
                        }}
                    />
                </>
            }
        />
    );
};

export default CommonDeleteDialog;
