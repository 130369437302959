import React, { useEffect, useState } from "react";
import { BrowserRouter, HashRouter } from "react-router-dom";
import { Toaster, ToastBar, toast } from "react-hot-toast";
import CircularProgress from "@mui/material/CircularProgress";
import Provider from "react-redux/es/components/Provider";
import CloseIcon from "@mui/icons-material/Close";
import Router from "./router/Router";
import store from "./store/Index";
import {Translator} from 'react-auto-translate';

function App() {
    const [languageId, setLanguageId] = useState(localStorage.getItem('languageCode'));


    const toasterClass = {
        minWidth: "434px",
        fontWeight: "500",
        justifyContent: "space-between",
        display: "flex",
        padding: "14px 10px 14px 10px",
        borderRadius: "4px",
        wordSpacing: "1px",
    };


    useEffect(() => {
        const handleStorageChange = (e) => {
          if (e.key === 'languageCode') {
            setLanguageId(e.newValue);
          }
        };
        window.addEventListener('storage', handleStorageChange);
        return () => {
          window.removeEventListener('storage', handleStorageChange);
        };
      }, [localStorage.getItem('user_id'), localStorage.getItem('languageId')]);



    return (
     
        <div>
            <Toaster
                position="bottom-left"
                toastOptions={{
                    className: "toastbar",
                    icon: null,
                    success: {
                        style: {
                            ...toasterClass,
                            background: "#36b336",
                            color: "#ffff",
                        },
                        duration: 5000,
                    },
                    loading: {
                        style: {
                            minWidth: "250px",
                            fontWeight: "600",
                            justifyContent: "center",
                            display: "flex",
                            padding: "14px 10px 14px 10px",
                            borderRadius: "5px",
                            wordSpacing: "1px",
                            transition: "all 0.5s ease-out",
                            background: "#F2F2F2",
                            color: "#3B3B3B",
                        },
                        position: "top-center",
                        icon: <CircularProgress />,
                    },
                    error: {
                        style: {
                            ...toasterClass,
                            background: "#FF4D4D",
                            color: "#ffff",
                        },
                        duration: 5000,
                    },
                }}
            >
                {(t) => (
                    <ToastBar toast={t}>
                        {({ message }) => (
                            <>
                                <p className="toast_message">{message}</p>
                                <div className="toast_close_icon_display">
                                    {t.type !== "loading" && (
                                        <CloseIcon
                                            className="toast_close_icon"
                                            onClick={() => toast.dismiss(t.id)}
                                        />
                                    )}
                                </div>
                            </>
                        )}
                    </ToastBar>
                )}
            </Toaster>
            <Provider store={store}>
                <HashRouter>
                    <Router store={store} />
                </HashRouter>
            </Provider>
        </div>

    );
}

export default App;
