import React, { useContext, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    getCommentsAction,
    addCommetAction,
} from "../../../store/Action/CommentsAction";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Badge from "@mui/material/Badge";
import Typography from "@mui/material/Typography";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableComponent from "../../../components/Table/TableComponent";
import Tooltip from "../../../components/ToolTip/TooltipComponent";
import { textField_style } from "./Style";
import { Grid } from "@mui/material";
import SuccessfullModel from "../../../components/DialogsBox/SuccessfullModel";
import { ReactComponent as MessageIcon } from "../../../../assets/Images/Icons/MessageIcon.svg";
import "../../../../assets/styles/Container/BasicTableStyle.scss";
import ButtonComponent from "../../../components/ButtonComponent";

function DialogComments(props) {
    const {
        guid,
        contractor_id,
        getCommentsAction,
        comments,
        count,
        callFrom,
        commentSection,
        addCommetAction,
    } = props;
    const [open, setOpen] = useState(false);
    const [openSuccesfullModel, setSuccesfullModel] = useState(false);
    const [modelContain, setmodelContain] = useState("");
    const [addComment, setAddComment] = useState({
        guid: guid,
        comment: "",
        user_id: contractor_id,
        section: "",
        attachment_path: null,
    });
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleAddComment = async () => {
        if (addComment.comment !== "" && addComment.section !== null) {
            const res = await addCommetAction(
                guid,
                addComment.section,
                addComment
            );
            if (res !== undefined) {
                await getCommentsAction(guid);
                setAddComment((prevState) => ({
                    ...prevState,
                    comment: "",
                }));
                setSuccesfullModel(true);
                setmodelContain("Comment added successfully");
            }
        } else {
            setmodelContain("Something went wrong!");
            setSuccesfullModel(true);
        }
    };
    const headerList = [
        {
            id: 1,
            name: "Section",
            headerClass: "tableHeading",
            accessibleKey: "section",
            rowClass: "typo4",
            headerAlign: "left",
            rowAlign: "left",
        },
        {
            id: 2,
            name: "Comment",
            headerClass: "tableHeading",
            accessibleKey: "comment",
            rowClass: "commentCell",
            headerAlign: "left",
            rowAlign: "left",
        },
        {
            id: 3,
            name: "Created",
            headerClass: "tableHeading",
            accessibleKey: "created_at",
            rowClass: "typo4",
            headerAlign: "center",
            rowAlign: "center",
        },
    ];

    const handleCloseDialog = () => {
        setSuccesfullModel(false);
    };
    return (
        <div>
            <SuccessfullModel
                open={openSuccesfullModel}
                handleClose={handleCloseDialog}
                content={modelContain}
            />
            <Tooltip
                arrow={true}
                title="Comments"
                placement="top"
                sx={{ left: 10, top: 5 }}
            >
                <ButtonComponent title={  <Badge
                        badgeContent={callFrom === "Footer" ? 0 : count}
                        onClick={handleClickOpen}
                        className="commentsBadge"
                    >
                        <MessageIcon />
                    </Badge>} variant="text" sx={{ color: "#3B3B3B" }} />
            </Tooltip>
            <Dialog
                className="dialogBoxMain"
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle
                    sx={{
                        fontSize: "20px",
                        fontWeight: "600",
                        lineHeight: "25px",
                        minWidth: "800px",
                    }}
                    id="Comments-dialog-title"
                    onClose={handleClose}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={9}>
                            <Typography className="font_20_600">
                                Comments Overview
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <IconButton
                                aria-label="close"
                                onClick={handleClose}
                                className="closeButton"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <TableComponent headerList={headerList}>
                        {comments &&
                            comments.length > 0 &&
                            comments.map((row, index) => (
                                <TableRow
                                    key={row.name}
                                    className={
                                        index % 2 !== 0
                                            ? "tertiaryBackground"
                                            : ""
                                    }
                                >
                                    {headerList.map((obj) => (
                                        <TableCell
                                            component="th"
                                            key={obj?.name}
                                            align={obj?.rowAlign}
                                            className={obj?.rowClass}
                                        >
                                            {row[obj?.accessibleKey]}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                    </TableComponent>
                </DialogContent>
                {callFrom !== "Footer" && (
                    <DialogActions>
                        <Grid
                            container
                            direction={"column"}
                            className="grid_bg"
                            p={2}
                        >
                            <Grid item>
                                <TextareaAutosize
                                    className="commentTextArea"
                                    aria-label="Comment textarea"
                                    label="Add Comment"
                                    placeholder="Add Comment"
                                    required
                                    sx={{ width: "100%", marginRight: 1 }}
                                    InputProps={{
                                        disableUnderline: true,
                                        style: {
                                            ...textField_style,
                                        },
                                    }}
                                    name="comment"
                                    value={addComment.comment}
                                    onChange={(event) => {
                                        const { name, value } = event.target;
                                        setAddComment((prevState) => ({
                                            ...prevState,
                                            [name]: value,
                                            section: commentSection,
                                        }));
                                    }}
                                />
                            </Grid>
                            <Grid
                                item
                                mt={2}
                                display={"flex"}
                                justifyContent={"flex-end"}
                            >
                                <ButtonComponent title={"Comment"}  onClick={handleAddComment}
                                    className="button_contained" />
                                {/* <Button
                                    onClick={handleAddComment}
                                    className="button_contained"
                                >
                                    Comment
                                </Button> */}
                            </Grid>
                        </Grid>
                    </DialogActions>
                )}
            </Dialog>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        customer: state.customer,
        user: state.user,
    };
};
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getCommentsAction,
            addCommetAction,
        },
        dispatch
    );
};
export default React.memo(
    connect(mapStateToProps, mapDispatchToProps)(DialogComments)
);
