import * as Actions from "../Action/MqsAction";
const initialState = {
    mqsQuestion: [],
    stateDropdown: null,
    testQuestion: null,
    mqsResoponseDropdown: null,
    selectedQuestion: null,
    mqsDefination: [],
    conflictQuestion: [],
    viewResponses: [],
};
const MqsReducer = (state = initialState, action) => {
    switch (action?.type) {
        case Actions.GET_MQS_CATEGORY: {
            return {
                ...state,
                mqsQuestion: action.payload,
            };
        }
        case Actions.STORE_CATEGORY: {
            return {
                ...state,
                mqsQuestion: [...state.mqsQuestion, action.payload],
            };
        }
        case Actions.GET_TEST_QUESTION: {
            return {
                ...state,
                testQuestion: action.payload,
            };
        }
        case Actions.GET_MQS_DROPDOWN: {
            return {
                ...state,
                stateDropdown: action.payload,
            };
        }
        case Actions.ADD_STORE_QUESTION: {
            return {
                ...state,
                testQuestion: [...state.testQuestion, action.payload],
            };
        }
        case Actions.SET_SELECTED_QUESTION: {
            return {
                ...state,
                selectedQuestion: action.payload,
            };
        }
        case Actions.UPDATE_QUESTION: {
            let list = state.testQuestion;
            let index = list.findIndex((obj) => obj.id === action.payload.id);
            if (index !== -1) list[index] = action.payload;
            return {
                ...state,
                testQuestion: list,
            };
        }
        case Actions.DELETE_QUESTION: {
            let questionList = state?.testQuestion?.data?.filter(
                (obj) => obj?.id !== action?.payload
            );
            return {
                ...state,
                testQuestion: {
                    ...state?.testQuestion,
                    data: questionList,
                },
            };
        }
        case Actions.GET_MQS_RESPONSES: {
            return {
                ...state,
                mqsResoponseDropdown: action.payload,
            };
        }
        case Actions.MQS_DEFINATION: {
            return {
                ...state,
                mqsDefination: action.payload,
            };
        }
        case Actions.GET_QUESTION_LIST: {
            return {
                ...state,
                testQuestion: action.payload,
            };
        }
        case Actions.GET_QUESTIONS_IN_BALANCING_CATEGORY: {
            return {
                ...state,
                testQuestion: action.payload,
            };
        }
        case Actions.GET_QUESTION_DETAIL: {
            return {
                ...state,
                selectedQuestion: action.payload,
            };
        }
        case Actions.SLIED_TO_ANOTHER_QUESTION: {
            let copyQuestionList = state.mqsQuestion;
            return {};
        }
        case Actions.GET_CONFLICT_QUESTIONS: {
            return {
                ...state,
                conflictQuestion: action.payload,
            };
        }
        case Actions.GET_VIEW_QUESTION_RESPONSES: {
            return {
                ...state,
                viewResponses: action.payload,
            };
        }

        default: {
            return state;
        }
    }
};
export default MqsReducer;
