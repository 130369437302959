import React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
    getFormById,
    formSubmissionAction,
} from "../../store/Action/FormStudioAction";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import RenderElements from "./RenderElements";
// import SuccessfullModel from "../../components/DialogsBox/SuccessfullModel";
import LoadingComponent from "../../components/Loading/LoadingComponent";
import "../../../assets/styles/Container/FormStudio.scss";
import {Translate } from 'react-auto-translate';
const SubmissionForm = (props) => {
    const { getFormById, formDetails, formSubmissionAction } = props;
    const paramsData = useParams();
    const [state, setState] = useState({
        formData: formDetails?.form_data || "",
        fontColor: formDetails?.font_color || "",
        formColor: formDetails?.form_color || "",
        files: null,
        images: null,
        time: null,
        openSuccesModel: { open: false, content: "" },
        checkBoxOption: [],
        isLoading: false,
        logo: formDetails?.logo || null,
    });
    const { formData, openSuccesModel, checkBoxOption, isLoading } = state;
    useEffect(() => {
        async function callApi() {
            setState((prevState) => ({ ...prevState, isLoading: true }));
            await getFormById(parseInt(paramsData.id));
            setState((prevState) => ({ ...prevState, isLoading: false }));
        }
        if (paramsData?.id && !formDetails) {
            callApi();
        }
    }, [getFormById]);

    if (formDetails && formData === "") {
        setState((prevState) => ({
            ...prevState,
            formData: formDetails?.form_data,
            fontColor: formDetails?.font_color,
            formColor: formDetails?.form_color,
            logo: formDetails?.logo,
        }));
    }

    const handlerFieldChange = (event, id, name = null) => {
        let copyFormDetails = formData;
        let index = copyFormDetails?.findIndex((obj) => obj.id === id);
        if (index !== -1) {
            let value;
            if (name) {
                if (name == "date") {
                    let date = new Date(event);
                    value = moment(date).format("YYYY-MM-DD");
                }
                if (name == "time") {
                    value = {
                        formatedTime: moment(event["$d"]).format("LT"),
                        timeEvent: event,
                    };
                }
                if (name == "signature") {
                    value = event;
                }
                if (name == "checkBoxOption") {
                    const index = state.formData.findIndex((item) => {
                        return item.id == id;
                    });
                    let options = [];
                    if (index !== -1) {
                        options =
                            state?.formData[index]?.formValues
                                ?.checkBoxOption || [];
                        if (options.includes(event.target.value)) {
                            options = options.filter((item) => {
                                return item !== event.target.value;
                            });
                        } else {
                            options.push(event.target.value);
                        }
                    } else {
                        options.push(event.target.value);
                    }
                    let obj = { id: id, data: options };
                    let arr = state?.formValues?.checkBoxOption || [];
                    arr = [...arr, ...options];
                    value = arr;
                }
                copyFormDetails[index] = {
                    ...copyFormDetails[index],
                    formValues: {
                        ...copyFormDetails[index]?.formValues,
                        [name]: value,
                    },
                };
            } else {
                copyFormDetails[index] = {
                    ...copyFormDetails[index],
                    formValues: {
                        ...copyFormDetails[index]?.formValues,
                        [event.target.name]: event.target.value,
                    },
                };
            }
        }
        setState((prevState) => ({ ...prevState, formData: copyFormDetails }));
    };
    const submitSubmission = async () => {
        let data = { form_id: formDetails?.id, data: formDetails?.form_data };
        const res = await formSubmissionAction(data);
        if (res?.status == "201") {
            setState((prevState) => ({
                ...prevState,
                openSuccesModel: { open: true, content: res?.data?.message },
            }));
        }
    };
    const closeSuccesModel = () => {
        setState((prevState) => ({
            ...prevState,
            openSuccesModel: { open: false, content: "" },
        }));
    };
    return (
        <Box
        sx={{
            background: formDetails?.page_color,
            minHeight: "100vh",
        }}
    >
        {openSuccesModel?.open ? (
            <Box className="centerPositionBox" sx={{ height: "600px" }}>
                <Typography sx={{ fontSize: "24px", color: "green" }}>
                    <Translate>Thank You !</Translate>
                </Typography>
            </Box>
        ) : isLoading ? (
            <LoadingComponent sx={{ height: "600px" }} />
        ) : (
            <Grid container spacing={0} justifyContent={"center"}>
                <Grid
                    item
                    width={"50%"}
                    sx={{ width: { sx: "100%", md: "70%" } }}
                >
                    <Box
                        display={"flex"}
                        justifyContent={"center"}
                        sx={{ marginY: 3 }}
                        className="highlightGradient"
                    >
                        <Typography
                            sx={{ textTransform: "capitalize" }}
                            className="font_24_600"
                        >
                            {formDetails?.name}
                        </Typography>
                    </Box>
                    <RenderElements
                        state={state}
                        formElements={formData}
                        isRead={false}
                        setState={setState}
                        handlerFieldChange={handlerFieldChange}
                        submitSubmission={submitSubmission}
                    />
                </Grid>
            </Grid>
        )}
    </Box>
    
    );
};

const mapStateToProps = (state) => {
    return {
        formDetails: state.formStudio.formDetails,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ getFormById, formSubmissionAction }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(SubmissionForm);
