import React from "react";
import Autocomplete from "@mui/material/Autocomplete";

const AutoCompleteDropDown = (props) => {
    const {
        multiple,
        options,
        defaultValue,
        getOptionLabel,
        filterSelectedOptions,
        renderInput,
        onChange,
        disabled,
        className,
        value,
        fullWidth,
        sx,
        name,
        id,
        filterOptions,
    } = props;

    return (
        <Autocomplete
            value={value}
            sx={sx}
            fullWidth={fullWidth || false}
            className={className}
            multiple={multiple}
            options={options}
            disabled={disabled}
            getOptionLabel={getOptionLabel}
            defaultValue={defaultValue}
            renderInput={renderInput}
            onChange={onChange}
            filterSelectedOptions={filterSelectedOptions}
            filterOptions={filterOptions}
            name={name}
            id={id}
        />
    );
};

export default AutoCompleteDropDown;
