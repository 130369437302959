import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableFooter from "@mui/material/TableFooter";
import  TableSortLabel  from "@mui/material/TableSortLabel";

function TableComponent(props) {
    const {
        headerList,
        extraHeaderLeft,
        extraHeaderRight,
        serialNo,
        tableClass,
        sx,
        children,
        tableFooter,
        sortConfig,
        tableClassDiv,
        headSx
    } = props;

    return (
        <TableContainer component={Paper} sx={headSx} className={tableClass || ""}>
            <Table sx={sx} size="small" className={tableClassDiv}>
                <TableHead>
                    <TableRow>
                        {serialNo ? (
                            <TableCell
                                align={serialNo?.headerAlign}
                                className={serialNo?.headerClass}
                            >
                                {serialNo?.name}
                            </TableCell>
                        ) : null}
                        {extraHeaderLeft || null}
                        {headerList?.length &&
                            headerList.map((obj) => (
                                <TableCell
                                    key={obj?.id}
                                    align={obj?.headerAlign}
                                    className={obj?.headerClass}
                                    sx={obj.sx}
                                >

                                    {obj.sort ? <TableSortLabel
                                                        active={
                                                            sortConfig.key ===
                                                            obj.key
                                                        }
                                                        direction={
                                                            sortConfig.direction ||
                                                            "asc"
                                                        }
                                                        onClick={() =>
                                                            obj.sortFunction(obj.accessibleKey)
                                                        }
                                                    > {obj?.name}   </TableSortLabel>:
                                    obj?.name}
                                </TableCell>
                            ))}
                        {extraHeaderRight || null}
                    </TableRow>
                </TableHead>
                <TableBody>{children}</TableBody>
                {tableFooter ? <TableFooter>{tableFooter}</TableFooter> : null}
            </Table>
        </TableContainer>
    );
}

export default TableComponent;
