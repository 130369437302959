import React from "react";
import TableCell from "@mui/material/TableCell";
import { Box } from "@mui/system";
import Grid from "@mui/material/Grid";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import TooltipComponent from "../../../../../components/ToolTip/TooltipComponent";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ButtonComponent from "../../../../../components/ButtonComponent";
import { ReactComponent as DeleteIcon } from "../../../../../../assets/Images/Icons/DeleteIcon.svg";
import TableComponent from "../../../../../components/Table/TableComponent";
import { connect } from "react-redux";

function AssessmentPartiesTable(props) {
    const { 
        setWorkFlow, 
        workFlow ,
        tableList,
        setListItem,
        handleUpdate,
        userLoggedIn
    } = props;
    const handleEdit=(id,index)=> {
        const listItem = tableList.find((item) => item.id === id);
        setWorkFlow({
            ...workFlow,
            updateItem:listItem.id,
            openAssignUser: true,
            updateUser: listItem
        });
    }
    function handleAdd(event) {
        setWorkFlow({
            ...workFlow,
            openAssignUser: true,
        });
    }
    const deleteAction = (id) => {
        const listItem = tableList.find((item) => item.id === id);
        setListItem(listItem);
    };
    const headerList = [
        {
            id: 1,
            name: "Party Name",
            headerClass: "tableHeading customerNameTableHeading",
            accessibleKey: "party_name",
            rowClass: "typo4",
            headerAlign: "left",
            rowAlign: "left",
            buttonType: "view",
        },
        {
            id: 2,
            name: "Role",
            headerClass: "tableHeading",
            accessibleKey: "role",
            rowClass: "typo4",
            headerAlign: "center",
            rowAlign: "center",
        },
        {
            id: 3,
            name: "Email",
            headerClass: "tableHeading conductorTypeTableHeading",
            accessibleKey: "email",
            rowClass: "typo4",
            headerAlign: "center",
            rowAlign: "center",
        },
    ];

    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item md={12} lg={12} className="table-box-shadow">
                    <TableComponent
                        headerList={headerList}
                        extraHeaderRight={
                            <TableCell
                                align="center"
                                className="tableHeading actionTableHeading"
                            >
                                Action
                            </TableCell>
                        }
                    >
                        {tableList?.length > 0 &&
                            tableList.map((row, index) => (
                                <TableRow
                                    key={row.name}
                                    className={index % 2 !== 0 ? "tertiaryBackground" : ""}
                                >
                                    {headerList.map((obj) => (
                                        <TableCell
                                            component="th"
                                            key={obj?.name}
                                            align={obj?.rowAlign}
                                            className={
                                                obj?.rowClass
                                                    ? obj?.rowClass
                                                    : row?.status === "active"
                                                    ? "typo3"
                                                    : "typo5"
                                            }
                                            onClick={() =>
                                                obj.handleButton(
                                                    row,
                                                    obj?.buttonType
                                                ) || null
                                            }
                                        >
                                            {row[obj?.accessibleKey]}
                                        </TableCell>
                                    ))}
                                    <TableCell align="center">
                                        <TooltipComponent 
                                        >
                                            <IconButton
                                                className="iconButton"
                                                onClick={() =>
                                                    handleEdit(row.id,index)
                                                }
                                            >
                                                <EditOutlinedIcon />
                                            </IconButton>
                                        </TooltipComponent>
                                        <TooltipComponent
                                        >
                                            <IconButton
                                                className="iconButton"
                                                onClick={() =>
                                                    deleteAction(
                                                        row.id
                                                    )
                                                }
                                            >
                                                 <DeleteIcon />
                                            </IconButton>
                                        </TooltipComponent>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableComponent>
                </Grid>
                <Grid item md={12} lg={12} textAlign="end">
                    <ButtonComponent
                        title={userLoggedIn?.language_data?.comman?.add_btn || "Add"}
                        sx={{
                            mr: 2,
                        }}
                        variant="outlined"
                        onClick={(event)=>{handleAdd(event)}}
                    />
                    <ButtonComponent
                        title={userLoggedIn?.language_data?.comman?.edit_btn || "Update"}
                        onClick={handleUpdate}
                    />
                </Grid>
            </Grid>
        </Box>
    );
}

const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,
    };
};

export default connect(mapStateToProps, null) (AssessmentPartiesTable);
