import React from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import PersonOffOutlinedIcon from "@mui/icons-material/PersonOffOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import TooltipComponent from "../../components/ToolTip/TooltipComponent";
import { connect } from "react-redux";
import { Translate } from "react-auto-translate";
function UserCard(props) {
    const {
        user,
        handleDelete,
        handleEdit,
        handleSettings,
        userPermission,
        permissionDenied,
        fetchRoleName,
        userLoggedIn,
        status
    } = props;

    return (
        <Paper elevation={3} className="userCardPaper">
            <Grid container height={"100%"}>
                <Grid item xs={12} className="userCardHeader">
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography className="cardHeading">
                                <Translate>
                                    {user?.company_info?.name}
                                </Translate>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        <div className="userLogoDiv">
                            {user?.photograph ? (
                                <img
                                    src={user?.photograph}
                                    alt={<Translate>L1C compliance</Translate>}
                                    className="userCardImage"
                                />
                            ) : (
                                <Avatar
                                    className="userAvatarCardImage"
                                    variant="rounded"
                                >
                                    <PersonRoundedIcon className="userAvatarCardImageIcon" />
                                </Avatar>
                            )}
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className="userTypo1">
                            <b>
                                <Translate>
                                    {user?.first_name + " " + user?.last_name}
                                </Translate>
                            </b>
                        </Typography>
                    </Grid>
                    {/* <Grid
                        item
                        xs={12}
                        display={"flex"}
                        justifyContent={"center"}
                    >
                        <Typography className="userTypo2">
                            <Translate>{user?.user_title || "---"}</Translate>
                        </Typography>
                    </Grid> */}
                    <Grid item xs={12}>
                        <Typography
                            className={
                                user?.status === "active"
                                    ? "userTypo3"
                                    : "userTypo5"
                            }
                        >
                            <Translate>{user?.status || "---"}</Translate>
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        gap={6}
                        display={"flex"}
                        justifyContent={"center"}
                        mb={0.5}
                    >
                        <Typography className="userRole">
                            <Translate>{fetchRoleName(user?.role)}</Translate>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2} className="actionBtn">
                   {
                    status == 'inactive' ? null :
                   <>
                   <TooltipComponent
                        title={
                            <Translate>
                                {userLoggedIn?.language_data?.user?.view_user ||
                                    "View User"}
                            </Translate>
                        }
                        arrow={true}
                    >
                        <IconButton
                            className="iconButton"
                            onClick={() =>
                                userPermission?.read
                                    ? handleEdit(user, "view")
                                    : permissionDenied()
                            }
                        >
                            <RemoveRedEyeOutlinedIcon />
                        </IconButton>
                    </TooltipComponent>
                    <TooltipComponent
                        title={
                            <Translate>
                                {userLoggedIn?.language_data?.user
                                    ?.manage_user || "Edit User"}
                            </Translate>
                        }
                        arrow={true}
                    >
                        <IconButton
                            className="iconButton"
                            onClick={() =>
                                userPermission?.edit
                                    ? handleEdit(user, "edit")
                                    : permissionDenied()
                            }
                        >
                            <EditOutlinedIcon />
                        </IconButton>
                    </TooltipComponent>
                    </>
                    }
                    {userLoggedIn?.user &&
                        user?.id !== userLoggedIn?.user?.id && (
                            (userLoggedIn?.user?.role_id === 44 && user?.role_id === 3) ? null : (
                            <TooltipComponent
                                arrow={true}
                                title={
                                    user?.status !== "active" ? (
                                        <Translate>Activate Customer</Translate>
                                    ) : (
                                        <Translate>
                                            {userLoggedIn?.language_data?.user
                                                ?.deactivate_customer ||
                                                "Deactivate Customer"}
                                        </Translate>
                                    )
                                }
                            >
                                <IconButton
                                    className="iconButton"
                                    onClick={() =>
                                        userPermission?.delete
                                            ? handleDelete(user)
                                            : permissionDenied()
                                    }
                                >
                                    {user?.status !== "active" ? (
                                        <ManageAccountsOutlinedIcon />
                                    ) : (
                                        <PersonOffOutlinedIcon />
                                    )}
                                </IconButton>
                            </TooltipComponent>
                            )
                        )}
                    <TooltipComponent
                        title={<Translate>Settings</Translate>}
                        arrow={true}
                    >
                        <IconButton
                            className="iconButton"
                            onClick={() => handleSettings(user)}
                        >
                            <SettingsOutlinedIcon />
                        </IconButton>
                    </TooltipComponent>
                </Grid>
            </Grid>
        </Paper>
    );
}
const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,
    };
};
export default connect(mapStateToProps, null)(UserCard);
