import React, { useContext, useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Badge from "@mui/material/Badge";
import TooltipComponent from "./ToolTip/TooltipComponent";
import DialogComments from "../pages/ComplianceWorkflow/ContractorDetails/DialogComments";
import ContextProvider from "./Context/ContextProvider";
import "../../assets/styles/Container/AccordionCustom.scss";
import { Translate } from "react-auto-translate";

const AccordionCustom = (props) => {
    const {
        title,
        comments,
        customer,
        children,
        accordionSummary,
        showComment,
        className,
        detailsClassName,
        expand,
        showTooltip,
        tooltipTitle,
        tooltipCildren,
        id,
    } = props;
    const show = useContext(ContextProvider);
    const [expanded, setExpanded] = useState(false);

    useEffect(() => {
        if (expanded !== show) {
            setExpanded(show);
        } else {
            setExpanded(expanded);
        }
    }, [show]);
    
    return (
        <Accordion
            className={className || "accordion"}
            expanded={expand || expanded}
            square={true}
            id={id}
        >
            {accordionSummary ? (
                accordionSummary
            ) : (
                <AccordionSummary
                    className={className || "accordionSummary"}
                    expandIcon={<ExpandMoreIcon />}
                    onClick={() => {
                        expanded ? setExpanded(false) : setExpanded(true);
                    }}
                >
                    <Grid container justifyContent="start">
                        <Grid
                            item
                            md={11}
                            sm={6}
                            xs={5}
                            display={"flex"}
                            alignItems={"center"}
                        >
                            <Typography className="accordionTitle">
                                <Translate>{title}</Translate>
                            </Typography>
                            {showTooltip ? (
                                <TooltipComponent
                                    arrow={true}
                                    title={tooltipTitle}
                                    placement="right"
                                    followCursor={false}
                                >
                                    {tooltipCildren}
                                </TooltipComponent>
                            ) : null}
                        </Grid>
                        {showComment ? (
                            <Grid
                                item
                                md={1}
                                sm={1}
                                xs={1}
                                display={"flex"}
                                justifyContent={"flex-end"}
                            >
                                <Badge
                                    badgeContent={0}
                                    sx={{
                                        mr: 1,
                                        "& .MuiBadge-badge": {
                                            color: "white",
                                            backgroundColor: "#f26a21",
                                        },
                                    }}
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    <DialogComments
                                        commentSection={title}
                                        guid={customer?.guid}
                                        contractor_id={customer?.id}
                                        comments={comments}
                                        count={comments?.length}
                                    />
                                </Badge>
                            </Grid>
                        ) : null}
                    </Grid>
                </AccordionSummary>
            )}
            <AccordionDetails
                className={detailsClassName || "accordionDetails"}
            >
                {children}
            </AccordionDetails>
        </Accordion>
    );
};

export default AccordionCustom;
