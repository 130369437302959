import React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
// import TooltipComponent from "../../../../components/ToolTip/TooltipComponent";
import TooltipComponent from "../../components/ToolTip/TooltipComponent";
import { Icon } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";


const RadioButtonGroup = (props) => {
    const {
        row,
        options,
        title,
        defaultValue,
        onChange,
        labelClass,
        className,
        disabled,
        value,
        name,
        required,
        sx,
        id,
        infoIcon,
        infoTitle
    } = props;
    return (
        <FormControl>
            <FormLabel className={`${labelClass || "font_16_600"} text-black`}>
                {title}
            </FormLabel>
            <RadioGroup
                row={row}
                defaultValue={defaultValue}
                onChange={onChange}
                disabled={disabled}
                value={value}
                required={required}
                name={name}
                sx={sx}
                id={id}
                infoIcon={infoIcon}
                infoTitle={infoTitle}
            >
                {options?.length > 0 &&
                    options?.map((element, index) => {
                        return (
                            <>
                                <FormControlLabel
                                    id={`${id}${index}`}
                                    key={index}
                                    value={element?.value}
                                    control={<Radio />}
                                    label={element?.label}
                                    className={`${className || "font_16_600"}`}
                                    disabled={disabled}
                                />
                                {infoIcon ?
                                    <TooltipComponent
                                        arrow={true}
                                        title={element?.defination}
                                        placement="top"
                                        sx={{
                                            left: 10,
                                            top: 5,
                                        }}
                                    >
                                        <Icon
                                            className="radioInfoIcon"
                                            sx={{
                                                alignItems: "center",
                                                marginRight: 4,

                                            }}
                                        >
                                            <InfoIcon sx={{ alignItems: "center" }} />
                                        </Icon>
                                    </TooltipComponent>
                                    : ""}
                            </>
                        );
                    })}
            </RadioGroup>
        </FormControl>
    );
};

export default RadioButtonGroup;
