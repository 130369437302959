import { API_CONSTANTS } from "../../config/ApiConstants";
import {
    postApiCall,
    getApiCall,
    putApiCall,
    deleteApiCall,
} from "../../utils/Action";
export const CREATE_FORM = "CREATE_FORM";
export const GET_FORM = "GET_FORM";
export const UPDATE_FORM = "UPDATE_FORM";
export const DELETE_FORM_BY_ID = "DELETE_FORM_BY_ID";
export const GET_FORM_BY_ID = "GET_FORM_BY_ID";
export const SET_DROW_DOWN_ITEM = "SET_DROW_DOWN_ITEM";
export const GET_FORM_SUBMISSIONS = "GET_FORM_SUBMISSIONS";
export const EXPORT_SUBMISSION = "EXPORT_SUBMISSION";
export const SUBMIT_SUBMISSION = "SUBMIT_SUBMISSION";
export const DCT_IN_ASSESMENT = "DCT_IN_ASSESMENT";
export const MAKE_DEFAULT = 'MAKE_DEFAULT'

export const createForm = (data) => {
    return async (dispatch) => {
        try {
            const response = await postApiCall(API_CONSTANTS.CREATE_FORM, data);
            if (response.status === 201) {
                return dispatch({
                    type: CREATE_FORM,
                    payload: response.data.result,
                    response: response,
                });
            }
        } catch (error) {
            throw error;
        }
    };
};
export const getForms = () => {
    return async (dispatch) => {
        try {
            const response = await getApiCall(API_CONSTANTS.CREATE_FORM);
            dispatch({
                type: GET_FORM,
                payload: response?.data?.result || [],
            });
        } catch (error) {
            throw error;
        }
    };
};

export const makeDefault = (id) => {
    return async (dispatch) => {
        try {
            const response = await getApiCall(API_CONSTANTS.MAKE_DEFAULT + `/${id}`);
            dispatch({
                type: MAKE_DEFAULT,
                payload: response?.data?.result || [],
            });
        } catch (error) {
            throw error;
        }
    };
};
export const updateForm = (data) => {
    return async (dispatch) => {
        try {
            const response = await putApiCall(
                API_CONSTANTS.CREATE_FORM + "/" + data.id,
                data
            );
            return dispatch({
                type: UPDATE_FORM,
                payload: response?.data?.result || [],
                response: response,
            });
        } catch (error) {
            throw error;
        }
    };
};
export const deleteForm = (id) => {
    return async (dispatch) => {
        try {
            const response = await deleteApiCall(
                API_CONSTANTS.CREATE_FORM + "/" + id
            );
            return dispatch({
                type: DELETE_FORM_BY_ID,
                payload: id || [],
                response: response,
            });
        } catch (error) {
            throw error;
        }
    };
};
export const getFormById = (id) => {
    return async (dispatch) => {
        try {
            const response = await getApiCall(
                API_CONSTANTS.CREATE_FORM + "/" + id
            );
            return dispatch({
                type: GET_FORM_BY_ID,
                payload: response?.data?.result,
            });
        } catch (error) {
            throw error;
        }
    };
};
export const dropDownItems = (text, id) => {
    return async (dispatch) => {
        try {
            return dispatch({
                type: SET_DROW_DOWN_ITEM,
                payload: { text, id },
            });
        } catch (error) {}
    };
};
export const getSubmissionsById = (id) => {
    return async (dispatch) => {
        try {
            const response = await getApiCall(
                API_CONSTANTS.FORM_SUBMISSION + "/" + id
            );
            return dispatch({
                type: GET_FORM_SUBMISSIONS,
                payload: response?.data?.result,
                response: response,
            });
        } catch (error) {
            throw error;
        }
    };
};
export const exportSubmissions = (id) => {
    return async (dispatch) => {
        try {
            const response = await getApiCall(
                API_CONSTANTS.EXPORT_SUBMISSION + "/" + id
            );
            return dispatch({
                type: EXPORT_SUBMISSION,
                payload: response,
            });
        } catch (error) {
            throw error;
        }
    };
};
export const formSubmissionAction = (data) => {
    return async () => {
        try {
            const response = await postApiCall(
                API_CONSTANTS.FORM_SUBMISSION,
                data
            );
            return response;
        } catch (error) {
            throw error;
        }
    };
};

export const getFormforAssesment = () => {
    return async (dispatch) => {
        try {
            const response = await getApiCall(
                API_CONSTANTS.GET_FORM_DETAILS
            );
            return dispatch({
                type: DCT_IN_ASSESMENT,
                payload: response?.data?.result,
            });
        } catch (error) {
            throw error;
        }
    };
};
