import React, { Fragment, useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { Link } from "@mui/material";
import { Typography } from "@mui/material";
import DescriptionDialog from "../../../../../components/DialogsBox/DescriptionDialog";

const TitleDescriptionComponent = (props) => {
    const { title, description } = props;
    const [visible, setVisible] = useState(false);
    const [open, setOpen] = useState(false);
    const wordCount = (str) => {
        return str.split(" ").length;
    };

    useEffect(() => {
        if (wordCount(description) > 50) {
            setVisible(true);
        }else{
            setVisible(false)
        }
    }, [description]);

    return (
        <Fragment>
            <Typography className="font_18_600 light-sea-green">
                {title}
            </Typography>
            <Typography className="font_16_600 mt-5" lineHeight={1.5}>
                {description && description <= 50
                    ? description
                    : description
                          .split(" ")
                          .slice(0, 49)
                          .join(" ")
                          .charAt(
                              description.split(" ").slice(0, 49).join(" ")
                                  .length - 1
                          ) === " "
                    ? description.split(" ").slice(0, 59).join(" ")
                    : description.split(" ").slice(0, 59).join(" ") + " "}
                {visible && <Link fontSize={16} sx={{cursor: 'pointer'}} onClick={()=> setOpen(true)}>Click here for more</Link>}
            </Typography>
            <DescriptionDialog open={open} setOpen={setOpen} description={description}/>
        </Fragment>
    );
};

TitleDescriptionComponent.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string
}

TitleDescriptionComponent.defaultProps = {
    title: '',
    description: ''
};

export default TitleDescriptionComponent;
