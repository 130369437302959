import React from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOffOutlinedIcon from "@mui/icons-material/PersonOffOutlined";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import HierarchyLogo from "../../../assets/Images/Icons/HierarchyLogo.png";
import StaticNavbar from "./StaticNavbar";
import NoImage from "../../../assets/Images/Logo/NoImage.png";
import { customerList } from "./WhiteLabelUtils";
import { connect } from "react-redux";

function CustomerPage(props) {
    const { primary, secondary, tertiary, userLoggedIn } = props;

    return (
        <>
            <StaticNavbar
                customer={true}
                primary={primary}
                secondary={secondary}
                tertiary={tertiary}
            />
            <Grid
                container
                display={"flex"}
                flexWrap={"wrap"}
                spacing={2}
                className="customerCardMainDiv"
            >
                <Grid item className="customerCardDiv">
                    <Grid
                        item
                        display={"flex"}
                        flexWrap={"wrap"}
                        justifyContent={"center"}
                    >
                        {customerList.map((customer) => (
                            <Paper
                                elevation={3}
                                className="labelCardPaper"
                                sx={{
                                    transform: "scale(0.8)",
                                    backgroundColor: tertiary,
                                }}
                            >
                                <Grid container>
                                    <Grid
                                        item
                                        xs={12}
                                        className="labelCardHeader"
                                        sx={{ backgroundColor: secondary }}
                                    >
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Typography className="cardHeading">
                                                    {customer?.client_type}
                                                </Typography>
                                            </Grid>
                                            <Grid container spacing={0}>
                                                <Grid item xs={5}>
                                                    <Typography
                                                        sx={{
                                                            justifyContent:
                                                                "start",
                                                            display: "flex",
                                                            paddingLeft: "20px",
                                                            color: "white",
                                                            textDecoration:
                                                                "underline",
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        {userLoggedIn?.language_data?.role?.user_count ||  "No. of Users"}:{" "}
                                                        {customer?.users_count}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={7}>
                                                    <Typography
                                                        sx={{
                                                            justifyContent:
                                                                "end",
                                                            display: "flex",
                                                            paddingRight:
                                                                "10px",
                                                            color: "white",
                                                        }}
                                                    >
                                                        Client ID:{" "}
                                                        {customer?.client_id}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid container className="whiteLabelingBottom">
                                        <Grid item xs={12}>
                                            <div className="logoDiv">
                                                {customer?.logo ? (
                                                    <img
                                                        className="logoImg"
                                                        src={customer?.logo}
                                                        alt="L1C compliance"
                                                        height={"110px"}
                                                        width={"100%"}
                                                    />
                                                ) : (
                                                    <img
                                                        src={NoImage}
                                                        alt="L1C compliance"
                                                        height={"110px"}
                                                        width={"100%"}
                                                    />
                                                )}
                                            </div>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography
                                                className="labelTypo1"
                                                sx={{ color: primary }}
                                            >
                                                {customer?.display_name}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography className="typo2">
                                                {customer?.phone}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography className="typo2">
                                                {customer?.email}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography
                                                className={
                                                    customer?.status ===
                                                    "active"
                                                        ? "typo3"
                                                        : "typo5"
                                                }
                                            >
                                                {customer?.status}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            container
                                            spacing={2}
                                            className="actionBtn"
                                        >
                                            <div className="iconWhiteLabeling">
                                            <IconButton className="iconButton">
                                                <PersonAddAltOutlinedIcon />
                                            </IconButton>
                                            <IconButton className="iconButton">
                                                <EditOutlinedIcon />
                                            </IconButton>
                                            <IconButton>
                                                <img
                                                    src={HierarchyLogo}
                                                    alt="Hierarchy"
                                                    className="hierarchyLogo"
                                                />
                                            </IconButton>
                                            <IconButton className="iconButton">
                                                <SettingsOutlinedIcon />
                                            </IconButton>
                                            <IconButton className="iconButton">
                                                <PersonOffOutlinedIcon />
                                            </IconButton>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
            <Box
                display={"flex"}
                sx={{
                    py: 3,
                    px: { md: 10, xs: 0 },
                    justifyContent: { md: "end", xs: "center " },
                }}
            >
                <Stack spacing={2}>
                    <Pagination
                        sx={{
                            "& .MuiPaginationItem-root": {
                                backgroundColor: tertiary,
                                height: "40px",
                                color: "#3B3B3B",
                                fontSize: "16px",
                                fontWeight: "400",
                                borderRadius: "8px",
                            },
                            "& .MuiPagination-text": {
                                alignContent: "center",
                            },
                            "& .Mui-selected": {
                                backgroundColor: `${primary} !important`,
                                color: "white",
                            },
                        }}
                        count={10}
                        shape="rounded"
                    />
                </Stack>
            </Box>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,
    };
};

export default connect(mapStateToProps, null) (CustomerPage);
