import { API_CONSTANTS } from "../../config/ApiConstants";
import { getApiCall } from "../../utils/Action";

export const SET_TEST_RESPONSE = "SET_TEST_RESPONSE";
export const SET_SKIP_QUESTION = "SET_SKIP_QUESTION";
export const SET_TEST_DATA = "SET_TEST_DATA";
export const SET_TEST_TIMER = "SET_TEST_TIMER";
export const SET_PAUSED_TIMER = "SET_PAUSED_TIMER";
export const SET_START_VALUE = "SET_START_VALUE";
export const GET_DEFINATION_GLOSARY = "GET_DEFINATION_GLOSARY";
export const SET_UPDATE_CONFILT_OBJECT = "SET_UPDATE_CONFILT_OBJECT";
export const CHECK_ALTERNATIVE_RESPONSE = "CHECK_ALTERNATIVE_RESPONSE";
export const CHECK_CONTINGENT_QUESTIONS = "CHECK_CONTINGENT_QUESTIONS";

export const addResponse = (data) => {
    
    return async (dispatch) => {
        try {
            dispatch({
                type: SET_TEST_RESPONSE,
                payload: data,
            });
        } catch (error) {
            throw error;
        }
    };
};

export const skipQuestionRes = (data) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: SET_SKIP_QUESTION,
                payload: data,
            });
        } catch (error) {
            throw error;
        }
    };
};

export const setTestData = (data) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: SET_TEST_DATA,
                payload: data,
            });
        } catch (err) {
            throw err;
        }
    };
};
export const setTestTimer = (data) => {
    console.log("timer", data);
    return async (dispatch) => {
        try {
            dispatch({
                type: SET_TEST_TIMER,
                payload: data,
            });
        } catch (err) {
            throw err;
        }
    };
};
export const setPausedTimer = (data) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: SET_PAUSED_TIMER,
                payload: data,
            });
        } catch (err) {
            throw err;
        }
    };
};
export const setStartArrayValue = (data) => {
    console.log("data SET_START_VALUE",data)
    return async (dispatch) => {
        try {
            dispatch({
                type: SET_START_VALUE,
                payload: data,
            });
        } catch (err) {
            throw err;
        }
    };
};
export const getWordDefByGlosary = (word) => {
    return async (dispatch) => {
        try {
            const res = await getApiCall(
                API_CONSTANTS.GET_DEINATION_FROM_GLOSSARIES +
                    "?" +
                    "term=" +
                    word
            );
            return dispatch({
                type: GET_DEFINATION_GLOSARY,
                payload: res.data,
            });
        } catch (err) {
            throw err;
        }
    };
};

export const setUpdateConfiltResponse = (data) => {
    return async (dispatch) => {
        try {
            return dispatch({
                type: SET_UPDATE_CONFILT_OBJECT,
                payload: data,
            });
        } catch (err) {
            throw err;
        }
    };
};

export const checkAlternativeResponse = (data) => {
    return async (dispatch) => {
        try {
            const response = await getApiCall(
                API_CONSTANTS.CHECK_ALTERNATIVE_RESPONSE,
                data
            );
            console.log("resss", response);
            if (response?.status === 200) {
                return dispatch({
                    type: CHECK_ALTERNATIVE_RESPONSE,
                    payload: response.data.result,
                });
            }
        } catch (error) {
            throw error;
        }
    };
};

export const getContingentQuestions = (data) => {
    return async (dispatch) => {
        try {
            const response = await getApiCall(
                API_CONSTANTS.CHECK_CONTINGENT_QUESTIONS,
                data
            );
            console.log("resss", response);
            if (response?.status === 200) {
                return dispatch({
                    type: CHECK_CONTINGENT_QUESTIONS,
                    payload: response.data.result,
                });
            }
        } catch (error) {
            throw error;
        }
    };
};
// export const getContigentQuestions =(data)=>{
//     return async(dispatch)=>{
//         try{
//             const res= getApiCall(API_CONSTANTS.GET_CONTIGENT_QUESTION)
//             return dispatch({
//                 type:SET_CONTIGENT_QUESTIONS,
//                 payload:
//             })
//         }
//     }
// }
