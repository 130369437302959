import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import InfoIcon from "@mui/icons-material/Info";
import Icon from "@mui/material/Icon";
import MenuItem from "@mui/material/MenuItem";
import Navbar from "../../common/Header/Navbar";
import PageHeader from "../../common/PageHeader";
import "../../../assets/styles/Container/SignedDocument.scss";
import TextFieldComponent from "../../components/TextField/TextFieldComponent";
import DropDownComponent from "../../components/DropDown/DropDownComponent";
import RadioButtonGroup from "../../components/RadioButtonGroup/RadioButtonGroup";
import SaveDocument from "./SaveDocument";
import ContextProvider from "../../components/Context/ContextProvider";
import ButtonComponent from "../../components/ButtonComponent";
import ExpandAllButton from "../../components/ExpandAllButton";
import {
    yesNoOptions,
    uploadStatus1,
    uploadStatus2,
    uploadStatus3,
    responseOptions,
} from "../../utils/CommonData";
import {
    getCategoryDropdown,
    storeSignedDocument,
    setSelectedDetails,
    updateSignedDocument,
    getSignedDocumentById,
} from "../../store/Action/SignedDocumentAction";
import { ROUTES } from "../../router/RouteList";
import AccordionCustom from "../../components/AccordionCustom";
const SignedDocument = (props) => {
    const {
        getCategoryDropdown,
        categoryDropdown,
        storeSignedDocument,
        questionCode,
        setSelectedDetails,
        selectedDocumentData,
        updateSignedDocument,
        getSignedDocumentById,
        loggedInUser,
        userLoggedIn,
    } = props;

    const mode = selectedDocumentData?.mode || null;

    const [state, setState] = useState({
        id: mode ? selectedDocumentData?.id : "",
        category_id: mode ? selectedDocumentData?.category_id : "",
        document_name: mode ? selectedDocumentData?.document_name : "",
        content_of_document_request: mode
            ? selectedDocumentData?.content_of_document_request
            : "",
        other_document_category: mode
            ? selectedDocumentData?.other_document_category
            : "",
        summary: mode ? selectedDocumentData?.summary : "",
        examples: mode ? selectedDocumentData?.examples : "",
        cannot_fill_request_reason: mode
            ? selectedDocumentData?.cannot_fill_request_reason
            : "",
        response_options: mode ? selectedDocumentData?.response_options : "",
        upload_status: mode ? selectedDocumentData?.upload_status : [],
        do_not_collect_it: mode ? selectedDocumentData?.do_not_collect_it : "",
        remove_related_questions_from_testing: mode
            ? selectedDocumentData?.remove_related_questions_from_testing
            : "",
        apply_scoring_to_optional_documents: mode
            ? selectedDocumentData?.apply_scoring_to_optional_documents
            : "",
        formtemplate_document_uploaded_by_admin: mode
            ? selectedDocumentData?.formtemplate_document_uploaded_by_admin
            : "",
        repressed: mode ? selectedDocumentData?.repressed : "",
        document_score: mode ? selectedDocumentData?.document_score : "",
        question_family_code: mode
            ? selectedDocumentData?.question_family_code
            : "",
        status: mode ? selectedDocumentData?.status : "",
        status_reason: mode ? selectedDocumentData?.status_reason : "",
        assign_to: mode ? selectedDocumentData?.assign_to : "",
        comments: mode ? selectedDocumentData?.comments : "",
        approved_by: mode ? selectedDocumentData?.approved_by : "",
        expand: true,
        hide: mode
            ? selectedDocumentData?.category_id === 5
                ? true
                : false
            : false,
        openSaveModel: false,
        sortedCategorys: null,
        defaultAssign_to: [],
    });
    const {
        hide,
        category_id,
        document_name,
        content_of_document_request,
        other_document_category,
        summary,
        examples,
        cannot_fill_request_reason,
        response_options,
        upload_status,
        do_not_collect_it,
        remove_related_questions_from_testing,
        apply_scoring_to_optional_documents,
        formtemplate_document_uploaded_by_admin,
        repressed,
        document_score,
        question_family_code,
        openSaveModel,
        expand,
        sortedCategorys,
    } = state;
    const pathName = window.location.search.split("?");
    const pageType = window.location.pathname.split("-");
    const navigate = useNavigate();

    useEffect(() => {
        const pageTypeName = pageType[0].split("/");
        if (
            !selectedDocumentData &&
            (pageTypeName[1] === "edit" || pageTypeName[1] === "view")
        ) {
            getSignedDocumentById(parseInt(pathName[1]), pageTypeName[1]);
        }
    }, [getCategoryDropdown, selectedDocumentData]);
    useEffect(() => {
        const callApi = async () => {
            const res = await getCategoryDropdown();
            let sortData = res.payload.sort((a, b) =>
                a.name > b.name ? 1 : -1
            );
            const findIndex = sortData.findIndex((obj) => obj.name === "Other");
            const object = sortData[findIndex];
            const sortCategory = sortData.filter((obj) => obj.name !== "Other");
            sortCategory.push(object);
            setState((prevState) => ({
                ...prevState,
                sortedCategorys: sortCategory,
            }));
        };
        callApi();
    }, [categoryDropdown?.length]);

    if (selectedDocumentData && mode && document_name === "") {
        setState((prevState) => ({ ...prevState, ...selectedDocumentData }));
    }

    const handleClickCancel = async () => {
        await setSelectedDetails(null);
        navigate(ROUTES.SIGNED_DOCUMENT);
    };
    const handelResponseChange = (event) => {
        const optionList = [
            { label: uploadStatus1, value: 1 },
            {
                label: `${uploadStatus2} ${cannot_fill_request_reason}`,
                value: 2,
            },
            {
                label: uploadStatus3,
                value: 3,
            },
        ];
        let list;
        switch (event?.target?.value) {
            case "Required":
                list = optionList;
                break;
            case "Hard Required":
                list = [
                    { label: uploadStatus1, value: 1 },
                    { label: uploadStatus2, value: 2 },
                ];
                break;
            case "Optional + Scored":
                list = optionList;
                break;
            case "Optional":
                list = [
                    { label: uploadStatus1, value: 1 },
                    { label: uploadStatus3, value: 2 },
                ];
                break;
            default:
                list = optionList;
        }
        setState((prevState) => ({
            ...prevState,
            response_options: event?.target?.value,
            upload_status: list,
        }));
    };

    const handelDocumentCategory = (event) => {
        setState((prevState) => ({
            ...prevState,
            category_id: event?.target?.value,
        }));
        if (event?.target?.value === 5 && !hide)
            setState((prevState) => ({ ...prevState, hide: true }));
        else if (event?.target?.value !== 5 && hide) {
            setState((prevState) => ({ ...prevState, hide: false }));
        }
    };
    async function handleSignedDocumentSubmit() {
        if (state.status.length == 0) {
            toast.error("Decision is required");
        } else {
            if (state.status_reason.length == 0) {
                toast.error("Reason is required");
            } else {
                if (state.comments.length == 0) {
                    toast.error("Comments is required");
                } else {
                    if (state.assign_to.length == 0) {
                        toast.error("Assign/Reassign is required");
                    } else {
                        delete state.openSaveModel;
                        delete state.hide;
                        delete state.expand;
                        if (mode !== "edit")
                            await storeSignedDocument({
                                ...state,
                                approved_by: loggedInUser,
                            });
                        else {
                            await updateSignedDocument({
                                ...state,
                                approved_by: loggedInUser,
                            });
                        }
                        navigate(ROUTES.SIGNED_DOCUMENT);
                        setSelectedDetails(null);
                    }
                }
            }
        }
    }
    function handleOpen() {
        if (document_name && category_id && response_options !== "") {
            setState((prevState) => ({ ...prevState, openSaveModel: true }));
        } else {
            toast.error("Please Enter Required Input.");
        }
    }

    return (
        <Fragment>
            <Navbar />
            <SaveDocument
                open={openSaveModel}
                handleSignedDocumentSubmit={handleSignedDocumentSubmit}
                state={state}
                setstate={setState}
            />
            <Box sx={{ px: { md: 7, xs: 1 } }}>
                <PageHeader title={"Signed Document"} />
                <ExpandAllButton expand={expand} setState={setState} />
                <ContextProvider.Provider value={expand}>
                    <Grid container>
                        <Grid item md={12} xs={12} mt={2}>
                            <AccordionCustom
                                title={"Signed Document Information"}
                            >
                                <Grid container spacing={2}>
                                    <Grid item md={6} xs={12}>
                                        <TextFieldComponent
                                            id="addSignedDocumentName"
                                            label={
                                                userLoggedIn?.language_data
                                                    ?.documentdatabase
                                                    ?.document_name ||
                                                "Document Name"
                                            }
                                            name={"document_name"}
                                            onChange={(event) =>
                                                setState((prevState) => ({
                                                    ...prevState,
                                                    document_name:
                                                        event?.target?.value,
                                                }))
                                            }
                                            value={document_name || ""}
                                            disabled={
                                                mode !== "view" ? false : true
                                            }
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <DropDownComponent
                                            id="addSignedDocumentCategory"
                                            label={
                                                userLoggedIn?.language_data
                                                    ?.documentdatabase
                                                    ?.document_category ||
                                                "Document Category"
                                            }
                                            name={"subscription"}
                                            fullWidth={true}
                                            onChange={handelDocumentCategory}
                                            value={category_id || ""}
                                            disabled={
                                                mode !== "view" ? false : true
                                            }
                                            children={sortedCategorys?.map(
                                                (option) => (
                                                    <MenuItem
                                                        key={option?.id}
                                                        value={option?.id}
                                                    >
                                                        {option?.name}
                                                    </MenuItem>
                                                )
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} mt={3}>
                                    <Grid item md={6} xs={12}>
                                        <TextFieldComponent
                                            label={
                                                userLoggedIn?.language_data
                                                    ?.documentdatabase
                                                    ?.list_success ||
                                                "Content of Document Request"
                                            }
                                            id="addSignedDocumentRequest"
                                            multiline={true}
                                            rows={3}
                                            name={"content_of_document_request"}
                                            sx={{ paddingTop: "20px" }}
                                            onChange={(event) =>
                                                setState((prevState) => ({
                                                    ...prevState,
                                                    content_of_document_request:
                                                        event?.target?.value,
                                                }))
                                            }
                                            value={
                                                content_of_document_request ||
                                                ""
                                            }
                                            disabled={
                                                mode !== "view" ? false : true
                                            }
                                        />
                                    </Grid>
                                    {hide ? (
                                        <Grid item md={5.9} xs={12}>
                                            <TextFieldComponent
                                                id="addSignedDocumentOtherCategory"
                                                label={
                                                    "Other Document Category"
                                                }
                                                name={"other_document_category"}
                                                onChange={(event) =>
                                                    setState((prevState) => ({
                                                        ...prevState,
                                                        other_document_category:
                                                            event?.target
                                                                ?.value,
                                                    }))
                                                }
                                                value={
                                                    other_document_category ||
                                                    ""
                                                }
                                                disabled={
                                                    mode !== "view"
                                                        ? false
                                                        : true
                                                }
                                            />
                                        </Grid>
                                    ) : null}
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item md={12} xs={12} mt={2}>
                                        <Typography className="selectText">
                                            {userLoggedIn?.language_data
                                                ?.documentdatabase
                                                ?.add_success || "Summary"}
                                            <Icon className="icon">
                                                <InfoIcon />
                                            </Icon>
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item md={12} xs={12} mt={3}>
                                        <TextFieldComponent
                                            id="addSignedDocumentSummary"
                                            label={
                                                userLoggedIn?.language_data
                                                    ?.documentdatabase
                                                    ?.add_success || "Summary"
                                            }
                                            multiline={true}
                                            rows={3}
                                            name={
                                                userLoggedIn?.language_data
                                                    ?.documentdatabase
                                                    ?.add_success || "Summary"
                                            }
                                            sx={{ paddingTop: "20px" }}
                                            onChange={(event) =>
                                                setState((prevState) => ({
                                                    ...prevState,
                                                    summary:
                                                        event?.target?.value,
                                                }))
                                            }
                                            value={summary || ""}
                                            disabled={
                                                mode !== "view" ? false : true
                                            }
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item md={12} xs={12} mt={2}>
                                        <Typography className="selectText">
                                            {userLoggedIn?.language_data
                                                ?.documentdatabase
                                                ?.update_success || "Examples"}
                                            <Icon className="icon">
                                                <InfoIcon />
                                            </Icon>
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item md={12} xs={12} mt={3}>
                                        <TextFieldComponent
                                            id="addSignedDocumentExample"
                                            sx={{ paddingTop: "20px" }}
                                            label={"Example"}
                                            rows={3}
                                            multiline={true}
                                            name={"examples"}
                                            onChange={(event) =>
                                                setState((prevState) => ({
                                                    ...prevState,
                                                    examples:
                                                        event?.target?.value,
                                                }))
                                            }
                                            value={examples || ""}
                                            disabled={
                                                mode !== "view" ? false : true
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </AccordionCustom>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} mt={2}>
                        <Grid item lg={12} md={12} xs={12}>
                            <AccordionCustom title={"Cannot Fulfill Request"}>
                                <Grid container>
                                    <Grid item md={12} xs={12}>
                                        <Typography className="selectText">
                                            {` Content of "Cannot Fulfill Request because…"`}
                                            <Icon className="icon">
                                                <InfoIcon />
                                            </Icon>
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container mt={3}>
                                    <Grid item md={12} xs={12} mt={1}>
                                        <TextFieldComponent
                                            id="addSignedDocumentCannotFulfillRequestReason"
                                            sx={{ paddingTop: "20px" }}
                                            label={
                                                userLoggedIn?.language_data
                                                    ?.documentdatabase
                                                    ?.cannot_fulfill_request_reason ||
                                                "Cannot Fulfill Request Reason"
                                            }
                                            multiline={true}
                                            rows={3}
                                            name={"cannot_fill_request_reason"}
                                            onChange={(event) =>
                                                setState((prevState) => ({
                                                    ...prevState,
                                                    cannot_fill_request_reason:
                                                        event?.target?.value,
                                                }))
                                            }
                                            value={
                                                cannot_fill_request_reason || ""
                                            }
                                            disabled={
                                                mode !== "view" ? false : true
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </AccordionCustom>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} mt={2}>
                        <Grid item lg={12}>
                            <AccordionCustom
                                title={
                                    userLoggedIn?.language_data
                                        ?.documentdatabase?.response_options ||
                                    "Response Options"
                                }
                            >
                                <Grid container spacing={2}>
                                    <Grid item md={6} xs={12}>
                                        <DropDownComponent
                                            id="addSignedDocumentResponseOptions"
                                            label={
                                                userLoggedIn?.language_data
                                                    ?.documentdatabase
                                                    ?.response_options ||
                                                "Response Options"
                                            }
                                            name={"response"}
                                            fullWidth={true}
                                            onChange={handelResponseChange}
                                            value={response_options || ""}
                                            disabled={
                                                mode !== "view" ? false : true
                                            }
                                            children={responseOptions?.map(
                                                (option) => (
                                                    <MenuItem
                                                        key={option}
                                                        value={option}
                                                    >
                                                        {option}
                                                    </MenuItem>
                                                )
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item lg={12} md={12} sm={12} mt={3}>
                                        <Typography className="font_18_600">
                                            {`Upload Options are available:`}
                                            <Icon className="icon">
                                                <InfoIcon />
                                            </Icon>
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={7} md={7} sm={7} mt={2}>
                                        {upload_status?.length ? (
                                            upload_status?.map((option) => (
                                                <Typography>
                                                    {option?.label}
                                                </Typography>
                                            ))
                                        ) : (
                                            <Typography>
                                                {userLoggedIn?.language_data
                                                    ?.documentdatabase
                                                    ?.no_option_available ||
                                                    "No option available..."}
                                            </Typography>
                                        )}
                                    </Grid>
                                </Grid>
                            </AccordionCustom>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} mt={2}>
                        <Grid item lg={12} md={12} xs={12}>
                            <AccordionCustom title={"Signed Document Settings"}>
                                <Grid container spacing={2}>
                                    <Grid item lg={6} md={6} sm={6}>
                                        <Grid container>
                                            <Grid item lg={12} md={12} sm={12}>
                                                <Typography className="font_18_600">
                                                    {userLoggedIn?.language_data
                                                        ?.documentdatabase
                                                        ?.remove_document_from ||
                                                        "Remove Document from Collection Process, i.e., Do Not Collect It"}
                                                    <Icon className="icon">
                                                        <InfoIcon />
                                                    </Icon>
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={12} md={12} sm={12}>
                                                <RadioButtonGroup
                                                    id="addSignedDocumentYesNoOption"
                                                    options={yesNoOptions}
                                                    row={true}
                                                    defaultValue={false}
                                                    name="do_not_collect_it"
                                                    onChange={(event) =>
                                                        setState(
                                                            (prevState) => ({
                                                                ...prevState,
                                                                do_not_collect_it:
                                                                    event
                                                                        ?.target
                                                                        ?.value ===
                                                                    "yes"
                                                                        ? true
                                                                        : false,
                                                            })
                                                        )
                                                    }
                                                    value={
                                                        do_not_collect_it
                                                            ? "yes"
                                                            : "no"
                                                    }
                                                    disabled={
                                                        mode !== "view"
                                                            ? false
                                                            : true
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6}>
                                        <Grid container>
                                            <Grid item lg={12} md={12} sm={12}>
                                                <Typography className="font_18_600">
                                                    {userLoggedIn?.language_data
                                                        ?.documentdatabase
                                                        ?.remove_ralatedques ||
                                                        "Remove Related Questions from Testing*"}
                                                    <Icon className="icon">
                                                        <InfoIcon />
                                                    </Icon>
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={12} md={12} sm={12}>
                                                <RadioButtonGroup
                                                    id="addSignedDocumentyesNoOptions2"
                                                    options={yesNoOptions}
                                                    row={true}
                                                    defaultValue={false}
                                                    name="remove_related_questions_from_testing"
                                                    onChange={(event) =>
                                                        setState(
                                                            (prevState) => ({
                                                                ...prevState,
                                                                remove_related_questions_from_testing:
                                                                    event
                                                                        ?.target
                                                                        ?.value ===
                                                                    "yes"
                                                                        ? true
                                                                        : false,
                                                            })
                                                        )
                                                    }
                                                    value={
                                                        remove_related_questions_from_testing
                                                            ? "yes"
                                                            : "no"
                                                    }
                                                    disabled={
                                                        mode !== "view"
                                                            ? false
                                                            : true
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} mt={2}>
                                    <Grid item lg={6} md={6} sm={6}>
                                        <Grid container>
                                            <Grid item lg={12} md={12} sm={12}>
                                                <Typography className="font_18_600">
                                                    {userLoggedIn?.language_data
                                                        ?.documentdatabase
                                                        ?.apply_scoring_doc ||
                                                        "Apply Scoring to Optional Documents? (Note)"}
                                                    <Icon className="icon">
                                                        <InfoIcon />
                                                    </Icon>
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={12} md={12} sm={12}>
                                                <RadioButtonGroup
                                                    id="addSignedDocumentyesNoOptions3"
                                                    options={yesNoOptions}
                                                    row={true}
                                                    name="apply_scoring_to_optional_documents"
                                                    onChange={(event) =>
                                                        setState(
                                                            (prevState) => ({
                                                                ...prevState,
                                                                apply_scoring_to_optional_documents:
                                                                    event
                                                                        ?.target
                                                                        ?.value ===
                                                                    "yes"
                                                                        ? true
                                                                        : false,
                                                            })
                                                        )
                                                    }
                                                    value={
                                                        apply_scoring_to_optional_documents
                                                            ? "yes"
                                                            : "no"
                                                    }
                                                    disabled={
                                                        mode !== "view"
                                                            ? false
                                                            : true
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6}>
                                        <Grid container>
                                            <Grid item lg={12} md={12} sm={12}>
                                                <Typography className="font_18_600">
                                                    {userLoggedIn?.language_data
                                                        ?.documentdatabase
                                                        ?.form_document_by_admin ||
                                                        "Form/Template Document Uploaded by Admin into the Documents Database?"}
                                                    <Icon className="icon">
                                                        <InfoIcon />
                                                    </Icon>
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={12} md={12} sm={12}>
                                                <RadioButtonGroup
                                                    id="addSignedDocumentyesNoOptions4"
                                                    options={yesNoOptions}
                                                    row={true}
                                                    defaultValue={true}
                                                    name="formtemplate_document_uploaded_by_admin"
                                                    onChange={(event) =>
                                                        setState(
                                                            (prevState) => ({
                                                                ...prevState,
                                                                formtemplate_document_uploaded_by_admin:
                                                                    event
                                                                        ?.target
                                                                        ?.value ===
                                                                    "yes"
                                                                        ? true
                                                                        : false,
                                                            })
                                                        )
                                                    }
                                                    value={
                                                        formtemplate_document_uploaded_by_admin
                                                            ? "yes"
                                                            : "no"
                                                    }
                                                    disabled={
                                                        mode !== "view"
                                                            ? false
                                                            : true
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} mt={2}>
                                    <Grid item lg={6} md={6} sm={6}>
                                        <Grid container>
                                            <Grid item lg={12} md={12} sm={12}>
                                                <Typography className="font_18_600">
                                                    {userLoggedIn?.language_data
                                                        ?.documentdatabase
                                                        ?.document_score ||
                                                        "Document Score"}
                                                    <Icon className="icon">
                                                        <InfoIcon />
                                                    </Icon>
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                my={2}
                                            >
                                                <TextFieldComponent
                                                    id="addSignedDocumentScore"
                                                    name={"document_score"}
                                                    label={
                                                        userLoggedIn
                                                            ?.language_data
                                                            ?.documentdatabase
                                                            ?.document_score ||
                                                        "Document Score"
                                                    }
                                                    fullWidth={true}
                                                    onChange={(event) =>
                                                        setState(
                                                            (prevState) => ({
                                                                ...prevState,
                                                                document_score:
                                                                    event
                                                                        ?.target
                                                                        ?.value,
                                                            })
                                                        )
                                                    }
                                                    value={document_score || ""}
                                                    disabled={
                                                        mode !== "view"
                                                            ? false
                                                            : true
                                                    }
                                                    type={"Number"}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6}>
                                        <Grid container>
                                            <Grid item lg={12} md={12} sm={12}>
                                                <Typography className="font_18_600">
                                                    {userLoggedIn?.language_data
                                                        ?.documentdatabase
                                                        ?.question_family_code ||
                                                        "Question Family Code"}
                                                    <Icon className="icon">
                                                        <InfoIcon />
                                                    </Icon>
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                mt={2}
                                            >
                                                <DropDownComponent
                                                    id="addSignedDocumentQuestionFamilyCode"
                                                    label={
                                                        userLoggedIn
                                                            ?.language_data
                                                            ?.documentdatabase
                                                            ?.question_family_code ||
                                                        "Question Family Code"
                                                    }
                                                    name={
                                                        "question_family_code"
                                                    }
                                                    fullWidth={true}
                                                    onChange={(event) =>
                                                        setState(
                                                            (prevState) => ({
                                                                ...prevState,
                                                                question_family_code:
                                                                    event
                                                                        ?.target
                                                                        ?.value,
                                                            })
                                                        )
                                                    }
                                                    value={
                                                        question_family_code ||
                                                        ""
                                                    }
                                                    disabled={
                                                        mode !== "view"
                                                            ? false
                                                            : true
                                                    }
                                                    children={questionCode?.map(
                                                        (option) => (
                                                            <MenuItem
                                                                key={
                                                                    option?.code
                                                                }
                                                                value={
                                                                    option?.code
                                                                }
                                                            >
                                                                {option?.code}
                                                            </MenuItem>
                                                        )
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item lg={12} md={12} sm={12}>
                                        <Typography className="font_18_600">
                                            {userLoggedIn?.language_data
                                                ?.documentdatabase?.repressed ||
                                                "Repressed"}
                                            <Icon className="icon">
                                                <InfoIcon />
                                            </Icon>
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12}>
                                        <RadioButtonGroup
                                            id="addSignedDocumentyesNoOptions4"
                                            options={yesNoOptions}
                                            row={true}
                                            name={
                                                userLoggedIn?.language_data
                                                    ?.documentdatabase
                                                    ?.repressed || "Repressed"
                                            }
                                            onChange={(event) =>
                                                setState((prevState) => ({
                                                    ...prevState,
                                                    repressed:
                                                        event?.target?.value ===
                                                        "yes"
                                                            ? true
                                                            : false,
                                                }))
                                            }
                                            value={repressed ? "yes" : "no"}
                                            disabled={
                                                mode !== "view" ? false : true
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </AccordionCustom>
                        </Grid>
                    </Grid>
                </ContextProvider.Provider>
                <Grid
                    container
                    my={2}
                    spacing={2}
                    gap={2}
                    display={"flex"}
                    justifyContent={"flex-end"}
                >
                    <ButtonComponent
                        title="Cancel"
                        variant="outlined"
                        onClick={handleClickCancel}
                        id="addSignedDocumentCancelButton"
                    />
                    {mode === "view" ? null : (
                        <ButtonComponent
                            title={mode === "edit" ? "Update" : userLoggedIn?.language_data?.comman?.save_btn || "Save"}
                            onClick={handleOpen}
                            id="addSignedDocumentSaveButton"
                        />
                    )}
                </Grid>
            </Box>
        </Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,
        categoryDropdown: state?.signedDocs?.categoryDropdown,
        questionCode: state?.auth?.commonDropdown?.question_family_codes,
        selectedDocumentData: state?.signedDocs?.selectedDocumentData,
        loggedInUser: state?.auth?.userLoggedIn?.user?.id,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getCategoryDropdown,
            storeSignedDocument,
            setSelectedDetails,
            updateSignedDocument,
            getSignedDocumentById,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(SignedDocument);
