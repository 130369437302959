import React, { useState } from "react";
import {
    TableRow,
    TableCell,
    TableContainer,
    IconButton,
    Paper,
} from "@mui/material";
import { ReactComponent as DeleteIcon } from "../../../../../../../assets/Images/Icons/DeleteIcon.svg";
import ButtonComponent from "../../../../../../components/ButtonComponent";
import TableComponent from "../../../../../../components/Table/TableComponent";
import AutoCompleteDropDown from "../../../../../../components/DropDown/AutoCompleteDropDown";
import TextFieldComponent from "../../../../../../components/TextField/TextFieldComponent";
import { connect } from "react-redux";
function GroupingCategoryWeight(props) {
    const { readOnly, testCategory, setStateTest, stateTest, userLoggedIn} = props;
    const format = {
        groupName: "Group",
        category_ids: [],
        min_questions: 10,
        max_questions: 10,
        min_weight: 10,
        max_weight: 10,
        priority: 10,
        passing_score: 10,
    };

    const headerList = [
        {
            name: " Group Name",
            headerClass: "tableHeading",
            accessibleKey: "group_name",
            rowClass: "typo4",
            headerAlign: "left",
            rowAlign: "left",
        },
        {
            id: 2,
            name: "Category Name",
            headerClass: "tableHeading",
            accessibleKey: "category_names",
            rowClass: "typo4",
            headerAlign: "left",
            rowAlign: "left",
        },
        {
            id: 3,
            name: "Min. No. of Question",
            headerClass: "tableHeading customerNameTableHeading",
            accessibleKey: "min_questions",
            rowClass: "typo4",
            headerAlign: "center",
            rowAlign: "center",
        },
        {
            id: 4,
            name: "Max. No. of Question",
            headerClass: "tableHeading",
            accessibleKey: "max_questions",
            rowClass: "typo4",
            headerAlign: "center",
            rowAlign: "center",
        },
        {
            id: 5,
            name: "Min. Category Weight Percentage",
            headerClass: "tableHeading",
            accessibleKey: "min_weight",
            rowClass: "typo4",
            headerAlign: "center",
            rowAlign: "center",
        },
        {
            id: 6,
            name: "Max. Category Weight Percentage",
            headerClass: "tableHeading",
            accessibleKey: "max_weight",
            rowClass: "typo4",
            headerAlign: "center",
            rowAlign: "center",
        },
        {
            id: 7,
            name: "Level of Priority Percentage",
            headerClass: "tableHeading",
            accessibleKey: "priority",
            rowClass: "typo4",
            headerAlign: "center",
            rowAlign: "center",
        },
        {
            id: 8,
            name: "Min. Passing Score Percentage",
            headerClass: "tableHeading",
            accessibleKey: "passingScore",
            rowClass: "typo4",
            headerAlign: "center",
            rowAlign: "center",
        },
    ];
    const handlerAddRow = () => {
        let newArr = stateTest.category_settings;
        newArr.push(format);
        setStateTest((prevState) => ({
            ...prevState,
            category_settings: newArr,
        }));
    };

    const handleChangeValue = (event, index, value = null) => {
        let newCategoryData = stateTest.category_settings;
        if (!event.target.name) {
            newCategoryData[index] = {
                ...newCategoryData[index],
                category_ids: value,
            };
        } else {
            newCategoryData[index] = {
                ...newCategoryData[index],
                [event.target.name]: event.target.value,
            };
        }
        setStateTest((prevState) => ({
            ...prevState,
            category_settings: newCategoryData,
        }));
    };
    return (
        <TableContainer
            component={Paper}
            sx={{
                borderRadius: "5px",
                boxShadow: "0px 0px 10px 4px rgb(0 0 0 / 10%)",
            }}
            className="tableClass"
        >
            <TableComponent
                headerList={headerList}
                extraHeaderRight={
                    stateTest.isEdit ? (
                        <TableCell
                            align="center"
                            className="tableHeading actionTableHeading"
                        >
                            Action
                        </TableCell>
                    ) : null
                }
                tableFooter={
                    stateTest.isEdit ? (
                        <TableRow>
                            <TableCell colSpan={8}></TableCell>
                            <TableCell align="center">
                                <ButtonComponent
                                    title="+"
                                    sx={{
                                        fontSize: "30px !important",
                                        borderRadius: "50%",
                                        minWidth: '0px!important',
                                        padding: "12px 18px",
                                        paddingInline: "18px !important",
                                    }}
                                    onClick={handlerAddRow}
                                />
                            </TableCell>
                        </TableRow>
                    ) : null
                }
            >
                {stateTest.category_settings?.map((row, index) => {
                    return (
                        <TableRow
                            key={index}
                            className={
                                index % 2 !== 0 ? "tertiaryBackground" : ""
                            }
                        >
                            <TableCell align="center">
                                <input
                                    type="text"
                                    value={row?.groupName}
                                    name="groupName"
                                    className="font_16_600 inputText"
                                    onChange={(event) =>
                                        handleChangeValue(event, index)
                                    }
                                    disabled={stateTest.isEdit ? false : true}
                                />
                            </TableCell>
                            <TableCell align="center">
                                <AutoCompleteDropDown
                                    options={testCategory}
                                    fullwidth={true}
                                    onChange={(event, value) =>
                                        handleChangeValue(event, index, value)
                                    }
                                    defaultValue={row.category_ids}
                                    sx={{ width: "105%", minWidth: '180px', fontSize: '15px !important'}}
                                    disabled={stateTest.isEdit ? false : true}
                                    renderInput={(params) => (
                                        <TextFieldComponent
                                            {...params}
                                            placeholder={userLoggedIn?.language_data?.faqs?.add_category_btn || "Add Category"}
                                            id="groupingCategoryIds"
                                            
                                        />
                                    )}
                                    name={"category_ids"}
                                    multiple={true}
                                    filterSelectedOptions={true}
                                    getOptionLabel={(option) => option.category}
                                />
                            </TableCell>
                            <TableCell align="center">
                                <input
                                    type="number"
                                    className="inputNumber font_16_600"
                                    min={0}
                                    value={row?.min_questions}
                                    name={"min_questions"}
                                    onChange={(event) =>
                                        handleChangeValue(event, index)
                                    }
                                    disabled={stateTest.isEdit ? false : true}
                                />
                            </TableCell>
                            <TableCell align="center">
                                <input
                                    type="number"
                                    className="inputNumber font_16_600"
                                    min={0}
                                    value={row?.max_questions}
                                    name={"max_questions"}
                                    onChange={(event) =>
                                        handleChangeValue(event, index)
                                    }
                                    disabled={stateTest.isEdit ? false : true}
                                />
                            </TableCell>
                            <TableCell align="center">
                                <input
                                    type="number"
                                    className="inputNumber font_16_600"
                                    min={0}
                                    value={row?.min_weight}
                                    name={"min_weight"}
                                    onChange={(event) =>
                                        handleChangeValue(event, index)
                                    }
                                    disabled={stateTest.isEdit ? false : true}
                                />
                            </TableCell>
                            <TableCell align="center">
                                <input
                                    type="number"
                                    className="inputNumber font_16_600"
                                    min={0}
                                    value={row?.max_weight}
                                    name={"max_weight"}
                                    onChange={(event) =>
                                        handleChangeValue(event, index)
                                    }
                                    disabled={stateTest.isEdit ? false : true}
                                />
                            </TableCell>
                            <TableCell align="center">
                                <input
                                    type="number"
                                    className="inputNumber font_16_600"
                                    min={0}
                                    name={"priority"}
                                    value={row?.priority}
                                    onChange={(event) =>
                                        handleChangeValue(event, index)
                                    }
                                    disabled={stateTest.isEdit ? false : true}
                                />
                            </TableCell>
                            <TableCell align="center">
                                <input
                                    type="number"
                                    className="inputNumber font_16_600"
                                    min={0}
                                    name={"passing_score"}
                                    value={row?.passing_score}
                                    onChange={(event) =>
                                        handleChangeValue(event, index)
                                    }
                                    disabled={stateTest.isEdit ? false : true}
                                />
                            </TableCell>
                            {stateTest.isEdit ? (
                                <TableCell
                                    align="center"
                                    position="sticky !important"
                                >
                                    <IconButton className="iconButton">
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            ) : null}
                        </TableRow>
                    );
                })}
            </TableComponent>
        </TableContainer>
    );
}

const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,

    };
};


export default connect(mapStateToProps, null) (GroupingCategoryWeight);
