import React from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { connect } from "react-redux";
import NoInfoAvailable from "../../components/NoInfoAvailable";
import {Translate } from 'react-auto-translate';
const ViewResponseBox = (props) => {
    const { formSubmissions, viewSubmissionForm, formId, activeLink } = props;

    return (
        <Paper className="resPaper">
        <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
        >
            <Typography className="resHeding">
                <Translate>Responses:</Translate>
            </Typography>
            <Typography
                className="formText"
                sx={{
                    minWidth: "48px",
                    justifyContent: "center",
                    display: "flex",
                }}
            >
                {formSubmissions?.length}
            </Typography>
        </Box>
        <Divider />
        <Box>
            <List disablePadding>
                {formSubmissions?.length !== 0 ? (
                    formSubmissions?.map((item, index) => (
                        <ListItem
                            disablePadding
                            className={"drawerListItem"}
                            onClick={() => {
                                viewSubmissionForm(item);
                            }}
                            id="viewResponseBoxViewSubmissionForm"
                        >
                            {console.log(item)}
                            <ListItemButton
                                className={
                                    activeLink === item.id
                                        ? "activeLink"
                                        : ""
                                }
                            >
                                <ListItemText className={"listIconText"}>
                                    <Translate>Response {index + 1}</Translate>
                                </ListItemText>
                                <ListItemIcon
                                    className={"listItemIcon"}
                                    sx={{
                                        minWidth: "48px",
                                        justifyContent: "center",
                                    }}
                                    fontSize={"large"}
                                >
                                    <RemoveRedEyeOutlinedIcon />
                                </ListItemIcon>
                            </ListItemButton>
                        </ListItem>
                    ))
                ) : (
                    <Box
                        className="centerPositionBox"
                        sx={{ height: "300px" }}
                    >
                        <NoInfoAvailable />
                    </Box>
                )}
            </List>
        </Box>
    </Paper>
    
    );
};

const mapStateToProps = (state) => {
    return {
        formSubmissions: state?.formStudio?.formSubmissions,
    };
};

export default connect(mapStateToProps, null)(ViewResponseBox);
