import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    assignContractorsAction,
} from "../../store/Action/ComplianceWorkflowAction";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import StatusUpdateDialog from "../../components/DialogsBox/StatusUpdateDialog";
import DropDownComponent from "../../components/DropDown/DropDownComponent";
import { MenuItem } from "@mui/material";
import SuccessfullModel from "../../components/DialogsBox/SuccessfullModel";
import TextFieldComponent from "../../components/TextField/TextFieldComponent";
import "../../../assets/styles/Container/ComplianceWorkflow.scss";
function RejectContractor(props) {
    const {userLoggedIn, complianceWorkflow, assignContractorsAction, customer, user } =
        props;
    const [state, setState] = useState({
        open: false,
        value: "",
        reasons: [
            "Reason for Rejection",
            "Contractor Not Interested",
            "other....",
        ],
        warnningOpen: false,
        warnningContent: "Please Select Contractor!",
        assignContractor: {
            client_id: "",
            assign_user_id: "",
            status: "",
            previous_status: "",
            contractorids: null,
            comments: "",
            reason: "",
        },
    });
    const {assignContractor, warnningOpen, open, reasons, warnningContent} = state
    const { selectedContractors }= complianceWorkflow
    const handleClickToOpen = () => {
        if (selectedContractors.length > 0) {
            setState((prevState) => ({ ...prevState, open: true }));
        } else {
            setState((prevState) => ({
                ...prevState,
                warnningOpen: true,
            }));
        }
    };
    const handleSubmit = async () => {
        const demoArray = assignContractor?.contractorids.map((item) => `${item.id}`
        );
        await assignContractorsAction({
            ...assignContractor,
            contractorids: demoArray,
        });
        setState((prevState) => ({ ...prevState, open: false }));
    };
    const handleToClose = async() => {
        setState((prevState) => ({ ...prevState, open: false }));
    };
    return (
        <Fragment>
            <SuccessfullModel
                open={warnningOpen}
                handleClose={() => setState({ ...state, warnningOpen: false })}
                content={warnningContent}
            />
            <StatusUpdateDialog
                open={open}
                title={`Contractor :  ${
                    selectedContractors.length === 1
                        ? selectedContractors[0]?.display_name
                        :selectedContractors.length> 1 ? "Multiple Selected Contractor": "---"
                }`}
                sx={{borderRadius:"40px"}}
                openBtnTxt={userLoggedIn?.language_data?.complianceworkflow?.reject || "Reject"}
                handleSubmit={handleSubmit}
                handleToClose={handleToClose}
                actionBtnText={userLoggedIn?.language_data?.complianceworkflow?.reject || "Reject"}
                handleClickToOpen={handleClickToOpen}
                className={"button_outline"}
            >
                <Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12} mx={6} mt={1}>
                            <DropDownComponent
                                // value={rejectReason}
                                name="rejectReason"
                                fullWidth={true}
                                label="Reason for Rejection"
                            >
                                {reasons.map((obj) => (
                                    <MenuItem
                                        key={obj}
                                        value={obj}
                                        onClick={() =>
                                            setState((prevState) => ({
                                                ...prevState,
                                                assignContractor: {
                                                    ...prevState.assignContractor,
                                                    client_id:
                                                        customer
                                                            ?.selectedCustomer
                                                            ?.id,
                                                    assign_user_id:
                                                        complianceWorkflow
                                                            ?.conductorList[0]
                                                            ?.id,
                                                    status: "R",
                                                    previous_status:
                                                        props?.currentTab,
                                                    contractorids:
                                                        complianceWorkflow?.selectedContractors,
                                                    reason: obj,
                                                },
                                            }))
                                        }
                                    >
                                        {obj}
                                    </MenuItem>
                                ))}
                            </DropDownComponent>
                        </Grid>
                        <Grid item xs={12} mx={6}>
                            <TextFieldComponent
                                label={"Comments"}
                                fullWidth={true}
                                onChange={(event) =>
                                    setState((prevState) => ({
                                        ...prevState,
                                        assignContractor: {
                                            ...prevState.assignContractor,
                                            comments: event.target.value,
                                        },
                                    }))
                                }
                                value={state.comments}
                                id="rejectContractorComments"

                            />
                        </Grid>
                    </Grid>
                </Box>
            </StatusUpdateDialog>
        </Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,
        customer: state.customer,
        user: state.user,
        complianceWorkflow: state.complianceWorkflow,
        selectedContractors: state.complianceWorkflow.selectedContractors,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            assignContractorsAction,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(RejectContractor);
