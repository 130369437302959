import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { FormControlLabel } from "@mui/material";
import Checkbox from "../Checkbox/CheckboxComponent";
import TableComponent from "../../components/Table/TableComponent";
import "../../../assets/styles/Container/SignedDocument.scss";
import { getSignedDocumentAprrovedList } from "../../store/Action/SignedDocumentAction";
import LoadingComponent from "../../components/Loading/LoadingComponent";
function ImportTable(props) {
    const navigate = useNavigate();
    const {
        getSignedDocumentAprrovedList,
        signedDocuments,
        setSelectContracts,
        isCheckedAll,
        isChecked,
        tableData,
        userLoggedIn
    } = props;

    const [state, setState] = useState({
        openDeleteDialog: false,
        deleteData: null,
        openAddDialog: false,
        openSuccesfullModel: false,
        modelContent: "",
        id: null,
        address: { state: "" },
        isLoading: false,
        filteredData: null,
    });
    const handleSelectAll = (e) => {
        setSelectContracts((prevState) => ({
            ...prevState,
            isCheckedAll: !isCheckedAll,
            isChecked: filteredData?.length && tableData.map((li) => li.id),
        }));
        if (isCheckedAll) {
            setSelectContracts((prevState) => ({
                ...prevState,
                isChecked: [],
            }));
        }
    };

    const { isLoading, filteredData } = state;
    useEffect(() => {
        async function callApi() {
            setState((prevState) => ({ ...prevState, isLoading: true }));
            await getSignedDocumentAprrovedList(null, null, false);
            setState((prevState) => ({ ...prevState, isLoading: false }));
        }
        if (signedDocuments === null) callApi();
        if (signedDocuments) {
            setState((prevState) => ({
                ...prevState,
                filteredData: signedDocuments,
            }));
        }
    }, [getSignedDocumentAprrovedList, signedDocuments]);

    const renderClassStatus = (obj, row) => {
        if (obj?.rowClass) return obj?.rowClass;
        else if (row?.status_name === "Approved") return "cellApproved";
        else if (
            row?.status_name?.replace(/\s/g, "") === "PendingforApproval" ||
            row?.status_name === "Others"
        )
            return "cellPending";
        else if (
            row?.status_name?.replace(/\s/g, "") === "OnHold" ||
            row?.status_name === "Rejected" ||
            row?.status_name === "Reassigned"
        )
            return "cellOnHold";
    };
    const headerList = [
        {
            id: 1,
            name: userLoggedIn?.language_data?.documentdatabase?.document_name || "Document Name",
            headerClass: "tableHeading",
            accessibleKey: "document_name",
            rowClass: "nameFile",
            headerAlign: "center",
            rowAlign: "center",
        },
        {
            id: 2,
            name:  userLoggedIn?.language_data?.documentdatabase?.document_category || "Category",
            headerClass: "tableHeading",
            rowClass: "tableBodyRow",
            accessibleKey: "category",
            headerAlign: "center",
            rowAlign: "center",
        },

        {
            id: 5,
            name: userLoggedIn?.language_data?.comman?.status || "Status",
            headerClass: "tableHeading",
            accessibleKey: "status_name",
            headerAlign: "center",
            rowAlign: "center",
        },
        {
            id: 6,
            name: userLoggedIn?.language_data?.comman?.approved_by,
            headerClass: "tableHeading",
            accessibleKey: "assign_user",
            headerAlign: "center",
            rowAlign: "center",
        },
    ];

    const handleClickOnCheckbox = (e) => {
        const { value, checked } = e.target;
        if (!checked) {
            //remove
            setSelectContracts((prevState) => ({
                ...prevState,
                isChecked: isChecked.filter((item) => item !== Number(value)),
            }));
        } else {
            //add
            let row = tableData.filter((item) => item.id === value);
            setSelectContracts((prevState) => ({
                ...prevState,
                isChecked: [...isChecked, Number(value)],
            }));
        }
    };

    return (
        <>
            {!signedDocuments || isLoading ? (
                <LoadingComponent />
            ) : (
                <Box sx={{ px: { md: 7, xs: 1, mt: "40px" } }}>
                    {filteredData?.length !== 0 && (
                        <TableComponent
                            headerList={headerList}
                            extraHeaderLeft={
                                <TableCell
                                    align="left"
                                    className="tableHeading"
                                >
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={handleSelectAll}
                                            />
                                        }
                                        labelPlacement="Select"
                                    ></FormControlLabel>
                                </TableCell>
                            }
                        >
                            {filteredData?.length !== 0 &&
                                filteredData?.map((row, index) => (
                                    <TableRow
                                        key={row?.name}
                                        className={
                                            index % 2 !== 0
                                                ? "tertiaryBackground"
                                                : ""
                                        }
                                    >
                                        <TableCell
                                            align="center"
                                            className="typo4"
                                        >
                                            <FormControlLabel
                                                value={row?.id}
                                                control={
                                                    <Checkbox
                                                        checked={isChecked?.includes(
                                                            row?.id
                                                        )}
                                                        onChange={
                                                            handleClickOnCheckbox
                                                        }
                                                        value={row?.id}
                                                    />
                                                }
                                                labelPlacement="Select"
                                            ></FormControlLabel>
                                        </TableCell>
                                        {headerList?.map((obj) => (
                                            <TableCell
                                                component="th"
                                                key={obj?.name}
                                                align={obj?.rowAlign}
                                                className={renderClassStatus(
                                                    obj,
                                                    row
                                                )}
                                            >
                                                {obj.id === 3
                                                    ? row?.category?.name
                                                    : obj?.id === 4
                                                    ? row?.assign_user
                                                          ?.display_name
                                                    : obj?.id === 3 &&
                                                      row?.status_name ===
                                                          "Approved"
                                                    ? row?.user?.display_name
                                                    : obj?.id === 6 &&
                                                      row?.status_name !==
                                                          "Approved"
                                                    ? "Not Assigned"
                                                    : row[obj?.accessibleKey]}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                        </TableComponent>
                    )}
                </Box>
            )}
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,
        customerDropdown: state?.auth?.commonDropdown?.states,
        signedDocuments: state?.signedDocs?.selectedDocumentData,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getSignedDocumentAprrovedList,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ImportTable);
