import React, { useEffect, useRef } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import SignatureCanvas from "react-signature-canvas";
import TextFieldComponent from "../../../components/TextField/TextFieldComponent";
import ButtonComponent from "../../../components/ButtonComponent";

const Signature = (props) => {
    const {
        state,
        setState,
        data,
        children,
        handleChange,
        handlerFieldChange,
        isRead,
        isEdit,
    } = props;

    const sigCanvas = useRef();

    useEffect(() => {
        if (isRead) sigCanvas?.current?.off();
        else sigCanvas?.current?.on();
    }, []);

    const handlerIsEdit = () => {
        if (isEdit) {
            setState((prevState) => ({ ...prevState, editLabel: true }));
        }
    };
    const onBlur = () => {
        setState((prevState) => ({ ...prevState, editLabel: false }));
    };

    const onhandler = () => {
        const URL = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
        handlerFieldChange(URL, data?.id, "signature");
    };

    return (
        <Grid container>
            <Grid
                item
                xs={12}
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <TextFieldComponent
                    id="signatureLabel"
                    sx={{
                        display:
                            state.selectedEle?.selected && state?.editLabel
                                ? "block"
                                : "none",
                        color: state.fontColor,
                        "& .MuiInputBase-input": {
                            color: state.fontColor,
                        },
                    }}
                    onBlur={onBlur}
                    onChange={handleChange}
                    value={data?.label}
                    className="elementHeadingTextField"
                    autoFocus={true}
                />
                <Typography
                    display={
                        state.selectedEle?.selected && state?.editLabel
                            ? "none"
                            : "block"
                    }
                    className="elementHeading"
                    sx={{ color: state.fontColor }}
                    variant="h5"
                    onClick={handlerIsEdit}
                >
                    {data.label}
                </Typography>
                {children}
            </Grid>
            <Grid item xs={12}>
                {isRead && data?.formValues?.signature ? (
                    <img
                        src={data?.formValues?.signature}
                        className="signatureImg"
                    />
                ) : (
                    <SignatureCanvas
                        penColor="black"
                        canvasProps={{
                            className: "sigCanvas",
                        }}
                        backgroundColor="white"
                        onEnd={onhandler}
                        ref={sigCanvas}
                    />
                )}
            </Grid>
            {!isRead ? (
                <Grid item xs={12} justifyContent={"flex-end"} display={"flex"}>
                    <ButtonComponent
                        title={"Clear"}
                        className="clearButton"
                        onClick={() => sigCanvas.current.clear()}
                    />
                </Grid>
            ) : null}
        </Grid>
    );
};
export default Signature;
