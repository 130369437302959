import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Xarrow from "react-xarrows";
import { InfoOutlined } from "@mui/icons-material";
import Box from "@mui/system/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TooltipComponent from "../../components/ToolTip/TooltipComponent";
import IconButton from "@mui/material/IconButton";
import Navbar from "../../common/Header/Navbar";
import { getCustomerHierarchy } from "../../store/Action/CustomerAction";
import color from "../../../assets/styles/Color.scss";
import LoadingComponent from "../../components/Loading/LoadingComponent";

const CustomerHierarchy = (props) => {
    const { getCustomerHierarchy, hierarchy } = props;
    const [state, setState] = useState({ isLoading: false });
    const { isLoading } = state;
    const arr = [1, 2, 3, 4, 5];
    const sum = arr.reduce((total, num) => total + num, 5);
    useEffect(() => {
        const pathName = window.location.pathname.split("/");
        async function callApi() {
            setState((prevState) => ({ ...prevState, isLoading: true }));
            await getCustomerHierarchy(parseInt(pathName[2]));
            setState((prevState) => ({ ...prevState, isLoading: false }));
        }
        callApi();
    }, [getCustomerHierarchy]);

    const renderSubCustomer = () => {
        if (
            hierarchy?.sub_customers?.length &&
            hierarchy?.sub_customers[0]?.sub_customers
        ) {
            const subCustomers = hierarchy.sub_customers[0].sub_customers;
            const rows = Math.ceil(subCustomers.length / 2);
            const isFewChildren = subCustomers.length <= 3;
            return (
                <Grid container gap={2} className="heirarchyGrid">
                {Array.from({ length: rows }, (_, rowIndex) => (
                    <Grid container key={rowIndex} gap={2} justifyContent={isFewChildren ? 'center' : 'flex-start'}>
                        {subCustomers.slice(rowIndex * 3, rowIndex * 3 + 3).map((customer, index) => (
                            <React.Fragment key={index}>
                                <Box id={`child${index + 1}`} className="box1">
                                    <Typography className="parent">
                                        {customer.name}
                                    </Typography>
                                    <TooltipComponent
                                        arrow={true}
                                        title={`${customer?.conductor_type?.toUpperCase()} & ${customer?.compliance_service
                                            .toString()
                                            .toUpperCase()
                                            .replaceAll(",", ", ")}`}
                                        placement="right-end"
                                    >
                                        <IconButton className="primaryColor">
                                            <InfoOutlined/>
                                        </IconButton>
                                    </TooltipComponent>
                                </Box>
                                <Xarrow
                                    start="parent2"
                                    end={`child${index + 1}`}
                                    path={"grid"}
                                    curveness={0.5}
                                    headSize={4}
                                    strokeWidth={2}
                                    startAnchor="bottom"
                                    endAnchor="top"
                                    color={color.primary}
                                    gridBreak="50"
                                />
                            </React.Fragment>
                        ))}
                    </Grid>
                ))}
            </Grid>
            );
        }
    };

    return (
        <>
            <Navbar />
            {!isLoading ? (
                <Box sx={{ px: { md: 7, xs: 1 } }}>
                    <Grid container>
                        <Grid item lg={12} mt={12}>
                            <Typography className="profileEditHeading">
                                Customer Hierarchy
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container gap={10} className="heirarchyGrid">
                        <Box id="parent1" className="box1">
                            <Typography className="parent">
                                {hierarchy?.name}
                            </Typography>
                            <TooltipComponent
                                arrow={true}
                                title={`${hierarchy?.type?.toUpperCase()} & ${hierarchy?.compliance_service
                                    .toString()
                                    .toUpperCase()
                                    .replaceAll(",", ", ")}`}
                                placement="right-end"
                            >
                                <IconButton className="primaryColor">
                                    <InfoOutlined/>
                                </IconButton>
                            </TooltipComponent>
                        </Box>
                        <Grid container className="heirarchyGrid">
                            {hierarchy?.sub_customers?.length &&
                                hierarchy?.sub_customers.map((customer) => (
                                    <Box id="parent2" className="box1">
                                        <Typography className="parent">
                                            {customer.name}
                                        </Typography>
                                        <TooltipComponent
                                            arrow={true}
                                            title={`${customer?.type?.toUpperCase()} & ${customer?.compliance_service
                                                .toString()
                                                .toUpperCase()
                                                .replaceAll(",", ", ")}`}
                                            placement="right-end"
                                        >
                                            <IconButton className="primaryColor">
                                                <InfoOutlined/>
                                            </IconButton>
                                        </TooltipComponent>
                                    </Box>
                                ))}
                        </Grid>
                        {renderSubCustomer()}
                    </Grid>
                    {
                        hierarchy ?
                            <Xarrow
                                start="parent1"
                                end="parent2"
                                path={"grid"}
                                curveness={0.5}
                                headSize={4}
                                strokeWidth={2}
                                startAnchor="bottom"
                                endAnchor="top"
                                color={color.primary}
                                gridBreak="50"
                            />
                        : null
                    }
                </Box>
            ) : (
                <LoadingComponent />
            )}
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        hierarchy: state?.customer.hierarchy,
        selectedCustomer: state?.customer?.selectedCustomer,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ getCustomerHierarchy }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerHierarchy);
