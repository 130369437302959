// import { toast } from "react-hot-toast";
import { API_CONSTANTS } from "../../config/ApiConstants";
import {
    getApiCall,
    postApiCall,
    deleteApiCall,
    putApiCall,
} from "../../utils/Action";
import { updateNoOfUser } from "./CustomerAction";

export const CREATE_USER = "CREATE_USER";
export const CREATE_USER_PROFILE = "CREATE_USER_PROFILE";
export const GET_USER_LIST = "GET_USER_LIST";
export const TOGGLE_DELETE_POPUP = "TOGGLE_DELETE_POPUP";
export const DELETE_USER_BY_ID = "DELETE_USER_BY_ID";
export const SET_SELECTED_USER = "SET_SELECTED_USER";
export const UPDATE_USER_BY_ID = "UPDATE_USER_BY_ID";
export const CHANGE_VIEW_TYPE = "CHANGE_VIEW_TYPE";
export const GET_USER_BY_ROLE = "GET_USER_BY_ROLE";
export const SET_USER_PER_PAGE = "SET_USER_PER_PAGE";
export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
export const GET_USER_MODULE_LIST = "GET_USER_MODULE_LIST";

export const createUserAction = (state) => {
    return async (dispatch, getState) => {
        try {
            const currentState = getState();
            const userList = currentState.user.userPagination;
            const response = await postApiCall(
                API_CONSTANTS.CREATE_USER,
                state
            );
            let profileRes;
            if (state?.photograph?.filename && state?.photograph?.content) {
                profileRes = await dispatch(
                    createUserProfile(
                        response?.data?.result?.id,
                        state.photograph
                    )
                );
            }
            if (state?.client_id) {
                await dispatch(updateNoOfUser(state?.client_id));
            }
            if (response?.status === 201 || profileRes?.status === 200) {
                return dispatch({
                    type: CREATE_USER,
                    payload: {
                        ...response?.data?.result,
                        photograph: profileRes?.data?.result?.photograph,
                    },
                });
            } else {
                // toast.error(
                //     response?.data?.message || profileRes?.data?.message
                // );
                return false;
            }
        } catch (error) {
            throw error;
        }
    };
};

export const createUserProfile = (userId, state) => {
    return async () => {
        try {
            return await postApiCall(`/users/${userId}/update-photograph`, {
                photograph: { ...state },
            });
        } catch (error) {
            throw error;
        }
    };
};

export const getUserList = (
    id = null,
    currentPage = 1,
    customerPageLimit = null,
    search = "",
    client_id = "",
    staus= 'active',
) => {
    return async (dispatch, getState) => {
        try {
            let response;
            const currentState = getState();
            const loggedInClient =
                currentState?.auth?.userLoggedIn?.user?.client_id;
            if (id === null) {
                if (currentState?.auth?.userLoggedIn?.user?.role_id == 1) {
                    response = await getApiCall(
                        `/users?search=${encodeURIComponent(search)}` +
                            "&page=" +
                            currentPage +
                            "&page_limit=" +
                            customerPageLimit + 
                            "&status=" + staus
                    );
                    response.data.result.data = response?.data?.result.data;
                } else {
                    response = await getApiCall(
                        `/users?search=${encodeURIComponent(search)}` +
                            "&page=" +
                            currentPage +
                            "&page_limit=" +
                            customerPageLimit +
                            "&client_id=" +
                            client_id +
                            "&status=" + staus
                    );
                    response.data.result.data = response?.data?.result.data;
                }
            } else {
                response = await getApiCall(
                    `/users${id ? "?client_id=" + id : " " + loggedInClient}` +
                        "&page=" +
                        currentPage ||
                        0 +
                            "&page_limit=" +
                            customerPageLimit +
                            "&search=" +
                            search +
                            "&status=" + staus
                );
            }
            dispatch({
                type: GET_USER_LIST,
                payload: response?.data?.result || [],
            });
        } catch (error) {
            throw error;
        }
    };
};

export const getUserByRole = (
    id = null,
    currentPage = null,
    customerPageLimit = null,
    search
) => {
    return async (dispatch) => {
        try {
            const response = await getApiCall(
                `/users${id ? "?role_id=" + id : ""}` +
                    "&page=" +
                    currentPage +
                    "&page_limit=" +
                    customerPageLimit,
                search
            );
            dispatch({
                type: GET_USER_BY_ROLE,
                payload: response?.data?.result || [],
            });
        } catch (error) {
            throw error;
        }
    };
};

export const toggleDeletePopup = (data) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_DELETE_POPUP, payload: data });
    };
};

export const deleteUserById = (data) => {
    return async (dispatch) => {
        try {
            const response = await deleteApiCall(`/users/${data.id}`);
            if (response?.status === 200) {
                dispatch({
                    type: UPDATE_USER_BY_ID,
                    payload: {
                        ...data,
                        status:
                            data?.status === "active" ? "inactive" : "active",
                    },
                });
            }
        } catch (error) {
            throw error;
        }
    };
};

export const getUserById = (id, type = null) => {
    return async (dispatch) => {
        try {
            const response = await getApiCall(`/users/${id}`);
            if (response?.status === 200) {
                dispatch({
                    type: SET_SELECTED_USER,
                    payload: { ...response?.data?.result, type: type },
                });
            }
        } catch (error) {
            throw error;
        }
    };
};

export const setSelectedUser = (data) => {
    return async (dispatch) => {
        dispatch({ type: SET_SELECTED_USER, payload: data });
    };
};

export const updateUserById = (data) => {
    return async (dispatch) => {
        try {
            const response = await putApiCall(`/users/${data?.id}`, data);
            let profileRes;
            if (data?.photograph?.filename && data?.photograph?.content) {
                profileRes = await dispatch(
                    createUserProfile(
                        response?.data?.result?.id,
                        data?.photograph
                    )
                );
            }
            if (response?.status === 200 || profileRes?.status === 200) {
                return dispatch({
                    type: UPDATE_USER_BY_ID,
                    payload: {
                        ...response?.data?.result,
                        photograph:
                            profileRes?.data?.result?.photograph ||
                            data?.content,
                    },
                });
            } else {
                // toast.error(
                //     response?.data?.message || profileRes?.data?.message
                // );
                return false;
            }
        } catch (error) {
            throw error;
        }
    };
};

export const changeViewType = (data) => {
    return async (dispatch) => {
        dispatch({ type: CHANGE_VIEW_TYPE, payload: data });
    };
};

export const setListViewLimit = (data) => {
    return async (dispatch) => {
        try {
            dispatch({ type: SET_USER_PER_PAGE, payload: data });
        } catch (error) {
            throw error;
        }
    };
};

export const setCurrentPage = (pageNumber) => {
    return async (dispatch) => {
        try {
            dispatch({ type: SET_CURRENT_PAGE, payload: pageNumber });
        } catch (error) {
            throw error;
        }
    };
};

export const getUserModuleList = (id) => {
    return async (dispatch) => {
        try {
            const response = await getApiCall("user-permission", { id: id });
            dispatch({
                type: GET_USER_MODULE_LIST,
                payload: response?.data?.result,
            });
        } catch (error) {
            throw error;
        }
    };
};
