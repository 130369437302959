import * as Actions from "../Action/AssessmentSettingAction";

const initialState = {
    test_type: [],
    list: [],
    assessmentSummaryData: null,
    testQuestionList: null,
};

const assessmentSettings = (state = initialState, action) => {
    switch (action?.type) {
        case Actions?.ASSESSMENT_SETTING: {
            return { ...action?.payload };
        }
        case Actions.ASSESSMENT_SUMMARY: {
            return {
                ...state,
                assessmentSummaryData: action.payload,
            };
        }
        case Actions.START_TEST: {
            return {
                ...state,
                testQuestionList: action.payload || null,
            };
        }
        case Actions.UPDATE_REPRESSED_QUESTION: {
            return {
                ...state,
                testQuestionList: {
                    ...state.testQuestionList,
                    mqs: action.payload,
                },
            };
        }
        case Actions.DELETE_SELF_TEST: {
            let testList = state.list.filter(
                (obj) => obj.id === action?.payload?.id
            );
            return {
                ...state,
                list: testList,
            };
        }
        default: {
            return state;
        }
    }
};

export default assessmentSettings;
