import React from "react";
import { useState } from "react";
import { Typography, Grid, Link } from "@mui/material";
import DialogComponent from "../../../components/DialogsBox/DialogComponent";
import ButtonComponent from "../../../components/ButtonComponent";
import { connect } from "react-redux";
import {Translate } from 'react-auto-translate';
const ShowAssesmentUserData = (props) => {
    const { assessmentSummaryDetails, userLoggedIn, hideExtra } = props;
    const [state, setState] = useState({
        isShowViewDetailsDialog: { open: false, title: null, content: null },
    });
    const { isShowViewDetailsDialog } = state;
    const viewDetailsLink = (title) => {
        return (
            <Link
                onClick={() => handlerViewContainDialog(title)}
                sx={{
                    cursor: "pointer",
                }}
            >
                View Details
            </Link>
        );
    };
    const assismentDetails = [
        {
            title: <Translate>Name:</Translate>,
            tileClassName: "roleText",
            details: assessmentSummaryDetails?.test_taker_name || "---",
            detailsClassName: "managerText",
        },
        {
            title: <Translate>Test Status:</Translate>,
            tileClassName: "roleText",
            details: assessmentSummaryDetails?.test_status,
            detailsClassName:
                assessmentSummaryDetails?.test_status === "Completed"
                    ? "failText"
                    : "completeText",
        },
        {
            title: <Translate>Test Result Knocked Over:</Translate>,
            tileClassName: "roleText",
            details: assessmentSummaryDetails?.knocked_over,
            detailsClassName: "managerText",
            isHide: localStorage.getItem("role_id") == 2 || hideExtra,
        },
        {
            title: <Translate>Test taker:</Translate>,
            tileClassName: "roleText",
            details:
                assessmentSummaryDetails?.test_taker_role ||
                assessmentSummaryDetails?.role_name,
            detailsClassName: "managerText",
        },
        {
            title: <Translate>Result:</Translate>,
            tileClassName: "roleText",
            details: assessmentSummaryDetails?.result,
            detailsClassName:
                assessmentSummaryDetails?.result === "Pass"
                    ? "failText"
                    : "completeText",
            isHide: localStorage.getItem("role_id") == 2,
        },
        {
            title:
                userLoggedIn?.language_data?.customer?.jurisdiction + ":" ||
                <Translate>Jurisdiction:</Translate>,
            tileClassName: "roleText",
            details:
                typeof assessmentSummaryDetails?.jurisdiction === "string"
                    ? assessmentSummaryDetails?.jurisdiction
                    : viewDetailsLink("jurisdiction"),
            detailsClassName: "managerText",
            isHide: localStorage.getItem("role_id") == 2 || hideExtra,
        },
        {
            title:
                userLoggedIn?.language_data?.customer?.state + ":" || <Translate>State:</Translate>,
            tileClassName: "roleText",
            details: assessmentSummaryDetails?.state || "---",
            detailsClassName: "managerText",
        },
        {
            title: <Translate>Test Score:</Translate>,
            tileClassName: "roleText",
            details: assessmentSummaryDetails?.score + ` %`,
            detailsClassName: "managerText",
            isHide: localStorage.getItem("role_id") == 2,
        },
        {
            title: <Translate>Applicable Law:</Translate>,
            tileClassName: "roleText",
            details:
                typeof assessmentSummaryDetails?.laws === "string"
                    ? assessmentSummaryDetails?.laws
                    : viewDetailsLink("law"),
            detailsClassName: "managerText",
            isHide: localStorage.getItem("role_id") == 2 || hideExtra,
        },
    ];

    const handlerViewContainDialog = (title) => {
        let content =
            title === "law"
                ? assessmentSummaryDetails?.laws &&
                  assessmentSummaryDetails?.laws?.map((obj) => {
                      return (
                          <Typography
                              className="font_18_600"
                              sx={{ paddingBottom: 1 }}
                          >
                              {obj}
                          </Typography>
                      );
                  })
                : assessmentSummaryDetails?.jurisdiction?.length !== 0 &&
                  assessmentSummaryDetails?.jurisdiction?.map((obj) => {
                      return (
                          <Typography
                              className="font_18_600"
                              sx={{ paddingBottom: 1 }}
                          >
                              {obj}
                          </Typography>
                      );
                  });
        setState((prevState) => ({
            ...prevState,
            isShowViewDetailsDialog: {
                open: true,
                title: title,
                content: content,
            },
        }));
    };
    return (
        <>
            <DialogComponent
                open={isShowViewDetailsDialog.open}
                title={isShowViewDetailsDialog.title}
                content={isShowViewDetailsDialog.content}
                handleClose={() => {
                    setState((prevState) => ({
                        ...prevState,
                        isShowViewDetailsDialog: {
                            open: false,
                            title: null,
                        },
                    }));
                }}
                action={
                    <>
                        <ButtonComponent
                            title={"Close"}
                            onClick={() => {
                                setState((prevState) => ({
                                    ...prevState,
                                    isShowViewDetailsDialog: {
                                        open: false,
                                        title: null,
                                    },
                                }));
                            }}
                        />
                    </>
                }
            />
            
            {assismentDetails.map((obj) => {
                return (
                    <>
                        {!obj.isHide && (
                            <>
                                <Grid item md={2} xs={12}>
                                    <Typography className={obj.tileClassName}>
                                        {obj.title}
                                    </Typography>
                                </Grid>
                                <Grid item md={2} xs={12}>
                                    <Typography
                                        className={obj.detailsClassName}
                                    >
                                        {obj.details}
                                    </Typography>
                                </Grid>
                            </>
                        )}
                    </>
                );
            })}
        </>
    );
};
const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,

        // assessmentSummaryData: state?.assessmentSettings?.assessmentSummaryData,
    };
};
export default connect(mapStateToProps, null)(ShowAssesmentUserData);
