import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import DropDownComponent from "../../../components/DropDown/DropDownComponent";
import TextFieldComponent from "../../../components/TextField/TextFieldComponent";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { dropDownItems } from "../../../store/Action/FormStudioAction";

const DropDown = (props) => {
    const {
        state,
        setState,
        data,
        children,
        handleChange,
        isRead,
        handlerFieldChange,
        setMenuItem,
        isEdit,
        userLoggedIn
    } = props;

    const handlerIsEdit = () => {
        if (isEdit)
            setState((prevState) => ({ ...prevState, editLabel: true }));
    };

    const onBlur = () => {
        setState((prevState) => ({ ...prevState, editLabel: false }));
    };

    return (
        <Grid container>
            <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "space-between" }}
            >
                <TextFieldComponent
                    sx={{
                        display:
                            state?.selectedEle?.selected && state?.editLabel
                                ? "block"
                                : "none",
                        color: state?.fontColor,
                        "& .MuiInputBase-input": { color: state?.fontColor },
                    }}
                    id="dropdownLabel"
                    onBlur={onBlur}
                    onChange={handleChange}
                    value={data?.label}
                    className="elementHeadingTextField textFieldFromBuilder"
                    autoFocus={true}
                />
                <Typography
                    display={
                        state?.selectedEle?.selected && state?.editLabel
                            ? "none"
                            : "block"
                    }
                    className="elementHeading"
                    sx={{ color: state?.fontColor }}
                    variant="h5"
                    onClick={handlerIsEdit}
                >
                    {data?.label}
                </Typography>
                {children}
            </Grid>
            <Grid item container spacing={2}>
                {isEdit ? (
                    <Grid item xs={6}>
                        <DropDownComponent
                            label={userLoggedIn?.language_data?.formstudio?.dropdown || "Dropdown"}
                            className="textFieldMultiline"
                            onChange={(event) =>
                                handlerFieldChange(event, data?.id)
                            }
                            value={data?.fieldDetails?.valueLabel}
                            name={userLoggedIn?.language_data?.formstudio?.dropdown ||  "dropDown"}
                        >
                            <TextFieldComponent
                                id="dropdownOptions"
                                label={"Enter Options"}
                                className="textFieldMultiline"
                                name={"option"}
                                onChange={setMenuItem}
                                multiline={true}
                                value={data?.fieldDetails?.valueLabel}
                            />
                        </DropDownComponent>
                    </Grid>
                ) : (
                    <Grid item xs={6}>
                        <DropDownComponent
                            className="textFieldMultiline"
                            onChange={(event) =>
                                handlerFieldChange(event, data?.id)
                            }
                            disabled={isRead}
                            name={"dropDown"}
                            value={data?.formValues?.dropDown || ""}
                        >
                            {data?.fieldDetails?.valueLabel?.map(
                                (obj, index) => (
                                    <MenuItem key={index} value={obj}>
                                        {obj}
                                    </MenuItem>
                                )
                            )}
                        </DropDownComponent>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,
    };
};

export default connect(mapStateToProps, null) (DropDown);
