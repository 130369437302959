import React, { Fragment, useEffect, useState } from "react";
import { connect,  } from "react-redux";
import { bindActionCreators } from "redux";
import {
    getDocumentsAction,
    addDocumentAction,
    deleteDocumentAction,
    updateDocumentsAction,
} from "../../../store/Action/DocumentsAction";
import { getDocumentAprrovedList } from "../../../store/Action/DocumentDatabaseAction";
import WorkFlowTable2 from "../ContractorDetails/DocumentTable";
import SuccessfullModel from "../../../components/DialogsBox/SuccessfullModel";
import CommonDeleteDialog from "../../../components/DialogsBox/CommonDeleteDialog";
import "../../../../assets/styles/Container/RcpCustomerInformation.scss";
import DocsTemplates from "../ContractorDetails/FooterComponents/DocumentDatabase/DocsTemplates";

function DocumentToBeCollected(props) {
    const {
        initilizeStates,
        addDocumentAction,
        deleteDocumentAction,
        updateDocumentsAction,
        documentDatabase,
        customer,
        setToggle,
        user,
        guid,
        userLoggedIn
    } = props;

    const { id } = user.selectedUser;
    const [editFormData, setEditFormData] = useState({
        isExist: false,
        listItem: null,
        openSuccesfullModel: false,
        openAdd: false,
        sequence: 0,
        document_form: {
            guid: guid,
            contractor_id: user?.selectedUser?.id,
            name: "",
            sequence: 1,
            required: true,
        },
    });
    const [tableList, setTableList] = useState([]);
    const [selectedDocumentDatabaseData, setselectedDocumentDatabaseData] = useState({
        isCheckedAll: false,
        isChecked: [],
    });
    const { isCheckedAll, isChecked } = selectedDocumentDatabaseData;
    const [state, setState] = useState({
        oprn: false,
        openImport: false,
        document: {
            filename: null,
            content: null,
        },
        files: [],
    });
    const headerList = [
        {
            name: userLoggedIn?.language_data?.documentdatabase?.document_name || "Document Name",
            headerClass: "tableHeading",
            accessibleKey: "name",
            rowClass: "typo4",
            headerAlign: "left",
            rowAlign: "left",
        },
    ];
    useEffect(() => {
        async function callApi() {
            setState((prevState) => ({ ...prevState, isLoading: true }));
            const data = await getDocumentAprrovedList({});
            setState((prevState) => ({ ...prevState, isLoading: false }));
        }
        if (documentDatabase === null) callApi();
        if (documentDatabase) {
            setState((prevState) => ({
                ...prevState,
                filteredData: documentDatabase,
            }));
        }
        callApi();
    }, [getDocumentAprrovedList, selectedDocumentDatabaseData]);
    // Add Document
    const handleAdd = async () => {
        const isExist = tableList.find(
            (obj) => obj.name === editFormData?.document_form?.name
        );
        if (isExist) {
            setEditFormData({
                ...editFormData,
                isExist: true,
            });

        } else {
            const res = await addDocumentAction(editFormData?.document_form);
            initilizeStates(
                customer.selectedCustomer.guid,
                user.selectedUser.id
            );
            setEditFormData({
                ...editFormData,
                openAdd: false,
                document_form: { ...editFormData.document_form, name: "" },
                openSuccesfullModel: true,
                modelContain: `Document added sucessfully!`,
            });
        }
    };

    //update
    async function handleUpdate() {
        const res = await updateDocumentsAction(
            guid,
            user.selectedUser.id,
            tableList
        );
        initilizeStates(customer.selectedCustomer.guid, user.selectedUser.id);
        setEditFormData({
            ...editFormData,
            openSuccesfullModel: true,
            modelContain: "Document updated successfully!",
        });
    }
    

    //Delete Document
    async function handleDelete() {
        const res = await deleteDocumentAction(guid, editFormData.listItem?.id);
        initilizeStates(customer.selectedCustomer.guid, user.selectedUser.id);
        setEditFormData({
            ...editFormData,
            listItem: null,
            openSuccesfullModel: true,
            modelContain: "Document removed sucessfully!",
        });
        // }
    }
    const deleteContent = `Are you sure,you want to remove the (${editFormData?.listItem?.name} Document) from the Documents?`;

    return (
        <Fragment>
            <SuccessfullModel
                open={editFormData.openSuccesfullModel}
                handleClose={() =>
                    setEditFormData({
                        ...editFormData,
                        openSuccesfullModel: false,
                        modelContain: null,
                    })
                }
                content={editFormData.modelContain}
            />
            <DocsTemplates
                open={editFormData.openAdd}
                InputProps={{ maxLength: 22 }}
                onClose={(isOpen) => {
                    setEditFormData({
                        ...editFormData,
                        openAdd: isOpen,
                    });
                }}
                onClick={handleAdd}
                onChange={(e) => {
                    setEditFormData({
                        ...editFormData,
                        document_form: {
                            ...editFormData.document_form,
                            name: e.target.value,
                        },
                    });
                }}
                isExist={editFormData.isExist}
                title="Document"
                label="Document"
                tableData={documentDatabase}
                headers={headerList}
                setState={setState}
                selectedDocumentDatabaseData={selectedDocumentDatabaseData}
                setselectedDocumentDatabaseData={setselectedDocumentDatabaseData}
                isChecked={isChecked}
                isCheckedAll={isCheckedAll}
            />
            <CommonDeleteDialog
                open={editFormData.listItem != null ? true : false}
                setOpen={(isOpen) => {}}
                cancelButtonAction={() => {
                    setEditFormData({
                        ...editFormData,
                        listItem: null,
                    });
                }}
                onClick={handleDelete}
                title="Delete Document"
                content={deleteContent}
            />
            <WorkFlowTable2
                headerList={headerList}
                documentDatabase={documentDatabase}
                setToggle={setToggle}
                templates={documentDatabase}
                setTableList={(list) => {
                    setTableList([...list]);
                }}
                tableFor={"documentDatabase"}
                
                setListItem={(item) => {
                    setEditFormData({
                        ...editFormData,
                        listItem: { ...item },
                    });
                }}
                handleUpdate={handleUpdate}
                setOpenAdd={(isOpen) => {
                    setEditFormData({
                        ...editFormData,
                        openAdd: isOpen,
                    });
                }}
            />
        </Fragment>
    );
}
const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,
        customer: state.customer,
        user: state.user,
        documentsList: state.documentsList,
        documentDatabase: state?.docDatabase?.selectedDocumentDatabaseData,

    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getDocumentsAction,
            addDocumentAction,
            deleteDocumentAction,
            updateDocumentsAction,
        },
        dispatch
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DocumentToBeCollected);
