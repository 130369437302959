import Button from "@mui/joy/Button";
import ButtonGroup from "@mui/joy/ButtonGroup";
import Typography from "@mui/joy/Typography";
import { useState } from "react";

function Buttongroup(props) {
    const { btnButton, onClick, state } = props;
    return (
        <ButtonGroup
            variant="soft"
            aria-label=" primary button group"
            buttonFlex="0 2 200px"
            sx={{
                width: "100%",
                color: "#000000",
                marginTop: "10px",
                clear: "both",
                display: "inline-block",
            }}
        >
            {btnButton?.map((data, index) => {
                return (
                    <>
                        <Button
                            key={data.id}
                            onClick={onClick}
                            style={{
                                padding: "16px",
                                border: "none",
                                fontWeight: "600",
                                fontStyle: "normal",
                                fontSize: "18px",

                                borderRadius: "14px 14px 0 0",
                                margin: "0 1px 0 1px",
                                backgroundColor:
                                    state?.defaultComplianceSettingDetails
                                        ?.defaultOption === data.value
                                        ? "#F2F2F2"
                                        : "rgb(251 129 63)",
                                color:
                                    state?.defaultComplianceSettingDetails
                                        ?.defaultOption === data.value
                                        ? "rgb(78 77 77)"
                                        : "rgb(78 77 77)",
                            }}
                            value={data?.value}
                        >
                            {data?.name} 
                        </Button>
                    </>
                );
            })}
        </ButtonGroup>
    );
}

export default Buttongroup;
