import * as Actions from "../Action/LoginAction";
const initialState = {
    userLoggedIn: null,
    commonDropdown: [],
    resetPassword: [],
    forgetPassword:[],
    isValidOtp: ''
};

const LoginReducer = (state = initialState, action) => {
    switch (action?.type) {
        case Actions.LOGIN_ACTION: {
            return {
                ...state,
                userLoggedIn: action.payload,
            };
        }

        case Actions.LOGOUT_ACTION: {
            return {};
        }

        case Actions.GET_DROPDOWN: {
            return {
                ...state,
                commonDropdown: action.payload,
            };
        }

        case Actions.OTP_VERIFICATION: {
            return {
                ...state, 
                isValidOtp: action.payload
            }
        }

        case Actions.RESET_PASSWORD: {
            return {
                ...state,
                rsestPassword: action.payload,
            };
        }

        case Actions.FORGET_PASSWORD: {
            return {
                ...state,
                forgetPassword: action.payload,
            };
        }

        case Actions.UPDATE_PROFILE: {
            return {
                ...state,
                userLoggedIn: {
                    ...state.userLoggedIn,
                    user: {
                        ...state?.userLoggedIn?.user,
                        first_name: action?.payload?.first_name || "",
                        last_name: action?.payload?.last_name || "",
                        middle_name: action?.payload?.middle_name || "",
                        photograph: action?.payload?.photograph || "",
                    },
                },
            };
        }

        case Actions.UPDATE_USER_PERMISSION: {
            return {
                ...state,
                userLoggedIn: { ...state.userLoggedIn, scope: action.payload },
            };
        }

        case Actions.DIRECT_LOGIN: {
            return {
                ...state,
                directLogin: action.payload,
            }
        }

        default: {
            return state;
        }
    }
};

export default LoginReducer;
