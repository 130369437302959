import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import ButtonComponent from "../../components/ButtonComponent";
import { renderSwitch } from "./FormStudioUtils";
import "../../../assets/styles/Container/FormStudio.scss";

const RenderElements = (props) => {
    const {
        state,
        setState,
        formElements,
        handlerFieldChange,
        submitSubmission,
        isRead,
        logo,
        btnText
    } = props;

    const handlerEdit = (elementObj) => {
        let newData = [];
        formElements?.forEach((obj) => {
            if (obj.eleIndex == elementObj.eleIndex) {
                newData.push({ ...obj, selected: true });
            } else {
                newData.push({ ...obj, selected: false });
            }
        });
        setState((prevState) => ({
            ...prevState,
            formElements: newData,
            selectedEle: { ...elementObj, selected: true },
        }));
    };
    

    return (
        <Box
            className="builderContainer"
            sx={{ background: state?.formColor, marginTop: "10px" }}
        >
            {
                logo && 
                <Grid item className="logoGridSec">
                <img
                    src={logo ? logo : state?.logo}
                    className="logoImageForm"
                    alt="NOT FOUND"
                    width={"50%"}
                />
            </Grid>
            }
           
            {formElements?.length &&
                formElements?.map((item, index) => (
                    <Box
                        className="elementBox"
                        onClick={() => (isRead ? null : handlerEdit(item))}
                    >
                        <Grid container>
                            <Grid item xs={12}>
                                {renderSwitch(
                                    item,
                                    index,
                                    state,
                                    setState,
                                    null,
                                    null,
                                    null,
                                    handlerFieldChange,
                                    isRead,
                                    false
                                )}
                            </Grid>
                        </Grid>
                    </Box>
                ))}
                   
                        <Box className={btnText ? "rightPositionBox" : "centerPositionBox"}>
                      
                        <ButtonComponent
                            variant="contained"
                            title={btnText || "Submit"}
                            onClick={() => isRead ? null : submitSubmission()}
                            disabled={isRead}
                            id="renderElementSubmitButton"
                        />
                    </Box>
                   
                   
    
                    
                
           
        </Box>
    );
};

export default RenderElements;
