import { TextField } from "@mui/material";
import React from "react";
import "./TextField.scss";

function TextFieldComponent(props) {
    const {
        label,
        onChange,
        value,
        required,
        id,
        type,
        InputProps,
        className,
        sx,
        disabled,
        name,
        variant,
        size,
        shrink,
        error,
        placeholder,
        multiline,
        onBlur,
        autoFocus,
        inputProps,
        rows,
        InputLabelProps,
    } = props;


      

    return (
        <TextField
            id={id}
            label={label}
            onChange={onChange}
            className={className || "textFieldComponent"}
            placeholder={placeholder || ""}
            fullWidth
            variant={variant || "filled"}
            value={value}
            autoComplete='off'
            required={required}
            type={type}
            inputProps={inputProps}
            InputProps={InputProps}
            sx={sx}
            disabled={disabled}
            name={name}
            InputLabelProps={InputLabelProps || { shrink: shrink || true }}
            size={size}
            error={error}
            multiline={multiline}
            onBlur={onBlur}
            autoFocus={autoFocus}
            rows={rows}
            
        />
    );
}

export default TextFieldComponent;
