import { API_CONSTANTS } from "../../config/ApiConstants";
import { getApiCall, postApiCall } from "../../utils/Action";
export const GET_EMAIL_LIST = "GET_EMAIL_LIST";
export const GET_EMAIL_LOG_LIST = "GET_EMAIL_LOG_LIST";
export const DELETE_EMAIL = "DELETE_EMAIL";
export const ADD_INSERT_EMAIL_TEMPLATES = "ADD_INSERT_EMAIL_TEMPLATES";
export const GET_EMAIL_DETAILS = "GET_EMAIL_DETAILS";
export const GET_USER_LIST = "GET_USER_LIST";
export const GET_STATUS_LIST = "GET_STATUS_LIST";
export const SEND_EMAILS_TEMPLATES = "SEND_EMAILS_TEMPLATES";
export const UPDATE_EMAIL_TEMPLATE_DETAILS = "UPDATE_EMAIL_TEMPLATE_DETAILS";
export const SET_EMAIL_PER_PAGE = "SET_EMAIL_PER_PAGE";
export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";

export const getEmailTemplatesData = (
    search = '',
    currentPage = null,
    customerPageLimit = null
) => {
    return async (dispatch) => {
        try {
            const response = await getApiCall(
                API_CONSTANTS.GET_EMAIL_TEMPLATES +
                    "?search=" + search +
                    "&page=" +
                    currentPage +
                    "&page_limit=" +
                    customerPageLimit
            );
            return dispatch({
                type: GET_EMAIL_LIST,
                payload: response?.data?.result || [],
            });
        } catch (error) {
            throw error;
        }
    };
};
export const setSelectedDetails = (data) => {
    return async (dispatch) => {
        dispatch({ type: GET_EMAIL_DETAILS, payload: data });
    };
};

export const getEmailTemplateDetail = (id) => {
    return async (dispatch) => {
        try {
            const response = await getApiCall(
                API_CONSTANTS.GET_EMAIL_TEMPLATE_DETAILS + `/${id}`
            );
            return dispatch({
                type: GET_EMAIL_DETAILS,
                payload: response?.data?.result || [],
            });
        } catch (error) {
            throw error;
        }
    };
};
export const getAssignUsersData = (client_id) => {
    return async (dispatch) => {
        try {
            const response = await getApiCall(
                API_CONSTANTS.GET_CLIENT_LIST +
                    `${client_id ? `/${client_id}` : ""}`
            );
            return dispatch({
                type: GET_USER_LIST,
                payload: response?.data?.result || [],
            });
        } catch (error) {
            throw error;
        }
    };
};
export const getEmailStatusData = () => {
    return async (dispatch) => {
        try {
            const response = await getApiCall(
                API_CONSTANTS.GET_EMAIL_STATUS_LIST
            );
            return dispatch({
                type: GET_STATUS_LIST,
                payload: response?.data?.result || [],
            });
        } catch (error) {
            throw error;
        }
    };
};

export const getEmailLogData = (id) => {
    return async (dispatch) => {
        try {
            const response = await getApiCall(
                API_CONSTANTS.LIST_EMAIL_HISTORY + `/${id}`
            );
            return dispatch({
                type: GET_EMAIL_LOG_LIST,
                payload: response?.data?.result || [],
            });
        } catch (error) {
            throw error;
        }
    };
};

export const storeEmailTemplate = (data) => {
    return async (dispatch, getState) => {
        try {
            const currentState = getState();
            const emailTemplateList = currentState.email.emailTemPagination
            const response = await postApiCall(
                API_CONSTANTS.INSERT_EMAIL_TEMPLATES,
                data
            );
            if (response.status === 200) {
                if(emailTemplateList.listViewLimit > emailTemplateList.total){
                    return dispatch({
                        type: ADD_INSERT_EMAIL_TEMPLATES,
                        payload:
                            {
                                ...response?.data?.result,
                                message: response?.data?.message,
                            } || [],
                    });
                }
                else return true

            }
        } catch (error) {
            
        }
    };
};

export const updateEmailTemplateDetail = (id, data) => {
    return async (dispatch) => {
        try {
            const response = await postApiCall(
                API_CONSTANTS.UPDATE_TEMPLATE_DETAILS + `/${id}`,
                data
            );
            if (response?.status === 200) {
                dispatch({
                    type: UPDATE_EMAIL_TEMPLATE_DETAILS,
                    payload: response?.data?.result,
                });
            } else {
            }
        } catch (error) {}
    };
};
export const deleteTemplate = (data) => {
    return async (dispatch, getState) => {
        try {
            const currentState = getState()
            const emailListTemplate = currentState?.email?.emailTemPagination
            const response = await getApiCall(
                API_CONSTANTS.DELETE_EMAIL_TEMPLATE + `/${data}`
            );
            if (response?.status === 200) {
                 dispatch({
                    type: DELETE_EMAIL,
                    payload: data,
                });
                dispatch(getEmailTemplatesData( emailListTemplate?.currentPage, emailListTemplate?.listViewLimit))
            }
        } catch (error) {
            throw error;
        }
    };
};

export const sendEmailTemplate = (id, data) => {
    return async () => {
        try {
            await postApiCall(
                API_CONSTANTS.SEND_EMAIL_TEMPLATES + `/${id}`,
                data
            );
        } catch (error) {}
    };
};

export const setCustomerPageLimit = (data) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: SET_EMAIL_PER_PAGE,
                payload: data,
            });
        } catch (error) {
            throw error;
        }
    };
};

export const setCurrentPage = (pageNumber) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: SET_CURRENT_PAGE,
                payload: pageNumber,
            });
        } catch (error) {
            throw error;
        }
    };
};
