import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import "../../../assets/styles/Container/ContractorEmail.scss";
import DropDownComponent from "../../components/DropDown/DropDownComponent";
import MenuItem from "@mui/material/MenuItem";
import {
    getAssignUsersData,
    getEmailStatusData,
} from "../../store/Action/EmailTemplateAction";
import CheckboxComponents from "../../components/Checkbox/CheckboxComponent";
import TextFieldComponent from "../../components/TextField/TextFieldComponent";
import DialogComponent from "../../components/DialogsBox/DialogComponent";
import ButtonComponent from "../../components/ButtonComponent";
import AutoCompleteDropDown from "../../components/DropDown/AutoCompleteDropDown";

function SaveDocumentDatabase(props) {
    const {
        open,
        client_id,
        getAssignUsersData,
        assignUserData,
        getEmailStatusData,
        emailStatusData,
        state,
        setstate,
        handleSignedDocumentSubmit,
    } = props;
    const [index, setIndex] = useState({ indexStatus: null, hide: false });
    const { hide } = index;
    useEffect(() => {
        getEmailStatusData();
        getAssignUsersData(client_id);
    }, [client_id, getAssignUsersData, getEmailStatusData]);
    const handleChangeStatus = (indexkey) => {
        setstate((pre) => ({ ...pre, status: indexkey }));
        setIndex((pre) => ({ ...pre, indexStatus: indexkey }));
    };
    const handleChangeStatusReason = (indexkey) => {
        setstate((pre) => ({ ...pre, status_reason: indexkey }));
    };
    const handleChangeAssign = (option) => {
        setstate((pre) => ({
            ...pre,
            assign_to: option.id,
            defaultAssign_to: [option],
        }));
    };
    const handleChangeDescription = (value) => {
        setstate((pre) => ({ ...pre, comments: value }));
    };
    const handleChecked = () => {
        setIndex((pre) => ({ ...pre, hide: !hide }));
    };

    const handleClose = () => {
        setstate((prevState) => ({ ...prevState, openSaveModel: false }));
    };

    return (
        <DialogComponent
            open={open}
            handleClose={handleClose}
            className="saveEmailDialog"
            title={"Created:"}
            content={
                <>
                    <Grid container mt={4}>
                        <Grid item md={2} xs={12}>
                            <Typography className="dialogContentHeading">
                                Decision
                            </Typography>
                        </Grid>
                        <Grid item md={10} xs={12}>
                            <DropDownComponent
                                label={"Select Decision"}
                                name={"status"}
                                fullWidth={true}
                                onChange={(event) => {
                                    handleChangeStatus(event.target.value);
                                }}
                                value={state?.status}
                            >
                                {emailStatusData?.length !== 0 &&
                                    emailStatusData?.status?.map(
                                        (option, index) => (
                                            <MenuItem
                                                key={option}
                                                value={index}
                                            >
                                                {option}
                                            </MenuItem>
                                        )
                                    )}
                            </DropDownComponent>
                        </Grid>
                    </Grid>
                    <Grid container mt={4}>
                        <Grid item md={2} xs={12}>
                            <Typography className="dialogContentHeading">
                                Reason
                            </Typography>
                        </Grid>
                        <Grid item md={10} xs={12}>
                            <DropDownComponent
                                label={"Select Reason"}
                                name={"status_reason"}
                                fullWidth={true}
                                onChange={(event) =>
                                    handleChangeStatusReason(event.target.value)
                                }
                                value={state?.status_reason}
                                children={
                                    emailStatusData?.length !== 0 &&
                                    emailStatusData?.status_reasons[
                                        state?.status
                                    ]?.map((option, index) => (
                                        <MenuItem key={index} value={index}>
                                            {option}
                                        </MenuItem>
                                    ))
                                }
                            />
                        </Grid>
                    </Grid>
                    <Grid container mt={4}>
                        <Grid item md={2} xs={12}>
                            <Typography className="dialogContentHeading">
                                Comments
                            </Typography>
                        </Grid>
                        <Grid item md={10} xs={12}>
                            <TextFieldComponent
                                id="saveDocumentDescription"
                                label="Comments"
                                name="description"
                                onChange={(event) =>
                                    handleChangeDescription(
                                        event?.target?.value
                                    )
                                }
                                multiline={true}
                                sx={{ paddingTop: "20px" }}
                                rows={3}
                                fullWidth={true}
                                value={state?.comments}
                            />
                        </Grid>
                    </Grid>
                    <Grid container mt={2}>
                        <Grid item md={2.5} xs={12}>
                            <Typography className="dialogContentHeading">
                                Assign/Reassign
                            </Typography>
                        </Grid>
                        <Grid item md={1} mt={-0.5}>
                            <CheckboxComponents
                                onChange={(event) =>
                                    handleChecked(event?.target?.checked)
                                }
                                value={
                                    state?.defaultAssign_to?.length > 0
                                        ? true
                                        : false
                                }
                                checked={hide}
                            />
                        </Grid>
                    </Grid>
                    <Grid container mt={2}>
                        <Grid item md={10} ml={12.5} xs={12}>
                            <AutoCompleteDropDown
                                options={assignUserData || []}
                                getOptionLabel={(option) =>
                                    option.first_name + " " + option.last_name
                                }
                                onChange={(_, option) =>
                                    handleChangeAssign(option)
                                }
                                defaultValue={state?.defaultAssign_to[0]}
                                disabled={!hide}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        className={"auto"}
                                        placeholder={"Assign/Reassign"}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                </>
            }
            action={
                <Grid mt={2} mr={4}>
                    <ButtonComponent
                        onClick={handleSignedDocumentSubmit}
                        title={"Submit"}
                    />
                </Grid>
            }
        />
    );
}

const mapStateToProps = (state) => {
    return {
        emailStatusData: state?.email?.emailStatusData,
        assignUserData: state?.email?.assignUserData,
        client_id: state?.auth?.userLoggedIn?.user?.client_id,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        { getAssignUsersData, getEmailStatusData },
        dispatch
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SaveDocumentDatabase);
