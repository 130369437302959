import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Box, Typography, Grid } from "@mui/material";
import { Icon } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import TextField from "@mui/material/TextField";
import { temp_text, heading2_text, style, textField_style } from "../Style";
import TooltipComponent from "../../../../components/ToolTip/TooltipComponent";
import { Translate } from "react-auto-translate";

function ContractorInformation(props) {
    const { auth, customer, user, customerinfo, userLoggedIn, commonDropdown } =
        props;
    const {
        first_name,
        last_name,
        // middle_name,
        // display_name,
        gender,
        dob,
        company_info,
        details,
        email,
        address,
        phone,
        work_phone,
        fax,
    } = user?.selectedUser;
    const [state, setState] = useState({
        contarctorSate: "",
        collarColor: null,
    });
    useEffect(() => {
        getState();
        getCollar();
    }, [first_name, auth?.commonDropdown]);

    function getState() {
        if (auth?.commonDropdown?.length !== 0) {
            const addressState = auth?.commonDropdown?.states.filter(
                (item) =>
                    item?.id === Number(user?.selectedUser?.address?.state)
            );
            setState((prevState) => ({
                ...prevState,
                contarctorSate: addressState[0].label,
            }));
        }
    }
    const getCollar = () => {
        if (commonDropdown.collars) {
            const filterCollarColar = commonDropdown.collars.find(
                (obj) => obj.id === details.collar_id
            );
            setState((prevstate) => ({
                ...prevstate,
                collarColor: filterCollarColar.label,
            }));
        }
    };
    return (
        <>
            <Grid container mb={2}>
                <Grid item md={12} sm={2} xs={12}>
                    <Grid container direction={"row"} alignItems={"center"}>
                        <Grid items md={1}>
                            <Typography style={heading2_text}>
                                r<Translate>Role : </Translate>
                            </Typography>
                        </Grid>
                        <Grid md={1}>
                            <Typography
                                style={temp_text}
                                sx={{
                                    pl: "0",
                                    display: "flex",
                                    justifyContent: "start",
                                    textAlign: "center",
                                }}
                            >
                                {" "}
                                CBE
                            </Typography>
                        </Grid>

                        <Grid items md={1}>
                            <TooltipComponent
                                arrow={true}
                                title="CBE"
                                placement="right"
                                sx={{
                                    left: 10,
                                    top: 5,
                                }}
                            >
                                <Icon
                                    className="infoIcon"
                                    sx={{ alignItems: "center" }}
                                >
                                    <InfoIcon sx={{ alignItems: "center" }} />
                                </Icon>
                            </TooltipComponent>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Typography style={heading2_text} sx={{ mb: 1 }}>
                <Translate>{"Customer Profile"}</Translate>
            </Typography>
            <Grid container spacing={2}>
                <Grid item md={6} sm={6} xs={12}>
                    <Box sx={{ ...style }}>
                        <TextField
                            required
                            variant="standard"
                            id="first-name-contractor-required"
                            label={
                                userLoggedIn?.language_data?.customer
                                    ?.first_name || "First Name"
                            }
                            sx={{
                                "& .MuiFormLabel-root": {
                                    fontSize: "18px",
                                },
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                disableUnderline: true,
                                readOnly: true,
                                style: {
                                    ...textField_style,
                                },
                            }}
                            value={first_name}
                        />
                    </Box>
                </Grid>
                {/* <Grid item md={6} sm={6} xs={12}>
                    <Box sx={{ ...style }}>
                        <TextField
                            required
                            variant="standard"
                            id="middle-name-contractor-required"
                            label={
                                userLoggedIn?.language_data?.customer
                                    ?.middle_name || "Middle Name"
                            }
                            sx={{
                                "& .MuiFormLabel-root": {
                                    fontSize: "18px",
                                },
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                disableUnderline: true,
                                readOnly: true,
                                style: {
                                    ...textField_style,
                                },
                            }}
                            value={middle_name === null ? "-" : middle_name}
                        />
                    </Box>
                </Grid> */}
                <Grid item md={6} sm={6} xs={12}>
                    <Box sx={{ ...style }}>
                        <TextField
                            required
                            variant="standard"
                            id="last-name-contractor-required"
                            label={
                                userLoggedIn?.language_data?.customer
                                    ?.last_name || "Last Name"
                            }
                            sx={{
                                "& .MuiFormLabel-root": {
                                    fontSize: "18px",
                                },
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                disableUnderline: true,
                                readOnly: true,
                                style: {
                                    ...textField_style,
                                },
                            }}
                            value={last_name}
                        />
                    </Box>
                </Grid>
                {/* <Grid item md={6} sm={6} xs={12}>
                    <Box sx={{ ...style }}>
                        <TextField
                            required
                            variant="standard"
                            id="display-name-contractor-required"
                            label={
                                userLoggedIn?.language_data?.customer
                                    ?.display_name || "Display Name"
                            }
                            sx={{
                                "& .MuiFormLabel-root": {
                                    fontSize: "18px",
                                },
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                disableUnderline: true,
                                readOnly: true,
                                style: {
                                    ...textField_style,
                                },
                            }}
                            value={display_name}
                        />
                    </Box>
                </Grid> */}
                {/* <Grid item md={4} sm={6} xs={12}>
                    <Box sx={{ ...style }}>
                        <TextField
                            required
                            variant="standard"
                            id="gender-contractor-required"
                            label="Gender"
                            sx={{
                                "& .MuiFormLabel-root": {
                                    fontSize: "18px",
                                },
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                disableUnderline: true,
                                readOnly: true,
                                style: {
                                    ...textField_style,
                                },
                            }}
                            value={
                                gender === "m"
                                    ? "Male"
                                    : gender === "f"
                                    ? "Female"
                                    : ""
                            }
                        />
                    </Box>
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                    <Box sx={{ ...style }}>
                        <TextField
                            required
                            variant="standard"
                            id="dob-contractorrequired"
                            label="Date of Birth"
                            sx={{
                                "& .MuiFormLabel-root": {
                                    fontSize: "18px",
                                },
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                disableUnderline: true,
                                readOnly: true,
                                style: {
                                    ...textField_style,
                                },
                            }}
                            value={dob}
                        />
                    </Box>
                </Grid> */}
            </Grid>

            <Typography style={heading2_text} sx={{ mt: 2, mb: 1 }}>
                {userLoggedIn?.language_data?.user?.office_information ||
                    "Office Information"}
            </Typography>
            <Grid container spacing={2}>
                <Grid item md={6} sm={6}>
                    <Box sx={{ ...style }}>
                        <TextField
                            required
                            variant="standard"
                            id="company-name-required"
                            label={
                                userLoggedIn?.language_data?.customer
                                    ?.company_name || "Company Name"
                            }
                            sx={{
                                "& .MuiFormLabel-root": {
                                    fontSize: "18px",
                                },
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                disableUnderline: true,
                                readOnly: true,
                                style: {
                                    ...textField_style,
                                },
                            }}
                            value={
                                company_info.name === null
                                    ? "-"
                                    : company_info.name
                            }
                        />
                    </Box>
                </Grid>
                <Grid item md={6} sm={6}>
                    <Box sx={{ ...style }}>
                        <TextField
                            required
                            variant="standard"
                            id="comapany-website-required"
                            label={
                                userLoggedIn?.language_data?.customer
                                    ?.company_website || "Company Website"
                            }
                            sx={{
                                "& .MuiFormLabel-root": {
                                    fontSize: "18px",
                                },
                                "& .MuiInput-input": {
                                    width: "50ch",
                                },
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                disableUnderline: true,
                                readOnly: true,
                                style: {
                                    ...textField_style,
                                },
                            }}
                            value={
                                company_info.website === null
                                    ? "-"
                                    : company_info.website
                            }
                        />
                    </Box>
                </Grid>
            </Grid>
            <Typography style={heading2_text} sx={{ mt: 2, mb: 1 }}>
                {userLoggedIn?.language_data?.customer?.contact_information ||
                    "Contact Information"}
            </Typography>
            <Grid container spacing={2}>
                <Grid item md={4} sm={6}>
                    <Box sx={{ ...style }}>
                        <TextField
                            required
                            variant="standard"
                            id="email-contact-required"
                            label={
                                userLoggedIn?.language_data?.customer
                                    ?.email_address || "Email Address"
                            }
                            sx={{
                                "& .MuiFormLabel-root": {
                                    fontSize: "16px",
                                },
                                "& .MuiInput-input": {
                                    width: "33ch",
                                },
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                disableUnderline: true,
                                readOnly: true,
                                style: {
                                    ...textField_style,
                                },
                            }}
                            value={email}
                        />
                    </Box>
                </Grid>
                <Grid item md={4} sm={6}>
                    <Box sx={{ ...style }}>
                        <TextField
                            required
                            variant="standard"
                            id="mobile-contact-required"
                            label={
                                userLoggedIn?.language_data?.customer
                                    ?.mobile_number || "Mobile Number"
                            }
                            sx={{
                                "& .MuiFormLabel-root": {
                                    fontSize: "18px",
                                },
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                disableUnderline: true,
                                readOnly: true,
                                style: {
                                    ...textField_style,
                                },
                            }}
                            value={phone === null ? "-" : phone}
                        />
                    </Box>
                </Grid>
                <Grid item md={4} sm={6}>
                    <Box sx={{ ...style }}>
                        <TextField
                            required
                            variant="standard"
                            id="work-contact-required"
                            label={
                                userLoggedIn?.language_data?.customer
                                    ?.work_phone || "Work Phone"
                            }
                            sx={{
                                "& .MuiFormLabel-root": {
                                    fontSize: "18px",
                                },
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                disableUnderline: true,
                                readOnly: true,
                                style: {
                                    ...textField_style,
                                },
                            }}
                            value={work_phone === null ? "-" : work_phone}
                        />
                    </Box>
                </Grid>

                <Grid item md={4} sm={6}>
                    <Box sx={{ ...style }}>
                        <TextField
                            required
                            variant="standard"
                            id="fax-contact-required"
                            label="FAX"
                            sx={{
                                "& .MuiFormLabel-root": {
                                    fontSize: "18px",
                                },
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                disableUnderline: true,
                                readOnly: true,
                                style: {
                                    ...textField_style,
                                },
                            }}
                            value={fax === null ? "-" : fax}
                        />
                    </Box>
                </Grid>
                <Grid item md={4} sm={6}>
                    <Box sx={{ ...style }}>
                        <TextField
                            required
                            variant="standard"
                            id="address1-contact-required"
                            label={
                                `${userLoggedIn?.language_data?.customer?.address_line} 1` ||
                                "Address Line 1"
                            }
                            sx={{
                                "& .MuiFormLabel-root": {
                                    fontSize: "18px",
                                },
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                disableUnderline: true,
                                readOnly: true,
                                style: {
                                    ...textField_style,
                                },
                            }}
                            value={address.line1 === null ? "-" : address.line1}
                        />
                    </Box>
                </Grid>
                <Grid item md={4} sm={6}>
                    <Box sx={{ ...style }}>
                        <TextField
                            required
                            variant="standard"
                            id="address2-contact-required"
                            label={
                                `${userLoggedIn?.language_data?.customer?.address_line} 2` ||
                                "Address Line 2"
                            }
                            sx={{
                                "& .MuiFormLabel-root": {
                                    fontSize: "18px",
                                },
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                disableUnderline: true,
                                readOnly: true,
                                style: {
                                    ...textField_style,
                                },
                            }}
                            value={address.line2 === null ? "-" : address.line2}
                        />
                    </Box>
                </Grid>
                <Grid item md={4} sm={6}>
                    <Box sx={{ ...style }}>
                        <TextField
                            required
                            variant="standard"
                            id="city-contact-required"
                            label={
                                userLoggedIn?.language_data?.customer?.city ||
                                "City"
                            }
                            sx={{
                                "& .MuiFormLabel-root": {
                                    fontSize: "18px",
                                },
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                disableUnderline: true,
                                readOnly: true,
                                style: {
                                    ...textField_style,
                                },
                            }}
                            value={address.city === null ? "-" : address.city}
                        />
                    </Box>
                </Grid>
                <Grid item md={4} sm={6}>
                    <Box sx={{ ...style }}>
                        <TextField
                            required
                            variant="standard"
                            id="state-contact-required"
                            label={
                                userLoggedIn?.language_data?.customer?.state ||
                                "State"
                            }
                            sx={{
                                "& .MuiFormLabel-root": {
                                    fontSize: "18px",
                                },
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                disableUnderline: true,
                                readOnly: true,
                                style: {
                                    ...textField_style,
                                },
                            }}
                            value={
                                state?.contarctorSate
                                    ? state?.contarctorSate
                                    : "-"
                            }
                        />
                    </Box>
                </Grid>
                <Grid item md={4} sm={6}>
                    <Box sx={{ ...style }}>
                        <TextField
                            required
                            variant="standard"
                            id="zip-contact-required"
                            label={
                                userLoggedIn?.language_data?.customer
                                    ?.zi_code || "Zip Code"
                            }
                            sx={{
                                "& .MuiFormLabel-root": {
                                    fontSize: "18px",
                                },
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                disableUnderline: true,
                                readOnly: true,
                                style: {
                                    ...textField_style,
                                },
                            }}
                            value={address.zip === null ? "-" : address.zip}
                        />
                    </Box>
                </Grid>
            </Grid>

            <Typography style={heading2_text} sx={{ mt: 2, mb: 1 }}>
                {userLoggedIn?.language_data?.user?.office_information ||
                    "Office Information"}
            </Typography>
            <Grid container spacing={2}>
                <Grid item md={4} sm={6}>
                    <Box sx={{ ...style }}>
                        <TextField
                            required
                            variant="standard"
                            id="collar-office-required"
                            label={
                                userLoggedIn?.language_data?.complianceworkflow
                                    ?.collar_color || "Collar Color"
                            }
                            sx={{
                                "& .MuiFormLabel-root": {
                                    fontSize: "18px",
                                },
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                disableUnderline: true,
                                readOnly: true,
                                style: {
                                    ...textField_style,
                                },
                            }}
                            value={state.collarColor}
                        />
                    </Box>
                </Grid>
                <Grid item md={4} sm={6}>
                    <Box sx={{ ...style }}>
                        <TextField
                            required
                            variant="standard"
                            id="emptype-office-required"
                            label="Classification Type"
                            sx={{
                                "& .MuiFormLabel-root": {
                                    fontSize: "18px",
                                },
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                disableUnderline: true,
                                readOnly: true,
                                style: {
                                    ...textField_style,
                                },
                            }}
                            value={details.employment_type}
                        />
                    </Box>
                </Grid>
                <Grid item md={4} sm={6}>
                    <Box sx={{ ...style }}>
                        <TextField
                            required
                            variant="standard"
                            id="jobloc-office-required"
                            label="Job Location"
                            sx={{
                                "& .MuiFormLabel-root": {
                                    fontSize: "18px",
                                },
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                disableUnderline: true,
                                readOnly: true,
                                style: {
                                    ...textField_style,
                                },
                            }}
                            value={details.job_location}
                        />
                    </Box>
                </Grid>
            </Grid>
            <Typography style={heading2_text} sx={{ mt: 2, mb: 1 }}>
                Authorized signing party for the customer
            </Typography>
            <Grid container spacing={2}>
                <Grid item md={4} sm={6} xs={12}>
                    <Box sx={{ ...style }}>
                        <TextField
                            required
                            variant="standard"
                            id="first-contractor-required"
                            label={
                                userLoggedIn?.language_data?.customer
                                    ?.first_name || "First Name"
                            }
                            sx={{
                                "& .MuiFormLabel-root": {
                                    fontSize: "18px",
                                },
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                disableUnderline: true,
                                readOnly: true,
                                style: {
                                    ...textField_style,
                                },
                            }}
                            value={first_name}
                        />
                    </Box>
                </Grid>
                {/* <Grid item md={4} sm={6} xs={12}>
                    <Box sx={{ ...style }}>
                        <TextField
                            required
                            variant="standard"
                            id="middle-contractor-required"
                            label={
                                userLoggedIn?.language_data?.customer
                                    ?.middle_name || "Middle Name"
                            }
                            sx={{
                                "& .MuiFormLabel-root": {
                                    fontSize: "18px",
                                },
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                disableUnderline: true,
                                readOnly: true,
                                style: {
                                    ...textField_style,
                                },
                            }}
                            value={middle_name === null ? "-" : middle_name}
                        />
                    </Box>
                </Grid> */}
                <Grid item md={4} sm={6} xs={12}>
                    <Box sx={{ ...style }}>
                        <TextField
                            required
                            variant="standard"
                            id="last-contractor-required"
                            label={
                                userLoggedIn?.language_data?.customer
                                    ?.last_name || "Last Name"
                            }
                            sx={{
                                "& .MuiFormLabel-root": {
                                    fontSize: "18px",
                                },
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                disableUnderline: true,
                                readOnly: true,
                                style: {
                                    ...textField_style,
                                },
                            }}
                            value={last_name}
                        />
                    </Box>
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                    <Box sx={{ ...style }}>
                        <TextField
                            required
                            variant="standard"
                            id="email-contractor-required"
                            label={
                                userLoggedIn?.language_data?.customer
                                    ?.email_address || "Email Address"
                            }
                            sx={{
                                "& .MuiFormLabel-root": {
                                    fontSize: "18px",
                                },
                                "& .MuiInput-input": {
                                    width: "33ch",
                                },
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                disableUnderline: true,
                                readOnly: true,
                                style: {
                                    ...textField_style,
                                },
                            }}
                            value={email}
                        />
                    </Box>
                </Grid>
                {/* <Grid item md={4} sm={6} xs={12}></Grid> */}
                <Grid item md={4} sm={6} xs={12}>
                    <Box sx={{ ...style }}>
                        <TextField
                            required
                            variant="standard"
                            id="com-name-required"
                            label={
                                userLoggedIn?.language_data?.customer
                                    ?.company_name || "Company Name"
                            }
                            sx={{
                                "& .MuiFormLabel-root": {
                                    fontSize: "18px",
                                },
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                disableUnderline: true,
                                readOnly: true,
                                style: {
                                    ...textField_style,
                                },
                            }}
                            value={
                                company_info.name === null
                                    ? "-"
                                    : company_info.name
                            }
                        />
                    </Box>
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                    <Box sx={{ ...style }}>
                        <TextField
                            required
                            variant="standard"
                            id="com-web-required"
                            label={
                                userLoggedIn?.language_data?.customer
                                    ?.company_website || "Company Website"
                            }
                            sx={{
                                "& .MuiFormLabel-root": {
                                    fontSize: "18px",
                                },
                                "& .MuiInput-input": {
                                    width: "33ch",
                                },
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                disableUnderline: true,
                                readOnly: true,
                                style: {
                                    ...textField_style,
                                },
                            }}
                            value={
                                company_info.website === null
                                    ? "-"
                                    : company_info.website
                            }
                        />
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,
        auth: state.auth,
        customer: state.customer,
        user: state.user,
        commonDropdown: state.auth.commonDropdown,
    };
};

export default connect(mapStateToProps, null)(ContractorInformation);
