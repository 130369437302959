import * as Actions from "../Action/CustomerAction";

const initialState = {
    customerList: null,
    selectedCustomer: null,
    viewCustomerType: "table",
    hierarchy: null,
    userList: null,
    customerPagination: {
        listViewLimit: 8,
        total: "",
        currentPage: 1,
        lastPage: "",
    },
};

const CustomerReducer = (state = initialState, action) => {
    switch (action?.type) {
        case Actions.GET_CUSTOMER_LIST: {
            return {
                ...state,
                customerList: action.payload?.data,
                customerPagination: {
                    ...state.customerPagination,
                    total: action?.payload?.total,
                    lastPage: action?.payload?.last_page,
                    currentPage: action?.payload?.current_page,
                },
            };
        }
        case Actions.TOGGLE_DELETE_POPUP: {
            return {
                ...state,
                deletePopUp: action.payload,
            };
        }
        case Actions.CREATE_CLIENT: {
            const newList = state.customerList;
            return {
                ...state,
                customerList: newList?.length
                    ? [...newList, action.payload]
                    : [action.payload],
            };
        }
        case Actions.SET_SELECTED_CUSTOMER: {
            return {
                ...state,
                selectedCustomer: action.payload,
            };
        }
        case Actions.UPDATE_CUSTOMER_BY_ID: {
            let list = state?.customerList;
            let index = list?.findIndex((obj) => obj.id === action.payload.id);
            if (index !== -1)
                list[index] = {
                    ...list[index],
                    client_type: action.payload?.client_type,
                    first_name: action.payload?.first_name,
                    display_name: action.payload?.display_name,
                    company_name: action.payload?.comapny_info?.company_name,
                    company_website:
                        action.payload?.comapny_info?.company_website,
                    email: action.payload?.email,
                    phone: action.payload?.phone,
                    logo: action.payload?.logo || list[index].logo,
                    favicon: action.payload?.favicon || list[index].favicon,
                };
            return {
                ...state,
                customerList: list,
            };
        }
        case Actions.CHANGE_CUSTOMER_VIEW_TYPE: {
            return {
                ...state,
                viewCustomerType: action.payload,
            };
        }
        case Actions.UPDATE_NO_OF_USER: {
            let list = state?.customerList;
            let index = list?.findIndex((obj) => obj.id === action.payload);
            if (index && index !== -1) {
                list[index].users_count = list[index]?.users_count
                    ? list[index]?.users_count + 1
                    : 1;
            }
            return {
                ...state,
                customerList: list,
            };
        }
        case Actions.GET_CUSTOMER_HIERARCHY: {
            return {
                ...state,
                hierarchy: action.payload,
            };
        }
        case Actions.GET_USERS_BY_CUSTOMER_ID: {
            return {
                ...state,
                userList: action.payload,
            };
        }
        case Actions.SET_CUSTOMER_PER_PAGE: {
            return {
                ...state,
                customerPagination: {
                    ...state.customerPagination,
                    listViewLimit: action?.payload,
                },
            };
        }
        case Actions.SET_CURRENT_PAGE: {
            return {
                ...state,
                customerPagination: {
                    ...state.customerPagination,
                    currentPage: action.payload?.data,
                },
            };
        }
        case Actions.CHANGE_STATUS_BY_ID: {
            let list = Array.from(state?.customerList);
            let index = list.findIndex((obj) => obj.id === action.payload.id);
            if (index !== -1) list[index] = action.payload;
            return { ...state, customerList: list };
        }

        default: {
            return state;
        }
    }
};

export default CustomerReducer;
