import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material";
import React from "react";
import { ChromePicker } from "react-color";
import ButtonComponent from "./ButtonComponent";
import { connect } from "react-redux";
const ColorPicker = (props) => {
    const {
        open,
        handleClose,
        defaultColor,
        type,
        handleColorChange,
        updateFormColor,
        userLoggedIn
    } = props;
    return (
        <Dialog open={open}>
            <DialogTitle>{userLoggedIn?.language_data?.formstudio?.color_picker || "Color Picker"}</DialogTitle>
            <DialogContent sx={{ padding: "0px" }}>
                <ChromePicker
                    color={defaultColor}
                    onChange={(event) => handleColorChange(event, type)}
                    disableAlpha={true}
                    width={"100%"}
                />
            </DialogContent>
            <DialogActions>
                <ButtonComponent title={userLoggedIn?.language_data?.comman?.cancel_btn ||"Cancel"} onClick={handleClose} />
                <ButtonComponent title={userLoggedIn?.language_data?.comman?.edit_btn || "Update"} onClick={updateFormColor} />
            </DialogActions>
        </Dialog>
    );
};

const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,
    };
};


export default connect(mapStateToProps, null) (ColorPicker);
