import React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import TextField from "@mui/material/TextField";

const TimePickerComponent = (props) => {
    const {
        onChange,
        name,
        value,
        checked,
        label,
        variant,
        className,
        disabled,
        required,
        id,
    } = props;

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
                id={id}
                onChange={onChange}
                name={name}
                value={value}
                checked={checked}
                label={label}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant={variant || "filled"}
                        InputLabelProps={{ shrink: true }}
                        required={required}
                        id="TimePickerComponents"
                    />
                )}
                className={className || "datePickerComponent"}
                disabled={disabled}
            />
        </LocalizationProvider>
    );
};

export default TimePickerComponent;
