import React from "react";
import Navbar from "../../common/Header/Navbar";
import { Box, Grid, Paper, Typography } from "@mui/material";
import "../../../assets/styles/Container/Home.scss";
import ButtonComponent from "../../components/ButtonComponent";
import CorouselMain from "../../components/Carousel/CorouselMain";
import GetDemoContactSection from "../../common/GetDemoContactSection";
import imagenumber1 from "../../../assets/Images/Common/#01.png";
import imagenumber2 from "../../../assets/Images/Common/#02.png";
import imagenumber3 from "../../../assets/Images/Common/#03.png";
import imagenumber4 from "../../../assets/Images/Common/#04.png";
import UnloginFooter from "../../common/UnloginFooter";
import { homePageMeetingImages } from "../../utils/CommonData";

function AboutPage(props) {
    const principles = [
        {
            title: "Easy & Intuitive",
            description:
                "The compliance process should be easy and intuitive for everyday users to navigate.",
            indexImage: imagenumber1,
        },
        {
            title: "Understandable Language",
            description:
                "The complexity of law that drives compliance should be converted into language that the common user can understand.",
            indexImage: imagenumber2,
        },
        {
            title: "Efficient & Accurate",
            description:
                "Customers must be put at ease about the efficiency and accuracy of the compliance testing process.",
            indexImage: imagenumber3,
        },
        {
            title: "Customizable Process",
            description:
                "Customers must be given the option to customize the compliance process to suit the unique attributes of their workforce while remaining true to the first 3 principals.",
            indexImage: imagenumber4,
        },
    ];
    return (
        <>
            <Navbar />
            <Grid
                container
                justifyContent={"space-between"}
                alignItems={"center"}
                sx={{ px: { md: 7, xs: 1 }, pt: "110px", mb: "40px" }}
            >
                <Grid
                    item
                    xs={12}
                    md={6}
                    lg={6}
                    xl={6}
                    sx={{ pr: { md: "50px", xs: 0 } }}
                >
                    <Typography className="mainHeading">
                        Get a close look to Elevance & Who we are
                    </Typography>
                    <Typography className="mainSubHeading">
                        {`Elevance is the brainchild of a group of technologists, lawyers, and compliance professionals with decades of experience.`}
                    </Typography>
                    <Grid
                        item
                        gap={3}
                        display={"flex"}
                        sx={{ paddingTop: "25px" }}
                    >
                        <Grid item>
                            <ButtonComponent
                                title={"Contact Us"}
                                className="button_outline"
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={6}
                    lg={6}
                    xl={6}
                    justifyContent={"center"}
                    sx={{ padding: { xs: "20px", md: "0px" } }}
                >
                    <Paper className="homeCorouselPaper">
                        <CorouselMain
                            images={homePageMeetingImages}
                            showThumbs={false}
                            showArrows={false}
                            showStatus={false}
                            autoPlay={true}
                        />
                    </Paper>
                </Grid>
            </Grid>
            <Box
                className="lightSecondaryDetails"
                sx={{ px: { md: 7, xs: 1 }, py: "30px" }}
            >
                <Grid container display={"flex"}>
                    <Grid
                        item
                        xs={12}
                        display={"flex"}
                        justifyContent={"center"}
                    >
                        <Typography className="mainHeading" pb={"20px"}>
                            About Elevance
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography
                            className="principleDescr"
                            sx={{ textAlign: "justify" }}
                        >
                            {` The founders believe the idea for Elevance was borne out of necessity: in an ever-increasing complex business and legal environment, properly executing the compliance function has become more cost prohibitive, confusing, and uncertain than ever before.  From ambiguous regulations to conflicting case law decisions to political uncertainty, it is easy for compliance to become a major headache.  Far too often, companies get compliance wrong, or, even worse, ignore it at great risk to themselves and others.  Hence the need for Elevance, a B2B SaaS solution which interprets and stays abreast of current law and integrates all applicable compliance requirements (even as they change or are updated over time), all on a user-friendly technology platform. `}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        display={"flex"}
                        justifyContent={"center"}
                        py={"20px"}
                    >
                        <Typography className="mainHeading">
                            Our Principals
                        </Typography>
                    </Grid>

                    <Grid
                        item
                        paddingY={"20px"}
                        display={"flex"}
                        gap={2}
                        sx={{ flexDirection: { xs: "column", md: "row" } }}
                    >
                        {principles.map((obj) => {
                            return (
                                <Grid
                                    item
                                    xs={12}
                                    md={3}
                                    lg={3}
                                    xl={3}
                                    display={"flex"}
                                    alignItems={"center"}
                                    flexDirection={"column"}
                                >
                                    <img src={obj.indexImage} />
                                    <Typography
                                        className="lightSecHightlightText"
                                        pt={"20px"}
                                        pb={"15px"}
                                    >
                                        {obj.title}
                                    </Typography>
                                    <Typography
                                        className="principleDescr"
                                        sx={{ textAlign: "center" }}
                                    >
                                        {obj.description}
                                    </Typography>
                                </Grid>
                            );
                        })}
                    </Grid>
                    <Typography
                        className="principleDescr"
                        sx={{ textAlign: "justify" }}
                    >
                        {`These 4 principals boil down to 1 inevitable fact: all of the hard, detailed work must be done behind the scenes, by compliance and legal professionals who turn nuanced understandings of compliance and the law into easy-to-follow workflows and easy-to-understand content.`}
                    </Typography>
                </Grid>
            </Box>
            <GetDemoContactSection />
            <UnloginFooter />
        </>
    );
}

export default AboutPage;
