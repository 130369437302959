import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AccordionCustom from "../../../../../components/AccordionCustom";
import Footer from "../../../../../common/Footer/Footer";
import PartiesTable from "./PartiesTable";
import TestingTable from "./TestingTable";
// import SignedDocumentTable from "./SignedDocumentTable";
// import CompoundedScoringTable from "./CompoundedScoringTable";
import ButtonComponent from "../../../../../components/ButtonComponent";
import TestingData from "./TestingData";
import Navbar from "../../../../../common/Header/Navbar";
import PageHeader from "../../../../../common/PageHeader";
import "../../../../../../assets/styles/Container/AuditDefenseReport.scss";
import ExpandAllButton from "../../../../../components/ExpandAllButton";
import ContextProvider from "../../../../../components/Context/ContextProvider";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getAuditDefenseReport } from "../../../../../store/Action/AuditDefenseReportAction";
import { getCustomerById } from "../../../../../store/Action/CustomerAction";
import AssessmentSummary from "../../AccordionList/AssessmentSummary";
import ShowAssesmentUserData from "../../ShowAssesmentUserData";
import MainLogo from "../../../../../../assets/Images/Logo/Logo.png";
import { Translate } from "react-auto-translate";
const AuditDefenseReport = (props) => {
    const [state, setState] = useState({
        expand: true,
        testOverviewData: null,
    });
    const { expand } = state;
    const {
        userLoggedIn,
        getAuditDefenseReport,
        guid,
        auditData,
        getCustomerById,
        selectedUser,
        user_id,
        user,
        customer_id,
        whiteLabel,
    } = props;
    console.log('localStorage.getItem("mode")', localStorage.getItem("mode"));
    useEffect(() => {
        callApi();
    }, [selectedUser, userLoggedIn]);
    const callApi = async () => {
        const res = await getAuditDefenseReport(
            localStorage.getItem("guid"),
            localStorage.getItem("contractor_id")
                ? localStorage.getItem("contractor_id")
                : localStorage.getItem("user_id"),
            localStorage.getItem("mode")
        );
        const copyData = res.payload.result.test_overview;
        const filterData =
            res.payload.result.assessment_summary.state === "California"
                ? copyData
                : copyData.filter((obj) => obj.id !== 5);
        setState((prevState) => ({
            ...prevState,
            testOverviewData: filterData,
        }));
    };
    useEffect(() => {
        getCustomerById(
            userLoggedIn?.user?.id !== undefined && userLoggedIn?.user?.id
        );
    }, [userLoggedIn]);

    const handlePrintClick = () => {
        setState({ ...state, expand: true });
        setTimeout(() => {
            window.print();
        }, 1000);
    };

    useEffect(() => {
        // Set the default page size to landscape when printing
        const style = document.createElement("style");
        style.innerHTML = `@media print { @page { size: A4 landscape; } }`;
        document.head.appendChild(style);

        return () => {
            // Clean up the style tag when the component unmounts
            document.head.removeChild(style);
        };
    }, []);

    return (
        <>
            <Navbar />
            <Box sx={{ display: "flex" }} className="navbarLogo printMediaLogo">
                {/* {whiteLabel?.use_company_name ? (
                <Typography className="companyNameHeading">
                    <Translate>{whiteLabel?.company_name || "Elevance"}</Translate>
                </Typography>
            ) : (

            )} */}
                <img
                    src={whiteLabel?.logo || MainLogo}
                    alt="mainLogo"
                    // style={{maxWidth: '28px !important'}}
                />
            </Box>
            <Box sx={{ px: { md: 7, xs: 1 } }}>
                <PageHeader
                    title={<Translate>Audit Defense Report</Translate>}
                />
                <Grid container spacing={2} mb={1}>
                    <ShowAssesmentUserData
                        assessmentSummaryDetails={
                            auditData?.data?.result?.assessment_summary
                        }
                        hideExtra={true}
                    />
                </Grid>
                <ExpandAllButton expand={state.expand} setState={setState} />
                {/* <Grid container pt={2} gap={2} pb={8} display={'flex'}>
            <Grid
                    item
                    xs={12}
                    gap={2}
                >
                   <div><Translate>Test Start Time</Translate></div>
                </Grid>
                <Grid
                    item
                    xs={12}

                    gap={2}
                >
                   <div><Translate>Test Start Time</Translate></div>
                </Grid>
                </Grid> */}
                <Grid container pt={2} gap={2} pb={8}>
                    <ContextProvider.Provider value={state.expand}>
                        <AccordionCustom title={"Part 1 - Parties"}>
                            <PartiesTable
                                data={auditData?.data?.result?.parties}
                            />
                        </AccordionCustom>
                        <AccordionCustom title={"Part 2 - Applicable Law"}>
                            {auditData?.data?.result?.laws
                                ?.sort()
                                .map((option, index) => (
                                    <Typography
                                        className="lawOption"
                                        key={index}
                                    >
                                         <br/>
                                        <Translate>{option}</Translate>
                                       
                                    </Typography>
                                ))}
                        </AccordionCustom>
                        <AccordionCustom title={"Part 3 - Testing Overview"}>
                            <TestingTable data={state.testOverviewData} />
                        </AccordionCustom>
                       
                            <AccordionCustom title={"Part 4 - Testing Data"}>
                                {auditData?.data?.result?.testing_data?.map(
                                    (data, index) => (
                                        <AccordionCustom
                                            key={index}
                                            title={
                                                `${index + 1}.` +
                                                " " +
                                                `${data.question}`
                                            }
                                            className="accordionBgWhite"
                                            detailsClassName="accordionDetailsWhite"
                                        >
                                            <TestingData data={data} />
                                        </AccordionCustom>
                                    )
                                )}
                            </AccordionCustom>
                       
                        {/* <AccordionCustom
                        title={<Translate>Part 5 - Document + Signed Document Data</Translate>}
                    >
                        <SignedDocumentTable />
                    </AccordionCustom>
                    <AccordionCustom
                        title={<Translate>Part 6 - Compounded Questions + Scoring</Translate>}
                    >
                        <CompoundedScoringTable />
                    </AccordionCustom> */}
                    </ContextProvider.Provider>
                    <Grid
                        item
                        xs={12}
                        display={"flex"}
                        justifyContent={"flex-end"}
                        gap={2}
                    >
                        {/* <ButtonComponent
                        title={userLoggedIn?.language_data?.formstudio?.preview || "Preview"}
                        variant={"outlined"}
                    /> */}
                        <ButtonComponent
                            onClick={handlePrintClick}
                            title={<Translate>Print</Translate>}
                        />
                    </Grid>
                </Grid>
            </Box>

            <Footer />
        </>
    );
};
const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,
        guid: state?.whiteLabel?.guid,
        auditData: state.auditData,
        selectedUser: state?.customer?.selectedCustomer,
        customer_id: state?.customer?.selectedCustomer?.id,
        user: state?.user,
        whiteLabel: state?.whiteLabel,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getAuditDefenseReport,
            getCustomerById,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(AuditDefenseReport);
