import * as Actions from "../Action/UserAction";

const initialState = {
    userProfile: [],
    usersList: null,
    assesmentUserList: null,
    selectedUser: null,
    viewType: "table",
    userPagination: {
        listViewLimit: 8,
        total: 0,
        currentPage: 1,
        lastPage: "",
    },
};

const UserReducer = (state = initialState, action) => {
    switch (action?.type) {
        case Actions.CREATE_USER: {
            return {
                ...state,
                usersList: state.usersList?.length
                    ? [...state.usersList, action.payload]
                    : [action.payload],
            };
        }
        case Actions.GET_USER_LIST: {
            return {
                ...state,
                usersList: action.payload?.data,
                userPagination: {
                    ...state.userPagination,
                    total: action?.payload?.total,
                    lastPage: action?.payload?.last_page,
                    currentPage: action?.payload?.current_page,
                },
            };
        }
        case Actions.GET_USER_BY_ROLE: {
            return {
                ...state,
                usersList: action.payload?.data,
                userPagination: {
                    ...state.userPagination,
                    total: action?.payload?.total,
                    lastPage: action?.payload?.last_page,
                    currentPage: action?.payload?.current_page,
                },
            };
        }
        case Actions.TOGGLE_DELETE_POPUP: {
            return { ...state, deletePopUp: action.payload };
        }
        case Actions.DELETE_USER_BY_ID: {
            let userData = state.usersList?.filter(
                (obj) => obj.id !== action.payload
            );
            return { ...state, usersList: userData };
        }
        case Actions.SET_SELECTED_USER: {
            return { ...state, selectedUser: action.payload };
        }
        case Actions.CHANGE_VIEW_TYPE: {
            return { ...state, viewType: action.payload };
        }
        case Actions.UPDATE_USER_BY_ID: {
            let list = state.usersList;
            let index = list.findIndex((obj) => obj.id === action.payload.id);
            list[index] = action.payload;
            return { ...state, usersList: list };
        }
        case Actions.SET_USER_PER_PAGE: {
            return {
                ...state,
                userPagination: {
                    ...state.userPagination,
                    listViewLimit: action?.payload,
                },
            };
        }
        case Actions.SET_CURRENT_PAGE: {
            return {
                ...state,
                userPagination: {
                    ...state.userPagination,
                    currentPage: action.payload?.data,
                },
            };
        }
        case Actions.GET_USER_MODULE_LIST: {
            return { ...state, userModuleList: action.payload };
        }
        default: {
            return state;
        }
    }
};

export default UserReducer;
