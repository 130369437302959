import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useNavigate } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import Navbar from "../../common/Header/Navbar";
import PageHeader from "../../common/PageHeader";
import TableComponent from "../../components/Table/TableComponent";
import NoInfoAvailable from "../../components/NoInfoAvailable";
import CommonDeleteDialog from "./../../components/DialogsBox/CommonDeleteDialog";
import FooterPagination from "../../common/PaginationSection/FooterPagination";
import TooltipComponent from "../../components/ToolTip/TooltipComponent";
import LoadingComponent from "../../components/Loading/LoadingComponent";
import { ReactComponent as DeleteIcon } from "../../../assets/Images/Icons/DeleteIcon.svg";
import { ReactComponent as EditIcon } from "../../../assets/Images/Icons/EditIcon.svg";
import { getContractsContentAction } from "../../store/Action/ContractAction";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

import {
    getContractInUsActionList,
    addContractInUsAction,
    deleteSignedDocument,
    getSignedDocumentData,
    getSignedDocumentById,
    setCurrentPage,
    setCustomerPageLimit,
    getCategoryDropdown,
    addTrainingLib,
    editTrainingLib,
    deleteTrainingLib,
    getTrainingLib,
} from "../../store/Action/SignedDocumentAction";
import { ROUTES } from "./../../router/RouteList";
import { setSelectedDetails } from "../../store/Action/SignedDocumentAction";
import { permissionDenied } from "../../utils/CommonUtils";
import HeaderPagination from "../../common/PaginationSection/HeaderPagination";
import "../../../assets/styles/Container/SignedDocument.scss";
import { pageLimit } from "../../utils/CommonData";
import UnloginFooter from "../../common/UnloginFooter";
import TextFieldComponent from "../../components/TextField/TextFieldComponent";
// import Videojs from "./video.js";
import {Media, Video } from '@vidstack/player-react';
import { forEach } from "lodash";




function Training(props) {
    const [videoSource, setVideoSource] = useState('');
    const [videoSourceTitle, setVideoSourceTitle] = useState('');


    const navigate = useNavigate();
    const {
        deleteSignedDocument,
        getSignedDocumentData,
        signedDocuments,
        dataTrainingLib,
        getSignedDocumentById,
        userLoggedIn,
        signedDocPagination,
        setCurrentPage,
        setCustomerPageLimit,
        getCategoryDropdown,
        addTrainingLib,
        editTrainingLib,
        deleteTrainingLib,
        getTrainingLib,
    } = props;

    const [state, setState] = useState({
        openDeleteDialog: false,
        deleteData: null,
        openAddDialog: false,
        openSuccesfullModel: false,
        modelContent: "",
        id: null,
        address: { state: "" },
        isLoading: false,
        filteredData: [],
        sortConfig: { key: null, direction: "asc" },
    });
    const [search, setSearch] = useState([])
    const [lib, setLib] = useState({
        'title': '',
        'url': '',
        'domain': '',
    });

    const [editValue, setEditValue] = useState({
        data: [],
        mode: "add"
    })


    const {
        openDeleteDialog,
        deleteData,
        isLoading,
        filteredData,
        sortConfig,
    } = state;

    const permission =
        userLoggedIn?.scope?.length &&
        userLoggedIn?.scope?.find((obj) => obj.prefix === "signed-document")
            ?.role_permissions;

    useEffect(() => {
        setState((prevState) => ({ ...prevState, isLoading: true }));
        const callAPIGet = async () => {
            await getTrainingLib();
        }
        callAPIGet();
    }, []);

    // Load the function
    const stateChange = () => {
        let data = [];
        if(dataTrainingLib) {
            dataTrainingLib?.result?.forEach((row, index) => {
                if(row.domain == window.location.hostname) {
                    data.push(row);
                } 
                else if(row.domain == 'all') {
                    data.push(row);
                }
    
                if( index == 0) {
                    setVideoSource(row?.url);
                    setVideoSourceTitle(row?.title);
                }
    
            });
        }


        setState((prevState) => ({
            ...prevState,
            filteredData: data,
            isLoading: false
        }));
    }

    useEffect(() => {
        stateChange();

        console.log('setVideoJsOptions',dataTrainingLib?.result)
    }, [dataTrainingLib]);

    const closeDelete = () => {
        setState((prevState) => ({ ...prevState, openDeleteDialog: false }));
    };
    const handleDelete = (data) => {
        setState((prevState) => ({
            ...prevState,
            deleteData: data,
            openDeleteDialog: true,
        }));
    };
    const handleDeleteAction = async (data) => {
        closeDelete();
        await deleteTrainingLib(data);
        getTrainingLib();
        // setState((prevState) => ({ ...prevState, isLoading: true }));
        // setState((prevState) => ({ ...prevState, isLoading: false }));
    };
    const handleClickOpen = async () => {
        // setState((prevState) => ({ ...prevState, isLoading: true }));
        await getSignedDocumentById(null);
        // setState((prevState) => ({ ...prevState, isLoading: false }));
        navigate(ROUTES.ADD_SIGNED_DOCUMENT);
    };
    async function signedDocumentView(data, mode) {
        // setState((prevState) => ({ ...prevState, isLoading: true }));
        await getSignedDocumentById(data?.id, mode);
        // setState((prevState) => ({ ...prevState, isLoading: false }));
        navigate(`${ROUTES.VIEW_SIGNED_DOCUMENT}?${data?.id}`, {
            state: { ...data, mode: mode },
        });
    }

    // UPDATE FUNCTION
    async function signedDocumentEdit(data, mode) {
        handleClickOpenDilogAdd(data, mode)
    }


    const renderClassStatus = (obj, row) => {
        if (obj?.rowClass) return obj?.rowClass;
        else if (row?.status_name === "Approved") return "cellApproved";
        else if (
            row?.status_name?.replace(/\s/g, "") === "PendingforApproval" ||
            row?.status_name === "Others"
        )
            return "cellPending";
        else if (
            row?.status_name?.replace(/\s/g, "") === "OnHold" ||
            row?.status_name === "Rejected" ||
            row?.status_name === "Reassigned"
        )
            return "cellOnHold";
    };

    const handleSort = (property) => {
        let sortedData = [...signedDocuments].sort((a, b) => {
            if (property !== "created_at") {
                return sortConfig.direction === "desc"
                    ? a?.[property]?.toLowerCase() <
                      b?.[property]?.toLowerCase()
                        ? 1
                        : b?.[property]?.toLowerCase() <
                          a?.[property]?.toLowerCase()
                        ? -1
                        : 0
                    : a?.[property]?.toLowerCase() >
                      b?.[property]?.toLowerCase()
                    ? 1
                    : b?.[property]?.toLowerCase() >
                      a?.[property]?.toLowerCase()
                    ? -1
                    : 0;
            } else {
                return sortConfig.direction === "desc"
                    ? new Date(a?.[property]) - new Date(b?.[property])
                    : new Date(b?.[property]) - new Date(a?.[property]);
            }
        });
        setState((prevState) => ({
            ...prevState,
            filteredData: sortedData,
            sortConfig: {
                direction: sortConfig.direction === "desc" ? "asc" : "desc",
            },
        }));
    };

    const headerList = [
        {
            id: 1,
            name: "Title",
            headerClass: "tableHeading",
            accessibleKey: "title",
            rowClass: "nameFile",
            headerAlign: "center",
            rowAlign: "center",
            sort: true,
            sortFunction: handleSort,
        },
        {
            id: 2,
            name: "Domain",
            headerClass: "tableHeading",
            accessibleKey: "domain",
            rowClass: "tableBodyRow",
            headerAlign: "center",
            rowAlign: "center",
            sort: false,
        },
        {
            id: 3,
            name: "URL",
            headerClass: "tableHeading Assign ToTableHeading",
            accessibleKey: "url",
            rowClass: "companyName",
            headerAlign: "center",
            rowAlign: "center",
            sort: false,
        },
        // {
        //     id: 4,
        //     name: "Status",
        //     headerClass: "tableHeading",
        //     accessibleKey: "status_name",
        //     headerAlign: "center",
        //     rowAlign: "center",
        //     sort: true,
        //     sortFunction: handleSort,
        // },
        {
            id: 6,
            name: "Time Stamp",
            headerClass: "tableHeading noOfUserTableHeading",
            accessibleKey: "created_at",
            rowClass: "stampTime",
            headerAlign: "center",
            rowAlign: "center",
            sort: true,
            sortFunction: handleSort,
        },
    ];

    function handleSearch(data) {
        getSignedDocumentData(
            null,
            signedDocPagination?.listViewLimit,
            true,
            search)
            setState((prevState) => ({
                ...prevState,
                filteredData: signedDocuments,
                search
            }));
    }

    const handleViewLimitChange = async (data) => {
        setCustomerPageLimit(data?.target?.value);
        // setState((prevState) => ({ ...prevState, isLoading: true }));
        await getSignedDocumentData(null, data?.target?.value, true, search);
        // setState((prevState) => ({ ...prevState, isLoading: false }));
    };

    const handlePaginationOnChange = async (_, pageNumber) => {
        setCurrentPage(pageNumber);
        // setState((prevState) => ({ ...prevState, isLoading: true }));
        await getSignedDocumentData(
            pageNumber,
            signedDocPagination?.listViewLimit,
            true,
            search
        );
        setState((prevState) => ({ ...prevState, isLoading: false }));
    };

    const [open, setOpen] = useState(false);

    const handleClickOpenDilogAdd = (data, mode) => {
        setEditValue({
            data, 
            mode
        });

        if(mode == "edit") {
            if(data) {
                setLib({
                    'title': data?.title,
                    'url': data?.url,
                    'domain': data?.domain,
                    'id': data?.id
                })
            }
        } else {
            setLib({
                'title': '',
                'url': '',
                'domain': '',
            })
        }
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const toggle = editValue.mode;
        if(toggle == "add") {
            await addTrainingLib(lib);
        } else {
            await editTrainingLib(lib);
        }

        getTrainingLib();
        setOpen(false);
    }
    
    const changeVideo = async (e) => {
        // e.preventDefault();
        // const toggle = editValue.mode;
        // if(toggle == "add") {
        //     await addTrainingLib(lib);
        // } else {
        //     await editTrainingLib(lib);
        // }

        // getTrainingLib();
        // setOpen(false);

        console.log(e.url)
        console.log('videoSource',videoSource)
        setVideoSource(e.url);
        setVideoSourceTitle(e.title);

    }

    
    return (
        <>
            <Navbar />

            <Grid
                container
                justifyContent={"space-between"}
                alignItems={"center"}
                sx={{ px: { md: 7, xs: 1 }, pt: "110px", mb: "40px" }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        {/* <Videojs videoSource={videoSource} /> */}
                        <center>
                            <Media>
                                <Video loading="visible" controls preload="true">
                                    <video loading="visible" width={window.innerWidth - 100} height={700} src={videoSource} preload="none" data-video="0" controls />
                                </Video>
                            </Media>
                        </center>
                        <h2 style={{fontSize: '40px'}}> <b>{videoSourceTitle}</b> </h2>
                    </Grid>
                    {filteredData?.length > 0 &&
                        filteredData?.map((row, index) => (
                            <Grid item xs={4} md={4} mt={5}>

                                <Typography onClick={(e)=> changeVideo(row)}>
                                    {/* <img
                                        onClick={(e)=> changeVideo(row)}
                                        width="380"
                                        height="280"
                                        className="center w-full bg-cover object-cover"
                                        src={
                                            "https://buffer.com/cdn-cgi/image/w=1000,fit=contain,q=90,f=auto/library/content/images/size/w600/2023/10/free-images.jpg"
                                        }
                                    /> */}
                                    <Media>
                                        <Video loading="visible" preload="true">
                                            <video loading="visible" width={400} height={300} src={row?.url} preload="none" data-video="0" />
                                        </Video>
                                    </Media>
                                    <Typography className="font_18_400"><b>{row?.title}</b></Typography>
                                    <br/>
                                </Typography>
                            </Grid>
                    ))}
                </Grid>
            </Grid>

            <UnloginFooter />
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        customerDropdown: state?.auth?.commonDropdown?.states,
        signedDocuments: state?.signedDocs?.signedDocuments,
        signedDocPagination: state?.signedDocs?.signedDocPagination,
        userLoggedIn: state?.auth?.userLoggedIn,
        dataTrainingLib: state?.signedDocs?.trainingLib,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            addContractInUsAction,
            getContractInUsActionList,
            deleteSignedDocument,
            getContractsContentAction,
            getSignedDocumentData,
            setSelectedDetails,
            getSignedDocumentById,
            setCurrentPage,
            setCustomerPageLimit,
            getCategoryDropdown,
            addTrainingLib,
            editTrainingLib,
            deleteTrainingLib,
            getTrainingLib,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Training);
