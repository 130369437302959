import * as Actions from '../Action/ContractAction'
import {b64DecodeUnicode} from '../../components/Base64'

const initialState = {
    contractsList:[],
	contractsTemplates:null,
	selectedContract:{
		id: null,
		type: null,
		name:null,
		status: null,
		state_id: null,
		path: null,
		html: null,
	},
};

const ContractsReducer = (state = initialState, action) => {
	switch (action?.type) {
		case Actions.GET_CONTRACTS_ACTION: {
			return {
				...state,
				contractsList:action.payload
			};
		}
		case Actions.ADD_CONTRACT_ACTION: {
			return {
				...state,
				contractsList:action.payload
			};
		}
		case Actions.UPDATE_CONTRACTS_ACTION: {
			return {
				...state,
				contractsList:action.payload
			};
		}	
		case Actions.DELETE_CONTRACT_ACTION: {
			return {
				...state,
				contractsList:action.payload
			};	
		}
		case Actions.GET_CONTRACTS_TEMPLATES_ACTION: {
			return {
				...state,
				contractsTemplates:action.payload
			};	
		}
		case Actions.SET_SELECTED_TEMPLATE_ACTION: {
			let fileContent=null;
			if(action?.payload){
				fileContent=b64DecodeUnicode(action?.payload?.html);
			}
			return {
				...state,
				selectedContract:{
					...state.selectedContract,
					id: action?.payload?.id,
					type: action?.payload?.type,
					name:action?.payload?.name,
					status: action?.payload?.status,
					state_id: action?.payload?.state_id,
					path: action?.payload?.path,
					html: fileContent,
				}
			};	
		}
		case Actions.UPDATE_CONTENT_LOCAL_ACTION: {
			return {
				...state,
				selectedContract:{
					...state.selectedContract,
					html:action?.payload,
				}
			};	
		}
		case Actions.UPDATE_CONTENT_GLOBAL_ACTION: {
			return {
				...state,
				selectedContract:{
					...state.selectedContract,
					id: action.payload,
					type: action.payload,
					name:action.payload,
					status: action.payload,
					state_id: action.payload,
					path: action.payload,
					html: action.payload,
				}
			};	
		}
		case Actions.DELETE_TEMPLATE_ACTION: {
			return {
				...state,
			};	
		}
		default: {
			return state;
		}
	}
};
export default ContractsReducer; 