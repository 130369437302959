import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toast } from "react-hot-toast";
import Echo from "laravel-echo";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import styles from "../../../assets/styles/AppbarModule.scss";
import MenuList from "@mui/material/MenuList";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Badge from "@mui/material/Badge";
import Divider from "@mui/material/Divider";
import NoInfoAvailable from "../../components/NoInfoAvailable";
import NotificationItem from "./NotificationItem";
import {
    getListWithCountAction,
    refreshNotificationListAction,
    deleteNotificationAction,
    readSingleNotificationAction,
    readAllNotificationAction,
} from "../../store/Action/NotificationAction";
import { NOTIFICATION_CHANNEL_CONSTANTS } from "../../config/ApiConstants";
import { ReactComponent as NotificationIcon } from "../../../assets/Images/Icons/Notification.svg";
import "../../../assets/styles/Container/Notification.scss";
import color from "../../../assets/styles/Color.scss";
import { getUserById } from "../../store/Action/UserAction";
window.Pusher = require("pusher-js");

function Notification(props) {
    const {
        notificationState,
        notificationConfig,
        getListWithCountAction,
        refreshNotificationListAction,
        deleteNotificationAction,
        readSingleNotificationAction,
        readAllNotificationAction,
        userLoggedIn
    } = props;
    const [anchorElNotification, setAnchorElNotifiaction] = useState(null);

    const handleNotificationIconClickClose = () =>
        setAnchorElNotifiaction(null);
    const handleNotificationIconClick = (e) =>
        setAnchorElNotifiaction(e.currentTarget);

    window.Echo = new Echo(
        notificationConfig
            ? notificationConfig
            : JSON.parse(localStorage.getItem("notificationConfig")) || ""
    );

    const handleRefreshList = (notification) => {
        const newNotification = {
            id: notification.id,
            data: {
                title: notification.title,
                msg: notification.msg,
                user: notification.user,
            },
        };
        refreshNotificationListAction(newNotification);
    };

    useEffect(() => {
        getListWithCountAction();
            window.Echo.private(
                NOTIFICATION_CHANNEL_CONSTANTS.USER_NOTIFICATION
            ).notification((notification) => {
                handleRefreshList(notification);
                let notificationMsg = (
                    <div
                        dangerouslySetInnerHTML={{
                            __html: notification?.msg,
                        }}
                    ></div>
                );
                toast(notificationMsg, {
                    position: "top-right",
                    style: {
                        minWwidth: "300px",
                        fontWeight: "600",
                        justifyContent: "center",
                        display: "flex",
                        padding: "14px 10px 14px 10px",
                        borderRadius: "5px",
                        backgroundColor: color.tertiary,
                    },
                    duration: 5000,
                });
            });
       
    }, []);

    return (
        <>
            <IconButton
                variant="contained"
                onClick={handleNotificationIconClick}
                aria-label={notificationState.count}
                className="notificationIconButton"
            >
                <Badge
                    badgeContent={notificationState.count}
                    overlap="circular"
                    className="badge"
                >
                    <NotificationIcon />
                </Badge>
            </IconButton>
            <Menu
                anchorEl={anchorElNotification}
                open={Boolean(anchorElNotification)}
                onClose={handleNotificationIconClickClose}
                disableScrollLock={true}
                anchorReference="anchorPosition"
                classes={{
                    paper: "notificationCustomMenu",
                }}
                anchorPosition={{
                    top: 70,
                    left: window.screen.availWidth - 550,
                }}
            >
                <MenuItem
                    sx={{
                        "&:hover": {
                            backgroundColor: "white",
                            cursor: "default",
                        },
                    }}
                    className="my-0"
                >
                    <Grid spacing={2} container>
                        <Grid item md={8}>
                            <Typography variant="h6" className="font_16_600">
                               {userLoggedIn?.language_data?.comman?.notifications || 'Notifications'}
                            </Typography>
                        </Grid>
                        <Grid item md={4}>
                            <Box
                                onClick={readAllNotificationAction}
                                sx={{
                                    cursor: "pointer",
                                    width: "fit-content",
                                }}
                            >
                                <Typography className="readAllText">
                                   {userLoggedIn?.language_data?.comman?.mark_notif || "Mark all as Read"}
                                </Typography>
                                <CheckCircleOutlineIcon className="readAllTextIcon" />
                            </Box>
                        </Grid>
                    </Grid>
                </MenuItem>
                <Divider light={true} />
                <MenuList
                    className={
                        styles.headerMenuItem + " notificationCustomMenuList"
                    }
                >
                    {Object.keys(notificationState.list).length > 0 ? (
                        Object.values(notificationState.list).map((element) => {
                            return (
                                <NotificationItem
                                    key={element.id}
                                    item={element}
                                    deleteAction={deleteNotificationAction}
                                    readSingleNotification={
                                        readSingleNotificationAction
                                    }
                                />
                            );
                        })
                    ) : (
                        <MenuItem className="notificationCustomMenuItem">
                            <NoInfoAvailable
                                className="notificationText font_14_600"
                                noInfoTypo="m-auto"
                            />
                        </MenuItem>
                    )}
                </MenuList>
            </Menu>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,
        notificationState: state.notification,
        notificatinConfig: state.notificationConfig,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getListWithCountAction,
            refreshNotificationListAction,
            deleteNotificationAction,
            readSingleNotificationAction,
            readAllNotificationAction,
            getUserById,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
