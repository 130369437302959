import * as Actions from '../Action/CommentsAction'

const initialState = {
    comments:[],
	commentsCount:null
};

const comments = (state = initialState, action) => {
	switch (action?.type) {
		case Actions.GET_COMMENTS_ACTION: {
			return {
				...state,
				comments:action.payload	
			};
		}
		case Actions.ADD_COMMENT_ACTION: {
			return {
				...state,
				comments:action.payload	
			};
		}
		case Actions.SET_COMMENTS_COUNT_ACTION: {
			return {
				...state,
				commentsCount:action.payload	
			};
		}
		default: {
			return state;
		}
	}
};

export default comments;