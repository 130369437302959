import React, { useState, useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import "../../../assets/styles/Common/_CommonStyle.scss";
import "../../../assets/styles/Container/ContractorEmail.scss";
import Navbar from "../../common/Header/Navbar";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import LoadingComponent from "./../../components/Loading/LoadingComponent";
import EmailSections from "./EmailSection";
import EmailHistory from "./EmailHistory";
import SaveEmailDialog from "./SaveEmailDialog";
import {
    storeEmailTemplate,
    sendEmailTemplate,
    updateEmailTemplateDetail,
    getEmailTemplateDetail,
    setSelectedDetails,
    getEmailLogData,
} from "../../store/Action/EmailTemplateAction";
import { ROUTES } from "../../router/RouteList";
import {Translate } from 'react-auto-translate';
function AddEmailTemplate(props) {
    const {
        storeEmailTemplate,
        sendEmailTemplate,
        updateEmailTemplateDetail,
        getEmailTemplateDetail,
        emailDetails,
        setSelectedDetails,
        getEmailLogData,
        emailLogData,
        loggedInUser,
        guid
    } = props;

    const pathName = window?.location?.pathname?.split("/");

    const [state, setState] = useState({
        from_mail: emailDetails?.from_mail || "",
        to_mail: emailDetails?.to_mail || "",
        cc_mail: emailDetails?.cc_mail || "",
        bcc_mail: emailDetails?.bcc_mail || "",
        subject: emailDetails?.subject || "",
        content: emailDetails?.content || "",
        template_name: emailDetails?.template_name || "",
        type: emailDetails?.type || "",
        description: emailDetails?.description || "",
        assign_to: emailDetails?.assign_to || "",
        status: emailDetails?.status || "",
        status_reason: emailDetails?.status_reason || "",
        approved_by: emailDetails?.approved_by || "",
        isLoading: false,
        openSaveModel: false,
        refreshToggle: false,
        defaultAssign_to: [],
        guid:guid
    });
    const { template_name, isLoading, openSaveModel, refreshToggle } = state;
    useEffect(() => {
        if (!pathName[1].includes("create")) {
            getEmailLogData(pathName[2]);
            getEmailTemplateDetail(pathName[2]);
        }
    }, [getEmailLogData, getEmailTemplateDetail]);

    if (
        emailDetails &&
        !pathName[1].includes("create") &&
        template_name === ""
    ) {
        setState((prevState) => ({
            ...prevState,
            from_mail: emailDetails?.from_mail || "",
            to_mail: emailDetails?.to_mail || "",
            cc_mail: emailDetails?.cc_mail || "",
            bcc_mail: emailDetails?.bcc_mail || "",
            subject: emailDetails?.subject || "",
            content: emailDetails?.content || "",
            template_name: emailDetails?.template_name || "",
            type: emailDetails?.type || "",
            description: emailDetails?.description || "",
            assign_to: emailDetails?.assign_to,
            status: emailDetails?.status,
            status_reason: emailDetails?.status_reason,
            guid:guid
           
        }));
    }

    const setSaveModel = (data) => {
        setState((prevState) => ({
            ...prevState,
            openSaveModel: data,
            refreshToggle: !refreshToggle,
        }));
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        await sendEmailTemplate(pathName[2], state);
        navigate(ROUTES.EMAIL_TEMPLATES);
    };
    const navigate = useNavigate();

    const saveTemplate = async () => {
        if (state?.subject && state?.template_name !== "") {
            if (state.status === "") {
                toast.error("Decision is required");
            } else {
                if (state.status_reason === "") {
                    toast.error("Reason is required");
                } else {
                    if (state.description === "") {
                        toast.error("Comments is required");
                    } else {
                        if (state.assign_to === "") {
                            toast.error("Assign/Reassign is required");
                        } else {
                            setState((prevState) => ({
                                ...prevState,
                                isLoading: true,
                                openSaveModel: false,
                            }));
                            await storeEmailTemplate({
                                ...state,
                                approved_by: loggedInUser,
                            });
                            setState((prevState) => ({
                                ...prevState,
                                isLoading: false,
                            }));
                            navigate(ROUTES.EMAIL_TEMPLATES);
                        }
                    }
                }
            }
        } else {
            toast.error("Please Enter Required Input.");
        }
    };

    const UpdateEmailTemplate = async () => {
        if (state.status === "") {
            toast.error("Decision is required");
        } else {
            if (state.status_reason === "") {
                toast.error("Reason is required");
            } else {
                if (state.description === "") {
                    toast.error("Comments is required");
                } else {
                    if (state.assign_to === "") {
                        toast.error("Assign/Reassign is required");
                    } else {
                        setState((prevState) => ({
                            ...prevState,
                            isLoading: true,
                            openSaveModel: false,
                        }));
                        delete state.isLoading;
                        delete state.openSaveModel;
                        delete state.refreshToggle;
                        await updateEmailTemplateDetail(pathName[2], {
                            ...state,
                            approved_by: loggedInUser,
                            guid:guid
                        });
                        setState((prevState) => ({
                            ...prevState,
                            isLoading: false,
                        }));
                        navigate(ROUTES.EMAIL_TEMPLATES);
                        setSelectedDetails(null);
                    }
                }
            }
        }
    };

    const handlerCancelOnClick = () => {
        setSelectedDetails(null);
        navigate(ROUTES.EMAIL_TEMPLATES);
    };

    return (
        <>
        <Navbar />
        <SaveEmailDialog
            open={openSaveModel}
            state={state}
            setState={setState}
            saveTemplate={saveTemplate}
            UpdateEmailTemplate={UpdateEmailTemplate}
        />
        {(!pathName[1].includes("create") && !emailDetails) || isLoading ? (
            <LoadingComponent sx={{ height: "600px" }} />
        ) : (
            <Box
                sx={{
                    paddingX: "20px",
                    paddingTop: "40px",
                    paddingBottom: "20px",
                }}
            >
                <Grid
                    lg={12}
                    mt={1}
                    display={"flex"}
                    justifyContent={"center"}
                    sx={{ paddingX: "60px", paddingTop: "40px" }}
                >
                    <Typography
                        className="emailHeading"
                        sx={{ marginRight: "20px" }}
                    >
                        <Translate>{template_name}</Translate>
                    </Typography>
                </Grid>
                <Grid container mt={3}>
                    <Grid px={2} item xs={12} xl={10} md={10}>
                        <EmailSections
                            setOpen={(data) => setSaveModel(data)}
                            state={state}
                            setState={setState}
                            handleSubmit={handleSubmit}
                            handlerCancelOnClick={handlerCancelOnClick}
                        />
                    </Grid>
                    <Grid item xs={12} xl={2} md={2}>
                        <EmailHistory emailLogData={emailLogData} />
                    </Grid>
                </Grid>
            </Box>
        )}
    </>
    );
}

const mapStateToProps = (state) => {
    return {
        emailDetails: state?.email?.emailDetails,
        emailLogData: state?.email?.emailLogData,
        loggedInUser: state?.auth?.userLoggedIn?.user?.id,
        guid: state?.whiteLabel?.guid
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            storeEmailTemplate,
            sendEmailTemplate,
            updateEmailTemplateDetail,
            getEmailTemplateDetail,
            setSelectedDetails,
            getEmailLogData,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEmailTemplate);
