import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Navbar from "../../common/Header/Navbar";
import PageHeader from "../../common/PageHeader";
import HeaderPagination from "../../common/PaginationSection/HeaderPagination";
import CustomerCard from "./CustomerCard";
import FooterPagination from "../../common/PaginationSection/FooterPagination";
import {
    getCustomerList,
    toggleDeletePopup,
    setSelectedCustomer,
    deleteCustomerById,
    getCustomerById,
    changeCustomerViewType,
    setCustomerPageLimit,
    setCurrentPage,
} from "../../store/Action/CustomerAction";
import { getUserList, setSelectedUser } from "../../store/Action/UserAction";
import "../../../assets/styles/Container/CustomerCard.scss";
import NoInfoAvailable from "../../components/NoInfoAvailable";
import CustomerTableView from "./CustomerTableView";
import CommonDeleteDialog from "../../components/DialogsBox/CommonDeleteDialog";
import LoadingComponent from "../../components/Loading/LoadingComponent";
import { ROUTES } from "../../router/RouteList";
import { permissionDenied } from "../../utils/CommonUtils";
import { pageLimit } from "../../utils/CommonData";

function ArchivedClients(props) {
    const {
        getCustomerList,
        customerList,
        toggleDeletePopup,
        setSelectedCustomer,
        setSelectedUser,
        deletePopUp,
        deleteCustomerById,
        getCustomerById,
        changeCustomerViewType,
        viewCustomerType,
        getUserList,
        userLoggedIn,
        customerPagination,
        setCurrentPage,
        userPagination,
        setCustomerPageLimit,
    } = props;
    const status = 'inactive';
    const navigate = useNavigate();
    const [state, setState] = useState({
        isLoading: false,
        filteredData: null,
    });
    const [search, setSearch] = useState([]);
    const { isLoading, filteredData } = state;
    const loggedInClient = filteredData?.find(
        (obj) => obj.id === userLoggedIn?.user?.client_id
    );
    const permission =
        userLoggedIn?.scope?.length &&
        userLoggedIn?.scope?.find((obj) => obj.prefix === "customer")
            ?.role_permissions;
    const userPermission =
        userLoggedIn?.scope?.length &&
        userLoggedIn?.scope?.find((obj) => obj.prefix === "users")
            ?.role_permissions;
    const hierarchyPermission =
        userLoggedIn?.scope?.length &&
        userLoggedIn?.scope?.find((obj) => obj.prefix === "hierarchy")
            ?.role_permissions;
    useEffect(() => {
        if (filteredData === null)
            getCustomerList(
                search,
                null,
                pageLimit || customerPagination?.listViewLimit,
                status
            );
        if (customerList) {
            setState((prevState) => ({
                ...prevState,
                filteredData: customerList,
            }));
        }
    }, [getCustomerList, customerList, window.location.pathname]);

    const closeDelete = () => {
        toggleDeletePopup({ open: false });
    };
    const handleDeleteAction = async (id) => {
        setState((prevState) => ({ ...prevState, isLoading: true }));
        closeDelete();
        await deleteCustomerById(id);
        getCustomerList(
            search,
            null,
            pageLimit || customerPagination?.listViewLimit,
            status
        );
        setState((prevState) => ({ ...prevState, isLoading: false }));
    };

    const handleAdd = async (customer) => {
        setState((prevState) => ({ ...prevState, isLoading: true }));
        await setSelectedUser(null);
        await getCustomerById(customer?.id);
        navigate(`${ROUTES.CREATE_USER}?${customer?.id}`);
        setState((prevState) => ({ ...prevState, isLoading: false }));
    };

    const handleEdit = async (customer, mode) => {
        setState((prevState) => ({ ...prevState, isLoading: true }));
        await getCustomerById(customer?.id, mode);
        setState((prevState) => ({ ...prevState, isLoading: false }));
        navigate(
            `${mode === "edit" ? ROUTES.EDIT_CLIENT : ROUTES.VIEW_CLIENT}?${
                customer?.id
            }`
        );
    };

    const handleDelete = (customer) => {
        toggleDeletePopup({ open: true, data: customer });
    };

    const handleAddButton = async () => {
        navigate(ROUTES.CREATE_CLIENT);
        await setSelectedCustomer(null);
    };

    const handleUsers = async (customer) => {
        setState((prevState) => ({ ...prevState, isLoading: true }));
        await getUserList(
            customer?.id,
            userPagination?.currentPage,
            userPagination?.listViewLimit
        );
        setState((prevState) => ({ ...prevState, isLoading: false }));
        navigate(ROUTES.USER + `${"?" + customer?.id}`, {
            state: customer?.id,
        });
    };

    const handleChangeView = (viewCustomerType) => {
        changeCustomerViewType(viewCustomerType);
    };

    const handleSettings = async (customer) => {
        const path = ROUTES.WHITE_LABELING?.split("/");
        setState((prevState) => ({ ...prevState, isLoading: true }));
        await setSelectedCustomer(customer);
        navigate(`/${path[1]}/` + customer?.id, { state: customer });
        setState((prevState) => ({ ...prevState, isLoading: false }));
    };

    const handleHierarchy = async (customer) => {
        const path = ROUTES.CUSTOMER_HIERARCHY?.split("/");
        setState((prevState) => ({ ...prevState, isLoading: true }));
        await setSelectedCustomer(customer);
        setState((prevState) => ({ ...prevState, isLoading: false }));
        navigate(`/${path[1]}/` + customer?.id, { state: customer });
    };

    const handleSearch = async (data) => {
        setState((prevState) => ({ ...prevState, isLoading: true }));
        const FilterData = await getCustomerList(
            search,
            null,
            pageLimit || customerPagination?.listViewLimit,
            status
        );
        if (data !== "") {
            setState((prevState) => ({
                ...prevState,
                filteredData: FilterData.payload.data,
                isLoading: false,
            }));
        } else {
            setState((prevState) => ({
                ...prevState,
                filteredData: FilterData.payload.data,
                isLoading: false,
            }));
        }
    };

    const handleViewLimitChange = async (data) => {
        setCustomerPageLimit(data?.target?.value);
        setState((prevState) => ({ ...prevState, isLoading: false }));
        await getCustomerList(search, null, data?.target?.value || pageLimit, status);
        setState((prevState) => ({ ...prevState, isLoading: false }));
    };

    const handlePaginationOnChange = async (_, pageNumber) => {
        setCurrentPage(pageNumber);
        setState((prevState) => ({ ...prevState, isLoading: true }));
        await getCustomerList(
            search,
            pageNumber,
            customerPagination?.listViewLimit || pageLimit,
            status
        );
        setState((prevState) => ({ ...prevState, isLoading: false }));
    };

    if(localStorage.getItem('role_id') == 2) {
        navigate(`${ROUTES.CUSTOMERS_INFORMATION}/${localStorage.getItem('guid')}/${localStorage.getItem('user_id')}`)
    }

    return (
        <Fragment>
            <Navbar />
            <CommonDeleteDialog
                open={deletePopUp?.open}
                title={`${
                    deletePopUp?.data?.status === "active"
                        ? "Deactivate"
                        : "Activate"
                }  Customer`}
                cancelButtonAction={closeDelete}
                cancelButtonTitle="Cancel"
                submitButtonTitle={
                    deletePopUp?.data?.status === "active"
                        ? "Deactivate"
                        : "Activate"
                }
                content={`${
                    deletePopUp?.data?.status === "active"
                        ? "Deactivating"
                        : "Activating"
                } Customer will ${
                    deletePopUp?.data?.status === "active"
                        ? "disable"
                        : "enable"
                } the access to all the users for ${
                    deletePopUp?.data?.display_name
                } .`}
                submitButtonAction={handleDeleteAction}
                submitButtonParameter={deletePopUp?.data}
            />
            <Box sx={{ px: { md: 7, xs: 1 } }}>
                <PageHeader
                    title={
                        "Archived Customer"
                    }
                    showSearchBar={true}
                    handleSearch={handleSearch}
                    setSearch={setSearch}
                />
                <Grid item xs={12}>
                    <HeaderPagination
                        showViewButton={true}
                        total={customerPagination?.total}
                        listViewLimit={customerPagination?.listViewLimit}
                        handleChangeView={handleChangeView}
                        handleViewLimitChange={handleViewLimitChange}
                        oneLine={
                            loggedInClient?.client_type !==
                            (userLoggedIn?.language_data?.customer
                                ?.direct_conductoe || "Direct Conductor")
                                ? false
                                : true
                        }
                        showPagination={pageLimit < customerPagination?.total}
                    />
                </Grid>
            </Box>
            {!filteredData || isLoading ? (
                <LoadingComponent customLoader={1} />
            ) : (
                <Box sx={{ px: { md: 7, xs: 1 } }}>
                    {filteredData?.length ? (
                        viewCustomerType === "card" ? (
                            <Grid
                                container
                                display={"flex"}
                                flexWrap={"wrap"}
                                spacing={2}
                                className="customerCardMainDiv"
                            >
                                <Grid item className="customerCardDiv">
                                    <Grid
                                        item
                                        display={"flex"}
                                        flexWrap={"wrap"}
                                        justifyContent={"center"}
                                        gap={1}
                                    >
                                        {filteredData.map((customer) => (
                                            <CustomerCard
                                                customer={customer}
                                                key={customer.id}
                                                toggleDeletePopup={
                                                    toggleDeletePopup
                                                }
                                                setSelectedCustomer={
                                                    setSelectedCustomer
                                                }
                                                handleEdit={handleEdit}
                                                handleAdd={handleAdd}
                                                handleDelete={handleDelete}
                                                handleUsers={handleUsers}
                                                handleSettings={handleSettings}
                                                handleHierarchy={
                                                    handleHierarchy
                                                }
                                                permission={permission}
                                                userPermission={userPermission}
                                                hierarchyPermission={
                                                    hierarchyPermission
                                                }
                                                permissionDenied={
                                                    permissionDenied
                                                }
                                                status = {status}
                                            />
                                        ))}
                                    </Grid>
                                </Grid>
                            </Grid>
                        ) : (
                            <CustomerTableView
                                display={"flex"}
                                flexWrap={"wrap"}
                                justifyContent={"center"}
                                customerList={filteredData}
                                toggleDeletePopup={toggleDeletePopup}
                                setSelectedCustomer={setSelectedCustomer}
                                handleEdit={handleEdit}
                                handleAdd={handleAdd}
                                handleDelete={handleDelete}
                                handleUsers={handleUsers}
                                handleSettings={handleSettings}
                                handleHierarchy={handleHierarchy}
                                permission={permission}
                                userPermission={userPermission}
                                hierarchyPermission={hierarchyPermission}
                                permissionDenied={permissionDenied}
                                status= {status}
                            />
                        )
                    ) : (
                        filteredData?.length === 0 && (
                            <NoInfoAvailable
                                className="noCustomerInfo"
                                noInfoTypo="noInfoTypoCustomer"
                            />
                        )
                    )}
                    {pageLimit < customerPagination?.total ? (
                        <FooterPagination
                            count={customerPagination?.lastPage}
                            page={customerPagination?.currentPage}
                            onChange={handlePaginationOnChange}
                            defaultPage={customerPagination?.currentPage}
                        />
                    ) : null}
                </Box>
            )}
        </Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        customerList: state.customer.customerList,
        deletePopUp: state?.customer?.deletePopUp,
        selectedCustomer: state?.customer,
        viewCustomerType: state?.customer?.viewCustomerType,
        userLoggedIn: state?.auth?.userLoggedIn,
        customerPagination: state?.customer?.customerPagination,
        userPagination: state?.user?.userPagination,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getCustomerList,
            toggleDeletePopup,
            setSelectedCustomer,
            setSelectedUser,
            deleteCustomerById,
            getCustomerById,
            changeCustomerViewType,
            getUserList,
            setCurrentPage,
            setCustomerPageLimit,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ArchivedClients);
