import { Box } from "@mui/system";
import React from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

const FooterPagination = (props) => {
    const { count, page, onChange, defaultPage } = props;
    return (
        <>
            <Box
                display={"flex"}
                sx={{
                    py: 3,
                    px: { md: 10, xs: 0 },
                    justifyContent: { md: "end", xs: "center " },
                }}
            >
                <Stack spacing={2}>
                    <Pagination
                        className="paginationSec"
                        count={count || 10}
                        page={page}
                        shape="rounded"
                        onChange={onChange}
                        defaultPage={page}
                    />
                </Stack>
            </Box>
        </>
    );
};

export default FooterPagination;
