import React, { useState, useEffect } from "react";
import { ROUTES } from "../../router/RouteList";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getCommentsAction } from "../../store/Action/CommentsAction";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import "../../../assets/styles/Container/Footer.scss";
import DialogComments from "../../pages/ComplianceWorkflow/ContractorDetails/DialogComments";
import DialogEventTracking from "../../pages/EventTracking/DialogEventTracking";
import { ReactComponent as MessageIcon } from "../../../assets/Images/Icons/MessageIcon.svg";
const navItems = ["Signed Document", "Document", "Email"];
const Footer = (props) => {
    const {
        comments,
        getCommentsAction,
        customer,
        contractor_id,
        selectedCustomer,
        user,
        mode,
    } = props;
    console.log("mode", mode);
    const guid = customer?.selectedCustomer?.guid;
    let location = useLocation();
    const [allSectionCommets, setAllComments] = useState([]);
    useEffect(() => {
        if (
            location.pathname.includes("customers") ||
            location.pathname === ROUTES.SETTINGS
        ) {
            getCommetState(guid);
        }
        // eslint-disable-next-line
    }, [comments.length]);
    async function getCommetState(guid) {
        try {
            await getCommentsAction(guid);
            setAllComments(filterComments(comments));
        } catch (error) {}
    }
    const filterComments = (objAllComments) => {
        const filterAllComments = [];
        const comments = [];
        Object.entries(objAllComments).map((entry) => {
            // eslint-disable-next-line
            return Object.entries(entry[1]).map((en) => {
                if (Array.isArray(en[1])) {
                    filterAllComments.push(en[1]);
                }
            });
        });
        for (let bit of filterAllComments) {
            comments.push(...bit);
        }
        return comments;
    };
    const handleFooterMenuClick = (e, buttonName) => {
        if (buttonName === "Signed Document") {
            window.open(
                `${ROUTES.CONTRACTS}/${selectedCustomer}/${contractor_id}`,
                "_blank"
            );
        } else if (buttonName === "Document") {
            window.open(
                `${ROUTES.DOCUMENTS}/${selectedCustomer}/${contractor_id}`,
                "_blank"
            );
        } else if (buttonName === "Email") {
            window.open(
                `${
                    ROUTES.WORKFLOW_EMAIL +
                    "/" +
                    selectedCustomer +
                    "/" +
                    contractor_id
                }`,
                "_blank"
            );
        } else if (buttonName === "Audit Defense Report") {
            localStorage.setItem("contractor_id", contractor_id);
            localStorage.setItem("mode", mode);
            window.open(`${ROUTES.AUDIT_DEFENSE_REPORT}`, "_blank");
        }
    };
    return (
        <Box className="footerBox" paddingX={3}>
            <Grid
                container
                display={"flex"}
                alignItems={"center"}
                alignContent={"center"}
            >
                <Grid item md={10} sm={10}>
                    {(location.pathname.includes("customers") ||
                        location.pathname === ROUTES.CONTRACTS ||
                        location.pathname === ROUTES.DOCUMENTS ||
                        location.pathname === ROUTES.WORKFLOW_EMAIL ||
                        location.pathname === ROUTES.AUDIT_DEFENSE_REPORT) && (
                        <List disablePadding style={{ display: "flex" }}>
                            {navItems.map((item) => (
                                <ListItem
                                    key={item}
                                    sx={{
                                        paddingY: "0",
                                        paddingX: "3",
                                        width: "fit-content",
                                    }}
                                >
                                    <ListItemButton
                                        mt={1}
                                        sx={{
                                            textAlign: "center",
                                            padding: "0",
                                        }}
                                        onClick={(e) =>
                                            handleFooterMenuClick(e, item)
                                        }
                                    >
                                        <ListItemText
                                            disablePadding
                                            primary={
                                                <Typography
                                                    type="body2"
                                                    style={{
                                                        fontSize: "16px",
                                                        fontWeight: "600",
                                                        lineHeight: "20px",
                                                        color: "#3B3B3B",
                                                    }}
                                                >
                                                    {item}
                                                </Typography>
                                            }
                                        />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                            <>
                                {localStorage.getItem("role_id") == 2 ? null : (
                                    <ListItem
                                        key={"Audit defence Report"}
                                        sx={{
                                            paddingY: "0",
                                            paddingX: "3",
                                            width: "fit-content",
                                        }}
                                    >
                                        <ListItemButton
                                            mt={1}
                                            sx={{
                                                textAlign: "center",
                                                padding: "0",
                                            }}
                                            onClick={(e) =>
                                                handleFooterMenuClick(
                                                    e,
                                                    "Audit Defense Report"
                                                )
                                            }
                                        >
                                            <ListItemText
                                                disablePadding
                                                primary={
                                                    <Typography
                                                        type="body2"
                                                        style={{
                                                            fontSize: "16px",
                                                            fontWeight: "600",
                                                            lineHeight: "20px",
                                                            color: "#3B3B3B",
                                                        }}
                                                    >
                                                        {"Audit Defense Report"}
                                                    </Typography>
                                                }
                                            />
                                        </ListItemButton>
                                    </ListItem>
                                )}
                            </>
                        </List>
                    )}
                </Grid>
                {/* <Grid item md={1} sm={1} mt={1} sx={{ textAlign: "center" }}>
                    {(location.pathname.includes("customers") ||
                        location.pathname === ROUTES.CONTRACTS ||
                        location.pathname === ROUTES.DOCUMENTS ||
                        location.pathname === ROUTES.WORKFLOW_EMAIL ||
                        location.pathname === ROUTES.SETTINGS) &&
                        (comments.lenth < 0 ? (
                            <MessageIcon sx={{ ml: "10px" }} />
                        ) : (
                            <DialogComments
                                comments={allSectionCommets}
                                count={allSectionCommets.length}
                                callFrom={"Footer"}
                            />
                        ))}
                </Grid> */}
                <Grid item md={1} sm={1} mt={1} sx={{ textAlign: "center" }}>
                    <DialogEventTracking />
                </Grid>
            </Grid>
        </Box>
    );
};

const mapStateToProps = (state) => {
    return {
        comments: state.comments.comments,
        customer: state.customer,
        user: state.user.selectedUser,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ getCommentsAction }, dispatch);
};

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(Footer));
