import * as Actions from "../Action/EmailTemplateAction";

const initialState = {
    emailTemplatesData: null,
    emailLogData: null,
    emailDetails: null,
    assignUserData: null,
    emailStatusData: null,
    emailTemPagination: {
        listViewLimit: 8,
        total: "",
        currentPage: 1,
        lastPage: "",
    },
};

const EmailTemplateReducer = (state = initialState, action) => {
    switch (action?.type) {
        case Actions.ADD_INSERT_EMAIL_TEMPLATES: {
            return {
                ...state,
                emailTemplatesData: [
                    action.payload,
                    ...state.emailTemplatesData,
                ],
            };
        }
        case Actions.UPDATE_EMAIL_TEMPLATE_DETAILS: {
            let updatedList = state.emailTemplatesData;
            const index = state.emailTemplatesData.findIndex(
                (obj) => obj.id === action.payload.id
            );
            if (index !== -1) updatedList[index] = action.payload;
            return {
                ...state,
                emailTemplatesData: updatedList,
            };
        }
        case Actions.GET_EMAIL_LIST: {
            return {
                ...state,
                emailTemplatesData: action.payload?.data,
                emailTemPagination: {
                    ...state.emailTemPagination,
                    total: action?.payload?.total,
                    lastPage: action?.payload?.last_page,
                    currentPage: action?.payload?.current_page,
                },
            };
        }
        case Actions.GET_EMAIL_DETAILS: {
            return {
                ...state,
                emailDetails: action.payload,
            };
        }
        case Actions.GET_USER_LIST: {
            return {
                ...state,
                assignUserData: action.payload,
            };
        }
        case Actions.GET_STATUS_LIST: {
            return {
                ...state,
                emailStatusData: action.payload,
            };
        }
        case Actions.GET_EMAIL_LOG_LIST: {
            return {
                ...state,
                emailLogData: action.payload,
            };
        }
        case Actions.SEND_EMAILS_TEMPLATES: {
            return {
                ...state,
                emailTemplatesData: [
                    action.payload,
                    ...state.emailTemplatesData,
                ],
            };
        }
        case Actions.DELETE_EMAIL: {
            let emailList = state.emailTemplatesData.filter(
                (obj) => obj.id !== action.payload
            );
            return {
                ...state,
                emailTemplatesData: emailList,
            };
        }
        case Actions.SET_EMAIL_PER_PAGE: {
            return {
                ...state,
                emailTemPagination: {
                    ...state.emailTemPagination,
                    listViewLimit: action?.payload,
                },
            };
        }
        case Actions.SET_CURRENT_PAGE: {
            return {
                ...state,
                emailTemPagination: {
                    ...state.emailTemPagination,
                    currentPage: action.payload?.data,
                },
            };
        }
        default: {
            return state;
        }
    }
};
export default EmailTemplateReducer;
