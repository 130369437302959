import React from "react";
import Grid from "@mui/material/Grid";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import StaticNavbar from "./StaticNavbar";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import AddIcon from "../../../assets/Images/Icons/AddIcon.png";
import vector from "../../../assets/Images/Icons/Vector.svg";
import TableCell from "@mui/material/TableCell";
import FormControlLabel from "@mui/material/FormControlLabel";
import TableRow from "@mui/material/TableRow";
import "../../../assets/styles/Container/ComplianceWorkflow.scss";
import CheckboxComponents from "../../components/Checkbox/CheckboxComponent";
import TableComponent from "../../components/Table/TableComponent";
import { connect } from "react-redux";
function ComplianceTable(props) {
    const { primary, secondary, tertiary, userLoggedIn  } = props;
    const tableRows = [
        {
            id: 1,
            contractor: "User1",
            state: "Connecticut",
            federalCircuit: "Second Circuit",
            collarColor: "Black",
            CBE: "Testing",
            assignTo: "Controller",
            assignedTime: "01-25-2023 21:44",
        },
        {
            id: 2,
            contractor: "User2",
            state: "Connecticut",
            federalCircuit: "Third Circuit",
            collarColor: "Red",
            CBE: "Testing 1",
            assignTo: "Controller",
            assignedTime: "01-25-2023 21:44",
        },
        {
            id: 3,
            contractor: "User3",
            state: "Connecticut",
            federalCircuit: "Second Circuit",
            collarColor: "Pink",
            CBE: "Testing 2",
            assignTo: "Controller",
            assignedTime: "01-25-2023 21:44",
        },
        {
            id: 4,
            contractor: "User4",
            state: "Connecticut",
            federalCircuit: "Second Circuit",
            collarColor: "Blue",
            CBE: "Testing 3",
            assignTo: "Controller",
            assignedTime: "01-25-2023 21:44",
        },
        {
            id: 5,
            contractor: "User5",
            state: "Connecticut",
            federalCircuit: "First Circuit",
            collarColor: "Red",
            CBE: "Testing 6",
            assignTo: "Controller",
            assignedTime: "01-25-2023 21:44",
        },
    ];

    const headerList = [
        {
            name: userLoggedIn?.language_data?.complianceworkflow?.contractor || "Contractor",
            headerClass: "font_20_600",
            accessibleKey: "contractor",
            rowClass: "typo4",
            headerAlign: "center",
            rowAlign: "left",
        },
        {
            name: userLoggedIn?.language_data?.complianceworkflow?.state || "State",
            headerClass: "font_20_600",
            accessibleKey: "state",
            rowClass: "typo4",
            headerAlign: "center",
            rowAlign: "left",
        },
        {
            name: userLoggedIn?.language_data?.complianceworkflow?.federal_circuit || "Federal Circuit",
            headerClass: "font_20_600",
            accessibleKey: "federalCircuit",
            rowClass: "typo4",
            headerAlign: "center",
            rowAlign: "left",
        },
        {
            name: userLoggedIn?.language_data?.complianceworkflow?.collar_color || "Collor Color",
            headerClass: "font_20_600",
            accessibleKey: "collarColor",
            rowClass: "typo4",
            headerAlign: "center",
            rowAlign: "left",
        },
        {
            name: "CBE",
            headerClass: "font_20_600",
            accessibleKey: "CBE",
            rowClass: "typo4",
            headerAlign: "center",
            rowAlign: "left",
        },
        {
            name: "Assign To",
            headerClass: "font_20_600",
            accessibleKey: "assignTo",
            rowClass: "typo4",
            headerAlign: "center",
            rowAlign: "left",
        },
        {
            name: "Assigned Time",
            headerClass: "font_20_600",
            accessibleKey: "assignedTime",
            rowClass: "typo4",
            headerAlign: "center",
            rowAlign: "left",
        },
    ];
    const value = 0;
    return (
        <>
            <StaticNavbar
                primary={primary}
                secondary={secondary}
                tertiary={tertiary}
            />
            <Grid
                container
                borderRadius={2}
                sx={{
                    backgroundColor: tertiary,
                    marginTop: "20px",
                    width: "100%",
                }}
            >
                <Grid item md={12}>
                    <Tabs
                        sx={{ transform: "scale(0.9)" }}
                        textColor="inherit"
                        variant="standard"
                    >
                        {[
                            userLoggedIn?.language_data?.complianceworkflow?.personal_info || "Unassigned Contractors",
                            "In Progress Contractor",
                            userLoggedIn?.language_data?.complianceworkflow?.pcp_compliant_contractor || "PCP Compliant Contractor",
                            userLoggedIn?.language_data?.complianceworkflow?.all_contractor || "All Contractor",
                        ].map((obj, index) => (
                            <Tab
                                sx={{
                                    textAlign: "left",
                                    padding: 1,
                                    width: "24.9%",
                                    backgroundColor:
                                        value === index ? tertiary : primary,
                                }}
                                label={obj}
                                className={"labelTabStyle"}
                            />
                        ))}
                    </Tabs>
                </Grid>
                <Grid item md={12} lg={12}>
                    <Grid container>
                        <Grid item md={12} sx={{ transform: "scale(0.9)" }}>
                            <Box
                                display={"flex"}
                                flexDirection={"row"}
                                justifyContent={"flex-start"}
                            >
                                <Button variant="text" sx={{ mr: 4 }}>
                                    <img
                                        src={vector}
                                        alt="vector"
                                        className="vectorStyle"
                                    />
                                </Button>
                                <Button
                                    sx={{
                                        "&:hover": {
                                            backgroundColor: primary,
                                            color: tertiary,
                                        },
                                        backgroundColor: primary,
                                        color: tertiary,
                                        ml: 4,
                                        paddingInline: "30px !important",
                                        height: "37px",
                                        marginTop: "11px",
                                        textTransform: "capitalize",
                                        fontWeight: "600",
                                        fontSize: "14px",
                                    }}
                                >
                                    {userLoggedIn?.language_data?.complianceworkflow?.assign || "Assign"}
                                </Button>
                                <Button
                                    className="labelButton"
                                    sx={{
                                        "&:hover": {
                                            backgroundColor: "transparent",
                                        },
                                        color: primary,
                                        backgroundColor: tertiary,
                                        border: `1px solid ${primary}`,
                                        paddingInline: "30px !important",
                                    }}
                                >
                                    {userLoggedIn?.language_data?.complianceworkflow?.reject || "Reject"}
                                </Button>
                                <Button
                                    sx={{
                                        "&:hover": {
                                            backgroundColor: primary,
                                            color: tertiary,
                                        },
                                        backgroundColor: primary,
                                        color: tertiary,
                                        position: "sticky",
                                        left: "1090px",
                                        height: "37px",
                                        marginTop: "10px",
                                        textTransform: "capitalize",
                                        fontWeight: "600",
                                        fontSize: "14px",
                                    }}
                                >
                                    <img
                                        className="image"
                                        src={AddIcon}
                                        alt="AddIcon"
                                    />
                                    {userLoggedIn?.language_data?.complianceworkflow?.new_contractor || "New Contractor"}
                                </Button>
                            </Box>
                        </Grid>
                        <Grid
                            item
                            md={12}
                          
                        >
                            {tableRows?.length !== 0 && (
                                <TableComponent
                                headSx={{
                                    backgroundColor: tertiary,
                                }}
                                    headerList={headerList}
                                    tableClass={"font_18_600"}
                                    sx={{
                                        transform: "scale(0.9)",
                                        backgroundColor: tertiary,
                                    }}
                                    extraHeaderLeft={
                                        <TableCell
                                            className="font_18_600 "
                                            sx={{
                                                backgroundColor: tertiary,
                                            }}
                                        >
                                            <FormControlLabel
                                                control={<CheckboxComponents />}
                                                labelPlacement="Select"
                                            ></FormControlLabel>
                                        </TableCell>
                                    }
                                >
                                    {tableRows.map((row, index) => (
                                        <TableRow
                                            key={row.name}
                                            style={{
                                                background:
                                                    index % 2 === 0
                                                        ? tertiary
                                                        : "",
                                            }}
                                        >
                                            <TableCell
                                                className="font_18_600"
                                                sx={{
                                                    backgroundColor: tertiary,
                                                }}
                                            >
                                                <FormControlLabel
                                                    value={row?.id}
                                                    control={
                                                        <CheckboxComponents />
                                                    }
                                                    labelPlacement="Select"
                                                ></FormControlLabel>
                                            </TableCell>
                                            <TableCell
                                                className="labelTypo1"
                                                sx={{
                                                    backgroundColor: tertiary,
                                                }}
                                                align="center"
                                            >
                                                {row?.contractor}
                                            </TableCell>
                                            <TableCell
                                                className="font_18_400"
                                                sx={{
                                                    backgroundColor: tertiary,
                                                }}
                                                align="center"
                                            >
                                                {row.state}
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                                className="font_18_400"
                                                sx={{
                                                    backgroundColor: tertiary,
                                                }}
                                            >
                                                {row.federalCircuit}
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                                className="font_18_400"
                                                sx={{
                                                    backgroundColor: tertiary,
                                                }}
                                            >
                                                {row.collarColor}
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                                className="font_18_400"
                                                sx={{
                                                    backgroundColor: tertiary,
                                                }}
                                            >
                                                {row.CBE}
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                                className="font_18_400"
                                                sx={{
                                                    backgroundColor: tertiary,
                                                }}
                                            >
                                                {row.assignTo}
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                                className="font_18_400"
                                                sx={{
                                                    backgroundColor: tertiary,
                                                }}
                                            >
                                                {row.assignedTime}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableComponent>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,
    };
};


export default connect(mapStateToProps, null) (ComplianceTable);
