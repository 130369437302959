import React from "react";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Translate } from "react-auto-translate";

const DialogComponent = (props) => {
    const {
        open,
        title,
        content,
        action,
        handleClose,
        contentClass,
        actionClass,
        sxContent,
        maxWidth,
        sx,
        className,
        TransitionComponent,
        sxaction,
        titleContent,
        ariaLabelledby,
        ariaDescribedby,
        direction,
        isShowContentArrenge,
        id,
    } = props;
    // const pointsArray =
    //     isShowContentArrenge && content && content.split("." && "\n");
    return (
        <div>
            <Dialog
                direction={direction || ""}
                open={open || false}
                onClose={handleClose}
                maxWidth={maxWidth}
                sx={sx}
                className={className}
                aria-labelledby={ariaLabelledby || null}
                aria-describedby={ariaDescribedby || null}
                TransitionComponent={TransitionComponent}
                id={id}
            >
                {title ? (
                    <DialogTitle
                        className={titleContent || ""}
                        sx={{ textTransform: "capitalize" }}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={8}>
                                <Typography className="font_20_600">
                                        {title}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <IconButton
                                    aria-label="close"
                                    onClick={() => handleClose()}
                                    className="closeButton"
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </DialogTitle>
                ) : null}
                <DialogContent
                    className={contentClass || ""}
                    sx={sxContent || ""}
                >
                    {isShowContentArrenge ? (
                             <span
                            className="font_16_600"
                            dangerouslySetInnerHTML={{
                                __html: content,
                            }}
                        />
                    ) : (
                            content
                    )}
                </DialogContent>
                <DialogActions
                    sx={sxaction || ""}
                    className={actionClass || ""}
                >
                   
                         {action}
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default DialogComponent;
