import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link, useNavigate } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Navbar from "../../common/Header/Navbar";
import { LoginSvg } from "../../../assets/styles/Svg";
import {
    performLoginAction,
    getClientById,
    verifyOtp,
} from "../../store/Action/LoginAction";
import "../../../assets/styles/Container/Login.scss";
import ButtonComponent from "./../../components/ButtonComponent";
import { ROUTES } from "../../router/RouteList";
import LoadingComponent from "../../components/Loading/LoadingComponent";
import TextFieldComponent from "../../components/TextField/TextFieldComponent";
import { getWorkersProgressList } from "../../store/Action/ComplianceWorkflowAction";

const roles = [
    { name: "Super Admin", id: 3, routs: ROUTES.USER },
    { name: "Manager ('M')", id: 44, routs: ROUTES.USER },
    { name: "Contractor", id: 2, routs: ROUTES.USER },
];

function Login(props) {
    const navigate = useNavigate();
    const [state, setState] = useState({
        email: "",
        password: "",
        showPassword: false,
        isLoading: false,
        twoFactor: false,
        otp: "",
        logging: false,
    });
    const {
        performLoginAction,
        whiteLabel,
        userLoggedIn,
        getClientById,
        getWorkersProgressList,
        workflowWorkersList,
        verifyOtp,
        isOtpVerification,
    } = props;
    const {
        email,
        password,
        showPassword,
        isLoading,
        twoFactor,
        otp,
        logging,
    } = state;
    useEffect(() => {
        if (isOtpVerification == 200) {
            handleSubmit();
        }
    }, [isOtpVerification]);
    async function handleSubmit(event) {
        event?.preventDefault();
        setState((prevState) => ({ ...prevState, logging: true }));
        const res = await performLoginAction(email, password);
        setState((prevState) => ({ ...prevState, logging: false }));
        const workersList = await getWorkersProgressList(
            res.payload.user.client_id,
            "rcp"
        );
        setState((prevState) => ({ ...prevState, twoFactor: false }));
        localStorage.setItem("role_id", res.payload.user.role_id);
        // if (isOtpVerification == 200) {
        if (res.payload.user.role_id == 1) {
            navigate(ROUTES.DEFAULT_PAGE);
        } else {
            if (res.payload.user.client_id) {
                let selectedRole;
                roles.forEach(async (element, index) => {
                    if (res?.payload.user?.role_id === element.id) {
                        selectedRole = element;
                    }
                });
                if (selectedRole) {
                    const clientID = await getClientById(
                        res?.payload?.user?.client_id
                    );
                    if (selectedRole.id == 2) {
                        let findUser = await workersList.payload.find(
                            (obj) =>
                                obj.user.id === res.payload.user.id &&
                                obj.user.mode === res.payload.user.mode
                        );
                        console.log("findUser", findUser);
                        let findTask =
                            findUser &&
                            findUser.tasks.find((obj) => obj.action === true);
                        if (
                            findTask &&
                            (findTask?.slug === "rcp_contractor" ||
                                findTask?.slug === "rcp_contractor_retest")
                        ) {
                            localStorage.setItem("task_id", findTask?.id);
                            localStorage.setItem("mode", findTask?.mode);
                            navigate(
                                `${ROUTES.CUSTOMERS_INFORMATION}/${clientID.result.guid}/${res.payload.user.id}`,
                                {
                                    state: {
                                        task_id: findTask?.id,
                                        mode: findTask?.mode,
                                    },
                                }
                            );
                        } else {
                            navigate(
                                `${ROUTES.CUSTOMERS_INFORMATION}/${clientID.result.guid}/${res.payload.user.id}`,
                                {
                                    state: {
                                        mode: res.payload.user.mode,
                                    },
                                }
                            );
                        }
                    } else {
                        if (localStorage.getItem("role_id") == 44) {
                            navigate(ROUTES.COMPLIANCE_WORKFLOW);
                        } else {
                            clientID.result.client_type == "dc"
                                ? navigate(ROUTES.USER)
                                : navigate(ROUTES.DEFAULT_PAGE);
                        }
                    }
                } else {
                    navigate(ROUTES.PROFILE);
                }
            } else {
                navigate(ROUTES.PROFILE);
            }
        }
        // }
        setState((prevState) => ({ ...prevState, logging: false }));
    }

    async function otpVerification(event) {
        event?.preventDefault();
        setState((prevState) => ({ ...prevState, isLoading: true }));
        const res = await verifyOtp(email, otp);
        localStorage.setItem("role_id", res?.payload?.user?.role_id);
        if (res) {
            setState((prevState) => ({ ...prevState, twoFactor: true }));
        }
        setState((prevState) => ({ ...prevState, isLoading: false }));
    }

    const today = new Date();
    const year = today.getFullYear();

    return (
        <Fragment>
            <Navbar />
            {whiteLabel?.client_id === undefined || isLoading ? (
                <LoadingComponent sx={{ height: "600px" }} />
            ) : (
                <div className="loginPage">
                    <Grid container>
                        <Grid item xs={6} className="loginImage">
                            <LoginSvg />
                        </Grid>
                        <Grid
                            item
                            className="loginGrid"
                            xs={12}
                            sm={12}
                            md={12}
                            lg={6}
                        >
                            <Grid container spacing={2} className="loginMargin">
                                <Grid item xs={12} className="loginGrid">
                                    <Paper className="loginCard">
                                        <form
                                            onSubmit={(event) =>
                                                handleSubmit(event)
                                            }
                                        >
                                            <Grid
                                                container
                                                className="loginGrid"
                                                spacing={2}
                                            >
                                                <Grid
                                                    item
                                                    className="loginGrid"
                                                    xs={12}
                                                >
                                                    <Typography className="loginHeading">
                                                        {twoFactor
                                                            ? "Verification Code"
                                                            : "Login"}
                                                    </Typography>
                                                </Grid>
                                                {twoFactor ? (
                                                    <>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            className="textfieldGrid"
                                                        >
                                                            <TextFieldComponent
                                                                onChange={(
                                                                    event
                                                                ) =>
                                                                    setState(
                                                                        (
                                                                            prevState
                                                                        ) => ({
                                                                            ...prevState,
                                                                            otp: event
                                                                                .target
                                                                                .value,
                                                                        })
                                                                    )
                                                                }
                                                                id="loginOtp"
                                                                variant="standard"
                                                                label={"Otp"}
                                                                className="loginTextfield"
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                required
                                                            />
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            className="btnGrid"
                                                            xs={12}
                                                        >
                                                            <ButtonComponent
                                                                title={"Submit"}
                                                                variant="contained"
                                                                sx={{
                                                                    padding:
                                                                        "12px",
                                                                }}
                                                                onClick={(
                                                                    event
                                                                ) =>
                                                                    otpVerification(
                                                                        event
                                                                    )
                                                                }
                                                            />
                                                        </Grid>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            className="textfieldGrid"
                                                        >
                                                            <TextFieldComponent
                                                                onChange={(
                                                                    event
                                                                ) =>
                                                                    setState(
                                                                        (
                                                                            prevState
                                                                        ) => ({
                                                                            ...prevState,
                                                                            email: event
                                                                                .target
                                                                                .value,
                                                                        })
                                                                    )
                                                                }
                                                                id="loginEmailAddress"
                                                                variant="standard"
                                                                label={
                                                                    "Email Address"
                                                                }
                                                                className="loginTextfield"
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                required
                                                                value={email}
                                                            />
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            className="textfieldGrid"
                                                        >
                                                            <TextFieldComponent
                                                                onChange={(
                                                                    event
                                                                ) =>
                                                                    setState(
                                                                        (
                                                                            prevState
                                                                        ) => ({
                                                                            ...prevState,
                                                                            password:
                                                                                event
                                                                                    .target
                                                                                    .value,
                                                                        })
                                                                    )
                                                                }
                                                                id="loginPassword"
                                                                required
                                                                variant="standard"
                                                                label="Password"
                                                                autoComplete="new-password"
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                type={
                                                                    showPassword
                                                                        ? "text"
                                                                        : "password"
                                                                }
                                                                value={password}
                                                                className="loginTextfield"
                                                                InputProps={{
                                                                    endAdornment:
                                                                        (
                                                                            <InputAdornment position="end">
                                                                                <IconButton
                                                                                    onClick={() =>
                                                                                        setState(
                                                                                            (
                                                                                                prevState
                                                                                            ) => ({
                                                                                                ...prevState,
                                                                                                showPassword:
                                                                                                    !showPassword,
                                                                                            })
                                                                                        )
                                                                                    }
                                                                                    onMouseDown={(
                                                                                        event
                                                                                    ) =>
                                                                                        event.preventDefault()
                                                                                    }
                                                                                    edge="start"
                                                                                    className="showButton"
                                                                                >
                                                                                    {!showPassword ? (
                                                                                        <VisibilityOff />
                                                                                    ) : (
                                                                                        <Visibility />
                                                                                    )}
                                                                                </IconButton>
                                                                            </InputAdornment>
                                                                        ),
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            display={"flex"}
                                                            justifyContent={
                                                                "flex-end"
                                                            }
                                                        >
                                                            <Link
                                                                to={`/forgot-password`}
                                                                className="forgotBtn"
                                                            >
                                                                Forgot Password?
                                                            </Link>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            className="btnGrid"
                                                            xs={12}
                                                        >
                                                            <ButtonComponent
                                                                title={
                                                                    !logging ? (
                                                                        "LOGIN"
                                                                    ) : (
                                                                        <svg
                                                                            height={
                                                                                "26px"
                                                                            }
                                                                            version="1.1"
                                                                            id="L9"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            x="0px"
                                                                            y="0px"
                                                                            viewBox="0 0 100 100"
                                                                            enable-background="new 0 0 0 0"
                                                                        >
                                                                            <path
                                                                                fill="#fff"
                                                                                d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                                                                            >
                                                                                <animateTransform
                                                                                    attributeName="transform"
                                                                                    attributeType="XML"
                                                                                    type="rotate"
                                                                                    dur="1s"
                                                                                    from="0 50 50"
                                                                                    to="360 50 50"
                                                                                    repeatCount="indefinite"
                                                                                />
                                                                            </path>
                                                                        </svg>
                                                                    )
                                                                }
                                                                type="submit"
                                                                variant="contained"
                                                                sx={{
                                                                    padding:
                                                                        "12px",
                                                                }}
                                                                onClick={(
                                                                    event
                                                                ) =>
                                                                    handleSubmit(
                                                                        event
                                                                    )
                                                                }
                                                            />
                                                        </Grid>
                                                    </>
                                                )}
                                            </Grid>
                                        </form>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} className="loginGrid">
                                    <Typography className="year">
                                        {year} All Copyright Reserved By
                                    </Typography>
                                    <Link className="complianceBtn">
                                        {whiteLabel?.company_name || "Elevance"}
                                    </Link>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            )}
        </Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,
        isOtpVerification: state?.auth?.isValidOtp,
        auth: state.auth,
        whiteLabel: state.whiteLabel,
        workflowWorkersList: state.complianceWorkflow.workflowWorkersList,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            performLoginAction,
            getClientById,
            getWorkersProgressList,
            verifyOtp,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
