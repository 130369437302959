import { deleteApiCall, getApiCall } from "../../utils/Action";
import { toast } from "react-hot-toast";
import { API_CONSTANTS } from "../../config/ApiConstants";
export const ASSESSMENT_SETTING = "ASSESSMENT_SETTING";
export const ASSESSMENT_SUMMARY = "ASSESSMENT_SUMMARY";
export const START_TEST = "START_TEST";
export const DELETE_SELF_TEST = "DELETE_SELF_TEST";
export const SET_TEST_OVERVIEW_DATA = "SET_TEST_OVERVIEW_DATA";
export const UPDATE_REPRESSED_QUESTION = "UPDATE_REPRESSED_QUESTION";

export const getAssessmentSetting = () => {
    return async (dispatch) => {
        try {
            const response = await getApiCall(API_CONSTANTS.ASSESSMENT_SETTING);
            if (response?.status === 200) {
                return dispatch({
                    type: ASSESSMENT_SETTING,
                    payload: response?.data?.result,
                });
            } else {
                // toast.error(response?.data?.message);
            }
        } catch (error) {
            throw error;
        }
    };
};
export const getAssessmentSummary = (
    client_id,
    user_id,
    contractor_id = null,
    mode = null
) => {
    return async (dispatch) => {
        try {
            let parmas = {
                client_id: `${client_id}`,
                user_id: `${user_id}`,
                mode: `${mode}`,
            };
            if (contractor_id) {
                parmas["contractor_id"] = `${contractor_id}`;
            }
            const response = await getApiCall(
                API_CONSTANTS.GET_ASSESSMENT_SUMMARY,
                parmas
            );
            return dispatch({
                type: ASSESSMENT_SUMMARY,
                payload: response?.data?.result || [],
            });
        } catch (error) {
            throw error;
        }
    };
};
export const getStartTest = (id, guid_id, type, role_id, user_id, mode) => {
    return async (dispatch) => {
        try {
            const response = await getApiCall(
                API_CONSTANTS.GET_START_TEST + `/${id}`,
                {
                    guid: `${guid_id}`,
                    type: type,
                    role_id: role_id,
                    user_id: user_id,
                    mode: mode,
                }
            );
            return dispatch({
                type: START_TEST,
                payload: response?.data?.result || null,
            });
        } catch (error) {
            throw error;
        }
    };
};
export const deleteTest = (data, guid_id) => {
    return async (dispatch) => {
        try {
            const response = await deleteApiCall(
                API_CONSTANTS.SELF_CONFIGURATION_SETTING + `/${data?.id}`,
                {
                    guid: `${guid_id}`,
                }
            );
            if (response?.status === 200) {
                return dispatch({
                    type: DELETE_SELF_TEST,
                    payload: data,
                });
            }
        } catch (error) {
            throw error;
        }
    };
};
export const testOverViewData = (data) => {
    return async (dispatch) => {
        try {
            const response = await getApiCall(
                API_CONSTANTS.GET_TEST_OVERVIEW_RESULT,
                data
            );
            if (response?.status === 200) {
                return dispatch({
                    type: SET_TEST_OVERVIEW_DATA,
                    payload: response?.data?.result,
                });
            }
        } catch (error) {
            throw error;
        }
    };
};
export const updateRepressedQuestion = (data) => {
    console.log("copyQuestions updateRepressedQuestion", data);
    return async (dispatch) => {
        try {
            return dispatch({
                payload: data,
                type: UPDATE_REPRESSED_QUESTION,
            });
        } catch (error) {
            throw error;
        }
    };
};
