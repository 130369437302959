import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import ContactUsIamges from "../../assets/Images/Common/contact Us.png";
import ButtonComponent from "../components/ButtonComponent";

const GetDemoContactSection = () => {
    return (
        <Grid
            container
            justifyContent={"space-between"}
            alignItems={"center"}
            sx={{
                px: { md: 7, xs: 1 },
                backgroundColor: " rgba(0, 161, 157, 0.9)",
            }}
        >
            <Grid
                item
                xs={12}
                md={6}
                lg={6}
                xl={6}
                sx={{
                    pr: { md: "50px", xs: 0 },
                    padding: { md: 0, xs: "10px" },
                }}
            >
                <Typography className="mainHeading">
                    We encourage you to take a test drive.
                </Typography>
                <Typography className="mainWhiteSubHeading">
                    {`This is the compliance solution you have been waiting to find!`}
                </Typography>
                <Grid item gap={3} display={"flex"} sx={{ paddingTop: "25px" }}>
                    <Grid item>
                        <ButtonComponent title={"Get a Demo"} />
                    </Grid>
                    <Grid item>
                        <ButtonComponent
                            title={"Contact Us"}
                            className="button_outline_custom"
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                item
                xs={12}
                md={6}
                lg={6}
                xl={6}
                sx={{
                    display: "flex",
                    paddingY: "30px",
                    justifyContent: { xs: "center", md: "flex-end" },
                }}
            >
                <img src={ContactUsIamges} />
            </Grid>
        </Grid>
    );
};

export default GetDemoContactSection;
