import React from "react";
import StaticNavbar from "./StaticNavbar";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { LoginSvg } from "../../../assets/styles/Svg";
import ButtonComponent from "./../../components/ButtonComponent";
import { Link } from "react-router-dom";
import TextFieldComponent from "../../components/TextField/TextFieldComponent";
import { connect } from "react-redux";
function LoginPage(props) {
    const { primary, secondary, tertiary, userLoggedIn } = props;
    const today = new Date();
    const year = today.getFullYear();
    return (
        <>
            <StaticNavbar
                login={true}
                primary={primary}
                secondary={secondary}
                tertiary={tertiary}
            />
            <Grid container spacing={2}>
                <Grid item xs={6} className="labelLoginImage">
                    <LoginSvg />
                </Grid>
                <Grid item className="labelLoginGrid" xs={6}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Paper
                                className="labelLoginCard"
                                sx={{ backgroundColor: tertiary }}
                            >
                                <Grid container className="grid" spacing={2}>
                                    <Grid item className="grid" xs={12}>
                                        <Typography className="loginHeading">
                                            Login
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        className="textfieldGrid"
                                    >
                                        <TextFieldComponent
                                            id="loginEmailAddress"
                                            variant="standard"
                                            label={userLoggedIn?.language_data?.customer?.email_address || "Email Address"}
                                            className="loginTextfield"
                                            required
                                            value={"admin@gmail.com"}
                                            disabled={true}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        className="textfieldGrid"
                                    >
                                        <TextFieldComponent
                                            id="loginPassword"
                                            required
                                            variant="standard"
                                            label="Password"
                                            type={"password"}
                                            value={"password"}
                                            disabled={true}
                                            className="loginTextfield"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            edge="start"
                                                            className="showButton"
                                                        >
                                                            <VisibilityOff />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Link
                                            className="labelForgotBtn"
                                            style={{ color: primary }}
                                        >
                                            Forgot Password?
                                        </Link>
                                    </Grid>
                                    <Grid item className="btnGrid" xs={12}>
                                        <ButtonComponent
                                            id="loginButton"
                                            title={"LOGIN"}
                                            variant="contained"
                                            sx={{
                                                padding: "12px",
                                                backgroundColor: `${primary} !important`,
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} className="loginGrid">
                            <Typography className="year">
                                {year} All Copyright Reserved By
                            </Typography>
                            <Link
                                className="labelComplianceBtn"
                                style={{ color: secondary }}
                            >
                                Elevance
                            </Link>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,
        
    };
};



export default connect(mapStateToProps, null ) (LoginPage);
