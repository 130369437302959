import { Grid, MenuItem, TableCell, TableRow, Typography } from "@mui/material";
import { Fragment, useState } from "react";
import DropDownComponent from "../DropDown/DropDownComponent";
import TableComponent from "../Table/TableComponent";
import CheckboxComponents from "../Checkbox/CheckboxComponent";
import ButtonComponent from "../ButtonComponent";
import TooltipComponent from "../ToolTip/TooltipComponent";
import DialogComponent from "./DialogComponent";

const MqsResponseDialog = (props) => {
    const { options, open, handleClose, handleOnClick, id } = props;

    const [state, setState] = useState({
        responses: [],
    });

    const headers = [
        {
            name: "Trigger",
            headerClass: "tableHeading",
            headerAlign: "center",
        },
        {
            name: "Response",
            headerClass: "tableHeading",
            headerAlign: "center",
        },
        {
            name: "Score",
            headerClass: "tableHeading",
            headerAlign: "center",
        },
    ];

    const inputOnChange = (event, index) => {
        const responseArray = [...state.responses];
        if (event.target.name === "trigger") {
            responseArray[index][event.target.name] = event.target.checked
                ? "1"
                : "0";
        } else {
            responseArray[index][event.target.name] = event.target.value;
        }
        setState((previous) => {
            return {
                ...previous,
                responses: responseArray,
            };
        });
    };

    const onChange = (event) => {
        const responseArray = options?.find(
            (item) => item?.id === event?.target?.value
        )["options"];
        const responses = responseArray?.map((item) => {
            return {
                response: item,
                trigger: "1",
                score: 0,
            };
        });
        setState((previous) => {
            return {
                ...previous,
                responses: responses,
            };
        });
    };

    const handleShow = () => {
        setState((previous) => {
            return {
                ...previous,
                show: !previous.show,
            };
        });
    };

    return (
        <Fragment>
            <DialogComponent
                id={id}
                open={open}
                fullWidth={true}
                handleClose={handleClose}
                className="dialog"
                sx={{
                    height: "auto",
                    maxHeight: "600px",
                    borderRadius: "15px",
                }}
                title={`Responses`}
                content={
                    <>
                        <Grid container spacing={2} mt={1}>
                            <Grid item sm={12} md={12} lg={12}>
                                <DropDownComponent
                                    id={id}
                                    fullWidth={true}
                                    label="Responses"
                                    onChange={onChange}
                                    onOpen={handleShow}
                                    onClose={handleShow}
                                >
                                    {options?.map((option, index) => {
                                        const title = option?.options?.map(
                                            (item, index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        id={`${id} ${index}`}
                                                    >
                                                        {item}
                                                    </div>
                                                );
                                            }
                                        );
                                        return (
                                            <MenuItem
                                                key={option?.id}
                                                value={option?.id}
                                                sx={{ display: "block" }}
                                            >
                                                <TooltipComponent
                                                    title={title}
                                                    arrow={true}
                                                    placement="right"
                                                >
                                                    <Typography
                                                        className="font_16_600"
                                                        variant="span"
                                                    >
                                                        {"Response Option " +
                                                            option?.id}
                                                    </Typography>
                                                </TooltipComponent>
                                            </MenuItem>
                                        );
                                    })}
                                </DropDownComponent>
                            </Grid>
                            {state.responses.length > 0 && (
                                <Grid item sm={12} md={12} lg={12}>
                                    <TableComponent headerList={headers}>
                                        {state.responses?.map((row, index) => (
                                            <TableRow key={row?.response}>
                                                <TableCell
                                                    align="center"
                                                    className="typo4"
                                                >
                                                    <CheckboxComponents
                                                        checked={
                                                            row?.trigger === "1"
                                                                ? true
                                                                : false
                                                        }
                                                        name="trigger"
                                                        onChange={(event) => {
                                                            inputOnChange(
                                                                event,
                                                                index
                                                            );
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell
                                                    align="center"
                                                    className="typo4"
                                                >
                                                    {row?.response}
                                                </TableCell>
                                                <TableCell
                                                    align="center"
                                                    className="typo4"
                                                >
                                                    <input
                                                        type="number"
                                                        max={10}
                                                        min={-10}
                                                        value={row?.score}
                                                        className="inputText font_16_600"
                                                        name="score"
                                                        onChange={(event) => {
                                                            inputOnChange(
                                                                event,
                                                                index
                                                            );
                                                        }}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableComponent>
                                </Grid>
                            )}
                        </Grid>
                    </>
                }
                sxaction={{ paddingRight: 3 }}
                action={
                    <>
                        <ButtonComponent
                            title={"Cancel"}
                            onClick={() => {
                                handleClose();
                                setState((previous) => {
                                    return { ...previous, responses: [] };
                                });
                            }}
                            variant={"outlined"}
                        />
                        <ButtonComponent
                            title={"Add"}
                            onClick={() => {
                                handleOnClick(state.responses);
                                setState((previous) => {
                                    return { ...previous, responses: [] };
                                });
                            }}
                            variant={"button_contained"}
                        />
                    </>
                }
            />
        </Fragment>
    );
};

export default MqsResponseDialog;
