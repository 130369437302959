import * as Actions from "../Action/WhiteLabelAction";

const initialState = {
    domain: "localhost",
    client_id: undefined,
    logo: null,
    favicon: null,
    color_scheme: {
        primary: "#f26a21",
        secondary: "#00a19d",
        // tertiary: "#fff8e5",
        tertiary: "#F2F2F2",
    },
    selectedWhiteLabel: null,
    guid: null,
};

const WhiteLabelReducer = (state = initialState, action) => {
    switch (action?.type) {
        case Actions.GET_WHITE_LABEL_INFO: {
            return {
                ...state,
                ...action.payload,
            };
        }
        case Actions.GET_WHITE_LABEL_BY_ID: {
            return { ...state, selectedWhiteLabel: action.payload };
        }
        default: {
            return state;
        }
    }
};

export default WhiteLabelReducer;
