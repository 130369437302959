import React, { useEffect, useState } from "react";
import {
    Grid,
    Typography,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TableFooter,
    TableContainer,
    IconButton,
    Paper,
    Checkbox,
} from "@mui/material";
import { ReactComponent as DeleteIcon } from "../../../assets/Images/Icons/DeleteIcon.svg";
import { ReactComponent as EditIcon } from "../../../assets/Images/Icons/EditIcon.svg";
import ButtonComponent from "../ButtonComponent";

const QuestionScoreTableComponent = (props) => {
    const {
        tableData,
        handleTableDataState,
        name,
        readOnly,
        showResponseDialog,
        responses,
    } = props;
    let format = {
        trigger: "1",
        response: "True",
        score: 0,
    }
    useEffect(()=>{
        format = responses?.length > 0 ? responses[0] : {
            trigger: "1",
            response: "True",
            score: 0,
        };
    }, [responses?.length])
    const [isEditArr, setEdit] = useState([]);
    const handleValueChange = (event, index) => {
        if (tableData?.length > 0) {
            const newArr = [...tableData];
            if (event.target.name === "trigger") {
                newArr[index][event.target.name] = event.target.checked
                    ? "1"
                    : "0";
            } else {
                newArr[index][event.target.name] = event.target.value;
            }
            handleTableDataState(name, newArr);
        }
    };

    const switchToEditMode = (event, state, index = null) => {
        event.stopPropagation();
        if (index === null) {
            const editArr = isEditArr?.map((item) => false);
            setEdit(editArr);
        } else {
            const editArr = [...isEditArr];
            editArr[index] = state;
            setEdit(editArr);
        }
    };

    const handleResponseChange = (event, index) => {
        const newResponse = responses?.find((item) => {
            return item?.response === event?.target?.value;
        });
        const newArr = [...tableData];
        newArr[index] = newResponse;
        handleTableDataState(name, newArr);
    };

    return (
        <TableContainer
            component={Paper}
            sx={{
                borderRadius: "5px",
                boxShadow: "0px 0px 10px 4px rgb(0 0 0 / 10%)",
            }}
            className="tableClass"
        >
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell
                            align="center"
                            className="tableHeading"
                            onClick={(event) => {
                                switchToEditMode(event, false);
                            }}
                        >
                            <Typography className="font_16_600">
                                Trigger Remediation
                            </Typography>
                        </TableCell>
                        <TableCell
                            align="center"
                            onClick={(event) => {
                                switchToEditMode(event, false);
                            }}
                            className="tableHeading"
                        >
                            <Typography className="font_16_600">
                                Responses
                            </Typography>
                        </TableCell>
                        <TableCell
                            align="center"
                            className="tableHeading"
                            onClick={(event) => {
                                switchToEditMode(event, false);
                            }}
                        >
                            <Typography className="font_16_600">
                                Score
                            </Typography>
                        </TableCell>
                        {!readOnly && (
                            <TableCell
                                align="center"
                                className="tableHeading"
                                onClick={(event) => {
                                    switchToEditMode(event, false);
                                }}
                            >
                                <Typography className="font_16_600">
                                    Action
                                </Typography>
                            </TableCell>
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tableData?.map((row, index) => {
                        return (
                            <TableRow
                                key={index}
                                className={
                                    index % 2 !== 0 ? "tertiaryBackground" : ""
                                }
                            >
                                <TableCell
                                    align="center"
                                    width={readOnly ? 400 : null}
                                    onClick={(event) => {
                                        switchToEditMode(event, false, index);
                                    }}
                                >
                                    <Checkbox
                                        type="checkbox"
                                        name="trigger"
                                        value={row?.trigger}
                                        defaultChecked={false}
                                        readOnly={readOnly}
                                        checked={
                                            row?.trigger === "1" ? true : false
                                        }
                                        onClick={(e) => e.stopPropagation()}
                                        onChange={(event) => {
                                            if (isEditArr[index]) {
                                                handleValueChange(event, index);
                                            }
                                        }}
                                    />
                                </TableCell>
                                <TableCell
                                    align="center"
                                    onClick={(event) => {
                                        switchToEditMode(event, false, index);
                                    }}
                                >
                                    {isEditArr[index] ? (
                                        name === "available_response" ? (
                                            <input
                                                type="text"
                                                value={row?.response}
                                                name="response"
                                                className="font_16_600 inputText"
                                                onClick={(e) =>
                                                    e.stopPropagation()
                                                }
                                                onChange={(event) =>
                                                    handleValueChange(
                                                        event,
                                                        index
                                                    )
                                                }
                                                readOnly={readOnly}
                                            />
                                        ) : (
                                            <select
                                                className="font_16_600 inputText"
                                                name="response"
                                                onClick={(e) =>
                                                    e.stopPropagation()
                                                }
                                                value={row?.response}
                                                onChange={(event) =>
                                                    handleResponseChange(
                                                        event,
                                                        index
                                                    )
                                                }
                                                readOnly={readOnly}
                                            >
                                                {responses?.map(
                                                    (item, index) => (
                                                        <option
                                                            value={
                                                                item?.response
                                                            }
                                                            key={index}
                                                            className="font_16_600"
                                                        >
                                                            {item?.response}
                                                        </option>
                                                    )
                                                )}
                                            </select>
                                        )
                                    ) : (
                                        <Typography
                                            className="font_16_600"
                                            variant="span"
                                        >
                                            {row?.response}
                                        </Typography>
                                    )}
                                </TableCell>
                                <TableCell
                                    align="center"
                                    onClick={(event) => {
                                        switchToEditMode(event, false);
                                    }}
                                >
                                    {isEditArr[index] ? (
                                        <input
                                            type="number"
                                            className="inputNumber font_16_600"
                                            min={0}
                                            name="score"
                                            value={row?.score}
                                            onChange={(event) =>
                                                handleValueChange(event, index)
                                            }
                                            onClick={(e) => e.stopPropagation()}
                                            readOnly={readOnly}
                                        />
                                    ) : (
                                        <Typography
                                            className="font_16_600"
                                            variant="span"
                                        >
                                            {row?.score}
                                        </Typography>
                                    )}
                                </TableCell>
                                {!readOnly && (
                                    <TableCell
                                        align="center"
                                        onClick={(event) => {
                                            switchToEditMode(event, false);
                                        }}
                                    >
                                        <Grid
                                            container
                                            direction={"row"}
                                            spacing={1}
                                            justifyContent="center"
                                        >
                                            <Grid item md={2}>
                                                <IconButton
                                                    onClick={(event) => {
                                                        switchToEditMode(
                                                            event,
                                                            true,
                                                            index
                                                        );
                                                    }}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                            </Grid>
                                            <Grid item md={2}>
                                                <IconButton
                                                    onClick={() => {
                                                        const tableArr = [
                                                            ...tableData,
                                                        ];
                                                        if (
                                                            tableArr?.length > 1
                                                        ) {
                                                            const newArr =
                                                                tableArr?.filter(
                                                                    (
                                                                        value,
                                                                        ind
                                                                    ) =>
                                                                        ind !==
                                                                        index
                                                                );
                                                            handleTableDataState(
                                                                name,
                                                                newArr
                                                            );
                                                        }
                                                    }}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    </TableCell>
                                )}
                            </TableRow>
                        );
                    })}
                </TableBody>
                {!readOnly && (
                    <TableFooter>
                        <TableRow>
                            <TableCell colSpan={3}></TableCell>
                            <TableCell align="center">
                                {
                                    name === "available_response" ? (<ButtonComponent title={tableData?.length > 0 ? "Edit" : "Add"} onClick={showResponseDialog}/>) : ( <ButtonComponent
                                        title="+"
                                        sx={{
                                            fontSize: "30px !important",
                                            borderRadius: "50%",
                                            minWidth: "0px",
                                            padding: "12px 18px",
                                            paddingInline: "18px !important",
                                        }}
                                        onClick={() => {
                                                const newArr = [...tableData];
                                                newArr.push(format);
                                                handleTableDataState(name, newArr);
                                            
                                        }}
                                    />)
                                }
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                )}
            </Table>
        </TableContainer>
    );
};

export default QuestionScoreTableComponent;
