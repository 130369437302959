import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Slide from "@mui/material/Slide";
import "../../../assets/styles/Container/AssignUser.scss";
import TextFieldComponent from "../TextField/TextFieldComponent";
import ButtonComponent from "../ButtonComponent";
import DialogComponent from "./DialogComponent";
import { getUserById } from "../../store/Action/UserAction";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

function AddWorkFlowDialog(props) {
    const {
        title,
        label,
        onChange,
        onClick,
        value,
        onClose,
        open,
        inputProps,
        isExist,
        id,
        userLoggedIn
    } = props;
    const [validationMessage, setValidationMessage] = useState(null);

    const handleOnClick = () => {
        if (value !== "") {
            onClick();
        } else {
            setValidationMessage(`Please add ${title} name`);
        }
        if (isExist) {
            setValidationMessage(`${title} name is already exits!`);
        }
    };

    const handleClose = () => {
        onClose(false);
        setValidationMessage(null);
    };

    return (
        <Box>
            <DialogComponent
                id={id}
                className="dialog-add"
                open={open}
                handleClose={handleClose}
                TransitionComponent={Transition}
                title={ userLoggedIn?.language_data?.comman?.add_btn || "Add " + title}
                content={
                    <>
                        <Grid container mt={1}>
                            <Grid item md={12}>
                                <TextFieldComponent
                                    id="task"
                                    label={
                                        (label !== null ? label : title) +
                                        " " +
                                        "Name"
                                    }
                                    name="task"
                                    value={value}
                                    onChange={(e) => {
                                        onChange(e);
                                    }}
                                    inputProps={inputProps}
                                />
                            </Grid>
                            {validationMessage && (
                                <Typography>{validationMessage}</Typography>
                            )}
                        </Grid>
                    </>
                }
                action={
                    <>
                        <ButtonComponent
                            title={userLoggedIn?.language_data?.comman?.cancel_btn}
                            onClick={handleClose}
                            variant={"outlined"}
                        />
                        <ButtonComponent
                            title={userLoggedIn?.language_data?.comman?.add_btn}
                            onClick={handleOnClick}
                            variant={"button_contained"}
                        />
                    </>
                }
            />
        </Box>
    );
}

const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,
    };
};
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getUserById,
        },
        dispatch
    );
};
export default connect(mapStateToProps, mapDispatchToProps) (AddWorkFlowDialog);
