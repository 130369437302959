import Grid from "@mui/material/Grid";
import { Fragment } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import DropDownComponent from "../../components/DropDown/DropDownComponent";
import USBasedTable from "./USBasedTable";
import TextFieldComponent from "../../components/TextField/TextFieldComponent";
import Checkbox from "../../components/Checkbox/CheckboxComponent";
import FormControlLabel from "@mui/material/FormControlLabel";
import "../../../assets/styles/Container/ScoringConfiguration.scss";
import ButtonComponent from "../../components/ButtonComponent";
import { getUserById } from "../../store/Action/UserAction";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
const USModifiers = (props) => {
    const {userLoggedIn} = props
    const handleSelectAll = (e) => {};

    return (
        <Fragment>
            <Grid container display="flex">
                <Grid container>
                    <Grid item md={12} xs={12} lg={4} mt={2} ml={8} xl={4}>
                        <Typography className="selectHeading">
                            Select Initiatives
                        </Typography>
                        <DropDownComponent fullWidth={true}>
                            {[
                                "Will Contactor perform services in the oil and Gas industry ?",
                                "Will Contactor perform services under Hazardous....",
                                "Will Contactor perform services under Hazardous....",
                                "Will Contactor perform services under Hazardous.... ",
                                "Will Contactor perform services under Hazardous....",
                                "Will Contactor perform services under Hazardous....",
                            ].map((obj) => (
                                <MenuItem
                                    value={obj}
                                    key={obj}
                                    className="dropDownText"
                                >
                                    {obj}
                                </MenuItem>
                            ))}
                        </DropDownComponent>
                        <Grid item md={4} lg={4} mt={5} mr={2}>
                            <Box className="boxFirst">
                                <Typography className="federal">
                                    Choose Responses
                                </Typography>
                                <Grid item md={12} paddingTop={7} paddingX={1}>
                                    <DropDownComponent fullWidth={true}>
                                        {[
                                            "Response 1",
                                            "Response 2",
                                            "Response 3",
                                        ].map((obj) => (
                                            <MenuItem
                                                value={obj}
                                                key={obj}
                                                className="dropDownText"
                                            >
                                                {obj}
                                            </MenuItem>
                                        ))}
                                    </DropDownComponent>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item md={6} lg={6} mt={2} ml={8}>
                        <Box className="boxSecond">
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography className="federal">
                                        Edit Initiatives
                                    </Typography>
                                </Grid>
                                <Grid item md={3} lg={10} mt={6} ml={1.5}>
                                    <Typography className="headingText">
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    onChange={handleSelectAll}
                                                />
                                            }
                                            labelPlacement="Select"
                                        ></FormControlLabel>
                                        Select whether question only applies if
                                        contractor is off site.
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    md={12}
                                    lg={12}
                                    xl={12}
                                    sm={12}
                                    xs={12}
                                    mt={6}
                                    ml={1.5}
                                    mr={2}
                                >
                                    <Typography className="questionHeading">
                                        Questions
                                    </Typography>
                                    <TextFieldComponent id="usModifiersQuestions" />
                                </Grid>
                            </Grid>
                            <Grid item md={12} xs={12} mt={2} mx={1.5}>
                                <USBasedTable />
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
                <Grid
                    item
                    lg={11}
                    md={5}
                    sm={3}
                    xs={4}
                    mt={3}
                    ml={8}
                    xl={6}
                    display="flex"
                    justifyContent="flex-end"
                    gap={"8px"}
                >
                    <ButtonComponent title={"Cancel"} variant="outlined" />

                    <ButtonComponent
                        sx={{ marginLeft: "20px" }}
                        title={userLoggedIn?.language_data?.comman?.save_btn || "Save"}
                        type="submit"
                        variant="contained"
                    />
                </Grid>
            </Grid>
        </Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,
    };
};
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getUserById,

        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps) (USModifiers);
