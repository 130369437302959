import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    updateContentLocalAction,
    updateContentGlobalAction,
} from "../../../../../store/Action/ContractAction";
import Navbar from "../../../../../common/Header/Navbar";
import Footer from "../../../../../common/Footer/Footer";
import "../../../../../../assets/styles/Common/_CommonStyle.scss";
import { Box, Grid, Typography } from "@mui/material";
import TopBar from "../../../../../common/TopBar";
import DocumentEditor from "../../../../../components/DocumentEditor/DocumentEditor";
import DocsImportUpload from "./DocsImportUpload";
import ButtonComponent from "../../../../../components/ButtonComponent";
import CheckboxComponents from "../../../../../components/Checkbox/CheckboxComponent";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import TextFieldComponent from "../../../../../components/TextField/TextFieldComponent";
import { submitSignedDocumentAction } from "../../../../../store/Action/SignedDocumentAction";
import LeftDocsSidebar from "../DocumentDatabase/LeftDocsSidebar";
import { useParams } from "react-router-dom";
import { getUserById } from "../../../../../store/Action/UserAction";
import LoadingComponent from "../../../../../components/Loading/LoadingComponent";

function Document(props) {
    let params = useParams();
    const {
        updateContentLocalAction,
        updateContentGlobalAction,
        documentDatabase,
        getUserById,
    } = props;

    const [state, setState] = useState({
        database_document: [],
        toggle: false,
        loading: false,
        selectedOption: "",
        topping: {
            state1: true,
            state2: false,
            state3: false,
        },
        accept: false,
        isChecked1: false,
        templateDetails: null,
    });

    const { toggle, topping, accept, loading, isChecked1, templateDetails } =
        state;

    useEffect(() => {
        async function callApi() {
            setState((prevState) => ({ ...prevState, loading: true }));
            await getUserById(params?.id);
            if (documentDatabase && documentDatabase.length > 0) {
                handleSelectedFile(documentDatabase[0]);
            }
            setState((prevState) => ({ ...prevState, loading: false }));
        }
        callApi();
    }, [params?.id]);

    const disabledBtnSx = { background: "rgba(59, 59, 59, 0.5) !important" };
    async function handleSelectedFile(template) {
        setState((prevState) => ({
            ...prevState,
            toggle: !toggle,
            templateDetails: template,
        }));
    }

    const onOptionChange = (e) => {
        setState((prevState) => ({
            ...prevState,
            topping: { [e.target.name]: true },
            accept: { ...accept, [e.target.name]: true },
            isChecked1: false,
            selectedOption: e.target.value,
        }));
    };
    const handleCheckboxChange1 = (checked) => {
        setState((prevState) => ({
            ...prevState,
            accept: true,
            isChecked1: checked,
        }));
    };

    return (
        <>
            <Navbar />
            {loading ? (
                <LoadingComponent sx={{ height: "600px" }} />
            ) : (
                <Box>
                    <DocsImportUpload
                        title={"Documents Repository"}
                        templates={documentDatabase?.documentDatabase}
                        toggle={toggle}
                        setToggle={(toggle) =>
                            setState((prevState) => ({
                                ...prevState,
                                toggle: true,
                            }))
                        }
                    />
                    <TopBar title={"Document"} />
                    <Grid container rowSpacing={1}>
                        <Grid item xs={3}>
                            <LeftDocsSidebar
                                setToggle={(toggle) =>
                                    setState((prevState) => ({
                                        ...prevState,
                                        toggle: true,
                                    }))
                                }
                                toggle={toggle}
                                templates={documentDatabase}
                                handleSelectedFile={handleSelectedFile}
                                documentDatabase={documentDatabase}
                            />
                        </Grid>
                        {templateDetails && (
                            <Grid item xs={8}>
                                <FormControl sx={{ marginLeft: "40px" }}>
                                    <FormLabel
                                        sx={{
                                            marginTop: "10px",
                                            color: "#3B3B3B !important",
                                            fontFamily: "Source Sans Pro",
                                            fontStyle: "normal",
                                            fontWeight: 600,
                                            fontSize: "20px",
                                        }}
                                    >
                                        Upload Status Option are:
                                    </FormLabel>
                                    <RadioGroup>
                                        <FormControlLabel
                                            value="Regular"
                                            name="state1"
                                            id="regular"
                                            onChange={onOptionChange}
                                            control={<Radio />}
                                            checked={topping.state1}
                                            label={
                                                <span
                                                    style={{ fontSize: "17px" }}
                                                >
                                                    Click here to upload
                                                </span>
                                            }
                                        />
                                        <span>
                                            <FormControlLabel
                                                value="Medium"
                                                name="state2"
                                                id="medium"
                                                onChange={onOptionChange}
                                                checked={topping.state2}
                                                control={<Radio />}
                                                label={
                                                    <span
                                                        style={{
                                                            fontSize: "17px",
                                                            linHeight: "23px",
                                                        }}
                                                    >
                                                        Cannot Fullfill Request
                                                        to Upload Because{" "}
                                                        {
                                                            templateDetails?.cannot_fill_request_reason
                                                        }
                                                    </span>
                                                }
                                            />
                                        </span>
                                        <span>
                                            <FormControlLabel
                                                value="Large"
                                                name="state3"
                                                checked={topping.state3}
                                                onChange={onOptionChange}
                                                id="large"
                                                control={<Radio />}
                                                label={
                                                    <span
                                                        style={{
                                                            fontSize: "17px",
                                                        }}
                                                    >
                                                        Other/No Upload
                                                    </span>
                                                }
                                            />
                                        </span>
                                    </RadioGroup>
                                </FormControl>
                                {topping.state1 && (
                                    <p>
                                        <Grid
                                            item
                                            xs={12}
                                            sx={{
                                                marginLeft: "25px",
                                                marginBottom: "20px",
                                                width: "1000px",
                                            }}
                                        >
                                            <DocumentEditor
                                                title={"Contract"}
                                                templateDetails={
                                                    templateDetails
                                                }
                                                updateContentLocalAction={
                                                    updateContentLocalAction
                                                }
                                                updateContentGlobalAction={
                                                    updateContentGlobalAction
                                                }
                                                value={topping}
                                                setToggle={(toggle) =>
                                                    setState((prevState) => ({
                                                        ...prevState,
                                                        toggle: true,
                                                    }))
                                                }
                                            />
                                        </Grid>
                                    </p>
                                )}
                                {topping.state2 && (
                                    <Grid
                                        item
                                        xs={11}
                                        sx={{
                                            marginTop: "20px",
                                            marginLeft: "40px",
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontFamily:
                                                    "Source Sans Pro !important",
                                                fontStyle: "normal !important",
                                                fontWeight: "600 !important",
                                                fontSize: "20px !important",
                                                lineHeight: "25px !important",

                                                color: "#3B3B3B !important",
                                            }}
                                        >
                                            Acknowledge
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontFamily:
                                                    "Source Sans Pro !important",
                                                fontStyle: "normal !important",
                                                fontWeight: "400 !important",
                                                fontSize: "18px !important",
                                                lineHeight: "25px !important",
                                                paddingTop: "10px",
                                            }}
                                        >
                                            You hereby acknowledge and agree
                                            that your decision to choose the
                                            Cannot Fulfill Request option is
                                            made in good faith and, to the best
                                            of your knowledge, your decision is
                                            accurate
                                        </Typography>
                                        <FormControlLabel
                                            control={
                                                <CheckboxComponents
                                                    name="I agree"
                                                    checked={!isChecked1}
                                                    onChange={() =>
                                                        handleCheckboxChange1(
                                                            !isChecked1
                                                        )
                                                    }
                                                />
                                            }
                                            label={
                                                <span
                                                    style={{ fontSize: "17px" }}
                                                >
                                                    I agree
                                                </span>
                                            }
                                        />
                                        <FormControlLabel
                                            control={
                                                <CheckboxComponents
                                                    sx={{ height: "22px" }}
                                                    onChange={() => {
                                                        setState(
                                                            (prevState) => ({
                                                                ...prevState,
                                                                accept: true,
                                                                isChecked1: true,
                                                            })
                                                        );
                                                    }}
                                                    checked={!accept}
                                                />
                                            }
                                            label={
                                                <span
                                                    style={{ fontSize: "17px" }}
                                                >
                                                    I do not agree
                                                </span>
                                            }
                                        />
                                        {!accept && (
                                            <Typography
                                                sx={{
                                                    fontFamily:
                                                        "Source Sans Pro !important",
                                                    fontStyle:
                                                        "normal !important",
                                                    fontWeight:
                                                        "400 !important",
                                                    fontSize: "18px !important",
                                                    lineHeight:
                                                        "25px !important",

                                                    color: "#3B3B3B !important",
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        color: "#E05D5D",
                                                        fontWeight: "600 ",
                                                        fontFamily:
                                                            "Source Sans Pro !important",
                                                        paddingTop: "10px",

                                                        fontSize: "16px ",
                                                    }}
                                                >
                                                    NOTE:
                                                </span>
                                                Because you disagree, you are
                                                not permitted to choose the
                                                Cannot Fulfill Request option.
                                                Please go back and review the
                                                request carefully. Please keep
                                                in mind your obligation to
                                                respond to every question and
                                                request in good faith and, to
                                                the best of your knowledge,
                                                accurately.
                                            </Typography>
                                        )}
                                    </Grid>
                                )}
                                {topping.state3 && (
                                    <Grid
                                        item
                                        xs={12}
                                        sx={{
                                            marginTop: "20px",
                                            marginLeft: "25px",
                                        }}
                                    >
                                        <TextFieldComponent
                                            label={"Comments"}
                                            // value={topping}
                                            sx={{
                                                height: "216px",
                                                width: "900px",
                                                marginLeft: "20px",
                                            }}
                                            id="documentComments"
                                        />
                                    </Grid>
                                )}

                                <Grid
                                    item
                                    xs={2}
                                    sx={{
                                        marginTop: "20px",
                                        marginLeft: "720px",
                                        marginBottom: 10,
                                        display: "flex",
                                    }}
                                >
                                    <Grid item xs={0}>
                                        <ButtonComponent
                                            variant="outlined"
                                            title="Cancel"
                                        />
                                    </Grid>
                                    <Grid item xs={1} sx={{ marginLeft: 1 }}>
                                        <ButtonComponent
                                            variant="contained"
                                            disabled={!accept}
                                            // onClick={handleBtnClick}
                                            sx={!accept ? disabledBtnSx : null}
                                            title="Submit"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                    <Footer />
                </Box>
            )}
        </>
    );
}
const mapStateToProps = (state) => {
    return {
        contractsList: state.contractsList,
        customer: state.customer.selectedCustomer,
        documentDatabase: state?.docDatabase?.selectedDocumentDatabaseData,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            updateContentLocalAction,
            updateContentGlobalAction,
            submitSignedDocumentAction,
            getUserById,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Document);
