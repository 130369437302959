import { getApiCall, postApiCall } from "../../utils/Action";
import { API_CONSTANTS } from "../../config/ApiConstants";
import { toast } from "react-hot-toast";

export const DCT_DATA = "DCT_DATA";



const DctAssesmentAction = (data, id)=>{
    const payload = {
        "form_id": id,
        "data" : data
    }
    
    return async(dispatch)=>{
        try{
            const response = await postApiCall(API_CONSTANTS.FORM_STUDIO_SUBMISSION, payload);
            if(response?.data.status){
                return dispatch({
                    type: DCT_DATA,
                    payload: response
                });
            }else{
                // toast.error(response?.data?.message);
            }
        }catch(error){
            throw error;
        }
    }
}
export default DctAssesmentAction;


