import React, { Fragment, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Box } from "@mui/material";
import FileUpload from "../../components/FileUpload";
import ButtonComponent from "../ButtonComponent";
import TextFieldComponent from "../TextField/TextFieldComponent";
import Typography from "@mui/material/Typography";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import IconButton from "@mui/material/IconButton";
import "../../../assets/styles/Container/ContractorEmail.scss";
import SuccessfullModel from "../DialogsBox/SuccessfullModel";
import SunEditorComponent from "../SunEditorComponent";
const DocumentEditor = (props) => {
    const {
        title,
        selectedContract,
        setOpen,
        contractsList,
        updateContentLocalAction,
        updateContentGlobalAction,
        toggle,
        templateDetails,
        selectedDocumentData,
        handlerFieldChange,
        isRead,
        isEdit,
        item,
        userLoggedIn
    } = props;
    const [state, setState] = useState({
        openSuccesfullModel: false,
        modelContent: "",
        template_name: "File Name",
        editorContent: "",
        focused: true,
    });
    const { template_name, focused, editorContent } = state;
    useEffect(() => {
        if (selectedDocumentData?.html) {
            setState((prevState) => ({
                ...prevState,
                editorContent: selectedContract?.html,
                category_id:
                    selectedContract?.category_id === null
                        ? "File Name"
                        : selectedContract?.category_id,
            }));
        }
    }, [editorContent, toggle]);
    // sunEditor ----------------------------------
    const onBlur = () => {
        if (template_name !== "") {
            setState((prevState) => ({ ...prevState, focused: true }));
        }
    };
    const editor = useRef();
    // The sunEditor parameter will be set to the core suneditor instance when this function is called
    const getSunEditorInstance = (sunEditor) => {
        editor.current = sunEditor;
    };

    const handleChange = (content) => {
        let htmlContent = `<body> ${content} </body>`;
        setState((prevState) => ({
            ...prevState,
            template_content: content,
        }));
        updateContentLocalAction(htmlContent);
    };

    async function updateTemplate() {
        // setOpen();
        templateDetails.document_name = template_name;
        await updateContentGlobalAction(selectedContract.id, templateDetails);
        setState({
            ...state,
            editorContent: "",
            template_name: "File Name",
            openSuccesfullModel: true,
            modelContent: "Contract updated successfully!",
        });
    }

    const handleClickEditor = (e) => {
        const selection = window.getSelection();
        if (selection.rangeCount > 0) {
            const range = selection.getRangeAt(0);
            const commentNode = document.createElement("span");
            commentNode.classList.add("comment");
            commentNode.setAttribute("data-comment", "Comment Added!");
            range.surroundContents(commentNode);
        }
    };
    // ----------
    const handleFileNameChange = (e) => {
        setState((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
    };
    const handlerTempalateName = (e) => {
        setState((prevState) => ({ ...prevState, focused: false }));
    };
    return (
        <Fragment>
            <Box className="editorBox">
                <Box className="editorToolBar">
                    <div
                        style={{
                            padding: 20,
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                gap: 10,
                            }}
                        >
                            <TextFieldComponent
                                sx={{
                                    display: focused ? "none" : "block",
                                }}
                                variant="outlined"
                                className="emailSectionTextField"
                                size="small"
                                name="template_name"
                                // value={template_name}
                                setContents={
                                    templateDetails
                                        ? templateDetails.document_name
                                        : state.editorContent
                                }
                                onBlur={onBlur}
                                onChange={handleFileNameChange}
                                placeholder="Enter Template Name"
                                id="documentEditorTemplateName"
                            />
                            <Typography
                                display={focused ? "block" : "none"}
                                className="email_editor_heading"
                            >
                                {templateDetails
                                    ? templateDetails.document_name
                                    : state.editorContent}
                            </Typography>

                            {title === "Contract" ? (
                                <IconButton onClick={handlerTempalateName}>
                                    <EditOutlinedIcon className="icon" />
                                </IconButton>
                            ) : (
                                ""
                            )}
                        </div>

                        {title === "Contract" ? (
                            template_name !== "File Name" ? (
                                <ButtonComponent
                                    className="button_contained"
                                    onClick={updateTemplate}
                                    title={userLoggedIn?.language_data?.emailtemplates?.update_template || "Update Template"}
                                />
                            ) : (
                                <ButtonComponent
                                    className="button_contained"
                                    title={"Upload"}
                                />
                            )
                        ) : (
                            ""
                        )}
                    </div>
                </Box>
                <Box
                    className="emailSectionEditor"
                    sx={{
                        overflow: "auto",
                        padding: 0,
                    }}
                >
                    {title === "Contract" || "View" ? (
                        <SunEditorComponent
                            buttonList={true}
                            readOnly={title === "View" ? true : false}
                            setDefaultStyle="font-size: 14px;"
                            width="100%"
                            height="300px"
                            getSunEditorInstance={getSunEditorInstance}
                            onChange={handleChange}
                            defaultValue={state.editorContent}
                            onClick={handleClickEditor}
                        />
                    ) : (
                        <Box sx={{ width: "100%", height: "380px" }}></Box>
                    )}
                </Box>
            </Box>
            <SuccessfullModel
                open={state.openSuccesfullModel}
                handleClose={() =>
                    setState({
                        ...state,
                        openSuccesfullModel: false,
                        modelContent: null,
                    })
                }
                content={state.modelContent}
            />
        </Fragment>
    );
};
const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,
        document: state.documentsList.documentFile,
        selectedContract: state.contractsList.selectedContract,
        contractsList: state.contractsList,
        signedDocuments: state?.signedDocs?.selectedDocumentData,
    };
};


export default connect(mapStateToProps, null)(DocumentEditor);
