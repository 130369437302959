import { useState } from "react";
import { Navigate, useLocation  } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import store from "../store/Index";
import { ROUTES } from "./RouteList";
import { getLoggedInUser } from "../store/Action/LoginAction";
import LoadingComponent from "./../components/Loading/LoadingComponent";
 
function ProtectedRoutes(props) {
    const location = useLocation();
 
    const { name, route, page, getLoggedInUser } = props;
    let list;
    const [state, setState] = useState();
    const getModuleList = async () => {
       
        const wholeState = store.getState();
        let moduleList = wholeState?.auth?.userLoggedIn?.scope || undefined;
        if (moduleList === undefined) {
            let newList = await getLoggedInUser();
            moduleList = newList.payload?.scope;
            list = moduleList?.filter(
                (obj) =>
                    ((obj.role_permissions?.create && page === "create") ||
                        (obj.role_permissions?.read && page === "read") ||
                        (obj.role_permissions?.edit && page === "edit") ||
                        obj.role_permissions?.delete) &&
                    obj?.prefix === name
            );
        } else {
            list = moduleList;
        }
    };
    if (!state) {
        console.log(list,'geee');
        getModuleList().then(() => {
            setState(list);
        });
    }
 
    // return route;
    console.log(list,'ttttt',props.location);
    console.log(location.pathname);
 
 
    return !state ? (
         <LoadingComponent sx={{ height: "600px" }} />
    ) : state?.length > 0 || location.pathname === '/training' || location.pathname === '/training-library'  ? (
        route
    ) : (
        <Navigate to={ROUTES.PERMISSION_DENIED} />
    );
}
 
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getLoggedInUser,
        },
        dispatch
    );
};
 
export default connect(null, mapDispatchToProps)(ProtectedRoutes);