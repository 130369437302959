import React from "react";
import { useEffect } from "react";
import ButtonComponent from "../../../components/ButtonComponent";
import { Grid, Typography } from "@mui/material";
import { Translate } from "react-auto-translate";

const Timer = (props) => {
    const { stateMainTest, setStateMainTest } = props;
    useEffect(() => {
        const timercon = setInterval(() => {
            if (!stateMainTest.pausedTimer && stateMainTest.timer.seconds > 0) {
                setStateMainTest((prev) => {
                    let minutes = parseInt(prev.timer.minutes);
                    let seconds = parseInt(prev.timer.seconds);
                    if (minutes === 0 && seconds === 0) {
                        clearInterval(timercon);
                    } else {
                        if (seconds === 1) {
                            minutes = minutes - 1;
                            seconds = 59;
                        } else {
                            seconds = seconds - 1;
                        }
                    }
                    return {
                        ...prev,
                        timer: { minutes: minutes, seconds: seconds },
                    };
                });
            }
        }, 1000);

        return () => {
            clearInterval(timercon);
        };
        //eslint-disable-next-line
    }, [stateMainTest.pausedTimer, stateMainTest.timer.seconds]);

    const handlePause = () => {
        setStateMainTest((prevState) => ({
            ...prevState,
            pausedTimer: !stateMainTest.pausedTimer,
        }));
    };
    return (
        <>
            <Grid
                container
                gap={3}
                sx={{
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignContent: "center",
                    alignItems: "center",
                }}
            >
                <Grid item>
               
                    <Typography variant="span" className="font_24_600">
                        {`Timer: ${
                            stateMainTest.timer.minutes < 10
                                ? "0" + stateMainTest.timer.minutes
                                : stateMainTest.timer.minutes
                        }:${
                            stateMainTest.timer.seconds < 10
                                ? "0" + stateMainTest.timer.seconds
                                : stateMainTest.timer.seconds
                        }`}
                    </Typography>
          
                </Grid>
                <Grid item>
                    <ButtonComponent
                        onClick={handlePause}
                        title={stateMainTest.pausedTimer ? <Translate>Resume</Translate>  : <Translate>Pause</Translate> }
                        disabled={stateMainTest.timeOut}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default Timer;
