 export const headerList = [
    {
        name: "Contractor",
        headerClass: "cellColor",
        accessibleKey: "display_name",
        rowClass: "tableColor",
        headerAlign: "left",
        rowAlign: "left",
    },
    {
        name: " State",
        headerClass: "cellColor",
        accessibleKey: "state",
        rowClass: "tableColor",
        headerAlign: "left",
        rowAlign: "left",
    },
    {
        name: "Federal Circuit",
        headerClass: "cellColor",
        accessibleKey: "circuit_name",
        rowClass: "tableColor",
        headerAlign: "left",
        rowAlign: "left",
    },
    {
        name: " Collar Color",
        headerClass: "cellColor",
        accessibleKey: "collar_name",
        rowClass: "tableColor",
        headerAlign: "left",
        rowAlign: "left",
    },
    {
        name: "Task Name",
        headerClass: "cellColor",
        accessibleKey: "task_name",
        rowClass: "tableColor",
        headerAlign: "left",
        rowAlign: "left",
    },
    {
        name: "Onboard Time",
        headerClass: "cellColor",
        accessibleKey: "updated_at",
        rowClass: "tableColor",
        headerAlign: "left",
        rowAlign: "left",
    },
    {
        name: "Assign To",
        headerClass: "cellColor",
        accessibleKey: "assign_to_user_name",
        rowClass: "tableColor",
        headerAlign: "left",
        rowAlign: "left",
    },
    {
        name: "Assigned Time",
        headerClass: "cellColor",
        accessibleKey: "updated_at",
        rowClass: "tableColor",
        headerAlign: "left",
        rowAlign: "left",
    },
    {
        name: "",
        headerClass: "cellColor",
        accessibleKey: "assign_to_user_name",
        rowClass: "tableColor",
        headerAlign: "left",
        rowAlign: "left",
    },
    {
        name: "Completed Time",
        headerClass: "cellColor",
        accessibleKey: "updated_at",
        rowClass: "tableColor",
        headerAlign: "left",
        rowAlign: "left",
    },
    {
        name: "User",
        headerClass: "cellColor",
        accessibleKey: "display_name",
        rowClass: "tableColor",
        headerAlign: "left",
        rowAlign: "left",
    },
    {
        name: "Status",
        headerClass: "cellColor",
        accessibleKey: "status",
        rowClass: "tableColor",
        headerAlign: "left",
        rowAlign: "left",
    },
    {
        name: "Last Modified", 
        headerClass: "cellColor",
        accessibleKey: "updated_at",
        rowClass: "tableColor",
        headerAlign: "left",
        rowAlign: "left",
    },
];

export const headers=(tabStatus)=>{
    if(tabStatus==='U') {
        let listU=[...headerList]
        return listU.slice(0,6);}
    if(tabStatus==='I'){
         let listI = [...headerList];
         listI.splice(5, 1);
        return listI.slice(0,7);
    }    
    if(tabStatus==='C'){
        let listC = [...headerList];
        listC.splice(5, 1);
        listC.splice(6, 1);
       return listC.slice(0,8);
     } 
    if(tabStatus==='All'){
        let listAll = [...headerList];
        listAll.splice(4, 5);
       return listAll;
     }    
   
}
