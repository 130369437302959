import { combineReducers } from "redux";
import auth from "./LoginReducer";
import notification from "./NotificationReducer";
import notificationConfig from "./NotificationConfigReducer";
import tasks from "./RcpTasksReducer";
import customer from "./CustomerReducer";
import complianceWorkflow from "./ComplianceWorkflowReducer";
import roles from "./RolesModuleReducer";
import contractor from "./ContractorEmailReducer";
import user from "./UserReducer";
import glossary from "./GlossaryReducer";
import states from "./StatesReducer";
import agreementSettings from "./AgreementSettingReducer";
import testScopeSettings from "./TestScopeSettingReducer";
import comments from "./CommentsReducer";
import documentsList from "./DocumentsReducer";
import contractsList from "./ContractReducer";
import mqs from "./MqsReducer";
import faqs from "./FaqsReducer";
import email from "./EmailTemplateReducer";
import defaultComplianceSettings from "./DefaultComplianceSettingReducer";
import formStudio from "./FormStudioReducer";
import scoringConfig from "./ScoringConfigurationReducer";
import assessmentPartiesList from "./AssessmentPartiesReducer";
import assessmentSettings from "./AssessmentSettingReducer";
import whiteLabel from "./WhiteLabelReducer";
import signedDocs from "./SignedDocumentReducer";
import docDatabase from "./DocumentDatabaseReducer";
import selfConfigrationList from "./SelfConfigurationReducer";
import testLawSettings from "./TestLawSettingsReducer";
import liteTestReducer from "./LiteTestOptionReducer";
import DynamicTestReducer from "./DynamicTestReducer";
import auditData from "./AuditDefenseReport";
import DCTAssesmentReducer from "./DCTAssesmentReducer";
import GetDctAssesmentReducer from "./GetDctAssesmentReducer";
import testReducer from "./TestReducer";
const createReducer = (asyncReducers) => {
    const appReducer = combineReducers({
        auth,
        tasks,
        customer,
        complianceWorkflow,
        notification,
        notificationConfig,
        roles,
        contractor,
        user,
        comments,
        documentsList,
        contractsList,
        glossary,
        formStudio,
        signedDocs,
        docDatabase,
        states,
        agreementSettings,
        mqs,
        faqs,
        email,
        testScopeSettings,
        defaultComplianceSettings,
        scoringConfig,
        assessmentPartiesList,
        whiteLabel,
        assessmentSettings,
        selfConfigrationList,
        testLawSettings,
        liteTestReducer,
        DynamicTestReducer,
        auditData,
        DCTAssesmentReducer,
        GetDctAssesmentReducer,
        testReducer,
        ...asyncReducers,
    });
    return (state, action) => {
        if (action.type === "LOGOUT_ACTION") {
            state = {};
        }
        return appReducer(state, action);
    };
};

export default createReducer;
