import React, { useState, useEffect } from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableComponent from "../../components/Table/TableComponent";
import Grid from "@mui/material/Grid";
import { API_CONSTANTS } from "../../config/ApiConstants";
import { getApiCall } from "../../utils/Action";
import LoadingComponent from "../../components/Loading/LoadingComponent";
import { Box } from "@mui/material";

export default function EventTracking() {
    const [events, setEvents] = useState([]);
    const [loader, setLoader] = useState(false);
    useEffect(() => {
        getEventTrackingData();
        // eslint-disable-next-line
    }, []);
    const getEventTrackingData = async (ID) => {
        setLoader(true);
        const res = await getApiCall(API_CONSTANTS.EVENTS_TRACKING);
        setEvents(res?.data?.result);
        setLoader(false);
    };
    const headerList = [
        {
            id: 1,
            name: "Details",
            headerClass: "tableHeading",
            accessibleKey: "details",
            rowClass: "commentCell",
            headerAlign: "left",
            rowAlign: "left",
        },
        {
            id: 2,
            name: "User",
            headerClass: "tableHeading",
            accessibleKey: "user",
            rowClass: "typo4",
            headerAlign: "center",
            rowAlign: "center",
        },
        {
            id: 3,
            name: "Timestamp",
            headerClass: "tableHeading",
            accessibleKey: "created_at",
            rowClass: "commentCell",
            headerAlign: "center",
            rowAlign: "center",
        },
    ];
    return (
        <>
            <Grid container spacing={2}>
                <Grid item md={12} lg={12}>
                    {loader ? (
                        <Box
                            sx={{
                                width: "850px",
                                height: "100px",
                                justifyContent: "center",
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <LoadingComponent sx={{ height: "600px" }} />
                        </Box>
                    ) : (
                        <TableComponent
                            headerList={headerList}
                            sx={{ minWidth: "850px" }}
                        >
                            {events?.length > 0 &&
                                events.map((row, index) => (
                                    <TableRow
                                        key={row.name}
                                        className={
                                            index % 2 !== 0
                                                ? "tertiaryBackground"
                                                : ""
                                        }
                                        sx={{ marginRight: 2 }}
                                    >
                                        {headerList.map((obj) => (
                                            <TableCell
                                                component="th"
                                                key={obj?.name}
                                                align={obj?.rowAlign}
                                                className={obj?.rowClass}
                                            >
                                                {row[obj?.accessibleKey].slice(
                                                    0,
                                                    28
                                                )}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                        </TableComponent>
                    )}
                </Grid>
            </Grid>
        </>
    );
}
