import { Grid } from "@mui/material";
import React, { Fragment, useState } from "react";
import RadioButtonGroup from "../../../../../components/RadioButtonGroup/RadioButtonGroup";
import "../../../../../../assets/styles/Container/AgreementSettings.scss";
import { yesNoOptions } from "../../../../../utils/CommonData";
import AgreementCommon from "./AgreementCommon";
import { connect } from "react-redux";

const AgreementSettings = (props) => {
    const [optionValue, setOptionValue] = useState("yes");
    const {
        userLoggedIn
    } = props;

    return (
        <Fragment>
            <Grid container spacing={0}>
                <Grid item lg={4} md={4}>
                    <RadioButtonGroup
                        row={true}
                        title="Do you want to use the default templates ?"
                        options={yesNoOptions}
                        defaultValue={yesNoOptions[0].value}
                        onChange={(event) => setOptionValue(event.target.value)}
                    />
                </Grid>
            </Grid>
            {optionValue === "yes" && (
                <Fragment>
                    <AgreementCommon
                        title="Governing Law Provision"
                        toolTipTitle="Applicable Governing Law Provision in Each Master Independent Contractor Agreement"
                        type="G"
                    />
                    <AgreementCommon
                        title={`Consent to ${userLoggedIn?.language_data?.customer?.jurisdiction || "Jurisdiction"}`}
                        toolTipTitle={`Applicable Consent to ${userLoggedIn?.language_data?.customer?.jurisdiction || "Jurisdiction"} Provision in Each Master Independent Contractor Agreement`}
                        type="C"
                        isJurisdiction={true}
                    />
                </Fragment>
            )}
        </Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,
    };
};

export default connect(mapStateToProps, null) (AgreementSettings);
