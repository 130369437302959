import * as Actions from "../Action/GlossaryAction";

const initialState = {
    glossaryList: null,
};

const glossary = (state = initialState, action) => {
    switch (action?.type) {
        case Actions.GET_GLOSSARY_LIST: {
            return {
                ...state,
                glossaryList: action.payload,
            };
        }
        case Actions.ADD_GLOSSARY: {
            return {
                ...state,
                glossaryList: [action.payload, ...state.glossaryList],
            };
        }
        case Actions.DELETE_GLOSSARY: {
            let glossaryData = state.glossaryList?.filter(
                (obj) => obj.id !== action.payload
            );
            return {
                ...state,
                glossaryList: glossaryData,
            };
        }
        case Actions.UPDATE_GLOSSARY: {
            let list =state.glossaryList;
            let index = list.findIndex((obj) => obj.id === action.payload.id);
            if (index !== -1) list[index] = action.payload;
            return {
                ...state,
                glossaryList: list,
            };
        }
        default: {
            return state;
        }
    }
};

export default glossary;
