import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useNavigate, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import {
    getContractsTemplatesAction,
    updateContentLocalAction,
    updateContentGlobalAction,
} from "../../../../../store/Action/ContractAction";
import Navbar from "../../../../../common/Header/Navbar";
import Footer from "../../../../../common/Footer/Footer";
import "../../../../../../assets/styles/Common/_CommonStyle.scss";
import TopBar from "../../../../../common/TopBar";
import LeftSideBar from "../../../../../components/DocumentEditor/LeftSideBar";
import DocumentEditor from "../../../../../components/DocumentEditor/DocumentEditor";
import DocxFileUpload from "../../../../../components/DocumentEditor/DocxFileUpload";
import ButtonComponent from "../../../../../components/ButtonComponent";
import CheckboxComponents from "../../../../../components/Checkbox/CheckboxComponent";
import TextFieldComponent from "../../../../../components/TextField/TextFieldComponent";
import LoadingComponent from "../../../../../components/Loading/LoadingComponent";
import {
    submitSignedDocumentAction,
    storeSignedDocument,
    getSignedDocumentAprrovedList,
} from "../../../../../store/Action/SignedDocumentAction";
import { getUserById } from "../../../../../store/Action/UserAction";

function ContractTemplates(props) {
    const navigate = useNavigate();
    let params = useParams();
    const [state, setState] = useState({
        toggle: false,
        loading: false,
        selectedOption: "",
        topping: {
            state1: true,
            state2: false,
            state3: false,
        },
        accept: false,
        isChecked1: false,
        templateDetails: null,
    });

    const {
        toggle,
        loading,
        selectedOption,
        topping,
        accept,
        isChecked1,
        templateDetails,
    } = state;

    const {
        getContractsTemplatesAction,
        updateContentLocalAction,
        updateContentGlobalAction,
        signedDocuments,
        getUserById,
    } = props;

    useEffect(() => {
        async function callApi() {
            setState((prevState) => ({ ...prevState, loading: true }));
            await getUserById(params?.id);
            await getContractsTemplatesAction()
                .then(() => {
                    setState((prevState) => ({
                        ...prevState,
                        loading: false,
                    }));
                })
                .catch((error) => {
                    setState((prevState) => ({
                        ...prevState,
                        loading: false,
                    }));
                });
        }
        callApi();
    }, []);

    useEffect(() => {
        if (signedDocuments?.length > 0) {
            handleSelectedFile(signedDocuments[0]);
        }
    }, [signedDocuments]);

    const disabledBtnSx = {
        background: "rgba(59, 59, 59, 0.5) !important",
    };

    async function handleSelectedFile(template) {
        setState((prevState) => ({
            ...prevState,
            templateDetails: template,
            toggle: true,
        }));
    }

    const onOptionChange = (e) => {
        setState((prevState) => ({
            ...prevState,
            topping: { [e.target.name]: true },
            accept: { ...accept, [e.target.name]: true },
            isChecked1: false,
            selectedOption: e.target.value,
        }));
    };

    const handleCheckboxChange1 = (e, checked) => {
        setState((prevState) => ({
            ...prevState,
            isChecked1: checked,
            accept: {
                ...accept,
                [e?.target?.name]: true,
            },
        }));
    };

    return (
        <>
            <Navbar />
            {loading ? (
                <LoadingComponent sx={{ height: "600px" }} />
            ) : (
                <Box>
                    <DocxFileUpload
                        title={"Signed Documents"}
                        templates={signedDocuments}
                        toggle={toggle}
                        setState={(toggle) =>
                            setState((prevState) => ({
                                ...prevState,
                                toggle: true,
                            }))
                        }
                    />
                    <TopBar title={"Document"} />
                    <Grid container rowSpacing={1}>
                        <Grid>
                            <LeftSideBar
                                isChecked={isChecked1}
                                handleCheckboxChange={handleCheckboxChange1}
                                signedDocuments={signedDocuments}
                                templates={signedDocuments}
                                toggle={toggle}
                                handleSelectedFile={handleSelectedFile}
                                setToggle={(toggle) =>
                                    setState((prevState) => ({
                                        ...prevState,
                                        toggle,
                                    }))
                                }
                            />
                        </Grid>
                        {templateDetails && templateDetails.upload_status && (
                            <Grid item xs={8}>
                                <FormControl sx={{ marginLeft: "40px" }}>
                                    <FormLabel className="formUpload">
                                        Upload Status Options:
                                    </FormLabel>
                                    <Grid container spacing={2}>
                                        <Grid item>
                                            <RadioGroup
                                                value={
                                                    selectedOption ||
                                                    (templateDetails?.upload_status &&
                                                        templateDetails
                                                            .upload_status[0]
                                                            .label)
                                                }
                                                onChange={onOptionChange}
                                            >
                                                {templateDetails &&
                                                    templateDetails.upload_status &&
                                                    templateDetails.upload_status.map(
                                                        (option, index) => (
                                                            <FormControlLabel
                                                                key={index}
                                                                value={
                                                                    option.label
                                                                }
                                                                name={`state${
                                                                    index + 1
                                                                }`}
                                                                id={option.id}
                                                                control={
                                                                    <Radio />
                                                                }
                                                                label={
                                                                    <>
                                                                        {
                                                                            option.label
                                                                        }
                                                                        {option.value ===
                                                                            2 &&
                                                                            templateDetails.response_options ===
                                                                                "Hard Required" && (
                                                                                <Typography
                                                                                    component="span"
                                                                                    className="radioCannotfufill"
                                                                                >
                                                                                    {
                                                                                        templateDetails.cannot_fill_request_reason
                                                                                    }
                                                                                </Typography>
                                                                            )}
                                                                    </>
                                                                }
                                                            />
                                                        )
                                                    )}
                                            </RadioGroup>
                                        </Grid>
                                    </Grid>
                                </FormControl>
                                {topping.state1 && (
                                    <Grid
                                        item
                                        xs={14}
                                        className="toppingState1"
                                    >
                                        <DocumentEditor
                                            title={"Contract"}
                                            templateDetails={templateDetails}
                                            updateContentLocalAction={
                                                updateContentLocalAction
                                            }
                                            updateContentGlobalAction={
                                                updateContentGlobalAction
                                            }
                                            toggle={toggle}
                                            value={topping}
                                            signedDocuments={signedDocuments}
                                            setToggle={(toggle) =>
                                                setState((prevState) => ({
                                                    ...prevState,
                                                    toggle,
                                                }))
                                            }
                                        />
                                    </Grid>
                                )}
                                {topping.state2 && (
                                    <Grid
                                        item
                                        xs={11}
                                        className="toppingState2"
                                    >
                                        <Typography className="acknowledgeTypo">
                                            Acknowledge
                                        </Typography>
                                        <Typography className="acknowledgeData">
                                            {`You hereby acknowledge and agree that your decision to choose the Cannot Fulfill Request option is made in good faith and, to the best of your knowledge, your decision is accurate`}
                                        </Typography>
                                        <FormControlLabel
                                            control={
                                                <CheckboxComponents
                                                    sx={{
                                                        paddingLeft: "45px",
                                                    }}
                                                    name="I agree"
                                                    checked={!isChecked1}
                                                    onChange={() =>
                                                        handleCheckboxChange1(
                                                            !isChecked1
                                                        )
                                                    }
                                                />
                                            }
                                            label={
                                                <div className="agreeCheckbox">
                                                    I agree
                                                </div>
                                            }
                                        />
                                        <FormControlLabel
                                            control={
                                                <CheckboxComponents
                                                    className="heightCheckBox"
                                                    onChange={() => {
                                                        setState(
                                                            (prevState) => ({
                                                                ...prevState,
                                                                isChecked1:
                                                                    !prevState.isChecked1,
                                                                accept: !prevState.accept,
                                                            })
                                                        );
                                                    }}
                                                    checked={!accept}
                                                />
                                            }
                                            label={
                                                <div className="donotAgree">
                                                    I do not agree
                                                </div>
                                            }
                                        />
                                        {accept && (
                                            <Typography className="acceptNote">
                                                <div className="noteAccept">
                                                    NOTE:
                                                </div>
                                                {`Because you disagree, you are not permitted to choose the Cannot Fulfill Request option Please go back and review the request carefully.Please keep in mind your obligation to respond to every question and request in good faith and, to the best of yourknowledge,accurately.`}
                                            </Typography>
                                        )}
                                    </Grid>
                                )}
                                {topping.state3 && (
                                    <Grid
                                        item
                                        xs={12}
                                        sx={{
                                            marginTop: "20px",
                                            marginLeft: "25px",
                                        }}
                                    >
                                        <TextFieldComponent
                                            label={"Comments"}
                                            // value={topping}
                                            sx={{
                                                height: "216px",
                                                width: "900px",
                                                marginLeft: "20px",
                                            }}
                                            id="contractTempComments"
                                        />
                                    </Grid>
                                )}
                                <Grid item xs={2} className="cancelWorkflow">
                                    <Grid item xs={0}>
                                        <ButtonComponent
                                            variant="outlined"
                                            title="Cancel"
                                            onClick={() =>
                                                navigate("/compliance-workflow")
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={1} ml={2}>
                                        <ButtonComponent
                                            variant="contained"
                                            disabled={!accept}
                                            onClick={handleSelectedFile}
                                            sx={!accept ? disabledBtnSx : null}
                                            title="Submit"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                    <Footer />
                </Box>
            )}
        </>
    );
}
const mapStateToProps = (state) => {
    return {
        contractsList: state.contractsList,
        signedDocuments: state?.signedDocs?.selectedDocumentData,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getContractsTemplatesAction,
            getSignedDocumentAprrovedList,
            updateContentLocalAction,
            updateContentGlobalAction,
            submitSignedDocumentAction,
            storeSignedDocument,
            getUserById,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ContractTemplates);
