export const headerList = [
    {
        name: "Document Name",
        headerClass: "tableHeading",
        accessibleKey: "document_name",
        rowClass: "typo4",
        headerAlign: "left",
        rowAlign: "left",
    },
    {
        name: " Document Category",
        headerClass: "tableHeading",
        accessibleKey: "category.name",
        rowClass: "typo4",
        headerAlign: "left",
        rowAlign: "left",
    },
    {
        name: "status Name",
        headerClass: "tableHeading",
        accessibleKey: "status_name",
        rowClass: "typo4",
        headerAlign: "left",
        rowAlign: "left",
    },
    {
        name: "Approved By",
        headerClass: "tableHeading",
        accessibleKey: "display_name",
        rowClass: "typo4",
        headerAlign: "left",
        rowAlign: "left",
    },
];