import { ROUTES } from "./RouteList";
import store from "../store/Index";
import { Translate } from "react-auto-translate";

function getModuleList() {
    const wholeState = store.getState();
    let userLoggedIn = wholeState?.auth?.userLoggedIn || undefined;
    return userLoggedIn;
}

function getCurrentClient() {
    const wholeState = store.getState();
    let customerList = wholeState?.customer?.customerList;
    return customerList;
}

export const menuList = [
    {
        id: "customer",
        title: <Translate>Customer</Translate>,
        url: ROUTES.HOME,
    },
    {
        id: "compliance-workflow",
        title: <Translate>Workflow</Translate>,
        url: ROUTES.COMPLIANCE_WORKFLOW,
    },
    {
        id: "signed-document",
        title: <Translate>Signed Document</Translate>,
        url: ROUTES.SIGNED_DOCUMENT,
    },
    {
        id: "document-database",
        title: <Translate>Document</Translate>,
        url: ROUTES.DOCUMENT_DATABASE,
    },
    {
        id: "email-templates",
        title: <Translate>Email Template</Translate>,
        url: ROUTES.EMAIL_TEMPLATES,
    },
    {
        id: "training-lib-master",
        title:
            "Training Library",
        url: ROUTES.TRAINING_LIBRARY_MASTER,
    },
    {
        id: "roles",
        title: (
            <Translate>
                {getModuleList()?.language_data?.comman?.menu_roles || "Roles"}
            </Translate>
        ),
        url: ROUTES.MANAGE_ROLES,
    },
    {
        id: "datacapturetool",
        title: <Translate>Data Capture Tool</Translate>,
        url: ROUTES.FORM_BUILDER,
    },
    {
        id: "archived-clients",
        title: <Translate>Archived Clients</Translate>,
        url: ROUTES.ARCHIVED_CLIENT,
    },
    {
        id: "archived-users",
        title: <Translate>Archived Users</Translate>,
        url: ROUTES.ARCHIVED_USER
    },
    {
        id: "tests",
        title: (
                getModuleList()?.language_data?.comman?.menu_test || "Tests"
        ),
        subMenuList: [
            {
                id: "mqs",
                title: (
                    <Translate>
                        {getModuleList()?.language_data?.comman?.menu_mqs_db ||
                            "MQS Database"}
                    </Translate>
                ),
                url: ROUTES.SELECT_TEST,
            },

            {
                id: "mqs",
                title: (
                    <Translate>
                        {getModuleList()?.language_data?.comman?.menu_mqs_cat ||
                            "MQS Category"}
                    </Translate>
                ),
                url: ROUTES.MANAGE_MQS,
            },
        ],
    },
    {
        id: "scroring",
        title: <Translate>Scoring</Translate>,
        //  url: ROUTES.FORM_BUILDER,
    },
];

export const settings = [{ id: "logout", title: "Logout" }];

if (getModuleList()) {
    if (localStorage.getItem("role_id") === "2") {
        settings.unshift({
            id: "profile",
            title: <Translate>Profile</Translate>,
            url: ROUTES.PROFILE,
        });
    }
}

export const helpMenu = [
    {
        id: "documentation",
        title: <Translate>Training Library</Translate>,
        url: ROUTES.TRAINING_VIDEOS,
    },
    { id: "faqs", title: <Translate>FAQs</Translate>, url: ROUTES.FAQS },
    {
        id: "glossary",
        title: <Translate>Glossary</Translate>,
        url: ROUTES.GLOSSARY,
    },
];

export const accountMenu = [
    {
        id: "profile",
        title: (
            <Translate>
                {getModuleList()?.language_data?.comman?.profile || "Profile"}
            </Translate>
        ),
        url: ROUTES.PROFILE,
    },
    {
        id: "Branding",
        title: <Translate>Branding</Translate>,
        state: getCurrentClient(),
    },
    {
        id: "Team",
        title: <Translate>Team</Translate>,
    },
];

export const getAccountMenu = () => {
    const customerList = getCurrentClient();
    return accountMenu;
};

export const getHelpMenu = () => {
    const userLoggedIn = getModuleList();
    // let newArray = helpMenu.filter((o2) =>
    //     userLoggedIn?.scope?.some(
    //         (o1) =>
    //             (o1.role_permissions?.create ||
    //                 o1.role_permissions?.read ||
    //                 o1.role_permissions?.edit ||
    //                 o1.role_permissions?.delete) &&
    //             o2.id === o1.prefix
    //     )
    // );
    // newArray = [
    //     ...newArray,
    //     {
    //         id: "documentation",
    //         title: "Documentation",
    //         url: ROUTES.DOCUMENTATION,
    //     },
    //     { id: "faqs", title: "FAQs", url: ROUTES.FAQS },
    // ];
    // return newArray;
    return helpMenu;
};

export const getSettingsMenu = () => {
    const userLoggedIn = getModuleList();
    const customerList = getCurrentClient();
    if (userLoggedIn?.scope?.length > 0) {
        let newArray = settings.filter((o2) =>
            userLoggedIn?.scope?.some(
                (o1) =>
                    (o1.role_permissions?.create ||
                        o1.role_permissions?.read ||
                        o1.role_permissions?.edit ||
                        o1.role_permissions?.delete) &&
                    o2.id === o1.prefix
            )
        );
        newArray = [
            ...newArray,
            { id: "logout", title: <Translate>Logout</Translate> },
        ];
        // return newArray;
        return settings;
    } else {
        return [];
    }
};

export const getMenuList = () => {
    let newArray = [];
    const userLoggedIn = getModuleList();
    getModuleList() &&
        menuList.forEach((obj) => {
            if (userLoggedIn?.scope?.length > 0) {
                if (obj?.id === "tests") {
                    let data = obj?.subMenuList?.filter((o2) =>
                        userLoggedIn?.scope?.some(
                            (o1) =>
                                (o1.role_permissions?.create ||
                                    o1.role_permissions?.read ||
                                    o1.role_permissions?.edit ||
                                    o1.role_permissions?.delete) &&
                                o2.id === o1.prefix
                        )
                    );
                    newArray = [
                        ...newArray,
                        { id: "tests", title: "Tests", subMenuList: data },
                    ];
                } else {
                    let data = userLoggedIn?.scope?.find(
                        (module) =>
                            module.prefix === obj?.id &&
                            (module.role_permissions?.create ||
                                module.role_permissions?.read ||
                                module.role_permissions?.edit ||
                                module.role_permissions?.delete)
                    );
                    if (data) newArray.push(obj);
                }
            }
        });
    // return newArray;
    return menuList;
};

export const getFormStudio = () => {
    let newArray = [];
    const userLoggedIn = getModuleList();
    newArray =
        userLoggedIn?.scope?.length > 0 &&
        userLoggedIn?.scope?.filter(
            (module) =>
                (module.prefix === "form-studio" ||
                    module.prefix === "form-studio-submission") &&
                (module.role_permissions?.create ||
                    module.role_permissions?.read ||
                    module.role_permissions?.edit ||
                    module.role_permissions?.delete)
        );
    // return newArray?.length > 0 ? true : false;
    return true;
};

export const getDashboard = () => {
    let newArray = [];
    const userLoggedIn = getModuleList();
    newArray =
        userLoggedIn?.scope?.length > 0 &&
        userLoggedIn?.scope?.filter(
            (module) =>
                module.prefix === "dashboard" &&
                (module.role_permissions?.create ||
                    module.role_permissions?.read ||
                    module.role_permissions?.edit ||
                    module.role_permissions?.delete)
        );
    //return newArray?.length > 0 ? true : false;
    return true;
};

export const getNotification = () => {
    let newArray = [];
    const userLoggedIn = getModuleList();
    newArray =
        userLoggedIn?.scope?.length > 0 &&
        userLoggedIn?.scope?.filter(
            (module) =>
                module.prefix === "notifications" &&
                (module.role_permissions?.create ||
                    module.role_permissions?.read ||
                    module.role_permissions?.edit ||
                    module.role_permissions?.delete)
        );

    // return newArray?.length > 0 ? true : false;
    return true;
};
