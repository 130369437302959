import React, { useState, useEffect, Fragment } from "react";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import "../../../../../../assets/styles/Container/RcpCustomerInformation.scss";
import AssessmentPartiesTable from "./AssessmentPartiesTable";
import { getUserList } from "../../../../../store/Action/UserAction";
import { getAssesmentUserList } from "../../../../../store/Action/ComplianceWorkflowAction";
import {
    getPartiesAction,
    addPartiesAction,
    deletePartiesAction,
    updatePartiesAction,
    singleUpdatePartiesAction,
} from "../../../../../store/Action/AssessmentPartiesAction";
import WorkFlowAssignUserDialog from "../../../../../components/DialogsBox/WorkFlowAssginUserDialog";
import SuccessfullModel from "../../../../../components/DialogsBox/SuccessfullModel";
import CommonDeleteDialog from "../../../../../components/DialogsBox/CommonDeleteDialog";
import { tableRowClasses } from "@mui/material";

function AssessmentParties(props) {
    const {
        customer,
        user,
        getPartiesAction,
        addPartiesAction,
        deletePartiesAction,
        updatePartiesAction,
        singleUpdatePartiesAction,
        getAssesmentUserList,
        userLoggedIn
    } = props;
    const { id, client_id } = user?.selectedUser || "";
    const { guid } = customer?.selectedCustomer || "";
    const AssesmentUser = useSelector(
        (state) => state.complianceWorkflow.assesmentUserList
    );
    const [rows, setRows] = useState([]);
    const [tableList, setTableList] = useState([]);
    const [toggle, setToggle] = useState(false);
    const [workFlow, setWorkFlow] = useState({
        listItem: null,
        updateItem: null,
        openAssignUser: false,
        openAdd: false,
        openSuccesfullModel: false,
        modelContain: null,
        sequence: 0,
        assignUser: {
            firstName: "",
            middleName: "",
            lastName: "",
            email: "",
            id: "",
            role: "",
        },
    });

    useEffect(() => {
        getAssesmentUserList(client_id);
    }, []);

    useEffect(() => {
        if (customer.selectedCustomer) {
            initilizeStates(guid, id);
        }
    }, [workFlow.listItem, toggle, workFlow.updateItem]);

    async function initilizeStates(guid, id) {
        const res = await getPartiesAction(guid, id);
        setTableList(res.payload);
    }

    async function handleAddParty() {
        if (workFlow.updateItem !== null) {
            await singleUpdatePartiesAction(guid, {
                id: workFlow.updateItem,
                contractor_id: workFlow.assignUser.id,
            });
            setWorkFlow({
                ...workFlow,
                updateItem: null,
                openAssignUser: false,
            });
        } else {
            const addRes = await addPartiesAction(
                guid,
                id,
                workFlow.assignUser.id
            );
            initilizeStates(guid, id);
            setToggle(!toggle);
            setWorkFlow({
                ...workFlow,
                openAssignUser: false,
                assignUser: {
                    ...workFlow.assignUser,
                    firstName: "",
                    middleName: "",
                    lastName: "",
                    email: "",
                    id: "",
                    role: "",
                },
            });
        }
    }

    async function handleUpdate() {
        setRows([
            ...rows,
            ...tableList.map((item) => {
                return { id: item.id, contractor_id: item.contractor_id };
            }),
        ]);
        await updatePartiesAction(guid, tableList);
        initilizeStates(guid, id);
        setToggle(!toggle);
    }

    //Delete Party
    async function handleDelete() {
        await deletePartiesAction(guid, workFlow.listItem?.id);
        initilizeStates(guid, id);
        setWorkFlow({
            ...workFlow,
            listItem: null,
            openSuccesfullModel: true,
            modelContain: "Party removed sucessfully!",
        });
    }

    const deleteContent = `Are you sure,you want to remove the (${workFlow?.listItem?.party_name} Party) from the Assessment Parties?`;

    return (
        <Fragment>
            <SuccessfullModel
                open={workFlow.openSuccesfullModel}
                handleClose={() =>
                    setWorkFlow({
                        ...workFlow,
                        openSuccesfullModel: false,
                        modelContain: null,
                    })
                }
                content={workFlow.modelContain}
            />
            <CommonDeleteDialog
                open={workFlow.listItem != null ? true : false}
                setOpen={(isOpen) => {}}
                cancelButtonAction={() => {
                    setWorkFlow({
                        ...workFlow,
                        listItem: null,
                    });
                }}
                onClick={handleDelete}
                title="Delete Party"
                content={deleteContent}
            />
            <WorkFlowAssignUserDialog
                open={workFlow.openAssignUser}
                label={userLoggedIn?.language_data?.comman?.add_btn || "Add"}
                title="Add Party"
                options={AssesmentUser}
                assignUser={workFlow.assignUser}
                workFlow={workFlow}
                setAssignUser={(user) => {
                    setWorkFlow({
                        ...workFlow,
                        assignUser: {
                            ...workFlow.assignUser,
                            firstName: user?.first_name,
                            middleName: user?.middle_name,
                            lastName: user?.last_name,
                            email: user?.email,
                            display_name: user?.display_name,
                            id: user?.id,
                            role: user?.role,
                        },
                    });
                }}
                getOptionLabel={(option) => option.display_name || option}
                handleClose={() =>
                    setWorkFlow({
                        ...workFlow,
                        openAssignUser: false,
                    })
                }
                handleOnClick={() => handleAddParty()}
            />
            <AssessmentPartiesTable
                tableList={tableList}
                setListItem={(item) => {
                    setWorkFlow({
                        ...workFlow,
                        listItem: { ...item },
                    });
                }}
                handleUpdate={handleUpdate}
                workFlow={workFlow}
                setWorkFlow={setWorkFlow}
            />
        </Fragment>
    );
}
const mapStateToProps = (state) => {
    return {
        customer: state.customer,
        user: state.user,
        userLoggedIn: state.auth?.userLoggedIn,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getPartiesAction,
            addPartiesAction,
            deletePartiesAction,
            updatePartiesAction,
            singleUpdatePartiesAction,
            getUserList,
            getAssesmentUserList,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(AssessmentParties);
