import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { directLoginWithLink } from "../../store/Action/LoginAction";
import { connect } from "react-redux";
import LoadingComponent from "../../components/Loading/LoadingComponent";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../router/RouteList";
import { getClientById } from "../../store/Action/LoginAction";
import { getWorkersProgressList } from "../../store/Action/ComplianceWorkflowAction";

const roles = [
    { name: "Super Admin", id: 3, routs: ROUTES.USER },
    { name: "Manager ('M')", id: 44, routs: ROUTES.USER },
    { name: "Contractor", id: 2, routs: ROUTES.USER },
];
const DirectLogin = (props) => {
    const navigate = useNavigate();
    const { directLogin, directLoginWithLink, getWorkersProgressList } = props;
    const [state, setState] = useState({
        isLoading: false,
    });
    const data = window.location.pathname.split("/")[2];
    useEffect(() => {
        const login = async () => {
            localStorage.clear();
            try {
                setState({ isLoading: true });
                const res = await directLoginWithLink(data);
                const workersList = await getWorkersProgressList(
                    res.payload.user.client_id,
                    "rcp"
                );
                if (res) {
                    localStorage.setItem("mode", res.payload.user.mode);
                    let findUser = await workersList.payload.find(
                        (obj) =>
                            obj.user.id ===
                                (res?.payload?.user?.role_id === 44
                                    ? res.payload.redirect_user_id
                                    : res.payload.user.id) &&
                            obj.user.mode === res.payload.user.mode
                        // res?.payload.user?.role_id === 44
                        //     ? obj.user.manager_id
                        //     : obj.user.id) === res.payload.user.id &&
                        // obj.user.mode === res.payload.user.mode
                    );
                    let findTask =
                        findUser &&
                        findUser?.tasks?.find((obj) => obj.action === true);
                    localStorage.setItem("role_id", res?.payload.user?.role_id);
                    if (res.payload.user.role_id == 1) {
                        navigate(ROUTES.DEFAULT_PAGE);
                    } else {
                        if (res.payload.user.client_id) {
                            let selectedRole;
                            for (const element of roles) {
                                if (res?.payload.user?.role_id === element.id) {
                                    selectedRole = element;
                                    break; // Exit the loop once a match is found
                                }
                            }
                            if (selectedRole) {
                                const clientIDAsyncFunction =
                                    await getClientById(
                                        res?.payload?.user?.client_id
                                    );
                                const clientID = await clientIDAsyncFunction();
                                if (selectedRole.id == 2) {
                                    if (selectedRole.id == 2) {
                                        if (
                                            findTask?.slug ===
                                                "rcp_contractor" ||
                                            findTask?.slug ===
                                                "rcp_contractor_retest"
                                        ) {
                                            localStorage.setItem(
                                                "task_id",
                                                findTask?.id
                                            );
                                            localStorage.setItem(
                                                "mode",
                                                findTask?.mode
                                            );
                                            navigate(
                                                `${ROUTES.CUSTOMERS_INFORMATION}/${clientID.result.guid}/${res.payload.user.id}`,
                                                {
                                                    state: {
                                                        task_id: findTask?.id,
                                                        mode:
                                                            findTask?.mode ||
                                                            res.payload.user
                                                                .mode,
                                                    },
                                                }
                                            );
                                        } else {
                                            navigate(
                                                `${ROUTES.CUSTOMERS_INFORMATION}/${clientID.result.guid}/${res.payload.user.id}`,
                                                {
                                                    state: {
                                                        task_id: findTask?.id,
                                                        mode:
                                                            findTask?.mode ||
                                                            res.payload.user
                                                                .mode,
                                                    },
                                                }
                                            );
                                        }
                                    } else {
                                        clientID.result.client_type == "dc"
                                            ? navigate(ROUTES.USER)
                                            : navigate(ROUTES.DEFAULT_PAGE);
                                    }
                                } else {
                                    if (res.payload.test_redirect) {
                                        localStorage.setItem(
                                            "task_id",
                                            findTask?.id
                                        );
                                        localStorage.setItem(
                                            "mode",
                                            findTask?.mode ||
                                                res.payload.user.mode
                                        );
                                        navigate(
                                            `${ROUTES.CUSTOMERS_INFORMATION}/${clientID.result.guid}/${res.payload.user.id}`,
                                            {
                                                state: {
                                                    task_id: findTask?.id,
                                                    mode:
                                                        findTask?.mode ||
                                                        res.payload.user.mode,
                                                    manager_role_id:
                                                        res?.payload?.user
                                                            ?.role_id,
                                                    contractor_ID:
                                                        res?.payload
                                                            ?.redirect_user_id ||
                                                        findUser?.user?.id,
                                                },
                                            }
                                        );
                                    } else {
                                        if (
                                            localStorage.getItem("role_id") ==
                                            44
                                        ) {
                                            navigate(
                                                ROUTES.COMPLIANCE_WORKFLOW
                                            );
                                        } else {
                                            clientID.result.client_type == "dc"
                                                ? navigate(ROUTES.USER)
                                                : navigate(ROUTES.DEFAULT_PAGE);
                                        }
                                    }
                                }
                            } else {
                                navigate(ROUTES.PROFILE);
                            }
                        } else {
                            navigate(ROUTES.PROFILE);
                        }
                    }
                }
            } catch (error) {
                console.error(error);
            } finally {
                setState({ isLoading: false });
            }
        };
        login();
    }, []);

    return <div>{state.isLoading && <LoadingComponent />}</div>;
};

const mapStateToProps = (state) => {
    return {
        directLogin: state.auth,
    };
};
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            directLoginWithLink,
            getWorkersProgressList,
        },
        dispatch
    );
};
export default connect(mapStateToProps, mapDispatchToProps)(DirectLogin);
