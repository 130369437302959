import { getApiCall, postApiCall, putApiCall } from "../../utils/Action";
import { API_CONSTANTS } from "../../config/ApiConstants";
import { toast } from "react-hot-toast";

export const GET_DEFAULT_COMPLIANCE_SETTINGS =
    "GET_DEFAULT_COMPLIANCE_SETTINGS";
export const GET_USER_DEFAULT_COMPLIANCE_SETTINGS =
    "GET_USER_DEFAULT_COMPLIANCE_SETTINGS";
export const CREATE_USER_DEFAULT_COMPLIANCE_SETTINGS =
    "CREATE_USER_DEFAULT_COMPLIANCE_SETTINGS";
export const EDIT_USER_DEFAULT_COMPLIANCE_SETTINGS =
    "EDIT_USER_DEFAULT_COMPLIANCE_SETTINGS";
export const GET_QUESTIONS_IN_BALANCING_CATEGORY =
    "GET_QUESTIONS_IN_BALANCING_CATEGORY";
export const VIEW_DEFAULT_EXTRA_SETTINGS = 
"VIEW_DEFAULT_EXTRA_SETTINGS"



export const getDefaultComplianceSettings = () => {
    return async (dispatch) => {
        try {
            const response = await getApiCall(
                API_CONSTANTS.DEFAULT_COMPLIANCE_SETTINGS
            );
            if (response?.status === 200) {
                return dispatch({
                    type: GET_DEFAULT_COMPLIANCE_SETTINGS,
                    payload: response?.data?.result,
                });
            } else {
                // toast.error(response?.message);
            }
        } catch (error) {
            throw error;
        }
    };
};

export const setExtraSettings = (data) => {
    return async (dispatch) => {
        try {
            const response = await postApiCall(
                API_CONSTANTS.SAVE_EXTRA_SETTINGS,data
                
            );
            if (response?.status === 200) {
                console.log(response)
            }
        } catch (error) {
            throw error;
        }
    };
};

export const getExtraSettings = (data) => {
    return async (dispatch) => {
        try {
            const response = await getApiCall(API_CONSTANTS.VIEW_EXTRA_SETTINGS, {
            guid: data
            });
            if(response?.status == 200) {
                return dispatch({
                    type: VIEW_DEFAULT_EXTRA_SETTINGS,
                    payload: response?.data?.result || null,
                });
            }
        } catch (error) {
            throw error;
        }
    }
}

export const getUserDefaultComplianceSetting = (guid) => {
    return async (dispatch) => {
        try {
            const response = await getApiCall(
                API_CONSTANTS.USER_DEFAULT_COMPLIANCE_SETTINGS,
                { guid: guid }
            );
            if (response?.status == 200) {
                return dispatch({
                    type: GET_USER_DEFAULT_COMPLIANCE_SETTINGS,
                    payload: response?.data?.result,
                });
            } else {
                // toast.error(response?.message);
            }
        } catch (error) {
            throw error;
        }
    };
};
export const createUserDefaultComliance = (data) => {
    return async (dispatch) => {
        try {
            const response = await postApiCall(
                API_CONSTANTS.CREATE_DEFAULT_COMPLIANCE_SETTINGS,
                data
            );
            if (response?.status == 201) {
                return dispatch({
                    type: CREATE_USER_DEFAULT_COMPLIANCE_SETTINGS,
                    payload: response?.data?.result || null,
                });
            } else {
                // toast.error(response?.message);
            }
        } catch (error) {
            throw error;
        }
    };
};
export const editUserDefaultComliance = (data) => {
    return async (dispatch) => {
        try {
            const response = await putApiCall(
                API_CONSTANTS.CREATE_DEFAULT_COMPLIANCE_SETTINGS +
                "/" +
                data.id,
                data
            );
            if (response?.status == 201) {
                return dispatch({
                    type: EDIT_USER_DEFAULT_COMPLIANCE_SETTINGS,
                    payload: response?.data?.result || null,
                });
            } else {
                // toast.error(response?.message);
            }
        } catch (error) {
            throw error;
        }
    };
};
