import HMobiledataIcon from "@mui/icons-material/HMobiledata";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import InsertInvitationOutlinedIcon from "@mui/icons-material/InsertInvitationOutlined";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import LibraryAddCheckOutlinedIcon from "@mui/icons-material/LibraryAddCheckOutlined";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import TitleOutlinedIcon from "@mui/icons-material/TitleOutlined";
import FormatSizeOutlinedIcon from "@mui/icons-material/FormatSizeOutlined";
import ModeStandbyOutlinedIcon from "@mui/icons-material/ModeStandbyOutlined";
import ArrowDropDownCircleOutlinedIcon from "@mui/icons-material/ArrowDropDownCircleOutlined";
import ViewDayOutlinedIcon from "@mui/icons-material/ViewDayOutlined";
import Heading from "./pages/Heading";
import FullName from "./pages/FullName";
import Address from "./pages/Address";
import Phone from "./pages/Phone";
import ShortTest from "./pages/ShortText";
import Time from "./pages/Time";
import Date from "./pages/Date";
import DateTime from "./pages/DateTime";
import LongTest from "./pages/LongText";
import DropDown from "./pages/DropDown";
import RadioGroup from "./pages/RadioGroup";
import Checkbox from "./pages/Checkbox";
import FileUpload from "./pages/FileUpload";
import Signature from "./pages/Signature";
import Divider from "./pages/Divider";
import ImageUpload from "./pages/ImageUpload";

export const elementList = [
    {
        id: 1,
        element: "Label",
        icon: <HMobiledataIcon />,
        selected: false,
    },
    {
        id: 2,
        element: "Full Name",
        icon: <AccountCircleOutlinedIcon />,
        selected: false,
    },
    {
        id: 3,
        element: "Address",
        icon: <RoomOutlinedIcon />,
        selected: false,
    },
    {
        id: 4,
        element: "Contact Information",
        icon: <LocalPhoneOutlinedIcon />,
        selected: false,
    },
    {
        id: 5,
        element: "Date & Time",
        icon: <CalendarMonthOutlinedIcon />,
        selected: false,
    },
];

export const basicElement = [
    {
        id: 6,
        element: "Text Box",
        icon: <TitleOutlinedIcon />,
        selected: false,
    },
    {
        id: 7,
        element: "Long Text Box",
        icon: <FormatSizeOutlinedIcon />,
        selected: false,
    },
    {
        id: 8,
        element: "DropDown",
        icon: <ArrowDropDownCircleOutlinedIcon />,
        selected: false,
    },
    {
        id: 9,
        element: "Radio Button",
        icon: <ModeStandbyOutlinedIcon />,
        selected: false,
    },
    {
        id: 10,
        element: "Checkbox",
        icon: <LibraryAddCheckOutlinedIcon />,
        selected: false,
    },
    {
        id: 11,
        element: "Image Upload",
        icon: <ImageOutlinedIcon />,
        selected: false,
    },
    {
        id: 12,
        element: "File Upload",
        icon: <CloudUploadOutlinedIcon />,
        selected: false,
    },
    {
        id: 13,
        element: "Time",
        icon: <AccessTimeOutlinedIcon />,
        selected: false,
    },
    {
        id: 14,
        element: "Date",
        icon: <InsertInvitationOutlinedIcon />,
        selected: false,
    },
    {
        id: 15,
        element: "Divider",
        icon: <ViewDayOutlinedIcon />,
        selected: false,
    },
    {
        id: 16,
        element: "Signature",
        icon: <ViewDayOutlinedIcon />,
        selected: false,
    },
];

export const radioButtonOption = [
    {
        label: "Option1",
        value: "dc",
        id: "1",
        name: "DirectConductor",
    },
    {
        label: "Option2",
        value: "idc",
        id: "2",
        name: "InDirectConductor",
    },
    {
        label: "Option3",
        value: "3",
        id: "3",
        name: "InDirectConductor",
    },
];

export const renderSwitch = (
    item,
    index,
    state,
    setState,
    handleChange,
    elementSelectAction,
    setMenuItem,
    handlerFieldChange,
    isRead,
    isEdit
) => {
    switch (item?.name) {
        case "Label":
            return (
                <Heading
                    state={state}
                    setState={setState}
                    data={item}
                    handleChange={(e) => handleChange(e, item)}
                    isEdit={isEdit}
                >
                    {elementSelectAction
                        ? elementSelectAction(item, index)
                        : null}
                </Heading>
            );
        case "Full Name":
            return (
                <FullName
                    state={state}
                    setState={setState}
                    data={item}
                    handleChange={(e) => handleChange(e, item)}
                    handlerFieldChange={handlerFieldChange}
                    isRead={isRead}
                    isEdit={isEdit}
                   
                >
                    {elementSelectAction
                        ? elementSelectAction(item, index)
                        : null}
                </FullName>
            );
        case "Address":
            return (
                <Address
                    state={state}
                    setState={setState}
                    data={item}
                    handleChange={(e) => handleChange(e, item)}
                    handlerFieldChange={handlerFieldChange}
                    isRead={isRead}
                    isEdit={isEdit}
                >
                    {elementSelectAction
                        ? elementSelectAction(item, index)
                        : null}
                </Address>
            );
        case "Contact Information":
            return (
                <Phone
                    state={state}
                    setState={setState}
                    data={item}
                    handleChange={(e) => handleChange(e, item)}
                    handlerFieldChange={handlerFieldChange}
                    isRead={isRead}
                    isEdit={isEdit}
                >
                    {elementSelectAction
                        ? elementSelectAction(item, index)
                        : null}
                </Phone>
            );
        case "Date & Time":
            return (
                <DateTime
                    state={state}
                    setState={setState}
                    data={item}
                    handleChange={(e) => handleChange(e, item)}
                    handlerFieldChange={handlerFieldChange}
                    isRead={isRead}
                    isEdit={isEdit}
                >
                    {elementSelectAction
                        ? elementSelectAction(item, index)
                        : null}
                </DateTime>
            );
        case "Text Box":
            return (
                <ShortTest
                    state={state}
                    setState={setState}
                    data={item}
                    handleChange={(e) => handleChange(e, item)}
                    handlerFieldChange={handlerFieldChange}
                    isRead={isRead}
                    isEdit={isEdit}
                >
                    {elementSelectAction
                        ? elementSelectAction(item, index)
                        : null}
                </ShortTest>
            );
        case "Long Text Box":
            return (
                <LongTest
                    state={state}
                    setState={setState}
                    data={item}
                    handleChange={(e) => handleChange(e, item)}
                    handlerFieldChange={handlerFieldChange}
                    isRead={isRead}
                    isEdit={isEdit}
                >
                    {elementSelectAction
                        ? elementSelectAction(item, index)
                        : null}
                </LongTest>
            );
        case "DropDown":
            return (
                <DropDown
                    state={state}
                    setState={setState}
                    data={item}
                    handleChange={(e) => handleChange(e, item)}
                    setMenuItem={(e) => setMenuItem(e, item)}
                    handlerFieldChange={handlerFieldChange}
                    isRead={isRead}
                    isEdit={isEdit}
                >
                    {elementSelectAction
                        ? elementSelectAction(item, index)
                        : null}
                </DropDown>
            );
        case "Radio Button":
            return (
                <RadioGroup
                    state={state}
                    setState={setState}
                    data={item}
                    handleChange={(e) => handleChange(e, item)}
                    setMenuItem={(e) => setMenuItem(e, item)}
                    handlerFieldChange={handlerFieldChange}
                    isRead={isRead}
                    isEdit={isEdit}
                >
                    {elementSelectAction
                        ? elementSelectAction(item, index)
                        : null}
                </RadioGroup>
            );
        case "Checkbox":
            return (
                <Checkbox
                    state={state}
                    setState={setState}
                    data={item}
                    handleChange={(e) => handleChange(e, item)}
                    setMenuItem={(e) => setMenuItem(e, item)}
                    handlerFieldChange={handlerFieldChange}
                    isRead={isRead}
                    isEdit={isEdit}
                >
                    {elementSelectAction
                        ? elementSelectAction(item, index)
                        : null}
                </Checkbox>
            );
        case "Image Upload":
            return (
                <ImageUpload
                    validation={"image/*"}
                    text={"Image"}
                    className={"uploadImagebox"}
                    state={state}
                    setState={setState}
                    data={item}
                    handleChange={(e) => handleChange(e, item)}
                    handlerFieldChange={handlerFieldChange}
                    isRead={isRead}
                    isEdit={isEdit}
                >
                    {elementSelectAction
                        ? elementSelectAction(item, index)
                        : null}
                </ImageUpload>
            );
        case "File Upload":
            return (
                <FileUpload
                    validation={".pdf,.doc,.docx"}
                    text={"File"}
                    className={"uploadFilebox"}
                    state={state}
                    setState={setState}
                    data={item}
                    handleChange={(e) => handleChange(e, item)}
                    handlerFieldChange={handlerFieldChange}
                    isRead={isRead}
                    isEdit={isEdit}
                >
                    {elementSelectAction
                        ? elementSelectAction(item, index)
                        : null}
                </FileUpload>
            );
        case "Time":
            return (
                <Time
                    state={state}
                    setState={setState}
                    data={item}
                    handleChange={(e) => handleChange(e, item)}
                    handlerFieldChange={handlerFieldChange}
                    isRead={isRead}
                    isEdit={isEdit}
                >
                    {elementSelectAction
                        ? elementSelectAction(item, index)
                        : null}
                </Time>
            );
        case "Date":
            return (
                <Date
                    state={state}
                    setState={setState}
                    data={item}
                    handleChange={(e) => handleChange(e, item)}
                    handlerFieldChange={handlerFieldChange}
                    isRead={isRead}
                    isEdit={isEdit}
                >
                    {elementSelectAction
                        ? elementSelectAction(item, index)
                        : null}
                </Date>
            );
        case "Signature":
            return (
                <Signature
                    state={state}
                    setState={setState}
                    data={item}
                    handleChange={(e) => handleChange(e, item)}
                    handlerFieldChange={handlerFieldChange}
                    isRead={isRead}
                    isEdit={isEdit}
                >
                    {elementSelectAction
                        ? elementSelectAction(item, index)
                        : null}
                </Signature>
            );
        case "Divider":
            return (
                <Divider state={state} setState={setState} data={item}>
                    {elementSelectAction
                        ? elementSelectAction(item, index)
                        : null}
                </Divider>
            );
        default:
            return;
    }
};

export const colorScheme = [
    { boaderColor: "#FFFFFF", backgroundColor: "#ececec", color: "" },
    { boaderColor: "#CDB599FF", backgroundColor: "#42EADDFF", color: "" },
    { boaderColor: "#00203FFF", backgroundColor: "#ADEFD1FF", color: "" },
    { boaderColor: "#2C5F2D", backgroundColor: "#97BC62FF", color: "" },
    { boaderColor: "#FFFFFF", backgroundColor: "#FCF6F5FF", color: "" },
    { boaderColor: "#E4D0D0", backgroundColor: "#FFDCDC", color: "#867070" },
    { boaderColor: "#B3FFF1", backgroundColor: "#F4FBFF", color: "" },
    { boaderColor: "#F26A21", backgroundColor: "#FFDF79", color: "" },
    { boaderColor: "#FF97BC", backgroundColor: "#FFF8FA", color: "" },
    { boaderColor: "#34A853", backgroundColor: "#FBBC04", color: "#4285F4" },
    { boaderColor: "#4285F4", backgroundColor: "#FBBC04", color: "#EA4335" },
    { boaderColor: "#FFFFFF", backgroundColor: "#0077B5", color: "#FFFFFF" },
    { boaderColor: "#25d366", backgroundColor: "#dcf8c6", color: "#075e54" },
    { boaderColor: "#cd486b", backgroundColor: "#8a3ab9", color: "#fbad50" },
    { boaderColor: "#DCE6F0", backgroundColor: "#395498", color: "#DCE6F0" },
];
