import { Box, Typography, Button } from "@mui/material";
import React from "react";
import Navbar from "../../common/Header/Navbar";
import PageHeader from "../../common/PageHeader";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import FormControlLabel from "@mui/material/FormControlLabel";
import DialogComponent from "../../components/DialogsBox/DialogComponent";
import ButtonComponent from "../../components/ButtonComponent";
import CheckboxComponents from "../../components/Checkbox/CheckboxComponent";
import DropDownComponent from "../../components/DropDown/DropDownComponent";
import { connect } from "react-redux";

function Assessments(props) {
    const [age, setAge] = React.useState("");

    const handleChange = (event) => {
        setAge(event.target.value);
    };
    const [open, setOpen] = React.useState(false);
    const [contractorDailog, setContractorDailog] = React.useState(false);
    const { userLoggedIn } = props;

    // const handleClose = () => {
    //     setOpen(false);
    // };
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleContractorClose = () => {
        setContractorDailog(false);
    };
    const handleOpen = () => {
        setContractorDailog(true);
    };

    return (
        <>
            <Navbar />
            <Box sx={{ px: { md: 10, xs: 1 } }}>
                <PageHeader title={"Create Assessment"} />
            </Box>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={3} sx={{ ml: "57px" }}>
                    <Grid item xs>
                        <Typography sx={{ fontWeight: 600, fontSize: "20px" }}>
                            Assessment Type
                        </Typography>
                        <Box
                            sx={{
                                width: 350,
                                mt: "10px",
                                background: "#F2F2F2",
                                borderRadius: "5px",
                            }}
                        >
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                    Select
                                </InputLabel>
                                <DropDownComponent
                                    labelId="demo-simple-select-label"
                                    id="manageAssessmentsType"
                                    value={age}
                                    label="Age"
                                    onChange={handleChange}
                                >
                                    {[
                                        { value: 10, label: "Ten" },
                                        { value: 20, label: "Twenty" },
                                        { value: 30, label: "Thirty" },
                                    ].map(({ value, label }) => (
                                        <MenuItem value={value}>
                                            {label}
                                        </MenuItem>
                                    ))}
                                </DropDownComponent>
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item xs={1} sx={{ mr: "300px", ml: "-90px" }}>
                        <Typography sx={{ fontWeight: 600, fontSize: "20px" }}>
                            Customer
                        </Typography>
                        <Box
                            sx={{
                                width: 350,
                                mt: "10px",
                                background: "#F2F2F2",
                                borderRadius: "5px",
                            }}
                        >
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                    Select
                                </InputLabel>
                                <DropDownComponent
                                    labelId="demo-simple-select-label"
                                    id="manageAssessmentsCustomer"
                                    value={age}
                                    label="Age"
                                    onChange={handleChange}
                                >
                                    {[
                                        { value: 10, label: "Ten" },
                                        { value: 20, label: "Twenty" },
                                        { value: 30, label: "Thirty" },
                                    ].map(({ value, label }) => (
                                        <MenuItem value={value}>
                                            {label}
                                        </MenuItem>
                                    ))}
                                </DropDownComponent>
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item xs sx={{ ml: "10px" }}>
                        <Typography sx={{ fontWeight: 600, fontSize: "20px" }}>
                            Test Audience
                        </Typography>
                        <Box
                            sx={{
                                width: 350,
                                mt: "10px",
                                background: "#F2F2F2",
                                borderRadius: "5px",
                            }}
                        >
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                    Select
                                </InputLabel>
                                <DropDownComponent
                                    labelId="demo-simple-select-label"
                                    id="manageAssessmentsAudience"
                                    value={age}
                                    label="Age"
                                    onChange={handleChange}
                                    children={[
                                        { value: 10, label: "Ten" },
                                        { value: 20, label: "Twenty" },
                                        { value: 30, label: "Thirty" },
                                    ].map(({ value, label }) => (
                                        <MenuItem value={value}>
                                            {label}
                                        </MenuItem>
                                    ))}
                                />
                            </FormControl>
                        </Box>
                    </Grid>
                </Grid>
                <Box sx={{ flexGrow: 1, mt: "30px", ml: "80px" }}>
                    <Grid container spacing={2} columns={16}>
                        <Grid item xs={4}>
                            <Typography
                                sx={{ fontWeight: 600, fontSize: "20px" }}
                            >
                                Scoring Modifiers
                            </Typography>
                            <Box>
                                <FormGroup>
                                    <FormControlLabel
                                        control={<CheckboxComponents />}
                                        label="Potential political Prefrence"
                                        id="manageAssessmentsPPP"
                                    />
                                    <FormControlLabel
                                        control={<CheckboxComponents />}
                                        label="Targeted misclassification Initiatives"
                                        id="manageAssessmentsTMI"
                                    />
                                    <FormControlLabel
                                        control={<CheckboxComponents />}
                                        label="Collor Color"
                                        id="manageAssessmentsCC"
                                    />
                                    <FormControlLabel
                                        control={<CheckboxComponents />}
                                        label="Location of Work"
                                        id="manageAssessmentsLOW"
                                    />
                                </FormGroup>
                            </Box>
                        </Grid>
                        <Grid item xs={4} sx={{ ml: "530px" }}>
                            <Typography
                                sx={{ fontWeight: 600, fontSize: "20px" }}
                            >
                                Foundational Test
                            </Typography>
                            <Box>
                                <FormGroup>
                                    <FormControlLabel
                                        control={<CheckboxComponents />}
                                        label="For Manager Only"
                                        id="ForManagerOnly"
                                    />
                                    <FormControlLabel
                                        control={<CheckboxComponents />}
                                        label="For Contractor Only"
                                        id="ForContractorOnly"
                                    />
                                </FormGroup>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid sx={{ ml: "850px", mt: "-60px" }}>
                        <Typography sx={{ fontWeight: 600, fontSize: "20px" }}>
                            Manager
                        </Typography>
                        <Box
                            sx={{
                                width: 300,
                                mt: "10px",
                                background: "#F2F2F2",
                                borderRadius: "5px",
                            }}
                        >
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                    Select
                                </InputLabel>
                                <DropDownComponent
                                    labelId="demo-simple-select-label"
                                    id="manageAssessmentsManager"
                                    value={age}
                                    label="Age"
                                    onChange={handleChange}
                                >
                                    {[
                                        { value: 10, label: "Ten" },
                                        { value: 20, label: "Twenty" },
                                        { value: 30, label: "Thirty" },
                                    ].map(({ value, label }) => (
                                        <MenuItem value={value}>
                                            {label}
                                        </MenuItem>
                                    ))}
                                </DropDownComponent>
                            </FormControl>
                        </Box>
                        <Box>
                            <ButtonComponent
                                sx={{
                                    background: " #00A19D !important",
                                    ml: "300px",
                                    mt: "-73px",
                                    height: "50px",
                                    borderRadius: "0px 5px 5px 0px",
                                }}
                                id="manageAssessmentsButton1"
                                title={
                                    <AddCircleOutlineIcon
                                        sx={{ color: "#fff" }}
                                        onClick={handleClickOpen}
                                    />
                                }
                            />
                            <DialogComponent
                                open={open}
                                handleClose={handleClose}
                                title={" Add Manager"}
                                id="addManager"
                                content={
                                    <>
                                        <Typography gutterBottom>
                                            Cras mattis consectetur purus sit
                                            amet fermentum. Cras justo odio,
                                            dapibus ac facilisis in, egestas
                                            eget quam. Morbi leo risus, porta ac
                                            consectetur ac, vestibulum at eros.
                                        </Typography>
                                        <Typography gutterBottom>
                                            Praesent commodo cursus magna, vel
                                            scelerisque nisl consectetur et.
                                            Vivamus sagittis lacus vel augue
                                            laoreet rutrum faucibus dolor
                                            auctor.
                                        </Typography>
                                        <Typography gutterBottom>
                                            Aenean lacinia bibendum nulla sed
                                            consectetur. Praesent commodo cursus
                                            magna, vel scelerisque nisl
                                            consectetur et. Donec sed odio dui.
                                            Donec ullamcorper nulla non metus
                                            auctor fringilla.
                                        </Typography>
                                    </>
                                }
                                action={
                                    <ButtonComponent
                                        id="manageAssessmentsButton2"
                                        title={"Submit"}
                                    />
                                }
                            />
                        </Box>
                    </Grid>
                    <Grid sx={{ ml: "850px" }}>
                        <Typography sx={{ fontWeight: 600, fontSize: "20px" }}>
                            {userLoggedIn?.language_data?.complianceworkflow
                                ?.conductor_type || "Contractor"}
                        </Typography>
                        <Box
                            sx={{
                                width: 300,
                                mt: "10px",
                                background: "#F2F2F2",
                                borderRadius: "5px",
                            }}
                        >
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                    Select
                                </InputLabel>
                                <DropDownComponent
                                    labelId="demo-simple-select-label"
                                    id="manageAssessmentsContractor"
                                    value={age}
                                    label="Age"
                                    onChange={handleChange}
                                >
                                    {[
                                        { value: 10, label: "Ten" },
                                        { value: 20, label: "Twenty" },
                                        { value: 30, label: "Thirty" },
                                    ].map(({ value, label }) => (
                                        <MenuItem value={value}>
                                            {label}
                                        </MenuItem>
                                    ))}
                                </DropDownComponent>
                            </FormControl>
                        </Box>
                        <Box>
                            <Button
                                sx={{
                                    background: " #00A19D !important",
                                    ml: "300px",
                                    mt: "-73px",
                                    height: "50px",
                                    borderRadius: "0px 5px 5px 0px",
                                }}
                            >
                                <AddCircleOutlineIcon
                                    sx={{ color: "#fff" }}
                                    onClick={handleOpen}
                                />
                            </Button>
                            <DialogComponent
                                open={contractorDailog}
                                handleClose={handleContractorClose}
                                title={" Add Contractor"}
                                id="addContractor"
                                content={
                                    <>
                                        <Typography gutterBottom>
                                            Cras mattis consectetur purus sit
                                            amet fermentum. Cras justo odio,
                                            dapibus ac facilisis in, egestas
                                            eget quam. Morbi leo risus, porta ac
                                            consectetur ac, vestibulum at eros.
                                        </Typography>
                                        <Typography gutterBottom>
                                            Praesent commodo cursus magna, vel
                                            scelerisque nisl consectetur et.
                                            Vivamus sagittis lacus vel augue
                                            laoreet rutrum faucibus dolor
                                            auctor.
                                        </Typography>
                                        <Typography gutterBottom>
                                            Aenean lacinia bibendum nulla sed
                                            consectetur. Praesent commodo cursus
                                            magna, vel scelerisque nisl
                                            consectetur et. Donec sed odio dui.
                                            Donec ullamcorper nulla non metus
                                            auctor fringilla.
                                        </Typography>
                                    </>
                                }
                                action={
                                    <ButtonComponent
                                        id="manageAssessmentsButton3"
                                        title={"Submit"}
                                    />
                                }
                            />
                        </Box>
                    </Grid>
                </Box>

                <Grid sx={{ mt: "60px", ml: "72px" }}>
                    <Button
                        sx={{
                            background: " #F2F2F2 ! important",
                            width: "100px",
                            height: "30px",
                            border: "1px solid #F26A21",
                            fontWeight: 600,
                            fontSize: "12px",
                            borderRadius: "5px",
                            color: " #F26A21",
                        }}
                        id="manageAssessmentsPreviosButton"
                    >
                        Pervious
                    </Button>
                    <Button
                        sx={{
                            borderRadius: "5px",
                            background: "#F26A21 ! important",
                            color: "#fff",
                            width: "100px",
                            height: "30px",
                            ml: "995px",
                        }}
                        id="manageAssessmentsNextButton"
                    >
                        Next
                    </Button>
                </Grid>
            </Box>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,
    };
};

export default connect(mapStateToProps, null)(Assessments);
