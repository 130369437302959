import React, { Fragment, useState, useEffect, useRef } from "react";
import Navbar from "./../../common/Header/Navbar";
import "../../../assets/styles/Container/Faqs.scss";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import PageHeader from "../../common/PageHeader";
import DropDownComponent from "../../components/DropDown/DropDownComponent";
import TextFieldComponent from "../../components/TextField/TextFieldComponent";
import { MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Box from "@mui/material/Box";
import ButtonComponent from "../../components/ButtonComponent";
import {
    storeFaqs,
    updateFaqs,
    setSelectedQuestion,
    getFaqsCategory,
    getFaqsById,
} from "../../store/Action/FaqsAction";
import SunEditorComponent from "../../components/SunEditorComponent";
import LoadingComponent from "../../components/Loading/LoadingComponent";
import { toast } from "react-hot-toast";
import { getUserById } from "../../store/Action/UserAction";
function AddFaqs(props) {
    const {
        userLoggedIn,
        storeFaqs,
        updateFaqs,
        faqsCategory,
        getFaqsCategory,
        selectedQuestion,
        setSelectedQuestion,
        getFaqsById,
    } = props;
    const [state, setState] = useState({
        category_id: "",
        question: "",
        answer: "",
        isLoading: false,
    });
    const pathName = window.location.search.split("?");
    const pageType = window.location.pathname.split("-");
    const { category_id, question, answer, isLoading } = state;
    if (selectedQuestion && question === "") {
        setState((prevState) => ({ ...prevState, ...selectedQuestion }));
    }
    useEffect(() => {
        getFaqsCategory();
    }, [getFaqsCategory]);
    useEffect(() => {
        getFaqsCategory();
        const pageTypeName = pageType[0].split("/");
        if (!selectedQuestion && pageTypeName[1] === "edit") {
            getFaqsById(parseInt(pathName[1]), pageTypeName[1]);
        }
    }, [getFaqsCategory, selectedQuestion]);
    const navigate = useNavigate(); 
    const handelCancelFaqs = () => {
        navigate("/faqs");
        setSelectedQuestion(null);
    };
    const editor = useRef();
    const getSunEditorInstance = (sunEditor) => {
        editor.current = sunEditor;
    };
    const handleChange = (answer) => {
        setState((prevState) => ({
            ...prevState,
            answer: answer,
        }));
    };
    async function handlecategorySubmit() {
        if(category_id && question && answer ) {
            setState((prevState) => ({ ...prevState, isLoading: false }));
            if (selectedQuestion === null) await storeFaqs(state);
            else await updateFaqs(state);
            setSelectedQuestion(null);
            setState((prevState) => ({ ...prevState, isLoading: false }));
            navigate("/faqs");
        } else {
            if(category_id?.length === 0) {
                toast.error("Category id is required")
            } else if(question?.length == 0) {
                toast.error("Question is required")
            } else if(answer.length == 0) {
                toast.error("Answer is required")
            }
        }
       
    }
    return (
        <>
            <Navbar />
            {isLoading ? (
                <LoadingComponent />
            ) : (
                <Box sx={{ px: { md: 7, xs: 1 } }}>
                    <PageHeader title={userLoggedIn?.language_data?.faqs?.add_faq_text || "Add FAQ"} />
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography className="faqHeading">
                                Category
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            md={4}
                            mt={2}
                            display={"flex"}
                            justifyContent={"center"}
                        >
                            <DropDownComponent
                                id="addFaqsCategory"
                                label={"Category"}
                                name={"Category"}
                                fullWidth={true}
                                onChange={(event) =>
                                    setState((prevState) => ({
                                        ...prevState,
                                        category_id: event.target.value,
                                    }))
                                }
                                required={true}
                                value={category_id}
                            >
                                {faqsCategory?.map((option) => (
                                    <MenuItem
                                        key={option?.id}
                                        value={option?.id}
                                    >
                                        {option?.name}
                                    </MenuItem>
                                ))}
                            </DropDownComponent>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} mt={2}>
                            <Typography className="faqHeading">
                                Question
                            </Typography>
                        </Grid>
                        <Grid item md={8} xs={12} mt={2}>
                            <TextFieldComponent
                                label={"Question"}
                                required={true}
                                id="faqsQuetions"
                                onChange={(event) =>
                                    setState((prevState) => ({
                                        ...prevState,
                                        question: event.target.value,
                                    }))
                                }
                                value={question}
                            />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} mt={2}>
                            <Typography className="faqHeading">
                                Answer
                            </Typography>
                        </Grid>
                        <Grid item md={8} mt={3}>
                            <SunEditorComponent
                                id="addFaqsSunEditor"
                                buttonList={true}
                                setDefaultStyle="font-size: 14px;"
                                width="100%"
                                height="220px"
                                getSunEditorInstance={getSunEditorInstance}
                                onChange={handleChange}
                                defaultValue={answer}
                                setContents={answer}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        mt={2}
                        paddingBottom={3}
                        display={"flex"}
                        justifyContent={"center"}
                    >
                        <Grid
                            item
                            md={1}
                            sx={{ marginLeft: "250px !important" }}
                        >
                            <ButtonComponent
                                id="addFaqsCancelButton"
                                title={"Cancel"}
                                variant="outlined"
                                onClick={handelCancelFaqs}
                            ></ButtonComponent>
                        </Grid>
                        <Grid item md={1} ml={2}>
                            <ButtonComponent
                                id="addFaqsSubmitButton"
                                title={
                                    selectedQuestion === null
                                        ? userLoggedIn?.language_data?.comman?.save_btn || "Save"
                                        : "Update"
                                }
                                type="submit"
                                variant="contained"
                                onClick={handlecategorySubmit}
                            ></ButtonComponent>
                        </Grid>
                    </Grid>
                </Box>
            )}
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,
        faqsCategory: state?.faqs?.faqsCategory,
        selectedQuestion: state?.faqs?.selectedQuestion,
        userLoggedIn: state.auth?.userLoggedIn,

    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            storeFaqs,
            updateFaqs,
            getFaqsCategory,
            setSelectedQuestion,
            getFaqsById,
            getUserById
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(AddFaqs);
