import React, { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import validator from "validator";
import toast from "react-hot-toast";
import Box from "@mui/system/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextFieldComponent from "../../components/TextField/TextFieldComponent";
import TooltipComponent from "../../components/ToolTip/TooltipComponent";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import SunEditorComponent from "../../components/SunEditorComponent";
import "../../../assets/styles/Container/ContractorEmail.scss";
import ButtonComponent from "../../components/ButtonComponent";
import { connect } from "react-redux";
import {Translate } from 'react-auto-translate';
function EmailSection(props) {
    const { setOpen, state, setState, handlerCancelOnClick, userLoggedIn } =
        props;
    const params = useParams();
    const pathName = window?.location?.pathname;
    let view = pathName.includes("view");
    const [focused] = useState(false);

    const checkValidation = (data) => {
        if (!data) return false;
        else return validator.isEmail(data);
    };

    const ErrorTooltip = (props) => {
        const { data } = props;
        return checkValidation(data) ? null : (
            <TooltipComponent
                className=""
                title={<Typography>Incorrect Email</Typography>}
                placement="right"
                arrow
            >
                <InfoOutlinedIcon sx={{ color: "#E05D5D" }} />
            </TooltipComponent>
        );
    };

    const getSaveModel = () => {
        if (state?.template_name === "") {
            toast.error("Please Enter Required Email Template Name.");
        } else {
            if (state?.subject === "") {
                toast.error("Please Enter Required Email Subject.");
            } else {
                if (
                    (state?.to_mail ? checkValidation(state?.to_mail) : true) &&
                    (state?.from_mail
                        ? checkValidation(state?.from_mail)
                        : true)
                ) {
                    setOpen(true);
                } else {
                    toast.error("Please Enter Valid Email Address.");
                }
            }
        }
    };

    const editor = useRef();
    const getSunEditorInstance = (sunEditor) => {
        editor.current = sunEditor;
    };
    const handleChange = (content) => {
        setState((prevState) => ({
            ...prevState,
            content: content,
        }));
    };
    const handlerChages = (e) => {
        setState((pre) => ({ ...pre, [e.target.name]: e.target?.value }));
    };

    return (
        <>
        <Box className="emailSection">
            <Box className="emailStore">
                <Grid container className="templateName">
                    <Grid
                        item
                        display={"flex"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        gap={10}
                    >
                        <TextFieldComponent
                            sx={{
                                display: focused ? "none" : "block",
                            }}
                            variant="outlined"
                            className="emailSectionTextField"
                            size="small"
                            name="template_name"
                            disabled={view}
                            value={state?.template_name}
                            onChange={handlerChages}
                            inputProps={{ maxLength: 52 }}
                            placeholder="Enter Template Name"
                            id="emailTemplateName"
                            required={true}
                        />
                        <Typography display={focused ? "block" : "none"}>
                            <Translate>{state.template_name}</Translate>
                        </Typography>
                    </Grid>
                    {view ? null : params.id ? (
                        <ButtonComponent
                            onClick={() => getSaveModel()}
                            title={
                                <Translate>
                                    {userLoggedIn?.language_data?.emailtemplates?.update_template || "Update Template"}
                                </Translate>
                            }
                            id="emailSectionUpdateButton"
                        />
                    ) : (
                        <ButtonComponent
                            disabled={
                                !(
                                    (state?.cc_mail
                                        ? checkValidation(state?.cc_mail)
                                        : true) &&
                                    (state?.bcc_mail
                                        ? checkValidation(state?.bcc_mail)
                                        : true)
                                )
                            }
                            onClick={() => getSaveModel()}
                            title={
                                <Translate>
                                    {userLoggedIn?.language_data?.emailtemplates?.save_template || "Save Template"}
                                </Translate>
                            }
                            id="emailSectionSaveButton"
                        />
                    )}
                </Grid>
                <Grid container display={"flex"} mt={1} spacing={1}>
                    <Grid
                        item
                        xs={12}
                        display={"flex"}
                        gap={3}
                        alignItems={"center"}
                    >
                        <Grid item xs={1}>
                            <Typography className="emailTextFieldLabel">
                                <Translate>From:</Translate>
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={10}
                            md={9}
                            display={"flex"}
                            gap={1}
                            alignItems={"center"}
                        >
                            <TextFieldComponent
                                fullWidth
                                id="emailFromEmail"
                                className="emailSectionTextField"
                                name="from_mail"
                                value={state?.from_mail}
                                disabled={view}
                                onChange={handlerChages}
                                error={
                                    state?.from_mail
                                        ? !checkValidation(state?.from_mail)
                                        : false
                                }
                                size="small"
                                variant="outlined"
                                placeholder="contractor@example.com"
                                sx={{
                                    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
                                        {
                                            border: "1.5px solid #E05D5D",
                                        },
                                }}
                            />
                            <Grid item xs={1}>
                                {state?.from_mail ? (
                                    <ErrorTooltip data={state?.from_mail} />
                                ) : null}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        display={"flex"}
                        gap={3}
                        alignItems={"center"}
                    >
                        <Grid item xs={1}>
                            <Typography className="emailTextFieldLabel">
                                <Translate>
                                    {userLoggedIn?.language_data?.emailtemplates?.to || "To"}
                                </Translate>
                                :
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={10}
                            md={9}
                            display={"flex"}
                            gap={1}
                            alignItems={"center"}
                        >
                            <TextFieldComponent
                                fullWidth
                                id="emailToEmail"
                                className="emailSectionTextField"
                                name="to_mail"
                                value={state?.to_mail}
                                disabled={view}
                                onChange={handlerChages}
                                error={
                                    state?.to_mail
                                        ? !checkValidation(state?.to_mail)
                                        : false
                                }
                                size="small"
                                variant="outlined"
                                placeholder="john@example.com"
                                sx={{
                                    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
                                        {
                                            border: "1.5px solid #E05D5D",
                                        },
                                }}
                            />
                            <Grid item xs={1}>
                                {state?.to_mail ? (
                                    <ErrorTooltip data={state?.to_mail} />
                                ) : null}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        display={"flex"}
                        gap={3}
                        alignItems={"center"}
                    >
                        <Grid item xs={1}>
                            <Typography className="emailTextFieldLabel">
                                <Translate>Cc:</Translate>
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={10}
                            md={9}
                            display={"flex"}
                            gap={1}
                            alignItems={"center"}
                        >
                            <TextFieldComponent
                                fullWidth
                                id="emailCcEmail"
                                className={"emailSectionTextField"}
                                name="cc_mail"
                                value={state?.cc_mail}
                                disabled={view}
                                onChange={handlerChages}
                                error={
                                    state?.cc_mail
                                        ? !checkValidation(state?.cc_mail)
                                        : false
                                }
                                size="small"
                                variant="outlined"
                                placeholder="john@example.com"
                                sx={{
                                    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
                                        {
                                            border: "1.5px solid #E05D5D",
                                        },
                                }}
                            />
                            <Grid item xs={1}>
                                {state?.cc_mail ? (
                                    <ErrorTooltip data={state?.cc_mail} />
                                ) : null}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        display={"flex"}
                        gap={3}
                        alignItems={"center"}
                    >
                        <Grid item xs={1}>
                            <Typography className="emailTextFieldLabel">
                                <Translate>Bcc:</Translate>
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={10}
                            md={9}
                            display={"flex"}
                            alignItems={"center"}
                            gap={1}
                        >
                            <TextFieldComponent
                                fullWidth
                                id="emailBccEmail"
                                className={"emailSectionTextField"}
                                name="bcc_mail"
                                value={state?.bcc_mail}
                                disabled={view}
                                onChange={handlerChages}
                                error={
                                    state?.bcc_mail
                                        ? !checkValidation(state?.bcc_mail)
                                        : false
                                }
                                size="small"
                                variant="outlined"
                                placeholder="john@example.com"
                                sx={{
                                    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
                                        {
                                            border: "1.5px solid #E05D5D",
                                        },
                                }}
                            />
                            <Grid item xs={1}>
                                {state?.bcc_mail ? (
                                    <ErrorTooltip data={state?.bcc_mail} />
                                ) : null}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        display={"flex"}
                        gap={3}
                        alignItems={"center"}
                    >
                        <Grid item xs={1}>
                            <Typography className="emailTextFieldLabel">
                                <Translate>
                                    {userLoggedIn?.language_data?.emailtemplates?.subject || "Subject"}
                                </Translate>
                                :
                            </Typography>
                        </Grid>
                        <Grid item xs={11} md={8.21}>
                            <TextFieldComponent
                                fullWidth
                                className={"emailSectionTextField"}
                                id="emailSubject"
                                name="subject"
                                value={state?.subject}
                                disabled={view}
                                onChange={handlerChages}
                                size="small"
                                variant="outlined"
                                placeholder="Invitation mail"
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <Box
                className="emailSectionEditor"
                sx={{
                    overflow: "auto",
                    padding: 0,
                    height: "100%",
                }}
            >
                <SunEditorComponent
                    buttonList={true}
                    setDefaultStyle="font-size: 14px;"
                    width="100%"
                    height="220px"
                    getSunEditorInstance={getSunEditorInstance}
                    onChange={handleChange}
                    defaultValue={state?.content}
                    disable={view}
                    setContents={state?.content}
                    id="emailSectionSunEditor"
                />
            </Box>
        </Box>
        <Box
            style={{
                justifyContent: "flex-end",
                display: "flex",
                gap: "20px",
                marginTop: "3.1rem",
            }}
            my={"20px"}
        >
            <ButtonComponent
                variant={"outlined"}
                onClick={handlerCancelOnClick}
                title={<Translate>Cancel</Translate>}
                id="emailSectionCancelButton"
            />
        </Box>
    </>
    
    );
}
const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,
    };
};

export default connect(mapStateToProps, null)(EmailSection);
