import axios from "axios";
import { BASE_URL, API_CONSTANTS } from "../../config/ApiConstants";
import { postApiCall, getApiCall, putApiCall } from "../../utils/Action";
import { getNotificationConfig } from "./NotificationConfigAction";
import { createUserProfile } from "./UserAction";
import { toast } from "react-hot-toast";

export const SET_SELECTED_LOGGED_IN_USER = "SET_SELECTED_LOGGED_IN_USER";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const LOGIN_ACTION = "LOGIN_ACTION";
export const LOGOUT_ACTION = "LOGOUT_ACTION";
export const GET_DROPDOWN = "GET_DROPDOWN";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const FORGET_PASSWORD = "FORGET_PASSWORD";
export const DIRECT_LOGIN = "DIRECT_LOGIN"
export const OTP_VERIFICATION = "OTP_VERIFICATION"

export const EDIT_PROFILE_IMAGE = "EDIT_PROFILE_IMAGE";
export const UPDATE_USER_PERMISSION = "UPDATE_USER_PERMISSION";

export const performLoginAction = (email, password) => {
    return async (dispatch, getState) => {
        try {
            const currentState = getState();
            console.log(getState)
            const response = await axios.post(BASE_URL + "auth/login", {
                email,
                password,
                client_id: currentState?.whiteLabel?.client_id || "",
            });
            
            if (response?.status === 200) {
                 localStorage.setItem("token", response?.data?.result?.token);
                localStorage.setItem("language", response?.data?.result?.user.language_id);
                localStorage.setItem("languageCode", `${
                    response?.data?.result?.user.language_id == 38 ? "en" : (
                        response?.data?.result?.user.language_id == 48 ? "fr" : (
                            response?.data?.result?.user.language_id == 40 ? "es" : "en"
                      )
                    )
                  }`);
                await dispatch(
                    getNotificationConfig(
                        response.data.result.app_variables,
                        response.data.result.token
                    )
                );
                await dispatch(getDropdown());
                return dispatch({
                    type: LOGIN_ACTION,
                    payload: response.data.result,
                });
            }
            await dispatch(getDropdown());
        } catch (error) {

            toast.error("Email or password is incorrect.");
        }
    };
};

export const verifyOtp = (email, verification_code) => {
    return async (dispatch) => {
        try {
            const response = await axios.post(BASE_URL + "auth/verify-otp", {
                email,
                verification_code,
            });
            if (response?.status === 200) {
                return dispatch({
                    type: OTP_VERIFICATION,
                    payload: response?.status,
                });
            }
        } catch (error) {
            console.error('Error:', error); // Add this line for debugging

            toast.error("Please enter a valid OTP.");
        }
    };
};

export const performLogOutAction = (history) => {
    return async (dispatch) => {
        try {
            await postApiCall("/auth/logout");
            localStorage.clear();
            window.history.pushState([], [], window.location.href);
            window.onpopstate = function (event) {
                history.go(0);
            };
            dispatch({ type: LOGOUT_ACTION });
        } catch (error) {

            throw error;
        }
    };
};

export const getLoggedInUser = (history) => {
    return async (dispatch) => {
        try {
            const response = await getApiCall("/auth/user");
            if (response?.status === 401) {
                dispatch({ type: LOGOUT_ACTION });
                localStorage.clear();
                window.history.pushState([], [], window.location.href);
                window.onpopstate = function (event) {
                    history.go(0);
                };
            } else {
                localStorage.setItem('language', response?.data?.result?.user?.language_id)
                await dispatch(getDropdown());
                return dispatch({
                    type: LOGIN_ACTION,
                    payload: response?.data?.result,
                });
            }
        } catch (error) {

            throw error;
        }
    };
};

export const getDropdown = () => {
    return async (dispatch) => {
        try {
            const response = await getApiCall("common/get-dropdowns");
            dispatch({
                type: GET_DROPDOWN,
                payload: response?.data?.result || [],
            });
        } catch (error) {

            throw error;
        }
    };
};

export const resetPassword = (token, password, password_confirmation, guid) => {
    return async (dispatch) => {
        try {
            const response = await postApiCall("auth/reset-password", {
                token,
                password,
                password_confirmation,
                guid
            });
            if (response?.status === 200) {
                return dispatch({
                    type: RESET_PASSWORD,
                    payload: response.data.message,
                });
            }
        } catch (error) {

            throw error;
        }
    };
};

export const forgotPassword = (email, guid) => {
    return async (dispatch) => {
        try {
            const response = await postApiCall("auth/forgot-password", { email, guid });
            if (response?.status === 200) {
                return dispatch({
                    type: FORGET_PASSWORD,
                    payload: response.data.message,
                });
            }
        } catch (error) {

            throw error;
        }
    };
};

export const updateProfile = (state, id) => {
    return async (dispatch) => {
        try {
            const response = await putApiCall(
                API_CONSTANTS.UPDATE_PROFILE + `/${id}`,
                state
            );
            let imageRes;
            if (state?.photograph?.filename && state?.photograph?.content) {
                imageRes = await dispatch(
                    createUserProfile(
                        response?.data?.result?.id,
                        state?.photograph
                    )
                );
            }
            if (response?.status === 200 || imageRes?.status === 200) {
                return dispatch({
                    type: UPDATE_PROFILE,
                    payload: {
                        ...response?.data?.result,
                        photograph: imageRes?.data?.result?.photograph,
                    },
                });
            } else {
                return false;
            }
        } catch (error) {

            throw error;
        }
    };
};

export const updateUserPermission = (data) => {
    return async (dispatch) => {
        dispatch({ type: UPDATE_USER_PERMISSION, payload: data });
    };
};

export const directLoginWithLink = (data) => {
    return async (dispatch) => {
        try {
            const payload = {
                login_string: data,
            };
            
            const response = await postApiCall("/auth/login-email", payload); 
            console.log(response)
            if (response.data.result.token) {
                localStorage.setItem("token", response?.data?.result?.token);
                localStorage.setItem("language", response?.data?.result?.user.language_id);
                localStorage.setItem("languageCode", `${
                    response?.data?.result?.user.language_id == 38 ? "en" : (
                        response?.data?.result?.user.language_id == 48 ? "fr" : (
                            response?.data?.result?.user.language_id == 40 ? "es" : "en"
                      )
                    )
                  }`);
                await dispatch(
                    getNotificationConfig(
                        response.data.result.app_variables,
                        response.data.result.token
                    )
                );
                await dispatch(getDropdown());
                return dispatch({
                    type: DIRECT_LOGIN,
                    payload: response.data.result,
                });
            }

            

        } catch (error) {
            throw error;
        }
    };
}

export const signUpAction = (data) => {
    return async (dispatch) => {
        try {
            const response = await postApiCall(API_CONSTANTS.SIGN_UP, data);
            if (response?.status === 200) {
                return true;
            }
        } catch (error) {

            throw error;
        }
    };
};

export const getClientById = (id) => {
   
    return async () => {
        try {
            const response = await getApiCall(`/clients/${id}`);
            if (response?.status === 200) {
                return response.data;
            }
        } catch (error) {
            throw error;
        }
    }
}
