import React, { Fragment, useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useNavigate } from "react-router-dom";
import { NestedMenuItem } from "mui-nested-menu";
import Notification from "../Notification/Notification";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import MenuItem from "@mui/material/MenuItem";
import "../../../assets/styles/AppbarModule.scss";
import MainLogo from "../../../assets/Images/Logo/Logo.png";
import NavbarDrawer from "./Drawer";
import {
    performLogOutAction,
    getLoggedInUser,
} from "../../store/Action/LoginAction";
import {
    getMenuList,
    getSettingsMenu,
    getHelpMenu,
    getAccountMenu,
    getFormStudio,
    getDashboard,
    getNotification,
    menuList,
} from "../../router/NavigationMenu";
import { ROUTES } from "../../router/RouteList";
import { getWhiteLabelInfo } from "../../store/Action/WhiteLabelAction";
import TooltipComponent from "../../components/ToolTip/TooltipComponent";
import { defaultFavicon } from "./../../utils/CommonData";
import SignUpNavbar from "./SignUpNavbar";
import ButtonComponent from "../../components/ButtonComponent";
import { getUserList } from "../../store/Action/UserAction";
import { Translate } from "react-auto-translate";

function Navbar(props) {
    const navigate = useNavigate();
    const {
        performLogOutAction,
        history,
        getLoggedInUser,
        getWhiteLabelInfo,
        whiteLabel,
        userLoggedIn,
        getUserList,
    } = props;
    const [state, setState] = useState({
        DrawerOpen: false,
        anchorElNav: null,
        anchorElUser: null,
        anchorElAccount: null,
        loading: false,
    });
    const { DrawerOpen, anchorElNav, anchorElUser, anchorElAccount, loading } =
        state;
    const path = window.location.pathname;
    const token = window.localStorage.getItem("token");

    useEffect(() => {
        async function apiCall() {
            setState((prevState) => ({ ...prevState, loading: true }));
            const response = await getWhiteLabelInfo(
                window.location.origin,
                navigate
            );
            let link = document.querySelector("link[rel~='icon']");
            if (!link) {
                link = document.createElement("link");
                link.rel = "icon";
                document.getElementsByTagName("head")[0].appendChild(link);
            }
            link.href = response?.payload?.use_company_name
                ? null
                : response?.payload?.favicon || defaultFavicon;
            document.title = response?.payload?.company_name || "Elevance";
            document.documentElement.style.setProperty(
                "--default-primary-color",
                response?.payload?.color_scheme?.primary
            );
            document.documentElement.style.setProperty(
                "--default-secondary-color",
                response?.payload?.color_scheme?.secondary
            );
            document.documentElement.style.setProperty(
                "--default-tertiary-color",
                response?.payload?.color_scheme?.tertiary
            );
            setState((prevState) => ({ ...prevState, loading: false }));
        }
        if (whiteLabel?.client_id === undefined) {
            apiCall();
        }
        if (!userLoggedIn && token) getLoggedInUser(history);
    }, [
        getLoggedInUser,
        history,
        getMenuList(),
        navigate,
        whiteLabel,
        userLoggedIn,
        token,
    ]);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (e) => setAnchorEl(e.currentTarget);
    const handleClose = () => setAnchorEl(null);
    const handleOpenNavMenu = () => {
        setState((prevState) => ({ ...prevState, DrawerOpen: true }));
    };
    const handleOpenUserMenu = (event) => {
        setState((prevState) => ({
            ...prevState,
            anchorElUser: event.currentTarget,
        }));
    };
    const handleCloseNavMenu = (option) => {
        option?.url && navigate(option?.url);
        setState((prevState) => ({
            ...prevState,
            anchorElNav: null,
            anchorElAccount: null,
        }));
    };
    const handleCloseUserMenu = async (option) => {
        if (option.title === "Logout") {
            await performLogOutAction(history);
            navigate(ROUTES.LOGIN);
        } else navigate(option.url);

        setState((prevState) => ({ ...prevState, anchorElUser: null }));
    };

    const handleManageMenu = async (option) => {
        if (option.id === "users") {
            userLoggedIn.user.client_id &&
                (await getUserList(userLoggedIn.user.client_id));
            option?.url && userLoggedIn.user.client_id
                ? navigate(option.url + `${"?" + userLoggedIn.user.client_id}`)
                : navigate(option.url);
        } else {
            option?.url && navigate(option.url);
        }
    };
    const memoizedMenuList = useMemo(() => getMenuList(), []);
    
    return (
        <Fragment>
            <NavbarDrawer open={DrawerOpen} setClose={setState} />
            <AppBar className="appbar" position="static">
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        <Box
                            sx={{ display: "flex" }}
                            className="navbarLogo"
                            onClick={() =>
                                navigate(
                                    localStorage.getItem("role_id") == 2
                                        ? null
                                        : "/"
                                )
                            }
                        >
                            {whiteLabel?.use_company_name ? (
                                <Typography className="companyNameHeading">
                                    {whiteLabel?.company_name || "Elevance"}
                                </Typography>
                            ) : (
                                <img
                                    src={whiteLabel?.logo || MainLogo}
                                    alt="mainLogo"
                                />
                            )}
                        </Box>
                        {path !== "/login" && token && token !== "undefined" ? (
                            <Fragment>
                                <Box
                                    sx={{
                                        flexGrow: 1,
                                        display: {
                                            xs: "flex",
                                            md: "none",
                                        },
                                        textAlign: "right",
                                    }}
                                >
                                    <IconButton
                                        size="large"
                                        onClick={handleOpenNavMenu}
                                    >
                                        <MenuIcon />
                                    </IconButton>
                                </Box>
                                <Box
                                    sx={{
                                        display: {
                                            xs: "flex",
                                            md: "none",
                                        },
                                        mr: 1,
                                    }}
                                >
                                    <img src={MainLogo} alt="mainLogo" />
                                </Box>
                                <Box
                                    mx={3}
                                    sx={{
                                        flexGrow: 1,
                                        display: {
                                            xs: "none",
                                            md: "flex",
                                        },
                                        justifyContent: "flex-end",
                                    }}
                                    className="navbarLinks"
                                >
                                    {getDashboard() &&
                                        userLoggedIn?.user.role_id !== 2 && (
                                            <ButtonComponent
                                                title={
                                                    <Translate>
                                                        {userLoggedIn
                                                            ?.language_data
                                                            ?.comman
                                                            ?.menu_dashboard ||
                                                            "Dashboard"}
                                                    </Translate>
                                                }
                                                className="headerButton"
                                                onClick={() =>
                                                    navigate(ROUTES.DASHBOARD)
                                                }
                                            />
                                        )}
                                    {getDashboard() &&
                                        userLoggedIn?.user.role_id !== 2 && (
                                            <ButtonComponent
                                                title={
                                                    <Translate>
                                                        Workers
                                                    </Translate>
                                                }
                                                className="headerButton"
                                                onClick={() =>
                                                    navigate(ROUTES.USER)
                                                }
                                            />
                                        )}
                                         {getDashboard() &&
                                        userLoggedIn?.user.role_id === 1 && (
                                            <ButtonComponent
                                                title={
    
                                                    "Training Library"
                                                }
                                                className="headerButton"
                                                onClick={() =>
                                                    navigate(ROUTES.TRAINING)
                                                }
                                            />
                                        )}
                                    {userLoggedIn?.user.role_id !== 2 && (
                                        <div>
                                            <ButtonComponent
                                                title={
                                                    <Translate>
                                                        {userLoggedIn
                                                            ?.language_data
                                                            ?.comman
                                                            ?.menu_manage ||
                                                            "Manage"}
                                                    </Translate>
                                                }
                                                className="headerButton"
                                                onClick={handleClick}
                                            />
                                            <Menu
                                                anchorEl={anchorEl}
                                                open={open}
                                                onClose={handleClose}
                                                MenuListProps={{
                                                    disablePadding: true,
                                                }}
                                            >
                                                {memoizedMenuList?.map(
                                                    (setting) =>
                                                        setting.title ===
                                                        "Tests" ? (
                                                            <NestedMenuItem
                                                                key={setting.id}
                                                                MenuProps={{
                                                                    MenuListProps:
                                                                        {
                                                                            disablePadding: true,
                                                                        },
                                                                }}
                                                                className="headerMenuItem"
                                                                label="Tests"
                                                                parentMenuOpen={
                                                                    open
                                                                }
                                                                sx={{
                                                                    "& .MuiBox-root":
                                                                        {
                                                                            "& .MuiTypography-root":
                                                                                {
                                                                                    color: "red",
                                                                                },
                                                                        },
                                                                }}
                                                            >
                                                                {setting?.subMenuList?.map(
                                                                    (
                                                                        subMenu
                                                                    ) => (
                                                                        <MenuItem
                                                                            key={
                                                                                subMenu.id
                                                                            }
                                                                            className="headerMenuItem"
                                                                            onClick={() =>
                                                                                handleManageMenu(
                                                                                    subMenu
                                                                                )
                                                                            }
                                                                        >
                                                                            {
                                                                                subMenu?.title
                                                                            }
                                                                        </MenuItem>
                                                                    )
                                                                )}
                                                            </NestedMenuItem>
                                                        ) : (
                                                            <MenuItem
                                                                key={setting.id}
                                                                onClick={() =>
                                                                    handleManageMenu(
                                                                        setting
                                                                    )
                                                                }
                                                                className={
                                                                    "headerMenuItem"
                                                                }
                                                            >
                                                                {setting.title}
                                                            </MenuItem>
                                                        )
                                                )}
                                            </Menu>
                                        </div>
                                    )}
                                    {getDashboard() &&
                                        userLoggedIn?.user.role_id !== 2 && (
                                            <div>
                                                <ButtonComponent
                                                    title={
                                                        <Translate>
                                                            Account
                                                        </Translate>
                                                    }
                                                    className="headerButton"
                                                    onClick={(event) =>
                                                        setState(
                                                            (prevState) => ({
                                                                ...prevState,
                                                                anchorElAccount:
                                                                    event.currentTarget,
                                                            })
                                                        )
                                                    }
                                                />
                                                <Menu
                                                    sx={{ mt: "45px" }}
                                                    anchorEl={anchorElAccount}
                                                    anchorOrigin={{
                                                        vertical: "top",
                                                        horizontal: "right",
                                                    }}
                                                    transformOrigin={{
                                                        vertical: "top",
                                                        horizontal: "right",
                                                    }}
                                                    open={Boolean(
                                                        anchorElAccount
                                                    )}
                                                    onClose={handleCloseNavMenu}
                                                    MenuListProps={{
                                                        disablePadding: true,
                                                    }}
                                                >
                                                    {getAccountMenu()?.length >
                                                    0
                                                        ? getAccountMenu()?.map(
                                                              (setting) => (
                                                                  <MenuItem
                                                                      key={
                                                                          setting.id
                                                                      }
                                                                      onClick={() =>
                                                                          handleCloseNavMenu(
                                                                              setting
                                                                          )
                                                                      }
                                                                      className={
                                                                          "headerMenuItem"
                                                                      }
                                                                  >
                                                                      {
                                                                          setting?.title
                                                                      }
                                                                  </MenuItem>
                                                              )
                                                          )
                                                        : null}
                                                </Menu>
                                            </div>
                                        )}
                                    <div>
                                        <ButtonComponent
                                            title={
                                                <Translate>
                                                    {userLoggedIn?.language_data
                                                        ?.comman?.menu_help ||
                                                        "Resources"}
                                                </Translate>
                                            }
                                            className="headerButton"
                                            onClick={(event) =>
                                                setState((prevState) => ({
                                                    ...prevState,
                                                    anchorElNav:
                                                        event.currentTarget,
                                                }))
                                            }
                                        />
                                        <Menu
                                            sx={{ mt: "45px" }}
                                            anchorEl={anchorElNav}
                                            anchorOrigin={{
                                                vertical: "top",
                                                horizontal: "right",
                                            }}
                                            transformOrigin={{
                                                vertical: "top",
                                                horizontal: "right",
                                            }}
                                            open={Boolean(anchorElNav)}
                                            onClose={handleCloseNavMenu}
                                            MenuListProps={{
                                                disablePadding: true,
                                            }}
                                        >
                                            {getHelpMenu()?.length > 0
                                                ? getHelpMenu().map(
                                                      (setting) => (
                                                          <MenuItem
                                                              key={setting.id}
                                                              onClick={() =>
                                                                  handleCloseNavMenu(
                                                                      setting
                                                                  )
                                                              }
                                                              className={
                                                                  "headerMenuItem"
                                                              }
                                                          >
                                                              {setting.title}
                                                          </MenuItem>
                                                      )
                                                  )
                                                : null}
                                        </Menu>
                                    </div>
                                    {getNotification() && <Notification />}
                                    <TooltipComponent
                                        title={
                                            <Translate>Open settings</Translate>
                                        }
                                        arrow={true}
                                    >
                                        <Avatar
                                            alt="Remy Sharp"
                                            src={userLoggedIn?.user?.photograph}
                                            className={"navbarProfile"}
                                            onClick={handleOpenUserMenu}
                                        >
                                            {userLoggedIn?.user?.photograph ||
                                                userLoggedIn?.user?.first_name?.charAt(
                                                    0
                                                ) ||
                                                null}
                                        </Avatar>
                                    </TooltipComponent>
                                    <Menu
                                        anchorEl={anchorElUser}
                                        anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "left",
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: "top",
                                            horizontal: "left",
                                        }}
                                        open={Boolean(anchorElUser)}
                                        onClose={() =>
                                            setState((prevState) => ({
                                                ...prevState,
                                                anchorElUser: null,
                                            }))
                                        }
                                        MenuListProps={{
                                            disablePadding: true,
                                        }}
                                    >
                                        {getSettingsMenu()?.map((setting) => (
                                            <MenuItem
                                                key={setting.disableGutters}
                                                onClick={() =>
                                                    handleCloseUserMenu(setting)
                                                }
                                                className={"headerMenuItem"}
                                            >
                                                {setting?.title}
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </Box>
                            </Fragment>
                        ) : (
                            <SignUpNavbar
                                setState={setState}
                                navigate={navigate}
                            />
                        )}
                    </Toolbar>
                </Container>
            </AppBar>
        </Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        whiteLabel: state?.whiteLabel,
        userLoggedIn: state.auth?.userLoggedIn,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            performLogOutAction,
            getLoggedInUser,
            getWhiteLabelInfo,
            getUserList,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
