import { API_CONSTANTS } from "../../config/ApiConstants";
import { postApiCall, getApiCall } from "../../utils/Action";

export const GET_CATEGORY_DROPDOWN_DATABASE = "GET_CATEGORY_DROPDOWN_DATABASE";
export const GET_DOCUMENT_DATABASE_DATA = " GET_DOCUMENT_DATABASE_DATA";
export const SET_SELECTED_DATA = " SET_SELECTED_DATA";
export const STORE_DOCUMENT_DATABASE = "STORE_DOCUMENT_DATABASE";
export const UPDATE_DOUCUMENT_DATABASE = "UPDATE_DOUCUMENT_DATABASE";
export const DELETE_DOCUMENT_DATABASE = "DELETE_DOCUMENT_DATABASE";
export const SET_DOCUMENT_PER_PAGE = "SET_DOCUMENT_PER_PAGE";
export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
export const GET_DOCUMENT_APRROVED_LIST = "GET_DOCUMENT_APRROVED_LIST";

export const getCategoryDropdownDatabase = () => {
    return async (dispatch) => {
        try {
            const response = await getApiCall(
                API_CONSTANTS.GET_DOCUMENT_DATABASE_CATEGORY
            );
            return dispatch({
                type: GET_CATEGORY_DROPDOWN_DATABASE,
                payload: response?.data?.result || [],
            });
        } catch (error) {}
    };
};
export const setSelectedDetailsDataBase = (data) => {
    return async (dispatch) => {
        dispatch({ type: SET_SELECTED_DATA, payload: data });
    };
};
export const getDocumentDatabaseById = (id, mode) => {
    return async (dispatch) => {
        try {
            if (!id) {
                return;
            }

            const response = await postApiCall(
                API_CONSTANTS.GET_DOCUMENT_DATABASE + `/${id}`
            );
            
            dispatch({
                type: SET_SELECTED_DATA,
                payload: { ...response?.data?.result, mode: mode } || [],
            });
        } catch (error) {
            throw error;
        }
    };
};
export const getDocumentDatabaseData = (
    search= '',
    currentPage = 1,
    customerPageLimit = 8,
    pagination = null,
) => {
    return async (dispatch) => {
        try {
            let response;
            if (pagination) {
                if(search?.length > 0) {
                    response = await postApiCall(
                        API_CONSTANTS.GET_DOCUMENT_DATABASE_DETAIL + 
                            "?search=" + search +
                            "&page=" +
                            currentPage +
                            "&page_limit=" +
                            customerPageLimit
                    );
                } else {
                    response = await postApiCall(
                        API_CONSTANTS.GET_DOCUMENT_DATABASE_DETAIL + 
                            "?page=" +
                            currentPage +
                            "&page_limit=" +
                            customerPageLimit
                    );
                }
            } else {
                if(search?.length > 0) {
                    response = await postApiCall(
                        API_CONSTANTS.GET_DOCUMENT_DATABASE_DETAIL + 
                        "?search=" + search +
                        "&page=" +
                        currentPage +
                        "&page_limit=" +
                        customerPageLimit
                    );
                } else {
                    response = await postApiCall(
                        API_CONSTANTS.GET_DOCUMENT_DATABASE_DETAIL + 
                        "?page=" +
                        currentPage +
                        "&page_limit=" +
                        customerPageLimit
                    );
                }
                response = await postApiCall(
                    API_CONSTANTS.GET_DOCUMENT_DATABASE_DETAIL + 
                    "?search=" + search +
                    "&page=" +
                    currentPage +
                    "&page_limit=" +
                    customerPageLimit
                );
                response.data.result.data = response.data.result;
            }
            dispatch({
                type: GET_DOCUMENT_DATABASE_DATA,
                payload: response?.data?.result || [],
            });
        } catch (error) {}
    };
};
export const storeDocumentDatabase = (state) => {
    return async (dispatch, getState) => {
        try {
            const currentState = getState();
            const docdataList = currentState.docDatabase.docDatabasePagination;
            const response = await postApiCall(
                API_CONSTANTS.STORE_DOCUMENT_DATABASE_DETAILS,
                state
            );
            console.log("storeDocumentDatabase Resonse", response);
            if (response.status === 201) {
                if (docdataList.listViewLimit > docdataList.total) {
                    return dispatch({
                        type: STORE_DOCUMENT_DATABASE,
                        payload: response?.data?.result,
                    });
                } else return true;
            }
        } catch (error) {}
    };
};
export const updateDocumentDatabase = (data) => {
    return async (dispatch) => {
        try {
            const response = await postApiCall(
                API_CONSTANTS.UPDATE_DOCUMENT_DATABASE_DETAILS + `/${data?.id}`,
                data
            );
            if (response.status === 201) {
                return dispatch({
                    type: UPDATE_DOUCUMENT_DATABASE,
                    payload: {
                        ...response?.data?.result,
                        data,
                    },
                });
            }
        } catch (error) {}
    };
};
export const deleteDocumentDatabase = (data) => {
    return async (dispatch, getState) => {
        try {
            const currentState = getState();
            const docDataDelete =
                currentState.docDatabase.docDatabasePagination;
            const response = await postApiCall(
                API_CONSTANTS.DELETE_DOCUMENT_DATABASE_DETAILS + `/${data.id}`
            );
            if (response.status === 200) {
                dispatch({
                    type: DELETE_DOCUMENT_DATABASE,
                    payload: data,
                });
                dispatch(
                    getDocumentDatabaseData(
                        null,
                        docDataDelete?.currentPage,
                        docDataDelete?.listViewLimit,
                        true
                    )
                );
            }
        } catch (error) {
            throw error;
        }
    };
};

export const setCustomerPageLimit = (data) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: SET_DOCUMENT_PER_PAGE,
                payload: data,
            });
        } catch (error) {
            throw error;
        }
    };
};

export const setCurrentPage = (pageNumber) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: SET_CURRENT_PAGE,
                payload: pageNumber,
            });
        } catch (error) {
            throw error;
        }
    };
};

export const getDocumentAprrovedList = (guid) => {
    return async (dispatch) => {
        try {
            const response = await postApiCall(`document-database/get-list`, {
                guid: "",
                status: "1",
                is_active: true,
            });
            if (response?.status === 200) {
                return dispatch({
                    type: GET_DOCUMENT_APRROVED_LIST,
                    payload: response.data.result,
                });
            }
        } catch (error) {
            throw error;
        }
    };
};
