import { API_CONSTANTS } from "../../config/ApiConstants";
import { getApiCall, postApiCall, putApiCall, deleteApiCall } from "../../utils/Action"
import { toast } from "react-hot-toast";

export const GET_RCPTASKS_ACTION = "GET_RCPTASKS_ACTION";
export const UPDATE_RCPTASK_STATUS_ACTION = "UPDATE_RCPTASK_STATUS_ACTION";
export const DELETE_RCPTASKS_ACTION = "DELETE_RCPTASKS_ACTION";

export const rcpTasksAction = (guid, id) => {
    return async (dispatch) => {
        try {
            const response = await getApiCall(API_CONSTANTS.GET_TASKS, { guid:guid, contractor_id:id });
            if (response?.status === 200) {
                return dispatch({
                    type: GET_RCPTASKS_ACTION,
                    payload: response?.data.result,
                });
            } else if (response?.status === 422){
                return dispatch({
                    type: GET_RCPTASKS_ACTION,
                    payload: [],
                });
            }
        } catch (error) {
            throw error
        }
    };
};

export const rcpTasksAddAction = (data) => {
    return async (dispatch) => {
        try {
            const response = await postApiCall(API_CONSTANTS.GET_TASKS, data);
            if (response.status === 201) {
                return dispatch({
                    type: GET_RCPTASKS_ACTION,
                    payload: response.data.result,
                });

            } else {
                // toast.error(response?.data?.message);
            }
        } catch (error) {
            throw error;
        }
    };
};

export const rcpTasksUpdateAction = (data, guid) =>{
    return async (dispatch) => {
        try {
            const response = await putApiCall(API_CONSTANTS.UPDATE_TASKS + guid, {tasks:data});
            if (response.status === 200) {
                toast.success(response?.data?.message)
                return dispatch(rcpTaskListAction(guid));
            } else {
                // toast.error(response?.data?.message);
            }
        } catch (error) {
            throw error;
        }
    };
}

export const rcpTaskDeleteAction = (id, guid) =>{
    return async (dispatch) => {
        try{
            const response = await deleteApiCall(API_CONSTANTS.DELETE_TASKS+id, {client: guid});
            if (response.status === 200) {
                return dispatch({
                    type: DELETE_RCPTASKS_ACTION,
                    payload: id
                });
            } else {
                // toast.error(response?.data?.message);
            }
        }catch(error){
            throw error;
        }
    }
}

export const rcpTaskAssignToUserAction = (data)=>{
    return async (dispatch) =>{
        try{
            const response = await putApiCall(API_CONSTANTS.ASSIGN_TASK, data);
            if(response.status === 200){
                toast.success(response?.data?.message);
                return dispatch(rcpTaskListAction(data.guid));
            }else{
                // toast.error(response?.data?.message);
            }
        }catch(error){
            throw error;
        }
    }
}

export const rcpTaskListAction = (guid, type)=>{
    return async (dispatch)=>{
        try{
            const response = await getApiCall(API_CONSTANTS.GET_RCP_TASKS+guid, {type: type})
            if (response.status === 200) {
                return dispatch({
                    type: GET_RCPTASKS_ACTION,
                    payload: response.data.result,
                });
            } else if (response.status === 422){
                return dispatch({
                    type: GET_RCPTASKS_ACTION,
                    payload: [],
                });
            }
        }catch(error){
            throw error;
        }
    }
}

export const rcpTasksStatusUpdateAction = (data) =>{
    return async (dispatch) => {
        try {
            const response = await putApiCall(API_CONSTANTS.UPDATE_TASKS_STATUS ,data);
            if (response?.status === 200) {
                toast.success(response?.data?.message)
                return dispatch(rcpTasksAction(data?.guid,data.contractor_id));
            }
        } catch (error) {
            throw error
        }
    };
}
