import React from "react";
import ButtonComponent from "../ButtonComponent";
import DialogComponent from "./DialogComponent";

const ComplienceStatusDilaog = (props) => {
    const {
        title,
        open,
        content,
        handleClose,
        buttonTitle,
        handleButtonSubmit,
        id,
    } = props;
    return (
        <DialogComponent
            id={id}
            open={open}
            title={title}
            handleClose={handleClose}
            ariaLabelledby="alert-dialog-title"
            ariaDescribedby="alert-dialog-description"
            sxContent={{
                textAlign: "center",
                py: "40px",
                px: "70px",
                width: "100%",
                borderRadius: "20px",
            }}
            content={content}
            className="complianceStatus"
            action={
                <>
                    <ButtonComponent
                        id={`${id}CompStateSubmitButton`}
                        title={buttonTitle}
                        className="button_contained"
                        onClick={handleButtonSubmit}
                    />
                </>
            }
        />
    );
};

export default ComplienceStatusDilaog;
