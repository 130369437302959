import * as Actions from "../Action/DocumentDatabaseAction";

const initialState = {
    categoryDropdown: null,
    documentDatabase: null,
    selectedDocumentDatabaseData: null,
    docDatabasePagination: {
        listViewLimit: 8,
        total: "",
        currentPage: 1,
        lastPage: "",
    },
};

const DocumentDataBaseReducer = (state = initialState, action) => {
    switch (action?.type) {
        case Actions.GET_CATEGORY_DROPDOWN_DATABASE: {
            return {
                ...state,
                categoryDropdownDatabase: action.payload,
            };
        }
        case Actions.GET_DOCUMENT_DATABASE_DATA: {
            return {
                ...state,
                documentDatabase: action.payload?.data,
                docDatabasePagination: {
                    ...state.docDatabasePagination,
                    total: action?.payload?.total,
                    lastPage: action?.payload?.last_page,
                    currentPage: action?.payload?.current_page,
                },
            };
        }
        case Actions.SET_SELECTED_DATA: {
            return {
                ...state,
                selectedDocumentDatabaseData: action.payload,
            };
        }
        case Actions.GET_DOCUMENT_APRROVED_LIST: {
            return {
                ...state,
                selectedDocumentDatabaseData: action.payload,
            };
        }
        case Actions.STORE_DOCUMENT_DATABASE: {
            return {
                ...state,
                documentDatabase: [action.payload, ...state.documentDatabase],
            };
        }
        case Actions.DELETE_DOCUMENT_DATABASE: {
            let documentList = state?.documentDatabase?.filter(
                (obj) => obj.id !== action?.payload?.id
            );
            return {
                ...state,
                documentDatabase: documentList,
            };
        }
        case Actions.UPDATE_DOUCUMENT_DATABASE: {
            let documentList = state?.documentDatabase;
            let index = state?.documentDatabase?.findIndex(
                (obj) => obj?.id === action?.payload?.id
            );
            if (index !== -1) documentList[index] = action.payload;
            return {
                ...state,
                documentDatabase: documentList,
            };
        }
        case Actions.SET_DOCUMENT_PER_PAGE: {
            return {
                ...state,
                docDatabasePagination: {
                    ...state.docDatabasePagination,
                    listViewLimit: action?.payload,
                },
            };
        }
        default: {
            return state;
        }
    }
};

export default DocumentDataBaseReducer;
