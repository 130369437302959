import * as Actions from "../Action/NotificationAction";

const initialState = {
    list: {},
    count: 0,
};

const dialog = (state = initialState, action) => {
    switch (action?.type) {
        case Actions.GET_LIST_WITH_COUNT: {
            return {
                ...state,
                list: { ...action.payload.list },
                count: action.payload.count || 0,
            };
        }
        case Actions.REFRESH_LIST: {
            const values = Object.values(state.list);
            values.unshift(action.payload);
            return {
                ...state,
                list: { ...values },
                count: parseInt(state.count) + 1,
            };
        }
        case Actions.NOTIFICATION_DELETE: {
            const deletedNotification = Object.values(state.list).filter(
                (value) => value.id === action.payload
            );
            const count =
                state.count > 0
                    ? !deletedNotification[0].read_at
                        ? parseInt(state.count) - 1
                        : state.count
                    : state.count;
            const values = Object.values(state.list).filter(
                (value) => value.id !== action.payload
            );
            return {
                ...state,
                list: { ...values },
                count: count,
            };
        }
        case Actions.READ_SINGLE: {
            const values = Object.values(state.list);
            const index = values.findIndex((item) => {
                return item.id === action.payload.id;
            });
            values[index].read_at = action.payload.read_at;
            return {
                ...state,
                list: { ...values },
                count: state.count > 0 ? parseInt(state.count) - 1 : 0,
            };
        }
        case Actions.READ_ALL: {
            return {
                ...state,
                list: { ...action.payload.list },
                count: action.payload.count,
            };
        }
        default: {
            return state;
        }
    }
};

export default dialog;
