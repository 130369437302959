import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Navbar from "../../common/Header/Navbar";
import HeaderDrawerLink from "./HeaderDrawerLink";
import FormCollectionPopper from "./FormCollectionPopper";
import ViewHeader from "./ViewHeader";
import ViewResponseBox from "./ViewResponseBox";
import RenderElements from "./RenderElements";
import CommonDeleteDialog from "../../components/DialogsBox/CommonDeleteDialog";
import LoadingComponent from "../../components/Loading/LoadingComponent";
import { ROUTES } from "../../router/RouteList";
import {
    deleteForm,
    exportSubmissions,
    getSubmissionsById,
    getFormById,
} from "../../store/Action/FormStudioAction";
import { Translate } from 'react-auto-translate';
import ButtonComponent from "../../components/ButtonComponent";
const ViewForm = (props) => {
    const {
        deleteForm,
        exportSubmissions,
        getSubmissionsById,
        getFormById,
        formDetail,
    } = props;
   
    const location = useLocation();
    const [state, setState] = useState({
        popperOpen: false,
        anchorEl: null,
        formData: formDetail?.form_data || null,
        fontColor: formDetail?.font_color || "#76520E",
        formColor: formDetail?.form_color || "#F2F2F2",
        pageColor: formDetail?.page_color || "",
        openDeleteDialog: {
            open: false,
            data: null,
        },
        openDownloadDialog: {
            open: false,
            data: null,
        },
        isLoading: false,
        activeLink: "",
        logo: null,
    });
    const {
        openDeleteDialog,
        popperOpen,
        anchorEl,
        formData,
        pageColor,
        isLoading,
        openDownloadDialog,
        activeLink,
        logo,
    } = state;
    const navigate = useNavigate();
    const formDetailsRef = useRef(null);
    const pathName = window.location.pathname.split("/");
    useEffect(() => {
        async function callApi() {
            setState((prevState) => ({ ...prevState, isLoading: true }));
            await getFormById(pathName[3]);
            await getSubmissionsById(pathName[3]);
        }
        callApi();
    }, [getSubmissionsById, getFormById, pathName[3]]);

    if (formDetail && formDetailsRef?.current !== formDetail) {
        formDetailsRef.current = formDetail;
        setState((prevState) => ({
            ...prevState,
            formData: formDetail?.form_data,
            fontColor: formDetail?.font_color,
            formColor: formDetail?.form_color,
            pageColor: formDetail?.page_color,
            isLoading: false,
        }));
    }
    if (formDetail && formData === null) {
        setState((prevState) => ({
            ...prevState,
            formData: formDetail?.form_data,
            fontColor: formDetail?.font_color,
            formColor: formDetail?.form_color,
            pageColor: formDetail?.page_color,
            logo: formDetail?.logo,
        }));
    }

    const handlerEditForm = () => {
        navigate(ROUTES.FORM_BUILDER + "/edit/" + location?.state?.item?.id, {
            state: { formData: location?.state?.item },
        });
    };
    const handlerRemoveForm = (id, name) => {
        setState((prevState) => ({
            ...prevState,
            openDeleteDialog: {
                open: true,
                data: { id: id, formName: name },
            },
        }));
    };
    const closeDeleteDialog = () => {
        setState((prevState) => ({
            ...prevState,
            openDeleteDialog: { open: false, data: null },
            openDownloadDialog: { open: false, data: null },
        }));
    };
    const removeForm = async (deletedData) => {
        const res = await deleteForm(deletedData?.data?.id);
        if (res?.response?.data?.status == 200) {
            navigate(ROUTES.FORM_BUILDER);
        }
        closeDeleteDialog();
    };
    const viewSubmissionForm = (data) => {
        setState((prevState) => ({
            ...prevState,
            formData: data?.data,
            activeLink: data?.id,

        }));
    };
    const downloadSubmission = async () => {
        const res = await exportSubmissions(openDownloadDialog?.data?.id);
        if (res?.payload?.data?.status == 200) {
            window.open(res?.payload?.data?.result);
        } else {
            toast.error(res?.payload?.data?.message);
        }
        setState((prevState) => ({
            ...prevState,
            openDownloadDialog: { open: false, data: null },
        }));
    };

    return (
        <>
            <Navbar />
            <CommonDeleteDialog
                title={`Delete Form`}
                open={openDeleteDialog?.open}
                content={`Are you sure you want to Delete ${openDeleteDialog?.data?.formName} Form?`}
                cancelButtonAction={closeDeleteDialog}
                submitButtonAction={removeForm}
                cancelButtonTitle={"Cancel"}
                submitButtonTitle={"Delete"}
                submitButtonParameter={openDeleteDialog}
                id="viewFormDeleteDialog"
            />
            <CommonDeleteDialog
                id="viewFormExportDialog"
                title={<Translate>Export Submissions Excel File</Translate>}
                open={openDownloadDialog?.open}
                content={<Translate>Do You want to Download Submissions in "{openDownloadDialog?.data?.name}" Form?</Translate>}
                cancelButtonAction={closeDeleteDialog}
                submitButtonAction={downloadSubmission}
                cancelButtonTitle={<Translate>Cancel</Translate>}
                submitButtonTitle={<Translate>Download</Translate>}
                submitButtonParameter={openDownloadDialog}
            />
            <FormCollectionPopper
                open={popperOpen}
                anchorEl={anchorEl}
                setState={setState}
            />
            {isLoading ? (
                <LoadingComponent sx={{ height: "600px" }} />
            ) : (
                <Box paddingTop={"60px"}>
                    <HeaderDrawerLink
                        popperOpen={popperOpen}
                        setState={setState}
                        formData={formData}
                    />
                    <Box sx={{ px: { md: 10, xs: 1 } }}>
                        <ViewHeader
                            setViewFormState={setState}
                            item={location?.state?.item}
                            handlerRemoveForm={handlerRemoveForm}
                            handlerEditForm={handlerEditForm}
                            formDetail={formDetail}
                        />
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <ViewResponseBox
                                    item={formData}
                                    formId={location?.state?.item?.id}
                                    viewSubmissionForm={viewSubmissionForm}
                                    activeLink={activeLink}
                                    setState={setState}
                                />
                            </Grid>
                            <Grid item xs={9}>
                                <Paper
                                    sx={{
                                        background: pageColor,
                                        paddingX: "60px",
                                        paddingY: "30px",
                                        boxShadow: "none",
                                    }}
                                >
                                    <RenderElements
                                        state={state}
                                        formElements={formData}
                                        isRead={true}
                                        setState={setState}
                                        logo={formDetail?.logo}
                                    />
                                </Paper>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            )}
        </>

    );
};

const mapStateToProps = (state) => {
    return {
        formDetail: state.formStudio.formDetails,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        { deleteForm, exportSubmissions, getSubmissionsById, getFormById },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewForm);
