import * as Actions from "../Action/SelfConfigurationAction";

const initialState = {
    selfConfigrationDetails: null,
    testOption: null,
    list: null,
};

const SelfConfigurationReducer = (state = initialState, action) => {
    switch (action?.type) {
        case Actions.GET_SELF_CONFIGURATION_DETAILS: {
            return {
                ...state,
                selfConfigrationDetails: action.payload,
            };
        }
        case Actions.EDIT_SELF_CONFIGURATION_TEST: {
            return {
                ...state,
                list: action.payload,
            };
        }
        case Actions.SET_TEST_OPTION: {
            return {
                ...state,
                testOption: action.payload,
            };
        }
        case Actions.GET_SELF_CONFIGURATION_CREATED_LIST: {
            return {
                ...state,
                list: action.payload,
            };
        }
        default: {
            return state;
        }
    }
};

export default SelfConfigurationReducer;
