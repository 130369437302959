import * as Actions from "../Action/FormStudioAction";

const initialState = {
    formsList: null,
    formDetails: null,
    dropDownOption: null,
    formSubmissions: null,
};

const FormBuilderReducer = (state = initialState, action) => {
    switch (action?.type) {
        case Actions.CREATE_FORM: {
            return {
                ...state,
                formsList: [...state.formsList, action.payload],
            };
        }
        case Actions.GET_FORM: {
            return {
                ...state,
                formsList: action.payload,
            };
        }
        case Actions.UPDATE_FORM: {
            let list = state.formsList;
            let index = list.findIndex((obj) => obj.id === action.payload.id);
            if (index !== -1) list[index] = action.payload;
            return {
                ...state,
                formsList: list,
            };
        }
        case Actions.DELETE_FORM_BY_ID: {
            let list = state.formsList;
            let index = list.findIndex((obj) => obj.id === action.payload.id);
            if (index !== -1) list[index] = action.payload;
            return { ...state, formsList: list };
        }
        case Actions.GET_FORM_BY_ID: {
            return {
                ...state,
                formDetails: action.payload,
            };
        }
        case Actions.SET_DROW_DOWN_ITEM: {
            return {
                ...state,
                dropDownOption: action.payload,
            };
        }
        case Actions.GET_FORM_SUBMISSIONS: {
            return {
                ...state,
                formSubmissions: action.payload,
            };
        }

        case Actions.DCT_IN_ASSESMENT: {
            return {
                ...state,
                dctforAssesment: action.payload
            }
        }

        default: {
            return state;
        }
    }
};

export default FormBuilderReducer;
