import React, { Fragment } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import LocationTable from "./LocationTable";
import TextFieldComponent from "../../components/TextField/TextFieldComponent";
import "../../../assets/styles/Container/ScoringConfiguration.scss";
import ButtonComponent from "../../components/ButtonComponent";
import { getUserById } from "../../store/Action/UserAction";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
const LocationOfWork = (props) => {
    const {
        userLoggedIn
    } = props;
    return (
        <Fragment>
            <Box sx={{ px: { md: 8, xs: 1, sm: 7 } }}>
                <Grid
                    container
                    sx={{ display: "flex" }}
                    className="gridLocation"
                >
                    <Grid item md={12} lg={12} xl={11} mt={5} ml={1.5} mr={5}>
                        <Typography className="questionHeading">
                            Questions
                        </Typography>
                        <TextFieldComponent id="locationQuestion"/>
                    </Grid>
                    <Grid container mt={7} mb={4}>
                        <Grid item xs={12} paddingLeft={2}>
                            <LocationTable />
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <Grid
                item
                lg={11}
                md={5}
                sm={3}
                xs={4}
                mt={3}
                ml={6}
                xl={1}
                display="flex"
                justifyContent="flex-end"
                gap={"8px"}
            >
                <ButtonComponent title={"Cancel"} variant="outlined" />
                <ButtonComponent
                    title={userLoggedIn?.language_data?.comman?.save_btn || "Save"}
                    type="submit"
                    variant="contained"
                />
            </Grid>
        </Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,
    };
};
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getUserById,

        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps) (LocationOfWork);
