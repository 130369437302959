import * as Actions from "../Action/RcpTasksAction";

const initialState = {
    tasks: [],
};

const task = (state = initialState, action) => {
    switch (action?.type) {
        case Actions.GET_RCPTASKS_ACTION: {
            if(action?.payload?.length > 0){
                return {
                    ...state,
                    tasks: [...action?.payload],
                };
            }else{
                return state;
            }
        }
        case Actions.DELETE_RCPTASKS_ACTION: {
            const newTaskArr = state?.tasks.filter((task)=>{
                return task.id !== action?.payload;
            });
            return {
                ...state,
                tasks: [...newTaskArr]
            };
        }
        default: {
            return state;
        }
    }
};
export default task;
