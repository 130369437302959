import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Grid from "@mui/material/Grid";
import ButtonComponent from "../../../components/ButtonComponent";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TooltipComponent from "../../../components/ToolTip/TooltipComponent";
import { ReactComponent as DeleteIcon } from "../../../../assets/Images/Icons/DeleteIcon.svg";
import TableComponent from "../../../components/Table/TableComponent";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { ReactComponent as EditIcon } from "../../../../assets/Images/Icons/EditIcon.svg";
import {
    getSignedDocumentAprrovedList,
    getSignedDocumentById,
} from "../../../store/Action/SignedDocumentAction";
import { getDocumentAprrovedList } from "../../../store/Action/DocumentDatabaseAction";
import { deleteTemplatetAction } from "../../../store/Action/ContractAction";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../router/RouteList";
import CommonDeleteDialog from "../../../components/DialogsBox/CommonDeleteDialog";
import { TextField } from "@mui/material";

function DocumentTable(props) {
    const navigate = useNavigate();
    const {
        getSignedDocumentAprrovedList,
        getDocumentAprrovedList,
        selectedDocumentData,
        setOpenAdd,
        tableList,
        handleUpdate,
        templates,
        toggle,
        setToggle,
        tableFor,
        userLoggedIn
    } = props;

    const [state, setState] = useState({
        open: false,
        toDelete: null,
        fileName: null,
        editedDocumentId: null,
        editedDocumentName: "",
    });

    async function documentDatabaseView(data, mode) {
        navigate(`${ROUTES.DOCUMENTS}?${data?.id}`, {
            state: { ...data, mode: mode },
        });
    }
    async function documentDatabaseEdit(data, mode) {
        navigate(`${ROUTES.DOCUMENTS}?${data?.id}`, {
            state: { ...data, mode: mode },
        });
    }
    async function signedDocumentView(data, mode) {
        navigate(`${ROUTES.CONTRACTS}?${data?.id}`, {
            state: { ...data, mode: mode },
        });
    }
    async function signedDocumentEdit(data, mode) {
        navigate(`${ROUTES.CONTRACTS}?${data?.id}`, {
            state: { ...data, mode: mode },
        });
    }
    const handleUpdateClick = () => {
        const sequence = tableList?.map((item) => item?.sequence);
        const duplicateSequence = sequence?.filter((item, index) => {
            return index !== sequence.indexOf(item);
        });
        if (duplicateSequence?.length > 0) {
            toast.error("Duplicate sequence is not allowed.");
        } else {
            handleUpdate();
        }
    };

    const handleAdd = () => {
        setOpenAdd(true);
    };

    const handleEditClick = (documentId, documentName) => {
        setState((prevState) => ({
            ...prevState,
            editedDocumentId: documentId,
            editedDocumentName: documentName,
        }));
    };

    useEffect(() => {
        async function callApi() {
            setState((prevState) => ({ ...prevState, isLoading: true }));
            if (tableFor == "signedDocuments") {
                const data = await getSignedDocumentAprrovedList({});
            }
            if (tableFor == "documentDatabase") {
                const data = await getDocumentAprrovedList({});
            }
            setState((prevState) => ({ ...prevState, isLoading: false }));
        }
        if (templates === null) callApi();
        if (templates) {
            setState((prevState) => ({
                ...prevState,
                filteredData: templates,
            }));
        }
        callApi();
    }, [getSignedDocumentAprrovedList, selectedDocumentData]);

    const renderClassStatus = (obj, row) => {
        if (obj?.rowClass) return obj?.rowClass;
        else if (row?.status_name === "Approved") return "cellApproved";
        else if (
            row?.status_name?.replace(/\s/g, "") === "PendingforApproval" ||
            row?.status_name === "Others"
        )
            return "cellPending";
        else if (
            row?.status_name?.replace(/\s/g, "") === "OnHold" ||
            row?.status_name === "Rejected" ||
            row?.status_name === "Reassigned"
        )
            return "cellOnHold";
    };
    const setDelete = (data, category_id) => {
        setState((prevState) => ({
            ...prevState,
            open: true,
            toDelete: category_id,
            document_name: data.document_name,
        }));
    };
    async function handleDelete() {
        await deleteTemplatetAction(state.toDelete);
        setState((prevState) => ({
            ...prevState,
            open: false,
        }));
        setToggle(!toggle);
    }
    const deleteContent = `Are you sure,you want to remove the (${state.document_name} SignedDocument) from the SignedDocument?`;

    const headerList = [
        {
            id: 1,
            name: userLoggedIn?.language_data?.documentdatabase?.document_name || "Document Name",
            headerClass: "tableHeading",
            accessibleKey: "document_name",
            rowClass: "nameFile",
            headerAlign: "center",
            rowAlign: "center",
        },
        {
            id: 2,
            name:  userLoggedIn?.language_data?.documentdatabase?.document_category || "Category",
            headerClass: "tableHeading",
            rowClass: "tableBodyRow",
            accessibleKey: "category",
            headerAlign: "center",
            rowAlign: "center",
        },

        {
            id: 3,
            name: userLoggedIn?.language_data?.comman?.status || "Status",
            headerClass: "tableHeading",
            accessibleKey: "status_name",
            headerAlign: "center",
            rowAlign: "center",
        },
        {
            id: 4,
            name: userLoggedIn?.language_data?.comman?.approved_by ||  "Approved By",
            headerClass: "tableHeading",
            accessibleKey: "assign_user",
            headerAlign: "center",
            rowAlign: "center",
        },
    ];

    return (
        <>
            <Box sx={{ px: { md: 7, xs: 1, mt: "40px" } }}>
                <CommonDeleteDialog
                    open={state.open}
                    cancelButtonAction={() => {
                        setState({
                            open: false,
                            toDelete: [],
                            document_name: null,
                        });
                    }}
                    onClick={handleDelete}
                    title="Delete Document"
                    content={deleteContent}
                />
                <TableComponent
                    headerList={headerList}
                    extraHeaderRight={
                        <TableCell align="center" className="tableHeading">
                            Action
                        </TableCell>
                    }
                >
                    {templates?.length > 0 &&
                        templates?.map((row, index, template) => (
                            <TableRow
                                key={row?.name}
                                className={
                                    index % 2 !== 0 ? "tertiaryBackground" : ""
                                }
                            >
                                {headerList?.map((obj) => (
                                    <TableCell
                                        component="th"
                                        key={obj?.name}
                                        align={obj?.rowAlign}
                                        className={renderClassStatus(obj, row)}
                                    >
                                        {obj?.id === 1 &&
                                        state.editedDocumentId === row?.id ? (
                                            <TextField
                                                value={state.editedDocumentName}
                                                className="textfieldDocument"
                                                onChange={(e) =>
                                                    setState((prevState) => ({
                                                        ...prevState,
                                                        editedDocumentName:
                                                            e.target.value,
                                                    }))
                                                }
                                                focused={
                                                    state.editedDocumentId ===
                                                    row?.id
                                                }
                                            />
                                        ) : (
                                            row[obj?.accessibleKey]
                                        )}
                                    </TableCell>
                                ))}

                                <TableCell sx={{ textAlign: "center" }}>
                                    {row?.action}
                                    <TooltipComponent
                                        title="View Signed Document"
                                        arrow={true}
                                    >
                                        <IconButton
                                            className="iconButton"
                                            onClick={() => {
                                                if (
                                                    tableFor ==
                                                    "signedDocuments"
                                                ) {
                                                    signedDocumentView(
                                                        row,
                                                        "view"
                                                    );
                                                }
                                                if (
                                                    tableFor ==
                                                    "documentDatabase"
                                                ) {
                                                    documentDatabaseView(
                                                        row,
                                                        "view"
                                                    );
                                                }
                                            }}
                                        >
                                            <RemoveRedEyeOutlinedIcon />
                                        </IconButton>
                                    </TooltipComponent>
                                    <TooltipComponent
                                        title="Edit Signed Document"
                                        arrow={true}
                                    >
                                        <IconButton
                                            className="iconButton"
                                            onClick={() =>
                                                handleEditClick(
                                                    row.id,
                                                    row.document_name
                                                )
                                            }
                                        >
                                            <EditIcon />
                                        </IconButton>
                                    </TooltipComponent>
                                    <TooltipComponent
                                        title="Delete Signed Doucment"
                                        arrow={true}
                                    >
                                        <IconButton
                                            className="iconButton"
                                            aria-label="delete"
                                            onClick={() => {
                                                setDelete(
                                                    template,
                                                    template.id
                                                );
                                            }}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </TooltipComponent>
                                </TableCell>
                            </TableRow>
                        ))}
                </TableComponent>

                <Grid item md={12} lg={12} mt={2} textAlign="end">
                    <ButtonComponent
                        title={userLoggedIn?.language_data?.comman?.add_btn || "Add"}
                        sx={{
                            mr: 2,
                        }}
                        variant="outlined"
                        onClick={handleAdd}
                    />
                    <ButtonComponent
                        title= {userLoggedIn?.language_data?.comman?.edit_btn || "Update"}
                        disabled={tableList?.length === 0}
                        onClick={handleUpdateClick}
                    />
                </Grid>
            </Box>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        userLoggedIn: state.auth?.userLoggedIn,
        customerDropdown: state?.auth?.commonDropdown?.states,
        signedDocuments: state?.signedDocs?.selectedDocumentData,
        documentDatabase: state?.docDatabase?.selectedDocumentDatabaseData,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getSignedDocumentAprrovedList,
            getDocumentAprrovedList,
            getSignedDocumentById,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentTable);
